import React from 'react';
import { Redirect, Route, RouteComponentProps, matchPath, Switch } from 'react-router-dom';
import Layout from 'shared/container/Layout';
import { PageNotFound } from 'shared/components';
import CashOnDeliveryByParcel from './Routes/CashOnDeliveryByParcel';
import CashOnDeliveryByShipment from './Routes/CashOnDeliveryByShipment';
import CashOnDeliveryByBooking from './Routes/CashOnDeliveryByBooking';

const CashOnDelivery: React.FC<RouteComponentProps> = (props) => {
  const { match, location } = props;

  const checkPath = (path: string) => {
    return !!matchPath(location.pathname, `${match.path}/${path}`);
  };

  const getTextHeader = () => {
    let text = '';
    if (checkPath('by-parcel')) {
      text = 'สรุปยอดการเก็บเงินปลายทาง';
    }
    if (checkPath('by-shipment')) {
      text = 'สรุปยอดการเก็บเงินปลายทาง';
    }
    if (checkPath('by-booking')) {
      text = 'สรุปยอดการเก็บเงินปลายทาง';
    }

    return text;
  };

  const textHeader = getTextHeader();

  return (
    <Layout textHeader={textHeader}>
      <Switch>
        {match.isExact && <Redirect to={`${match.path}/by-parcel`} />}
        <Route path={`${match.path}/by-parcel`} component={CashOnDeliveryByParcel} />
        <Route path={`${match.path}/by-shipment`} component={CashOnDeliveryByShipment} />
        <Route path={`${match.path}/by-booking`} component={CashOnDeliveryByBooking} />
        <Route component={PageNotFound} />
      </Switch>
    </Layout>
  );
};

export default CashOnDelivery;
