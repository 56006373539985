import React, { useContext } from 'react';
import { FileZipOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Button, Modal, Typography, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import UPDATE_PAYMENT_VOUCHER_MUTATION from 'shared/graphql/mutation/updatePaymentVoucher';
import formatCurrency from 'shared/utils/formatCurrency';
import { PaymentVoucherContext } from 'views/AccountPayment/common/context/PaymentVoucherContext';
import { formatDateTime } from 'shared/utils/dateTime';

const { Text } = Typography;

const ModalConfirmComplete = (props) => {
  const { onCancel, visible } = props;
  const { selectedPaymentVoucherDetail: selectedPaymentVoucherDetailContext } = useContext(
    PaymentVoucherContext,
  );
  const [
    selectedPaymentVoucherDetail,
    setSelectedPaymentVoucherDetail,
  ] = selectedPaymentVoucherDetailContext;
  const {
    paymentVoucherId,
    createDate,
    voucherNumber,
    grandTotalPrice,
    proofSlip,
    dueDate,
    slipRemark,
  } = selectedPaymentVoucherDetail;

  const closeModalHandler = () => {
    setSelectedPaymentVoucherDetail((prevState) => ({
      ...prevState,
      isOpenModal: false,
    }));
  };

  const [updatePaymentVoucher, { loading }] = useMutation(UPDATE_PAYMENT_VOUCHER_MUTATION, {
    update: (_cache, { data }) => {
      const { _id } = data.updatePaymentVoucher;
      if (_id) {
        message.success('เปลี่ยนสถานะเป็นจ่ายเรียบร้อยแล้ว');
        onCancel();
        closeModalHandler();
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
    refetchQueries: () => ['PaymentVoucher', 'PaymentVouchers', 'PaymentVoucherStatus'],
  });

  const confirmComplete = () => {
    updatePaymentVoucher({
      variables: {
        _id: paymentVoucherId,
        voucher_status: 4,
        proof_slip: proofSlip,
        due_date: dueDate,
        slip_remark: slipRemark,
      },
    });
  };

  return (
    <Modal
      title="บันทึกการจ่ายใบสำคัญจ่ายนี้หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<FileZipOutlined />}
          type="green"
          onClick={() => confirmComplete()}
          loading={loading}
        >
          บันทึกการจ่าย
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>วันที่เอกสาร:</Col>
        <Col span={18}>
          <Text strong>{formatDateTime(createDate)}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>รหัสใบสำคัญจ่าย:</Col>
        <Col span={18}>
          <Text strong>{voucherNumber}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>มูลค่าใบสำคัญจ่าย:</Col>
        <Col span={18}>
          <Text strong>{formatCurrency(grandTotalPrice, '0')}</Text>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalConfirmComplete;
