/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Row, Col } from 'antd';
import { DeleteOutlined, PlusCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useFormContext } from 'react-hook-form';
import CAL_BILLING_NOTE_QUERY, {
  CalBillingNoteData,
  CalBillingNoteVars,
} from 'shared/graphql/query/calBillingNote';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { Button, Text, Spin } from 'shared/components';
import { StyledTable } from './Styles';

type InvoiceDataTable = {
  key: string;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: ReactNode;
  column4: string;
  invoiceId: string;
};

const columns = [
  {
    title: 'เครื่องมือ',
    dataIndex: 'tools',
    width: 70,
  },
  {
    title: 'วันที่สร้างเอกสาร',
    dataIndex: 'column1',
    width: 110,
  },
  {
    title: 'รหัสใบแจ้งหนี้',
    dataIndex: 'column2',
    width: 200,
  },
  {
    title: 'มูลค่าใบแจ้งหนี้',
    dataIndex: 'column6',
    width: 130,
    align: 'right',
  },
  {
    title: 'วันที่ครบกำหนดชำระ',
    dataIndex: 'column3',
    width: 160,
  },
];

const InvoiceList = () => {
  const { getValues } = useFormContext();
  const { error, data } = useQuery<CalBillingNoteData, CalBillingNoteVars>(CAL_BILLING_NOTE_QUERY, {
    variables: {
      bill_invoice: [],
    },
    fetchPolicy: 'cache-and-network',
  });

  let content: ReactNode = <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  let invoiceData: InvoiceDataTable[] = [];
  if (data && data.calBillingNote) {
    const { bill_invoice } = data.calBillingNote;

    invoiceData = bill_invoice.map((item) => {
      const { invoice } = item;

      return {
        key: invoice._id,
        tools: (
          <Button
            disabled={getValues('invoicesId').length < 2}
            title="ลบ"
            color="red--outline"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => {}}
          />
        ),
        column1: formatDate('2020-02-27 00:00:00'),
        column2: 'INV20012700003',
        column3: (
          <Text customType="primary" strong>
            {formatCurrency(5000)}
          </Text>
        ),
        column4: formatDate('2020-02-27 00:00:00'),
        invoiceId: invoice._id,
      };
    });

    content = (
      <>
        <StyledTable
          scroll={{ x: 'max-content' }}
          pagination={false}
          columns={columns}
          dataSource={invoiceData}
          size="small"
        />
        <Row justify="space-between" style={{ marginTop: 10 }}>
          <Col>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button color="blue--light" icon={<PlusCircleOutlined />} disabled>
                  เพิ่มใบแจ้งหนี้
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  if (error) {
    content = error.message;
  }

  return <div>{content}</div>;
};

export default InvoiceList;
