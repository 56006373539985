import gql from 'graphql-tag';

export interface CodOutParcelBBLTransferConfirmData {
  codOutParcelBBLTransferConfirm: {
    responseCode: string;
    responseMesg: string;
    data: {
      _id: string;
      receiverName: string;
    };
  };
}

export interface CodOutParcelBBLTransferConfirmVars {
  transactionId: string;
  employeeConfirmPhone: string;
}

export default gql`
  mutation CodOutParcelBBLTransferConfirm($transactionId: ID!, $employeeConfirmPhone: String!) {
    codOutParcelBBLTransferConfirm(
      transactionId: $transactionId
      employeeConfirmPhone: $employeeConfirmPhone
    ) {
      responseCode
      responseMesg
      data {
        _id
        receiverName
      }
    }
  }
`;
