import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { color } from 'shared/utils/styles';
import { Text, Spin } from 'shared/components';

export const Container = styled(Row)`
  line-height: 1.2;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid ${color.border};
  border-radius: 4px;
  width: 100%;
  align-items: 'center';
`;

interface CardIconProps {
  title: string;
  text: string | React.ReactNode;
  icon: React.ReactNode;
  loading?: boolean;
}

const CardIcon: React.FC<CardIconProps> = (props) => {
  const { title, text, icon, loading, ...otherProps } = props;

  return (
    <Container {...otherProps}>
      <Col span={4} style={{ fontSize: 30, alignItems: 'center', justifyContent: 'center' }}>
        {icon}
      </Col>
      <Col span={20}>
        <Row>
          <Text style={{ fontSize: 20 }}>{title}</Text>
        </Row>
        <Row>
          {loading ? (
            <Spin />
          ) : (
            <Text strong style={{ fontSize: 28 }}>
              {text}
            </Text>
          )}
        </Row>
      </Col>
    </Container>
  );
};

export default CardIcon;
