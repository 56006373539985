import gql from 'graphql-tag';

export interface SingleUploadVars {
  file: File;
}

export interface SingleUploadData {
  singleUpload: {
    path: string;
  };
}

export default gql`
  mutation SingleUpload($file: Upload) {
    singleUpload(file: $file) {
      path
    }
  }
`;
