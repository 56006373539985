/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import formatCurrency from 'shared/utils/formatCurrency';
import { Typography } from 'antd';
import { ParcelCostData } from 'shared/graphql/query/parcelCost';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { StyledTable } from './Styles';

const { Text } = Typography;

type ServiceTableProps = {
  parcelCostData: ParcelCostData;
};

const ServiceTable: React.FC<ServiceTableProps> = ({ parcelCostData }) => {
  const { serviceItems } = parcelCostData.parcelCost;
  const columnsTableService = [
    {
      title: '',
      children: [
        {
          title: 'รายการ',
          dataIndex: 'list_name',
          key: 'list_name',
          width: 200,
        },
        {
          title: 'จำนวน',
          dataIndex: 'qty',
          key: 'qty',
          width: 50,
          align: 'right',
        },
      ],
    },
    {
      title: 'รายการต้นทุน',
      children: [
        {
          title: 'ราคาต่อหน่วย',
          dataIndex: 'price',
          key: 'price',
          width: 100,
          align: 'right',
        },
        {
          title: 'ส่วนลด',
          dataIndex: 'discount',
          key: 'discount',
          width: 100,
          align: 'right',
        },
        {
          title: 'ราคาหลังลด',
          dataIndex: 'total_price',
          key: 'total_price',
          width: 100,
          align: 'right',
        },
        {
          title: 'ภาษีมูลค่าเพิ่ม',
          dataIndex: 'vat',
          key: 'vat',
          width: 100,
          align: 'right',
        },
        {
          title: 'หัก ณ ที่จ่าย',
          dataIndex: 'wht',
          key: 'wht',
          width: 100,
          align: 'right',
        },
        {
          title: 'ราคาต้นทุน',
          dataIndex: 'grand_total_price',
          key: 'grand_total_price',
          width: 100,
          align: 'right',
        },
      ],
    },
  ];

  const serviceGroup = _.chain(serviceItems)
    .groupBy('service_category_name')
    .map((value, key) => ({
      serviceCategoryName: key,
      data: value,
    }))
    .value();

  const dataTableService = serviceGroup.reduce<any[]>((total, currentGroup) => {
    const rowHeader = {
      key: uuidv4(),
      list_name: <Text strong>{currentGroup.serviceCategoryName}</Text>,
    };

    const rowService = currentGroup.data.map((item) => ({
      key: uuidv4(),
      list_name: item.list_name,
      qty: item.qty,
      price: formatCurrency(item.price, '-'),
      discount: formatCurrency(item.discount, '-'),
      total_price: formatCurrency(item.total_price, '-'),
      vat: formatCurrency(item.vat, '-'),
      wht: formatCurrency(item.wht, '-'),
      grand_total_price: formatCurrency(item.grand_total_price, '-'),
    }));

    return [...total, rowHeader, ...rowService];
  }, []);

  return (
    <StyledTable
      bordered
      pagination={false}
      className="table-1"
      columns={columnsTableService}
      dataSource={dataTableService}
    />
  );
};

export default ServiceTable;
