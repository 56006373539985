/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Select } from 'antd';
import { useFormikContext } from 'formik';

const { Option } = Select;

const SelectFieldPaymentMethod = () => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext();

  return (
    <Select
      disabled
      value={values.paymentMethod}
      style={{ width: '100%' }}
      onChange={(value) => setFieldValue('paymentMethod', value)}
      onBlur={() => setFieldTouched('paymentMethod')}
      name="paymentMethod"
    >
      <Option value="">เลือกช่องทางการจ่าย</Option>
      <Option value="โอนเงิน/เงินสด">โอนเงิน/เงินสด</Option>
    </Select>
  );
};

export default SelectFieldPaymentMethod;
