import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SubTabs } from 'shared/components';
import LOG_AR_STATUS_QUERY, { LogARStatusData } from 'shared/graphql/query/logARStatus';
import { LogARStatus } from 'views/DebtTracker/common/model/logARStatus.model';

interface DebtTrackerTabProps {
  name: string;
  tab: LogARStatus;
  onChange: (tab: LogARStatus) => void;
}

const DebtTrackerTab: React.FC<DebtTrackerTabProps> = (props) => {
  const { tab, onChange, name } = props;
  const { data, refetch } = useQuery<LogARStatusData>(LOG_AR_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const checkSelectTab = (id: LogARStatus) => {
    return id === tab && name === 'debtTracker';
  };

  const selectTab = (index: LogARStatus) => {
    refetch();
    onChange(index);
  };

  const tabs = [
    {
      id: LogARStatus.OUTSTANDING_DEBT,
      name: 'ลูกหนี้ค้างชำระ',
      color: 'badge-grey',
      count: data?.logARStatus.outstandingDebt || 0,
      active: false,
      divider: false,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default DebtTrackerTab;
