import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SubTabs } from 'shared/components';
import INVOICE_STATUS_QUERY, { InvoiceStatusData } from 'shared/graphql/query/invoiceStatus';
import { InvoiceStatus } from 'views/AccountReceive/common/model/invoiceStatus.model';

interface InvoiceTabsProps {
  tab: number;
  name: string;
  onChange: (tab: InvoiceStatus) => void;
}

const InvoiceTabs: React.FC<InvoiceTabsProps> = (props) => {
  const { tab, onChange, name } = props;
  const { data, refetch } = useQuery<InvoiceStatusData>(INVOICE_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const checkSelectTab = (id: InvoiceStatus) => {
    return id === tab && name === 'invoice';
  };

  const selectTab = (index: InvoiceStatus) => {
    refetch();
    onChange(index);
  };

  const tabs = [
    {
      id: InvoiceStatus.DRAFT,
      name: 'แบบร่างใบแจ้งหนี้',
      color: 'badge-grey',
      count: data?.invoiceStatus.draft || 0,
    },
    {
      id: InvoiceStatus.INVOICE,
      name: 'ใบแจ้งหนี้',
      color: 'badge-grey',
      count: data?.invoiceStatus.invoice || 0,
    },
    {
      id: InvoiceStatus.BILLING_NOTE,
      name: 'วางบิลแล้ว',
      color: 'badge-grey',
      count: data?.invoiceStatus.billing_note || 0,
    },
    {
      id: InvoiceStatus.RECEIPT,
      name: 'ชำระเงินแล้ว',
      color: 'badge-grey',
      count: data?.invoiceStatus.receipt || 0,
      divider: true,
    },
    {
      id: InvoiceStatus.CANCEL,
      name: 'ยกเลิก',
      color: 'badge-grey',
      count: data?.invoiceStatus.cancel || 0,
    },
    {
      id: InvoiceStatus.EDIT,
      name: 'แก้ไข',
      color: 'badge-grey',
      count: data?.invoiceStatus.edit || 0,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default InvoiceTabs;
