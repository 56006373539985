/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { CodInContext } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/CodInContext';
import CREATE_PAYMENT_COD_MUTATION, {
  CreatePaymentCodData,
  CreatePaymentCodVars,
} from 'shared/graphql/mutation/createPaymentCod';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { formatDate } from 'shared/utils/dateTime';
import { FormValues } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/Routes/CodIn/CodInList/ModalCreateCod/model/formValues.model';
import { useFormContext } from 'react-hook-form';
import { StyledModal } from './Styles';

type ModalConfirmCreateCodProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmCreateCod: React.FC<ModalConfirmCreateCodProps> = (props) => {
  const { onCancel, visible } = props;
  const { getValues, reset } = useFormContext<FormValues>();
  const { selectedCodsIn, setSelectedCodsIn } = useContext(CodInContext);
  const { modalConfirmCod } = selectedCodsIn;
  const { codsIn } = modalConfirmCod;

  const [createPaymentCod, { loading }] = useMutation<CreatePaymentCodData, CreatePaymentCodVars>(
    CREATE_PAYMENT_COD_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.createPaymentCod._id) {
          message.success('สร้างการทำจ่ายแล้ว');
          onCancel();
          setSelectedCodsIn((prevState) => ({
            ...prevState,
            isOpenModal: false,
          }));
          reset();
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['Cod', 'Cods', 'CodStatus'],
    },
  );

  const confirmCreateCod = () => {
    createPaymentCod({
      variables: {
        customer: getValues('customerId'),
        cod: getValues('codsInId').map((codInId) => codInId),
        payment_cod_remark: getValues('paymentCodRemark'),
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="สร้างการทำจ่ายรายการ COD ใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmCreateCod()}
          loading={loading}
        >
          ใช่,สร้างการทำจ่าย
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          ทั้งหมด <Text strong>{`${codsIn.length} รายการ`}</Text>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 0]}>
            <Col span={4}>ชื่อลูกค้า</Col>
            <Col span={5}>รหัส COD-IN</Col>
            <Col span={5}>วันที่รับสินค้า</Col>
            <Col span={5}>Booking Number</Col>
            <Col span={4}>ยอดรวม COD (บาท)</Col>
          </Row>

          {codsIn.map((codIn) => (
            <Row gutter={[8, 0]} key={codIn.codInNumber}>
              <Col span={4}>
                <Text strong>{codIn.customerFullName || '-'}</Text>
              </Col>
              <Col span={5}>
                <Text strong>{codIn.codInNumber || '-'}</Text>
              </Col>
              <Col span={5}>
                <Text strong>{codIn.pickUpDate ? formatDate(codIn.pickUpDate) : '-'}</Text>
              </Col>
              <Col span={5}>
                <Text strong>{codIn.bookingNumber || '-'}</Text>
              </Col>
              <Col span={4}>
                <Text strong customType="primary">
                  {formatCurrency(codIn.totalCod)}
                </Text>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmCreateCod;
