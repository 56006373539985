import gql from 'graphql-tag';
import { CodItemStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/model/codItemStatus.model';
import { CodInStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/model/codInStatus.model';

export interface CodVars {
  _id: string;
}

export interface CodItem {
  _id: string;
  vendor: {
    _id: string;
    full_name: string;
  };
  status: CodItemStatus;
  total_cod: number;
  cod_in_date: string;
  pick_up_date: string;
  delivered_date: string;
  last_update: string;
  document: string[];
  remark: string;
  reject_remark: string;
}

export interface CodData {
  cod: {
    _id: string;
    cod_in_number: string;
    cod_item: CodItem[];
    cod_status: CodInStatus;
    customer: {
      _id: string;
      full_name: string;
    };
    booking_number: string;
    total_cod: number;
    total_vendor: number;
    confirm_status: number;
  };
}

export default gql`
  query Cod($_id: ID!) {
    cod(_id: $_id) {
      _id
      cod_in_number
      cod_status
      cod_item {
        _id
        vendor {
          _id
          full_name
        }
        status
        total_cod
        cod_in_date
        pick_up_date
        delivered_date
        last_update
        document
        remark
        reject_remark
      }
      customer {
        _id
        full_name
      }
      booking_number
      total_cod
      total_vendor
      confirm_status
    }
  }
`;
