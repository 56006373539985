import React, { useState, ReactChild } from 'react';
import { AppStatus } from 'views/AccountPayment/common/model/appStatus.model';

export interface SelectedApprovePaymentDetailType {
  appId: string;
  isOpenModal: boolean;
  isLoading: boolean;
  appNumber: string;
  appStatus: AppStatus | null;
  grandTotalPrice: number;
  createDate: string;
  modalConfirmUpdate: {
    appNumber: string;
    grandTotalPrice: number;
  };
  paymentType: string;
}

interface ApprovePayment {
  selectedApprovePaymentDetail: SelectedApprovePaymentDetailType;
  setSelectedApprovePaymentDetail: (
    params: (cb: SelectedApprovePaymentDetailType) => SelectedApprovePaymentDetailType,
  ) => void;
}

type ApprovePaymentProps = {
  children: ReactChild;
};

const ApprovePaymentContext = React.createContext({} as ApprovePayment);

const ApprovePaymentProvider: React.FC<ApprovePaymentProps> = (props) => {
  const { children } = props;
  const [
    selectedApprovePaymentDetail,
    setSelectedApprovePaymentDetail,
  ] = useState<SelectedApprovePaymentDetailType>({
    appId: '',
    isOpenModal: false,
    isLoading: true,
    appNumber: '',
    appStatus: null,
    grandTotalPrice: 0,
    createDate: '',
    modalConfirmUpdate: {
      appNumber: '',
      grandTotalPrice: 0,
    },
    paymentType: '',
  });

  const store = {
    selectedApprovePaymentDetail,
    setSelectedApprovePaymentDetail,
  };

  return <ApprovePaymentContext.Provider value={store}>{children}</ApprovePaymentContext.Provider>;
};

export { ApprovePaymentContext, ApprovePaymentProvider };
