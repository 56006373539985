import React, { ReactNode } from 'react';
import { Tabs } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { color } from 'shared/utils/styles';

const { TabPane: TabPaneAntd } = Tabs;

type Tab = {
  key: string;
  text: string;
  component: ReactNode;
  isShowIcon?: boolean;
};

type TabPaneProps = {
  tabs: Tab[];
};

const TabPane: React.FC<TabPaneProps> = (props) => {
  const { tabs } = props;
  return (
    <Tabs defaultActiveKey="1" animated={false}>
      {tabs.map((tab) => (
        <TabPaneAntd
          key={tab.key}
          tab={
            <span>
              {tab.text}
              {tab.isShowIcon && (
                <>
                  {' '}
                  <CheckCircleOutlined
                    style={{
                      color: color.success,
                      marginLeft: 5,
                    }}
                  />
                </>
              )}
            </span>
          }
        >
          {tab.component}
        </TabPaneAntd>
      ))}
    </Tabs>
  );
};

export default TabPane;
