type EnumLiteralsOf<T extends unknown> = T[keyof T];
export const PaymentVoucherStatus = Object.freeze({
  PENDING: 1 as const,
  WAITING: 2 as const,
  CONFIRM: 3 as const,
  COMPLETE: 4 as const,
  REJECT: 5 as const,
  CANCEL: 6 as const,
  EDIT: 7 as const,
});

export type PaymentVoucherStatus = EnumLiteralsOf<typeof PaymentVoucherStatus>;
