/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext } from 'react';
import { Row, Col, message } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import baseUrl from 'shared/config/baseUrl';
import {
  BillingNoteProvider,
  BillingNoteContext,
  SelectedInvoicesType,
} from 'views/AccountReceive/common/context/BillingNoteContext';
import { Button } from 'shared/components';
import { BillingNotesStatus } from 'views/AccountReceive/common/model/billingNoteStatus.model';
import { InvoiceStatus } from 'views/AccountReceive/common/model/invoiceStatus.model';
import { Container } from './Styles';
import InvoicesTable from './InvoicesTable';
import BillingNotesTable from './BillingNotesTable';
import ModalCreateBillingNote from './ModalCreateBillingNote';
import InvoiceTabs from './InvoiceTabs';
import BillingNoteTabs from './BillingNoteTabs';

const InvoiceWrapper = () => {
  return (
    <BillingNoteProvider>
      <Invoice />
    </BillingNoteProvider>
  );
};

const Invoice = () => {
  const [tabInvoice, setTabInvoice] = useState<InvoiceStatus>(InvoiceStatus.INVOICE);
  const [tabBillingNote, setTabBillingNote] = useState<BillingNotesStatus>(
    BillingNotesStatus.WAITING,
  );
  const [tabName, setTabName] = useState<'invoice' | 'billingNote'>('invoice');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {
    selectedInvoices,
    setSelectedInvoices,
    selectedBillingNote,
    setSelectedBillingNote,
  } = useContext(BillingNoteContext);

  const confirmCreatePdfZip = () => {
    const billingNotesId =
      selectedBillingNote.billingNoteId.length === 1
        ? [selectedBillingNote.billingNoteId[0], selectedBillingNote.billingNoteId[0]]
        : selectedBillingNote.billingNoteId;
    const url = `${baseUrl}/GetPdfZip/?name=BL&_id=${billingNotesId.join('&_id=')}`;
    window.open(url);
    message.success('ดาวน์โหลดสำเร็จแล้ว');
    setSelectedBillingNote((prevState) => ({
      ...prevState,
      billingNoteId: [],
    }));
  };

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={16}>
          <Row>
            <Col style={{ marginRight: 20 }}>
              <InvoiceTabs
                tab={tabInvoice}
                name={tabName}
                onChange={(value) => {
                  setTabInvoice(value);
                  setTabName('invoice');
                  setCurrentPage(1);
                }}
              />
            </Col>
            <Col>
              <BillingNoteTabs
                tab={tabBillingNote}
                name={tabName}
                onChange={(value) => {
                  setTabBillingNote(value);
                  setTabName('billingNote');
                  setCurrentPage(1);
                }}
              />
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row justify="end">
            <Col>
              {tabName === 'invoice' && (
                <Button
                  color="green"
                  disabled={selectedInvoices.invoicesId.length === 0}
                  onClick={() => {
                    setSelectedInvoices(
                      (prevState): SelectedInvoicesType => ({
                        ...prevState,
                        isOpenModal: true,
                      }),
                    );
                  }}
                >
                  สร้างใบวางบิล
                </Button>
              )}
              {tabName === 'billingNote' && tabBillingNote === BillingNotesStatus.WAITING && (
                <Button
                  icon={<FileTextOutlined />}
                  disabled={selectedBillingNote.billingNoteId.length === 0}
                  onClick={() => {
                    confirmCreatePdfZip();
                  }}
                  color="green"
                >
                  ดาวน์โหลดเอกสาร
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      {tabName === 'invoice' && (
        <InvoicesTable
          status={tabInvoice}
          pageSize={pageSize}
          onChangePageSize={(value) => setPageSize(value)}
          currentPage={currentPage}
          onChangeCurrentPage={(value) => setCurrentPage(value)}
        />
      )}

      {tabName === 'billingNote' && (
        <BillingNotesTable
          status={tabBillingNote}
          pageSize={pageSize}
          onChangePageSize={(value) => setPageSize(value)}
          currentPage={currentPage}
          onChangeCurrentPage={(value) => setCurrentPage(value)}
        />
      )}

      <ModalCreateBillingNote />
    </Container>
  );
};

export default InvoiceWrapper;
