/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext } from 'react';
import { Row, Col, message } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import baseUrl from 'shared/config/baseUrl';
import {
  ReceiptProvider,
  ReceiptContext,
} from 'views/AccountReceive/common/context/ReceiptContext';
import { Button } from 'shared/components';
import { BillingNotesStatus } from 'views/AccountReceive/common/model/billingNoteStatus.model';
import { ReceiptStatus } from 'views/AccountReceive/common/model/receiptStatus.model';
import BillingNoteTabs from './BillingNoteTabs';
import ReceiptTabs from './ReceiptTabs';
import { Container } from './Styles';
import BillingNotesTable from './BillingNotesTable';
import ReceiptsTable from './ReceiptsTable';

const ReceiptWrapper = () => {
  return (
    <ReceiptProvider>
      <Receipt />
    </ReceiptProvider>
  );
};

const Receipt = () => {
  const [tabBillingNote, setTabBillingNote] = useState<BillingNotesStatus>(
    BillingNotesStatus.BILLING_NOTE,
  );
  const [tabReceipt, setTabReceipt] = useState<ReceiptStatus>(ReceiptStatus.COMPLETE);
  const [tabName, setTabName] = useState<'billingNote' | 'receipt'>('billingNote');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { selectedReceipts, setSelectedReceipts } = useContext(ReceiptContext);

  const confirmCreatePdfZip = () => {
    const receiptsId =
      selectedReceipts.receiptsId.length === 1
        ? [selectedReceipts.receiptsId[0], selectedReceipts.receiptsId[0]]
        : selectedReceipts.receiptsId;
    const url = `${baseUrl}/GetPdfZip/?name=RE&_id=${receiptsId.join('&_id=')}`;
    window.open(url);
    message.success('ดาวน์โหลดสำเร็จแล้ว');
    setSelectedReceipts((prevState) => ({
      ...prevState,
      receiptsId: [],
    }));
  };

  return (
    <>
      <Container>
        <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
          <Col span={12}>
            <Row>
              <Col style={{ marginRight: 20 }}>
                <BillingNoteTabs
                  tab={tabBillingNote}
                  name={tabName}
                  onChange={(value) => {
                    setTabBillingNote(value);
                    setTabName('billingNote');
                    setCurrentPage(1);
                  }}
                />
              </Col>
              <Col>
                <ReceiptTabs
                  tab={tabReceipt}
                  name={tabName}
                  onChange={(value) => {
                    setTabReceipt(value);
                    setTabName('receipt');
                    setCurrentPage(1);
                  }}
                />
              </Col>
            </Row>
          </Col>
          {tabName === 'receipt' && (
            <Col span={12}>
              <Row justify="end">
                <Col>
                  <Button
                    color="green"
                    icon={<FileTextOutlined />}
                    disabled={selectedReceipts.receiptsId.length === 0}
                    onClick={() => {
                      confirmCreatePdfZip();
                    }}
                  >
                    ดาวน์โหลดเอกสาร
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>

        {tabName === 'billingNote' && (
          <BillingNotesTable
            status={tabBillingNote}
            pageSize={pageSize}
            onChangePageSize={(value) => setPageSize(value)}
            currentPage={currentPage}
            onChangeCurrentPage={(value) => setCurrentPage(value)}
          />
        )}

        {tabName === 'receipt' && (
          <ReceiptsTable
            status={tabReceipt}
            pageSize={pageSize}
            onChangePageSize={(value) => setPageSize(value)}
            currentPage={currentPage}
            onChangeCurrentPage={(value) => setCurrentPage(value)}
          />
        )}
      </Container>
    </>
  );
};

export default ReceiptWrapper;
