/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button } from 'shared/components';
import { useHistory } from 'react-router-dom';
import { StyledModal } from './Styles';

type ModalConfirmCancelProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmCancel: React.FC<ModalConfirmCancelProps> = (props) => {
  const { onCancel, visible } = props;
  const history = useHistory();

  return (
    <StyledModal
      className="custom-modal"
      title="ยกเลิกการบันทึกรายการ JV ใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<DeleteOutlined />}
          color="red--outline"
          onClick={() => history.push('/setting/chart-of-accounts/jv')}
        >
          ตกลง
        </Button>,
        <Button key="cancelButton" icon={<CloseCircleOutlined />} onClick={onCancel}>
          ยกเลิก
        </Button>,
      ]}
    />
  );
};

export default ModalConfirmCancel;
