type EnumLiteralsOf<T extends unknown> = T[keyof T];
export const ParcelCostingStatus = Object.freeze({
  WAIT_AGENT_CONFIRM: 1 as const,
  WAITING_CONFIRM: 2 as const,
  CONFIRM: 3 as const,
  REJECT: 4 as const,
  EDIT: 5 as const,
  CANCEL: 6 as const,
});

export type ParcelCostingStatus = EnumLiteralsOf<typeof ParcelCostingStatus>;
