/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { Col, Divider, message, Row, Skeleton, Tabs } from 'antd';
import Icon, { CheckCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { Tag, Text } from 'shared/components';

import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { color } from 'shared/utils/styles';
import IconFileInvoiceDollar from 'shared/assets/icons/file-invoice-dollar.svg';
import IconHandHoldingUSD from 'shared/assets/icons/hand-holding-usd.svg';
import IconChartLine from 'shared/assets/icons/chart-line.svg';
import IconHourGlassHalf from 'shared/assets/icons/hourglass-half.svg';
import { ParcelCostingContext } from 'views/Costing/common/context/ParcelCostingContext';
import PARCEL_COST_QUERY, { ParcelCostData, ParcelCostVars } from 'shared/graphql/query/parcelCost';
import { ParcelCostingStatus } from 'views/Costing/common/model/parcelCostingStatus.model';
import TagStatus from './TagStatus';
import { CardContainer, StyledCardIcon } from './Styles';
import CostingOtherDocument from './CostingOtherDocument';
import ParcelCostingTable from './ParcelCostTable';

const { TabPane } = Tabs;

const CostingDetail: React.FC = () => {
  const { selectedCostingDetail } = useContext(ParcelCostingContext);
  const { costingId } = selectedCostingDetail!;

  const { data } = useQuery<ParcelCostData, ParcelCostVars>(PARCEL_COST_QUERY, {
    variables: {
      _id: costingId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = <Skeleton />;
  if (data && data.parcelCost) {
    const {
      remark,
      vendor,
      employee,
      refNumber,
      createdAt,
      costModel,
      totalCostAvg,
      parcelTarget,
      reject_remark,
      costing_status,
      costing_number,
      edit_request_status,
      total_after_discount,
      totalParcelDelivered,
      totalDocumentParcels,
    } = data.parcelCost;

    content = (
      <>
        <CardContainer>
          <StyledCardIcon
            title="ต้นทุนก่อนภาษี / Cost Before VAT"
            text={<Text customType="danger">{`${formatCurrency(total_after_discount)} บาท`}</Text>}
            icon={<Icon component={IconFileInvoiceDollar} />}
          />

          <StyledCardIcon
            title="จำนวนสินค้า (ส่งสำเร็จ / ทั้งหมด)"
            text={
              <div>
                {`${totalParcelDelivered} / ${totalDocumentParcels} ชิ้น`}{' '}
                <Text style={{ fontSize: 20 }}>{`(${
                  (totalParcelDelivered / totalDocumentParcels) * 100
                }%)`}</Text>
              </div>
            }
            icon={<Icon component={IconHandHoldingUSD} />}
          />

          <StyledCardIcon
            title="ต้นทุนรายชิ้น (เกิดขึ้นจริง / เป้าหมาย)"
            text={`${formatCurrency(totalCostAvg)} / ${formatCurrency(parcelTarget)} บาท`}
            icon={<Icon component={IconChartLine} />}
          />

          <StyledCardIcon
            title="เหลือเวลาในการดำเนินงาน"
            text="-"
            icon={<Icon component={IconHourGlassHalf} />}
          />
        </CardContainer>

        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[32, 16]}>
          <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={8}>
                    ผู้รับเงิน <Text customType="grey">(เจ้าหนี้)</Text>:
                  </Col>
                  <Col span={16}>
                    <Text strong>{vendor.full_name}</Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={8}>ประเภทการจัดจ้าง:</Col>
                  <Col span={16}>
                    <Text strong>
                      {{ trip: 'รายเที่ยว', monthly: 'รายเดือน' }[costModel] || costModel}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={8}>ผู้ส่งราคา:</Col>
                  <Col span={16}>
                    <Text strong>{employee?.full_name || '-'}</Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={8}>หมายเหตุ:</Col>
                  <Col span={16}>
                    <Text strong>{remark || '-'}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={8}>
            <Row>
              <Col span={8}>วันที่ส่งรายการ:</Col>
              <Col span={16}>
                <Text strong>{formatDate(createdAt)}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รหัสต้นทุน/ขาย:</Col>
              <Col span={16}>
                <Text strong>{costing_number}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รหัสงานขนส่ง:</Col>
              <Col span={16}>
                <Text strong>{refNumber}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>สถานะ:</Col>
              <Col span={16}>
                {edit_request_status &&
                  costing_status === ParcelCostingStatus.WAIT_AGENT_CONFIRM && (
                    <Tag customColor="red--light">ยื่นคำร้อง</Tag>
                  )}

                {!edit_request_status || costing_status === ParcelCostingStatus.EDIT ? (
                  <TagStatus status={costing_status} />
                ) : null}
              </Col>
            </Row>

            {costing_status === ParcelCostingStatus.REJECT && (
              <Row>
                <Col span={8}>เหตุผลปฏิเสธ:</Col>
                <Col span={16}>
                  <Text strong>{reject_remark || '-'}</Text>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Divider style={{ margin: 0, marginTop: -5 }} />

        <Tabs defaultActiveKey="1" animated={false} destroyInactiveTabPane>
          <TabPane
            tab={
              <span>
                รายการจ่าย{' '}
                <CheckCircleOutlined style={{ color: `${color.success}`, marginLeft: 5 }} />
              </span>
            }
            key="1"
          >
            <ParcelCostingTable />
          </TabPane>
          <TabPane
            tab={
              <span>
                เอกสารอื่นๆ{' '}
                <CheckCircleOutlined style={{ color: `${color.success}`, marginLeft: 5 }} />
              </span>
            }
            key="2"
          >
            <CostingOtherDocument costingId={costingId} />
          </TabPane>
        </Tabs>
      </>
    );
  }

  return content;
};

export default CostingDetail;
