import React, { ReactChild, useState } from 'react';
import { CostingStatus } from 'views/Costing/common/model/costingStatus.model';

export interface CostingDetailType {
  costingId: string;
  costingNumber: string | null;
  costingStatus: CostingStatus | null;
  isLoading: boolean;
  backUrl: string;
  modalConfirmChecked: {
    costingNumber: string | null;
    numberRef: string | null;
    totalAfterDiscountCost: number;
    totalAfterDiscountSale: number;
    profit: number;
  };
  modalConfirmReject: {
    costingNumber: string | null;
    numberRef: string | null;
    totalAfterDiscountCost: number;
    totalAfterDiscountSale: number;
    profit: number;
  };
  modalConfirmRejectEdit: {
    costingNumber: string | null;
    numberRef: string | null;
    totalAfterDiscountCost: number;
    totalAfterDiscountSale: number;
    profit: number;
  };
  modalConfirmEdit: {
    costingNumber: string | null;
    numberRef: string | null;
    totalAfterDiscountCost: number;
    totalAfterDiscountSale: number;
    profit: number;
  };
  editRequest: boolean;
}

interface Costing {
  selectedCostingDetail: CostingDetailType;
  setSelectedCostingDetail: (params: (cb: CostingDetailType) => CostingDetailType) => void;
}

const CostingContext = React.createContext({} as Costing);

type CostingProps = {
  children: ReactChild;
};

const CostingProvider: React.FC<CostingProps> = (props) => {
  const { children } = props;
  const [selectedCostingDetail, setSelectedCostingDetail] = useState<CostingDetailType>({
    costingId: '',
    costingNumber: null,
    costingStatus: null,
    isLoading: true,
    backUrl: '',
    modalConfirmChecked: {
      costingNumber: null,
      numberRef: null,
      totalAfterDiscountCost: 0,
      totalAfterDiscountSale: 0,
      profit: 0,
    },
    modalConfirmReject: {
      costingNumber: null,
      numberRef: null,
      totalAfterDiscountCost: 0,
      totalAfterDiscountSale: 0,
      profit: 0,
    },
    modalConfirmRejectEdit: {
      costingNumber: null,
      numberRef: null,
      totalAfterDiscountCost: 0,
      totalAfterDiscountSale: 0,
      profit: 0,
    },
    modalConfirmEdit: {
      costingNumber: null,
      numberRef: null,
      totalAfterDiscountCost: 0,
      totalAfterDiscountSale: 0,
      profit: 0,
    },
    editRequest: false,
  });

  const store = {
    selectedCostingDetail,
    setSelectedCostingDetail,
  };

  return <CostingContext.Provider value={store}>{children}</CostingContext.Provider>;
};

export { CostingContext, CostingProvider };
