import React, { useState, ReactChild } from 'react';
import { ReceiptStatus } from 'views/AccountReceive/common/model/receiptStatus.model';
import { BillingNotesStatus } from 'views/AccountReceive/common/model/billingNoteStatus.model';

export interface SelectedReceiptsType {
  receiptsId: string[];
  isOpenModal: boolean;
  isLoading: boolean;
}

export interface SelectedReceiptDetailType {
  receiptId: string;
  isOpenModal: boolean;
  isLoading: boolean;
  receiptNumber: string;
  receiptStatus: ReceiptStatus | null;
  invoiceId: string;
  document: string;
  systemRequest: string;
  totalReceipt: number;
}

export interface SelectedBillingNoteDetailType {
  billingNoteId: string;
  billNumber: string;
  billStatus: BillingNotesStatus | null;
  isOpenModal: boolean;
  isLoading: boolean;
  payerId: string;
  payerFullName: string;
  serviceDate: string;
  grandTotalPrice: number;
  unPaid: number;
  document: string;
}

interface Receipt {
  selectedReceipts: SelectedReceiptsType;
  setSelectedReceipts: (params: (cb: SelectedReceiptsType) => SelectedReceiptsType) => void;
  selectedReceiptDetail: SelectedReceiptDetailType;
  setSelectedReceiptDetail: (
    params: (cb: SelectedReceiptDetailType) => SelectedReceiptDetailType,
  ) => void;
  selectedBillingNoteDetail: SelectedBillingNoteDetailType;
  setSelectedBillingNoteDetail: (
    params: (cb: SelectedBillingNoteDetailType) => SelectedBillingNoteDetailType,
  ) => void;
}

type ReceiptProps = {
  children: ReactChild;
};

const ReceiptContext = React.createContext({} as Receipt);

const ReceiptProvider: React.FC<ReceiptProps> = (props) => {
  const { children } = props;
  const [selectedReceipts, setSelectedReceipts] = useState<SelectedReceiptsType>({
    receiptsId: [],
    isLoading: true,
    isOpenModal: false,
  });
  const [selectedReceiptDetail, setSelectedReceiptDetail] = useState<SelectedReceiptDetailType>({
    receiptId: '',
    isOpenModal: false,
    isLoading: true,
    receiptNumber: '',
    receiptStatus: null,
    invoiceId: '',
    document: '',
    systemRequest: '',
    totalReceipt: 0,
  });

  const [selectedBillingNoteDetail, setSelectedBillingNoteDetail] = useState<
    SelectedBillingNoteDetailType
  >({
    billingNoteId: '',
    billNumber: '',
    billStatus: null,
    isOpenModal: false,
    isLoading: true,
    payerId: '',
    payerFullName: '',
    serviceDate: '',
    grandTotalPrice: 0,
    unPaid: 0,
    document: '',
  });

  const store = {
    selectedReceipts,
    setSelectedReceipts,
    selectedReceiptDetail,
    setSelectedReceiptDetail,
    selectedBillingNoteDetail,
    setSelectedBillingNoteDetail,
  };

  return <ReceiptContext.Provider value={store}>{children}</ReceiptContext.Provider>;
};

export { ReceiptContext, ReceiptProvider };
