/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Select, Typography, Input, Button, DatePicker, message } from 'antd';
import { FileTextOutlined, SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { formatDate, formatDateTime } from 'shared/utils/dateTime';
import baseUrl from 'shared/config/baseUrl';
import _ from 'lodash';
import updateObject from 'shared/utils/updateObject';
import formatCurrency from 'shared/utils/formatCurrency';
import PAYMENTS_QUERY from 'shared/graphql/query/payments';
import {
  PaymentOrderProvider,
  PaymentOrderContext,
} from 'views/AccountPayment/common/context/PaymentOrderContext';
import moment from 'moment';
import { TableSearch, SelectPayeesField, SelectPaymentTypeField } from 'shared/components';
import { PaymentStatus } from 'views/AccountPayment/common/model/paymentStatus.model';
import PaymentOrderSelected from './PaymentOrderSelected';
import PaymentOrderTabs from './PaymentOrderTabs';
import SelectPaymentVouchersField from './SelectPaymentVouchersField';
import SelectEmployeeField from './SelectEmployeeField';
import { Container, StyledButton, StyledCheckbox, ToolsContainer, StyledModal } from './Styles';
import TagSaveStatus from './TagSaveStatus';
import ModalCreatePaymentOrder from './ModalCreatePaymentOrder';
import ModalDetailPaymentOrder from './ModalDetailPaymentOrder';
import ModalCreatePaymentInsurance from './ModalCreatePaymentInsurance';

const { Option } = Select;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const PaymentOrderWrapper = () => {
  return (
    <PaymentOrderProvider>
      <PaymentOrder />
    </PaymentOrderProvider>
  );
};

const PaymentOrder = () => {
  const [tab, setTab] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentNumber, setPaymentNumber] = useState('');
  const [serviceDate, setServiceDate] = useState('');
  const [createDate, setCreateDate] = useState('');
  const [roundDate, setRoundDate] = useState('');
  const [vendorId, setVendorId] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [paymentVoucherId, setPaymentVoucherId] = useState('');
  const [numberRef, setNumberRef] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [orderType, setOrderType] = useState('');

  const {
    selectedPayments: selectedPaymentsContext,
    selectedPaymentDetail: selectedPaymentDetailContext,
    createPaymentOrder: createPaymentOrderContext,
    createPaymentInsurance: createPaymentInsuranceContext,
  } = useContext(PaymentOrderContext);
  const [selectedPayments, setSelectedPayments] = selectedPaymentsContext;
  const [selectedPaymentDetail, setSelectedPaymentDetail] = selectedPaymentDetailContext;
  const [createPaymentOrder, setCreatePaymentOrder] = createPaymentOrderContext;
  const [createPaymentInsurance, setCreatePaymentInsurance] = createPaymentInsuranceContext;

  useEffect(() => {
    if (selectedPayments.payments.length === 0) {
      setSelectedPayments((prevState) => ({
        ...prevState,
        payeeId: '',
        dueDate: '',
        paymentType: '',
        payeeFullName: '',
      }));
    }
  }, [selectedPayments.payments.length, setSelectedPayments]);

  const confirmCreatePdfZip = () => {
    const selectedPaymentsId = selectedPayments.payments.map((item) => item.id);
    const paymentsId =
      selectedPaymentsId.length === 1
        ? [selectedPaymentsId[0], selectedPaymentsId[0]]
        : selectedPaymentsId;
    const url = `${baseUrl}/GetPdfZip/?name=AP&_id=${paymentsId.join('&_id=')}`;
    window.open(url);
    message.success('ดาวน์โหลดสำเร็จแล้ว');
    setSelectedPayments((prevState) => ({
      ...prevState,
      payments: [],
    }));
  };

  const { loading, data } = useQuery(PAYMENTS_QUERY, {
    variables: {
      payment_number: paymentNumber,
      service_date: serviceDate,
      create_date: createDate,
      payee: vendorId,
      employee: employeeId,
      payment_voucher: paymentVoucherId,
      round_paid: roundDate,
      page: currentPage,
      showData: pageSize,
      payment_status: tab,
      number_ref: numberRef,
      order_by: orderBy,
      order_type: orderType,
      payment_type: paymentType,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const onCheckAllChange = (check) => {
    if (data) {
      const selectedPaymentId = selectedPayments.payments.map((item) => item);
      const currentPaymentId = data.payments.payments.map((item) => ({
        id: item._id,
        paymentNumber: item.payment_number,
      }));
      const differencePayment = _.differenceWith(currentPaymentId, selectedPaymentId, _.isEqual);

      if (check) {
        setSelectedPayments((prevState) => ({
          ...prevState,
          payments: [...selectedPaymentId, ...differencePayment],
        }));
      }

      if (!check) {
        const filterPaymentId = selectedPaymentId.filter(
          (item) => !currentPaymentId.map((payment) => payment.id).includes(item.id),
        );
        setSelectedPayments((prevState) => ({
          ...prevState,
          payments: filterPaymentId,
        }));
      }
    }
  };

  const selectPayment = (item, checked) => {
    if (checked) {
      if (selectedPayments.payments.length === 0) {
        setSelectedPayments((prevState) => ({
          ...prevState,
          payeeId: item.payee._id,
          paymentType: item.payment_type,
          dueDate: item.round_paid,
          payeeFullName: item.payee.full_name,
        }));
      }
      setSelectedPayments((prevState) => ({
        ...prevState,
        payments: [...prevState.payments, { id: item._id, paymentNumber: item.payment_number }],
      }));
    } else {
      const paymentsFilter = selectedPayments.payments.filter((payment) => payment.id !== item._id);
      setSelectedPayments((prevState) => ({
        ...prevState,
        payments: [...paymentsFilter],
      }));
    }
  };

  const filterPaymentSelect = (
    payeeIdItem,
    selectPayeeIdItem,
    paymentTypeItem,
    selectPaymentTypeItem,
  ) => {
    if (payeeIdItem === selectPayeeIdItem && paymentTypeItem === selectPaymentTypeItem) {
      return false;
    }
    return true;
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
      search: (tab === PaymentStatus.COMPLETE || tab === PaymentStatus.CANCEL) && (
        <StyledCheckbox
          onChange={(event) => onCheckAllChange(event.target.checked)}
          checked={data?.payments.payments.every(
            (item) =>
              selectedPayments.payments.map((payment) => payment.id).indexOf(item._id) !== -1,
          )}
          value={selectedPayments.payments}
        />
      ),
    },
    {
      title: 'วันที่เอกสาร',
      dataIndex: 'serviceDate',
      sortName: 'service_date',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) =>
            values === null
              ? setServiceDate('')
              : setServiceDate(
                  `${values[0].format('YYYY-MM-DD')}:${values[1].format('YYYY-MM-DD')}`,
                )
          }
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
          ranges={{
            วันนี้: [moment(), moment()],
            สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
            เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
            ปีนี้: [moment().startOf('year'), moment().endOf('year')],
          }}
        />
      ),
    },
    {
      title: 'รหัสใบสั่งจ่าย',
      dataIndex: 'paymentNumber',
      sortName: 'payment_number',
      width: 150,
      search: (
        <Input
          allowClear
          value={paymentNumber}
          placeholder="ค้นหา"
          onChange={(event) => setPaymentNumber(event.target.value)}
        />
      ),
    },
    ...(tab === 2 || tab === 3
      ? [
          {
            title: 'สถานะ',
            dataIndex: 'saveStatus',
            width: 50,
          },
        ]
      : []),
    {
      title: 'จำนวนตั้งเบิก (บาท)',
      dataIndex: 'totalPrice',
      width: 150,
      align: 'right',
    },
    {
      title: 'ผู้รับเงิน (เจ้าหนี้)',
      dataIndex: 'payeeFullName',
      width: 300,
      search: (
        <SelectPayeesField
          onChange={(value) => (value ? setVendorId(value.payeeId) : setVendorId(undefined))}
        />
      ),
    },
    {
      title: 'รอบจ่าย',
      dataIndex: 'roundPaid',
      sortName: 'round_paid',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) =>
            values === null
              ? setRoundDate('')
              : setRoundDate(`${values[0].format('YYYY-MM-DD')}:${values[1].format('YYYY-MM-DD')}`)
          }
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
          ranges={{
            วันนี้: [moment(), moment()],
            สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
            เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
            ปีนี้: [moment().startOf('year'), moment().endOf('year')],
          }}
        />
      ),
    },
    {
      title: 'ประเภทการจ่าย',
      dataIndex: 'paymentType',
      width: 150,
      search: (
        <SelectPaymentTypeField onChange={(value) => setPaymentType(value)} placeholder="ค้นหา" />
      ),
    },
    {
      title: 'ผู้ตั้งเบิก',
      dataIndex: 'employeeFullName',
      width: 250,
      search: <SelectEmployeeField onChange={(value) => setEmployeeId(value)} />,
    },
    {
      title: 'รหัสใบสำคัญจ่าย',
      dataIndex: 'voucherNumber',
      width: 170,
      search: <SelectPaymentVouchersField onChange={(value) => setPaymentVoucherId(value)} />,
    },
    {
      title: 'รหัส Shipment/JOB/Booking',
      dataIndex: 'numberRef',
      sortName: 'number_ref',
      width: 210,
      search: (
        <Input
          allowClear
          value={numberRef}
          placeholder="ค้นหา"
          onChange={(event) => setNumberRef(event.target.value)}
        />
      ),
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'createDate',
      sortName: 'create_date',
      width: 200,
      search: (
        <RangePicker
          onChange={(values) =>
            values === null
              ? setCreateDate('')
              : setCreateDate(`${values[0].format('YYYY-MM-DD')}:${values[1].format('YYYY-MM-DD')}`)
          }
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
          ranges={{
            วันนี้: [moment(), moment()],
            สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
            เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
            ปีนี้: [moment().startOf('year'), moment().endOf('year')],
          }}
        />
      ),
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'lastUpdate',
      sortName: 'last_update',
      width: 150,
    },
  ];

  let paymentsData = [];
  let totalDocument = 0;

  if (data && data.payments) {
    const { payments } = data.payments;
    paymentsData = payments.map((item, index) => ({
      key: index,
      tools: (
        <ToolsContainer>
          {(item.payment_status === PaymentStatus.PENDING ||
            item.payment_status === PaymentStatus.COMPLETE ||
            item.payment_status === PaymentStatus.CANCEL) && (
            <StyledCheckbox
              checked={
                selectedPayments.payments.map((payment) => payment.id).indexOf(item._id) !== -1
              }
              disabled={
                selectedPayments.payments.length > 0 &&
                item.payment_status === PaymentStatus.PENDING &&
                filterPaymentSelect(
                  item.payee._id,
                  selectedPayments.payeeId,
                  item.payment_type,
                  selectedPayments.paymentType,
                )
              }
              value={item._id}
              onChange={(event) => selectPayment(item, event.target.checked)}
            />
          )}
          <Button
            onClick={() => {
              setSelectedPaymentDetail((prevState) => ({
                ...prevState,
                paymentId: item._id,
                isOpenModal: true,
                paymentNumber: item.payment_number,
              }));
            }}
            type="grey--text-only"
            icon={<SearchOutlined />}
            size="small"
            style={{ marginRight: 10 }}
          />
          <Button
            disabled={!item.document}
            href={item.document}
            target="_blank"
            type="grey--text-only"
            icon={<FileTextOutlined />}
            size="small"
          />
        </ToolsContainer>
      ),
      serviceDate: formatDate(item.service_date),
      paymentNumber: item.payment_number,
      saveStatus: (
        <TagSaveStatus
          status={!item.save_status ? 1 : item.save_status}
          style={{ width: '100%', textAlign: 'center' }}
        />
      ),
      totalPrice: (
        <Text strong type="primary">
          {formatCurrency(item.total_price)}
        </Text>
      ),
      payeeFullName: item.payee.full_name,
      roundPaid: formatDate(item.round_paid),
      paymentType: item.payment_type || '-',
      employeeFullName: item.employee.full_name,
      voucherNumber: item.payment_voucher?.voucher_number || '-',
      numberRef: item.number_ref,
      createDate: formatDateTime(item.create_date),
      lastUpdate: formatDateTime(item.last_update),
    }));

    totalDocument = data.payments.totalDocument;
  }

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={16}>
          <PaymentOrderTabs
            tab={tab}
            onChange={(value) => {
              setSelectedPayments((prevState) => ({
                ...prevState,
                payments: [],
                payeeId: '',
                paymentType: '',
                payeeFullName: '',
                dueDate: '',
              }));

              setTab(value);
              setCurrentPage(1);
            }}
          />
        </Col>
        <Col span={8}>
          <Row justify="end">
            {tab === 1 && (
              <Col style={{ marginRight: 10 }}>
                <StyledButton
                  onClick={() => {
                    setCreatePaymentOrder((prevState) => ({
                      ...prevState,
                      isOpenModal: true,
                    }));
                  }}
                  type="blue--light"
                >
                  สร้างใบสั่งจ่าย
                </StyledButton>
              </Col>
            )}
            {tab === PaymentStatus.PENDING && (
              <Col style={{ marginRight: 10 }}>
                <StyledButton
                  onClick={() => {
                    setSelectedPayments((prevState) => ({
                      ...prevState,
                      isOpenModal: true,
                    }));
                  }}
                  disabled={selectedPayments.payments.length === 0 || tab !== PaymentStatus.PENDING}
                  type="blue--light"
                >
                  สร้างใบสำคัญจ่าย
                </StyledButton>
              </Col>
            )}
            {tab === PaymentStatus.REVIEW && (
              <Col style={{ marginRight: 10 }}>
                <StyledButton
                  onClick={() => {
                    setCreatePaymentInsurance((prevState) => ({
                      ...prevState,
                      isOpenModal: true,
                    }));
                  }}
                  type="blue--light"
                >
                  สร้างใบสั่งจ่ายค่าประกัน
                </StyledButton>
              </Col>
            )}
            {(tab === PaymentStatus.COMPLETE || tab === PaymentStatus.CANCEL) && (
              <Col>
                <StyledButton
                  type="green"
                  icon={<FileTextOutlined />}
                  onClick={() => {
                    confirmCreatePdfZip();
                  }}
                  disabled={selectedPayments.payments.length === 0}
                >
                  ดาวน์โหลดเอกสาร
                </StyledButton>
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              setCurrentPage(1);
              setPageSize(value);
            }}
            defaultValue={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={paymentsData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value) => setCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />

      <StyledModal
        visible={selectedPayments.isOpenModal}
        onCancel={() => {
          setSelectedPayments((prevState) => updateObject(prevState, { isOpenModal: false }));
        }}
        closable={false}
        className="custom-modal"
        footer={null}
        destroyOnClose
      >
        <PaymentOrderSelected />
      </StyledModal>

      <ModalCreatePaymentOrder />
      <ModalDetailPaymentOrder />
      <ModalCreatePaymentInsurance />
    </Container>
  );
};

export default PaymentOrderWrapper;
