/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { Button, Text, Spin } from 'shared/components';
import BILLINGNOTE_QUERY, {
  BillingNoteData,
  BillingNoteVars,
  InvoiceDetail,
} from 'shared/graphql/query/billingNote';

import { v4 as uuidv4 } from 'uuid';
import { ReceiptContext } from 'views/AccountReceive/common/context/ReceiptContext';
import { StyledTable } from './Styles';
import ExpandedRow from './ExpandedRow';

type InvoiceDataTable = {
  key: string;
  tools: ReactNode;
  column1: ReactNode;
  column2: ReactNode;
  column3: ReactNode;
  InvoiceDetail: InvoiceDetail[];
};

const columns = [
  {
    title: 'เครื่องมือ',
    dataIndex: 'tools',
    width: 70,
  },
  {
    title: 'วันที่เอกสาร',
    dataIndex: 'column1',
    width: 110,
  },
  {
    title: 'รหัสใบแจ้งหนี้',
    dataIndex: 'column2',
    width: 110,
  },
  {
    title: 'มูลค่าใบแจ้งหนี้',
    dataIndex: 'column3',
    width: 110,
    align: 'right',
  },
  {
    title: '',
  },
];

const BillingNoteDetailTable: React.FC = () => {
  const { selectedBillingNoteDetail } = useContext(ReceiptContext);
  const { billingNoteId } = selectedBillingNoteDetail;
  const { error, data, loading } = useQuery<BillingNoteData, BillingNoteVars>(BILLINGNOTE_QUERY, {
    variables: {
      _id: billingNoteId,
    },
    fetchPolicy: 'cache-and-network',
  });

  let content: ReactNode = <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  let billingNoteData: InvoiceDataTable[] = [];
  if (data && data.billingNote) {
    const { bill_invoice } = data.billingNote;

    billingNoteData = bill_invoice.map(
      (item): InvoiceDataTable => {
        const {
          invoice: { service_date, invoice_number, grand_total_price, invoice_detail },
        } = item;

        return {
          key: uuidv4(),
          tools: (
            <Button
              disabled
              title="ลบ"
              color="red--outline"
              icon={<DeleteOutlined />}
              size="small"
            />
          ),
          column1: service_date ? formatDate(service_date) : '-',
          column2: invoice_number || '-',
          column3: grand_total_price ? (
            <Text strong customType="primary">
              {formatCurrency(grand_total_price)}
            </Text>
          ) : (
            '-'
          ),
          InvoiceDetail: invoice_detail,
        };
      },
    );

    content = (
      <>
        <StyledTable
          pagination={false}
          columns={columns}
          dataSource={billingNoteData}
          size="small"
          loading={loading}
          expandable={{
            expandedRowRender: (record: { InvoiceDetail: InvoiceDetail[] }) => (
              <ExpandedRow data={record.InvoiceDetail} />
            ),
          }}
        />
      </>
    );
  }

  if (error) {
    content = error.message;
  }

  return <div>{content}</div>;
};

export default BillingNoteDetailTable;
