import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import { Text } from 'shared/components';
import { DebtTrackerContext } from 'views/DebtTracker/common/context/DebtTrackerContext';
import { ContainerTitle, ContainerDetail } from './Styles';
import DebtTrackerHistoryDetail from './DebtTrackerHistoryDetail';
import DebtTrackerHistoryForm from './DebtTrackerHistoryForm';

const DebtTrackerHistory: React.FC = () => {
  const { debtTrackerHistoryValue } = useContext(DebtTrackerContext);

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <ContainerTitle color="#2C6BAE">
            <Text style={{ color: '#FFFFFF' }}>ประวัติการตามหนี้</Text>
          </ContainerTitle>
        </Col>
        {debtTrackerHistoryValue.isShowForm && (
          <Col span={12}>
            <ContainerTitle color="#EAF1F7">
              <Text style={{ color: '#2C6BAE' }}>บันทึกการติดตามหนี้</Text>
            </ContainerTitle>
          </Col>
        )}
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <ContainerDetail>
            <DebtTrackerHistoryDetail />
          </ContainerDetail>
        </Col>
        {debtTrackerHistoryValue.isShowForm && (
          <Col span={12}>
            <ContainerDetail>
              <DebtTrackerHistoryForm />
            </ContainerDetail>
          </Col>
        )}
      </Row>
    </>
  );
};

export default DebtTrackerHistory;
