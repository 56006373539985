/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { Row, Col, Input, Form, DatePicker, Select, Tooltip } from 'antd';
import { Text } from 'shared/components';
import moment from 'moment';
import { useFormContext, Controller } from 'react-hook-form';
import { DebtTrackerContext } from 'views/DebtTracker/common/context/DebtTrackerContext';
import { FormValues } from 'views/DebtTracker/Routes/DebtTrackerDetail/ModalDebtTrackerHistory/DebtTrackerHistory/DebtTrackerHistoryForm/formValues.model';

const dateFormat = 'YYYY/MM/DD';
const { Option } = Select;
const { TextArea } = Input;

const InputDebtTrackerHistoryForm: React.FC = () => {
  const { debtTrackerHistoryValue, selectedDebtTrackerHistory } = useContext(DebtTrackerContext);

  const { control, errors, watch, setValue, trigger } = useFormContext<FormValues>();

  const watchFollowStatus = watch('followStatus');
  const watchAction = watch('action');

  return (
    <>
      <Row>
        <Col span={24}>
          {!selectedDebtTrackerHistory.isEditDebtTrackerHistory ? (
            <Text strong>
              บันทึกการติดตามหนี้ ครั้งที่&nbsp;
              {debtTrackerHistoryValue.followDetailAmount > 0
                ? debtTrackerHistoryValue.followDetailAmount + 1
                : 1}
            </Text>
          ) : (
            <Text strong>
              แก้ไขบันทึกการติดตามหนี้ ครั้งที่ {debtTrackerHistoryValue.followDetailAmount}
            </Text>
          )}
        </Col>
      </Row>
      <Row gutter={[8, 0]}>
        <Col span={12}>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Text strong>
                    สถานะการติดต่อ<Text customType="danger">*</Text>:
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    validateStatus={errors.followStatus && 'error'}
                    help={errors.followStatus ? errors.followStatus.message : undefined}
                    style={{ marginBottom: 10 }}
                  >
                    <Controller
                      control={control}
                      name="followStatus"
                      render={({ onChange, value }) => (
                        <Select
                          value={value || undefined}
                          onChange={(valueOnChange) => {
                            onChange(valueOnChange);
                            if (valueOnChange !== 'อื่นๆ') {
                              setValue('followStatusDetail', valueOnChange);
                            } else {
                              setValue('followStatusDetail', '');
                            }
                            if (valueOnChange === 'ติดต่อไม่ได้') {
                              setValue('action', 'ระงับการใช้งาน');
                              setValue('actionDetail', 'ระงับการใช้งาน');
                            }
                            if (valueOnChange === 'อยู่ระหว่างการตรวจสอบของลูกค้า') {
                              setValue('action', 'โทรสอบถามความคืบหน้าการชำระเงิน');
                              setValue('actionDetail', 'โทรสอบถามความคืบหน้าการชำระเงิน');
                            }
                            if (valueOnChange === 'ให้ติดต่อกลับมาใหม่') {
                              setValue('action', 'ติดต่อลูกค้ากลับภายหลัง ภายใน 2 วันทำการ');
                              setValue('actionDetail', 'ติดต่อลูกค้ากลับภายหลัง ภายใน 2 วันทำการ');
                            }
                            if (valueOnChange === 'แจ้งจะติดต่อกลับ') {
                              setValue(
                                'action',
                                'ถ้าลูกค้าไม่ติดต่อกลับ จะต้องติดต่อลูกค้ากลับ ภายใน 2 วันทำการ',
                              );
                              setValue(
                                'actionDetail',
                                'ถ้าลูกค้าไม่ติดต่อกลับ จะต้องติดต่อลูกค้ากลับ ภายใน 2 วันทำการ',
                              );
                            }
                            if (valueOnChange === 'ชำระเกิน') {
                              setValue('action', 'เปิด Card ใน Clickup และใส่ยอดที่ชำระเกินไว้');
                              setValue(
                                'actionDetail',
                                'เปิด Card ใน Clickup และใส่ยอดที่ชำระเกินไว้',
                              );
                            }
                            if (valueOnChange === 'ชำระขาด') {
                              setValue('action', 'เปิด Card ใน Clickup และใส่ยอดที่ชำระขาดไว้');
                              setValue(
                                'actionDetail',
                                'เปิด Card ใน Clickup และใส่ยอดที่ชำระขาดไว้',
                              );
                            }
                            if (valueOnChange === 'ขอผ่อนชำระ') {
                              setValue(
                                'action',
                                'แจ้งให้ลูกค้าทำหนังสือฯ หรือส่งอีเมลขอผ่อนชำระ พร้อมระงับการใช้งานจนกว่าลูกค้าจะชำระครบ',
                              );
                              setValue(
                                'actionDetail',
                                'แจ้งให้ลูกค้าทำหนังสือฯ หรือส่งอีเมลขอผ่อนชำระ พร้อมระงับการใช้งานจนกว่าลูกค้าจะชำระครบ',
                              );
                            }
                            if (valueOnChange === 'ไม่มีกำหนดชำระ') {
                              setValue('action', 'ระงับการใช้งาน');
                              setValue('actionDetail', 'ระงับการใช้งาน');
                            }
                            if (valueOnChange === 'นัดชำระแล้ว') {
                              setValue('action', 'รอตรวจสอบยอดชำระ');
                              setValue('actionDetail', 'รอตรวจสอบยอดชำระ');
                            }
                            if (valueOnChange === 'รอการชำระตาม Due') {
                              setValue('action', 'รอตรวจสอบยอดชำระ');
                              setValue('actionDetail', 'รอตรวจสอบยอดชำระ');
                            }
                            if (valueOnChange === 'ออก Credit Note ให้ลูกค้า') {
                              setValue('action', 'ออก Credit Note เรียบร้อยแล้ว');
                              setValue('actionDetail', 'ออก Credit Note เรียบร้อยแล้ว');
                            }
                            if (valueOnChange === 'Shipment มีปัญหาทางด้านการขนส่ง') {
                              setValue(
                                'action',
                                'ส่งเรื่องให้แผนกที่เกี่ยวข้องตรวจสอบ และชี้แจงกับลูกค้า แต่ถ้าหากลูกค้าไม่ได้ใช้จริง ก็จัดทำ Credit Note ให้ลูกค้า',
                              );
                              setValue(
                                'actionDetail',
                                'ส่งเรื่องให้แผนกที่เกี่ยวข้องตรวจสอบ และชี้แจงกับลูกค้า แต่ถ้าหากลูกค้าไม่ได้ใช้จริง ก็จัดทำ Credit Note ให้ลูกค้า',
                              );
                            }
                            if (valueOnChange === 'พึ่งวางบิล') {
                              setValue('action', 'โทรยืนยันการชำระเงินกับทางลูกค้า');
                              setValue('actionDetail', 'โทรยืนยันการชำระเงินกับทางลูกค้า');
                            }
                            if (valueOnChange === 'ยังไม่ได้วางบิล(วางบิลตอนสิ้นเดือน)') {
                              setValue(
                                'action',
                                'ส่งอีเมล์พร้อมเอกสารฉบับจริงให้ลูกค้าเมือถึงตอนสิ้นเดือน',
                              );
                              setValue(
                                'actionDetail',
                                'ส่งอีเมล์พร้อมเอกสารฉบับจริงให้ลูกค้าเมือถึงตอนสิ้นเดือน',
                              );
                            }
                            if (valueOnChange === 'รอวาง PO เพื่อวางบิลในระบบลูกค้า') {
                              setValue(
                                'action',
                                'สอบถามใบรับวางบิลจากลูกค้าเพื่อจะได้ PO แนบวางบิลในระบบ',
                              );
                              setValue(
                                'actionDetail',
                                'สอบถามใบรับวางบิลจากลูกค้าเพื่อจะได้ PO แนบวางบิลในระบบ',
                              );
                            }
                            if (valueOnChange === 'แบบร่างใบแจ้งหนี้ ยังไม่ได้วางบิลลูกค้า') {
                              setValue('action', 'รอสร้างใบแจ้งหนี้เพื่อวางบิลกับลูกค้า');
                              setValue('actionDetail', 'รอสร้างใบแจ้งหนี้เพื่อวางบิลกับลูกค้า');
                            }
                            if (valueOnChange === 'เป็นการออก Invoice ค่า claim') {
                              setValue(
                                'action',
                                'สอบถามรอบจ่ายกับเจ้าหน้าที่ที่เกี่ยวข้องและติดตามทวงถามรอบชำระ',
                              );
                              setValue(
                                'actionDetail',
                                'สอบถามรอบจ่ายกับเจ้าหน้าที่ที่เกี่ยวข้องและติดตามทวงถามรอบชำระ',
                              );
                            }
                            if (valueOnChange === 'ออกใบเสร็จเพื่อไปวางบิล/รับเช็ค') {
                              setValue('action', 'รอตรวจสอบยอดชำระ');
                              setValue('actionDetail', 'รอตรวจสอบยอดชำระ');
                            }
                            if (valueOnChange === 'รับเช็คแล้วแต่ยังไม่ได้ฝาก') {
                              setValue('action', 'รอตรวจสอบยอดเงินเข้า');
                              setValue('actionDetail', 'รอตรวจสอบยอดเงินเข้า');
                            }
                            if (valueOnChange === 'วางบิลลูกหนี้พนักงาน (อื่นๆ)') {
                              setValue(
                                'action',
                                'ให้แจ้งยอดกับ HR เพื่อหักเงินจากเงินเดือนพนักงาน หรือติดตามทวงถามให้ชำระ',
                              );
                              setValue(
                                'actionDetail',
                                'ให้แจ้งยอดกับ HR เพื่อหักเงินจากเงินเดือนพนักงาน หรือติดตามทวงถามให้ชำระ',
                              );
                            }
                            if (valueOnChange === 'เลือกชำระ') {
                              setValue(
                                'action',
                                'โทรสอบถามรายละเอียดเกี่ยวกับ Invoice ที่ลูกค้าชำระ และสอบถามวันที่ลูกค้าจะชำระเข้ามาให้ชัดเจน',
                              );
                              setValue(
                                'actionDetail',
                                'โทรสอบถามรายละเอียดเกี่ยวกับ Invoice ที่ลูกค้าชำระ และสอบถามวันที่ลูกค้าจะชำระเข้ามาให้ชัดเจน',
                              );
                            }
                            if (valueOnChange === 'ไม่รับสาย') {
                              setValue('action', 'โทรหาลูกค้าวันถัดไป');
                              setValue('actionDetail', 'โทรหาลูกค้าวันถัดไป');
                            }
                            if (valueOnChange === 'อื่นๆ') {
                              setValue('action', 'อื่นๆ');
                              setValue('actionDetail', '');
                            }
                            trigger(['action']);
                          }}
                          style={{ width: '100%' }}
                          placeholder="เลือก"
                        >
                          <Option value="ติดต่อไม่ได้">ติดต่อไม่ได้</Option>
                          <Option value="อยู่ระหว่างการตรวจสอบของลูกค้า">
                            อยู่ระหว่างการตรวจสอบของลูกค้า
                          </Option>
                          <Option value="ให้ติดต่อกลับมาใหม่">ให้ติดต่อกลับมาใหม่</Option>
                          <Option value="แจ้งจะติดต่อกลับ">แจ้งจะติดต่อกลับ</Option>
                          <Option value="ชำระเกิน">ชำระเกิน</Option>
                          <Option value="ชำระขาด">ชำระขาด</Option>
                          <Option value="ขอผ่อนชำระ">ขอผ่อนชำระ</Option>
                          <Option value="ไม่มีกำหนดชำระ">ไม่มีกำหนดชำระ</Option>
                          <Option value="นัดชำระแล้ว">นัดชำระแล้ว</Option>
                          <Option value="รอการชำระตาม Due">รอการชำระตาม Due</Option>
                          <Option value="ออก Credit Note ให้ลูกค้า">
                            ออก Credit Note ให้ลูกค้า
                          </Option>
                          <Option value="Shipment มีปัญหาทางด้านการขนส่ง">
                            Shipment มีปัญหาทางด้านการขนส่ง
                          </Option>
                          <Option value="พึ่งวางบิล">พึ่งวางบิล</Option>
                          <Option value="ยังไม่ได้วางบิล(วางบิลตอนสิ้นเดือน)">
                            ยังไม่ได้วางบิล(วางบิลตอนสิ้นเดือน)
                          </Option>
                          <Option value="รอวาง PO เพื่อวางบิลในระบบลูกค้า">
                            รอวาง PO เพื่อวางบิลในระบบลูกค้า
                          </Option>
                          <Option value="แบบร่างใบแจ้งหนี้ ยังไม่ได้วางบิลลูกค้า">
                            แบบร่างใบแจ้งหนี้ ยังไม่ได้วางบิลลูกค้า
                          </Option>
                          <Option value="เป็นการออก Invoice ค่า claim">
                            เป็นการออก Invoice ค่า claim
                          </Option>
                          <Option value="ออกใบเสร็จเพื่อไปวางบิล/รับเช็ค">
                            ออกใบเสร็จเพื่อไปวางบิล/รับเช็ค
                          </Option>
                          <Option value="รับเช็คแล้วแต่ยังไม่ได้ฝาก">
                            รับเช็คแล้วแต่ยังไม่ได้ฝาก
                          </Option>
                          <Option value="วางบิลลูกหนี้พนักงาน (อื่นๆ)">
                            วางบิลลูกหนี้พนักงาน (อื่นๆ)
                          </Option>
                          <Option value="เลือกชำระ">เลือกชำระ</Option>
                          <Option value="ไม่รับสาย">ไม่รับสาย</Option>
                          <Option value="อื่นๆ">อื่นๆ</Option>
                        </Select>
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {watchFollowStatus === 'อื่นๆ' && (
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Text strong>
                      ระบุเพิ่มเติม<Text customType="danger">*</Text>:
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      validateStatus={errors.followStatusDetail && 'error'}
                      help={
                        errors.followStatusDetail ? errors.followStatusDetail.message : undefined
                      }
                      style={{ marginBottom: 10 }}
                    >
                      <Controller
                        control={control}
                        name="followStatusDetail"
                        render={({ onChange, value }) => (
                          <Input
                            value={value || undefined}
                            onChange={(valueOnChange) => onChange(valueOnChange)}
                            style={{ width: '100%' }}
                            placeholder="ระบุ"
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Text strong>
                    การกระทำ<Text customType="danger">*</Text>:
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    validateStatus={errors.action && 'error'}
                    help={errors.action ? errors.action.message : undefined}
                    style={{ marginBottom: 10 }}
                  >
                    <Controller
                      control={control}
                      name="action"
                      render={({ onChange, value }) => (
                        <Select
                          value={value || undefined}
                          onChange={(valueOnChange) => {
                            onChange(valueOnChange);
                            if (valueOnChange !== 'อื่นๆ') {
                              setValue('actionDetail', valueOnChange);
                            } else {
                              setValue('actionDetail', '');
                            }
                          }}
                          style={{ width: '100%' }}
                          placeholder="เลือก"
                          disabled={!watchFollowStatus}
                        >
                          <Option value="ระงับการใช้งาน">ระงับการใช้งาน</Option>
                          <Option value="โทรสอบถามความคืบหน้าการชำระเงิน">
                            โทรสอบถามความคืบหน้าการชำระเงิน
                          </Option>
                          <Option value="ติดต่อลูกค้ากลับภายหลัง ภายใน 2 วันทำการ">
                            ติดต่อลูกค้ากลับภายหลัง ภายใน 2 วันทำการ
                          </Option>
                          <Option value="ถ้าลูกค้าไม่ติดต่อกลับ จะต้องติดต่อลูกค้ากลับ ภายใน 2 วันทำการ">
                            <Tooltip title="ถ้าลูกค้าไม่ติดต่อกลับ จะต้องติดต่อลูกค้ากลับ ภายใน 2 วันทำการ">
                              <Text>
                                ถ้าลูกค้าไม่ติดต่อกลับ จะต้องติดต่อลูกค้ากลับ ภายใน 2 วันทำการ
                              </Text>
                            </Tooltip>
                          </Option>
                          <Option value="เปิด Card ใน Clickup และใส่ยอดที่ชำระเกินไว้">
                            เปิด Card ใน Clickup และใส่ยอดที่ชำระเกินไว้
                          </Option>
                          <Option value="เปิด Card ใน Clickup และใส่ยอดที่ชำระขาดไว้">
                            เปิด Card ใน Clickup และใส่ยอดที่ชำระขาดไว้
                          </Option>
                          <Option value="แจ้งให้ลูกค้าทำหนังสือฯ หรือส่งอีเมลขอผ่อนชำระ พร้อมระงับการใช้งานจนกว่าลูกค้าจะชำระครบ">
                            <Tooltip
                              title="แจ้งให้ลูกค้าทำหนังสือฯ หรือส่งอีเมลขอผ่อนชำระ
                            พร้อมระงับการใช้งานจนกว่าลูกค้าจะชำระครบ"
                            >
                              <Text>
                                แจ้งให้ลูกค้าทำหนังสือฯ หรือส่งอีเมลขอผ่อนชำระ
                                พร้อมระงับการใช้งานจนกว่าลูกค้าจะชำระครบ
                              </Text>
                            </Tooltip>
                          </Option>
                          <Option value="รอตรวจสอบยอดชำระ">รอตรวจสอบยอดชำระ</Option>
                          <Option value="ออก Credit Note เรียบร้อยแล้ว">
                            ออก Credit Note เรียบร้อยแล้ว
                          </Option>
                          <Option value="ส่งเรื่องให้แผนกที่เกี่ยวข้องตรวจสอบ และชี้แจ้งกับลูกค้า แต่ถ้าหากลูกค้าไม่ได้ใช้จริง ก็จัดทำ Credit Note ให้ลูกค้า">
                            <Tooltip
                              title="ส่งเรื่องให้แผนกที่เกี่ยวข้องตรวจสอบ และชี้แจ้งกับลูกค้า
                            แต่ถ้าหากลูกค้าไม่ได้ใช้จริง ก็จัดทำ Credit Note ให้ลูกค้า"
                            >
                              <Text>
                                ส่งเรื่องให้แผนกที่เกี่ยวข้องตรวจสอบ และชี้แจ้งกับลูกค้า
                                แต่ถ้าหากลูกค้าไม่ได้ใช้จริง ก็จัดทำ Credit Note ให้ลูกค้า
                              </Text>
                            </Tooltip>
                          </Option>
                          <Option value="โทรยืนยันการชำระเงินกับทางลูกค้า">
                            โทรยืนยันการชำระเงินกับทางลูกค้า
                          </Option>
                          <Option value="ส่งอีเมล์พร้อมเอกสารฉบับจริงให้ลูกค้าเมื่อถึงตอนสิ้นเดืิอน">
                            <Tooltip title="ส่งอีเมล์พร้อมเอกสารฉบับจริงให้ลูกค้าเมื่อถึงตอนสิ้นเดืิอน">
                              <Text>
                                ส่งอีเมล์พร้อมเอกสารฉบับจริงให้ลูกค้าเมื่อถึงตอนสิ้นเดืิอน
                              </Text>
                            </Tooltip>
                          </Option>
                          <Option value="สอบถามใบรับวางบิลจากลูกค้าเพื่อจะได้ PO แนบวางบิลในระบบ">
                            <Tooltip title="สอบถามใบรับวางบิลจากลูกค้าเพื่อจะได้ PO แนบวางบิลในระบบ">
                              <Text>สอบถามใบรับวางบิลจากลูกค้าเพื่อจะได้ PO แนบวางบิลในระบบ</Text>
                            </Tooltip>
                          </Option>
                          <Option value="รอสร้างใบแจ้งหนี้เพื่อวางบิลกับลูกค้า">
                            รอสร้างใบแจ้งหนี้เพื่อวางบิลกับลูกค้า
                          </Option>
                          <Option value="สอบถามรอบจ่ายกับเจ้าหน้าที่ที่เกี่ยวข้องและติดตามทวงถามรอบชำระ">
                            <Tooltip title="สอบถามรอบจ่ายกับเจ้าหน้าที่ที่เกี่ยวข้องและติดตามทวงถามรอบชำระ">
                              <Text>
                                สอบถามรอบจ่ายกับเจ้าหน้าที่ที่เกี่ยวข้องและติดตามทวงถามรอบชำระ
                              </Text>
                            </Tooltip>
                          </Option>
                          <Option value="รอตรวจสอบยอดเงินเข้า">รอตรวจสอบยอดเงินเข้า</Option>
                          <Option value="ให้แจ้งยอดกับ HR เพื่อหักเงินจากเงินเดือนพนักงาน หรือติดตามทวงถามให้ชำระ">
                            <Tooltip title="ให้แจ้งยอดกับ HR เพื่อหักเงินจากเงินเดือนพนักงาน หรือติดตามทวงถามให้ชำระ">
                              <Text>
                                ให้แจ้งยอดกับ HR เพื่อหักเงินจากเงินเดือนพนักงาน
                                หรือติดตามทวงถามให้ชำระ
                              </Text>
                            </Tooltip>
                          </Option>
                          <Option value="โทรสอบถามรายละเอียดเกี่ยวกับ Invoice ที่ลูกค้าชำระ และสอบถามวันที่ลูกค้าจะชำระเข้ามาให้ชัดเจน">
                            <Tooltip title="โทรสอบถามรายละเอียดเกี่ยวกับ Invoice ที่ลูกค้าชำระ และสอบถามวันที่ลูกค้าจะชำระเข้ามาให้ชัดเจน">
                              <Text>
                                โทรสอบถามรายละเอียดเกี่ยวกับ Invoice ที่ลูกค้าชำระ
                                และสอบถามวันที่ลูกค้าจะชำระเข้ามาให้ชัดเจน
                              </Text>
                            </Tooltip>
                          </Option>
                          <Option value="โทรหาลูกค้าวันถัดไป">โทรหาลูกค้าวันถัดไป</Option>
                          <Option value="อื่นๆ">อื่นๆ</Option>
                        </Select>
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {watchAction === 'อื่นๆ' && (
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Text strong>
                      ระบุเพิ่มเติม<Text customType="danger">*</Text>:
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      validateStatus={errors.actionDetail && 'error'}
                      help={errors.actionDetail ? errors.actionDetail.message : undefined}
                      style={{ marginBottom: 10 }}
                    >
                      <Controller
                        control={control}
                        name="actionDetail"
                        render={({ onChange, value }) => (
                          <Input
                            value={value || undefined}
                            onChange={(valueOnChange) => onChange(valueOnChange)}
                            style={{ width: '100%' }}
                            placeholder="ระบุ"
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 0]}>
        <Col span={12}>
          <Row>
            <Col span={24}>
              <Text strong>
                นัดหมายครั้งต่อไป<Text customType="danger">*</Text>:
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                validateStatus={errors.nextFollowDate && 'error'}
                help={errors.nextFollowDate ? errors.nextFollowDate.message : undefined}
                style={{ marginBottom: 10 }}
              >
                <Controller
                  control={control}
                  name="nextFollowDate"
                  render={({ onChange, value }) => (
                    <DatePicker
                      style={{ width: '100%' }}
                      allowClear={false}
                      value={value ? moment(value, dateFormat) : null}
                      placeholder="- เลือก -"
                      onChange={(valueDate) => {
                        if (!valueDate) {
                          onChange('');
                        } else {
                          onChange(valueDate.format(dateFormat));
                        }
                      }}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 0]}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Text strong>รายละเอียดเพิ่มเติม:</Text>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                validateStatus={errors.detail && 'error'}
                help={errors.detail ? errors.detail.message : undefined}
                style={{ marginBottom: 10 }}
              >
                <Controller
                  control={control}
                  name="detail"
                  render={({ onChange, value }) => (
                    <TextArea
                      value={value || undefined}
                      placeholder="ระบุ (ถ้ามี)"
                      rows={3}
                      onChange={(valueOnChange) => onChange(valueOnChange)}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default InputDebtTrackerHistoryForm;
