/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, useState } from 'react';
import { Row, Col, Input, Radio, Select } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text, InputNumberFormat } from 'shared/components';
import {
  FormValues,
  PaymentList,
} from 'views/AccountPayment/Routes/PaymentOrder/ModalCreatePaymentOrder/model/formValues.model';
import { StyledTable, StyledFormItem } from './Styles';
import BuildListPayment from './BuildListPayment';
import ModalAddService from './ModalAddService';

const { Option } = Select;
const { TextArea } = Input;

type ListPaymentDataTable = {
  key: string | undefined;
  tools: ReactNode;
  column1: ReactNode;
  column2: ReactNode;
  column3: ReactNode;
  column4: ReactNode;
  column5: ReactNode;
  column6: ReactNode;
  column7: ReactNode;
  column8: ReactNode;
  column9: ReactNode;
  column10: ReactNode;
  column11: ReactNode;
};

const columns = [
  {
    title: 'เครื่องมือ',
    dataIndex: 'tools',
    width: 70,
  },
  {
    title: 'รหัสรายการ',
    dataIndex: 'column1',
    width: 180,
  },
  {
    title: 'ชื่อรายการ',
    dataIndex: 'column2',
    width: 200,
  },
  {
    title: 'จำนวน',
    dataIndex: 'column3',
    width: 80,
    align: 'right',
  },
  {
    title: 'ราคาต่อหน่วย',
    dataIndex: 'column4',
    width: 100,
    align: 'right',
  },
  {
    title: 'ราคารวม',
    dataIndex: 'column5',
    width: 130,
    align: 'right',
  },
  {
    title: 'ส่วนลด',
    dataIndex: 'column6',
    width: 130,
    align: 'right',
  },
  {
    title: 'รวมหลังส่วนลด',
    dataIndex: 'column7',
    width: 130,
    align: 'right',
  },
  {
    title: 'จำนวนเรียกคืน',
    dataIndex: 'column8',
    width: 130,
    align: 'right',
  },
  {
    title: 'ยกเว้นภาษี',
    dataIndex: 'column9',
    width: 130,
    align: 'right',
  },
  {
    title: 'ภาษีมูลค่าเพิ่ม',
    dataIndex: 'column10',
    width: 130,
    align: 'right',
  },
  {
    title: 'หัก ณ ที่จ่าย',
    dataIndex: 'column11',
    width: 130,
    align: 'right',
  },
];

const ListPayment: React.FC = () => {
  const [isOpenModalAddService, setOpenModalAddService] = useState<boolean>(false);
  const { control, watch, errors, trigger } = useFormContext<FormValues>();
  const watchPaymentList = watch('paymentList');
  const { fields: fieldsPaymentList, append, remove } = useFieldArray<PaymentList>({
    control,
    name: 'paymentList',
  });

  const changeNumberFormat = (value: number) => {
    return value.toFixed(2);
  };

  const getValueFormNumber = (value: number | undefined) => {
    if (typeof value === 'number') {
      return changeNumberFormat(value);
    }
    return undefined;
  };

  const convertToTypeNumber = (value: string | number | undefined) => {
    if (value === undefined) {
      return 0;
    }

    if (typeof value === 'string') {
      return +value;
    }

    return value;
  };

  const calTotalPrice = (param: { qty: number; price: number }) => {
    return param.qty * param.price;
  };

  const calTotalPriceAfterDiscount = (param: { qty: number; price: number; discount: number }) => {
    return param.qty * param.price - param.discount;
  };

  let paymentData: ListPaymentDataTable[] = [];
  paymentData = fieldsPaymentList.map(
    (item, index): ListPaymentDataTable => {
      return {
        key: item.id,
        tools: (
          <Button
            disabled={fieldsPaymentList.length < 2}
            title="ลบ"
            color="red--outline"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => remove(index)}
          />
        ),
        column1: (
          <>
            <Controller
              as={Input}
              type="hidden"
              control={control}
              name={`paymentList[${index}].dataMasterId`}
              defaultValue={item.dataMasterId}
            />
            {item.listNumber}
            <Controller
              as={Input}
              type="hidden"
              control={control}
              name={`paymentList[${index}].listNumber`}
              defaultValue={item.listNumber}
            />
          </>
        ),
        column2: (
          <>
            {item.listName}
            <Controller
              as={Input}
              type="hidden"
              control={control}
              name={`paymentList[${index}].listName`}
              defaultValue={item.listName}
            />
          </>
        ),
        column3: (
          <StyledFormItem
            validateStatus={errors.paymentList && errors.paymentList[index]?.qty && 'error'}
          >
            <Controller
              control={control}
              name={`paymentList[${index}].qty`}
              defaultValue={item.qty}
              render={({ onChange, value, onBlur }) => (
                <InputNumberFormat
                  value={value}
                  onValueChange={(valueChange) => {
                    onChange(getValueFormNumber(valueChange.floatValue));
                    trigger([`paymentList[${index}].discount`]);
                  }}
                  decimalScale={0}
                  placeholder="0"
                  onBlur={() => onBlur()}
                />
              )}
            />
          </StyledFormItem>
        ),
        column4: (
          <StyledFormItem
            validateStatus={errors.paymentList && errors.paymentList[index]?.price && 'error'}
          >
            <Controller
              control={control}
              name={`paymentList[${index}].price`}
              defaultValue={item.price}
              render={({ onChange, value, onBlur }) => (
                <InputNumberFormat
                  value={value}
                  onValueChange={(valueChange) => {
                    onChange(getValueFormNumber(valueChange.floatValue));
                    trigger([`paymentList[${index}].discount`]);
                  }}
                  onBlur={() => onBlur()}
                  fixedDecimalScale
                />
              )}
            />
          </StyledFormItem>
        ),
        column5: formatCurrency(
          calTotalPrice({
            qty: convertToTypeNumber(watchPaymentList[index]?.qty),
            price: convertToTypeNumber(watchPaymentList[index]?.price),
          }),
        ),
        column6: (
          <StyledFormItem
            validateStatus={errors.paymentList && errors.paymentList[index]?.discount && 'error'}
          >
            <Controller
              control={control}
              name={`paymentList[${index}].discount`}
              render={({ onChange, value, onBlur }) => (
                <InputNumberFormat
                  value={value}
                  onValueChange={(valueChange) => {
                    onChange(getValueFormNumber(valueChange.floatValue));
                  }}
                  onBlur={() => onBlur()}
                  fixedDecimalScale
                />
              )}
            />
          </StyledFormItem>
        ),
        column7: formatCurrency(
          calTotalPriceAfterDiscount({
            qty: convertToTypeNumber(watchPaymentList[index]?.qty),
            price: convertToTypeNumber(watchPaymentList[index]?.price),
            discount: convertToTypeNumber(watchPaymentList[index]?.discount),
          }),
        ),
        column8: (
          <Controller
            control={control}
            name={`paymentList[${index}].vatCondition`}
            defaultValue={item.vatCondition}
            render={({ onChange }) => (
              <Row justify="space-between" align="middle">
                <Col style={{ paddingLeft: 20 }}>
                  <Radio
                    value="advance"
                    checked={watchPaymentList[index]?.vatCondition === 'advance'}
                    onChange={(event) => onChange(event.target.value)}
                    style={{ marginRight: -8 }}
                  />
                </Col>
                <Col>
                  {watchPaymentList[index]?.vatCondition !== 'advance'
                    ? '-'
                    : formatCurrency(
                        calTotalPriceAfterDiscount({
                          qty: convertToTypeNumber(watchPaymentList[index]?.qty),
                          price: convertToTypeNumber(watchPaymentList[index]?.price),
                          discount: convertToTypeNumber(watchPaymentList[index]?.discount),
                        }),
                      )}
                </Col>
              </Row>
            )}
          />
        ),
        column9: (
          <Controller
            control={control}
            name={`paymentList[${index}].vatCondition`}
            defaultValue={item.vatCondition}
            render={({ onChange }) => (
              <Row justify="space-between" align="middle">
                <Col style={{ paddingLeft: 20 }}>
                  <Radio
                    value="nonVat"
                    checked={watchPaymentList[index]?.vatCondition === 'nonVat'}
                    onChange={(event) => onChange(event.target.value)}
                    style={{ marginRight: -8 }}
                  />
                </Col>
                <Col>
                  {watchPaymentList[index]?.vatCondition !== 'nonVat'
                    ? '-'
                    : formatCurrency(
                        calTotalPriceAfterDiscount({
                          qty: convertToTypeNumber(watchPaymentList[index]?.qty),
                          price: convertToTypeNumber(watchPaymentList[index]?.price),
                          discount: convertToTypeNumber(watchPaymentList[index]?.discount),
                        }),
                      )}
                </Col>
              </Row>
            )}
          />
        ),
        column10: (
          <Controller
            control={control}
            name={`paymentList[${index}].vatCondition`}
            defaultValue={item.vatCondition}
            render={({ onChange }) => (
              <Row justify="space-between" align="middle">
                <Col style={{ paddingLeft: 20 }}>
                  <Radio
                    value="vat"
                    checked={watchPaymentList[index]?.vatCondition === 'vat'}
                    onChange={(event) => onChange(event.target.value)}
                    style={{ marginRight: -8 }}
                  />
                </Col>
                <Col>
                  {watchPaymentList[index]?.vatCondition !== 'vat'
                    ? '-'
                    : formatCurrency(
                        calTotalPriceAfterDiscount({
                          qty: convertToTypeNumber(watchPaymentList[index]?.qty),
                          price: convertToTypeNumber(watchPaymentList[index]?.price),
                          discount: convertToTypeNumber(watchPaymentList[index]?.discount),
                        }),
                      )}
                </Col>
              </Row>
            )}
          />
        ),
        column11: (
          <Controller
            control={control}
            defaultValue={`${item.wht}`}
            name={`paymentList[${index}].wht`}
            render={({ value, onChange }) => (
              <Select
                value={value}
                style={{ width: 'calc(100% - 32px)' }}
                onChange={(valueWht) => onChange(valueWht)}
              >
                <Option value="0">0%</Option>
                <Option value="1">1%</Option>
                <Option value="2">2%</Option>
                <Option value="3">3%</Option>
                <Option value="5">5%</Option>
              </Select>
            )}
          />
        ),
      };
    },
  );

  return (
    <>
      <StyledTable
        scroll={{ x: 'max-content' }}
        pagination={false}
        columns={columns}
        dataSource={paymentData}
        size="small"
      />

      <Row justify="space-between" style={{ marginTop: 10 }}>
        <Col>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Button
                color="blue--light"
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  setOpenModalAddService(true);
                }}
              >
                เพิ่มรายการ
              </Button>

              <ModalAddService
                visible={isOpenModalAddService}
                onCancel={() => setOpenModalAddService(false)}
                onOk={(value) => {
                  append({
                    dataMasterId: value.id,
                    listNumber: value.dataMasterServiceItemNumber,
                    listName: value.serviceItemName,
                    qty: undefined,
                    price: undefined,
                    discount: undefined,
                    vatCondition: 'nonVat',
                    wht: 0,
                  });

                  setOpenModalAddService(false);
                }}
              />
            </Col>
            <Col span={24}>
              หมายเหตุ <Text customType="grey">(ไม่บังคับ)</Text>
              <Controller
                control={control}
                name="paymentRemark"
                placeholder="ระบุ"
                render={({ value, onChange }) => (
                  <TextArea
                    placeholder="ระบุ"
                    rows={4}
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                  />
                )}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Col>
              <BuildListPayment />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ListPayment;
