/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { useQuery } from '@apollo/react-hooks';
import { Button, Row, Col, Typography, Input, Spin } from 'antd';
import { DeleteOutlined, PlusCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';
import PAYMENT_VOUCHER_QUERY from 'shared/graphql/query/paymentVoucher';
import PAYMENTS_QUERY from 'shared/graphql/query/payments';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { PaymentVoucherContext } from 'views/AccountPayment/common/context/PaymentVoucherContext';
import ModalAddPayment from 'views/AccountPayment/Routes/PaymentVoucher/PaymentVoucherDetail/ModalAddPayment';
import { StyledTable, StyledRowDetail, StyledDetailContainer, StyledFormItem } from './Styles';
import ExpandedRowPayment from './ExpandedRowPayment';
import ModalConfirmDeletePayment from './ModalConfirmDeletePayment';
import ModalConfirmAddPayment from './ModalConfirmAddPayment';

const { Text } = Typography;
const { TextArea } = Input;
const columns = [
  {
    title: 'เครื่องมือ',
    dataIndex: 'tools',
    width: 70,
  },
  {
    title: 'วันที่เอกสาร',
    dataIndex: 'column1',
    width: 110,
  },
  {
    title: 'รหัสใบสั่งจ่าย',
    dataIndex: 'column2',
    width: 200,
  },
  {
    title: 'รอบจ่าย',
    dataIndex: 'column3',
    width: 100,
  },
  {
    title: 'ผู้ตั้งเบิก',
    dataIndex: 'column4',
    width: 250,
  },
  {
    title: 'รหัส Shipment/JOB/Booking',
    dataIndex: 'column5',
    width: 350,
  },
  {
    title: 'จำนวนตั้งเบิก (บาท)',
    dataIndex: 'column6',
    width: 130,
    align: 'right',
  },
];

const PaymentList = () => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext();
  const { selectedPaymentVoucherDetail: selectedPaymentVoucherDetailContext } = useContext(
    PaymentVoucherContext,
  );
  const [selectedPaymentVoucherDetail] = selectedPaymentVoucherDetailContext;
  const { paymentVoucherId, voucherStatus } = selectedPaymentVoucherDetail;
  const [isOpenModalAddPayment, setOpenModalAddPayment] = useState(false);
  const [isOpenModalDeletePayment, setOpenModalDeletePayment] = useState(false);
  const [selectedPaymentAdd, setSelectedPaymentAdd] = useState([]);
  const [selectedPaymentDelete, setSelectedPaymentDelete] = useState({
    id: '',
    paymentNumber: '',
    createDate: '',
    totalPrice: 0,
  });
  const [paymentsIdSendUpdate, setPaymentsIdSendUpdate] = useState([]);
  const [isOpenModalConfirmAddPayment, setOpenModalConfirmAddPayment] = useState(false);

  const { error, data } = useQuery(PAYMENT_VOUCHER_QUERY, {
    variables: {
      _id: paymentVoucherId,
    },
  });

  let content = <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  let paymentList = [];
  if (data && data.paymentVoucher) {
    const { paymentVoucher } = data;
    const {
      re_total_before_discount: reTotalBeforeDiscount,
      non_total_before_discount: nonTotalBeforeDiscount,
      inc_total_before_discount: incTotalBeforeDiscount,
      re_total_discount: reTotalDiscount,
      non_total_discount: nonTotalDiscount,
      inc_total_discount: incTotalDiscount,
      re_total_after_discount: reTotalAfterDiscount,
      non_total_after_discount: nonTotalAfterDiscount,
      inc_total_after_discount: incTotalAfterDiscount,
      total_vat: totalVat,
      re_total: reTotal,
      non_total: nonTotal,
      inc_total: incTotal,
      total_price: totalPrice,
      grand_total_price: grandTotalPrice,
      all_wht: allWht,
      payment,
    } = paymentVoucher;

    paymentList = payment.map((item, index) => {
      const {
        _id: id,
        create_date: createDate,
        payment_number: paymentNumber,
        round_paid: roundPaid,
        employee,
        number_ref: numberRef,
        total_price: paymentTotalPrice,
      } = item;

      return {
        key: id,
        tools: (
          <Button
            title="ลบ"
            type="red--outline"
            icon={<DeleteOutlined />}
            size="small"
            disabled={payment.length === 1 || (voucherStatus !== 1 && voucherStatus !== 2)}
            onClick={() => {
              setPaymentsIdSendUpdate(
                payment
                  .map((paymentItem) => paymentItem._id)
                  .filter((paymentId) => paymentId !== id),
              );
              setOpenModalDeletePayment(true);
              setSelectedPaymentDelete({
                id,
                createDate,
                paymentNumber,
                totalPrice,
              });
            }}
          />
        ),
        column1: formatDate(createDate),
        column2: paymentNumber || '-',
        column3: formatDate(roundPaid),
        column4: employee.full_name,
        column5: numberRef || '-',
        column6: (
          <Text type="primary" strong>
            {formatCurrency(paymentTotalPrice)}
          </Text>
        ),
        payment: item,
      };
    });

    const whtList = allWht.map((item, index, arr) => {
      const { wht, price, total_wht: totalWht } = item;
      const isLastItem = index === arr.length - 1;
      return (
        <StyledRowDetail key={item.wht} className={classNames({ divider: isLastItem })}>
          <Col className="column-1">{`หัก ณ ที่จ่าย ${wht}% (${formatCurrency(price)})`}</Col>
          <Col className="column-2 text-right" />
          <Col className="column-3 text-right" />
          <Col className="column-4 text-right">{formatCurrency(totalWht, '-')}</Col>
        </StyledRowDetail>
      );
    });

    content = (
      <>
        <StyledTable
          scroll={{ x: 'max-content' }}
          pagination={false}
          columns={columns}
          dataSource={paymentList}
          size="small"
          expandable={{
            expandedRowRender: (record) => <ExpandedRowPayment payment={record.payment} />,
          }}
        />
        <Row justify="space-between" style={{ marginTop: 10 }}>
          <Col>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button
                  disabled={voucherStatus !== 1 && voucherStatus !== 2 && voucherStatus !== 3}
                  type="blue--light"
                  icon={<PlusCircleOutlined />}
                  onClick={() => setOpenModalAddPayment(true)}
                >
                  เพิ่มรายการ
                </Button>
              </Col>
              <Col span={24}>
                หมายเหตุ <Text type="grey">(ไม่บังคับ)</Text>
                <StyledFormItem
                  validateStatus={errors.voucherRemark && touched.voucherRemark && 'error'}
                >
                  <TextArea
                    value={values.voucherRemark}
                    placeholder="ระบุ"
                    rows={4}
                    name="voucherRemark"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={voucherStatus === 4 || voucherStatus === 5 || voucherStatus === 6}
                  />
                </StyledFormItem>
              </Col>
            </Row>
          </Col>
          <Col>
            <StyledDetailContainer>
              <StyledRowDetail>
                <Col className="column-1">ราคารวมก่อนส่วนลด</Col>
                <Col className="column-2 text-right">
                  {formatCurrency(reTotalBeforeDiscount, '-')}
                </Col>
                <Col className="column-3 text-right">
                  {formatCurrency(nonTotalBeforeDiscount, '-')}
                </Col>
                <Col className="column-4 text-right">
                  {formatCurrency(incTotalBeforeDiscount, '-')}
                </Col>
              </StyledRowDetail>
              <StyledRowDetail className="divider">
                <Col className="column-1">ส่วนลด</Col>
                <Col className="column-2 text-right">{formatCurrency(reTotalDiscount, '-')}</Col>
                <Col className="column-3 text-right">{formatCurrency(nonTotalDiscount, '-')}</Col>
                <Col className="column-4 text-right">{formatCurrency(incTotalDiscount, '-')}</Col>
              </StyledRowDetail>

              <StyledRowDetail>
                <Col className="column-1">ราคารวมหลังส่วนลด</Col>
                <Col className="column-2 text-right">
                  {formatCurrency(reTotalAfterDiscount, '-')}
                </Col>
                <Col className="column-3 text-right">
                  {formatCurrency(nonTotalAfterDiscount, '-')}
                </Col>
                <Col className="column-4 text-right">
                  {formatCurrency(incTotalAfterDiscount, '-')}
                </Col>
              </StyledRowDetail>
              <StyledRowDetail className="divider">
                <Col className="column-1">ภาษีมูลค่าเพิ่ม 7%</Col>
                <Col className="column-2 text-right">-</Col>
                <Col className="column-3 text-right">-</Col>
                <Col className="column-4 text-right">{formatCurrency(totalVat, '-')}</Col>
              </StyledRowDetail>

              <StyledRowDetail>
                <Col className="column-1">รวมเป็นเงิน</Col>
                <Col className="column-2 text-right">{formatCurrency(reTotal, '-')}</Col>
                <Col className="column-3 text-right">{formatCurrency(nonTotal, '-')}</Col>
                <Col className="column-4 text-right">{formatCurrency(incTotal, '-')}</Col>
              </StyledRowDetail>
              <StyledRowDetail className="divider">
                <Col className="column-1">
                  <Text strong>จำนวนรวมทั้งสิ้น</Text>
                </Col>
                <Col className="column-2 text-right" />
                <Col className="column-3 text-right" />
                <Col className="column-4 text-right">
                  <Text strong>{formatCurrency(totalPrice, '-')}</Text>
                </Col>
              </StyledRowDetail>

              {whtList}

              <StyledRowDetail>
                <Col className="column-1">
                  <Text strong>รวมชำระทั้งสิ้น</Text>
                </Col>
                <Col className="column-2 text-right" />
                <Col className="column-3 text-right" />
                <Col className="column-4 text-right">
                  <Text strong style={{ fontSize: 24 }}>
                    {formatCurrency(grandTotalPrice)}
                  </Text>
                </Col>
              </StyledRowDetail>
            </StyledDetailContainer>
          </Col>
        </Row>

        <ModalConfirmDeletePayment
          paymentVoucherId={paymentVoucherId}
          payment={selectedPaymentDelete}
          paymentsId={paymentsIdSendUpdate}
          visible={isOpenModalDeletePayment}
          onCancel={() => setOpenModalDeletePayment(false)}
        />

        <ModalAddPayment
          visible={isOpenModalAddPayment}
          onOk={(payments) => {
            const oldPayments = data.paymentVoucher.payment.map((paymentItem) => ({
              id: paymentItem._id,
              createDate: paymentItem.create_date,
              paymentNumber: paymentItem.payment_number,
              totalPrice: paymentItem.total_price,
            }));
            const newPayments = [...payments];
            setSelectedPaymentAdd([...oldPayments, ...newPayments]);
            setOpenModalAddPayment(false);
            setOpenModalConfirmAddPayment(true);
          }}
          onCancel={() => setOpenModalAddPayment(false)}
          payeeId={selectedPaymentVoucherDetail.payeeId}
          paymentType={selectedPaymentVoucherDetail.paymentType}
        />

        <ModalConfirmAddPayment
          paymentVoucherId={paymentVoucherId}
          payments={selectedPaymentAdd}
          visible={isOpenModalConfirmAddPayment}
          onCancel={() => setOpenModalConfirmAddPayment(false)}
        />
      </>
    );
  }

  if (error) {
    content = error.message;
  }

  return <div>{content}</div>;
};

export default PaymentList;
