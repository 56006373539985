import styled from 'styled-components';
import { color } from 'shared/utils/styles';
import CardIcon from './CardIcon';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledCardIcon = styled(CardIcon)`
  &:not(:last-child) {
    border-right: 1px solid ${color.grey};
  }
`;
