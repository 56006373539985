import gql from 'graphql-tag';

export interface VerifyOTPData {
  verifyOTP: {
    sessionId: string;
    verifyUri: string;
    destination: string;
    status: string;
    attempt: number;
    expiresAt: string;
    retryAfter: string;
  };
}

export interface VerifyOTPDataVars {
  verifyUri: string;
  code: string;
}

export default gql`
  mutation VerifyOTP($verifyUri: String!, $code: String!) {
    verifyOTP(verifyUri: $verifyUri, code: $code) {
      sessionId
      verifyUri
      destination
      status
      attempt
      expiresAt
      retryAfter
    }
  }
`;
