/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Select, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import PAYEES_QUERY, { PayeesData, PayeeVars } from 'shared/graphql/query/payees';

const { Option } = Select;

type Payees = {
  id: string;
  fullName: string;
};

type SelectPayeesFieldValue = {
  payeeId: string;
  fullName: string;
};

type SelectPayeeFieldProps = {
  value?: string;
  onChange?: (value?: SelectPayeesFieldValue) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
};

const defaultProps = {
  disabled: false,
};

const SelectPayeeField: React.FC<SelectPayeeFieldProps> = (props) => {
  const { value, onChange, disabled } = props;
  const [fullName, setFullName] = useState<string>('');
  const [payeeList, setPayeeList] = useState<Payees[]>([]);
  const { data, loading, error } = useQuery<PayeesData, PayeeVars>(PAYEES_QUERY, {
    variables: {
      full_name: fullName,
      payee_status: 1,
      showData: 100000,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && data?.payees) {
      const { payees } = data.payees;
      const payeeFilter = payees.map((payeeItem) => {
        if (payeeItem.payee_type === 2) {
          return {
            id: payeeItem._id,
            fullName: payeeItem.employee?.full_name,
          };
        }
        return {
          id: payeeItem._id,
          fullName: payeeItem.vendor.full_name,
        };
      });
      setPayeeList([...payeeFilter]);
    }
  }, [data?.payees, error, loading]);

  const options = payeeList.map((payee) => (
    <Option key={payee.id} value={payee.id}>
      {payee.fullName}
    </Option>
  ));

  const selectedPayee = (valueInput?: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }

    const payee = payeeList.filter((payeeItem) => payeeItem.id === valueInput);
    if (onChange && payee.length === 1) {
      const { id, fullName: payeeFullName } = payee[0];
      onChange({
        payeeId: id,
        fullName: payeeFullName,
      });
    }
  };

  return (
    <Select
      allowClear
      style={{
        width: '100%',
      }}
      value={value}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      showSearch
      placeholder="ค้นหา"
      onSearch={(valueSearch) => setFullName(valueSearch)}
      onChange={(valueChange) => {
        selectedPayee(valueChange);
        setFullName('');
      }}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
    >
      {options}
    </Select>
  );
};

SelectPayeeField.defaultProps = defaultProps;

export default SelectPayeeField;
