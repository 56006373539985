import gql from 'graphql-tag';
import { ParcelDetailStatus } from 'views/CostingParcel/Routes/ParcelDetail/components/ParcelDetailStatus';

export interface ParcelDetails {
  _id: string;
  number: string;
  createdAt: string;
  pickupRangeDate: string;
  customer: {
    _id: string;
    full_name: string;
  };
  totalCost: number;
  totalRevenue: number;
}

export interface ParcelDetailListData {
  parcelDetailList: {
    parcelDetails: ParcelDetails[];
    totalDocument: number;
  };
}

export interface ParcelDetailListVars {
  _id?: string;
  number?: string;
  createdAt?: string;
  pickupRangeDate?: string;
  customer?: string;
  status?: ParcelDetailStatus;
  order_by?: string;
  order_type?: '1' | '-1' | '';
  page?: number;
  showData?: number;
}

export default gql`
  query ParcelDetailList(
    $_id: ID
    $number: String
    $createdAt: String
    $pickupRangeDate: String
    $customer: ID
    $status: Int
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
  ) {
    parcelDetailList(
      params: {
        _id: $_id
        number: $number
        createdAt: $createdAt
        pickupRangeDate: $pickupRangeDate
        customer: $customer
        status: $status
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      parcelDetails {
        _id
        number
        createdAt
        pickupRangeDate
        customer {
          _id
          full_name
        }
        totalCost
        totalRevenue
      }
      totalDocument
    }
  }
`;
