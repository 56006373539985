import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import {
  InvoiceContext,
  CreateInvoiceType,
} from 'views/AccountReceive/common/context/InvoiceContext';
import { StyledButton, TextHeader, Container } from './Styles';

const Header: React.FC = () => {
  const { setCreateInvoice } = useContext(InvoiceContext);

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>สร้างใบแจ้งหนี้</TextHeader>
        </Col>
        <Col>
          <StyledButton
            // disabled={isLoading}
            icon={<CheckCircleOutlined />}
            color="green"
            // onClick={handleSubmit}
          >
            สร้างใบแจ้งหนี้
          </StyledButton>
          <StyledButton
            onClick={() => {
              setCreateInvoice(
                (prevState): CreateInvoiceType => ({
                  ...prevState,
                  isOpenModal: false,
                }),
              );
            }}
          >
            ปิด
          </StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
