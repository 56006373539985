import styled from 'styled-components';
import { Badge } from 'antd';
import { font, mixin } from 'shared/utils/styles';

export const Container = styled.div`
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;

    & li {
      box-sizing: border-box;
      display: inline-block;
      padding: 20px 0px 10px;
      position: relative;
      ${font.size(20)}
      ${mixin.clickable}

      &:not(:last-child) {
        margin-right: 20px;
      }

      &.active {
        border-bottom: 2px solid #000000;
        ${font.bold}
      }
    }
  }
`;

export const StyledBadge = styled(Badge)`
  .ant-badge-count {
    background-color: #ffffff;
    color: #080808;
    box-shadow: 0 0 0 1px #dddddd inset;
  }
`;

export const Divider = styled.div`
  display: inline-block;
  width: 1px;
  height: 30px;
  background-color: #e3e3e3;
  margin-right: 20px;
  vertical-align: middle;
`;
