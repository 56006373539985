/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import { Divider, Row, Col, Tabs, Skeleton } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { color } from 'shared/utils/styles';
import { Text } from 'shared/components';
import { useFormContext } from 'react-hook-form';
import BILLING_NOTE_QUERY, {
  BillingNoteData,
  BillingNoteVars,
} from 'shared/graphql/query/billingNote';
import { ReceiptContext } from 'views/AccountReceive/common/context/ReceiptContext';
import { TagStatusBillingNote } from 'views/AccountReceive/common/components';
import SelectFieldSearchCustomers from './SelectFieldSearchCustomers';
import BillingNoteDetailTable from './BillingNoteDetailTable';
import DocumentList from './DocumentList';

const { TabPane } = Tabs;

const BillingNoteDetail: React.FC = () => {
  const { selectedBillingNoteDetail } = useContext(ReceiptContext);
  const { billingNoteId } = selectedBillingNoteDetail;
  const { getValues, setValue, watch } = useFormContext();
  const watchFields = watch(['payerId', 'payerFullName']);
  const { data } = useQuery<BillingNoteData, BillingNoteVars>(BILLING_NOTE_QUERY, {
    variables: {
      _id: billingNoteId,
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = <Skeleton />;

  if (data?.billingNote) {
    const {
      bill_number,
      system_request,
      payer,
      service_date,
      bill_status,
      grand_total_price,
    } = data.billingNote;

    content = (
      <>
        <Text strong>เงื่อนไขใบวางบิล</Text>
        <Divider style={{ margin: '10px 0' }} />
        <Row gutter={[32, 16]}>
          <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>:
                  </Col>
                  <Col span={24}>
                    {watchFields && (
                      <SelectFieldSearchCustomers
                        value={{
                          key: getValues('payerId'),
                          label: getValues('payerFullName'),
                        }}
                        onChange={(value) => {
                          setValue('payerId', value.value);
                          setValue('payerFullName', value.label);
                        }}
                        disabled
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
            <Row>
              <Col span={10}>ประเภทงาน:</Col>
              <Col span={14}>
                <Text strong>{system_request}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>ที่อยู่วางบิล:</Col>
              <Col span={14}>
                <Text strong>{payer.full_address || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เลขประจำตัวผู้เสียภาษี:</Col>
              <Col span={14}>
                <Text strong>{payer.tax_id || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เบอร์โทรศัพท์:</Col>
              <Col span={14}>
                <Text strong>{payer.phone.length > 0 ? payer.phone.join(', ') : '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>อีเมล:</Col>
              <Col span={14}>
                <Text strong>{payer.email.length > 0 ? payer.email.join(', ') : '-'}</Text>
              </Col>
            </Row>
          </Col>

          <Col span={8}>
            <Row>
              <Col span={8}>วันที่เอกสาร:</Col>
              <Col span={16}>
                <Text strong>{formatDate(service_date)}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รหัสใบวางบิล:</Col>
              <Col span={16}>
                <Text strong>{bill_number}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รวมมูลค่าใบวางบิล:</Col>
              <Col span={16}>
                <Text strong customType="primary">
                  {formatCurrency(grand_total_price)}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>สถานะ:</Col>
              <Col span={16}>
                <TagStatusBillingNote status={bill_status} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider style={{ margin: 0, marginTop: -5 }} />

        <Tabs defaultActiveKey="1" animated={false}>
          <TabPane
            tab={
              <span>
                รายละเอียดงาน{' '}
                <CheckCircleOutlined
                  style={{
                    color: color.success,
                    marginLeft: 5,
                  }}
                />
              </span>
            }
            key="1"
          >
            <BillingNoteDetailTable />
          </TabPane>
          <TabPane
            tab={
              <span>
                หลักฐานการชำระ{' '}
                <CheckCircleOutlined
                  style={{
                    color: color.success,
                    marginLeft: 5,
                  }}
                />
              </span>
            }
            key="2"
          >
            <DocumentList />
          </TabPane>
        </Tabs>
      </>
    );
  }

  return content;
};

export default BillingNoteDetail;
