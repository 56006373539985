import React from 'react';
import { Row, Col } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { color } from 'shared/utils/styles';
import formatCurrency from 'shared/utils/formatCurrency';
import { InputNumberFormat, Text, Button } from 'shared/components';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import {
  FormValues,
  AllWht,
} from 'views/AccountReceive/Routes/Receipt/BillingNotesTable/ModalCreateReceipt/model/formValues.model';
import useSumTotalPrice from 'views/AccountReceive/Routes/Receipt/BillingNotesTable/ModalCreateReceipt/hooks/useSumTotalPrice';
import { Container, StyledTotalPriceContainer, StyledFormItem } from './Styles';

const FormPrice: React.FC = () => {
  const { errors, control, watch, setValue } = useFormContext<FormValues>();
  const watchTotalPaid = watch('totalPaid');
  const watchAllWht = watch('allWht');
  const watchAllField = watch();
  const { totalPriceLeft, totalPriceRight } = useSumTotalPrice();
  const { fields: fieldsAllWht, append, remove } = useFieldArray<AllWht>({
    control,
    name: 'allWht',
  });

  const changeNumberFormat = (value: number) => {
    return value.toFixed(2);
  };

  const getValueFormNumber = (value: number | undefined) => {
    if (typeof value === 'number') {
      return changeNumberFormat(value);
    }
    return undefined;
  };

  return (
    <Container>
      {watchAllField && (
        <Row gutter={[24, 0]}>
          <Col span={12} style={{ paddingTop: 16, borderRight: `1px solid ${color.grey}` }}>
            <Row gutter={[24, 8]}>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    จำนวนเงิน<Text type="danger">*</Text>
                  </Col>
                  <Col span={12}>
                    <StyledFormItem validateStatus={errors.receiptPrice && 'error'}>
                      <Controller
                        control={control}
                        name="receiptPrice"
                        render={({ onChange, value, onBlur }) => (
                          <InputNumberFormat
                            value={value}
                            onValueChange={(valueChange) => {
                              onChange(getValueFormNumber(valueChange.floatValue));
                            }}
                            onBlur={() => onBlur()}
                          />
                        )}
                      />
                    </StyledFormItem>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={12}>ส่วนลดจ่าย</Col>
                  <Col span={12}>
                    <StyledFormItem validateStatus={errors.receiptDiscount && 'error'}>
                      <Controller
                        control={control}
                        name="receiptDiscount"
                        render={({ onChange, value, onBlur }) => (
                          <InputNumberFormat
                            value={value}
                            onValueChange={(valueChange) => {
                              onChange(getValueFormNumber(valueChange.floatValue));
                            }}
                            onBlur={() => onBlur()}
                          />
                        )}
                      />
                    </StyledFormItem>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={12}>ค่าธรรมเนียมธนาคาร</Col>
                  <Col span={12}>
                    <StyledFormItem validateStatus={errors.bankFee && 'error'}>
                      <Controller
                        control={control}
                        name="bankFee"
                        render={({ onChange, value, onBlur }) => (
                          <InputNumberFormat
                            value={value}
                            onValueChange={(valueChange) => {
                              onChange(getValueFormNumber(valueChange.floatValue));
                            }}
                            onBlur={() => onBlur()}
                          />
                        )}
                      />
                    </StyledFormItem>
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ marginBottom: 24 }}>
                <Row>
                  <Col span={12}>ค่าใช้จ่ายอื่นๆ</Col>
                  <Col span={12}>
                    <StyledFormItem validateStatus={errors.otherExpenses && 'error'}>
                      <Controller
                        control={control}
                        name="otherExpenses"
                        render={({ onChange, value, onBlur }) => (
                          <InputNumberFormat
                            value={value}
                            onValueChange={(valueChange) => {
                              onChange(getValueFormNumber(valueChange.floatValue));
                            }}
                            onBlur={() => onBlur()}
                          />
                        )}
                      />
                    </StyledFormItem>
                  </Col>
                </Row>
              </Col>
              {fieldsAllWht.map((item, index) => (
                <Col span={24} key={item.id}>
                  <Row>
                    <Col span={12}>{`${index + 1}) ภาษีถูกหัก ณ ที่จ่าย`}</Col>
                    <Col span={12}>
                      <Row gutter={[8, 0]}>
                        <Col span={10}>
                          <StyledFormItem
                            validateStatus={errors.allWht && errors.allWht[index]?.wht && 'error'}
                          >
                            <Controller
                              control={control}
                              name={`allWht[${index}].wht`}
                              defaultValue={item.wht}
                              render={({ onChange, value, onBlur }) => (
                                <InputNumberFormat
                                  addonAfter="%"
                                  value={value}
                                  onValueChange={(valueChange) => {
                                    onChange(getValueFormNumber(valueChange.floatValue));
                                  }}
                                  onBlur={() => onBlur()}
                                />
                              )}
                            />
                          </StyledFormItem>
                        </Col>
                        <Col span={10}>
                          <StyledFormItem
                            validateStatus={
                              errors.allWht && errors.allWht[index]?.totalWht && 'error'
                            }
                          >
                            <Controller
                              control={control}
                              name={`allWht[${index}].totalWht`}
                              defaultValue={item.totalWht}
                              render={({ onChange, value, onBlur }) => (
                                <InputNumberFormat
                                  value={value}
                                  onValueChange={(valueChange) => {
                                    onChange(getValueFormNumber(valueChange.floatValue));
                                  }}
                                  onBlur={() => onBlur()}
                                />
                              )}
                            />
                          </StyledFormItem>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }}>
                          <Button
                            icon={<DeleteOutlined />}
                            disabled={fieldsAllWht.length < 2}
                            onClick={() => {
                              remove(index);
                              setValue(`allWht[${index}].wht`, undefined);
                              setValue(`allWht[${index}].totalWht`, undefined);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ))}
              <Col span={24}>
                <Row justify="end">
                  <Col>
                    <Button
                      type="link"
                      onClick={() => {
                        append({ wht: undefined, totalWht: undefined });
                      }}
                    >
                      + เพิ่ม
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={12} style={{ paddingTop: 16 }}>
            <Row gutter={[24, 8]}>
              <Col span={24}>
                <Row>
                  <Col span={12}>รวมมูลค่ารับชำระ</Col>
                  <Col span={12}>
                    <InputNumberFormat
                      value={formatCurrency(watchTotalPaid)}
                      disabled
                      style={{ textAlign: 'right', color: `${color.black}` }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={12}>รายได้อื่นๆ</Col>
                  <Col span={12}>
                    <StyledFormItem validateStatus={errors.otherIncome && 'error'}>
                      <Controller
                        control={control}
                        name="otherIncome"
                        render={({ onChange, value, onBlur }) => (
                          <InputNumberFormat
                            value={value}
                            onValueChange={(valueChange) => {
                              onChange(getValueFormNumber(valueChange.floatValue));
                            }}
                            onBlur={() => onBlur()}
                          />
                        )}
                      />
                    </StyledFormItem>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={12}>ดอกเบี้ยรับ</Col>
                  <Col span={12}>
                    <StyledFormItem validateStatus={errors.interestIncome && 'error'}>
                      <Controller
                        control={control}
                        name="interestIncome"
                        render={({ onChange, value, onBlur }) => (
                          <InputNumberFormat
                            value={value}
                            onValueChange={(valueChange) => {
                              onChange(getValueFormNumber(valueChange.floatValue));
                            }}
                            onBlur={() => onBlur()}
                          />
                        )}
                      />
                    </StyledFormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {watchAllWht && (
        <Row gutter={[24, 16]}>
          <Col
            span={12}
            style={{ paddingTop: 40, paddingBottom: 16, borderRight: `1px solid ${color.grey}` }}
          >
            <StyledTotalPriceContainer justify="space-between" align="middle">
              <Col>รวมรับทั้งสิ้น</Col>
              <Col>
                <Text
                  customType={totalPriceLeft !== totalPriceRight ? 'danger' : 'success'}
                  strong
                  style={{ fontSize: 24 }}
                >
                  {formatCurrency(totalPriceLeft)}
                </Text>
              </Col>
            </StyledTotalPriceContainer>
          </Col>
          <Col span={12} style={{ paddingTop: 40, paddingBottom: 16 }}>
            <StyledTotalPriceContainer justify="space-between" align="middle">
              <Col>รวมรับทั้งสิ้น</Col>
              <Col>
                <Text
                  customType={totalPriceLeft !== totalPriceRight ? 'danger' : 'success'}
                  strong
                  style={{ fontSize: 24 }}
                >
                  {formatCurrency(totalPriceRight)}
                </Text>
              </Col>
            </StyledTotalPriceContainer>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default FormPrice;
