import gql from 'graphql-tag';

export interface PayeeVars {
  _id?: string;
  full_name?: string;
  vendor?: string;
  employee?: string;
  payee_status?: number;
  showData?: number;
}

export interface Employee {
  _id: string;
  department: string;
  full_name: string;
}

export interface Vendor {
  _id: string;
  vendor_status: boolean;
  full_name: string;
}

export interface Payees {
  _id: string;
  payee_type: number;
  employee: Employee;
  vendor: Vendor;
}

export interface PayeesData {
  payees: {
    payees: Payees[];
  };
}

export default gql`
  query Payees(
    $_id: ID
    $payee_type: Int
    $payee_status: Int
    $full_name: String
    $vendor: ID
    $employee: ID
    $showData: Int
  ) {
    payees(
      params: {
        _id: $_id
        payee_type: $payee_type
        payee_status: $payee_status
        full_name: $full_name
        vendor: $vendor
        employee: $employee
      }
      showData: $showData
    ) {
      payees {
        _id
        payee_type
        employee {
          _id
          department
          full_name
        }
        vendor {
          _id
          vendor_status
          full_name
        }
      }
    }
  }
`;
