import gql from 'graphql-tag';

export interface CreateCustomerData {
  createCustomer: {
    _id: string;
  };
}

export interface CreateCustomerVars {
  from: number;
  first_name: string;
  last_name?: string;
  full_name: string;
  email: string[];
  phone: string[];
  tax_id: string;
  branch: string;
  address: string;
  sub_district: string;
  district: string;
  province: string;
  postcode: string;
  business_invoice_type: number;
  business_invoice_date_range: {
    start: string;
    end: string;
  };
  payment_bank_account?: {
    bank_name: string;
    bank_branch: string;
    account_name: string;
    account_number: string;
  };
  business_pay_round: {
    round_date: string;
    after_invoice: string;
  };
  customer_bank_account?: {
    mode: number;
    bank_id?: string;
    account_name: string;
    account_number: string;
    promptPay?: {
      type: number;
      account_number?: string;
    };
  };
}

export default gql`
  mutation CreateCustomer(
    $from: Int
    $first_name: String
    $last_name: String
    $full_name: String
    $email: [String]
    $phone: [String]
    $tax_id: String
    $branch: String
    $address: String
    $sub_district: String
    $district: String
    $province: String
    $postcode: String
    $business_invoice_type: Int
    $business_invoice_date_range: inputBusinessInvoiceDateRange
    $payment_bank_account: inputPaymentBankAccount
    $business_pay_round: inputBusinessPayRound
    $customer_bank_account: inputCustomerBankAccount
  ) {
    createCustomer(
      createCustomer: {
        from: $from
        first_name: $first_name
        last_name: $last_name
        full_name: $full_name
        email: $email
        phone: $phone
        tax_id: $tax_id
        branch: $branch
        address: $address
        sub_district: $sub_district
        district: $district
        province: $province
        postcode: $postcode
        business_invoice_type: $business_invoice_type
        business_invoice_date_range: $business_invoice_date_range
        payment_bank_account: $payment_bank_account
        business_pay_round: $business_pay_round
        customer_bank_account: $customer_bank_account
      }
    ) {
      _id
    }
  }
`;
