/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { ReactNode } from 'react';
import { Select, Empty } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@apollo/react-hooks';
import SYSTEM_REQUEST_QUERY, { SystemRequestData } from 'shared/graphql/query/systemRequest';

const { Option } = Select;

type SelectSystemRequestFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
};

const SelectSystemRequestField: React.FC<SelectSystemRequestFieldProps> = (props) => {
  const { value, onChange, placeholder, disabled } = props;
  const { data, loading } = useQuery<SystemRequestData>(SYSTEM_REQUEST_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  let options: ReactNode[] = [];
  if (data?.systemRequest) {
    const { system_request } = data.systemRequest;
    options = system_request.map((item) => (
      <Option key={uuidv4()} value={item}>
        {item}
      </Option>
    ));
  }

  return (
    <Select
      style={{
        width: '100%',
      }}
      value={value || undefined}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      placeholder={placeholder || 'เลือกประเภทงาน'}
      showSearch
      onSearch={(valueSearch) => onChange && onChange(valueSearch)}
      onChange={(valueChange) => onChange && onChange(valueChange)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
      allowClear
    >
      {options}
    </Select>
  );
};

export default SelectSystemRequestField;
