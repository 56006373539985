/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import { Divider, Row, Col, Tabs, Skeleton } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { color } from 'shared/utils/styles';
import { Text } from 'shared/components';
import { useFormContext } from 'react-hook-form';
import INVOICE_QUERY, { InvoiceData, InvoiceVars } from 'shared/graphql/query/invoice';
import { BillingNoteContext } from 'views/AccountReceive/common/context/BillingNoteContext';
import { TagStatusInvoice } from 'views/AccountReceive/common/components';
import SelectFieldSearchCustomers from './SelectFieldSearchCustomers';
import InvoiceTable from './InvoiceTable';

const { TabPane } = Tabs;

const InvoiceDetail: React.FC = () => {
  const { selectedInvoiceDetail } = useContext(BillingNoteContext);
  const { invoiceId } = selectedInvoiceDetail;
  const { getValues, setValue, watch } = useFormContext();
  const watchFields = watch(['payerId', 'payerFullName']);
  const { data } = useQuery<InvoiceData, InvoiceVars>(INVOICE_QUERY, {
    variables: {
      _id: invoiceId,
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = <Skeleton />;

  if (data?.invoice) {
    const {
      invoice_status,
      system_request,
      payer,
      invoice_number,
      service_date,
      business_invoice_type,
      billing_note,
      receipt,
      grand_total_price,
      due_date,
    } = data.invoice;

    content = (
      <>
        <Text strong>เงื่อนไขใบแจ้งหนี้</Text>
        <Divider style={{ margin: '10px 0' }} />
        <Row gutter={[32, 16]}>
          <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>:
                  </Col>
                  <Col span={24}>
                    {watchFields && (
                      <SelectFieldSearchCustomers
                        value={{
                          key: getValues('payerId'),
                          label: getValues('payerFullName'),
                        }}
                        onChange={(value) => {
                          setValue('payerId', value.value);
                          setValue('payerFullName', value.label);
                        }}
                        disabled
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={8}>รูปแบบรับใบแจ้งหนี้:</Col>
                  <Col span={16}>
                    <Text strong>{business_invoice_type || '-'}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>วันครบกำหนด:</Col>
                  <Col span={16}>
                    <Text strong>{due_date ? formatDate(due_date) : '-'}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>วันรับชำระ:</Col>
                  <Col span={16}>
                    <Text strong>
                      {receipt?.receipt_date ? formatDate(receipt.receipt_date) : '-'}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
            <Row>
              <Col span={10}>ประเภทงาน:</Col>
              <Col span={14}>
                <Text strong>{system_request}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>ที่อยู่วางบิล:</Col>
              <Col span={14}>
                <Text strong>{payer.address || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เลขประจำตัวผู้เสียภาษี:</Col>
              <Col span={14}>
                <Text strong>{payer.tax_id || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เบอร์โทรศัพท์:</Col>
              <Col span={14}>
                <Text strong>{payer.phone.length > 0 ? payer.phone.join(', ') : '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>อีเมล:</Col>
              <Col span={14}>
                <Text strong>{payer.email.length > 0 ? payer.email.join(', ') : '-'}</Text>
              </Col>
            </Row>
          </Col>

          <Col span={8}>
            <Row>
              <Col span={8}>วันที่เอกสาร:</Col>
              <Col span={16}>
                <Text strong>{formatDate(service_date)}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รหัสใบแจ้งหนี้:</Col>
              <Col span={16}>
                <Text strong>{invoice_number}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>มูลค่าใบแจ้งหนี้:</Col>
              <Col span={16}>
                <Text strong customType="primary">
                  {formatCurrency(grand_total_price)}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>สถานะ:</Col>
              <Col span={16}>
                <TagStatusInvoice status={invoice_status} />
              </Col>
            </Row>
            <Row>
              <Col span={8}>รหัสใบวางบิล:</Col>
              <Col span={16}>
                <Text>{billing_note?.bill_number || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รหัสใบเสร็จ/ใบกำกับ:</Col>
              <Col span={16}>
                <Text>{receipt?.receipt_number || '-'}</Text>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider style={{ margin: 0, marginTop: -5 }} />

        <Tabs defaultActiveKey="1" animated={false}>
          <TabPane
            tab={
              <span>
                รายละเอียดงาน{' '}
                <CheckCircleOutlined
                  style={{
                    color: color.success,
                    marginLeft: 5,
                  }}
                />
              </span>
            }
            key="1"
          >
            <InvoiceTable />
          </TabPane>
        </Tabs>
      </>
    );
  }

  return content;
};

export default InvoiceDetail;
