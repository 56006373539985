import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SubTabs } from 'shared/components';
import COD_STATUS_QUERY, { CodStatusData } from 'shared/graphql/query/newCodStatus';
import { CodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/codStatus.model';

interface CodTabsProps {
  name: string;
  tab: CodStatus;
  onChange: (tab: CodStatus) => void;
}

const CodTabs: React.FC<CodTabsProps> = (props) => {
  const { tab, onChange, name } = props;
  const { data, refetch } = useQuery<CodStatusData>(COD_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const checkSelectTab = (id: CodStatus) => {
    return id === tab && name === 'cod';
  };

  const selectTab = (index: CodStatus) => {
    refetch();
    onChange(index);
  };

  const tabs = [
    {
      id: CodStatus.OPS_PENDING,
      name: 'รอOpsตรวจสอบ',
      color: 'badge-grey',
      count: data?.newCodStatus.ops_pending || 0,
      divider: false,
    },
    {
      id: CodStatus.PENDING,
      name: 'รอตรวจสอบ',
      color: 'badge-grey',
      count: data?.newCodStatus.pending || 0,
      divider: false,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default CodTabs;
