/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import UPDATE_BILLING_NOTE_MUTATION, {
  UpdateBillingNoteVars,
  UpdateBillingNoteData,
} from 'shared/graphql/mutation/updateBillingNote';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { StyledModal } from './Styles';

type ModalConfirmDeleteInvoiceProps = {
  billingNoteId: string;
  invoicesId: string[];
  invoice: {
    invoiceNumber: string;
    grandTotalPrice: number;
  };
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmDeleteInvoice: React.FC<ModalConfirmDeleteInvoiceProps> = (props) => {
  const { onCancel, visible, invoicesId, invoice, billingNoteId } = props;
  const [updateBillingNote, { loading }] = useMutation<
    UpdateBillingNoteData,
    UpdateBillingNoteVars
  >(UPDATE_BILLING_NOTE_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.updateBillingNote._id) {
        message.success('ลบรายการใบแจ้งหนี้แล้ว');
        onCancel();
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['BillingNote', 'BillingNoteStatus', 'BillingNotes', 'InvoiceStatus'],
  });

  const confirmUpdate = () => {
    updateBillingNote({
      variables: {
        _id: billingNoteId,
        bill_invoice: invoicesId.map((invoiceId) => ({ invoice: invoiceId })),
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันลบใบแจ้งหนี้หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<DeleteOutlined />}
          color="red"
          onClick={() => confirmUpdate()}
          loading={loading}
        >
          ลบใบแจ้งหนี้
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>รหัสใบแจ้งหนี้:</Col>
        <Col span={18}>
          <Text strong>{invoice.invoiceNumber}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>มูลค่าใบแจ้งหนี้:</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(invoice.grandTotalPrice)}
          </Text>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmDeleteInvoice;
