/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Button } from 'shared/components';
import { PaymentOrderContext } from 'views/AccountPayment/common/context/PaymentOrderContext';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalDetailPaymentOrder/model/formValues.model';
import CHECK_VENDOR_INVOICE_ID_QUERY, {
  CheckVendorInvoiceIDData,
  CheckVendorInvoiceIDVars,
} from 'shared/graphql/query/checkVendorInvoiceID';
import UPDATE_PAYMENT_MUTATION, {
  UpdatePaymentData,
  UpdatePaymentVars,
} from 'shared/graphql/mutation/updatePayment';
import { useFormContext } from 'react-hook-form';
import useVatCondition from 'views/AccountPayment/Routes/PaymentOrder/ModalDetailPaymentOrder/hooks/useVatCondition';
import { StyledModal } from './Styles';

type ModalCheckVendorInvoiceProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalCheckVendorInvoice: React.FC<ModalCheckVendorInvoiceProps> = (props) => {
  const { onCancel, visible } = props;
  const { getValues, handleSubmit } = useFormContext<FormValues>();
  const allFieldValues = getValues();
  const { selectedPaymentDetail: detailPaymentOrderContext } = useContext(PaymentOrderContext);
  const [selectedPaymentDetail] = detailPaymentOrderContext;
  const { getPaymentList } = useVatCondition();

  const [checkVendorInvoiceId, { data, loading }] = useLazyQuery<
    CheckVendorInvoiceIDData,
    CheckVendorInvoiceIDVars
  >(CHECK_VENDOR_INVOICE_ID_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [updatePayment] = useMutation<UpdatePaymentData, UpdatePaymentVars>(
    UPDATE_PAYMENT_MUTATION,
    {
      update: (_cache, { data: updatePaymentData }) => {
        if (updatePaymentData?.updatePayment._id) {
          message.success('บันทึกข้อมูลใบสั่งจ่ายแล้ว');
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['Payment', 'PaymentStatus', 'Payments'],
    },
  );

  useEffect(() => {
    if (visible) {
      checkVendorInvoiceId({
        variables: {
          vendor_invoice_id: allFieldValues.vendorInvoice,
        },
      });
    }
  }, [allFieldValues.vendorInvoice, checkVendorInvoiceId, visible]);

  const confirmCreate = handleSubmit((values) => {
    updatePayment({
      variables: {
        _id: selectedPaymentDetail.paymentId,
        payee: values.payeeId,
        payment_type: values.paymentType,
        payment_remark: values.paymentRemark,
        save_status: values.saveStatus,
        round_paid: values.roundPaidDate,
        invoice_date: values.paymentInvoiceDate,
        invoice_due_date: values.invoiceDueDate,
        vendor_invoice_id: values.vendorInvoice,
        number_ref: values.numberRef,
        payment_list: getPaymentList.map((item) => ({
          datamaster_service_item_id: item.dataMasterId,
          advance_type: item.advanceType,
          list_number: item.listNumber,
          list_name: item.listName,
          qty: item.qty,
          unit: '-',
          price: item.price,
          discount: item.discount,
          vat: item.vat,
          wht: item.wht,
        })),
        vendor: values.vendorId !== '' ? values.vendorId : null,
        department: values.paymentType !== 'COGs' ? values.department : null,
        service_date: values.serviceDate,
        fixed_asset_check: Number(values.fixedAssetCheck),
      },
    });
    onCancel();
  });
  return (
    <>
      <StyledModal
        className="custom-modal"
        title={`Vendor Invoice นี้ถูกสร้างไปแล้วที่ ${data?.checkVendorInvoiceID?.payment_number}`}
        visible={visible}
        onCancel={onCancel}
        destroyOnClose
        footer={[
          <Button
            key="okButton"
            icon={<CheckCircleOutlined />}
            color="green"
            onClick={() => confirmCreate()}
            loading={loading}
          >
            ใช่, บันทึกใบสั่งจ่าย
          </Button>,
          <Button
            key="cancelButton"
            icon={<CloseCircleOutlined />}
            onClick={onCancel}
            disabled={loading}
          >
            ยกเลิก
          </Button>,
        ]}
      >
        <Row>
          <Col>ต้องการบันทึกหรือไม่? </Col>
        </Row>
      </StyledModal>
    </>
  );
};

export default ModalCheckVendorInvoice;
