/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext } from 'react';
import { Row, Col, Select, Typography, Input, Button, DatePicker, message } from 'antd';
import { FileTextOutlined, SearchOutlined } from '@ant-design/icons';
import baseUrl from 'shared/config/baseUrl';
import { useQuery } from '@apollo/react-hooks';
import { formatDate, formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import _ from 'lodash';
import PAYMENT_VOUCHERS_QUERY from 'shared/graphql/query/paymentVouchers';
import {
  PaymentVoucherProvider,
  PaymentVoucherContext,
} from 'views/AccountPayment/common/context/PaymentVoucherContext';
import moment from 'moment';
import {
  TextExpand,
  TableSearch,
  SelectPayeesField,
  SelectPaymentTypeField,
} from 'shared/components';
import { PaymentVoucherStatus } from 'views/AccountPayment/common/model/paymentVoucherStatus.model';
import { Container, ToolsContainer, StyledCheckbox, StyledButton } from './Styles';
import PaymentVoucherDetail from './PaymentVoucherDetail';
import PaymentVoucherTabs from './PaymentVoucherTabs';
import SelectPaymentField from './SelectPaymentField';
import TagSaveStatus from './TagSaveStatus';
import ModalCreateApp from './ModalCreateApp';

const { Option } = Select;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const PaymentVoucherWrapper = () => {
  return (
    <PaymentVoucherProvider>
      <PaymentVoucher />
    </PaymentVoucherProvider>
  );
};

const PaymentVoucher = () => {
  const [tab, setTab] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentId, setPaymentId] = useState('');
  const [createDate, setCreateDate] = useState('');
  const [voucherNumber, setVoucherNumber] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [roundPaid, setRoundPaid] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [paymentMethod, setPaymentMethod] = useState(undefined);
  const [vendorId, setVendorId] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [orderType, setOrderType] = useState('');
  const {
    selectedPaymentVouchers: selectedPaymentVouchersContext,
    selectedPaymentVoucherDetail: selectedPaymentVoucherDetailContext,
  } = useContext(PaymentVoucherContext);
  const [selectedPaymentVouchers, setSelectedPaymentVouchers] = selectedPaymentVouchersContext;
  const [
    selectedPaymentVoucherDetail,
    setSelectedPaymentVoucherDetail,
  ] = selectedPaymentVoucherDetailContext;

  const confirmCreatePdfZip = () => {
    const vouchersId =
      selectedPaymentVouchers.vouchersId.length === 1
        ? [selectedPaymentVouchers.vouchersId[0], selectedPaymentVouchers.vouchersId[0]]
        : selectedPaymentVouchers.vouchersId;
    const url = `${baseUrl}/GetPdfZip/?name=PV&_id=${vouchersId.join('&_id=')}`;
    window.open(url);
    message.success('ดาวน์โหลดสำเร็จแล้ว');
    setSelectedPaymentVouchers((prevState) => ({
      ...prevState,
      vouchersId: [],
    }));
  };

  const { loading, data } = useQuery(PAYMENT_VOUCHERS_QUERY, {
    variables: {
      create_date: createDate,
      voucher_number: voucherNumber,
      round_paid: roundPaid,
      payee: vendorId,
      due_date: dueDate,
      payment_method: paymentMethod,
      payment: paymentId,
      voucher_status: tab,
      page: currentPage,
      showData: pageSize,
      order_by: orderBy,
      order_type: orderType,
      payment_type: paymentType,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const onCheckAllChange = (check) => {
    if (data) {
      const selectedPaymentVoucherId = selectedPaymentVouchers.vouchersId.map((item) => item);
      const currentPaymentVoucherId = data.paymentVouchers.paymentVouchers.map((item) => item._id);
      const differencePaymentVoucher = _.differenceWith(
        currentPaymentVoucherId,
        selectedPaymentVoucherId,
        _.isEqual,
      );

      if (check) {
        setSelectedPaymentVouchers((prevState) => ({
          ...prevState,
          vouchersId: [...selectedPaymentVoucherId, ...differencePaymentVoucher],
        }));
      }

      if (!check) {
        const filterPaymentVoucherId = selectedPaymentVoucherId.filter(
          (item) => !currentPaymentVoucherId.includes(item),
        );

        setSelectedPaymentVouchers((prevState) => ({
          ...prevState,
          vouchersId: filterPaymentVoucherId,
        }));
      }
    }
  };

  const selectPaymentVoucher = (item, checked) => {
    if (checked) {
      if (selectedPaymentVouchers.vouchersId.length === 0) {
        setSelectedPaymentVouchers((prevState) => ({
          ...prevState,
          paymentType: item.payment_type,
        }));
      }

      setSelectedPaymentVouchers((prevState) => ({
        ...prevState,
        vouchersId: [...prevState.vouchersId, item._id],
      }));
    } else {
      const paymentVouchersFilter = selectedPaymentVouchers.vouchersId.filter(
        (paymentVoucher) => paymentVoucher !== item._id,
      );
      setSelectedPaymentVouchers((prevState) => ({
        ...prevState,
        vouchersId: [...paymentVouchersFilter],
      }));
    }
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
      search: (tab === PaymentVoucherStatus.COMPLETE || tab === PaymentVoucherStatus.CANCEL) && (
        <StyledCheckbox
          onChange={(event) => onCheckAllChange(event.target.checked)}
          checked={data?.paymentVouchers.paymentVouchers.every(
            (item) => selectedPaymentVouchers.vouchersId.indexOf(item._id) !== -1,
          )}
          value={selectedPaymentVouchers.vouchersId}
        />
      ),
    },
    {
      title: 'วันที่เอกสาร',
      dataIndex: 'createDate',
      sortName: 'create_date',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) =>
            values === null
              ? setCreateDate('')
              : setCreateDate(`${values[0].format('YYYY-MM-DD')}:${values[1].format('YYYY-MM-DD')}`)
          }
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
          ranges={{
            วันนี้: [moment(), moment()],
            สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
            เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
            ปีนี้: [moment().startOf('year'), moment().endOf('year')],
          }}
        />
      ),
    },
    {
      title: 'รหัสใบสำคัญจ่าย',
      dataIndex: 'voucherNumber',
      sortName: 'voucher_number',
      width: 150,
      search: (
        <Input
          allowClear
          value={voucherNumber}
          placeholder="ค้นหา"
          onChange={(event) => setVoucherNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'รอบจ่าย',
      dataIndex: 'roundPaid',
      sortName: 'round_paid',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) =>
            values === null
              ? setRoundPaid('')
              : setRoundPaid(`${values[0].format('YYYY-MM-DD')}:${values[1].format('YYYY-MM-DD')}`)
          }
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
          ranges={{
            วันนี้: [moment(), moment()],
            สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
            เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
            ปีนี้: [moment().startOf('year'), moment().endOf('year')],
          }}
        />
      ),
    },
    {
      title: 'ประเภทการจ่าย',
      dataIndex: 'paymentType',
      width: 150,
      search: (
        <SelectPaymentTypeField onChange={(value) => setPaymentType(value)} placeholder="ค้นหา" />
      ),
    },
    ...(tab === 3 || tab === 4
      ? [
          {
            title: 'สถานะ',
            dataIndex: 'saveStatus',
            width: 50,
          },
        ]
      : []),
    {
      title: 'จำนวนตั้งเบิก (บาท)',
      dataIndex: 'totalPrice',
      width: 120,
      align: 'right',
    },
    {
      title: 'ผู้รับเงิน (เจ้าหนี้)',
      dataIndex: 'payeeFullName',
      width: 300,
      search: (
        <SelectPayeesField
          onChange={(value) => (value ? setVendorId(value.payeeId) : setVendorId(undefined))}
        />
      ),
    },
    {
      title: 'วันที่ครบกำหนด',
      dataIndex: 'dueDate',
      sortName: 'due_date',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) =>
            values === null
              ? setDueDate('')
              : setDueDate(`${values[0].format('YYYY-MM-DD')}:${values[1].format('YYYY-MM-DD')}`)
          }
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
          ranges={{
            วันนี้: [moment(), moment()],
            สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
            เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
            ปีนี้: [moment().startOf('year'), moment().endOf('year')],
          }}
        />
      ),
    },
    {
      title: 'ช่องทางการจ่าย',
      dataIndex: 'paymentMethod',
      width: 150,
      search: (
        <Select
          style={{
            width: '100%',
          }}
          allowClear
          placeholder="เลือก"
          onChange={(value) => setPaymentMethod(value)}
          value={paymentMethod}
        >
          <Option value="โอนเงิน/เงินสด">โอนเงิน/เงินสด</Option>
        </Select>
      ),
    },
    {
      title: 'รหัสใบสั่งจ่าย',
      dataIndex: 'paymentNumber',
      width: 170,
      search: <SelectPaymentField onChange={(value) => setPaymentId(value)} />,
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'lastUpdate',
      sortName: 'last_update',
      width: 150,
    },
  ];

  let paymentVouchersData = [];
  let totalDocument = 0;

  if (data && data.paymentVouchers) {
    const { paymentVouchers } = data.paymentVouchers;

    paymentVouchersData = paymentVouchers.map((item, index) => ({
      key: index,
      tools: (
        <ToolsContainer>
          {(item.voucher_status === PaymentVoucherStatus.PENDING ||
            item.voucher_status === PaymentVoucherStatus.COMPLETE ||
            item.voucher_status === PaymentVoucherStatus.CANCEL) && (
            <StyledCheckbox
              checked={
                selectedPaymentVouchers.vouchersId.map((payment) => payment).indexOf(item._id) !==
                -1
              }
              disabled={
                selectedPaymentVouchers.vouchersId.length > 0 &&
                item.voucher_status === PaymentVoucherStatus.PENDING &&
                item.payment_type !== selectedPaymentVouchers.paymentType
              }
              value={item._id}
              onChange={(event) => selectPaymentVoucher(item, event.target.checked)}
            />
          )}
          <Button
            onClick={() => {
              setSelectedPaymentVoucherDetail((prevState) => ({
                ...prevState,
                paymentVoucherId: item._id,
                isOpenModal: true,
                payeeId: item.payee._id,
                paymentType: item.payment_type,
              }));
            }}
            type="grey--text-only"
            icon={<SearchOutlined />}
            size="small"
            style={{
              marginRight: 10,
            }}
          />
          <Button
            disabled={!item.document}
            href={item.document}
            target="_blank"
            type="grey--text-only"
            icon={<FileTextOutlined />}
            size="small"
          />
        </ToolsContainer>
      ),
      createDate: formatDate(item.create_date),
      voucherNumber: item.voucher_number,
      roundPaid: formatDate(item.round_paid),
      paymentType: item.payment_type || '-',
      saveStatus: (
        <TagSaveStatus
          status={!item.save_status ? 1 : item.save_status}
          style={{ width: '100%', textAlign: 'center' }}
        />
      ),
      totalPrice: (
        <Text strong type="primary">
          {formatCurrency(item.total_price)}
        </Text>
      ),
      payeeFullName: item.payee.full_name,
      dueDate: formatDate(item.due_date),
      paymentMethod: item.payment_method,
      paymentNumber: (
        <TextExpand expand={!loading}>
          {item.payment.map((payment) => payment.payment_number).join(', ')}
        </TextExpand>
      ),
      lastUpdate: formatDateTime(item.last_update),
    }));

    totalDocument = data.paymentVouchers.totalDocument;
  }

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={16}>
          <PaymentVoucherTabs
            tab={tab}
            onChange={(value) => {
              setTab(value);
              setCurrentPage(1);
            }}
          />
        </Col>

        <Col span={8}>
          <Row justify="end">
            {tab === PaymentVoucherStatus.PENDING && (
              <Col>
                <StyledButton
                  onClick={() => {
                    setSelectedPaymentVouchers((prevState) => ({
                      ...prevState,
                      isOpenModal: true,
                    }));
                  }}
                  disabled={selectedPaymentVouchers.vouchersId.length === 0}
                  type="blue--light"
                >
                  สร้างรอบจ่าย
                </StyledButton>
              </Col>
            )}
            {(tab === PaymentVoucherStatus.COMPLETE || tab === PaymentVoucherStatus.CANCEL) && (
              <Col>
                <StyledButton
                  type="green"
                  icon={<FileTextOutlined />}
                  onClick={() => {
                    confirmCreatePdfZip();
                  }}
                  disabled={selectedPaymentVouchers.vouchersId.length === 0}
                >
                  ดาวน์โหลดเอกสาร
                </StyledButton>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              setCurrentPage(1);
              setPageSize(value);
            }}
            defaultValue={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={paymentVouchersData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value) => setCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />
      <PaymentVoucherDetail />
      <ModalCreateApp />
    </Container>
  );
};

export default PaymentVoucherWrapper;
