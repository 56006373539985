import gql from 'graphql-tag';

export interface CreateFollowDebtHistoryData {
  createFollowDebtHistory: {
    _id: string;
  };
}

export interface CreateFollowDebtHistoryVars {
  logAR: string;
  invoice: string;
  followStatus: string;
  followStatusDetail: string;
  action: string;
  actionDetail: string;
  nextfollowDate: string;
  detail: string;
  employee: string;
  status: number;
}

export default gql`
  mutation CreateFollowDebtHistory(
    $logAR: ID
    $invoice: ID
    $followStatus: String
    $followStatusDetail: String
    $action: String
    $actionDetail: String
    $nextfollowDate: String
    $detail: String
    $employee: ID
    $status: Int
  ) {
    createFollowDebtHistory(
      create: {
        logAR: $logAR
        invoice: $invoice
        followStatus: $followStatus
        followStatusDetail: $followStatusDetail
        action: $action
        actionDetail: $actionDetail
        nextfollowDate: $nextfollowDate
        detail: $detail
        employee: $employee
        status: $status
      }
    ) {
      _id
    }
  }
`;
