import React from 'react';
import formatCurrency from 'shared/utils/formatCurrency';
import { CodItem } from 'shared/graphql/query/paymentCod';
import { formatDateTime } from 'shared/utils/dateTime';
import { Text } from 'shared/components';
import { StyledTable } from './Styles';

type ExpandedRowProps = {
  data: CodItem[];
};

const columns = [
  {
    title: 'ชื่อ 3PL',
    dataIndex: 'column1',
    width: 150,
  },
  {
    title: 'จำนวนที่ 3PL ต้องโอน (บาท)',
    dataIndex: 'column2',
    align: 'right',
    width: 180,
  },
  {
    title: 'วันที่แจ้งโอน',
    dataIndex: 'column3',
    width: 180,
  },
  {
    title: 'วันที่ส่งสำเร็จ',
    dataIndex: 'column4',
    width: 180,
  },
  {
    title: '',
  },
];

const ExpandedRow: React.FC<ExpandedRowProps> = (props) => {
  const { data } = props;
  const codItems = data.map((item, index) => {
    return {
      key: index,
      column1: item.vendor.full_name || '-',
      column2: (
        <Text customType="primary" strong>
          {formatCurrency(item.total_cod, '-')}
        </Text>
      ),
      column3: item.cod_in_date ? formatDateTime(item.cod_in_date) : '-',
      column4: item.delivered_date ? formatDateTime(item.delivered_date) : '-',
    };
  });

  return (
    <div style={{ marginTop: 10 }}>
      <StyledTable
        scroll={{ x: 'max-content' }}
        pagination={false}
        columns={columns}
        dataSource={codItems}
        size="small"
      />
    </div>
  );
};

export default ExpandedRow;
