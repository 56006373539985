import React, { useState, ReactChild } from 'react';
import { BillingNotesStatus } from 'views/AccountReceive/common/model/billingNoteStatus.model';
import { InvoiceStatus } from 'views/AccountReceive/common/model/invoiceStatus.model';

export interface SelectedBillingNoteDetailType {
  billingNoteId: string;
  billNumber: string;
  billStatus: BillingNotesStatus | null;
  isOpenModal: boolean;
  isLoading: boolean;
  createReceipt: {
    grandTotalPrice: number;
    invoicesId: string[];
  };
  dueDate: string;
  payerFullName: string;
  payerEmail: string[];
  grandTotalPrice: number;
  modalConfirmUpdate: {
    payerFullName: string;
    businessInvoiceType: string;
    grandTotalPrice: number;
    invoices: {
      createDate: string;
      invoiceNumber: string;
      grandTotalPrice: number;
    }[];
  };
  document: string;
}

export interface SelectedBillingNoteType {
  billingNoteId: string[];
}

export interface SelectedInvoicesType {
  invoicesId: string[];
  payerId: string;
  payerFullName: string;
  isOpenModal: boolean;
  isLoading: boolean;
  systemRequest: string;
  businessInvoiceType: string;
  modalConfirmCreateBillingNote: {
    payerFullName: string;
    businessInvoiceType: string;
    grandTotalPrice: number;
    invoices: {
      serviceDate: string;
      invoiceNumber: string;
      grandTotalPrice: number;
      dueDate: string;
    }[];
  };
}

export interface SelectedInvoiceDetailType {
  isOpenModal: boolean;
  invoiceId: string;
  invoiceStatus: InvoiceStatus | null;
  invoiceNumber: string;
  isLoading: boolean;
  systemRequest: string;
  payerId: string;
  payerFullName: string;
  businessInvoiceType: string;
  document: string;
}

interface BillingNoteProps {
  children: ReactChild;
}

interface BillingNote {
  selectedBillingNoteDetail: SelectedBillingNoteDetailType;
  setSelectedBillingNoteDetail: (
    params: (cb: SelectedBillingNoteDetailType) => SelectedBillingNoteDetailType,
  ) => void;
  selectedBillingNote: SelectedBillingNoteType;
  setSelectedBillingNote: (
    params: (cb: SelectedBillingNoteType) => SelectedBillingNoteType,
  ) => void;
  selectedInvoices: SelectedInvoicesType;
  setSelectedInvoices: (params: (cb: SelectedInvoicesType) => SelectedInvoicesType) => void;
  selectedInvoiceDetail: SelectedInvoiceDetailType;
  setSelectedInvoiceDetail: (
    params: (cb: SelectedInvoiceDetailType) => SelectedInvoiceDetailType,
  ) => void;
}

const BillingNoteContext = React.createContext({} as BillingNote);

const BillingNoteProvider: React.FC<BillingNoteProps> = (props) => {
  const { children } = props;
  const [selectedBillingNoteDetail, setSelectedBillingNoteDetail] = useState<
    SelectedBillingNoteDetailType
  >({
    billingNoteId: '',
    billNumber: '',
    billStatus: null,
    isOpenModal: false,
    isLoading: true,
    createReceipt: {
      grandTotalPrice: 0,
      invoicesId: [],
    },
    dueDate: '',
    payerFullName: '',
    payerEmail: [],
    grandTotalPrice: 0,
    modalConfirmUpdate: {
      payerFullName: '',
      businessInvoiceType: '',
      grandTotalPrice: 0,
      invoices: [],
    },
    document: '',
  });

  const [selectedBillingNote, setSelectedBillingNote] = useState<SelectedBillingNoteType>({
    billingNoteId: [],
  });

  const [selectedInvoices, setSelectedInvoices] = useState<SelectedInvoicesType>({
    invoicesId: [],
    payerId: '',
    payerFullName: '',
    isOpenModal: false,
    isLoading: false,
    systemRequest: '',
    businessInvoiceType: '',
    modalConfirmCreateBillingNote: {
      payerFullName: '',
      businessInvoiceType: '',
      grandTotalPrice: 0,
      invoices: [],
    },
  });

  const [selectedInvoiceDetail, setSelectedInvoiceDetail] = useState<SelectedInvoiceDetailType>({
    invoiceId: '',
    invoiceStatus: null,
    invoiceNumber: '',
    isOpenModal: false,
    isLoading: false,
    systemRequest: '',
    payerId: '',
    payerFullName: '',
    businessInvoiceType: '',
    document: '',
  });

  const store = {
    selectedBillingNoteDetail,
    setSelectedBillingNoteDetail,
    selectedBillingNote,
    setSelectedBillingNote,
    selectedInvoices,
    setSelectedInvoices,
    selectedInvoiceDetail,
    setSelectedInvoiceDetail,
  };

  return <BillingNoteContext.Provider value={store}>{children}</BillingNoteContext.Provider>;
};

export { BillingNoteContext, BillingNoteProvider };
