import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Menu, Dropdown, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { MenuUnfoldOutlined, MenuFoldOutlined, DownOutlined } from '@ant-design/icons';
import { AppContext } from 'shared/context/AppContext';
import { removeStoredAuthToken } from 'shared/utils/authToken';
import StyledHeader from './Styles';

const propTypes = {
  collapsed: PropTypes.bool,
  toggle: PropTypes.func,
  textHeader: PropTypes.string.isRequired,
};

const defaultProps = {
  toggle: undefined,
  collapsed: false,
};

const Header = (props) => {
  const history = useHistory();
  const { userDetail } = useContext(AppContext);
  const { name } = userDetail;
  const { collapsed, toggle, textHeader, staticContext, ...except } = props;

  let username = name || 'Username';

  return (
    <StyledHeader {...except}>
      <Row justify="space-between">
        <Col span={12}>
          <div className="header-wrapper">
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: toggle,
            })}
            <div className="text-header">{textHeader}</div>
          </div>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    removeStoredAuthToken();
                    return history.push('/');
                  }}
                >
                  ออกจากระบบ
                </Menu.Item>
              </Menu>
            }
          >
            <Button type="link">
              {username} <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </StyledHeader>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
