/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { ApprovePaymentProvider } from 'views/AccountPayment/common/context/ApprovePaymentContext';
import { AppStatus } from 'views/AccountPayment/common/model/appStatus.model';
import { Container } from './Styles';
import AppTable from './AppTable';
import AppTabs from './AppTabs';

const InvoiceWrapper: React.FC = () => {
  return (
    <ApprovePaymentProvider>
      <ApprovePayment />
    </ApprovePaymentProvider>
  );
};

const ApprovePayment: React.FC = () => {
  const [tabApp, setTabApp] = useState<AppStatus>(AppStatus.PENDING);
  const [tabName, setTabName] = useState<'app'>('app');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={12}>
          <Row>
            <Col>
              <AppTabs
                tab={tabApp}
                name={tabName}
                onChange={(value) => {
                  setTabApp(value);
                  setTabName('app');
                  setCurrentPage(1);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {tabName === 'app' && (
        <AppTable
          status={tabApp}
          pageSize={pageSize}
          onChangePageSize={(value) => setPageSize(value)}
          currentPage={currentPage}
          onChangeCurrentPage={(value) => setCurrentPage(value)}
        />
      )}
    </Container>
  );
};

export default InvoiceWrapper;
