/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, ReactNode } from 'react';
import { Input, message, Row, Col, Select } from 'antd';
import { useRouteMatch, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import VENDORS_QUERY, { VendorsData, VendorsVars } from 'shared/graphql/query/vendors';
import { Button, TableSearch } from 'shared/components';
import { formatDateTime } from 'shared/utils/dateTime';
import { SearchOutlined } from '@ant-design/icons';
import { VendorType } from 'views/Setting/common/model/vendorType.model';
import { VendorStatus } from 'views/Setting/common/model/vendorStatus.model';
import { ToolsContainer } from './Styles';

const { Option } = Select;

type IndividualTableProps = {
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type CreditorListDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: string;
  column4: string;
  column5: string;
};

const IndividualTable: React.FC<IndividualTableProps> = (props) => {
  const match = useRouteMatch();
  const { pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [number, setNumber] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [taxId, setTaxId] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('number');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('1');

  const { loading, error, data } = useQuery<VendorsData, VendorsVars>(VENDORS_QUERY, {
    variables: {
      vendor_type: VendorType.INDIVIDUAL,
      number,
      full_name: fullName,
      tax_id: taxId,
      phone,
      showData: pageSize,
      page: currentPage,
      order_by: orderBy,
      order_type: orderType,
      vendor_status: VendorStatus.USABLE,
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 120,
    },
    {
      title: 'รหัส',
      dataIndex: 'column1',
      width: 150,
      sortName: 'number',
      search: (
        <Input allowClear placeholder="ค้นหา" onChange={(event) => setNumber(event.target.value)} />
      ),
    },
    {
      title: 'ชื่อเจ้าหนี้',
      dataIndex: 'column2',
      width: 250,
      search: (
        <Input
          allowClear
          placeholder="ค้นหา"
          onChange={(event) => setFullName(event.target.value)}
        />
      ),
    },
    {
      title: 'เลขประจำตัวผู้เสียภาษี',
      dataIndex: 'column3',
      width: 200,
      search: (
        <Input allowClear placeholder="ค้นหา" onChange={(event) => setTaxId(event.target.value)} />
      ),
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'column4',
      width: 150,
      search: (
        <Input allowClear placeholder="ค้นหา" onChange={(event) => setPhone(event.target.value)} />
      ),
    },
    {
      title: 'อัพเดตล่าสุด',
      dataIndex: 'column5',
      width: 150,
    },
    {
      title: '',
    },
  ];

  let reportsData: CreditorListDataTable[] = [];
  let totalDocument = 0;

  if (data?.vendors) {
    const { vendors } = data.vendors;
    reportsData = vendors.map(
      (item, index): CreditorListDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <Link to={`${match.path}/edit/${item._id}`}>
              <Button color="grey--text-only" icon={<SearchOutlined />} size="small" />
            </Link>
          </ToolsContainer>
        ),
        column1: item.number || '-',
        column2: item.full_name || '-',
        column3: item.tax_id || '-',
        column4: item.phone.length === 0 ? '-' : item.phone.join(', '),
        column5: formatDateTime(item.last_update),
      }),
    );

    totalDocument = data.vendors.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={reportsData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />
    </>
  );
};

export default IndividualTable;
