/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { CodInContext } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/CodInContext';
import { StyledModal } from './Styles';

type ModalCodCompletedProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalCodCompleted: React.FC<ModalCodCompletedProps> = (props) => {
  const { onCancel, visible } = props;
  const { selectedCodItem } = useContext(CodInContext);
  const { modalCodInCompleted } = selectedCodItem;
  const {
    customerFullName,
    codInNumber,
    bookingNumber,
    totalCod,
    totalVendor,
    confirmStatus,
  } = modalCodInCompleted;

  return (
    <StyledModal
      className="custom-modal"
      title="คุณได้ตรวจสอบรายการครบแล้ว ระบบทำการเปลี่ยนสถานะเป็นรอจ่าย COD"
      visible={visible}
      onCancel={onCancel}
      footer={
        <Button key="okButton" onClick={onCancel}>
          รับทราบ
        </Button>
      }
    >
      <Row>
        <Col span={4}>
          <Row>
            <Col span={24}>ชื่อลูกค้า:</Col>
            <Col span={24}>
              <Text strong>{customerFullName}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={5}>
          <Row>
            <Col span={24}>รหัส COD-IN:</Col>
            <Col span={24}>
              <Text strong>{codInNumber}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Col span={24}>Booking Number:</Col>
            <Col span={24}>
              <Text strong>{bookingNumber}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={5}>
          <Row>
            <Col span={24}>ยอดรวม COD (บาท):</Col>
            <Col span={24}>
              <Text strong customType="primary">
                {formatCurrency(totalCod)}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col span={4}>
          <Row>
            <Col span={24}>3PL ทั้งหมด:</Col>
            <Col span={24}>
              <Text strong>{`${totalVendor}/${confirmStatus}`}</Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalCodCompleted;
