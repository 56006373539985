/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, useContext, useState } from 'react';
import { Row, Col } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { DeleteOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { Button, Text, Spin } from 'shared/components';
import BILLING_NOTE_QUERY, {
  BillingNoteData,
  BillingNoteVars,
  InvoiceDetail,
} from 'shared/graphql/query/billingNote';
import { v4 as uuidv4 } from 'uuid';
import { BillingNoteContext } from 'views/AccountReceive/common/context/BillingNoteContext';
import ModalAddInvoice from 'views/AccountReceive/Routes/BillingNote/ModalAddInvoice';
import { StyledTable } from './Styles';
import ExpandedRow from './ExpandedRow';
import ModalConfirmDeleteInvoice from './ModalConfirmDeleteInvoice';
import ModalConfirmAddInvoice from './ModalConfirmAddInvoice';

type InvoiceDataTable = {
  key: string;
  tools: ReactNode;
  column1: ReactNode;
  column2: ReactNode;
  column3: ReactNode;
  InvoiceDetail: InvoiceDetail[] | null;
};

const columns = [
  {
    title: 'เครื่องมือ',
    dataIndex: 'tools',
    width: 70,
  },
  {
    title: 'วันที่เอกสาร',
    dataIndex: 'column1',
    width: 110,
  },
  {
    title: 'รหัสใบแจ้งหนี้',
    dataIndex: 'column2',
    width: 110,
  },
  {
    title: 'มูลค่าใบแจ้งหนี้',
    dataIndex: 'column3',
    align: 'right',
    width: 110,
  },
  {
    title: '',
  },
];

type SelectedInvoice = {
  id: string;
  serviceDate: string;
  invoiceNumber: string;
  grandTotalPrice: number;
};

const BillingNoteDetailTable: React.FC = () => {
  const [isOpenModalAddInvoice, setOpenModalAddInvoice] = useState<boolean>(false);
  const [isOpenModalConfirmAddInvoice, setOpenModalConfirmAddInvoice] = useState<boolean>(false);
  const [isOpenModalDeleteInvoice, setOpenModalDeleteInvoice] = useState<boolean>(false);
  const [selectedInvoiceDelete, setSelectedInvoiceDelete] = useState<SelectedInvoice>({
    id: '',
    invoiceNumber: '',
    serviceDate: '',
    grandTotalPrice: 0,
  });
  const [selectedInvoiceAdd, setSelectedInvoiceAdd] = useState<SelectedInvoice[]>([]);
  const [invoicesIdSendUpdate, setInvoicesIdSendUpdate] = useState<string[]>([]);
  const { selectedBillingNoteDetail } = useContext(BillingNoteContext);
  const { billingNoteId, billStatus } = selectedBillingNoteDetail;
  const { error, data, loading } = useQuery<BillingNoteData, BillingNoteVars>(BILLING_NOTE_QUERY, {
    variables: {
      _id: billingNoteId,
    },
    fetchPolicy: 'cache-and-network',
  });

  let content: ReactNode = <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  let billingNoteData: InvoiceDataTable[] = [];
  if (data && data.billingNote) {
    const { bill_invoice, payer } = data.billingNote;

    billingNoteData = bill_invoice.map(
      (item): InvoiceDataTable => {
        const {
          invoice: { service_date, _id, invoice_number, grand_total_price, invoice_detail },
        } = item;

        return {
          key: uuidv4(),
          tools: (
            <Button
              // disabled={bill_invoice.length < 2 || billStatus !== 1}
              disabled={billStatus !== 1}
              title="ลบ"
              color="red--outline"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => {
                setInvoicesIdSendUpdate(
                  bill_invoice
                    .map((invoiceItem) => invoiceItem.invoice._id)
                    .filter((invoiceId) => invoiceId !== _id),
                );
                setOpenModalDeleteInvoice(true);
                setSelectedInvoiceDelete({
                  id: _id,
                  invoiceNumber: invoice_number,
                  grandTotalPrice: grand_total_price,
                  serviceDate: service_date,
                });
              }}
            />
          ),
          column1: service_date ? formatDate(service_date) : '-',
          column2: invoice_number || '-',
          column3: (
            <Text customType="primary" strong>
              {formatCurrency(grand_total_price, '-')}
            </Text>
          ),
          InvoiceDetail: invoice_detail,
        };
      },
    );

    content = (
      <>
        <StyledTable
          pagination={false}
          columns={columns}
          dataSource={billingNoteData}
          size="small"
          loading={loading}
          expandable={{
            expandedRowRender: (record: { InvoiceDetail: InvoiceDetail[] }) => (
              <ExpandedRow data={record.InvoiceDetail} />
            ),
          }}
        />

        <ModalConfirmDeleteInvoice
          billingNoteId={billingNoteId}
          invoice={selectedInvoiceDelete}
          invoicesId={invoicesIdSendUpdate}
          visible={isOpenModalDeleteInvoice}
          onCancel={() => setOpenModalDeleteInvoice(false)}
        />

        <Row justify="space-between" style={{ marginTop: 10 }}>
          <Col>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button
                  disabled={billStatus !== 1}
                  color="blue--light"
                  icon={<PlusCircleOutlined />}
                  onClick={() => setOpenModalAddInvoice(true)}
                >
                  เพิ่มใบแจ้งหนี้
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <ModalAddInvoice
          visible={isOpenModalAddInvoice}
          onOk={(invoices) => {
            const oldInvoices: SelectedInvoice[] = bill_invoice.map((invoiceItem) => ({
              id: invoiceItem.invoice._id,
              serviceDate: invoiceItem.invoice.service_date,
              grandTotalPrice: invoiceItem.invoice.grand_total_price,
              invoiceNumber: invoiceItem.invoice.invoice_number,
            }));
            const newInvoices = [...invoices];
            setSelectedInvoiceAdd([...oldInvoices, ...newInvoices]);
            setOpenModalAddInvoice(false);
            setOpenModalConfirmAddInvoice(true);
          }}
          onCancel={() => setOpenModalAddInvoice(false)}
          customerId={payer._id}
        />

        <ModalConfirmAddInvoice
          billingNoteId={billingNoteId}
          invoices={selectedInvoiceAdd}
          visible={isOpenModalConfirmAddInvoice}
          onCancel={() => setOpenModalConfirmAddInvoice(false)}
        />
      </>
    );
  }

  if (error) {
    content = error.message;
  }

  return <div>{content}</div>;
};

export default BillingNoteDetailTable;
