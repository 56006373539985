import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error,
      info,
    });
  }

  render() {
    const { hasError, error, info } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div>
          <h3>Oops, something went wrong :(</h3>
          <p>The error: {error.toString()}</p>
          <p>Where it occured: {info.componentStack}</p>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
