import gql from 'graphql-tag';
import { CodOutStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codOutStatus.model';

export interface CodOutParcelPayWithBBLData {
  codOutParcelPayWithBBL: {
    _id: string;
  };
}

export interface CodOutParcelPayWithBBLVars {
  _id: string;
  cod_out_status?: CodOutStatus;
  payment_method?: string;
  bank_account?: {
    bank_name: string;
    bank_branch: string;
    account_name: string;
    account_number: string;
  };
  slip_remark?: string;
  employee_create_transection?: string;
  employee_approver?: string;
  transectionId: string;
}

export default gql`
  mutation CodOutParcelPayWithBBL(
    $_id: ID!
    $cod_out_status: Int
    $payment_method: String
    $bank_account: inputBankAccount
    $slip_remark: String
    $employee_create_transection: ID
    $employee_approver: ID
    $transectionId: ID
  ) {
    codOutParcelPayWithBBL(
      _id: $_id
      update: {
        cod_out_status: $cod_out_status
        payment_method: $payment_method
        bank_account: $bank_account
        slip_remark: $slip_remark
        employee_create_transection: $employee_create_transection
        employee_approver: $employee_approver
        transectionId: $transectionId
      }
    ) {
      _id
    }
  }
`;
