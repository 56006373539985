/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect } from 'react';
import {
  ParcelCostingContext,
  ParcelCostingDetailType,
} from 'views/Costing/common/context/ParcelCostingContext';
import { Spin } from 'shared/components';
import { useQuery } from '@apollo/react-hooks';
import PARCEL_COST_QUERY, { ParcelCostData, ParcelCostVars } from 'shared/graphql/query/parcelCost';
import Header from './Header';
import CostingDetail from './CostingDetail';

type ModalCostingDetailProps = {
  costingId: string;
  backUrl: string;
};

const ModalParcelCostingDetail: React.FC<ModalCostingDetailProps> = (props) => {
  const { costingId, backUrl } = props;
  const { selectedCostingDetail, setSelectedCostingDetail } = useContext(ParcelCostingContext);
  const { isLoading } = selectedCostingDetail!;

  const { loading, error, data } = useQuery<ParcelCostData, ParcelCostVars>(PARCEL_COST_QUERY, {
    variables: {
      _id: costingId,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (costingId) {
      setSelectedCostingDetail!(
        (prevState): ParcelCostingDetailType => ({
          ...prevState,
          costingId,
          backUrl,
        }),
      );
    }
  }, [costingId, setSelectedCostingDetail, backUrl]);

  useEffect(() => {
    if (!loading && !error && data && data.parcelCost) {
      const {
        costing_status,
        costing_number,
        total_after_discount,
        refNumber,
        edit_request_status,
        grand_total_price,
      } = data.parcelCost;

      setSelectedCostingDetail!(
        (prevState): ParcelCostingDetailType => ({
          ...prevState,
          costingStatus: costing_status,
          costingNumber: costing_number,
          isLoading: false,
          modalConfirmChecked: {
            refNumber,
            costingNumber: costing_number,
            totalAfterDiscount: total_after_discount,
          },
          modalConfirmReject: {
            costingNumber: costing_number,
            grandTotalPrice: grand_total_price,
          },
          editRequest: edit_request_status,
        }),
      );
    }

    return () => {
      setSelectedCostingDetail!(
        (prevState): ParcelCostingDetailType => ({
          ...prevState,
          isLoading: true,
        }),
      );
    };
  }, [costingId, data, error, loading, setSelectedCostingDetail]);

  return (
    <Spin spinning={isLoading}>
      <Header />
      <CostingDetail />
    </Spin>
  );
};

export default ModalParcelCostingDetail;
