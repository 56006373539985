import React from 'react';
import { Tag } from 'shared/components';
import { TagColorType } from 'shared/components/Tag';
import { InvoiceStatus } from 'views/AccountReceive/common/model/invoiceStatus.model';

type TagStatusInvoiceProps = {
  status: InvoiceStatus;
};

const getTextColorStatus = (
  status: InvoiceStatus,
): {
  color: TagColorType | undefined;
  text: string | number;
  outline: boolean;
} => {
  switch (status) {
    case 1:
      return { color: 'blue', text: 'รอสร้างใบแจ้งหนี้', outline: false };
    case 2:
      return { color: 'blue', text: 'รอสร้างใบวางบิล', outline: false };
    case 3:
      return { color: 'blue', text: 'วางบิลแล้ว', outline: false };
    case 4:
      return { color: 'green', text: 'ออกใบเสร็จแล้ว', outline: false };
    case 5:
      return { color: 'red--light', text: 'ยกเลิก', outline: false };
    case 6:
      return { color: 'red--light', text: 'แก้ไข', outline: false };
    default:
      return { color: undefined, text: status, outline: false };
  }
};

const TagStatusInvoice: React.FC<TagStatusInvoiceProps> = (props) => {
  const { status } = props;
  const textColor = getTextColorStatus(status);

  return (
    <Tag customColor={textColor.color} outline={textColor.outline}>
      {textColor.text}
    </Tag>
  );
};

export default TagStatusInvoice;
