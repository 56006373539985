import gql from 'graphql-tag';

export interface Documents {
  _id: string;
  create_date: string;
  document_type: number;
  document_file: string;
  delete: boolean;
  list_ref: string[];
}

export interface PaymentDocumentsData {
  paymentDocuments: {
    documents: Documents[];
  };
}

export interface PaymentDocumentsVars {
  payment_id: string;
  document_type?: number;
}

export default gql`
  query PaymentDocuments($payment_id: ID!, $document_type: Int) {
    paymentDocuments(
      params: { payment_id: $payment_id, document_type: $document_type, delete: false }
    ) {
      documents {
        _id
        create_date
        document_type
        document_file
        delete
        list_ref
      }
    }
  }
`;
