/* eslint-disable no-underscore-dangle */
import React, { ReactNode } from 'react';
import { Row, Col, message, Modal, Typography } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PAYMENT_DOCUMENTS_QUERY, {
  PaymentDocumentsData,
  PaymentDocumentsVars,
} from 'shared/graphql/query/paymentDocuments';
import DELETE_PAYMENT_DOCUMENTS_MUTATION, {
  DeletePaymentDocumentsData,
  DeletePaymentDocumentsVars,
} from 'shared/graphql/mutation/deletePaymentDocuments';
import { Spin, Button } from 'shared/components';
import { FileContainer } from './Styles';

const { confirm } = Modal;
const { Text } = Typography;

type DocumentListProps = {
  paymentId: string;
};

const DocumentList: React.FC<DocumentListProps> = (props) => {
  const { paymentId } = props;

  const [deletePaymentDocuments, { loading: deletePaymentDocumentsLoading }] = useMutation<
    DeletePaymentDocumentsData,
    DeletePaymentDocumentsVars
  >(DELETE_PAYMENT_DOCUMENTS_MUTATION, {
    update: () => {
      message.success('ลบไฟล์เอกสารแล้ว');
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['PaymentDocuments'],
  });
  const { loading, data } = useQuery<PaymentDocumentsData, PaymentDocumentsVars>(
    PAYMENT_DOCUMENTS_QUERY,
    {
      variables: {
        payment_id: paymentId,
        document_type: 2,
      },
      onError: (err) => {
        message.error(err.message);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  let documentList: ReactNode[] = [];

  const showConfirmDeleteDocument = (id: string) => {
    const modal = {
      title: 'ยืนยัน',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          ต้องการ <Text strong>ลบไฟล์เอกสาร</Text> ใช่หรือไม่?
        </>
      ),
      okText: 'ตกลง',
      onOk() {
        deletePaymentDocuments({
          variables: {
            _id: [id],
          },
        });
      },
      cancelText: 'ยกเลิก',
    };

    confirm(modal);
  };

  if (data && data.paymentDocuments) {
    const { documents } = data.paymentDocuments;
    documentList = documents.map((item) => (
      <Col key={item._id} span={24}>
        <FileContainer>
          <Button
            onClick={() => showConfirmDeleteDocument(item._id)}
            icon={<DeleteOutlined />}
            color="red--outline"
            style={{ marginRight: 10 }}
          />
          <a href={item.document_file} target="_blank" rel="noopener noreferrer">
            {item.document_file}
          </a>
        </FileContainer>
      </Col>
    ));
  }

  return (
    <Spin spinning={loading || deletePaymentDocumentsLoading}>
      <Row gutter={[16, 16]}>{documentList}</Row>
    </Spin>
  );
};

export default DocumentList;
