/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Modal, Typography, Row, message } from 'antd';
import { Button } from 'shared/components';
import { useMutation } from '@apollo/react-hooks';
import { PaymentOrderContext } from 'views/AccountPayment/common/context/PaymentOrderContext';
import UPDATE_PAYMENT_MUTATION, {
  UpdatePaymentData,
  UpdatePaymentVars,
} from 'shared/graphql/mutation/updatePayment';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalDetailPaymentOrder/model/formValues.model';
import { useFormContext } from 'react-hook-form';
import formatCurrency from 'shared/utils/formatCurrency';

const { Text } = Typography;

type ModalConfirmPendingProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmPending: React.FC<ModalConfirmPendingProps> = (props) => {
  const { onCancel, visible } = props;
  const { selectedPaymentDetail: selectedPaymentDetailContext } = useContext(PaymentOrderContext);
  const [selectedPaymentDetail, setSelectedPaymentDetail] = selectedPaymentDetailContext;
  const { watch, reset } = useFormContext<FormValues>();

  const watchAllField = watch();

  const closeModalHandler = () => {
    setSelectedPaymentDetail((prevState) => ({
      ...prevState,
      isOpenModal: false,
    }));
  };

  const [updatePayment, { loading }] = useMutation<UpdatePaymentData, UpdatePaymentVars>(
    UPDATE_PAYMENT_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.updatePayment._id) {
          message.success('เปลี่ยนสถานะใบสั่งจ่ายแล้ว');
          onCancel();
          reset();
          closeModalHandler();
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['Payment', 'PaymentStatus', 'Payments'],
    },
  );

  const confirmReturn = () => {
    updatePayment({
      variables: {
        _id: selectedPaymentDetail.paymentId,
        payment_status: 2,
      },
    });
  };

  return (
    <Modal
      title="ตรวจสอบใบสั่งจ่ายนี้หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmReturn()}
          loading={loading}
        >
          ตรวจสอบแล้ว
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>ผู้ตั้งเบิก:</Col>
        <Col span={18}>
          <Text strong>{watchAllField.employeeFullName}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>ผู้รับเงิน:</Col>
        <Col span={18}>
          <Text strong>{watchAllField.payeeName}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>มูลค่าใบสั่งจ่าย:</Col>
        <Col span={18}>
          <Text strong>{formatCurrency(watchAllField.grandTotalPrice, '0')}</Text>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalConfirmPending;
