/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import { Divider, Row, Col, Tabs, Skeleton, Select, Input, DatePicker } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import formatCurrency from 'shared/utils/formatCurrency';
import { color } from 'shared/utils/styles';
import { Text } from 'shared/components';
import APP_QUERY, { AppData, AppVars } from 'shared/graphql/query/app';
import { ApprovePaymentContext } from 'views/AccountPayment/common/context/ApprovePaymentContext';
import { useFormContext, Controller } from 'react-hook-form';
import { FormValues } from 'views/AccountPayment/Routes/ApprovePayment/AppTable/ModalAppDetail/model/formValues.model';
import { TagStatusApp } from 'views/AccountPayment/common/components';
import moment from 'moment';
import { AppStatus } from 'views/AccountPayment/common/model/appStatus.model';
import PaymentVoucherTable from './PaymentVoucherTable';
import PaymentVoucherProofSlip from './PaymentVoucherProofSlip';

const { TabPane } = Tabs;
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';

const AppDetail: React.FC = () => {
  const { selectedApprovePaymentDetail } = useContext(ApprovePaymentContext);
  const { control } = useFormContext<FormValues>();
  const { appId, appStatus } = selectedApprovePaymentDetail;
  const { data } = useQuery<AppData, AppVars>(APP_QUERY, {
    variables: {
      _id: appId,
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = <Skeleton />;

  if (data?.app) {
    const {
      payment_method,
      payment_type,
      bank_account: { account_number },
      app_number,
      grand_total_price,
      app_status,
      proof_slip,
      cancle_remark,
    } = data.app;

    content = (
      <>
        <Text strong>เงื่อนไขรอบจ่าย</Text>
        <Divider style={{ margin: '10px 0' }} />
        <Row gutter={[32, 16]}>
          <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Row justify="space-between">
                  <Col>
                    ช่องทางการจ่าย<Text customType="danger">*</Text>
                  </Col>
                  <Col span={24}>
                    <Select value={payment_method} disabled style={{ width: '100%' }}>
                      <Option value="โอนเงิน">โอนเงิน</Option>
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col>
                    บัญชีที่ต้องการโอน<Text customType="danger">*</Text>
                  </Col>
                  <Col span={24}>
                    <Input value={account_number} disabled />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Row justify="space-between">
                  <Col>
                    ประเภทการจ่าย<Text customType="danger">*</Text>
                  </Col>
                  <Col span={24}>
                    <Select value={payment_type} disabled style={{ width: '100%' }}>
                      <Option value="OPEX">OPEX</Option>
                      <Option value="COGs">COGs</Option>
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify="space-between">
                  <Col>
                    วันที่รอบจ่าย<Text customType="danger">*</Text>
                  </Col>
                  <Col span={24}>
                    <Controller
                      control={control}
                      name="roundPaid"
                      render={({ onChange, value }) => (
                        <DatePicker
                          style={{ width: '100%' }}
                          allowClear={false}
                          value={value ? moment(value, dateFormat) : null}
                          placeholder="วันที่รอบจ่าย"
                          onChange={(valueDate) => {
                            if (!valueDate) {
                              onChange('');
                            } else {
                              onChange(valueDate.format(dateFormat));
                            }
                          }}
                          disabled={
                            appStatus === AppStatus.PAID || appStatus === AppStatus.APPROVED
                          }
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={8}>
            <Row>
              <Col span={8}>รหัสรอบจ่าย:</Col>
              <Col span={16}>
                <Text strong>{app_number}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>มูลค่ารอบจ่าย:</Col>
              <Col span={16}>
                <Text strong customType="primary">
                  {formatCurrency(grand_total_price)}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>สถานะ:</Col>
              <Col span={16}>
                <TagStatusApp status={app_status} />
              </Col>
            </Row>
            {cancle_remark && (
              <Row>
                <Col span={8}>เหตุผลยกเลิก:</Col>
                <Col span={16}>
                  <Text customType="danger">{cancle_remark}</Text>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Divider style={{ margin: 0, marginTop: -5 }} />

        <Tabs defaultActiveKey="1" animated={false}>
          <TabPane
            tab={
              <span>
                รายการใบสำคัญจ่าย{' '}
                <CheckCircleOutlined
                  style={{
                    color: color.success,
                    marginLeft: 5,
                  }}
                />
              </span>
            }
            key="1"
          >
            <PaymentVoucherTable />
          </TabPane>

          {appStatus === AppStatus.PAID && (
            <TabPane
              tab={
                <span>
                  หลักฐานการจ่าย{' '}
                  <CheckCircleOutlined
                    style={{
                      color: color.success,
                      marginLeft: 5,
                    }}
                  />
                </span>
              }
              key="2"
            >
              <PaymentVoucherProofSlip filesPath={[proof_slip]} />
            </TabPane>
          )}
        </Tabs>
      </>
    );
  }

  return content;
};

export default AppDetail;
