import React, { useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  PaymentOrderContext,
  CreatePaymentInsuranceType,
} from 'views/AccountPayment/common/context/PaymentOrderContext';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalCreatePaymentInsurance/model/formValues.model';
import Header from './Header';
import CreatePaymentOrder from './CreatePaymentInsurance';
import { StyledModal } from './Styles';

const validateSchema = yup.object().shape({
  payeeId: yup.string().required(),
  paymentNumber: yup.string().required(),
});

const initialValue = {
  payeeId: '',
  paymentNumber: '',
};

const ModalCreatePaymentInsuranceWrapper = () => {
  const { createPaymentInsurance: createPaymentInsuranceContext } = useContext(PaymentOrderContext);
  const [createPaymentInsurance, setCreatePaymentInsurance] = createPaymentInsuranceContext;
  const { isOpenModal } = createPaymentInsurance;
  return (
    <StyledModal
      visible={isOpenModal}
      onCancel={() => {
        setCreatePaymentInsurance(
          (prevState): CreatePaymentInsuranceType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalCreatePaymentInsurance />
    </StyledModal>
  );
};

const ModalCreatePaymentInsurance: React.FC = () => {
  const methods = useForm<FormValues>({
    mode: 'onChange',
    shouldFocusError: true,
    shouldUnregister: false,
    resolver: yupResolver(validateSchema),
    defaultValues: initialValue,
  });

  return (
    <FormProvider {...methods}>
      <Header />
      <CreatePaymentOrder />
    </FormProvider>
  );
};

export default ModalCreatePaymentInsuranceWrapper;
