/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'shared/components';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalCreatePaymentInsurance/model/formValues.model';
import CREATE_PAYMENT_INSURANCE_MUTATION, {
  CreatePaymentInsuranceData,
  CreatePaymentInsuranceVars,
} from 'shared/graphql/mutation/createPaymentInsurance';
import { useFormContext } from 'react-hook-form';
import { PaymentOrderContext } from 'views/AccountPayment/common/context/PaymentOrderContext';
import { StyledModal } from './Styles';

type ModalConfirmProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirm: React.FC<ModalConfirmProps> = (props) => {
  const { createPaymentInsurance: createPaymentInsuranceContext } = useContext(PaymentOrderContext);
  const [, setCreatePaymentInsurance] = createPaymentInsuranceContext;
  const { onCancel, visible } = props;
  const { reset, handleSubmit } = useFormContext<FormValues>();

  const [createInvoiceFromExcel, { loading }] = useMutation<
    CreatePaymentInsuranceData,
    CreatePaymentInsuranceVars
  >(CREATE_PAYMENT_INSURANCE_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.createPaymentInsurance?.result) {
        message.success(`${data.createPaymentInsurance.result}`);
        reset();
        onCancel();
        setCreatePaymentInsurance((prevState) => ({
          ...prevState,
          isOpenModal: false,
        }));
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['Payments', 'Payment'],
  });

  const onSubmit = handleSubmit((values) => {
    createInvoiceFromExcel({
      variables: {
        payee: values.payeeId,
        payment_number: values.paymentNumber,
      },
    });
  });

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันหรือไม่?"
      visible={visible}
      onCancel={() => onCancel()}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => onSubmit()}
          loading={loading}
        >
          ใช่
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={() => onCancel()}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <div>ยืนยันการสร้าง</div>
    </StyledModal>
  );
};

export default ModalConfirm;
