import gql from 'graphql-tag';

export interface CustomersVars {
  _id?: string;
  tax_id?: string;
  full_name?: string;
  showData?: number;
  page?: number;
  order_by?: string;
  order_type?: '1' | '-1' | '';
}

export interface Customers {
  _id: string;
  number: string;
  full_name: string;
  tax_id: string;
  phone: string[];
  last_update: string;
}

export interface CustomersData {
  customers: {
    customers: Customers[];
    totalDocument: number;
  };
}

export default gql`
  query Customers(
    $_id: ID
    $tax_id: ID
    $full_name: String
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
  ) {
    customers(
      params: { _id: $_id, tax_id: $tax_id, full_name: $full_name }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      customers {
        _id
        number
        full_name
        tax_id
        phone
        last_update
      }
      totalDocument
    }
  }
`;
