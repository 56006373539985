/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { useRouteMatch, Link } from 'react-router-dom';
import { Button } from 'shared/components';
import { CustomerStatus } from 'views/Setting/Routes/Customer/common/model/customerStatus.model';
import { Container } from './Styles';
import CustomerTable from './CustomerTable';
import CustomerTab from './CustomerTab';

const CustomerList: React.FC = () => {
  const match = useRouteMatch();
  const [tab, setTab] = useState<CustomerStatus>(CustomerStatus.active);
  const [tabName, setTabName] = useState<'customer'>('customer');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={12}>
          <Row>
            <Col>
              <CustomerTab
                tab={tab}
                name={tabName}
                onChange={(value) => {
                  setTab(value);
                  setTabName('customer');
                  setCurrentPage(1);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Col>
              <Link to={`${match.path}/create`}>
                <Button color="green">เพิ่มรายชื่อลูกหนี้</Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      {tabName === 'customer' && (
        <CustomerTable
          pageSize={pageSize}
          onChangePageSize={(value) => setPageSize(value)}
          currentPage={currentPage}
          onChangeCurrentPage={(value) => setCurrentPage(value)}
        />
      )}
    </Container>
  );
};

export default CustomerList;
