import React from 'react';
import { Row, Col } from 'antd';
import { Text } from 'shared/components';
import DocumentList from './DocumentList';

type PaymentVoucherProofSlipProps = {
  filesPath: string[];
};

const PaymentVoucherProofSlip: React.FC<PaymentVoucherProofSlipProps> = (props) => {
  const { filesPath } = props;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        หลักฐานการชำระ
        <div>
          <Text customType="grey">
            (รูปแบบไฟล์ที่รองรับ .jpg , .jpeg , .png , .pdf , และขนาดไฟล์ไม่เกิน 2Mb.)
          </Text>
        </div>
      </Col>
      <Col span={24}>
        <DocumentList filesPath={filesPath} />
      </Col>
    </Row>
  );
};

export default PaymentVoucherProofSlip;
