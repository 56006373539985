/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext, ReactNode } from 'react';
import moment from 'moment';
import { Input, DatePicker, message, Row, Col, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { formatDate, formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import BILLING_NOTES_QUERY, {
  BillingNotesData,
  BillingNotesVars,
  BillingNotesList,
} from 'shared/graphql/query/billingNotes';
import {
  BillingNoteContext,
  SelectedBillingNoteDetailType,
  SelectedBillingNoteType,
} from 'views/AccountReceive/common/context/BillingNoteContext';
import { Text, Button, TableSearch, SelectSystemRequestField } from 'shared/components';
import SelectCustomersField from 'views/AccountReceive/Routes/BillingNote/SelectCustomersField';
import { BillingNotesStatus } from 'views/AccountReceive/common/model/billingNoteStatus.model';
import SelectEmployeeField from 'views/AccountReceive/Routes/BillingNote/SelectEmployeeField';
import { BusinessInvoiceType } from 'views/AccountReceive/common/model/businessInvoiceType.model';
import { SelectBusinessTypeField } from 'views/AccountReceive/common/components';
import { ToolsContainer, StyledCheckbox } from './Styles';
import ModalBillingNoteDetail from './ModalBillingNoteDetail';

const { Option } = Select;
const { RangePicker } = DatePicker;

type BillingNotesTableProps = {
  status: BillingNotesStatus;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type BillingNotesDataTable = {
  key: number;
  tools: ReactNode;
  serviceDate: string;
  billNumber: string;
  grandTotalPriceBillingNote: ReactNode;
  dueDate: string;
  payerFullName: string;
  businessInvoiceType: string;
  systemRequest: string;
  employeeFullName: string;
  createDate: string;
  lastUpdate: string;
};

const BillingNotesTable: React.FC<BillingNotesTableProps> = (props) => {
  const { status, pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [serviceDate, setServiceDate] = useState<string>('');
  const [billNumber, setBillNumber] = useState<string>('');
  const [dueDate, setDueDate] = useState<string>('');
  const [payerId, setPayerId] = useState<string>('');
  const [businessInvoiceType, setBusinessInvoiceType] = useState<BusinessInvoiceType>();
  const [businessInvoiceDateRange, setBusinessInvoiceDateRange] = useState<string>('');
  const [systemRequest, setSystemRequest] = useState<string>('');
  const [employeeId, setEmployeeId] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('');
  const [createDate, setCreateDate] = useState<string>('');
  const { setSelectedBillingNoteDetail, selectedBillingNote, setSelectedBillingNote } = useContext(
    BillingNoteContext,
  );

  const { loading, error, data } = useQuery<BillingNotesData, BillingNotesVars>(
    BILLING_NOTES_QUERY,
    {
      variables: {
        service_date: serviceDate,
        bill_number: billNumber,
        payer: payerId,
        due_date: dueDate,
        page: currentPage,
        showData: pageSize,
        bill_status: status,
        system_request: systemRequest,
        employee: employeeId,
        business_invoice_type: businessInvoiceType,
        business_invoice_date_range: +businessInvoiceDateRange,
        order_by: orderBy,
        order_type: orderType,
        create_date: createDate,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const onCheckAllChange = (check: boolean) => {
    if (data) {
      const selectedBillingId = selectedBillingNote.billingNoteId.map((item) => item);
      const currentBillingId = data.billingNotes.billingNotes.map((item) => item._id);
      const differenceBilling = _.differenceWith(currentBillingId, selectedBillingId, _.isEqual);

      if (check) {
        setSelectedBillingNote(
          (prevState): SelectedBillingNoteType => ({
            ...prevState,
            billingNoteId: [...selectedBillingId, ...differenceBilling],
          }),
        );
      }

      if (!check) {
        const filterBillingId = selectedBillingId.filter(
          (item) => !currentBillingId.includes(item),
        );

        setSelectedBillingNote(
          (prevState): SelectedBillingNoteType => ({
            ...prevState,
            billingNoteId: filterBillingId,
          }),
        );
      }
    }
  };

  const selectBillingNote = (_item: BillingNotesList, checked: boolean, value: string) => {
    if (checked) {
      if (selectedBillingNote.billingNoteId.length === 0) {
        setSelectedBillingNote(
          (prevState): SelectedBillingNoteType => ({
            ...prevState,
          }),
        );
      }

      setSelectedBillingNote(
        (prevState): SelectedBillingNoteType => ({
          ...prevState,
          billingNoteId: [...prevState.billingNoteId, value],
        }),
      );
    } else {
      const billingNoteIdFilter = selectedBillingNote.billingNoteId.filter(
        (billingNoteId) => billingNoteId !== value,
      );
      setSelectedBillingNote(
        (prevState): SelectedBillingNoteType => ({
          ...prevState,
          billingNoteId: [...billingNoteIdFilter],
        }),
      );
    }
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
      search: status === BillingNotesStatus.WAITING && (
        <StyledCheckbox
          onChange={(event) => onCheckAllChange(event.target.checked)}
          checked={data?.billingNotes.billingNotes.every(
            (item) => selectedBillingNote.billingNoteId.indexOf(item._id) !== -1,
          )}
          value={selectedBillingNote.billingNoteId}
        />
      ),
    },
    {
      title: 'วันที่เอกสาร',
      dataIndex: 'serviceDate',
      sortName: 'service_date',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) => {
            if (values === null) {
              setServiceDate('');
              return;
            }

            setServiceDate(`${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'รหัสใบวางบิล',
      dataIndex: 'billNumber',
      sortName: 'bill_number',
      width: 150,
      search: (
        <Input
          allowClear
          value={billNumber}
          placeholder="ค้นหา"
          onChange={(event) => setBillNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'มูลค่าใบวางบิล',
      dataIndex: 'grandTotalPriceBillingNote',
      width: 150,
      align: 'right',
    },
    ...(status === 1
      ? [
          {
            title: 'วันครบกำหนดชำระ',
            dataIndex: 'dueDate',
            sortName: 'due_date',
            width: 160,
            search: (
              <RangePicker
                onChange={(values) =>
                  values === null
                    ? setDueDate('')
                    : setDueDate(
                        `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                      )
                }
                format="YYYY/MM/DD"
                placeholder={['เริ่มต้น', 'สิ้นสุด']}
              />
            ),
          },
        ]
      : []),
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'payerFullName',
      width: 300,
      search: <SelectCustomersField onChange={(value: string) => setPayerId(value)} />,
    },
    {
      title: 'รูปแบบรับใบแจ้งหนี้',
      dataIndex: 'businessInvoiceType',
      width: 300,
      search: (
        <SelectBusinessTypeField
          onChange={(value) => {
            setBusinessInvoiceType(value.businessInvoiceType);
            setBusinessInvoiceDateRange(value.businessDate);
          }}
        />
      ),
    },
    {
      title: 'ประเภทงาน',
      dataIndex: 'systemRequest',
      width: 150,
      search: (
        <SelectSystemRequestField
          onChange={(valueChange) => {
            setSystemRequest(valueChange);
          }}
        />
      ),
    },
    {
      title: 'ผู้สร้าง',
      dataIndex: 'employeeFullName',
      width: 250,
      search: <SelectEmployeeField onChange={(value: string) => setEmployeeId(value)} />,
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'createDate',
      sortName: 'create_date',
      width: 200,
      search: (
        <RangePicker
          onChange={(values) =>
            values === null
              ? setCreateDate('')
              : setCreateDate(
                  `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                )
          }
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
          ranges={{
            วันนี้: [moment(), moment()],
            สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
            เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
            ปีนี้: [moment().startOf('year'), moment().endOf('year')],
          }}
        />
      ),
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'lastUpdate',
      sortName: 'last_update',
      width: 150,
    },
  ];

  let billingNotesData: BillingNotesDataTable[] = [];

  let totalDocument = 0;

  if (data && data.billingNotes) {
    const { billingNotes } = data.billingNotes;
    billingNotesData = billingNotes.map(
      (item, index): BillingNotesDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            {status === BillingNotesStatus.WAITING && (
              <StyledCheckbox
                checked={selectedBillingNote.billingNoteId.indexOf(item._id) !== -1}
                value={item._id}
                onChange={(event) =>
                  selectBillingNote(item, event.target.checked, event.target.value)
                }
              />
            )}
            <Button
              onClick={() => {
                setSelectedBillingNoteDetail(
                  (prevState): SelectedBillingNoteDetailType => ({
                    ...prevState,
                    billingNoteId: item._id,
                    isOpenModal: true,
                  }),
                );
              }}
              color="grey--text-only"
              icon={<SearchOutlined />}
              size="small"
            />
          </ToolsContainer>
        ),
        serviceDate: !item.service_date ? '-' : formatDate(item.service_date),
        billNumber: item.bill_number || '-',
        grandTotalPriceBillingNote: (
          <Text strong customType="primary">
            {formatCurrency(item.grand_total_price)}
          </Text>
        ),
        payerFullName: item.payer.full_name,
        dueDate: formatDate(item.due_date),
        businessInvoiceType: item.business_invoice_type,
        systemRequest: item.system_request,
        employeeFullName: item.employee.full_name,
        createDate: formatDateTime(item.create_date) || '-',
        lastUpdate: formatDateTime(item.last_update),
      }),
    );

    totalDocument = data.billingNotes.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={billingNotesData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value: number) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />
      <ModalBillingNoteDetail />
    </>
  );
};

export default BillingNotesTable;
