import gql from 'graphql-tag';
import { CodOutStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codOutStatus.model';

export interface CodOutParcelData {
  codOutParcel: {
    _id: string;
    cod_out_number: string;
    cod_out_status: CodOutStatus;
    total_cod: number;
    paid_date: string;
    bank_ref: string;
    cod_out_remark: string;
    customer: {
      _id: string;
      full_name: string;
      address: string;
      tax_id: string;
      phone: string[];
      email: string[];
      customer_bank_account: {
        bank_id: {
          _id: string;
          bank_code: string;
          bank_name_th: string;
          bank_name_en: string;
        };
        account_name: string;
        account_number: string;
      };
    };
    payment_method: string;
    bank_account: {
      bank_name: string;
      bank_branch: string;
      account_name: string;
      account_number: string;
    };
    parcel_orders: {
      _id: string;
      cod_in_number: string;
      cod_price: number;
      cod_in_date: string;
      tracking_number: string;
      updatedAt: string;
    }[];
    proof_slip: string;
  };
}

export interface CodOutParcelVars {
  _id: string;
}

export default gql`
  query CodOutParcel($_id: ID) {
    codOutParcel(_id: $_id) {
      _id
      cod_out_number
      cod_out_status
      total_cod
      paid_date
      bank_ref
      cod_out_remark
      customer {
        _id
        full_name
        address
        tax_id
        phone
        email
        customer_bank_account {
          bank_id {
            _id
            bank_code
            bank_name_th
            bank_name_en
          }
          account_name
          account_number
        }
      }
      payment_method
      bank_account {
        bank_name
        bank_branch
        account_name
        account_number
      }
      parcel_orders {
        _id
        cod_in_number
        cod_price
        cod_in_date
        tracking_number
        updatedAt
      }
      proof_slip
    }
  }
`;
