import gql from 'graphql-tag';

export interface UpdateFollowDebtHistoryData {
  updateFollowDebtHistory: {
    _id: string;
  };
}

export interface UpdateFollowDebtHistoryVars {
  _id: string;
  followStatus?: string;
  followStatusDetail?: string;
  action?: string;
  actionDetail?: string;
  nextfollowDate?: string;
  detail?: string;
  employee?: string;
  status: number;
}

export default gql`
  mutation UpdateFollowDebtHistory(
    $_id: ID!
    $followStatus: String
    $followStatusDetail: String
    $action: String
    $actionDetail: String
    $nextfollowDate: String
    $detail: String
    $employee: ID
    $status: Int
  ) {
    updateFollowDebtHistory(
      _id: $_id
      update: {
        followStatus: $followStatus
        followStatusDetail: $followStatusDetail
        action: $action
        actionDetail: $actionDetail
        nextfollowDate: $nextfollowDate
        detail: $detail
        employee: $employee
        status: $status
      }
    ) {
      _id
    }
  }
`;
