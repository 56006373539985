/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';
import { ModalTopButton, Text } from 'shared/components';
import { useHistory } from 'react-router-dom';
import { DollarCircleOutlined } from '@ant-design/icons';
import { Row, Col, Divider, Skeleton, Spin } from 'antd';
import { formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import { CodContext } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/context/CodContext';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import PARCEL_ORDER_QUERY, {
  ParcelOrderData,
  ParcelOrderVars,
} from 'shared/graphql/query/parcelOrder';
import COD_PARCEL_QUERY, { CodParcelData, CodParcelVars } from 'shared/graphql/query/codParcel';
import CardIcon from './CardIcon';

interface ModalCodItemDetailProps {
  codItemId: string;
  goBack: string;
}

const ModalCodItemDetailWrapper: React.FC<ModalCodItemDetailProps> = (props) => {
  const { codItemId, goBack } = props;
  const { selectedCodItemDetail } = useContext(CodContext);
  const { trackingNumber } = selectedCodItemDetail;
  const history = useHistory();

  return (
    <ModalTopButton
      visible
      onCancel={() => {
        history.push(goBack);
      }}
      title={`Tracking Number: ${trackingNumber}`}
      width={900}
      btnHeader={[]}
    >
      <ModalCodItemDetail codItemId={codItemId} goBack={goBack} />
    </ModalTopButton>
  );
};

const ModalCodItemDetail: React.FC<ModalCodItemDetailProps> = (props) => {
  const { codItemId } = props;

  const { loading, error, data } = useQuery<ParcelOrderData, ParcelOrderVars>(PARCEL_ORDER_QUERY, {
    variables: {
      _id: codItemId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [getCodData, { data: codData }] = useLazyQuery<CodParcelData, CodParcelVars>(
    COD_PARCEL_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (data && data.parcelOrder && data.parcelOrder.cod) {
      getCodData({
        variables: {
          _id: data.parcelOrder.cod,
        },
      });
    }
  }, [data, data?.parcelOrder.cod, getCodData]);

  let content = <Skeleton />;

  if (data?.parcelOrder) {
    const {
      cod_price,
      customer,
      cod_in_date,
      cod_remark,
      vendor,
      shipment_number,
    } = data.parcelOrder;

    content = (
      <>
        <Row gutter={[32, 16]}>
          <Col
            span={12}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CardIcon
              title="จำนวนที่ 3PL ต้องโอน (บาท)"
              text={<Text customType="primary">{`${formatCurrency(cod_price)} บาท`}</Text>}
              icon={<DollarCircleOutlined />}
            />
          </Col>
        </Row>

        <Divider style={{ marginBottom: 10, marginTop: -5 }} />

        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Row>
              <Col span={8}>ชื่อ 3PL:</Col>
              <Col span={16}>
                <Text strong>{vendor?.full_name || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รหัส COD-IN:</Col>
              <Col span={16}>
                <Text strong>{codData?.codParcel?.cod_in_number || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Shipment No.:</Col>
              <Col span={16}>
                <Text strong>{shipment_number}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>ชื่อลูกค้า:</Col>
              <Col span={16}>
                <Text strong>{customer?.full_name}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>วันที่แจ้งโอน:</Col>
              <Col span={16}>
                <Text strong>{cod_in_date ? formatDateTime(cod_in_date) : '-'}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={8}>Reference 1:</Col>
              <Col span={16}>
                <Text strong>{codData?.codParcel?.paid_qr_detail?.qr_ref1 || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Reference 2:</Col>
              <Col span={16}>
                <Text strong>{codData?.codParcel?.paid_qr_detail?.qr_ref2 || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>ชื่อผู้โอน:</Col>
              <Col span={16}>
                <Text strong>{codData?.codParcel?.paid_qr_detail?.qr_from_name || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>BBL Bank Ref:</Col>
              <Col span={16}>
                <Text strong>{codData?.codParcel?.paid_qr_detail?.qr_bank_ref || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>เหตุผล:</Col>
              <Col span={16}>
                <Text strong>{cod_remark || '-'}</Text>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Divider style={{ marginBottom: 0, marginTop: 10 }} /> */}

        {/* <TabPane
          tabs={[
            {
              key: '1',
              text: 'หลักฐานการโอน',
              isShowIcon: true,
              component: (
                <>
                  {document.length === 0 ? (
                    '-'
                  ) : (
                    <div>
                      <FileList files={document} />
                    </div>
                  )}
                </>
              ),
            },
            {
              key: '2',
              text: 'รายละเอียดงาน',
              isShowIcon: true,
              component: (
                <>
                  <Row gutter={[16, 0]}>
                    <Col span={6}>ชื่อลูกค้า</Col>
                    <Col span={18}>
                      <Row gutter={[16, 0]}>
                        <Col span={10}>Shipment No</Col>
                        <Col span={9}>Booking Number</Col>
                        <Col span={5} style={{ textAlign: 'right' }}>
                          มูลค่า COD
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {shipment_list.length === 0 ? (
                    '-'
                  ) : (
                    <Row gutter={[16, 0]}>
                      <Col span={6}>{customer?.full_name || '-'}</Col>
                      <Col span={18}>
                        {shipment_list.map((item) => (
                          <Row key={uuidv4()} gutter={[16, 0]}>
                            <Col span={10}>
                              <Text strong>{item.shipment_number || '-'}</Text>
                            </Col>
                            <Col span={9}>
                              <Text strong>{item.booking_number || '-'}</Text>
                            </Col>
                            <Col span={5} style={{ textAlign: 'right' }}>
                              <Text customType="primary" strong>
                                {item.cod_price ? formatCurrency(item.cod_price) : '-'}
                              </Text>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  )}
                </>
              ),
            },
          ]}
        /> */}
      </>
    );
  }

  if (error) {
    content = <div>{error.message}</div>;
  }

  return <Spin spinning={loading}>{content}</Spin>;
};

export default ModalCodItemDetailWrapper;
