import styled from 'styled-components';
import { font } from 'shared/utils/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 1.2;
  padding-left: 20px;
  padding-right: 20px;
`;

export const LeftContainer = styled.div`
  margin-right: 20px;
  ${font.size(30)}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextTitle = styled.div`
  ${font.size(18)}
  padding-top: 5px;
`;

export const TextContent = styled.div`
  ${font.size(26)}
  ${font.bold}
`;

export default Container;
