import React from 'react';
import { Redirect, Route, RouteComponentProps, matchPath, Switch } from 'react-router-dom';
import Layout from 'shared/container/Layout';
import Tabs from 'shared/components/Tabs';
import { PageNotFound } from 'shared/components';
import ParcelDetail from './Routes/ParcelDetail';
import ListParcel from './Routes/ListParcel';

type Tabs = 'list-parcel' | 'parcel-detail';

const CostingParcel: React.FC<RouteComponentProps> = (props) => {
  const { history, match, location } = props;

  const selectTab = (tabIndex: Tabs) => {
    const getUrl = match.path;
    history.push(`${getUrl}/${tabIndex}`);
  };

  const checkTabsActive = (tabIndex: Tabs) => {
    return !!matchPath(location.pathname, `${match.path}/${tabIndex}`);
  };

  const tabs = [
    {
      name: 'List Parcel',
      icon: 'file',
      active: checkTabsActive('list-parcel'),
      clicked: () => selectTab('list-parcel'),
    },
    {
      name: 'Parcel Detail',
      icon: 'file',
      active: checkTabsActive('parcel-detail'),
      clicked: () => selectTab('parcel-detail'),
    },
  ];

  return (
    <Layout textHeader="ราคาขายต่อชิ้น">
      <Tabs menu={tabs} />
      <Switch>
        {match.isExact && <Redirect to={`${match.path}/list-parcel`} />}
        <Route path={`${match.path}/parcel-detail`} component={ParcelDetail} />
        <Route path={`${match.path}/list-parcel`} component={ListParcel} />
        <Route component={PageNotFound} />
      </Switch>
    </Layout>
  );
};

export default CostingParcel;
