import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SubTabs } from 'shared/components';
import PARCEL_STATUS_QUERY, { ParcelStatusData } from 'shared/graphql/query/parcelStatus';
import { ListParcelStatus } from './ListParcelStatus';

interface ListParcelTabProps {
  name: string;
  tab: ListParcelStatus;
  onChange: (tab: ListParcelStatus) => void;
}

const ListParcelTab: React.FC<ListParcelTabProps> = (props) => {
  const { tab, onChange, name } = props;

  const { data, refetch } = useQuery<ParcelStatusData>(PARCEL_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const checkSelectTab = (id: ListParcelStatus) => {
    return id === tab && name === 'listParcel';
  };

  const selectTab = (index: ListParcelStatus) => {
    refetch();
    onChange(index);
  };

  const tabs = [
    {
      id: ListParcelStatus.WAIT_PARCEL_DETAIL,
      name: 'รอสร้าง Parcel Detail',
      color: 'badge-grey',
      count: data?.parcelStatus.waitParcelDetail || 0,
      active: false,
      divider: false,
    },
    {
      id: ListParcelStatus.WAIT_INVOICE,
      name: 'รอสร้างใบแจ้งหนี้',
      color: 'badge-grey',
      count: data?.parcelStatus.waitInvoice || 0,
      active: false,
      divider: false,
    },
    {
      id: ListParcelStatus.CREATE_INVOICE,
      name: 'สร้างใบแจ้งหนี้แล้ว',
      color: 'badge-grey',
      count: data?.parcelStatus.createInvoice || 0,
      active: false,
      divider: false,
    },
    {
      id: ListParcelStatus.CANCEL,
      name: 'ยกเลิก',
      color: 'badge-grey',
      count: data?.parcelStatus.cancel || 0,
      active: false,
      divider: false,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default ListParcelTab;
