import styled from 'styled-components';
import { Table } from 'shared/components';
import { Form } from 'antd';

export const StyledTable = styled(Table)`
  & .tab-1 {
    padding-left: 15px;
  }

  & .tab-2 {
    padding-left: 35px;
  }

  & .tab-3 {
    padding-left: 55px;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;
