/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, ReactNode } from 'react';
import { message, Row, Col, Select, DatePicker, Popover } from 'antd';
import { useRouteMatch, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import LogARs_QUERY, { LogARsData, LogARsVars } from 'shared/graphql/query/logARs';
import {
  Button,
  TableSearch,
  Text,
  SelectCustomerField,
  SelectCustomerNumberField,
} from 'shared/components';
import { formatDate } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import { SearchOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { InputRangeFilter } from 'views/DebtTracker/common/components';
import { ToolsContainer, TextHover } from './Styles';

const { Option } = Select;
const { RangePicker } = DatePicker;

type DebtTrackerTableProps = {
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type LogARsDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: ReactNode;
  column4: ReactNode;
  column5: ReactNode;
  column6: ReactNode;
  column7: ReactNode;
  column8: string;
  column9: string;
};

const DebtTrackerTable: React.FC<DebtTrackerTableProps> = (props) => {
  const match = useRouteMatch();
  const { pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [customerId, setCustomerId] = useState<string>('');
  const [totalDebt, setTotalDebt] = useState<string>('');
  const [totalDebtOverdue, setTotalDebtOverdue] = useState<string>('');
  const [lastFollowDate, setLastFollowDate] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('customerName');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('1');

  const { loading, error, data } = useQuery<LogARsData, LogARsVars>(LogARs_QUERY, {
    variables: {
      customer: customerId,
      totalDebt: totalDebt === '' ? '>0' : totalDebt,
      totalDebtOverdue,
      lastFollowDate,
      showData: pageSize,
      page: currentPage,
      order_by: orderBy === '' ? 'customerName' : orderBy,
      order_type: orderType === '' ? '1' : orderType,
    },
    fetchPolicy: 'cache-and-network',
  });

  const getPopOver = (value: string[], title: string, unit: string) => {
    if (!value || value.length === 0) {
      return '-';
    }

    const valueList = value.map((item) => item);

    let text: ReactNode = '';
    if (value.length === 1) {
      text = <Text>{valueList}</Text>;
    } else if (value.length > 1) {
      text = (
        <Popover
          placement="right"
          content={
            <>
              {title}
              {valueList.map((item) => {
                return (
                  <Row key={uuidv4()}>
                    <Text strong>{item}</Text>
                  </Row>
                );
              })}
            </>
          }
        >
          <TextHover>{`${valueList.length} ${unit}`}</TextHover>
        </Popover>
      );
    }

    return text;
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'รหัสลูกค้า',
      dataIndex: 'column1',
      width: 150,
      sortName: 'customerNumber',
      search: (
        <SelectCustomerNumberField value={customerId} onChange={(value) => setCustomerId(value)} />
      ),
    },
    {
      title: 'ชื่อลูกหนี้',
      dataIndex: 'column2',
      width: 300,
      search: <SelectCustomerField value={customerId} onChange={(value) => setCustomerId(value)} />,
    },
    {
      title: 'มูลค่าหนี้รวม',
      dataIndex: 'column3',
      width: 150,
      sortName: 'totalDebt',
      align: 'right',
      search: <InputRangeFilter onChangeValue={(value) => setTotalDebt(value)} />,
    },
    {
      title: 'ใบแจ้งหนี้ทั้งหมด',
      dataIndex: 'column4',
      width: 130,
      align: 'right',
    },
    {
      title: 'ยอดเกินกำหนดชำระ',
      dataIndex: 'column5',
      width: 150,
      sortName: 'totalDebtOverdue',
      align: 'right',
      search: <InputRangeFilter onChangeValue={(value) => setTotalDebtOverdue(value)} />,
    },
    {
      title: 'ใบแจ้งหนี้เกินกำหนด',
      dataIndex: 'column6',
      width: 130,
      align: 'right',
    },
    {
      title: 'อ้างอิงใบวางบิล',
      dataIndex: 'column7',
      width: 130,
      align: 'right',
    },
    {
      title: 'วันนัดหมายครั้งต่อไป',
      dataIndex: 'column8',
      width: 210,
      sortName: 'lastFollowDate',
      search: (
        <RangePicker
          onChange={(values) => {
            if (values) {
              const formatRangDate = `${values[0]?.format('YYYY-MM-DD')}:${values[1]?.format(
                'YYYY-MM-DD',
              )}`;
              return setLastFollowDate(formatRangDate);
            }
            setLastFollowDate('');
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'อัพเดตล่าสุด',
      dataIndex: 'column9',
      width: 150,
      sortName: 'updatedAt',
    },
  ];

  let logARsDataTable: LogARsDataTable[] = [];
  let totalDocument = 0;

  if (data?.logARs) {
    const { logARs } = data.logARs;
    logARsDataTable = logARs.map(
      (item, index): LogARsDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <Link to={`${match.path}/detail/${item._id}`}>
              <Button color="grey--text-only" icon={<SearchOutlined />} size="small" />
            </Link>
          </ToolsContainer>
        ),
        column1: item.customer?.number || '-',
        column2: item.customer?.full_name || '-',
        column3: <Text strong>{formatCurrency(+item.totalDebt, '0')}</Text>,
        column4: getPopOver(item.totalInvoice, 'รหัสใบแจ้งหนี้', 'ใบแจ้งหนี้'),
        column5: (
          <Text strong customType="primary">
            {formatCurrency(+item.totalDebtOverdue, '0')}
          </Text>
        ),
        column6: getPopOver(item.totalInvoiceOverdue, 'รหัสใบแจ้งหนี้', 'ใบแจ้งหนี้'),
        column7: getPopOver(item.totalBillingNote, 'รหัสใบวางบิล', 'ใบวางบิล'),
        column8: formatDate(item.lastFollowDate) || '-',
        column9: item.updatedAt || '-',
      }),
    );

    totalDocument = data.logARs.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={logARsDataTable}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />
    </>
  );
};

export default DebtTrackerTable;
