/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Col, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import CREATE_JV_MUTATION, { CreateJvData, CreateJvVars } from 'shared/graphql/mutation/createJv';
import { Button, Text } from 'shared/components';
import { FormValues } from 'views/Setting/Routes/ChartOfAccounts/JV/JvDetail/model/formValues.model';
import formatCurrency from 'shared/utils/formatCurrency';
import { useFormContext } from 'react-hook-form';
import { StyledModal } from './Styles';

type ModalConfirmCreateProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmCreate: React.FC<ModalConfirmCreateProps> = (props) => {
  const { onCancel, visible } = props;
  const history = useHistory();
  const { handleSubmit, watch, reset } = useFormContext<FormValues>();
  const watchValue = watch('chartOfAccountList')[0]?.value;

  const [createJv, { loading }] = useMutation<CreateJvData, CreateJvVars>(CREATE_JV_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.createJV._id) {
        message.success('สร้าง JV แล้ว');
        onCancel();
        history.push('/setting/chart-of-accounts/jv');
        reset();
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['Jvs'],
  });

  const submitCreate = handleSubmit((values) => {
    createJv({
      variables: {
        description: values.description,
        document_date: values.documentDate,
        chartOfAccount: values.chartOfAccountList[0].chartOfAccountId,
        code: values.chartOfAccountList[0].code,
        account_name: values.chartOfAccountList[0].accountName,
        value: values.chartOfAccountList[0].value,
      },
    });
  });

  return (
    <StyledModal
      className="custom-modal"
      title="บันทึกรายการ JV ใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => submitCreate()}
          loading={loading}
          disabled={loading}
        >
          ตกลง
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>คำอธิบาย:</Col>
        <Col span={18}>
          <Text strong>{watch('description')}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>รหัสบัญชี:</Col>
        <Col span={18}>
          <Text strong>{watch('chartOfAccountList')[0]?.code}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>ชื่อบัญชี:</Col>
        <Col span={18}>
          <Text strong>{watch('chartOfAccountList')[0]?.accountName}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>ยอด:</Col>
        <Col span={18}>
          <Text strong>{watchValue && formatCurrency(watchValue)}</Text>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmCreate;
