/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect } from 'react';
import { Divider, Row, Col, Skeleton } from 'antd';
import Icon from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { color } from 'shared/utils/styles';
import IconFileInvoiceDollar from 'shared/assets/icons/file-invoice-dollar.svg';
import IconHandUSDLight from 'shared/assets/icons/hands-usd-light.svg';
import IconCalendarDayLight from 'shared/assets/icons/calendar-day-light.svg';
import IconCalendarExclamationLight from 'shared/assets/icons/calendar-exclamation-light.svg';
import INVOICE_QUERY, { InvoiceData, InvoiceVars } from 'shared/graphql/query/invoice';
import { PageResultError, Text } from 'shared/components';
import {
  DebtTrackerContext,
  DebtTrackerHistoryValue,
} from 'views/DebtTracker/common/context/DebtTrackerContext';
import { CardContainer, StyledCardIcon } from './Styles';

type Params = {
  invoiceId: string;
};

const InvoiceDetail: React.FC = () => {
  const { invoiceId } = useParams<Params>();

  const { setDebtTrackerHistoryValue } = useContext(DebtTrackerContext);

  const { data, error } = useQuery<InvoiceData, InvoiceVars>(INVOICE_QUERY, {
    variables: {
      _id: invoiceId,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.invoice && data?.invoice.receipt) {
      const { receipt_date } = data.invoice.receipt;
      setDebtTrackerHistoryValue(
        (prevState): DebtTrackerHistoryValue => ({
          ...prevState,
          isShowForm: false,
          receiptDate: receipt_date,
        }),
      );
    } else {
      setDebtTrackerHistoryValue(
        (prevState): DebtTrackerHistoryValue => ({
          ...prevState,
          isShowForm: true,
          receiptDate: '',
        }),
      );
    }
  }, [data?.invoice, data?.invoice.receipt, setDebtTrackerHistoryValue]);

  const businessPayRound = (roundDate: string, afterInvoice: string) => {
    if (roundDate === null && afterInvoice === null) {
      return '-';
    }
    if (roundDate !== null && +roundDate > 0) {
      return `ทุกวันที่ ${roundDate} ของเดือน`;
    }
    if (afterInvoice !== null && +afterInvoice > 0) {
      return `หลังจากวันที่แจ้งหนี้ ${afterInvoice} วัน`;
    }
  };

  let content = <Skeleton />;

  if (error) {
    content = <PageResultError title="เกิดข้อผิดพลาด" message={error.message} />;
  }

  if (data?.invoice) {
    const {
      due_date,
      overDueDays,
      nextFollowDate,
      receipt,
      payer,
      business_invoice_type,
      create_date,
      invoice_number,
      grand_total_price,
    } = data.invoice;
    content = (
      <>
        <CardContainer>
          <StyledCardIcon
            title="วันครบกำหนดชำระ"
            text={formatDate(due_date) || '-'}
            icon={<Icon component={IconFileInvoiceDollar} />}
          />
          <StyledCardIcon
            title="เกินกำหนด"
            text={overDueDays ? `${overDueDays} วัน` : '-'}
            icon={<Icon component={IconCalendarExclamationLight} />}
          />
          <StyledCardIcon
            title="นัดหมายครั้งต่อไป"
            text={formatDate(nextFollowDate) || '-'}
            icon={<Icon component={IconCalendarDayLight} />}
          />
          <StyledCardIcon
            title="วันรับชำระ"
            text={formatDate(receipt?.receipt_date) || '-'}
            icon={<Icon component={IconHandUSDLight} />}
            color={color.success}
          />
        </CardContainer>

        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[32, 0]} style={{ marginTop: 10, marginBottom: 10 }}>
          <Col span={8}>
            <Row gutter={[0, 0]}>
              <Col span={8}>
                <Text>รหัสลูกค้า:</Text>
              </Col>
              <Col span={16}>
                <Text strong>{payer?.number || '-'}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={8}>
                <Text>ชื่อลูกค้า (ลูกหนี้):</Text>
              </Col>
              <Col span={16}>
                <Text strong>{payer?.full_name || '-'}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={8}>
                <Text>รูปแบบรับใบแจ้งหนี้:</Text>
              </Col>
              <Col span={16}>
                <Text strong>{business_invoice_type || '-'}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={8}>
                <Text>รอบการชำระลูกหนี้:</Text>
              </Col>
              <Col span={16}>
                <Text strong>
                  {businessPayRound(
                    payer.business_pay_round.round_date,
                    payer.business_pay_round.after_invoice,
                  )}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={9}>
            <Row gutter={[0, 0]}>
              <Col span={8}>
                <Text>เลขประจำตัวผู้เสียภาษี:</Text>
              </Col>
              <Col span={16}>
                <Text strong>{payer?.tax_id || '-'}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={8}>
                <Text>เบอร์โทรศัพท์:</Text>
              </Col>
              <Col span={16}>
                <Text strong>{payer?.phone.length > 0 ? payer?.phone.join(', ') : '-'}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={8}>
                <Text>อีเมล:</Text>
              </Col>
              <Col span={16}>
                <Text strong>{payer?.email.length > 0 ? payer?.email.join(', ') : '-'}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={8}>
                <Text>ที่อยู่:</Text>
              </Col>
              <Col span={16}>
                <Text strong>
                  {`${payer?.address || ''} ${payer?.sub_district || ''} ${payer?.district || ''} ${
                    payer?.province || ''
                  } ${payer?.postcode || ''}`}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={7}>
            <Row gutter={[0, 0]}>
              <Col span={8}>
                <Text>วันที่เอกสาร:</Text>
              </Col>
              <Col span={16}>
                <Text strong>{create_date ? formatDate(create_date) : '-'}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={8}>
                <Text>รหัสใบแจ้งหนี้:</Text>
              </Col>
              <Col span={16}>
                <Text strong>{invoice_number || '-'}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={8}>
                <Text>มูลค่าใบแจ้งหนี้:</Text>
              </Col>
              <Col span={16}>
                <Text strong customType="primary">
                  {grand_total_price ? formatCurrency(grand_total_price) : '-'}
                </Text>
              </Col>
            </Row>
            {receipt?.receipt_number && (
              <Row gutter={[0, 0]}>
                <Col span={8}>
                  <Text>รหัสใบเสร็จรับเงิน:</Text>
                </Col>
                <Col span={16}>
                  <Text strong>{receipt.receipt_number}</Text>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Divider style={{ marginBottom: 0, marginTop: 10 }} />
      </>
    );
  }

  return content;
};

export default InvoiceDetail;
