/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode } from 'react';
import { Select, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import CHART_OF_ACCOUNTS_QUERY, {
  ChartOfAccountsData,
  ChartOfAccountsVars,
} from 'shared/graphql/query/chartOfAccounts';

const { Option } = Select;

type SelectChartOfAccountFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
  mode: string;
  level?: number | undefined;
};

const SelectChartOfAccountField: React.FC<SelectChartOfAccountFieldProps> = (props) => {
  const { value, onChange, mode, level } = props;
  const [accountName, setAccountName] = useState<string>('');
  const { data, loading } = useQuery<ChartOfAccountsData, ChartOfAccountsVars>(
    CHART_OF_ACCOUNTS_QUERY,
    {
      variables: {
        account_name: accountName,
        mode,
        level,
        // showData: 10,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  let options: ReactNode[] = [];
  if (data && data.chartOfAccounts) {
    const { chartOfAccounts } = data.chartOfAccounts;
    options = chartOfAccounts.map((item) => (
      <Option key={item._id} value={item._id}>
        {item.account_name}
      </Option>
    ));
  }

  return (
    <Select
      allowClear
      style={{
        width: '100%',
      }}
      value={value}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      showSearch
      placeholder="ค้นหา"
      onSearch={(valueSearch) => setAccountName(valueSearch)}
      onChange={(valueChange) => onChange && onChange(valueChange)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};

export default SelectChartOfAccountField;
