import React, { useState, ReactChild } from 'react';
import { CodOutStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codOutStatus.model';

export interface SelectedPaymentCodDetailType {
  paymentCodId: string;
  isOpenModal: boolean;
  isLoading: boolean;
  paymentCodStatus: CodOutStatus | null;
  paymentCodNumber: string;
  customerId: string;
  customerFullName: string;
  totalCod: number;
  customerBankAccountNumber: string;
}

interface PaymentCod {
  selectedPaymentCodDetail: SelectedPaymentCodDetailType;
  setSelectedPaymentCodDetail: (
    params: (cb: SelectedPaymentCodDetailType) => SelectedPaymentCodDetailType,
  ) => void;
}

type PaymentCodProps = {
  children: ReactChild;
};

const PaymentCodContext = React.createContext({} as PaymentCod);

const PaymentCodProvider: React.FC<PaymentCodProps> = (props) => {
  const { children } = props;

  const [
    selectedPaymentCodDetail,
    setSelectedPaymentCodDetail,
  ] = useState<SelectedPaymentCodDetailType>({
    paymentCodId: '',
    isOpenModal: false,
    isLoading: true,
    paymentCodStatus: null,
    paymentCodNumber: '',
    customerId: '',
    customerFullName: '',
    totalCod: 0,
    customerBankAccountNumber: '',
  });

  const store = {
    selectedPaymentCodDetail,
    setSelectedPaymentCodDetail,
  };

  return <PaymentCodContext.Provider value={store}>{children}</PaymentCodContext.Provider>;
};

export { PaymentCodContext, PaymentCodProvider };
