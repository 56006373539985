import React from 'react';
import { Tag } from 'shared/components';
import { TagColorType } from 'shared/components/Tag';
import { ReceiptStatus } from 'views/AccountReceive/common/model/receiptStatus.model';

type TagStatusReceiptProps = {
  status: ReceiptStatus;
};

const getTextColorStatus = (
  status: ReceiptStatus,
): {
  color: TagColorType | undefined;
  text: string | number;
  outline: boolean;
} => {
  switch (status) {
    case 1:
      return { color: 'green', text: 'ชำระแล้ว', outline: false };
    case 2:
      return { color: 'red', text: 'ยกเลิก', outline: false };
    default:
      return { color: undefined, text: status, outline: false };
  }
};

const TagStatusReceipt: React.FC<TagStatusReceiptProps> = (props) => {
  const { status } = props;
  const textColor = getTextColorStatus(status);

  return (
    <Tag customColor={textColor.color} outline={textColor.outline}>
      {textColor.text}
    </Tag>
  );
};

export default TagStatusReceipt;
