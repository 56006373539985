import styled from 'styled-components';
import { Tag } from 'antd';
import { costingStatusColor, font } from 'shared/utils/styles';

interface TagCustomProps {
  type: string;
}

export const StyledTag = styled(Tag)<TagCustomProps>`
  ${font.size(20)}
  ${font.regular}
  padding: 0 10px;
  height: 24px;
  line-height: 1.2;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => costingStatusColor[props.type].backgroundColor};
  color: ${(props) => costingStatusColor[props.type].color};
`;

export default StyledTag;
