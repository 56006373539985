import gql from 'graphql-tag';

export interface BankAccount {
  bank_name: string;
  bank_branch: string;
  account_name: string;
  account_number: string;
}

export interface Payee {
  _id: string;
  payee_type: number;
  employee: {
    _id: string;
    full_name: string;
  };
  vendor: {
    _id: string;
    full_name: string;
  };
  tax_id: string;
  full_name: string;
  email: string[];
  phone: string[];
  address: string;
  sub_district: string;
  district: string;
  province: string;
  postcode: string;
  full_address: string;
  bank_account: BankAccount[];
}

export interface PayeeData {
  payee: Payee;
}

export interface PayeeVars {
  _id: string;
}

export default gql`
  query Payee($_id: ID!) {
    payee(_id: $_id) {
      _id
      payee_type
      employee {
        _id
        full_name
      }
      vendor {
        _id
        full_name
      }
      tax_id
      full_name
      email
      phone
      address
      sub_district
      district
      province
      postcode
      full_address
      bank_account {
        bank_name
        bank_branch
        account_name
        account_number
      }
    }
  }
`;
