import styled from 'styled-components';
import { Layout } from 'antd';
import { font, color, mixin } from 'shared/utils/styles';

const { Header } = Layout;
const StyledHeader = styled(Header)`
  .trigger {
    transition: color 0.3s;
    ${font.size(20)}
    ${mixin.clickable}
  }

  .trigger:hover {
    color: #ffffff;
  }

  &.ant-layout-header {
    padding: 0 20px;
    background-color: ${color.backgroundPrimary};
    color: #ffffff;
    height: 54px;
  }

  .ant-btn-link {
    color: #ffffff;
  }

  & .header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 54px;
  }

  & .text-header {
    padding-left: 20px;
    ${font.size(30)}
    ${font.bold}
  }
`;

export default StyledHeader;
