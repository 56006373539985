import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SubTabs } from 'shared/components';
import PAYMENT_COD_STATUS_QUERY, {
  PaymentCodStatusData,
} from 'shared/graphql/query/paymentCodStatus';
import { PaymentCodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/model/paymentCodStatus.model';

interface PaymentCodTabsProps {
  name: string;
  tab: PaymentCodStatus;
  onChange: (tab: PaymentCodStatus) => void;
}

const PaymentCodTabs: React.FC<PaymentCodTabsProps> = (props) => {
  const { tab, onChange, name } = props;
  const { data, refetch } = useQuery<PaymentCodStatusData>(PAYMENT_COD_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const checkSelectTab = (id: PaymentCodStatus) => {
    return id === tab && name === 'paymentCod';
  };

  const selectTab = (index: PaymentCodStatus) => {
    refetch();
    onChange(index);
  };

  const tabs = [
    {
      id: PaymentCodStatus.WAITING,
      name: 'รอบันทึกจ่าย',
      color: 'badge-grey',
      count: data?.paymentCodStatus.waiting || 0,
      divider: false,
    },
    {
      id: PaymentCodStatus.PAID,
      name: 'จ่ายเรียบร้อย',
      color: 'badge-grey',
      count: data?.paymentCodStatus.paid || 0,
      divider: false,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default PaymentCodTabs;
