import React, { useContext, useState } from 'react';
import { ModalTopButton, Text, TabPane, FileList } from 'shared/components';
import { CheckCircleOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { Row, Col, Divider } from 'antd';
import { color } from 'shared/utils/styles';
import { formatDateTime } from 'shared/utils/dateTime';
import TagCodItemStatus from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/components/TagCodItemStatus';
import { CodInContext } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/CodInContext';
import formatCurrency from 'shared/utils/formatCurrency';
import { CodItemStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/model/codItemStatus.model';
import CardIcon from './CardIcon';
import ModalConfirmChecked from './ModalConfirmChecked';
import ModalConfirmReject from './ModalConfirmReject';

type ModalCodItemDetailProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalCodItemDetailWrapper: React.FC<ModalCodItemDetailProps> = (props) => {
  const { visible, onCancel } = props;
  const [isOpenModalConfirmChecked, setOpenModalConfirmChecked] = useState<boolean>(false);
  const [isOpenModalConfirmReject, setOpenModalConfirmReject] = useState<boolean>(false);
  const { selectedCodItem } = useContext(CodInContext);
  const { vendorFullName, codItemStatus } = selectedCodItem;

  return (
    <ModalTopButton
      visible={visible}
      onCancel={onCancel}
      title={`${vendorFullName} (3PL)`}
      width={850}
      btnHeader={[
        {
          text: 'ตรวจสอบแล้ว',
          clicked: () => setOpenModalConfirmChecked(true),
          color: 'green',
          icon: <CheckCircleOutlined />,
          visible: codItemStatus === 2,
        },
        {
          text: 'ปฏิเสธ',
          clicked: () => setOpenModalConfirmReject(true),
          color: 'red--light',
          visible: codItemStatus === 2 || codItemStatus === 3,
        },
      ]}
    >
      <ModalCodItemDetail />
      <ModalConfirmChecked
        visible={isOpenModalConfirmChecked}
        onCancel={() => setOpenModalConfirmChecked(false)}
      />
      <ModalConfirmReject
        visible={isOpenModalConfirmReject}
        onCancel={() => setOpenModalConfirmReject(false)}
      />
    </ModalTopButton>
  );
};

const ModalCodItemDetail: React.FC = () => {
  const { selectedCodItem } = useContext(CodInContext);
  const {
    codItemStatus,
    totalCod,
    customerFullName,
    bookingNumber,
    codInDate,
    document,
    remark,
    rejectRemark,
  } = selectedCodItem;

  return (
    <>
      <Row gutter={[32, 16]}>
        <Col
          span={12}
          style={{ borderRight: `1px dashed ${color.grey}`, display: 'flex', alignItems: 'center' }}
        >
          <CardIcon
            title="จำนวนที่ 3PL ต้องโอน (บาท)"
            text={<Text customType="primary">{`${formatCurrency(totalCod)} บาท`}</Text>}
            icon={<DollarCircleOutlined />}
          />
        </Col>
        <Col span={12}>
          <Row>
            <Col span={24}>สถานะ</Col>
            <Col span={24}>{codItemStatus && <TagCodItemStatus status={codItemStatus} />}</Col>
          </Row>
        </Col>
      </Row>

      <Divider style={{ marginBottom: 10, marginTop: -5 }} />

      <Row>
        <Col span={12}>
          <Row>
            <Col span={8}>ชื่อลูกค้า:</Col>
            <Col span={16}>
              <Text strong>{customerFullName}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>Booking Number:</Col>
            <Col span={16}>
              <Text strong>{bookingNumber}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>วันที่แจ้งโอน:</Col>
            <Col span={16}>
              <Text strong>{codInDate === '-' ? '-' : formatDateTime(codInDate)}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          {codItemStatus === CodItemStatus.CHECKED && (
            <Row>
              <Col span={8}>เหตุผล:</Col>
              <Col span={16}>
                <Text strong>{remark || '-'}</Text>
              </Col>
            </Row>
          )}
          {codItemStatus === CodItemStatus.REJECT && (
            <Row>
              <Col span={8}>เหตุผลการปฎิเสธ:</Col>
              <Col span={16}>
                <Text strong>{rejectRemark || '-'}</Text>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Divider style={{ marginBottom: 0, marginTop: 10 }} />

      <TabPane
        tabs={[
          {
            key: '1',
            text: 'เอกสารอื่นๆ',
            isShowIcon: true,
            component: (
              <>
                {document.length === 0 ? (
                  '-'
                ) : (
                  <div>
                    ไฟล์หลักฐานการโอนเงิน
                    <FileList files={document} />
                  </div>
                )}
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default ModalCodItemDetailWrapper;
