import styled from 'styled-components';
import { Button } from 'antd';
import { color, mixin } from 'shared/utils/styles';

type ButtonCustomProps = {
  color:
    | 'green'
    | 'green--outline'
    | 'green--light'
    | 'red'
    | 'red--light'
    | 'red--outline'
    | 'grey--text-only'
    | 'blue'
    | 'blue--light'
    | 'blue--outline'
    | undefined;
};

export const StyledButton = styled(Button)<ButtonCustomProps>`
  ${(props) =>
    props.color === 'green' &&
    `
  background-color: ${color.backgroundSuccess};
  border-color: ${color.backgroundSuccess};
  color: ${color.white};

  &:active,
  &:focus,
  &:hover {
    background-color: ${mixin.rgba(color.backgroundSuccess, 0.8)};
    border-color: ${mixin.rgba(color.backgroundSuccess, 0.8)};
    color: ${color.white};
  }
  `};

  ${(props) =>
    props.color === 'green--light' &&
    `
    background-color: ${color.backgroundLightSuccess};
    border-color: ${color.backgroundLightSuccess};
    color: ${color.success};

    &:active,
    &:focus,
    &:hover {
      background-color: ${mixin.rgba(color.backgroundLightSuccess, 0.8)};
      border-color: ${mixin.rgba(color.backgroundLightSuccess, 0.8)};
      color: ${color.success};
    }
  `};

  ${(props) =>
    props.color === 'green--outline' &&
    `
    background-color: ${color.white};
    border-color: ${color.backgroundSuccess};
    color: ${color.success};

    &:active,
    &:focus,
    &:hover {
      background-color: ${mixin.rgba(color.backgroundSuccess, 0.8)};
      border-color: ${mixin.rgba(color.backgroundSuccess, 0.8)};
      color: ${color.white};
    }
  `};

  ${(props) =>
    props.color === 'red' &&
    `
    background-color: ${color.backgroundDanger};
    border-color: ${color.backgroundDanger};
    color: ${color.white};

    &:active,
    &:focus,
    &:hover {
      background-color: ${mixin.rgba(color.backgroundDanger, 0.8)};
      border-color: ${mixin.rgba(color.backgroundDanger, 0.8)};
      color: ${color.white};
    }
  `};

  ${(props) =>
    props.color === 'red--light' &&
    `
    background-color: ${color.backgroundLightDanger};
    border-color: ${color.backgroundLightDanger};
    color: ${color.danger};

    &:active,
    &:focus,
    &:hover {
      background-color: ${mixin.rgba(color.backgroundLightDanger, 0.8)};
      border-color: ${mixin.rgba(color.backgroundLightDanger, 0.8)};
      color: ${color.danger};
    }
  `};

  ${(props) =>
    props.color === 'red--outline' &&
    `
    color: ${color.danger};
    &:active,
    &:focus,
    &:hover {
      background-color: ${mixin.rgba(color.white, 0.8)};
      border-color: ${mixin.rgba(color.danger, 0.8)};
      color: ${color.danger};
    }
  `};

  ${(props) =>
    props.color === 'grey--text-only' &&
    `
    background-color: transparent;
    border: none;
    color: ${mixin.rgba(color.black, 0.65)};
    &:active,
    &:focus,
    &:hover {
      opacity: 0.85;
      background-color: transparent;
      border: none;
      color: ${mixin.rgba(color.black, 0.65)};
    }
  `};

  ${(props) =>
    props.color === 'blue' &&
    `
  background-color: ${color.primary};
  border-color: ${color.primary};
  color: ${color.white};

  &:active,
  &:focus,
  &:hover {
    background-color: ${mixin.rgba(color.primary, 0.8)};
    border-color: ${mixin.rgba(color.primary, 0.8)};
    color: ${color.white};
  }
  `};

  ${(props) =>
    props.color === 'blue--light' &&
    `
    background-color: ${color.backgroundLightPrimary};
    border-color: ${color.backgroundLightPrimary};
    color: ${color.primary};
    &:active,
    &:focus,
    &:hover {
      background-color: ${mixin.rgba(color.backgroundLightPrimary, 0.8)};
      border-color: ${mixin.rgba(color.backgroundLightPrimary, 0.8)};
      color: ${color.primary};
    }
  `};

  ${(props) =>
    props.color === 'blue--outline' &&
    `
    color: ${color.primary};
    &:active,
    &:focus,
    &:hover {
      background-color: ${mixin.rgba(color.white, 0.8)};
      border-color: ${mixin.rgba(color.primary, 0.8)};
      color: ${color.primary};
    }
  `};
`;
export default StyledButton;
