import React from 'react';
import { Redirect, Route, matchPath, RouteComponentProps, Switch } from 'react-router-dom';
import { Tabs, PageNotFound } from 'shared/components';
import Company from './Company';
import Individual from './Individual';

type Tabs = 'company' | 'individual';

const Creditor: React.FC<RouteComponentProps> = (props) => {
  const { history, match, location } = props;

  const selectTab = (tabIndex: Tabs) => {
    const getUrl = match.path;
    history.push(`${getUrl}/${tabIndex}`);
  };

  const checkTabsActive = (tabIndex: Tabs) => {
    return !!matchPath(location.pathname, `${match.path}/${tabIndex}`);
  };

  const tabs = [
    {
      name: 'บัญชีเจ้าหนี้-บริษัท',
      icon: 'file',
      active: checkTabsActive('company'),
      clicked: () => selectTab('company'),
    },
    {
      name: 'บัญชีเจ้าหนี้-บุคคล',
      icon: 'file',
      active: checkTabsActive('individual'),
      clicked: () => selectTab('individual'),
    },
  ];

  return (
    <>
      <Tabs menu={tabs} />
      <Switch>
        {match.isExact && <Redirect to={`${match.path}/company`} />}
        <Route path={`${match.path}/company`} component={Company} />
        <Route path={`${match.path}/individual`} component={Individual} />
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default Creditor;
