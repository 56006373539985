/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Select, message, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import EMPLOYEES_QUERY, { EmployeesData, Employees } from 'shared/graphql/query/employees';

const { Option } = Select;

type SelectEmployeesFieldValue = {
  employeeId: string;
  department: string;
  fullName: string;
};

type SelectEmployeesFieldProps = {
  value?: string;
  onChange?: (value: SelectEmployeesFieldValue) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
};

const defaultProps = {
  disabled: false,
};

const SelectEmployeesField: React.FC<SelectEmployeesFieldProps> = (props) => {
  const { value, onChange, disabled, style } = props;
  const [fullName, setFullName] = useState<string>('');
  const [employees, setEmployees] = useState<Employees[]>([]);
  const { data, loading, error } = useQuery<EmployeesData>(EMPLOYEES_QUERY, {
    variables: {
      full_name: fullName,
      showData: 1000,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && data?.employees) {
      setEmployees([...data.employees.employees]);
    }
  }, [data?.employees, error, loading]);

  const options = employees.map((employee) => (
    <Option key={employee._id} value={employee._id}>
      {employee.full_name}
    </Option>
  ));

  const selectedEmployee = (valueInput: string) => {
    const employee = employees.filter((employeeItem) => employeeItem._id === valueInput);
    if (onChange && employee.length === 1) {
      const { _id, full_name, department } = employee[0];
      onChange({
        employeeId: _id,
        fullName: full_name,
        department,
      });
    }
  };

  return (
    <Select
      style={{
        width: '100%',
        ...style,
      }}
      value={value}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      placeholder="เลือก"
      showSearch
      onSearch={(valueSearch) => setFullName(valueSearch)}
      onChange={(valueChange) => {
        selectedEmployee(valueChange);
        setFullName('');
      }}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
    >
      {options}
    </Select>
  );
};

SelectEmployeesField.defaultProps = defaultProps;

export default SelectEmployeesField;
