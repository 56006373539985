import gql from 'graphql-tag';
import { CostingStatus } from 'views/Costing/common/model/costingStatus.model';

export interface UpdateCostingData {
  updateCosting: {
    _id: string;
    costing_status: CostingStatus;
  };
}

export interface UpdateCostingVars {
  _id: string;
  costing_status?: CostingStatus;
  reject_remark?: string;
  confirm_edit?: boolean;
  edit_request_status?: 2;
  edit_request_remark?: string;
}

export default gql`
  mutation UpdateCosting(
    $_id: ID!
    $costing_status: Int
    $reject_remark: String
    $confirm_edit: Boolean
    $edit_request_status: Int
    $edit_request_remark: String
  ) {
    updateCosting(
      _id: $_id
      updateCosting: {
        costing_status: $costing_status
        reject_remark: $reject_remark
        confirm_edit: $confirm_edit
        edit_request_status: $edit_request_status
        edit_request_remark: $edit_request_remark
      }
    ) {
      _id
      costing_status
    }
  }
`;
