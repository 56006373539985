import React from 'react';
import { Divider, Row, Col, Tabs, Skeleton, Form } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { color } from 'shared/utils/styles';
import { Text, SelectCustomersNameAndBranchField } from 'shared/components';
import { FormValues } from 'views/AccountReceive/Routes/Invoice/ModalCreateInvoiceFromExcel/model/formValues.model';
import { useFormContext, Controller } from 'react-hook-form';
import UploadDocument from './UploadDocument';

const { TabPane } = Tabs;

const CreateInvoiceFromExcel: React.FC = () => {
  const { control, errors } = useFormContext<FormValues>();

  let content = <Skeleton />;

  content = (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row justify="space-between">
            <Col span={24}>
              ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>:
            </Col>
            <Col span={8}>
              <Form.Item validateStatus={errors.payerId && 'error'} style={{ marginBottom: 10 }}>
                <Controller
                  control={control}
                  name="payerId"
                  render={({ onChange, value }) => (
                    <SelectCustomersNameAndBranchField
                      value={value || undefined}
                      onChange={(valueChange) => {
                        onChange(valueChange);
                      }}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider style={{ margin: 0, marginTop: -5 }} />

      <Tabs defaultActiveKey="1" animated={false}>
        <TabPane
          tab={
            <span>
              อัพโหลดไฟล์เอกสาร{' '}
              <CheckCircleOutlined
                style={{
                  color: color.success,
                  marginLeft: 5,
                }}
              />
            </span>
          }
          key="1"
        >
          <Controller
            control={control}
            name="file"
            render={({ onChange }) => <UploadDocument onChange={onChange} />}
          />
        </TabPane>
      </Tabs>
    </>
  );

  return content;
};

export default CreateInvoiceFromExcel;
