import * as yup from 'yup';

const validateSchema = yup.object().shape({
  description: yup.string().required('กรุณากรอกข้อมูล'),
  documentDate: yup.string().required('กรุณากรอกข้อมูล'),
  chartOfAccountList: yup.array().of(
    yup.object().shape({
      value: yup.number().required('กรุณากรอกข้อมูล'),
    }),
  ),
});

export default validateSchema;
