/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Divider, Row, Col, Tabs, Input, DatePicker, Radio, Tooltip, Select } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import formatCurrency from 'shared/utils/formatCurrency';
import moment from 'moment';
import { color } from 'shared/utils/styles';
import {
  Text,
  SelectEmployeesField,
  SelectPayeesField,
  SelectSystemRequestField,
  SelectPaymentTypeField,
  ButtonCopy,
  SelectDepartmentField,
} from 'shared/components';
import { useFormContext, Controller } from 'react-hook-form';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalDetailPaymentOrder/model/formValues.model';
import TagStatusPayment from 'views/AccountPayment/Routes/PaymentOrder/TagStatusPayment';
import { PaymentStatus } from 'views/AccountPayment/common/model/paymentStatus.model';
import ListPayment from './ListPayment';
import { StyledFormItem } from './Styles';
import UploadDocument from './UploadDocument';

const { TabPane } = Tabs;
const InputGroup = Input.Group;
const dateFormat = 'YYYY/MM/DD';
const { Option } = Select;

const DetailPaymentOrder: React.FC = () => {
  const { setValue, watch, control, errors } = useFormContext<FormValues>();
  const watchGrandTotalPrice = watch('grandTotalPrice');
  const watchPaymentStatus = watch('paymentStatus');
  const watchPaymentNumber = watch('paymentNumber');
  const watchRejectRemark = watch('rejectRemark');
  const watchCancelRemark = watch('cancelRemark');
  const watchRefNumber = watch('numberRef');

  const disabledDate = (current: any) => {
    const req = /^AP(\d{2})(\d{2})(\d{5})$/;
    const matchPaymentNumber = watchPaymentNumber.match(req);

    if (matchPaymentNumber) {
      const year = +`25${matchPaymentNumber[1]}` - 543;
      console.log(matchPaymentNumber);

      const month = matchPaymentNumber[2];
      const startOfMonth = moment(`${year}-${month}-01`).startOf('month');
      const endOfMonth = moment(`${year}-${month}-01`).endOf('month');
      const dateBetween = current.isBetween(startOfMonth, endOfMonth, undefined, '[]');
      return current && !dateBetween;
    }

    return true;
  };

  return (
    <>
      <Text strong>เงื่อนไขรายการจ่าย</Text>
      <Divider style={{ margin: '10px 0' }} />
      <Row gutter={[32, 16]}>
        <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  ผู้ตั้งเบิก<Text customType="danger">*</Text>
                  <StyledFormItem
                    validateStatus={errors.employeeId && 'error'}
                    style={{ width: '100%' }}
                  >
                    <Controller
                      control={control}
                      name="employeeId"
                      render={({ onChange, value }) => (
                        <SelectEmployeesField
                          disabled
                          value={value}
                          onChange={(valueChange) => {
                            onChange(valueChange.employeeId);
                            setValue('department', valueChange.department);
                            setValue('employeeFullName', valueChange.fullName);
                          }}
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
                <Col span={12}>
                  แผนกของค่าใช้จ่าย<Text customType="danger">*</Text>
                  <StyledFormItem
                    validateStatus={errors.department && 'error'}
                    style={{ width: '100%' }}
                  >
                    {watch('paymentType') !== 'COGs' ? (
                      <Controller
                        control={control}
                        name="department"
                        render={({ onChange, value }) => (
                          <SelectDepartmentField
                            value={value || undefined}
                            onChange={(valueChange) => {
                              onChange(valueChange);
                            }}
                            disabled={
                              watchPaymentStatus === PaymentStatus.COMPLETE ||
                              watchPaymentStatus === PaymentStatus.REJECT ||
                              watchPaymentStatus === PaymentStatus.CANCEL ||
                              watchPaymentStatus === PaymentStatus.PAID ||
                              watchPaymentStatus === PaymentStatus.EDIT
                            }
                          />
                        )}
                      />
                    ) : (
                      <Input value="-" disabled />
                    )}
                  </StyledFormItem>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col>
                  ผู้รับเงิน <Text customType="grey">(เจ้าหนี้)</Text>
                  <Text customType="danger">*</Text>
                </Col>
                <Col span={24}>
                  <StyledFormItem validateStatus={errors.payeeId && 'error'}>
                    <Controller
                      control={control}
                      name="payeeId"
                      render={({ onChange, value }) => (
                        <SelectPayeesField
                          disabled={
                            watchPaymentStatus === PaymentStatus.COMPLETE ||
                            watchPaymentStatus === PaymentStatus.REJECT ||
                            watchPaymentStatus === PaymentStatus.CANCEL ||
                            watchPaymentStatus === PaymentStatus.PAID ||
                            watchPaymentStatus === PaymentStatus.EDIT
                          }
                          value={value || undefined}
                          onChange={(valueChange) => {
                            if (valueChange) {
                              onChange(valueChange.payeeId);
                              setValue('payeeName', valueChange.fullName);
                            }
                          }}
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Text>รอบที่ต้องจ่าย</Text>
                  <Text customType="danger">*</Text>
                  <StyledFormItem validateStatus={errors.roundPaidDate && 'error'}>
                    <Controller
                      control={control}
                      name="roundPaidDate"
                      render={({ onChange, value }) => (
                        <DatePicker
                          disabled={
                            watchPaymentStatus === PaymentStatus.COMPLETE ||
                            watchPaymentStatus === PaymentStatus.REJECT ||
                            watchPaymentStatus === PaymentStatus.CANCEL ||
                            watchPaymentStatus === PaymentStatus.PAID ||
                            watchPaymentStatus === PaymentStatus.EDIT
                          }
                          style={{ width: '100%' }}
                          allowClear={false}
                          value={value ? moment(value, dateFormat) : null}
                          placeholder="- เลือก -"
                          onChange={(valueDate) => {
                            if (!valueDate) {
                              onChange('');
                            } else {
                              onChange(valueDate.format(dateFormat));
                            }
                          }}
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
                <Col span={12}>
                  <Text>รหัส Shipment/JOB/Booking</Text>
                  <StyledFormItem validateStatus={errors.numberRef && 'error'}>
                    <Controller
                      control={control}
                      name="numberRef"
                      render={({ value, onChange }) => (
                        <InputGroup compact>
                          <Tooltip placement="top" title={watchRefNumber}>
                            <Input
                              disabled={watchPaymentStatus !== PaymentStatus.REVIEW}
                              placeholder="ระบุ"
                              value={value || undefined}
                              onChange={(valueNumberRef) => {
                                if (valueNumberRef) {
                                  onChange(valueNumberRef);
                                } else {
                                  onChange('');
                                }
                              }}
                              style={{ width: 'calc(100% - 32px)' }}
                            />
                          </Tooltip>
                          <ButtonCopy text={watchRefNumber} />
                        </InputGroup>
                      )}
                    />
                  </StyledFormItem>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
          <Row gutter={[16, 16]} justify="space-between">
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Text>ประเภทการจ่าย</Text>
                  <Text customType="danger">*</Text>
                </Col>
                <Col span={24}>
                  <StyledFormItem validateStatus={errors.paymentType && 'error'}>
                    <Controller
                      control={control}
                      name="paymentType"
                      render={({ onChange, value }) => (
                        <SelectPaymentTypeField
                          disabled={
                            watchPaymentStatus === PaymentStatus.COMPLETE ||
                            watchPaymentStatus === PaymentStatus.REJECT ||
                            watchPaymentStatus === PaymentStatus.CANCEL ||
                            watchPaymentStatus === PaymentStatus.PAID ||
                            watchPaymentStatus === PaymentStatus.EDIT
                          }
                          value={value || undefined}
                          onChange={(valuePaymentType) => {
                            onChange(valuePaymentType);
                          }}
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Text>เกี่ยวข้องกับ</Text>
                  <Text customType="danger">*</Text>
                </Col>
                <Col span={24}>
                  <StyledFormItem validateStatus={errors.systemRequest && 'error'}>
                    <Controller
                      control={control}
                      name="systemRequest"
                      render={({ onChange, value }) => (
                        <SelectSystemRequestField
                          disabled={watchPaymentStatus !== PaymentStatus.REVIEW}
                          value={value}
                          onChange={(valueChange) => {
                            onChange(valueChange);
                          }}
                          placeholder="- เลือก -"
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
              </Row>
            </Col>
          </Row>
          {watch('paymentType') === 'OPEX' && (
            <Row gutter={[16, 16]} justify="space-between">
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Text>Fixed Asset Check</Text>
                    <Text customType="danger">*</Text>
                  </Col>
                  <Col span={24}>
                    <StyledFormItem validateStatus={errors.fixedAssetCheck && 'error'}>
                      <Controller
                        control={control}
                        name="fixedAssetCheck"
                        render={({ onChange, value }) => (
                          <Select
                            style={{
                              width: '100%',
                            }}
                            value={value || undefined}
                            placeholder="- เลือก -"
                            onChange={(valueChange) => onChange && onChange(valueChange)}
                            disabled={
                              watchPaymentStatus === PaymentStatus.COMPLETE ||
                              watchPaymentStatus === PaymentStatus.REJECT ||
                              watchPaymentStatus === PaymentStatus.CANCEL ||
                              watchPaymentStatus === PaymentStatus.PAID ||
                              watchPaymentStatus === PaymentStatus.EDIT
                            }
                            allowClear
                          >
                            <Option value="1">Expense</Option>
                            <Option value="2">{'Asset <= 3000'}</Option>
                            <Option value="3">{'Asset > 3000'}</Option>
                          </Select>
                        )}
                      />
                    </StyledFormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row gutter={[16, 16]} justify="space-between">
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Text>Payment Invoice Date</Text>
                  <Text customType="danger">*</Text>
                </Col>
                <Col span={24}>
                  <StyledFormItem validateStatus={errors.paymentInvoiceDate && 'error'}>
                    <Controller
                      control={control}
                      name="paymentInvoiceDate"
                      render={({ onChange, value }) => (
                        <DatePicker
                          disabled={
                            watchPaymentStatus === PaymentStatus.COMPLETE ||
                            watchPaymentStatus === PaymentStatus.REJECT ||
                            watchPaymentStatus === PaymentStatus.CANCEL ||
                            watchPaymentStatus === PaymentStatus.PAID ||
                            watchPaymentStatus === PaymentStatus.EDIT
                          }
                          style={{ width: '100%' }}
                          allowClear={false}
                          value={value ? moment(value, dateFormat) : null}
                          placeholder="- เลือก -"
                          onChange={(valueDate) => {
                            if (!valueDate) {
                              onChange('');
                            } else {
                              onChange(valueDate.format(dateFormat));
                            }
                          }}
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Text>Invoice Due Date</Text>
                  <Text customType="danger">*</Text>
                </Col>
                <Col span={24}>
                  <StyledFormItem validateStatus={errors.invoiceDueDate && 'error'}>
                    <Controller
                      control={control}
                      name="invoiceDueDate"
                      render={({ onChange, value }) => (
                        <DatePicker
                          disabled={
                            watchPaymentStatus === PaymentStatus.COMPLETE ||
                            watchPaymentStatus === PaymentStatus.REJECT ||
                            watchPaymentStatus === PaymentStatus.CANCEL ||
                            watchPaymentStatus === PaymentStatus.PAID ||
                            watchPaymentStatus === PaymentStatus.EDIT
                          }
                          style={{ width: '100%' }}
                          allowClear={false}
                          value={value ? moment(value, dateFormat) : null}
                          placeholder="- เลือก -"
                          onChange={(valueDate) => {
                            if (!valueDate) {
                              onChange('');
                            } else {
                              onChange(valueDate.format(dateFormat));
                            }
                          }}
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify="space-between">
            <Col span={12}>
              <Text>Vendor Invoice</Text>
              <StyledFormItem validateStatus={errors.vendorInvoice && 'error'}>
                <Controller
                  control={control}
                  name="vendorInvoice"
                  render={({ value, onChange }) => (
                    <Input
                      disabled={
                        watchPaymentStatus === PaymentStatus.COMPLETE ||
                        watchPaymentStatus === PaymentStatus.REJECT ||
                        watchPaymentStatus === PaymentStatus.CANCEL ||
                        watchPaymentStatus === PaymentStatus.PAID ||
                        watchPaymentStatus === PaymentStatus.EDIT
                      }
                      placeholder="ระบุ"
                      value={value}
                      onChange={(valueVendorInvoice) => onChange(valueVendorInvoice)}
                    />
                  )}
                />
              </StyledFormItem>
            </Col>
            <Col span={12}>
              <Text>Vendor Name</Text>
              <StyledFormItem validateStatus={errors.vendorId && 'error'}>
                <Controller
                  control={control}
                  name="vendorId"
                  render={({ onChange, value }) => (
                    <SelectPayeesField
                      disabled={
                        watchPaymentStatus === PaymentStatus.COMPLETE ||
                        watchPaymentStatus === PaymentStatus.REJECT ||
                        watchPaymentStatus === PaymentStatus.CANCEL ||
                        watchPaymentStatus === PaymentStatus.PAID ||
                        watchPaymentStatus === PaymentStatus.EDIT
                      }
                      value={value || undefined}
                      onChange={(valueChange) => {
                        onChange(valueChange ? valueChange.payeeId : '');
                      }}
                    />
                  )}
                />
              </StyledFormItem>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            <Col span={8}>วันที่เอกสาร:</Col>
            <Col span={16}>
              <StyledFormItem validateStatus={errors.serviceDate && 'error'}>
                <Controller
                  control={control}
                  name="serviceDate"
                  render={({ onChange, value }) => (
                    <DatePicker
                      disabled={
                        watchPaymentStatus === PaymentStatus.COMPLETE ||
                        watchPaymentStatus === PaymentStatus.REJECT ||
                        watchPaymentStatus === PaymentStatus.CANCEL ||
                        watchPaymentStatus === PaymentStatus.PAID ||
                        watchPaymentStatus === PaymentStatus.EDIT
                      }
                      allowClear={false}
                      style={{ width: '100%' }}
                      value={value ? moment(value, dateFormat) : null}
                      placeholder="- เลือก -"
                      onChange={(valueDate) => {
                        if (!valueDate) {
                          onChange('');
                        } else {
                          onChange(valueDate.format(dateFormat));
                        }
                      }}
                      disabledDate={disabledDate}
                    />
                  )}
                />
              </StyledFormItem>
            </Col>
          </Row>
          <Row>
            <Col span={8}>รหัสใบสั่งจ่าย:</Col>
            <Col span={16}>
              <Text strong>{watchPaymentNumber}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>มูลค่าใบสำคัญจ่าย:</Col>
            <Col span={16}>
              <Text strong customType="primary">
                {formatCurrency(watchGrandTotalPrice)}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>สถานะ:</Col>
            <Col span={16}>
              <StyledFormItem validateStatus={errors.serviceDate && 'error'}>
                <TagStatusPayment status={watchPaymentStatus} />
              </StyledFormItem>
            </Col>
          </Row>
          {watchPaymentStatus === PaymentStatus.REJECT && (
            <Row>
              <Col span={8}>เหตุผลปฏิเสธ:</Col>
              <Col span={16}>
                <Text strong>{watchRejectRemark || '-'}</Text>
              </Col>
            </Row>
          )}
          {watchPaymentStatus === PaymentStatus.CANCEL && (
            <Row>
              <Col span={8}>เหตุผลยกเลิก:</Col>
              <Col span={16}>
                <Text strong>{watchCancelRemark || '-'}</Text>
              </Col>
            </Row>
          )}
          {(watchPaymentStatus === PaymentStatus.PENDING ||
            watchPaymentStatus === PaymentStatus.COMPLETE) && (
            <Row>
              <Controller
                control={control}
                name="saveStatus"
                render={({ value, onChange }) => (
                  <Radio.Group
                    value={value}
                    onChange={(valueOnChange) => onChange(valueOnChange.target.value)}
                  >
                    <Radio value={1}>รอบันทึกบัญชี</Radio>
                    <Radio value={2}>บันทึกบัญชี</Radio>
                  </Radio.Group>
                )}
              />
            </Row>
          )}
        </Col>
      </Row>

      <Divider style={{ margin: 0, marginTop: -5 }} />

      <Tabs defaultActiveKey="1" animated={false}>
        <TabPane
          tab={
            <span>
              รายการจ่าย{' '}
              <CheckCircleOutlined
                style={{
                  color: color.success,
                  marginLeft: 5,
                }}
              />
            </span>
          }
          key="1"
        >
          <ListPayment />
        </TabPane>
        <TabPane
          tab={
            <span>
              เอกสารอื่นๆ{' '}
              <CheckCircleOutlined
                style={{
                  color: color.success,
                  marginLeft: 5,
                }}
              />
            </span>
          }
          key="2"
        >
          <Controller control={control} name="otherDocument" render={() => <UploadDocument />} />
        </TabPane>
      </Tabs>
    </>
  );
};

export default DetailPaymentOrder;
