import React from 'react';
import { Tag } from 'shared/components';
import { TagColorType } from 'shared/components/Tag';
import { CodItemStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/model/codItemStatus.model';

type TagCodItemStatusProps = {
  status: CodItemStatus;
};

const getTextColorStatus = (
  status: CodItemStatus,
): {
  color: TagColorType | undefined;
  text: string | number;
  outline: boolean;
} => {
  switch (status) {
    case 1:
      return { color: undefined, text: 'รอส่งยอด', outline: false };
    case 2:
      return { color: 'blue--light', text: 'รอตรวจสอบ', outline: false };
    case 3:
      return { color: 'green', text: 'ตรวจสอบแล้ว', outline: false };
    case 4:
      return { color: 'red--light', text: 'ปฏิเสธ', outline: false };
    default:
      return { color: undefined, text: status, outline: false };
  }
};

const TagCodItemStatus: React.FC<TagCodItemStatusProps> = (props) => {
  const { status } = props;
  const textColor = getTextColorStatus(status);

  return (
    <Tag customColor={textColor.color} outline={textColor.outline}>
      {textColor.text}
    </Tag>
  );
};

export default TagCodItemStatus;
