import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import {
  PaymentCodContext,
  SelectedPaymentCodDetailType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/context/PaymentCodContext';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/Routes/PaymentCod/PaymentCodListTable/ModalPaymentCodDetail/model/formValues.model';
import { PaymentCodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/paymentCodStatus.model';
import { StyledButton, TextHeader, Container } from './Styles';
import ModalPayCOD from './ModalPayCOD';

const Header: React.FC = () => {
  const { trigger } = useFormContext<FormValues>();
  const [isOpenModalPayCod, setIsOpenModalPayCod] = useState<boolean>(false);
  const { selectedPaymentCodDetail, setSelectedPaymentCodDetail } = useContext(PaymentCodContext);
  const { isLoading, paymentCodNumber, paymentCodStatus } = selectedPaymentCodDetail;

  const closeModalHandler = () => {
    setSelectedPaymentCodDetail(
      (prevState): SelectedPaymentCodDetailType => ({
        ...prevState,
        isOpenModal: false,
      }),
    );
  };

  const openModalPayCod = () => {
    trigger(['paymentMethod', 'accountNumber']).then((valid) => {
      if (valid) {
        setIsOpenModalPayCod(true);
      }
    });
  };

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>{`รหัส COD-OUT ${
            paymentCodNumber ? `: ${paymentCodNumber}` : ''
          }`}</TextHeader>
        </Col>
        <Col>
          {paymentCodStatus === PaymentCodStatus.WAITING && (
            <>
              <StyledButton
                disabled={isLoading}
                icon={<SaveOutlined />}
                color="green"
                onClick={openModalPayCod}
              >
                บันทึกการจ่าย
              </StyledButton>
            </>
          )}

          <ModalPayCOD visible={isOpenModalPayCod} onCancel={() => setIsOpenModalPayCod(false)} />

          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
