import styled from 'styled-components';
import { color } from 'shared/utils/styles';

export const StyledHistoryPopover = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-left: 3px;

  & .ant-btn {
    background-color: transparent;
    border: none;
    fill: #8c8c8c;
  }

  & svg {
    width: 15px;
    height: 15px;
    margin-top: 5px;
    margin-left: 1px;
  }

  & Button:hover {
    background-color: ${color.backgroundPrimary};
    fill: ${color.white};
  }
`;

export default StyledHistoryPopover;
