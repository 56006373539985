/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Row, Col, message, Skeleton } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import INVOICE_DOCUMENTS_QUERY, {
  InvoiceDocumentsData,
  InvoiceDocumentsVars,
} from 'shared/graphql/query/invoiceDocuments';
import { Spin, Text } from 'shared/components';
import { FileContainer } from './Styles';

type DocumentListProps = {
  invoiceId: string;
};

const DocumentList: React.FC<DocumentListProps> = (props) => {
  const { invoiceId } = props;

  const { loading, data } = useQuery<InvoiceDocumentsData, InvoiceDocumentsVars>(
    INVOICE_DOCUMENTS_QUERY,
    {
      variables: {
        invoice_id: invoiceId,
        document_type: 2,
      },
      onError: (err) => {
        message.error(err.message);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  let content = <Skeleton />;

  if (data && data.invoiceDocuments && data.invoiceDocuments.documents.length > 0) {
    const { documents } = data.invoiceDocuments;
    content = (
      <>
        {documents.map((item) => (
          <Col key={item._id} span={24}>
            <FileContainer>
              <a href={item.document_file} target="_blank" rel="noopener noreferrer">
                {item.document_file}
              </a>
            </FileContainer>
          </Col>
        ))}
      </>
    );
  }

  if (data && data.invoiceDocuments && data.invoiceDocuments.documents.length === 0) {
    content = (
      <Col span={24}>
        <Text>ไม่มีรายการ</Text>
      </Col>
    );
  }

  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>{content}</Row>
    </Spin>
  );
};

export default DocumentList;
