import gql from 'graphql-tag';

export interface CreateCostingDocumentVars {
  costing_id: string;
  document_file: string;
}

export interface CreateCostingDocumentData {
  updateCosting: {
    _id: string;
  };
}

export default gql`
  mutation CreateCostingDocument($costing_id: ID!, $document_file: String!) {
    createCostingDocument(
      createCostingDocument: { costing_id: $costing_id, document_file: $document_file }
    ) {
      _id
    }
  }
`;
