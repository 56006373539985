/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Select, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import CUSTOMERS_QUERY from 'shared/graphql/query/customers';

const { Option } = Select;
const SelectCustomersField = (props) => {
  const [fullName, setFullName] = useState('');
  const { loading, error, data } = useQuery(CUSTOMERS_QUERY, {
    variables: {
      full_name: fullName,
      showData: 10,
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Select
      style={{
        width: '100%',
      }}
      allowClear
      notFoundContent={
        !loading &&
        !error && <Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      }
      loading={loading}
      showSearch
      placeholder="ค้นหา"
      onSearch={(value) => setFullName(value)}
      onChange={(value) => props.onChange(value)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {loading || error
        ? []
        : data.customers.customers.map((item) => (
            <Option key={item._id} value={item._id}>
              {item.full_name}
            </Option>
          ))}
    </Select>
  );
};

export default SelectCustomersField;
