/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext, ReactNode } from 'react';
import { Input, DatePicker, message, Row, Col, Select } from 'antd';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { formatDate, formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import BILLING_NOTES_QUERY, {
  BillingNotesData,
  BillingNotesVars,
} from 'shared/graphql/query/billingNotes';
import {
  ReceiptContext,
  SelectedBillingNoteDetailType,
} from 'views/AccountReceive/common/context/ReceiptContext';
import { Text, Button, TableSearch, SelectSystemRequestField } from 'shared/components';
import { BillingNotesStatus } from 'views/AccountReceive/common/model/billingNoteStatus.model';
import { BusinessInvoiceType } from 'views/AccountReceive/common/model/businessInvoiceType.model';
import {
  SelectBusinessTypeField,
  SelectCustomersField,
  SelectEmployeeField,
} from 'views/AccountReceive/common/components';
import { ToolsContainer } from './Styles';
import ModalCreateReceipt from './ModalCreateReceipt';
import ModalBillingNoteDetail from './ModalBillingNoteDetail';

const { Option } = Select;
const { RangePicker } = DatePicker;

type BillingNotesTableProps = {
  status: BillingNotesStatus;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type BillingNotesDataTable = {
  key: number;
  tools: ReactNode;
  serviceDate: string;
  billNumber: string;
  grandTotalPriceBillingNote: ReactNode;
  paidColumn: ReactNode;
  unpaidColumn: ReactNode;
  dueDate: string;
  payerFullName: string;
  businessInvoiceType: string;
  systemRequest: string;
  employeeFullName: string;
  createDate: string;
  lastUpdate: string;
};

const BillingNotesTable: React.FC<BillingNotesTableProps> = (props) => {
  const { status, pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [serviceDate, setServiceDate] = useState<string>('');
  const [billNumber, setBillNumber] = useState<string>('');
  const [payerId, setPayerId] = useState<string>('');
  const [businessInvoiceType, setBusinessInvoiceType] = useState<BusinessInvoiceType>();
  const [businessInvoiceDateRange, setBusinessInvoiceDateRange] = useState<string>('');
  const [systemRequest, setSystemRequest] = useState<string>('');
  const [employeeId, setEmployeeId] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('');
  const [createDate, setCreateDate] = useState<string>('');

  const { setSelectedBillingNoteDetail } = useContext(ReceiptContext);

  const { loading, error, data } = useQuery<BillingNotesData, BillingNotesVars>(
    BILLING_NOTES_QUERY,
    {
      variables: {
        service_date: serviceDate,
        bill_number: billNumber,
        payer: payerId,
        page: currentPage,
        showData: pageSize,
        bill_status: status,
        employee: employeeId,
        system_request: systemRequest,
        business_invoice_type: businessInvoiceType,
        business_invoice_date_range: +businessInvoiceDateRange,
        order_by: orderBy,
        order_type: orderType,
        create_date: createDate,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  let columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'วันที่เอกสาร',
      dataIndex: 'serviceDate',
      sortName: 'service_date',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) => {
            if (values === null) {
              setServiceDate('');
              return;
            }

            setServiceDate(`${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'รหัสใบวางบิล',
      dataIndex: 'billNumber',
      sortName: 'bill_number',
      width: 150,
      search: (
        <Input
          allowClear
          value={billNumber}
          placeholder="ค้นหา"
          onChange={(event) => setBillNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'มูลค่าใบวางบิล',
      dataIndex: 'grandTotalPriceBillingNote',
      width: 150,
      align: 'right',
    },
    {
      title: 'รับชำระแล้ว',
      dataIndex: 'paidColumn',
      width: 150,
      align: 'right',
    },
    {
      title: 'คงเหลือรับชำระ',
      dataIndex: 'unpaidColumn',
      width: 150,
      align: 'right',
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'payerFullName',
      width: 300,
      search: <SelectCustomersField onChange={(value: string) => setPayerId(value)} />,
    },
    {
      title: 'รูปแบบรับใบแจ้งหนี้',
      dataIndex: 'businessInvoiceType',
      width: 300,
      search: (
        <SelectBusinessTypeField
          onChange={(value) => {
            setBusinessInvoiceType(value.businessInvoiceType);
            setBusinessInvoiceDateRange(value.businessDate);
          }}
        />
      ),
    },
    {
      title: 'ประเภทงาน',
      dataIndex: 'systemRequest',
      width: 150,
      search: (
        <SelectSystemRequestField
          onChange={(valueChange) => {
            setSystemRequest(valueChange);
          }}
        />
      ),
    },
    {
      title: 'ผู้สร้าง',
      dataIndex: 'employeeFullName',
      width: 250,
      search: <SelectEmployeeField onChange={(value: string) => setEmployeeId(value)} />,
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'createDate',
      sortName: 'create_date',
      width: 200,
      search: (
        <RangePicker
          onChange={(values) =>
            values === null
              ? setCreateDate('')
              : setCreateDate(
                  `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                )
          }
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
          ranges={{
            วันนี้: [moment(), moment()],
            สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
            เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
            ปีนี้: [moment().startOf('year'), moment().endOf('year')],
          }}
        />
      ),
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'lastUpdate',
      sortName: 'last_update',
      width: 150,
    },
  ];

  if (status === 6) {
    columns = columns.filter(
      (column) => column.dataIndex !== 'paidColumn' && column.dataIndex !== 'unpaidColumn',
    );
  }

  let billingNotesData: BillingNotesDataTable[] = [];
  let totalDocument = 0;

  if (data && data.billingNotes) {
    const { billingNotes } = data.billingNotes;
    billingNotesData = billingNotes.map(
      (item, index): BillingNotesDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <Button
              onClick={() => {
                setSelectedBillingNoteDetail(
                  (prevState): SelectedBillingNoteDetailType => ({
                    ...prevState,
                    billingNoteId: item._id,
                    isOpenModal: true,
                  }),
                );
              }}
              color="grey--text-only"
              icon={<SearchOutlined />}
              size="small"
            />
          </ToolsContainer>
        ),
        serviceDate: !item.service_date ? '-' : formatDate(item.service_date),
        billNumber: item.bill_number || '-',
        grandTotalPriceBillingNote: (
          <Text strong customType="primary">
            {formatCurrency(item.grand_total_price)}
          </Text>
        ),
        payerFullName: item.payer.full_name,
        paidColumn: <Text strong>{formatCurrency(item.paid, '-')}</Text>,
        unpaidColumn: (
          <Text strong customType="danger">
            {formatCurrency(item.unpaid, '-')}
          </Text>
        ),
        dueDate: formatDate(item.due_date),
        businessInvoiceType: item.business_invoice_type,
        systemRequest: item.system_request,
        employeeFullName: item.employee.full_name,
        createDate: formatDateTime(item.create_date),
        lastUpdate: formatDateTime(item.last_update),
      }),
    );

    totalDocument = data.billingNotes.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={billingNotesData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value: number) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />

      {status === 2 && <ModalCreateReceipt />}
      {status === 6 && <ModalBillingNoteDetail />}
    </>
  );
};

export default BillingNotesTable;
