import gql from 'graphql-tag';

export interface CustomerVars {
  _id: string;
}

export interface CustomerData {
  customer: {
    _id: string;
    from: number;
    number: string;
    first_name: string;
    last_name: string;
    full_name: string;
    tax_id: string;
    branch: string;
    phone: string[];
    email: string[];
    address: string;
    province: string;
    district: string;
    sub_district: string;
    postcode: string;
    business_invoice_type: number;
    business_invoice_date_range: {
      start: string;
      end: string;
    };
    payment_bank_account: {
      bank_name: string;
      bank_branch: string;
      account_name: string;
      account_number: string;
    };
    business_pay_round: {
      round_date: string;
      after_invoice: string;
    };
    customer_bank_account: {
      bank_id: {
        _id: string;
        bank_code: string;
        bank_name_th: string;
        bank_name_en: string;
      };
      account_name: string;
      account_number: string;
      mode: number;
      promptPay: {
        type: number;
        account_number: string;
      };
    };
  };
}

export default gql`
  query Customer($_id: ID) {
    customer(_id: $_id) {
      _id
      from
      number
      first_name
      last_name
      full_name
      tax_id
      branch
      phone
      email
      address
      province
      district
      sub_district
      postcode
      business_invoice_type
      business_invoice_date_range {
        start
        end
      }
      payment_bank_account {
        bank_name
        bank_branch
        account_name
        account_number
      }
      business_pay_round {
        round_date
        after_invoice
      }
      customer_bank_account {
        bank_id {
          _id
          bank_code
          bank_name_th
          bank_name_en
        }
        account_name
        account_number
        mode
        promptPay {
          type
          account_number
        }
      }
    }
  }
`;
