/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { Button, Text } from 'shared/components';
import { useMutation } from '@apollo/react-hooks';
import { DebtTrackerContext } from 'views/DebtTracker/common/context/DebtTrackerContext';
import CANCEL_FOLLOW_DEBT_HISTORY_MUTATION, {
  CancelFollowDebtHistoryData,
  CancelFollowDebtHistoryVars,
} from 'shared/graphql/mutation/cancelFollowDebtHistory';
import { StyledModal } from './Styles';

type ModalConfirmCancelProps = {
  visible: boolean;
  onCancel: () => void;
  followDetailId: string;
};

const ModalConfirmCancel: React.FC<ModalConfirmCancelProps> = (props) => {
  const { onCancel, visible, followDetailId } = props;

  const { debtTrackerHistoryValue } = useContext(DebtTrackerContext);

  const [cancelFollowDebtHistory, { loading }] = useMutation<
    CancelFollowDebtHistoryData,
    CancelFollowDebtHistoryVars
  >(CANCEL_FOLLOW_DEBT_HISTORY_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.cancelFollowDebtHistory._id) {
        message.success('ยกเลิกเรียบร้อยแล้ว');
        onCancel();
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['FollowDebtHistory', 'Invoice'],
  });

  const submit = () => {
    cancelFollowDebtHistory({
      variables: {
        _id: debtTrackerHistoryValue.followDebtHistoryId,
        followDetail: followDetailId,
        cancelStatus: true,
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ต้องการยกเลิกบันทึกการติดตามหนี้ใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => submit()}
          loading={loading}
          disabled={loading}
        >
          ใช่,ต้องการยกเลิก
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Text>
            ข้อมูลที่บันทึกการติดตามหนี้ครั้งที่ {debtTrackerHistoryValue.followDetailAmount}
            &nbsp;ทั้งหมดจะถูกยกเลิก กรุณาตรวจสอบความถูกต้องก่อนการยกเลิก
          </Text>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmCancel;
