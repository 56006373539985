import React from 'react';
import { Redirect, Route, RouteComponentProps, matchPath, Switch } from 'react-router-dom';
import Layout from 'shared/container/Layout';
import { PageNotFound } from 'shared/components';
import Customer from './Routes/Customer';
import Creditor from './Routes/Creditor';
import ChartOfAccounts from './Routes/ChartOfAccounts';

const Setting: React.FC<RouteComponentProps> = (props) => {
  const { match, location } = props;

  const checkPath = (path: string) => {
    return !!matchPath(location.pathname, `${match.path}/${path}`);
  };

  const getTextHeader = () => {
    let text = '';
    if (checkPath('customer')) {
      text = 'บัญชีลูกหนี้';
    }
    if (checkPath('creditor')) {
      text = 'บัญชีเจ้าหนี้';
    }
    if (checkPath('chart-of-accounts')) {
      text = 'ผังบัญชี';
    }

    return text;
  };

  const textHeader = getTextHeader();

  return (
    <Layout textHeader={textHeader}>
      <Switch>
        {match.isExact && <Redirect to={`${match.path}/customer`} />}
        <Route path={`${match.path}/customer`} component={Customer} />
        <Route path={`${match.path}/creditor`} component={Creditor} />
        <Route path={`${match.path}/chart-of-accounts`} component={ChartOfAccounts} />
        <Route component={PageNotFound} />
      </Switch>
    </Layout>
  );
};

export default Setting;
