import gql from 'graphql-tag';

export interface CodParcelOrderStatusData {
  codParcelOrderStatus: {
    waiting3PL: number;
    pending: number;
    wait_to_pay: number;
    reject: number;
    cancel: number;
    codOut: number;
    paid: number;
  };
}

export default gql`
  query CodParcelOrderStatus {
    codParcelOrderStatus {
      waiting3PL
      pending
      wait_to_pay
      reject
      cancel
      codOut
      paid
    }
  }
`;
