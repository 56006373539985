import styled from 'styled-components';
import { color } from 'shared/utils/styles';
import { Row } from 'antd';

export const StyledDetailContainer = styled.div`
  border: 1px solid ${color.border};
`;

export const StyledRowDetail = styled(Row)`
  & .ant-col {
    padding: 2px 8px;
  }

  &.divider {
    border-bottom: 1px solid ${color.border};
  }

  & .text-right {
    text-align: right;
  }

  & .column-1 {
    width: 220px;
  }

  & .column-2 {
    width: 145px;
  }

  & .column-3 {
    width: 110px;
  }

  & .column-4 {
    width: 130px;
  }
`;
