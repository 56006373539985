import gql from 'graphql-tag';
import { CodOutStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codOutStatus.model';

export interface UpdateCodOutParcelData {
  updateCodOutParcel: {
    _id: string;
  };
}

export interface UpdateCodOutParcelVars {
  _id: string;
  cod_out_status?: CodOutStatus;
  payment_method?: string;
  bank_account?: {
    bank_name: string;
    bank_branch: string;
    account_name: string;
    account_number: string;
  };
  cod_out_remark?: string;
  proof_slip?: string;
  paid_date?: string;
  bank_ref?: string;
  slip_remark?: string;
  parcel_orders?: string[];
}

export default gql`
  mutation UpdateCodOutParcel(
    $_id: ID!
    $cod_out_status: Int
    $payment_method: String
    $bank_account: inputBankAccount
    $cod_out_remark: String
    $proof_slip: String
    $paid_date: String
    $bank_ref: String
    $slip_remark: String
    $parcel_orders: [ID]
  ) {
    updateCodOutParcel(
      _id: $_id
      update: {
        cod_out_status: $cod_out_status
        payment_method: $payment_method
        bank_account: $bank_account
        cod_out_remark: $cod_out_remark
        proof_slip: $proof_slip
        paid_date: $paid_date
        bank_ref: $bank_ref
        slip_remark: $slip_remark
        parcel_orders: $parcel_orders
      }
    ) {
      _id
    }
  }
`;
