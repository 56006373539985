import gql from 'graphql-tag';
import { PaymentCodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/paymentCodStatus.model';

export interface PaymentCodVars {
  _id: string;
}

export interface ShipmentList {
  _id: string;
  shipment_number: string;
  booking_number: string;
  cod_price: number;
}

export interface CodItem {
  _id: string;
  cod_in_number: string;
  total_cod: number;
  cod_in_date: string;
  shipment_list: ShipmentList[];
  last_update: string;
}

export interface PaymentCodData {
  paymentNewCod: {
    _id: string;
    cod_out_number: string;
    payment_cod_status: PaymentCodStatus;
    total_cod: number;
    paid_date: string;
    bank_ref: string;
    payment_cod_remark: string;
    customer: {
      _id: string;
      full_name: string;
      address: string;
      tax_id: string;
      phone: string[];
      email: string[];
    };
    payment_method: string;
    bank_account: {
      bank_name: string;
      bank_branch: string;
      account_name: string;
      account_number: string;
    };
    cod_item: CodItem[];
    proof_slip: string;
  };
}

export default gql`
  query PaymentNewCod($_id: ID!) {
    paymentNewCod(_id: $_id) {
      _id
      cod_out_number
      payment_cod_status
      total_cod
      paid_date
      bank_ref
      payment_cod_remark
      customer {
        _id
        full_name
        address
        tax_id
        phone
        email
      }
      payment_method
      bank_account {
        bank_name
        bank_branch
        account_name
        account_number
      }
      cod_item {
        _id
        cod_in_number
        total_cod
        cod_in_date
        shipment_list {
          _id
          shipment_number
          booking_number
          cod_price
        }
        last_update
      }
      proof_slip
    }
  }
`;
