import gql from 'graphql-tag';
import { AppStatus } from 'views/AccountPayment/common/model/appStatus.model';

export interface UpdateAppData {
  updateApp: {
    _id: string;
    app_status: AppStatus;
  };
}

export interface UpdateAppVars {
  _id: string;
  app_status?: AppStatus;
  payment_voucher?: string[];
  proof_slip?: string;
  paid_date?: string;
  bank_ref?: string;
  slip_remark?: string;
  round_paid?: string;
  cancle_remark?: string;
}

export default gql`
  mutation UpdateApp(
    $_id: ID!
    $app_status: Int
    $payment_voucher: [ID]
    $proof_slip: String
    $paid_date: String
    $bank_ref: String
    $slip_remark: String
    $round_paid: String
    $cancle_remark: String
  ) {
    updateApp(
      _id: $_id
      update: {
        app_status: $app_status
        payment_voucher: $payment_voucher
        proof_slip: $proof_slip
        paid_date: $paid_date
        bank_ref: $bank_ref
        slip_remark: $slip_remark
        round_paid: $round_paid
        cancle_remark: $cancle_remark
      }
    ) {
      _id
      app_status
    }
  }
`;
