/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useEffect, useContext, ReactNode } from 'react';
import { Row, Col, Select, Input, DatePicker, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { formatDate, formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import COSTINGS_QUERY, {
  CostingsData,
  CostingsList,
  CostingsVars,
} from 'shared/graphql/query/costings';
import {
  InvoiceContext,
  SelectedCostingsType,
  SelectedCostingDetailType,
} from 'views/AccountReceive/common/context/InvoiceContext';
import { Button, Text } from 'shared/components';
import SelectCustomersField from 'views/AccountReceive/Routes/Invoice/SelectCustomersField';
import { CostingStatus } from 'views/Costing/common/model/costingStatus.model';
import { StyledTable, StyledCheckbox, ToolsContainer } from './Styles';

const { Option } = Select;
const { RangePicker } = DatePicker;

type CostingsTableProps = {
  tab: CostingStatus;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type CostingsDataTable = {
  key: number;
  tools: ReactNode;
  column2: string;
  costingNumberColumn: string;
  numberRefColumn: string;
  column5: string;
  column6: string;
  column7: ReactNode;
  column8: string;
  column9: string;
  column10: string;
  column11: string;
};

const CostingsTable: React.FC<CostingsTableProps> = (props) => {
  const { pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [orderBy] = useState<string>('');
  const [orderType] = useState<string>('');
  const [costingNumber, setCostingNumber] = useState<string>('');
  const [numberRef, setNumberRef] = useState<string>('');
  const [, setCreateDate] = useState<string>('');
  const [payerId, setPayerId] = useState<string>('');

  const { selectedCostings, setSelectedCostings, setSelectedCostingDetail } = useContext(
    InvoiceContext,
  );

  useEffect(() => {
    if (selectedCostings.costingsId.length === 0) {
      setSelectedCostings(
        (prevState): SelectedCostingsType => ({
          ...prevState,
          payerId: '',
          dueDate: '',
        }),
      );
    }
  }, [selectedCostings.costingsId.length, setSelectedCostings]);

  const { loading, error, data } = useQuery<CostingsData, CostingsVars>(COSTINGS_QUERY, {
    variables: {
      last_send: '',
      costing_number: costingNumber,
      number_ref: numberRef,
      payer: payerId,
      payee: '',
      employee: '',
      payment: '',
      profit: '',
      invoice: '',
      last_update: '',
      order_by: orderBy,
      order_type: orderType,
      page: currentPage,
      showData: pageSize,
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">วันที่อนุมัติ</div>
          <div>
            <RangePicker
              onChange={(values) =>
                values === null
                  ? setCreateDate('')
                  : setCreateDate(
                      `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                    )
              }
              format="YYYY/MM/DD"
              placeholder={['เริ่มต้น', 'สิ้นสุด']}
            />
          </div>
        </div>
      ),
      dataIndex: 'column2',
      width: 160,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">รหัสต้นทุน/ขาย</div>
          <div>
            <Input
              allowClear
              value={costingNumber}
              placeholder="ค้นหา"
              onChange={(event) => setCostingNumber(event.target.value)}
            />
          </div>
        </div>
      ),
      dataIndex: 'costingNumberColumn',
      width: 150,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">รหัสงานขนส่ง</div>
          <div>
            <Input
              allowClear
              value={numberRef}
              placeholder="ค้นหา"
              onChange={(event) => setNumberRef(event.target.value)}
            />
          </div>
        </div>
      ),
      dataIndex: 'numberRefColumn',
      width: 150,
    },

    {
      title: (
        <div className="table-header">
          <div className="table-header__text">วันที่เริ่มงาน</div>
          <div>
            <RangePicker
              onChange={(values) =>
                values === null
                  ? setCreateDate('')
                  : setCreateDate(
                      `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                    )
              }
              format="YYYY/MM/DD"
              placeholder={['เริ่มต้น', 'สิ้นสุด']}
            />
          </div>
        </div>
      ),
      dataIndex: 'column5',
      width: 160,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">วันที่งานเสร็จสิ้น</div>
          <div>
            <RangePicker
              onChange={(values) =>
                values === null
                  ? setCreateDate('')
                  : setCreateDate(
                      `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                    )
              }
              format="YYYY/MM/DD"
              placeholder={['เริ่มต้น', 'สิ้นสุด']}
            />
          </div>
        </div>
      ),
      dataIndex: 'column6',
      width: 160,
    },
    {
      title: 'ราคาขาย',
      dataIndex: 'column7',
      width: 100,
      align: 'right',
    },

    {
      title: (
        <div className="table-header">
          <div className="table-header__text">ชื่อลูกค้า</div>
          <div>
            <SelectCustomersField onChange={(value: string) => setPayerId(value)} />
          </div>
        </div>
      ),
      dataIndex: 'column8',
      width: 150,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">รูปแบบรับใบแจ้งหนี้</div>
          <div>
            <SelectCustomersField onChange={(value: string) => setPayerId(value)} />
          </div>
        </div>
      ),
      dataIndex: 'column9',
      width: 250,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">ประเภทงาน</div>
          <div>
            <SelectCustomersField onChange={(value: string) => setPayerId(value)} />
          </div>
        </div>
      ),
      dataIndex: 'column10',
      width: 150,
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'column11',
      width: 150,
    },
  ];

  let costingsData: CostingsDataTable[] = [];
  let totalDocument = 0;

  const selectCostings = (item: CostingsList, checked: boolean, value: string) => {
    if (checked) {
      if (selectedCostings.costingsId.length === 0) {
        setSelectedCostings((prevState) => ({
          ...prevState,
          payerId: item.payer._id,
        }));
      }

      setSelectedCostings(
        (prevState): SelectedCostingsType => ({
          ...prevState,
          costingsId: [...prevState.costingsId, value],
        }),
      );
    } else {
      const costingsIdFilter = selectedCostings.costingsId.filter(
        (costingId) => costingId !== value,
      );
      setSelectedCostings((prevState) => ({
        ...prevState,
        costingsId: [...costingsIdFilter],
      }));
    }
  };

  if (data && data.costings) {
    const { costings } = data.costings;
    costingsData = costings.map(
      (item, index): CostingsDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <StyledCheckbox
              checked={selectedCostings.costingsId.indexOf(item._id) !== -1}
              disabled={
                selectedCostings.costingsId.length > 0 &&
                item.payer._id !== selectedCostings.payerId
              }
              value={item._id}
              onChange={(event) => selectCostings(item, event.target.checked, event.target.value)}
            />
            <Button
              onClick={() => {
                setSelectedCostingDetail(
                  (prevState): SelectedCostingDetailType => ({
                    ...prevState,
                    costingId: item._id,
                    isOpenModal: true,
                    costingNumber: item.costing_number,
                  }),
                );
              }}
              color="grey--text-only"
              icon={<SearchOutlined />}
              size="small"
            />
          </ToolsContainer>
        ),
        column2: !item.checked_date ? '-' : formatDate(item.create_date),
        costingNumberColumn: item.costing_number || '-',
        numberRefColumn: item.number_ref || '-',
        column5: !item.checked_date ? '-' : formatDate(item.create_date),
        column6: !item.checked_date ? '-' : formatDate(item.create_date),
        column7: (
          <Text strong customType="primary">
            {formatCurrency(item.grand_total_price.sale, '-')}
          </Text>
        ),
        column8: item.payer.full_name,
        column9: item.payer.full_name,
        column10: item.payer.full_name,
        column11: formatDateTime(item.last_update),
      }),
    );

    totalDocument = data.costings.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>
      <StyledTable
        scroll={{ x: 'max-content' }}
        columns={columns}
        dataSource={costingsData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value: number) => onChangeCurrentPage(value),
        }}
      />
    </>
  );
};

export default CostingsTable;
