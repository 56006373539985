import gql from 'graphql-tag';

export interface ReceiptStatusData {
  receiptStatus: {
    cancel: number;
    complete: number;
  };
}

export default gql`
  query ReceiptStatus {
    receiptStatus {
      cancel
      complete
    }
  }
`;
