import gql from 'graphql-tag';
import { AppStatus } from 'views/AccountPayment/common/model/appStatus.model';

interface Apps {
  _id: string;
  app_number: string;
  app_status: AppStatus;
  create_date: string;
  payment_type: string;
  round_paid: string;
  payment_voucher: {
    voucher_number: string;
  }[];
  last_update: string;
  grand_total_price: number;
}

export interface AppsData {
  apps: {
    apps: Apps[];
    totalDocument: number;
  };
}

export interface AppsVars {
  app_status?: AppStatus;
  app_number?: string;
  payment_type?: 'OPEX' | 'COGs' | 'Advance';
  payment_voucher?: string;
  round_paid?: string;
  create_date?: string;
  last_update?: string;
  showData?: number;
  page?: number;
  order_by?: string;
  order_type?: '1' | '-1' | '';
}

export default gql`
  query Apps(
    $app_status: Int
    $app_number: String
    $payment_type: String
    $payment_voucher: ID
    $round_paid: String
    $create_date: String
    $last_update: String
    $showData: Int
    $page: Int
    $order_by: String
    $order_type: String
  ) {
    apps(
      params: {
        app_status: $app_status
        app_number: $app_number
        payment_type: $payment_type
        payment_voucher: $payment_voucher
        round_paid: $round_paid
        create_date: $create_date
        last_update: $last_update
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      apps {
        _id
        app_number
        app_status
        create_date
        payment_type
        round_paid
        payment_voucher {
          voucher_number
        }
        last_update
        grand_total_price
      }
      totalDocument
    }
  }
`;
