/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  ReceiptContext,
  SelectedBillingNoteDetailType,
} from 'views/AccountReceive/common/context/ReceiptContext';
import { Spin } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { message } from 'antd';
import BILLING_NOTE_QUERY, {
  BillingNoteData,
  BillingNoteVars,
} from 'shared/graphql/query/billingNote';
import Header from './Header';
import { StyledModal } from './Styles';
import BillingNoteDetail from './BillingNoteDetail';
import { FormValues } from './model/formValues.model';

const ModalBillingNoteDetailWrapper: React.FC = () => {
  const { selectedBillingNoteDetail, setSelectedBillingNoteDetail } = useContext(ReceiptContext);
  const { isOpenModal } = selectedBillingNoteDetail;

  return (
    <StyledModal
      visible={isOpenModal}
      onCancel={() => {
        setSelectedBillingNoteDetail(
          (prevState): SelectedBillingNoteDetailType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalBillingNoteDetail />
    </StyledModal>
  );
};

const validateSchema = yup.object().shape({
  billingNoteId: yup.string().required(),
  payerId: yup.string().required(),
  payerFullName: yup.string().required(),
  billInvoice: yup.array().required(),
});

const ModalBillingNoteDetail: React.FC = () => {
  const { selectedBillingNoteDetail, setSelectedBillingNoteDetail } = useContext(ReceiptContext);
  const { isLoading, billingNoteId } = selectedBillingNoteDetail;
  const { data, loading, error } = useQuery<BillingNoteData, BillingNoteVars>(BILLING_NOTE_QUERY, {
    variables: {
      _id: billingNoteId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      billingNoteId: '',
      payerId: '',
      payerFullName: '',
      billInvoice: [],
    },
  });

  const { register, setValue, reset } = methods;

  useEffect(() => {
    setSelectedBillingNoteDetail(
      (prevState): SelectedBillingNoteDetailType => ({
        ...prevState,
        isLoading: loading,
      }),
    );

    if (!loading && !error && data?.billingNote) {
      const { _id, bill_number, payer, bill_status, bill_invoice, document } = data.billingNote;

      setSelectedBillingNoteDetail(
        (prevState): SelectedBillingNoteDetailType => ({
          ...prevState,
          billNumber: bill_number,
          billStatus: bill_status,
          document,
        }),
      );

      setTimeout(() => {
        reset({
          billingNoteId: _id,
          payerId: payer._id,
          payerFullName: payer.full_name,
          billInvoice: bill_invoice.map((item) => item.invoice._id),
        });
      });
    }

    return () => {
      setSelectedBillingNoteDetail(
        (prevState): SelectedBillingNoteDetailType => ({
          ...prevState,
          isLoading: true,
        }),
      );
    };
  }, [data?.billingNote, error, loading, setSelectedBillingNoteDetail, setValue]);

  useEffect(() => {
    register('billingNoteId');
    register('payerId');
    register('payerFullName');
    register('billInvoice');
  }, [register]);

  return (
    <Spin spinning={isLoading}>
      <FormProvider {...methods}>
        <form>
          <Header />
          <BillingNoteDetail />
        </form>
      </FormProvider>
    </Spin>
  );
};

export default ModalBillingNoteDetailWrapper;
