/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { Col, Row, message } from 'antd';
import moment from 'moment';
import { Button, Text } from 'shared/components';
import { formatDate } from 'shared/utils/dateTime';
import { useMutation } from '@apollo/react-hooks';
import { useFormContext } from 'react-hook-form';
import { AppContext } from 'shared/context/AppContext';
import {
  DebtTrackerContext,
  SelectedDebtTrackerHistory,
} from 'views/DebtTracker/common/context/DebtTrackerContext';
import EDIT_FOLLOW_DEBT_HISTORY_MUTATION, {
  EditFollowDebtHistoryData,
  EditFollowDebtHistoryVars,
} from 'shared/graphql/mutation/editFollowDebtHistory';
import { FormValues } from 'views/DebtTracker/Routes/DebtTrackerDetail/ModalDebtTrackerHistory/DebtTrackerHistory/DebtTrackerHistoryForm/formValues.model';
import { StyledModal } from './Styles';

type Params = {
  invoiceId: string;
};

type ModalConfirmEditProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmEdit: React.FC<ModalConfirmEditProps> = (props) => {
  const { onCancel, visible } = props;

  const { invoiceId } = useParams<Params>();

  const { userDetail } = useContext(AppContext);
  const { debtTrackerHistoryValue, setSelectedDebtTrackerHistory } = useContext(DebtTrackerContext);

  const { handleSubmit, watch, reset } = useFormContext<FormValues>();

  const [editFollowDebtHistory, { loading }] = useMutation<
    EditFollowDebtHistoryData,
    EditFollowDebtHistoryVars
  >(EDIT_FOLLOW_DEBT_HISTORY_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.editFollowDebtHistory._id) {
        message.success('บันทึกเรียบร้อยแล้ว');
        onCancel();
        reset({
          invoiceId,
          followDebtHistoryId: debtTrackerHistoryValue.followDebtHistoryId,
          employeeId: userDetail.employeeId,
          nextFollowDate: moment().format('YYYY/MM/DD'),
          followStatus: '',
          followStatusDetail: '',
          action: '',
          actionDetail: '',
          detail: '',
        });
        setSelectedDebtTrackerHistory(
          (prevState): SelectedDebtTrackerHistory => ({
            ...prevState,
            isEditDebtTrackerHistory: false,
          }),
        );
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['FollowDebtHistory', 'Invoice'],
  });

  const submit = handleSubmit((values) => {
    editFollowDebtHistory({
      variables: {
        _id: values.followDebtHistoryId,
        followDetail: values.followDetailId,
        followStatus: values.followStatus,
        followStatusDetail: values.followStatusDetail,
        action: values.action,
        actionDetail: values.actionDetail,
        nextfollowDate: values.nextFollowDate,
        detail: values.detail,
        employee: values.employeeId,
        status: 2,
      },
    });
  });

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันการบันทึกการแก้ไขใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => submit()}
          loading={loading}
          disabled={loading}
        >
          ใช่,บันทึกการแก้ไข
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Text strong>
            บันทึกการติดตามหนี้ ครั้งที่&nbsp;
            {debtTrackerHistoryValue.followDetailAmount}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={5}>
          <Text strong>สถานะการติดต่อ:</Text>
        </Col>
        <Col span={19}>
          <Text>{watch('followStatusDetail') || '-'}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={5}>
          <Text strong>นัดหมายครั้งต่อไป:</Text>
        </Col>
        <Col span={19}>
          <Text>{watch('nextFollowDate') ? formatDate(watch('nextFollowDate')) : '-'}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={5}>
          <Text strong>รายละเอียดเพิ่มเติม:</Text>
        </Col>
        <Col span={19}>
          <Text>{watch('detail') || '-'}</Text>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmEdit;
