import React from 'react';
import { Redirect, Route, matchPath, RouteComponentProps, Switch } from 'react-router-dom';
import { Tabs, PageNotFound } from 'shared/components';
import ChartOfAccountsList from './ChartOfAccountsList';
import Jv from './JV';

type Tabs = 'chart-of-accounts-list' | 'jv';

const ChartOfAccounts: React.FC<RouteComponentProps> = (props) => {
  const { history, match, location } = props;

  const selectTab = (tabIndex: Tabs) => {
    const getUrl = match.path;
    history.push(`${getUrl}/${tabIndex}`);
  };

  const checkTabsActive = (tabIndex: Tabs) => {
    return !!matchPath(location.pathname, `${match.path}/${tabIndex}`);
  };

  const tabs = [
    {
      name: 'รายการผังบัญชี',
      icon: 'file',
      active: checkTabsActive('chart-of-accounts-list'),
      clicked: () => selectTab('chart-of-accounts-list'),
    },
    {
      name: 'เอกสาร JV',
      icon: 'file',
      active: checkTabsActive('jv'),
      clicked: () => selectTab('jv'),
    },
  ];

  return (
    <>
      <Tabs menu={tabs} />
      <Switch>
        {match.isExact && <Redirect to={`${match.path}/chart-of-accounts-list`} />}
        <Route path={`${match.path}/chart-of-accounts-list`} component={ChartOfAccountsList} />
        <Route path={`${match.path}/jv`} component={Jv} />
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default ChartOfAccounts;
