import React, { useState, ReactChild } from 'react';

export interface SelectedDebtTrackerHistory {
  isEditDebtTrackerHistory: boolean;
  historyLogId: string;
}

export interface DebtTrackerHistoryValue {
  followDebtHistoryId: string;
  followDetailAmount: number;
  isShowForm: boolean;
  receiptDate: string;
}

interface DebtTracker {
  selectedDebtTrackerHistory: SelectedDebtTrackerHistory;
  setSelectedDebtTrackerHistory: (
    params: (cb: SelectedDebtTrackerHistory) => SelectedDebtTrackerHistory,
  ) => void;
  debtTrackerHistoryValue: DebtTrackerHistoryValue;
  setDebtTrackerHistoryValue: (
    params: (cb: DebtTrackerHistoryValue) => DebtTrackerHistoryValue,
  ) => void;
}

type DebtTrackerProps = {
  children: ReactChild;
};

const DebtTrackerContext = React.createContext({} as DebtTracker);

const DebtTrackerProvider: React.FC<DebtTrackerProps> = (props) => {
  const { children } = props;
  const [
    selectedDebtTrackerHistory,
    setSelectedDebtTrackerHistory,
  ] = useState<SelectedDebtTrackerHistory>({
    isEditDebtTrackerHistory: false,
    historyLogId: '',
  });

  const [debtTrackerHistoryValue, setDebtTrackerHistoryValue] = useState<DebtTrackerHistoryValue>({
    followDebtHistoryId: '',
    followDetailAmount: 0,
    isShowForm: false,
    receiptDate: '',
  });

  const store = {
    selectedDebtTrackerHistory,
    setSelectedDebtTrackerHistory,
    debtTrackerHistoryValue,
    setDebtTrackerHistoryValue,
  };

  return <DebtTrackerContext.Provider value={store}>{children}</DebtTrackerContext.Provider>;
};

export { DebtTrackerContext, DebtTrackerProvider };
