import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SubTabs } from 'shared/components';
import INVOICE_STATUS_QUERY, { InvoiceStatusData } from 'shared/graphql/query/invoiceStatus';
import { InvoiceStatus } from 'views/AccountReceive/common/model/invoiceStatus.model';

interface InvoiceTabsProps {
  name: string;
  tab: InvoiceStatus;
  onChange: (tab: InvoiceStatus) => void;
}

const InvoiceTabs: React.FC<InvoiceTabsProps> = (props) => {
  const { tab, onChange, name } = props;
  const { data, refetch } = useQuery<InvoiceStatusData>(INVOICE_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const checkSelectTab = (id: InvoiceStatus) => {
    return id === tab && name === 'invoice';
  };

  const selectTab = (index: InvoiceStatus) => {
    refetch();
    onChange(index);
  };

  const tabs = [
    {
      id: InvoiceStatus.INVOICE,
      name: 'รอสร้างใบวางบิล',
      color: 'badge-grey',
      count: data?.invoiceStatus.invoice || 0,
      divider: false,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default InvoiceTabs;
