import gql from 'graphql-tag';

export interface CodItemStatusData {
  newCodItemStatus: {
    ops_pending: number;
    pending: number;
    wait_to_pay: number;
    reject: number;
    cancel: number;
    paid: number;
  };
}

export default gql`
  query NewCodItemStatus {
    newCodItemStatus {
      ops_pending
      pending
      wait_to_pay
      reject
      cancel
      paid
    }
  }
`;
