import React from 'react';
import { Row, Col, Skeleton, Form, DatePicker } from 'antd';
import { Text, SelectCustomersField } from 'shared/components';
import { useFormContext, Controller } from 'react-hook-form';
import { FormValues } from '../formUtil/formValues.model';

const { RangePicker } = DatePicker;

const ParcelCreate: React.FC = () => {
  const { control, errors } = useFormContext<FormValues>();

  let content = <Skeleton />;

  content = (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Row justify="space-between">
            <Col span={24}>
              ลูกค้า (ลูกหนี้):
              <Text type="danger">*</Text>
            </Col>
            <Col span={24}>
              <Form.Item validateStatus={errors.customerId && 'error'} style={{ marginBottom: 10 }}>
                <Controller
                  control={control}
                  name="customerId"
                  render={({ onChange, value }) => (
                    <SelectCustomersField
                      value={value || undefined}
                      onChange={(valueChange) => {
                        if (onChange) {
                          onChange(valueChange || '');
                        }
                      }}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="space-between">
            <Col span={24}>
              <Text>วันที่ Pickup ของงาน LM:</Text>
              <Text type="danger">*</Text>
            </Col>
            <Col span={24}>
              <Form.Item validateStatus={errors.pickupDate && 'error'} style={{ marginBottom: 10 }}>
                <Controller
                  control={control}
                  name="pickupDate"
                  render={({ onChange }) => (
                    <RangePicker
                      onChange={(values) => {
                        if (values) {
                          const formatRangeDate = `${values[0]?.format(
                            'YYYY-MM-DD',
                          )}:${values[1]?.format('YYYY-MM-DD')}`;
                          return onChange(formatRangeDate);
                        }
                        onChange('');
                      }}
                      format="YYYY/MM/DD"
                      placeholder={['เริ่มต้น', 'สิ้นสุด']}
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );

  return content;
};

export default ParcelCreate;
