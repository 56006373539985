import gql from 'graphql-tag';

export default gql`
  query PaymentVoucherStatus {
    paymentVoucherStatus {
      pending
      waiting
      confirm
      complete
      reject
      cancel
      edit
    }
  }
`;
