import React from 'react';
import { SubTabs } from 'shared/components';
import { useQuery } from '@apollo/react-hooks';
import PARCEL_DETAIL_STATUS_QUERY, {
  ParcelDetailStatusData,
} from 'shared/graphql/query/parcelDetailStatus';
import { ParcelDetailStatus } from './ParcelDetailStatus';

interface ParcelDetailTabProps {
  name: string;
  tab: ParcelDetailStatus;
  onChange: (tab: ParcelDetailStatus) => void;
}

const ParcelDetailTab: React.FC<ParcelDetailTabProps> = (props) => {
  const { tab, onChange, name } = props;

  const { data, refetch } = useQuery<ParcelDetailStatusData>(PARCEL_DETAIL_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const checkSelectTab = (id: ParcelDetailStatus) => {
    return id === tab && name === 'parcelDetail';
  };

  const selectTab = (index: ParcelDetailStatus) => {
    refetch();
    onChange(index);
  };

  const tabs = [
    {
      id: ParcelDetailStatus.WAIT_INVOICE,
      name: 'รอสร้างใบแจ้งหนี้',
      color: 'badge-grey',
      count: data?.parcelDetailStatus.waitInvoice || 0,
      active: false,
      divider: false,
    },
    {
      id: ParcelDetailStatus.CREATE_INVOICE,
      name: 'สร้างใบแจ้งหนี้แล้ว',
      color: 'badge-grey',
      count: data?.parcelDetailStatus.createInvoice || 0,
      active: false,
      divider: true,
    },
    {
      id: ParcelDetailStatus.CANCEL,
      name: 'ยกเลิก',
      color: 'badge-grey',
      count: data?.parcelDetailStatus.cancel || 0,
      active: false,
      divider: false,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default ParcelDetailTab;
