import React, { useContext } from 'react';
import { DebtTrackerContext } from 'views/DebtTracker/common/context/DebtTrackerContext';
import DebtTrackerHistoryCreate from './DebtTrackerHistoryCreate';
import DebtTrackerHistoryEdit from './DebtTrackerHistoryEdit';

const DebtTrackerHistoryForm: React.FC = () => {
  const { selectedDebtTrackerHistory } = useContext(DebtTrackerContext);

  let content = <></>;

  if (!selectedDebtTrackerHistory.isEditDebtTrackerHistory) {
    content = <DebtTrackerHistoryCreate />;
  } else {
    content = <DebtTrackerHistoryEdit />;
  }

  return content;
};

export default DebtTrackerHistoryForm;
