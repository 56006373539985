import gql from 'graphql-tag';

export interface LogARData {
  logAR: {
    _id: string;
    customer: {
      _id: string;
      number: string;
      full_name: string;
      business_invoice_type: number;
      business_pay_round: {
        round_date: string;
        after_invoice: string;
      };
      tax_id: string;
      phone: string[];
      email: string[];
      address: string;
      sub_district: string;
      district: string;
      province: string;
      postcode: string;
      full_address: string;
    };
    totalDebt: string;
    totalDebtOverdue: string;
  };
}

export interface LogARVars {
  _id: string;
}

export default gql`
  query LogAR($_id: ID) {
    logAR(_id: $_id) {
      _id
      customer {
        _id
        number
        full_name
        business_invoice_type
        business_pay_round {
          round_date
          after_invoice
        }
        tax_id
        phone
        email
        address
        sub_district
        district
        province
        postcode
        full_address
      }
      totalDebt
      totalDebtOverdue
    }
  }
`;
