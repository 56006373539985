/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import { Divider, Row, Col, Skeleton, Select, Alert } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import formatCurrency from 'shared/utils/formatCurrency';
import { color } from 'shared/utils/styles';
import {
  Text,
  TabPane,
  SelectBankAccountsField,
  SelectFieldSearchCustomers,
} from 'shared/components';
import { useFormContext, Controller } from 'react-hook-form';
import PAYMENT_COD_QUERY, {
  PaymentCodData,
  PaymentCodVars,
} from 'shared/graphql/query/paymentNewCod';
import { PaymentCodContext } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/context/PaymentCodContext';
import { TagPaymentCodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/components';
import { FormValues } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/Routes/PaymentCod/PaymentCodListTable/ModalPaymentCodDetail/model/formValues.model';
import { PaymentCodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/paymentCodStatus.model';
import PaymentCodDetailTable from './PaymentCodDetailTable';
import PaymentCodProofSlip from './PaymentCodProofSlip';
import { StyledFormItem } from './Styles';

const { Option } = Select;

const PaymentCodDetail: React.FC = () => {
  const { selectedPaymentCodDetail } = useContext(PaymentCodContext);
  const { paymentCodId } = selectedPaymentCodDetail;
  const { setValue, control, errors } = useFormContext<FormValues>();
  const { data } = useQuery<PaymentCodData, PaymentCodVars>(PAYMENT_COD_QUERY, {
    variables: {
      _id: paymentCodId,
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = <Skeleton />;

  if (data?.paymentNewCod) {
    const {
      customer,
      cod_out_number,
      total_cod,
      payment_cod_status,
      paid_date,
      bank_ref,
      proof_slip,
    } = data.paymentNewCod;

    content = (
      <>
        {errors && (errors.paymentMethod || errors.accountNumber) ? (
          <Alert
            message="กรุณากรอกข้อมูลให้ครบถ้วน"
            banner
            style={{ margin: '-20px -20px 10px' }}
          />
        ) : null}
        <Text strong>เงื่อนไขการทำจ่าย</Text>
        <Divider style={{ margin: '10px 0' }} />
        <Row gutter={[32, 16]}>
          <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    ผู้รับเงิน <Text customType="grey">(เจ้าหนี้)</Text>:
                  </Col>
                  <Col span={24}>
                    <SelectFieldSearchCustomers
                      value={{
                        key: customer._id,
                        label: customer.full_name,
                      }}
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                ช่องทางการจ่าย:<Text customType="danger">*</Text>
                <StyledFormItem validateStatus={errors.paymentMethod && 'error'}>
                  <Controller
                    control={control}
                    name="paymentMethod"
                    render={({ onChange, value }) => (
                      <Select
                        style={{
                          width: '100%',
                        }}
                        value={value || undefined}
                        placeholder="เลือก"
                        onChange={(valueChange) => onChange(valueChange)}
                        disabled={payment_cod_status !== PaymentCodStatus.WAITING}
                      >
                        <Option value="โอนเงิน/เงินสด">โอนเงิน/เงินสด</Option>
                      </Select>
                    )}
                  />
                </StyledFormItem>
              </Col>
              <Col span={12}>
                บัญชีที่ต้องการโอน:<Text customType="danger">*</Text>
                <StyledFormItem validateStatus={errors.accountNumber && 'error'}>
                  <Controller
                    control={control}
                    name="accountNumber"
                    render={({ onChange, value }) => (
                      <SelectBankAccountsField
                        value={value || undefined}
                        onChange={(valueChange) => {
                          onChange(valueChange.accountNumber);
                          setValue('accountName', valueChange.accountName);
                          setValue('bankName', valueChange.bankName);
                          setValue('bankBranch', valueChange.bankBranch);
                        }}
                        disabled={payment_cod_status !== PaymentCodStatus.WAITING}
                      />
                    )}
                  />
                </StyledFormItem>
              </Col>
            </Row>
          </Col>

          <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
            <Row>
              <Col span={10}>ที่อยู่:</Col>
              <Col span={14}>
                <Text strong>{customer.address || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เลขประจำตัวผู้เสียภาษี:</Col>
              <Col span={14}>
                <Text strong>{customer.tax_id || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เบอร์โทรศัพท์:</Col>
              <Col span={14}>
                <Text strong>{customer.phone.length > 0 ? customer.phone.join(', ') : '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>อีเมล:</Col>
              <Col span={14}>
                <Text strong>{customer.email.length > 0 ? customer.email.join(', ') : '-'}</Text>
              </Col>
            </Row>
          </Col>

          <Col span={8}>
            <Row>
              <Col span={8}>รหัส COD-OUT:</Col>
              <Col span={16}>
                <Text strong>{cod_out_number}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>มูลค่า COD รวม(บาท):</Col>
              <Col span={16}>
                <Text strong customType="primary" style={{ fontSize: 24 }}>
                  {formatCurrency(total_cod)}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>สถานะ:</Col>
              <Col span={16}>
                <TagPaymentCodStatus status={payment_cod_status} />
              </Col>
            </Row>
            {payment_cod_status === PaymentCodStatus.PAID && (
              <>
                <Row>
                  <Col span={8}>วันที่จ่าย:</Col>
                  <Col span={16}>
                    <Text strong>{paid_date}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Bank Ref:</Col>
                  <Col span={16}>
                    <Text strong>{bank_ref}</Text>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>

        <Divider style={{ margin: 0, marginTop: -5 }} />

        <TabPane
          tabs={[
            {
              key: '1',
              text: 'รายละเอียดงาน',
              isShowIcon: true,
              component: <PaymentCodDetailTable />,
            },
            ...(payment_cod_status === PaymentCodStatus.PAID
              ? [
                  {
                    key: '2',
                    text: 'หลักฐานการจ่าย',
                    isShowIcon: true,
                    component: <PaymentCodProofSlip filesPath={[proof_slip]} />,
                  },
                ]
              : []),
          ]}
        />
      </>
    );
  }

  return content;
};

export default PaymentCodDetail;
