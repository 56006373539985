import React from 'react';
import { CostingStatusText, CostingStatusType } from 'shared/constants/status';
import { StyledTag } from './Styles';

type TagStatusProps = {
  status: 1 | 2 | 3 | 4;
};

const convertToStatusType = (status: TagStatusProps['status']): string => {
  switch (status) {
    case 1:
      return CostingStatusType.CHECKING;
    case 2:
      return CostingStatusType.CHECKED;
    case 3:
      return CostingStatusType.REJECT;
    case 4:
      return CostingStatusType.EDIT;
    default:
      return status;
  }
};

const getTextStatus = (statusName: string) => {
  return CostingStatusText[statusName];
};

const TagStatus: React.FC<TagStatusProps> = (props) => {
  const { status } = props;
  const statusType = convertToStatusType(status);
  const text = getTextStatus(statusType);
  return <StyledTag type={statusType}>{text}</StyledTag>;
};

export default TagStatus;
