import React, { useContext, useEffect } from 'react';
import {
  CodInContext,
  SelectedCodsInType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/CodInContext';
import { Spin } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormValues } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/Routes/CodIn/CodInList/ModalCreateCod/model/formValues.model';
import Header from './Header';
import CreateCod from './CreateCod';
import { StyledModal } from './Styles';

const ModalCreateCodWrapper = () => {
  const { selectedCodsIn, setSelectedCodsIn } = useContext(CodInContext);
  const { isOpenModal } = selectedCodsIn;

  return (
    <StyledModal
      visible={isOpenModal}
      onCancel={() => {
        setSelectedCodsIn(
          (prevState): SelectedCodsInType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalCreateCod />
    </StyledModal>
  );
};

const ModalCreateCod: React.FC = () => {
  const { selectedCodsIn } = useContext(CodInContext);
  const { isLoading, codsInId, customerId, customerFullName } = selectedCodsIn;
  const validateSchema = yup.object().shape({
    codsInId: yup.array().required(),
    customerId: yup.string().required(),
    customerFullName: yup.string().required(),
    totalCod: yup.number(),
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      codsInId: [],
      customerId,
      customerFullName,
      paymentCodRemark: '',
    },
  });

  const { register, handleSubmit, reset } = methods;

  useEffect(() => {
    register('codsInId');
    register('customerId');
    register('customerFullName');
  }, [register]);

  useEffect(() => {
    setTimeout(() => {
      reset({
        codsInId,
        customerId,
        customerFullName,
      });
    }, 0);
  }, [codsInId, customerId, customerFullName, reset]);

  const onSubmit = () => {
    alert('submit');
  };

  return (
    <Spin spinning={isLoading}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Header />
          <CreateCod />
        </form>
      </FormProvider>
    </Spin>
  );
};

export default ModalCreateCodWrapper;
