/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, ReactNode } from 'react';
import { message, Row, Col, Select, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useRouteMatch, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import {
  Button,
  TableSearch,
  SelectCustomerField,
  SelectCustomerNumberField,
} from 'shared/components';
import { formatDateTime } from 'shared/utils/dateTime';
import CUSTOMERS_QUERY, { CustomersData, CustomersVars } from 'shared/graphql/query/customers';
import { ToolsContainer } from './Styles';

const { Option } = Select;

type CustomerTableProps = {
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type CustomerDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: string;
  column4: string;
  column5: string;
};

const CustomerTable: React.FC<CustomerTableProps> = (props) => {
  const match = useRouteMatch();

  const { pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [customerId, setCustomerId] = useState<string>('');
  const [taxId, setTaxId] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('full_name');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('1');

  const { loading, error, data } = useQuery<CustomersData, CustomersVars>(CUSTOMERS_QUERY, {
    variables: {
      _id: customerId,
      tax_id: taxId,
      showData: pageSize,
      page: currentPage,
      order_by: orderBy === '' ? 'customerName' : orderBy,
      order_type: orderType === '' ? '1' : orderType,
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'รหัสลูกหนี้',
      dataIndex: 'column1',
      width: 150,
      search: (
        <SelectCustomerNumberField value={customerId} onChange={(value) => setCustomerId(value)} />
      ),
    },
    {
      title: 'ชื่อลูกหนี้',
      dataIndex: 'column2',
      width: 300,
      search: <SelectCustomerField value={customerId} onChange={(value) => setCustomerId(value)} />,
    },
    {
      title: 'เลขประจำตัวผู้เสียภาษี',
      dataIndex: 'column3',
      width: 150,
      search: (
        <Input
          value={taxId}
          placeholder="ค้นหา"
          onChange={(event) => setTaxId(event.target.value)}
          allowClear
        />
      ),
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'column4',
      width: 150,
    },
    {
      title: 'อัพเดตล่าสุด',
      dataIndex: 'column5',
      width: 150,
    },
  ];

  let customersDataTable: CustomerDataTable[] = [];
  let totalDocument = 0;

  if (data?.customers) {
    const { customers } = data.customers;
    customersDataTable = customers.map(
      (item, index): CustomerDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <Link to={`${match.path}/edit/${item._id}`}>
              <Button color="grey--text-only" icon={<SearchOutlined />} size="small" />
            </Link>
          </ToolsContainer>
        ),
        column1: item.number || '-',
        column2: item.full_name || '-',
        column3: item.tax_id || '-',
        column4: item.phone.length > 0 ? item.phone.join(', ') : '-',
        column5: formatDateTime(item.last_update),
      }),
    );

    totalDocument = data.customers.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={customersDataTable}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />
    </>
  );
};

export default CustomerTable;
