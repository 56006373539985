import React from 'react';
import {
  Redirect,
  Route,
  matchPath,
  useHistory,
  useLocation,
  useRouteMatch,
  Switch,
} from 'react-router-dom';
import { Tabs, PageNotFound } from 'shared/components';
import Layout from 'shared/container/Layout';
import { DebtTrackerProvider } from 'views/DebtTracker/common/context/DebtTrackerContext';
import DebtTrackerList from './Routes/DebtTrackerList';
import DebtTrackerDetail from './Routes/DebtTrackerDetail';
import ModalDebtTrackerHistory from './Routes/DebtTrackerDetail/ModalDebtTrackerHistory';

type Tabs = 'debt-tracker';

const DebtTrackerWrapper: React.FC = () => {
  return (
    <DebtTrackerProvider>
      <DebtTracker />
    </DebtTrackerProvider>
  );
};

const DebtTracker: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const selectTab = () => {
    const getUrl = match.path;
    history.push(`${getUrl}`);
  };

  const checkTabsActive = () => {
    return !!matchPath(location.pathname, `${match.path}`);
  };

  const tabs = [
    {
      name: 'รายการตามหนี้',
      icon: 'file',
      active: checkTabsActive(),
      clicked: () => selectTab(),
    },
  ];

  return (
    <>
      {match.isExact && <Redirect to={`${match.path}`} />}
      <Layout textHeader="การติดตามหนี้">
        <Tabs menu={tabs} />
        <Switch>
          <Route path={`${match.path}/detail/:id`} component={DebtTrackerDetail} />
          <Route path={`${match.path}`} component={DebtTrackerList} />
          <Route component={PageNotFound} />
        </Switch>
        <Route
          exact
          path={`${match.path}/detail/:id/invoice/:invoiceId`}
          component={ModalDebtTrackerHistory}
        />
      </Layout>
    </>
  );
};

export default DebtTrackerWrapper;
