import gql from 'graphql-tag';

export interface DeleteCostingDocumentsVars {
  _id: string[];
}

export interface DeleteCostingDocumentsData {
  updateCosting: {
    _id: string;
  };
}

export default gql`
  mutation DeleteCostingDocuments($_id: [ID]) {
    deleteCostingDocuments(_id: $_id) {
      _id
    }
  }
`;
