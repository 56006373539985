/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'shared/components';
import { FormValues } from 'views/AccountReceive/Routes/Invoice/ModalCreateInvoiceFromExcel/model/formValues.model';
import CREATE_INVOICE_FROM_EXCEL_MUTATION, {
  CreateInvoiceFromExcelData,
  CreateInvoiceFromExcelVars,
} from 'shared/graphql/mutation/createInvoiceFromExcel';
import { useFormContext } from 'react-hook-form';
import { InvoiceContext } from 'views/AccountReceive/common/context/InvoiceContext';
import { StyledModal } from './Styles';

type ModalConfirmProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirm: React.FC<ModalConfirmProps> = (props) => {
  const { onCancel, visible } = props;
  const { reset, handleSubmit } = useFormContext<FormValues>();
  const { setCreateInvoiceFromExcel } = useContext(InvoiceContext);

  const [createInvoiceFromExcel, { loading }] = useMutation<
    CreateInvoiceFromExcelData,
    CreateInvoiceFromExcelVars
  >(CREATE_INVOICE_FROM_EXCEL_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.createInvoiceFromExcel.inProgress) {
        message.success('สร้างเอกสารเรียบร้อยแล้ว');
        reset();
        onCancel();
        setCreateInvoiceFromExcel((prevState) => ({
          ...prevState,
          isOpenModal: false,
        }));
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['Invoices', 'Invoice'],
  });

  const onSubmit = handleSubmit((values) => {
    createInvoiceFromExcel({
      variables: {
        payer: values.payerId,
        filePath: values.file,
      },
    });
  });

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันหรือไม่?"
      visible={visible}
      onCancel={() => onCancel()}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => onSubmit()}
          loading={loading}
        >
          ใช่
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={() => onCancel()}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <div>ยืนยันการสร้าง</div>
    </StyledModal>
  );
};

export default ModalConfirm;
