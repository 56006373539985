import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Route, useRouteMatch } from 'react-router-dom';
import ListParcelTab from './components/ParcelDetailTab';
import { Container } from './Styles';
import ListParcelTable from './components/ParcelDetailTable';
import { ParcelDetailStatus } from './components/ParcelDetailStatus';
import ModalParcelDetail from './components/ModalParcelDetail';

const ParcelDetail: React.FC = () => {
  const [tab, setTab] = React.useState<ParcelDetailStatus>(ParcelDetailStatus.WAIT_INVOICE);
  const { path } = useRouteMatch();
  const [tabName, setTabName] = React.useState<'parcelDetail'>('parcelDetail');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={18}>
          <ListParcelTab
            tab={tab}
            name={tabName}
            onChange={(value) => {
              setTab(value);
              setTabName('parcelDetail');
              setCurrentPage(1);
            }}
          />
        </Col>
      </Row>

      {tabName === 'parcelDetail' && (
        <Row>
          <Col span={24}>
            <ListParcelTable
              tab={tab}
              pageSize={pageSize}
              onChangePageSize={(value) => setPageSize(value)}
              currentPage={currentPage}
              onChangeCurrentPage={(value) => setCurrentPage(value)}
            />
          </Col>
        </Row>
      )}

      <Route
        exact
        path={`${path}/detail/:parcelDetailId`}
        render={(routeProps) => (
          <ModalParcelDetail
            parcelDetailId={routeProps.match.params.parcelDetailId}
            goBack={path}
          />
        )}
      />
    </Container>
  );
};

export default ParcelDetail;
