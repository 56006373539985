import React from 'react';
import { Tag } from 'shared/components';
import { TagColorType } from 'shared/components/Tag';
import { AppStatus } from 'views/AccountPayment/common/model/appStatus.model';

type TagStatusAppProps = {
  status: AppStatus;
};

const getTextColorStatus = (
  status: AppStatus,
): {
  color: TagColorType | undefined;
  text: string | number;
  outline: boolean;
} => {
  switch (status) {
    case 1:
      return { color: 'blue', text: 'รออนุมัติจ่าย', outline: false };
    case 2:
      return { color: 'green--light', text: 'รอบันทึกการจ่าย', outline: false };
    case 3:
      return { color: 'green', text: 'จ่ายเรียบร้อย', outline: false };
    case 4:
      return { color: 'red--light', text: 'แก้ไข', outline: false };
    case 5:
      return { color: 'red--light', text: 'ยกเลิก', outline: false };
    default:
      return { color: undefined, text: status, outline: false };
  }
};

const TagStatusApp: React.FC<TagStatusAppProps> = (props) => {
  const { status } = props;
  const textColor = getTextColorStatus(status);

  return (
    <Tag customColor={textColor.color} outline={textColor.outline}>
      {textColor.text}
    </Tag>
  );
};

export default TagStatusApp;
