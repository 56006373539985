import gql from 'graphql-tag';

export default gql`
  mutation UpdatePaymentWHT(
    $_id: ID!
    $voucher_id: ID
    $doc_type: String
    $wht_type: String
    $vendor: ID
    $wht_list: [inputwhtList]
  ) {
    updatePaymentWHT(
      _id: $_id
      updatePaymentWHT: {
        voucher_id: $voucher_id
        doc_type: $doc_type
        wht_type: $wht_type
        vendor: $vendor
        wht_list: $wht_list
      }
    ) {
      _id
    }
  }
`;
