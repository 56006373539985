import { useQuery } from '@apollo/react-hooks';
import currentUserQuery from './graphql/query/currentUser';
import { removeStoredAuthToken, getStoredAuthToken } from 'shared/utils/authToken';
import { useHistory } from 'react-router-dom';

const useCurrentUser = () => {
  const history = useHistory();
  const token = getStoredAuthToken();

  const { loading, error, data } = useQuery(currentUserQuery, {
    fetchPolicy: 'cache-and-network',
  });

  if (data && data.currentUser === null && token) {
    removeStoredAuthToken();
    history.push('/');
  }

  return { loading, error, data };
};

export default useCurrentUser;
