import gql from 'graphql-tag';
import { CodParcelStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codParcelStatus.model';

export interface ParcelOrder {
  _id: string;
  shipment_number: string;
  customer: {
    _id: string;
    full_name: string;
  };
  delivered_date: string;
  tracking_number: string;
  cod_price: number;
}

export interface CodParcelData {
  codParcel: {
    _id: string;
    cod_in_number: string;
    total_cod: number;
    cod_status: CodParcelStatus;
    vendor: {
      _id: string;
      full_name: string;
    };
    parcel_orders: ParcelOrder[];
    cod_in_date: string;
    paid_qr_detail: {
      qr_ref1: string;
      qr_ref2: string;
      qr_from_name: string;
      qr_bank_ref: string;
    };
    document: string[];
    reject_remark: string;
  };
}

export interface CodParcelVars {
  _id: string;
}

export default gql`
  query CodParcel($_id: ID) {
    codParcel(_id: $_id) {
      _id
      cod_in_number
      total_cod
      cod_status
      vendor {
        _id
        full_name
      }
      parcel_orders {
        _id
        shipment_number
        customer {
          _id
          full_name
        }
        delivered_date
        tracking_number
        cod_price
      }
      cod_in_date
      paid_qr_detail {
        qr_ref1
        qr_ref2
        qr_from_name
        qr_bank_ref
      }
      document
      reject_remark
    }
  }
`;
