/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React from 'react';
import { RouteComponentProps, matchPath } from 'react-router-dom';
import { PageNotFound } from 'shared/components';
import { Container } from './Styles';
import CreateJv from './CreateJv';
import EditJv from './EditJv';

const JvDetail: React.FC<RouteComponentProps> = (props) => {
  const { location } = props;

  let content = <PageNotFound />;

  const matchPage = (path: string) => {
    return !!matchPath(location.pathname, path);
  };

  if (matchPage(`/setting/chart-of-accounts/jv/create`)) {
    content = <CreateJv />;
  } else if (matchPage(`/setting/chart-of-accounts/jv/edit`)) {
    content = <EditJv />;
  }

  return <Container>{content}</Container>;
};

export default JvDetail;
