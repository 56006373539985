/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message, Divider } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { PaymentVoucherContext } from 'views/AccountPayment/common/context/PaymentVoucherContext';
import CREATE_APP_MUTATION, {
  CreateAppData,
  CreateAppDataVars,
} from 'shared/graphql/mutation/createApp';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { formatDate } from 'shared/utils/dateTime';
import { FormValues } from 'views/AccountPayment/Routes/PaymentVoucher/ModalCreateApp/model/formValues.model';
import { useFormContext } from 'react-hook-form';
import { StyledModal } from './Styles';

type ModalConfirmCreateAppProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmCreateApp: React.FC<ModalConfirmCreateAppProps> = (props) => {
  const { onCancel, visible } = props;
  const { getValues, reset } = useFormContext<FormValues>();
  const { selectedPaymentVouchers: selectedPaymentVouchersContext } = useContext(
    PaymentVoucherContext,
  );
  const [selectedPaymentVouchers, setSelectedPaymentVouchers] = selectedPaymentVouchersContext;
  const { modalConfirmCreateApp } = selectedPaymentVouchers;
  const { grandTotalPrice, paymentVouchers } = modalConfirmCreateApp;

  const [createApp, { loading }] = useMutation<CreateAppData, CreateAppDataVars>(
    CREATE_APP_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.createApp._id) {
          message.success('สร้างรอบจ่ายแล้ว');
          onCancel();
          setSelectedPaymentVouchers((prevState) => ({
            ...prevState,
            isOpenModal: false,
            vouchersId: [],
          }));
          reset();
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => [
        'PaymentVoucher',
        'PaymentVouchers',
        'PaymentVoucherStatus',
        'AppStatus',
      ],
    },
  );

  const confirmCreateApp = () => {
    createApp({
      variables: {
        payment_voucher: getValues('vouchersId').map((voucherId) => voucherId),
        payment_method: getValues('paymentMethod'),
        bank_account: {
          account_number: getValues('accountNumber'),
          bank_name: getValues('bankName'),
          bank_branch: getValues('bankBranch'),
          account_name: getValues('accountName'),
        },
        payment_type: getValues('paymentType'),
        round_paid: getValues('roundPaid'),
        app_remark: getValues('appRemark'),
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันการสร้างรอบจ่ายใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmCreateApp()}
          loading={loading}
        >
          สร้างรอบจ่าย
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>มูลค่าใบสำคัญจ่าย (บาท):</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(grandTotalPrice, '0')}
          </Text>
        </Col>
      </Row>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Row>
        <Col span={24}>
          ทั้งหมด <Text strong>{`${paymentVouchers.length} รายการ`}</Text>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 0]}>
            <Col span={4}>วันที่เอกสาร</Col>
            <Col span={4}>รหัสใบสำคัญจ่าย</Col>
            <Col span={4}>รอบจ่าย</Col>
            <Col span={4}>ผู้รับเงิน</Col>
            <Col span={6}>จำนวนตั้งเบิก (บาท)</Col>
          </Row>

          {paymentVouchers.map((paymentVoucher) => (
            <Row gutter={[8, 0]} key={paymentVoucher.paymentVoucherNumber}>
              <Col span={4}>
                <Text strong>
                  {paymentVoucher.createDate ? formatDate(paymentVoucher.createDate) : '-'}
                </Text>
              </Col>
              <Col span={4}>
                <Text strong>{paymentVoucher.paymentVoucherNumber || '-'}</Text>
              </Col>
              <Col span={4}>
                <Text strong>{formatDate(paymentVoucher.roundPaid)}</Text>
              </Col>
              <Col span={4}>
                <Text strong>{paymentVoucher.payee || '-'}</Text>
              </Col>
              <Col span={6}>
                <Text strong customType="primary">
                  {formatCurrency(paymentVoucher.grandTotalPrice)}
                </Text>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmCreateApp;
