/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import { Divider, Row, Col, Tabs } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { color } from 'shared/utils/styles';
import { Text } from 'shared/components';
import { useFormContext } from 'react-hook-form';
import { ReceiptContext } from 'views/AccountReceive/common/context/ReceiptContext';
import { SelectFieldSearchCustomers } from 'views/AccountReceive/common/components';
import { FormValues } from 'views/AccountReceive/Routes/Receipt/BillingNotesTable/ModalCreateReceipt/model/formValues.model';
import useSumTotalPrice from 'views/AccountReceive/Routes/Receipt/BillingNotesTable/ModalCreateReceipt/hooks/useSumTotalPrice';
import BillingNoteDetailTable from './BillingNoteDetailTable';

const { TabPane } = Tabs;

const RecheckStep: React.FC = () => {
  const { selectedBillingNoteDetail } = useContext(ReceiptContext);
  const {
    payerId,
    payerFullName,
    billNumber,
    grandTotalPrice,
    serviceDate,
  } = selectedBillingNoteDetail;
  const { getValues } = useFormContext<FormValues>();
  const allFieldValue = getValues();
  const { totalPriceRight } = useSumTotalPrice();

  return (
    <>
      <Row gutter={[32, 16]}>
        <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>:
                </Col>
                <Col span={24}>
                  <SelectFieldSearchCustomers
                    value={{
                      key: payerId,
                      label: payerFullName,
                    }}
                    disabled
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
          <Row>
            <Col span={10}>ช่องทางการรับ:</Col>
            <Col span={14}>
              <Text strong>{allFieldValue.channel}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={10}>ธนาคารที่รับชำระ:</Col>
            <Col span={14}>
              <Text strong>{allFieldValue.bankName}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={10}>เลขบัญชีที่รับชำระ:</Col>
            <Col span={14}>
              <Text strong>{allFieldValue.accountNumber}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={10}>วันที่รับเงิน:</Col>
            <Col span={14}>
              <Text strong>{formatDate(allFieldValue.receiptDate)}</Text>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            <Col span={8}>วันที่เอกสาร:</Col>
            <Col span={16}>
              <Text strong>{formatDate(serviceDate)}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>รหัสใบวางบิล:</Col>
            <Col span={16}>
              <Text strong>{billNumber}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>รวมมูลค่าใบวางบิล:</Col>
            <Col span={16}>
              <Text strong customType="primary">
                {formatCurrency(grandTotalPrice)}
              </Text>
            </Col>
          </Row>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          <Row>
            <Col span={24}>
              มูลค่าใบแจ้งหนี้ที่รับชำระ:{' '}
              <Text strong>{formatCurrency(allFieldValue.totalPaid)}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>จำนวนที่รับชำระ:</Col>
            <Col span={16}>
              <Text strong>{formatCurrency(totalPriceRight)}</Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider style={{ margin: 0, marginTop: -5 }} />

      <Tabs defaultActiveKey="1" animated={false}>
        <TabPane
          tab={
            <span>
              รายละเอียดงาน{' '}
              <CheckCircleOutlined
                style={{
                  color: color.success,
                  marginLeft: 5,
                }}
              />
            </span>
          }
          key="1"
        >
          <BillingNoteDetailTable />
        </TabPane>
      </Tabs>
    </>
  );
};

export default RecheckStep;
