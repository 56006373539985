import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';

interface ButtonCopyProps {
  text: string;
}

const ButtonCopy: React.FC<ButtonCopyProps> = (props) => {
  const [isLinkCopied, setLinkCopied] = useState(false);
  const { text, ...otherProps } = props;

  const handleLinkCopy = () => {
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
  };

  return (
    <CopyToClipboard text={text}>
      <Tooltip title={isLinkCopied ? 'คัดลอกแล้ว' : 'คัดลอก'}>
        <Button onClick={handleLinkCopy} icon={<CopyOutlined />} {...otherProps} />
      </Tooltip>
    </CopyToClipboard>
  );
};

export default ButtonCopy;
