import React, { useRef } from 'react';
import { Row, Col, message } from 'antd';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { Text } from 'shared/components';
import { FormValues } from 'views/AccountReceive/Routes/Invoice/ModalCreateInvoiceFromExcel/model/formValues.model';
import { useFormContext } from 'react-hook-form';
import SINGLE_UPLOAD_MUTATION, {
  SingleUploadData,
  SingleUploadVars,
} from 'shared/graphql/mutation/singleUpload';
import { useMutation } from '@apollo/react-hooks';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

type UploadDocumentProps = {
  onChange: (filesPath: string) => void;
};

const UploadDocument: React.FC<UploadDocumentProps> = (props) => {
  const { onChange } = props;
  const filePondRef = useRef<any>();
  const { errors } = useFormContext<FormValues>();

  const [singleUpload] = useMutation<SingleUploadData, SingleUploadVars>(SINGLE_UPLOAD_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.singleUpload.path) {
        onChange(data?.singleUpload.path);
        message.success('อัพโหลดเอกสารแล้ว');
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {errors.file && <Text customType="danger">กรุณาอัพโหลดเอกสาร</Text>}
        <FilePond
          ref={filePondRef}
          maxFiles={1}
          server={{
            process: (_fieldName, file, _metadata, _load, error) => {
              singleUpload({
                variables: {
                  file: file as File,
                },
              })
                .then(() => {
                  // filePondRef.current.removeFile();
                  _load(file);
                })
                .catch((err) => {
                  message.error(err.message);
                  error(err);
                });
            },
            revert: () => {
              onChange('');
            },
          }}
          acceptedFileTypes={[
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ]}
          maxFileSize="2MB"
        />
      </Col>
    </Row>
  );
};

export default UploadDocument;
