import styled from 'styled-components';
import { Row, Form } from 'antd';
import { color } from 'shared/utils/styles';

export const Container = styled.div``;
export const StyledTotalPriceContainer = styled(Row)`
  border: 1px solid ${color.grey};
  padding: 5px 10px;
  border-radius: 6px;
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;
