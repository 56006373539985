/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { Spin } from 'shared/components';
import { message } from 'antd';
import { PaymentVoucherContext } from 'views/AccountPayment/common/context/PaymentVoucherContext';
import CREATE_PAYMENT_WHT_MUTATION from 'shared/graphql/mutation/createPaymentWHT';
import UPDATE_PAYMENT_WHT_MUTATION from 'shared/graphql/mutation/updatePaymentWHT';
import UPDATE_PAYMENT_VOUCHER_MUTATION from 'shared/graphql/mutation/updatePaymentVoucher';
import Header from './Header';
import PaymentDetail from './PaymentDetail';
import { StyledModal } from './Styles';
import initialWhtList from './initialWhtList';

const informationSchema = yup.object().shape({
  // payment WHT
  docType: yup.string().when('paymentWhtId', {
    is: (value) => value,
    then: yup.string().required(),
  }),
  whtType: yup.string().when('docType', {
    is: (value) => value,
    then: yup.string().required(),
  }),
  paymentWhtId: yup.string().nullable(),
  whtList: yup.array().when('docType', (docType) => {
    if (docType) {
      return yup.array().of(
        yup.object().shape(
          {
            description: yup.string().required(),
            date: yup
              .string()
              .when('tax', {
                is: (value) => value,
                then: yup.string().required(),
              })
              .when('amount', {
                is: (value) => value,
                then: yup.string().required(),
              })
              .when('input1', {
                is: (value) => value,
                then: yup.string().required(),
              }),
            amount: yup
              .string()
              .when('tax', {
                is: (value) => value,
                then: yup.string().required(),
              })
              .when('date', {
                is: (value) => value,
                then: yup.string().required(),
              })
              .when('input1', {
                is: (value) => value,
                then: yup.string().required(),
              }),
            tax: yup
              .number()
              .when('amount', {
                is: (value) => value,
                then: yup.number().required(),
              })
              .when('date', {
                is: (value) => value,
                then: yup.number().required(),
              })
              .when('input1', {
                is: (value) => value,
                then: yup.number().required(),
              }),
            input1: yup
              .string()
              .when(['amount', 'isHasInput1'], {
                is: (amount, isHasInput1) => amount && isHasInput1,
                then: yup.string().required(),
              })
              .when(['date', 'isHasInput1'], {
                is: (date, isHasInput1) => date && isHasInput1,
                then: yup.string().required(),
              })
              .when(['tax', 'isHasInput1'], {
                is: (tax, isHasInput1) => tax && isHasInput1,
                then: yup.string().required(),
              }),
            input2: yup.string(),
          },
          [
            ['date', 'amount'],
            ['date', 'tax'],
            ['date', 'input1'],
            ['amount', 'tax'],
            ['amount', 'input1'],
            ['tax', 'input1'],
          ],
        ),
      );
    }

    return yup.array();
  }),
});

const initialValues = {
  paymentVoucherId: '',
  payeeId: '',
  payeeFullName: '',
  paymentMethod: '',
  voucherRemark: '',
  bankName: '',
  bankBranch: '',
  accountName: '',
  accountNumber: '',
  // payment wht
  docType: '',
  whtType: '',
  bookNumber: '',
  runningNumber: '',
  whtOrder: '',
  whtList: [...initialWhtList],
  paymentWhtId: '',
  saveStatus: 1,
};

const PaymentVoucherDetail = () => {
  const { selectedPaymentVoucherDetail: selectedPaymentVoucherDetailContext } = useContext(
    PaymentVoucherContext,
  );
  const [
    selectedPaymentVoucherDetail,
    setSelectedPaymentVoucherDetail,
  ] = selectedPaymentVoucherDetailContext;
  const { paymentVoucherId, isOpenModal, isLoading, voucherStatus } = selectedPaymentVoucherDetail;

  const [createPaymentWHT] = useMutation(CREATE_PAYMENT_WHT_MUTATION, {
    update: (_cache, { data }) => {
      const { _id } = data.createPaymentWHT;
      if (_id) {
        message.success('สร้างภาษีหัก ณ ที่จ่ายแล้ว');
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['PaymentVoucher', 'PaymentWHT'],
  });

  const [updatePaymentWHT] = useMutation(UPDATE_PAYMENT_WHT_MUTATION, {
    update: (_cache, { data }) => {
      const { _id } = data.updatePaymentWHT;
      if (_id) {
        message.success('บันทึกภาษีหัก ณ ที่จ่ายแล้ว');
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['PaymentVoucher', 'PaymentWHT'],
  });

  const [updatePaymentVoucher] = useMutation(UPDATE_PAYMENT_VOUCHER_MUTATION, {
    update: (_cache, { data }) => {
      const { _id } = data.updatePaymentVoucher;
      if (_id) {
        message.success('บันทึกใบสำคัญจ่ายแล้ว');
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['PaymentVoucher', 'PaymentVouchers'],
  });

  const closeModalHandler = () => {
    setSelectedPaymentVoucherDetail((prevState) => ({
      ...prevState,
      isOpenModal: false,
    }));
  };

  const savePaymentWHT = (values) => {
    const { docType, whtType, payeeId, whtList, paymentWhtId } = values;
    if (paymentWhtId && docType) {
      updatePaymentWHT({
        variables: {
          _id: paymentWhtId,
          voucher_id: paymentVoucherId,
          doc_type: docType,
          wht_type: whtType,
          vendor: payeeId,
          wht_list: whtList.map((item) => ({
            description: item.description || null,
            date: item.date || null,
            amount: item.amount !== '' ? +item.amount : null,
            tax: item.tax !== '' ? +item.tax : null,
            input1: item.input1 !== '' ? item.input1 : null,
            input2: item.input2 !== '' ? item.input2 : null,
          })),
        },
      });

      return;
    }

    if (!docType) {
      return;
    }

    createPaymentWHT({
      variables: {
        voucher_id: paymentVoucherId,
        doc_type: docType,
        wht_type: whtType,
        vendor: payeeId,
        wht_list: whtList.map((item) => ({
          description: item.description || undefined,
          date: item.date || undefined,
          amount: item.amount !== '' ? +item.amount : undefined,
          tax: item.tax !== '' ? +item.tax : undefined,
          input1: item.input1 !== '' ? item.input1 : undefined,
          input2: item.input2 !== '' ? item.input2 : undefined,
        })),
      },
    });
  };

  const saveUpdatePaymentVoucher = (values) => {
    updatePaymentVoucher({
      variables: {
        _id: paymentVoucherId,
        voucher_remark: values.voucherRemark,
        save_status: values.saveStatus,
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={informationSchema}
      enableReinitialize={!isOpenModal}
      onSubmit={(values) => {
        if (voucherStatus === 5 || voucherStatus === 6) {
          return;
        }

        if (voucherStatus === 4) {
          saveUpdatePaymentVoucher(values);
          savePaymentWHT(values);
          return;
        }

        saveUpdatePaymentVoucher(values);
        savePaymentWHT(values);
      }}
    >
      {(formikProps) => {
        const { handleReset } = formikProps;
        return (
          <StyledModal
            title={<Header />}
            visible={isOpenModal}
            onCancel={() => {
              closeModalHandler();
            }}
            closable={false}
            className="custom-modal"
            footer={null}
            destroyOnClose
            afterClose={() => {
              handleReset();
            }}
          >
            <Spin spinning={isLoading}>
              <PaymentDetail />
            </Spin>
          </StyledModal>
        );
      }}
    </Formik>
  );
};

export default PaymentVoucherDetail;
