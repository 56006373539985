import gql from 'graphql-tag';

export interface PaymentStatusData {
  paymentStatus: {
    review: number;
    pending: number;
    complete: number;
    paid: number;
    reject: number;
    cancel: number;
    edit: number;
  };
}

export default gql`
  query PaymentStatus {
    paymentStatus {
      review
      pending
      complete
      paid
      reject
      cancel
      edit
    }
  }
`;
