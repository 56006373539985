import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SubTabs } from 'shared/components';
import COD_OUT_STATUS_QUERY, { CodOutParcelStatusData } from 'shared/graphql/query/codOutStatus';
import { CodOutStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codOutStatus.model';

interface PaymentCodTabsProps {
  name: string;
  tab: CodOutStatus;
  onChange: (tab: CodOutStatus) => void;
}

const PaymentCodTabs: React.FC<PaymentCodTabsProps> = (props) => {
  const { tab, onChange, name } = props;
  const { data, refetch } = useQuery<CodOutParcelStatusData>(COD_OUT_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const checkSelectTab = (id: CodOutStatus) => {
    return id === tab && name === 'paymentCod';
  };

  const selectTab = (index: CodOutStatus) => {
    refetch();
    onChange(index);
  };

  const tabs = [
    {
      id: CodOutStatus.WAITING,
      name: 'รอบันทึกจ่าย',
      color: 'badge-grey',
      count: data?.codOutStatus.waiting || 0,
      divider: false,
    },
    {
      id: CodOutStatus.PAID,
      name: 'จ่ายเรียบร้อย',
      color: 'badge-grey',
      count: data?.codOutStatus.paid || 0,
      divider: false,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default PaymentCodTabs;
