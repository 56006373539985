import React, { useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Spin } from 'shared/components';
import { useQuery } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import { AppContext } from 'shared/context/AppContext';
import {
  PaymentOrderContext,
  CreatePaymentOrderType,
} from 'views/AccountPayment/common/context/PaymentOrderContext';
import EMPLOYEE_QUERY, { EmployeeData, EmployeeVars } from 'shared/graphql/query/employee';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalCreatePaymentOrder/model/formValues.model';
import Header from './Header';
import CreatePaymentOrder from './CreatePaymentOrder';
import { StyledModal } from './Styles';

const ModalCreatePaymentOrderWrapper = () => {
  const { createPaymentOrder: createPaymentOrderContext } = useContext(PaymentOrderContext);
  const [createPaymentOrder, setCreatePaymentOrder] = createPaymentOrderContext;
  const { isOpenModal } = createPaymentOrder;

  return (
    <StyledModal
      visible={isOpenModal}
      onCancel={() => {
        setCreatePaymentOrder(
          (prevState): CreatePaymentOrderType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalCreatePaymentOrder />
    </StyledModal>
  );
};

const validateSchema = yup.object().shape({
  paymentType: yup.string().required(),
  systemRequest: yup.string().required(),
  paymentInvoiceDate: yup.string().required(),
  invoiceDueDate: yup.string().required(),
  serviceDate: yup.string().required(),
  department: yup.string().when('paymentType', {
    is: (value) => value !== 'COGs',
    then: yup.string().required('กรุณากรอกข้อมูล'),
    otherwise: yup.string().nullable(),
  }),
  employeeId: yup.string().required(),
  payeeId: yup.string().required(),
  roundPaidDate: yup.string().required(),
  numberRef: yup.string(),
  paymentList: yup
    .array()
    .of(
      yup.object().shape({
        dataMasterId: yup.string().required(),
        listNumber: yup.string().required(),
        listName: yup.string().required(),
        qty: yup.number().positive().required(),
        price: yup.number().positive().required(),
        discount: yup
          .number()
          .min(0)
          .test(
            'moreNumber',
            'discount can not more number total price after discount',
            function (discount) {
              const qtyNumber = Number(this.parent.qty) || 0;
              const priceNumber = Number(this.parent.price) || 0;
              const discountNumber = Number(discount) || 0;
              const totalPrice = qtyNumber * priceNumber;

              if (discountNumber) {
                return discountNumber <= totalPrice;
              }

              return true;
            },
          ),
        vatCondition: yup.string().required(),
        wht: yup.number().required(),
      }),
    )
    .required(),
  paymentRemark: yup.string(),
  vendorInvoice: yup.string(),
  vendorId: yup.string().nullable(),
  fixedAssetCheck: yup.string().when('paymentType', {
    is: (value) => value === 'OPEX',
    then: yup.string().required('กรุณากรอกข้อมูล'),
    otherwise: yup.string().nullable(),
  }),
});

const initialValue = {
  employeeId: '',
  department: '',
  payeeId: '',
  roundPaidDate: '',
  numberRef: '',
  paymentType: '',
  systemRequest: '',
  paymentInvoiceDate: '',
  invoiceDueDate: '',
  serviceDate: moment().format('YYYY/MM/DD'),
  paymentList: [],
  paymentRemark: '',
  payeeName: '',
  grandTotalPrice: 0,
  otherDocument: [],
  vendorInvoice: '',
  vendorId: '',
  fixedAssetCheck: '',
};

const ModalCreatePaymentOrder: React.FC = () => {
  const { createPaymentOrder: createPaymentOrderContext } = useContext(PaymentOrderContext);
  const [createPaymentOrder] = createPaymentOrderContext;
  const { userDetail } = useContext(AppContext);
  const { isLoading } = createPaymentOrder;

  const { data: employeeData } = useQuery<EmployeeData, EmployeeVars>(EMPLOYEE_QUERY, {
    variables: {
      _id: userDetail.employeeId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const methods = useForm<FormValues>({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(validateSchema),
    defaultValues: initialValue,
  });

  const { reset, register } = methods;

  useEffect(() => {
    register('payeeName');
    register('grandTotalPrice');
  }, [register]);

  useEffect(() => {
    setTimeout(() => {
      reset({
        ...initialValue,
        employeeId: userDetail.employeeId,
      });
    }, 0);
  }, [userDetail.employeeId, reset, employeeData?.employee.department]);

  return (
    <Spin spinning={isLoading}>
      <FormProvider {...methods}>
        <form>
          <Header />
          <CreatePaymentOrder />
        </form>
      </FormProvider>
    </Spin>
  );
};

export default ModalCreatePaymentOrderWrapper;
