import gql from 'graphql-tag';
import { PaymentStatus } from 'views/AccountPayment/common/model/paymentStatus.model';

export interface UpdatePaymentData {
  updatePayment: {
    _id: string;
  };
}

export interface UpdatePaymentVars {
  _id: string;
  payee?: string;
  vendor?: string | null;
  payment_type?: string;
  payment_remark?: string;
  payment_status?: PaymentStatus;
  reject_remark?: string;
  cancel_remark?: string;
  save_status?: number;
  round_paid?: string;
  invoice_date?: string;
  invoice_due_date?: string;
  vendor_invoice_id?: string;
  number_ref?: string;
  payment_list?: {
    datamaster_service_item_id: string;
    advance_type: boolean;
    list_number: string;
    list_name: string;
    qty: number;
    unit: string;
    price: number;
    discount: number;
    vat: number;
    wht: number;
  }[];
  department?: string | null;
  service_date?: string;
  fixed_asset_check?: number;
}

export default gql`
  mutation UpdatePayment(
    $_id: ID!
    $payee: ID
    $vendor: ID
    $payment_type: String
    $payment_remark: String
    $payment_status: Int
    $reject_remark: String
    $cancel_remark: String
    $save_status: Int
    $round_paid: String
    $invoice_date: String
    $invoice_due_date: String
    $vendor_invoice_id: String
    $number_ref: String
    $payment_list: [inputPaymentList]
    $department: String
    $service_date: String
    $fixed_asset_check: Int
  ) {
    updatePayment(
      _id: $_id
      updatePayment: {
        payee: $payee
        vendor: $vendor
        payment_type: $payment_type
        payment_remark: $payment_remark
        payment_status: $payment_status
        reject_remark: $reject_remark
        cancel_remark: $cancel_remark
        save_status: $save_status
        round_paid: $round_paid
        invoice_date: $invoice_date
        invoice_due_date: $invoice_due_date
        vendor_invoice_id: $vendor_invoice_id
        number_ref: $number_ref
        payment_list: $payment_list
        department: $department
        service_date: $service_date
        fixed_asset_check: $fixed_asset_check
      }
    ) {
      _id
    }
  }
`;
