import React, { useState } from 'react';
import { FilePdfFilled, FileFilled } from '@ant-design/icons';
import { Avatar } from 'antd';
import mimeTypes from 'mime-types';
import Lightbox from 'react-image-lightbox';
import { v4 as uuidv4 } from 'uuid';
import { Container, FileDetail, IconContainer, ImagePreview } from './Styles';

type FileListProps = {
  files: string[];
};

const FileList: React.FC<FileListProps> = (props) => {
  const { files } = props;
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>('');

  const getFileType = (path: string) => {
    const mime = mimeTypes.lookup(path);
    if (typeof mime === 'string') {
      return {
        ext: mimeTypes.extension(mime),
        mime,
      };
    }

    return {
      ext: undefined,
      mime: '',
    };
  };

  const getIconPreview = (path: string) => {
    const { ext, mime } = getFileType(path);
    if (mime.indexOf('image') > -1) {
      return (
        <ImagePreview>
          <Avatar shape="square" size={38} src={path} />
        </ImagePreview>
      );
    }

    if (ext === 'pdf') {
      return <FilePdfFilled />;
    }

    return <FileFilled />;
  };

  const getFileNameFormPath = (filePath: string) => {
    return filePath.replace(/^.*[\\\\/]/, '');
  };

  const getEventClicked = (path: string) => {
    const { mime } = getFileType(path);
    if (mime.indexOf('image') > -1) {
      return {
        onClick: () => {
          setSelectedImage(path);
          setOpenModal(true);
        },
      };
    }

    return {
      href: path,
      target: '_blank',
    };
  };

  const mapFiles = files.map((file) => ({
    path: file,
    icon: getIconPreview(file),
  }));

  return (
    <>
      {mapFiles.map((file) => (
        <Container key={uuidv4()} {...getEventClicked(file.path)}>
          <IconContainer>{file.icon}</IconContainer>
          <FileDetail>{getFileNameFormPath(file.path)}</FileDetail>
        </Container>
      ))}

      {isOpenModal && (
        <Lightbox
          imageTitle={getFileNameFormPath(selectedImage)}
          imageCaption={selectedImage}
          mainSrc={selectedImage}
          onCloseRequest={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default FileList;
