/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Container } from './Styles';
import ChartOfAccountsTable from './ChartOfAccountsTable';
import ChartOfAccountsTab from './ChartOfAccountsTab';

const ChartOfAccountsList: React.FC = () => {
  const [tab, setTab] = useState<number>(1);
  const [tabName, setTabName] = useState<'category'>('category');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getMode = (value: number): string => {
    switch (value) {
      case 1:
        return '60d99b3cc7c47b969218180b';
      case 2:
        return '60d99bb731c8b696af010329';
      case 3:
        return '60d99bdc4e963196c1cb8b2b';
      case 4:
        return '60d99c0b90b04a96e7b25ffb';
      case 5:
        return '60d99d4ddce21a972b14ab7c';
      default:
        return '60d99b3cc7c47b969218180b';
    }
  };

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={18}>
          <Row>
            <Col>
              <ChartOfAccountsTab
                tab={tab}
                name={tabName}
                onChange={(value) => {
                  setTab(value);
                  setTabName('category');
                  setCurrentPage(1);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {tabName === 'category' && (
        <ChartOfAccountsTable
          mode={getMode(tab)}
          pageSize={pageSize}
          onChangePageSize={(value) => setPageSize(value)}
          currentPage={currentPage}
          onChangeCurrentPage={(value) => setCurrentPage(value)}
        />
      )}
    </Container>
  );
};

export default ChartOfAccountsList;
