/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useRef, useState } from 'react';
import moment from 'moment';
import { SaveOutlined } from '@ant-design/icons';
import { Col, Row, message, Input, DatePicker, Form } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import {
  PaymentCodContext,
  SelectedPaymentCodDetailType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/PaymentCodContext';
import SINGLE_UPLOAD_MUTATION, {
  SingleUploadData,
  SingleUploadVars,
} from 'shared/graphql/mutation/singleUpload';
import { useFormContext, Controller } from 'react-hook-form';
import formatCurrency from 'shared/utils/formatCurrency';
import { Text } from 'shared/components';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { FormValues } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/Routes/PaymentCod/PaymentCodListTable/ModalPaymentCodDetail/model/formValues.model';
import { StyledModal, StyledButton } from './Styles';
import DocumentList from './DocumentList';
import ModalConfirmUpdate from './ModalConfirmUpdate';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);
const dateFormat = 'YYYY/MM/DD';
const { TextArea } = Input;

type ModalPayCODProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalPayCOD: React.FC<ModalPayCODProps> = (props) => {
  const { onCancel, visible } = props;
  const [isOpenModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const { selectedPaymentCodDetail, setSelectedPaymentCodDetail } = useContext(PaymentCodContext);
  const { codOutNumber, totalCod } = selectedPaymentCodDetail;
  const filePondRef = useRef<any>();
  const { handleSubmit, setValue, control, errors, trigger, watch } = useFormContext<FormValues>();
  const watchProofSlip = watch('proofSlip');

  const [singleUpload] = useMutation<SingleUploadData, SingleUploadVars>(SINGLE_UPLOAD_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.singleUpload.path) {
        setValue('proofSlip', data.singleUpload.path);
        trigger(['proofSlip']);
        message.success('อัพโหลดเอกสารแล้ว');
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
  });

  const deletedFileUpload = (_path: string) => {
    setValue('proofSlip', '');
    trigger(['proofSlip']);
  };

  const onSubmit = handleSubmit(() => {
    setOpenModalConfirm(true);
  });

  return (
    <StyledModal
      closable={false}
      title={
        <Row justify="space-between" align="middle">
          <Col>บันทึกการจ่ายรายการ COD</Col>
          <Col>
            <StyledButton icon={<SaveOutlined />} color="green" onClick={() => onSubmit()}>
              บันทึกการจ่าย
            </StyledButton>
            <StyledButton onClick={onCancel}>ปิด</StyledButton>
          </Col>
        </Row>
      }
      className="custom-modal"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Row>
        <Col span={6}>รหัส COD:</Col>
        <Col span={18}>
          <Text strong>{codOutNumber}</Text>
        </Col>
      </Row>

      <Row>
        <Col span={6}>มูลค่า COD รวม (บาท):</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(totalCod, '0')}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          วันที่จ่าย:<Text customType="danger">*</Text>
        </Col>
        <Col span={18}>
          <Form.Item validateStatus={errors.paidDate && 'error'} style={{ marginBottom: 10 }}>
            <Controller
              control={control}
              name="paidDate"
              render={({ onChange, value }) => (
                <DatePicker
                  style={{ width: '100%', maxWidth: 200 }}
                  allowClear={false}
                  value={value ? moment(value, dateFormat) : null}
                  placeholder="วันที่จ่าย"
                  onChange={(valueDate) => {
                    if (!valueDate) {
                      onChange('');
                    } else {
                      onChange(valueDate.format(dateFormat));
                    }
                  }}
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          Bank Ref:<Text customType="danger">*</Text>
        </Col>
        <Col span={18}>
          <Form.Item validateStatus={errors.bankRef && 'error'} style={{ marginBottom: 10 }}>
            <Controller
              style={{ width: '100%', maxWidth: 200 }}
              as={Input}
              name="bankRef"
              control={control}
              placeholder="ระบุ"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div>
            แนบไฟล์หลักฐานการจ่าย:<Text customType="danger">*</Text>{' '}
            {errors.proofSlip && (
              <Text customType="danger">(กรุณาอัพโหลดเอกสารหลักฐานการจ่าย)</Text>
            )}
            <div>
              <Text customType="grey">
                (รูปแบบไฟล์ที่รองรับ .jpg , .jpeg , .png , .pdf , และขนาดไฟล์ไม่เกิน 2Mb.)
              </Text>
            </div>
          </div>

          {!watchProofSlip && (
            <FilePond
              ref={filePondRef}
              server={{
                process: (_fieldName, file, _metadata, _load, error) => {
                  singleUpload({
                    variables: {
                      file: file as File,
                    },
                  }).catch((err) => {
                    message.error(err.message);
                    error(err);
                  });
                },
              }}
              acceptedFileTypes={['image/jpeg', 'image/png', 'application/pdf']}
              maxFileSize="2MB"
              maxFiles={1}
            />
          )}

          {watchProofSlip && (
            <DocumentList
              filesPath={[watchProofSlip]}
              onDelete={(path) => deletedFileUpload(path)}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          หมายเหตุ:
          <Form.Item validateStatus={errors.slipRemark && 'error'}>
            <Controller
              style={{ width: '100%' }}
              as={TextArea}
              name="slipRemark"
              control={control}
              placeholder="ระบุ"
              rows={4}
            />
          </Form.Item>
        </Col>
      </Row>

      <ModalConfirmUpdate
        visible={isOpenModalConfirm}
        onCompleted={() => {
          setOpenModalConfirm(false);
          onCancel();
          setSelectedPaymentCodDetail(
            (prevState): SelectedPaymentCodDetailType => ({
              ...prevState,
              isOpenModal: false,
            }),
          );
        }}
        onCancel={() => {
          setOpenModalConfirm(false);
        }}
      />
    </StyledModal>
  );
};

export default ModalPayCOD;
