import React from 'react';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import ResultStyle from './Styles';

const PageNotFound = (props) => {
  return (
    <ResultStyle
      status="404"
      title="404"
      subTitle="ไม่พบหน้าที่ต้องการ"
      extra={
        <Button type="primary" onClick={() => props.history.push('/')}>
          กลับสู่หน้าหลัก
        </Button>
      }
    />
  );
};

export default withRouter(PageNotFound);
