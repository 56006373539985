import gql from 'graphql-tag';

export interface CancelParcelDetailData {
  cancelParcelDetail: {
    _id: string;
  };
}

export interface CancelParcelDetailVars {
  _id: string;
  cancelRemark: string;
}

export default gql`
  mutation CancelParcelDetail($_id: ID!, $cancelRemark: String!) {
    cancelParcelDetail(_id: $_id, cancelRemark: $cancelRemark) {
      _id
    }
  }
`;
