/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { Col, Row, message } from 'antd';
import moment from 'moment';
import { Button, Text } from 'shared/components';
import { formatDate } from 'shared/utils/dateTime';
import { useMutation } from '@apollo/react-hooks';
import { useFormContext } from 'react-hook-form';
import { AppContext } from 'shared/context/AppContext';
import { DebtTrackerContext } from 'views/DebtTracker/common/context/DebtTrackerContext';
import CREATE_FOLLOW_DEBT_HISTORY_MUTATION, {
  CreateFollowDebtHistoryData,
  CreateFollowDebtHistoryVars,
} from 'shared/graphql/mutation/createFollowDebtHistory';
import UPDATE_FOLLOW_DEBT_HISTORY_MUTATION, {
  UpdateFollowDebtHistoryData,
  UpdateFollowDebtHistoryVars,
} from 'shared/graphql/mutation/updateFollowDebtHistory';
import { FormValues } from 'views/DebtTracker/Routes/DebtTrackerDetail/ModalDebtTrackerHistory/DebtTrackerHistory/DebtTrackerHistoryForm/formValues.model';
import { StyledModal } from './Styles';

type Params = {
  id: string;
  invoiceId: string;
};

type ModalConfirmCreateProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmCreate: React.FC<ModalConfirmCreateProps> = (props) => {
  const { onCancel, visible } = props;

  const { id, invoiceId } = useParams<Params>();

  const { userDetail } = useContext(AppContext);
  const { debtTrackerHistoryValue } = useContext(DebtTrackerContext);

  const { handleSubmit, watch, reset } = useFormContext<FormValues>();

  const [createFollowDebtHistory, { loading: loadingCreate }] = useMutation<
    CreateFollowDebtHistoryData,
    CreateFollowDebtHistoryVars
  >(CREATE_FOLLOW_DEBT_HISTORY_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.createFollowDebtHistory._id) {
        message.success('บันทึกเรียบร้อยแล้ว');
        onCancel();
        reset({
          logARId: id,
          invoiceId,
          followDebtHistoryId: debtTrackerHistoryValue.followDebtHistoryId,
          employeeId: userDetail.employeeId,
          nextFollowDate: moment().format('YYYY/MM/DD'),
          followStatus: '',
          followStatusDetail: '',
          action: '',
          actionDetail: '',
          detail: '',
        });
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['FollowDebtHistory', 'Invoice'],
  });

  const [updateFollowDebtHistory, { loading: loadingUpdate }] = useMutation<
    UpdateFollowDebtHistoryData,
    UpdateFollowDebtHistoryVars
  >(UPDATE_FOLLOW_DEBT_HISTORY_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.updateFollowDebtHistory._id) {
        message.success('บันทึกเรียบร้อยแล้ว');
        onCancel();
        reset({
          logARId: id,
          invoiceId,
          followDebtHistoryId: debtTrackerHistoryValue.followDebtHistoryId,
          employeeId: userDetail.employeeId,
          nextFollowDate: moment().format('YYYY/MM/DD'),
          followStatus: '',
          followStatusDetail: '',
          action: '',
          actionDetail: '',
          detail: '',
        });
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['FollowDebtHistory', 'Invoice'],
  });

  const submit = handleSubmit((values) => {
    if (debtTrackerHistoryValue.followDetailAmount === 0) {
      createFollowDebtHistory({
        variables: {
          logAR: values.logARId,
          invoice: values.invoiceId,
          followStatus: values.followStatus,
          followStatusDetail: values.followStatusDetail,
          action: values.action,
          actionDetail: values.actionDetail,
          nextfollowDate: values.nextFollowDate,
          detail: values.detail,
          employee: values.employeeId,
          status: 1,
        },
      });
    } else {
      updateFollowDebtHistory({
        variables: {
          _id: values.followDebtHistoryId,
          followStatus: values.followStatus,
          followStatusDetail: values.followStatusDetail,
          action: values.action,
          actionDetail: values.actionDetail,
          nextfollowDate: values.nextFollowDate,
          detail: values.detail,
          employee: values.employeeId,
          status: 1,
        },
      });
    }
  });

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันการบันทึกใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => submit()}
          loading={loadingCreate || loadingUpdate}
          disabled={loadingCreate || loadingUpdate}
        >
          ใช่,บันทึกการตามหนี้
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loadingCreate || loadingUpdate}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Text strong>
            บันทึกการติดตามหนี้ ครั้งที่&nbsp;
            {debtTrackerHistoryValue.followDetailAmount
              ? debtTrackerHistoryValue.followDetailAmount + 1
              : 1}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={5}>
          <Text strong>สถานะการติดต่อ:</Text>
        </Col>
        <Col span={19}>
          <Text>{watch('followStatusDetail') || '-'}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={5}>
          <Text strong>นัดหมายครั้งต่อไป:</Text>
        </Col>
        <Col span={19}>
          <Text>{watch('nextFollowDate') ? formatDate(watch('nextFollowDate')) : '-'}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={5}>
          <Text strong>รายละเอียดเพิ่มเติม:</Text>
        </Col>
        <Col span={19}>
          <Text>{watch('detail') || '-'}</Text>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmCreate;
