/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message, Divider } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import CREATE_CONFIRM_RECEIPT_MUTATION, {
  CreateConfirmReceiptData,
  CreateConfirmReceiptVars,
} from 'shared/graphql/mutation/createConfirmReceipt';
import {
  ReceiptContext,
  SelectedBillingNoteDetailType,
} from 'views/AccountReceive/common/context/ReceiptContext';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { formatDate } from 'shared/utils/dateTime';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountReceive/Routes/Receipt/BillingNotesTable/ModalCreateReceipt/model/formValues.model';
import useSumTotalPrice from 'views/AccountReceive/Routes/Receipt/BillingNotesTable/ModalCreateReceipt/hooks/useSumTotalPrice';
import { StyledModal } from './Styles';

type ModalConfirmCreateInvoiceProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmCreateReceipt: React.FC<ModalConfirmCreateInvoiceProps> = (props) => {
  const { onCancel, visible } = props;
  const { selectedBillingNoteDetail, setSelectedBillingNoteDetail } = useContext(ReceiptContext);
  const { billNumber, payerFullName, grandTotalPrice } = selectedBillingNoteDetail;
  const { totalPriceRight } = useSumTotalPrice();
  const { getValues } = useFormContext<FormValues>();
  const allFieldValue = getValues();

  const [createConfirmReceipt, { loading }] = useMutation<
    CreateConfirmReceiptData,
    CreateConfirmReceiptVars
  >(CREATE_CONFIRM_RECEIPT_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.createConfirmReceipt._id) {
        message.success('บันทึกการรับชำระแล้ว');
        onCancel();
        setSelectedBillingNoteDetail(
          (prevState): SelectedBillingNoteDetailType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['BillingNote', 'BillingNoteStatus', 'BillingNotes', 'ReceiptStatus'],
  });

  const confirmCreateReceipt = () => {
    const {
      billInvoices,
      channel,
      receiptDate,
      bankName,
      accountNumber,
      receiptDiscount,
      bankFee,
      otherExpenses,
      otherIncome,
      interestIncome,
      proofOfPayment,
      receiptPrice,
      allWht,
      receiptValue,
    } = allFieldValue;

    const filterAllWht = !allWht
      ? undefined
      : allWht
          .map((item) => ({
            wht: item.wht ? +item.wht : 0,
            total_wht: item.totalWht ? +item.totalWht : 0,
          }))
          .filter((item) => item.wht > 0);

    createConfirmReceipt({
      variables: {
        invoice: billInvoices.map((invoice) => invoice.invoice._id),
        channel,
        receipt_date: receiptDate,
        bank_name: bankName,
        account_number: accountNumber,
        receipt_price: receiptPrice ? +receiptPrice : 0,
        receipt_discount: receiptDiscount ? +receiptDiscount : undefined,
        bank_fee: bankFee ? +bankFee : undefined,
        other_expenses: otherExpenses ? +otherExpenses : undefined,
        all_wht: filterAllWht,
        other_income: otherIncome ? +otherIncome : undefined,
        interest_income: interestIncome ? +interestIncome : undefined,
        proof_of_payment: proofOfPayment,
        receipt_value: receiptValue ? +receiptValue : 0,
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันบันทึกการรับชำระใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmCreateReceipt()}
          loading={loading}
        >
          ใช่, ยืนยันรับชำระ
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>
          ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>:
        </Col>
        <Col span={18}>
          <Text strong>{payerFullName}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>รหัสใบวางบิล:</Col>
        <Col span={18}>
          <Text strong>{billNumber}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>มูลค่าใบวางบิล:</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(grandTotalPrice, '0')}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>จำนวนรับชำระ:</Col>
        <Col span={18}>
          <Text strong>{formatCurrency(totalPriceRight, '0')}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>คงเหลือที่ต้องรับชำระ:</Col>
        <Col span={18}>
          <Text strong customType="danger">
            {formatCurrency(allFieldValue.unPaid)}
          </Text>
        </Col>
      </Row>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Row>
        <Col span={24}>
          ทั้งหมด <Text strong>{`${allFieldValue.billInvoices.length} รายการ`}</Text>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 0]}>
            <Col span={4}>วันที่เอกสาร</Col>
            <Col span={5}>รหัสใบแจ้งหนี้</Col>
            <Col span={5}>มูลค่าใบแจ้งหนี้</Col>
            <Col span={5}>จำนวนรับชำระ:</Col>
            <Col span={5}>วันที่ครบกำหนดชำระ</Col>
          </Row>

          {allFieldValue.billInvoices.map((item) => {
            const { service_date, invoice_number, grand_total_price, due_date } = item.invoice;
            return (
              <Row gutter={[8, 0]}>
                <Col span={4}>
                  <Text strong>{service_date ? formatDate(service_date) : '-'}</Text>
                </Col>
                <Col span={5}>
                  <Text strong>{invoice_number || '-'}</Text>
                </Col>
                <Col span={5}>
                  <Text strong customType="primary">
                    {formatCurrency(grand_total_price)}
                  </Text>
                </Col>
                <Col span={5}>
                  <Text strong>{formatCurrency(grand_total_price)}</Text>
                </Col>
                <Col span={5}>
                  <Text strong>{formatDate(due_date)}</Text>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmCreateReceipt;
