/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react';
import { Row, Col, Typography, Select, DatePicker, Input, Tooltip, Button } from 'antd';
import Icon, { QuestionCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { InputNumberFormat } from 'shared/components';
import { useFormikContext, FastField } from 'formik';
import initialWhtList from 'views/AccountPayment/Routes/PaymentVoucher/PaymentVoucherDetail/initialWhtList';
import { PaymentVoucherContext } from 'views/AccountPayment/common/context/PaymentVoucherContext';
import PAYMENT_WHT_QUERY from 'shared/graphql/query/paymentWHT';
import formatCurrency from 'shared/utils/formatCurrency';
import { StyledTable, StyledFormItem } from './Styles';
import TableDetail from './TableDetail';

const { Text } = Typography;
const { Option } = Select;

const columnsForm = [
  {
    title: 'ประเภทเงินได้พึงประเมินจ่าย',
    dataIndex: 'column1',
  },
  {
    title: 'วัน เดือน ปี ที่จ่ายเงิน',
    dataIndex: 'column2',
    width: 180,
  },
  {
    title: 'จำนวนเงินที่จ่าย',
    dataIndex: 'column3',
    width: 180,
  },
  {
    title: 'ภาษีที่หักและนำส่งไว้',
    dataIndex: 'column4',
    width: 180,
  },
];

const PaymentWHT = () => {
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    touched,
    setValues,
  } = useFormikContext();

  const { selectedPaymentVoucherDetail: selectedPaymentVoucherDetailContext } = useContext(
    PaymentVoucherContext,
  );
  const [selectedPaymentVoucherDetail] = selectedPaymentVoucherDetailContext;
  const { paymentVoucherId, voucherStatus, allWht } = selectedPaymentVoucherDetail;

  const { data: paymentWhtData } = useQuery(PAYMENT_WHT_QUERY, {
    variables: {
      voucher_id: paymentVoucherId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const setDefaultPaymentWHT = () => {
    if (values.paymentWhtId || values.docType) {
      return;
    }

    const whtListData = [...values.whtList];
    const filterWht1 = allWht.filter((item) => item.wht === 1);
    if (filterWht1.length > 0) {
      const reg = /^[5]\./g;
      const searchIndex = whtListData.findIndex((item) => reg.test(item.description));
      const editWhtList = {
        ...whtListData[searchIndex],
        amount: filterWht1[0].price.toFixed(2),
        tax: filterWht1[0].totalWht.toFixed(2),
        input1: 'ค่าขนส่ง',
      };

      if (editWhtList.tax > 0) {
        whtListData[searchIndex] = editWhtList;
        setFieldValue('whtList', [...whtListData]);
      }
    }

    const filterWhtNot1 = allWht.filter((item) => item.wht !== 1);
    if (filterWhtNot1.length > 0) {
      const reg = /^[6]\./g;
      const searchIndex = whtListData.findIndex((item) => reg.test(item.description));
      const editWhtList = {
        ...whtListData[searchIndex],
        amount: filterWhtNot1
          .map((item) => item.price)
          .reduce((total, item) => total + item)
          .toFixed(2),
        tax: filterWhtNot1
          .map((item) => item.totalWht)
          .reduce((total, item) => total + item)
          .toFixed(2),
        input1: 'ค่าบริการ',
      };

      if (editWhtList.tax > 0) {
        whtListData[searchIndex] = editWhtList;
        setFieldValue('whtList', [...whtListData]);
      }
    }
  };

  useEffect(() => {
    setDefaultPaymentWHT();
  }, []);

  const getParagraph = (text) => {
    const tab1 = /^\(([\D]+)\)/g.test(text); // (ข)
    const tab2 = /^\(([\d]+)\)/g.test(text); // (1)
    const tab3 = /^\([\d]+\.[\d]+\)/g.test(text); // (1.1)

    let setTab = null;
    if (tab1) {
      setTab = 'tab-1';
    } else if (tab2) {
      setTab = 'tab-2';
    } else if (tab3) {
      setTab = 'tab-3';
    }

    return <span className={setTab}>{text}</span>;
  };

  const isDisabledForm = () => {
    return voucherStatus === 5 || voucherStatus === 6;
  };

  const getInput1 = (description, index) => {
    // 1.4, 2.5, 5., 6.
    const isHasInput = /^(\(1\.4\)|\(2\.5\)|5\.|6\.)/gi.test(description);

    return isHasInput ? (
      <>
        {!values.docType && (
          <Input
            disabled
            style={{ marginLeft: 10, marginRight: 10, width: 200 }}
            placeholder="ระบุ"
          />
        )}
        {values.docType && (
          <FastField name={`whtList.${index}.input1`}>
            {({ field, form, meta }) => {
              return (
                <StyledFormItem validateStatus={meta.error && meta.touched && 'error'}>
                  <Input
                    value={field.value}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    name={field.name}
                    placeholder="ระบุ"
                    style={{ marginLeft: 10, marginRight: 10, width: 200 }}
                    disabled={!values.docType || isDisabledForm()}
                  />
                </StyledFormItem>
              );
            }}
          </FastField>
        )}
      </>
    ) : null;
  };

  const getInput2 = (text) => {
    return text || null;
  };

  const changeNumberFormat = (value) => {
    return value >= 0 ? value.toFixed(2) : '';
  };

  const dataTableFormWhtList = values.whtList.map((list, index) => {
    return {
      key: index,
      column1: (
        <Row align="middle">
          <Col>{getParagraph(list.description)}</Col>
          <Col>{getInput1(list.description, index)}</Col>
          <Col>{getInput2(list.input2)}</Col>
        </Row>
      ),
      column2: (
        <>
          {!values.docType && <DatePicker placeholder="" style={{ width: '100%' }} disabled />}
          {values.docType && (
            <FastField name={`whtList.${index}.date`}>
              {({ field, form, meta }) => {
                return (
                  <StyledFormItem validateStatus={meta.error && meta.touched && 'error'}>
                    <DatePicker
                      format="YYYY/MM/DD"
                      value={field.value && moment(field.value, 'YYYY/MM/DD')}
                      placeholder=""
                      style={{ width: '100%' }}
                      onChange={(date) => {
                        const selectedDate = !date ? '' : date.format('YYYY/MM/DD');
                        form.setFieldValue(field.name, selectedDate);
                      }}
                      onBlur={() => setFieldTouched(field.name, true)}
                      name={field.name}
                      disabled={isDisabledForm()}
                    />
                  </StyledFormItem>
                );
              }}
            </FastField>
          )}
        </>
      ),
      column3: (
        <>
          {!values.docType && <InputNumberFormat style={{ width: '100%' }} disabled />}
          {values.docType && (
            <FastField name={`whtList.${index}.amount`}>
              {({ field, form, meta }) => {
                return (
                  <StyledFormItem validateStatus={meta.error && meta.touched && 'error'}>
                    <InputNumberFormat
                      value={field.value}
                      onValueChange={(value) =>
                        form.setFieldValue(field.name, changeNumberFormat(value.floatValue))
                      }
                      onBlur={() => form.setFieldTouched(field.name, true)}
                      name={field.name}
                      style={{ width: '100%' }}
                      disabled={isDisabledForm()}
                    />
                  </StyledFormItem>
                );
              }}
            </FastField>
          )}
        </>
      ),
      column4: (
        <>
          {!values.docType && <InputNumberFormat style={{ width: '100%' }} disabled />}
          {values.docType && (
            <FastField name={`whtList.${index}.tax`}>
              {({ field, form, meta }) => {
                return (
                  <StyledFormItem validateStatus={meta.error && meta.touched && 'error'}>
                    <InputNumberFormat
                      value={field.value}
                      onValueChange={(value) =>
                        form.setFieldValue(field.name, changeNumberFormat(value.floatValue))
                      }
                      onBlur={() => form.setFieldTouched(field.name, true)}
                      name={field.name}
                      style={{ width: '100%' }}
                      disabled={isDisabledForm()}
                    />
                  </StyledFormItem>
                );
              }}
            </FastField>
          )}
        </>
      ),
    };
  });

  const dataTableForm = [
    ...dataTableFormWhtList,
    {
      key: values.whtList.length,
      column1: (
        <Row justify="end">
          <Col>รวมเงินที่จ่ายและภาษีที่หักนำส่ง</Col>
        </Row>
      ),
      column2: '',
      column3: (
        <InputNumberFormat
          style={{ width: '100%' }}
          value={
            !values.docType
              ? formatCurrency(0)
              : values.whtList
                  .filter((wht) => wht.amount > 0)
                  .map((wht) => wht.amount)
                  .reduce((total, amount) => {
                    return total + +amount;
                  }, 0)
                  .toFixed(2)
          }
          disabled
        />
      ),
      column4: (
        <InputNumberFormat
          style={{ width: '100%' }}
          value={
            !values.docType
              ? formatCurrency(0)
              : values.whtList
                  .filter((wht) => wht.tax > 0)
                  .map((wht) => wht.tax)
                  .reduce((total, tax) => {
                    return total + +tax;
                  }, 0)
                  .toFixed(2)
          }
          disabled
        />
      ),
    },
  ];

  let buttonViewDocument = null;
  if (paymentWhtData && paymentWhtData.paymentWHT) {
    const { document } = paymentWhtData.paymentWHT;

    if (document) {
      buttonViewDocument = (
        <Row justify="end" style={{ marginTop: 20 }}>
          <Col>
            <Button icon={<DownloadOutlined />} href={document} target="_blank">
              เอกสารภาษีหัก ณ ที่จ่าย
            </Button>
          </Col>
        </Row>
      );
    }
  }

  return (
    <div>
      <Row justify="space-between" gutter={[16, 0]}>
        <Col span={12}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              แบบยื่น<Text type="danger">*</Text>
              <StyledFormItem validateStatus={errors.docType && touched.docType && 'error'}>
                <Select
                  value={values.docType}
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    if (!value && !values.paymentWhtId) {
                      const formValues = {
                        ...values,
                        docType: '',
                        whtType: '',
                        whtList: [...initialWhtList],
                      };

                      setValues(formValues);
                      return;
                    }

                    setFieldValue('docType', value);
                  }}
                  onBlur={() => setFieldTouched('docType', true)}
                  name="docType"
                  disabled={isDisabledForm()}
                >
                  <Option value="">เลือกแบบยื่น</Option>
                  <Option value="ภ.ง.ด.1ก พิเศษ">ภ.ง.ด.1ก พิเศษ</Option>
                  <Option value="ภ.ง.ด.2">ภ.ง.ด.2</Option>
                  <Option value="ภ.ง.ด.2ก">ภ.ง.ด.2ก</Option>
                  <Option value="ภ.ง.ด.3">ภ.ง.ด.3</Option>
                  <Option value="ภ.ง.ด.3ก">ภ.ง.ด.3ก</Option>
                  <Option value="ภ.ง.ด.53">ภ.ง.ด.53</Option>
                </Select>
              </StyledFormItem>
            </Col>
            <Col span={8}>
              ผู้จ่ายเงิน<Text type="danger">*</Text>
              <StyledFormItem validateStatus={errors.whtType && touched.whtType && 'error'}>
                <Select
                  value={values.whtType}
                  style={{ width: '100%' }}
                  onChange={(value) => setFieldValue('whtType', value)}
                  onBlur={() => setFieldTouched('whtType', true)}
                  name="whtType"
                  disabled={!values.docType || isDisabledForm()}
                >
                  <Option value="">เลือกผู้จ่ายเงิน</Option>
                  <Option value="หักภาษี ณ ที่จ่าย">หักภาษี ณ ที่จ่าย</Option>
                  <Option value="ภาษีออกให้ครั้งเดียว">ภาษีออกให้ครั้งเดียว</Option>
                  <Option value="ภาษีออกให้ตลอดไป">ภาษีออกให้ตลอดไป</Option>
                </Select>
              </StyledFormItem>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[16, 16]} justify="end">
            <Col span={8}>
              เล่มที่
              <Tooltip title="เลขออกจากระบบ">
                <Text type="grey" style={{ fontSize: 14, marginLeft: 6, verticalAlign: 2 }}>
                  <Icon component={QuestionCircleOutlined} />
                </Text>
              </Tooltip>
              <StyledFormItem validateStatus={errors.bookNumber && touched.bookNumber && 'error'}>
                <Input
                  style={{ width: '100%' }}
                  value={values.bookNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="bookNumber"
                  placeholder="-"
                  disabled
                />
              </StyledFormItem>
            </Col>
            <Col span={8}>
              เลขที่
              <Tooltip title="เลขออกจากระบบ">
                <Text type="grey" style={{ fontSize: 14, marginLeft: 6, verticalAlign: 2 }}>
                  <Icon component={QuestionCircleOutlined} />
                </Text>
              </Tooltip>
              <StyledFormItem
                validateStatus={errors.runningNumber && touched.runningNumber && 'error'}
              >
                <Input
                  style={{ width: '100%' }}
                  value={values.runningNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="runningNumber"
                  placeholder="-"
                  disabled
                />
              </StyledFormItem>
            </Col>
            <Col span={8}>
              ลำดับเลขที่
              <Tooltip title="เลขออกจากระบบ">
                <Text type="grey" style={{ fontSize: 14, marginLeft: 6, verticalAlign: 2 }}>
                  <Icon component={QuestionCircleOutlined} />
                </Text>
              </Tooltip>
              <StyledFormItem validateStatus={errors.whtOrder && touched.whtOrder && 'error'}>
                <Input
                  style={{ width: '100%' }}
                  value={values.whtOrder}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="whtOrder"
                  placeholder="-"
                  disabled
                />
              </StyledFormItem>
            </Col>
          </Row>
        </Col>
      </Row>

      <TableDetail />
      <StyledTable
        style={{ marginTop: 20 }}
        bordered
        dataSource={dataTableForm}
        columns={columnsForm}
        pagination={false}
      />

      {buttonViewDocument}
    </div>
  );
};

export default PaymentWHT;
