import styled from 'styled-components';
import { color } from 'shared/utils/styles';
import { Button } from 'antd';
import CardIcon from './CardIcon';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledCardIcon = styled(CardIcon)`
  &:not(:last-child) {
    border-right: 1px solid ${color.grey};
  }
`;

export const StyledButtonIcon = styled(Button)`
  background-color: transparent;
  border: none;
  color: #8c8c8c;

  & .anticon {
    font-size: 16px !important;
  }

  &:hover {
    background-color: ${color.backgroundPrimary};
    color: ${color.white};
  }
`;
