/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode } from 'react';
import { Select, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import CODS_QUERY, { CodsData, CodsVars } from 'shared/graphql/query/newCods';

const { Option } = Select;

type SelectCodFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const SelectCodField: React.FC<SelectCodFieldProps> = (props) => {
  const { value, onChange } = props;
  const [codInNumber, setCodInNumber] = useState<string>('');
  const { data, loading } = useQuery<CodsData, CodsVars>(CODS_QUERY, {
    variables: {
      cod_in_number: codInNumber,
      showData: 10,
    },
    fetchPolicy: 'cache-and-network',
  });

  let options: ReactNode[] = [];
  if (data && data.newCods) {
    const { cods } = data.newCods;
    options = cods.map((item) => (
      <Option key={item._id} value={item._id}>
        {item.cod_in_number}
      </Option>
    ));
  }

  return (
    <Select
      allowClear
      style={{
        width: '100%',
      }}
      value={value}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      showSearch
      placeholder="ค้นหา"
      onSearch={(valueSearch) => setCodInNumber(valueSearch)}
      onChange={(valueChange) => onChange && onChange(valueChange)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};

export default SelectCodField;
