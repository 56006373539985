/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, { ReactNode, useState } from 'react';
import { Button, Col, DatePicker, Input, message, Row, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { formatDate, formatDateTime } from 'shared/utils/dateTime';
import { TableSearch, TextExpand } from 'shared/components';
import formatCurrency from 'shared/utils/formatCurrency';
import PARCEL_COST_LIST_QUERY, {
  ParcelCostListData,
  ParcelCostListVar,
} from 'shared/graphql/query/parcelCostList';
import { ParcelCostingProvider } from 'views/Costing/common/context/ParcelCostingContext';
import moment from 'moment';
import { Link, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { ParcelCostingStatus } from 'views/Costing/common/model/parcelCostingStatus.model';
import { Container, StyledModal, ToolsContainer } from './Styles';
import ModalParcelCostingDetail from './ModalParcelCostingDetail';
import ParcelCostingTabs from './ParcelCostingTabs';
import SelectVendorField from './SelectVendorField';

const { Option } = Select;
const { RangePicker } = DatePicker;

const CostingParcelListWrapper: React.FC = () => {
  return (
    <ParcelCostingProvider>
      <CostingParcelList />
    </ParcelCostingProvider>
  );
};

const CostingParcelList: React.FC = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const [tab, setTab] = useState<ParcelCostingStatus>(ParcelCostingStatus.WAIT_AGENT_CONFIRM);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [vendorId, setVendorId] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [costingNumber, setCostingNumber] = useState('');
  const [refNumber, setRefNumber] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [orderType, setOrderType] = useState('');

  const { loading, data } = useQuery<ParcelCostListData, ParcelCostListVar>(
    PARCEL_COST_LIST_QUERY,
    {
      variables: {
        createdAt,
        vendor: vendorId,
        order_by: orderBy,
        page: currentPage,
        showData: pageSize,
        costing_status: tab,
        order_type: orderType,
        costing_number: costingNumber,
        refNumber: refNumber || undefined,
      },
      onError: (err) => {
        message.error(err.message);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'วันที่ส่งรายการ',
      dataIndex: 'createdAt',
      sortName: 'createdAt',
      width: 200,
      search: (
        <RangePicker
          onChange={(values) => {
            setCreatedAt(
              values === null
                ? ''
                : `${values?.[0]?.format('YYYY-MM-DD')}:${values?.[1]?.format('YYYY-MM-DD')}`,
            );
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
          ranges={{
            วันนี้: [moment(), moment()],
            สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
            เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
            ปีนี้: [moment().startOf('year'), moment().endOf('year')],
          }}
        />
      ),
    },
    {
      title: 'รหัสต้นทุน/ขาย',
      dataIndex: 'costing_number',
      sortName: 'costing_number',
      width: 180,
      search: (
        <Input
          allowClear
          value={costingNumber}
          placeholder="ค้นหา"
          onChange={(event) => setCostingNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'รหัสงานขนส่ง',
      dataIndex: 'refNumber',
      sortName: 'refNumber',
      width: 180,
      search: (
        <Input
          allowClear
          value={refNumber}
          placeholder="ค้นหา"
          onChange={(event) => setRefNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'ต้นทุนก่อนภาษี',
      dataIndex: 'total_after_discount',
      sortName: 'total_after_discount',
      width: 150,
      align: 'right',
    },
    {
      title: 'ผู้รับเงิน',
      dataIndex: 'vendorFullName',
      width: 300,
      search: <SelectVendorField onChange={(value) => setVendorId(value || '')} />,
    },
    {
      title: 'ผู้ส่งราคา',
      dataIndex: 'employeeFullName',
      width: 150,
    },
    {
      title: 'อ้างอิงรหัสใบสั่งจ่าย',
      dataIndex: 'paymentNumber',
      sortName: 'payment.payment_number',
      width: 170,
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'updatedAt',
      sortName: 'updatedAt',
      width: 150,
    },
  ];

  let tableData: Array<{
    key: number;
    tools: ReactNode;
    createdAt: string;
    costing_number: ReactNode;
    refNumber: string;
    total_after_discount: ReactNode;
    vendorFullName: string;
    employeeFullName: string;
    paymentNumber: ReactNode;
    updatedAt: string;
  }> = [];

  let totalDocument = 0;
  if (data && data.parcelCostList) {
    const { parcelsCost } = data.parcelCostList;
    tableData = parcelsCost.map((item, index) => ({
      key: index,
      tools: (
        <ToolsContainer>
          <Link to={`${match.path}/detail/${item._id}`}>
            <Button
              // @ts-expect-error
              type="grey--text-only"
              icon={<SearchOutlined />}
              size="small"
              style={{ marginRight: 10 }}
            />
          </Link>
        </ToolsContainer>
      ),
      createdAt: formatDate(item.createdAt),
      costing_number: item.costing_number,
      refNumber: item.refNumber,
      total_after_discount: formatCurrency(item.total_price || 0),
      vendorFullName: item.vendor.full_name,
      employeeFullName: item.employee?.full_name || '-',
      paymentNumber:
        (item.payment || []).length === 0 ? (
          '-'
        ) : (
          <TextExpand expand={!loading}>
            {item.payment.map((payment) => payment.payment_number).join(', ')}
          </TextExpand>
        ),
      updatedAt: formatDateTime(item.updatedAt),
    }));

    totalDocument = data.parcelCostList.totalDocument;
  }

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={16}>
          <ParcelCostingTabs
            tab={tab}
            name="costing"
            onChange={(value) => {
              setTab(value);
              setCurrentPage(1);
            }}
          />
        </Col>
      </Row>

      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              setCurrentPage(1);
              setPageSize(value);
            }}
            defaultValue={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={tableData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value) => setCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />

      <Route
        // @ts-expect-error
        except
        path={`${match.path}/detail/:costingId`}
        render={(routeProps) => (
          <StyledModal
            visible
            onCancel={() => {
              history.push(match.url);
            }}
            closable={false}
            className="custom-modal"
            footer={null}
            destroyOnClose
          >
            <ModalParcelCostingDetail
              costingId={routeProps.match.params.costingId}
              backUrl={match.url}
            />
          </StyledModal>
        )}
      />
    </Container>
  );
};

export default CostingParcelListWrapper;
