import gql from 'graphql-tag';
import { ReportStatus } from 'views/Reports/common/model/reportStatus.model';

export interface Report {
  _id: string;
  report_type: string;
  name: string;
  date_range: string;
  file: string;
  employee: {
    full_name: string;
  } | null;
  create_date: string;
  status: ReportStatus;
}

export interface ReportsData {
  reports: {
    reports: Report[];
    totalDocument: number;
  };
}

export interface ReportsVars {
  name?: string;
  page?: number;
  showData?: number;
  order_by?: string;
  order_type?: string;
  report_type?: string;
}

export default gql`
  query Reports(
    $name: String
    $report_type: String
    $page: Int
    $showData: Int
    $order_by: String
    $order_type: String
  ) {
    reports(
      params: { name: $name, report_type: $report_type }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      reports {
        _id
        report_type
        name
        date_range
        file
        employee {
          full_name
        }
        create_date
        status
      }
      totalDocument
    }
  }
`;
