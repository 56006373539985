import gql from 'graphql-tag';

export interface PayCODData {
  payCOD: {
    _id: string;
  };
}

export interface PayCODVars {
  _id: string;
  payment_method?: string;
  bank_account?: {
    bank_name: string;
    bank_branch?: string;
    account_name: string;
    account_number: string;
  };
  proof_slip: string;
  paid_date: string;
  bank_ref: string;
  slip_remark?: string;
}

export default gql`
  mutation PayCOD(
    $_id: ID!
    $payment_method: String
    $proof_slip: String!
    $paid_date: String!
    $bank_ref: String!
    $slip_remark: String
    $bank_account: inputBankAccount!
  ) {
    payCOD(
      _id: $_id
      paymentCod: {
        payment_method: $payment_method
        bank_account: $bank_account
        proof_slip: $proof_slip
        paid_date: $paid_date
        bank_ref: $bank_ref
        slip_remark: $slip_remark
      }
    ) {
      _id
    }
  }
`;
