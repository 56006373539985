/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, message, Row, Modal, Input } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { Button, Text } from 'shared/components';
import styled from 'styled-components';
import CANCEL_PARCEL_DETAIL_MUTATION, {
  CancelParcelDetailData,
  CancelParcelDetailVars,
} from 'shared/graphql/mutation/cancelParcelDetail';
import { useHistory } from 'react-router-dom';

export const StyledModal = styled(Modal)`
  &.custom-modal.ant-modal {
    width: 100% !important;
    max-width: 640px;
  }
`;

type ModalCancelProps = {
  visible: boolean;
  onCancel: () => void;
  goBack: string;
  id: string;
};

const ModalCancel: React.FC<ModalCancelProps> = (props) => {
  const { onCancel, visible, goBack, id } = props;
  const history = useHistory();
  const [remark, setRemark] = useState<string>('');

  const [cancelParcelDetail, { loading }] = useMutation<
    CancelParcelDetailData,
    CancelParcelDetailVars
  >(CANCEL_PARCEL_DETAIL_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.cancelParcelDetail._id) {
        message.success('ยกเลิกการใช้แล้ว');
        onCancel();
        history.push(goBack);
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['ParcelDetailList', 'ParcelDetailStatus'],
  });

  const confirmCancel = () => {
    cancelParcelDetail({
      variables: {
        _id: id,
        cancelRemark: remark,
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันยกเลิกการใช้หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="red"
          onClick={() => confirmCancel()}
          loading={loading}
          disabled={!remark}
        >
          ยกเลิกการใช้
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
      destroyOnClose
    >
      <Row gutter={[0, 0]}>
        <Col span={24}>
          หมายเหตุ:<Text customType="danger">*</Text>
          <Input.TextArea
            value={remark}
            placeholder="ระบุ"
            rows={4}
            onChange={(event) => {
              setRemark(event.target.value);
            }}
          />
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalCancel;
