/* eslint-disable no-useless-escape */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Form, Divider, Radio, Select } from 'antd';
import { Text, SelectBanksField } from 'shared/components';
import useProvince from 'shared/hook/useProvince';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import {
  FormValues,
  Email,
  CustomerBankMode,
  CustomerBankPromptPayMode,
} from 'views/Setting/Routes/Customer/Routes/CustomerDetail/formValues.model';
import { TextHeader, StyledTag } from './Styles';

const { Option } = Select;

const Forms: React.FC = () => {
  const {
    errors,
    control,
    watch,
    setValue,
    register,
    trigger,
    setError,
  } = useFormContext<FormValues>();
  const watchProvinceField = watch('province');
  const watchDistrictField = watch('district');
  const watchBusinessInvoiceType = watch('businessInvoiceType');

  const [inputAddEmail, setInputAddEmail] = useState<string>('');

  const { provinces, districts, subDistricts, setProvince, setDistrict } = useProvince();

  const { fields: fieldsEmail, append, remove } = useFieldArray<Email>({
    control,
    name: 'email',
  });

  useEffect(() => {
    if (watchProvinceField) {
      const findProvince = provinces.find((province) => province.name === watchProvinceField);
      if (findProvince) {
        setProvince(findProvince.id);
      }
    }
  }, [provinces, setProvince, watchProvinceField]);

  useEffect(() => {
    if (watchDistrictField) {
      const findDistrict = districts.find((district) => district.name === watchDistrictField);
      if (findDistrict) {
        setDistrict(findDistrict.id);
      }
    }
  }, [districts, setDistrict, watchDistrictField]);

  useEffect(() => {
    if (fieldsEmail.length) trigger('email');
  }, [fieldsEmail.length, trigger]);

  const checkUniqueEmail = (emails: string[]) => {
    return new Set(emails).size === emails.length;
  };

  const checkValidEmail = (email: string) => {
    const validateEmail = /^(([a-zA-Z0-9]+([\_\.][a-zA-Z0-9]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
    if (validateEmail.test(email)) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <TextHeader>ข้อมูลลูกหนี้</TextHeader>
        </Col>
      </Row>

      <Row>
        <Col span={20}>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              ชื่อ:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.firstName && 'error'}
                help={errors.firstName ? errors.firstName.message : undefined}
              >
                <Controller as={Input} control={control} name="firstName" placeholder="ระบุ" />
              </Form.Item>
            </Col>
            <Col span={12}>
              นามสกุล:
              <Form.Item
                validateStatus={errors.lastName && 'error'}
                help={errors.lastName ? errors.lastName.message : undefined}
              >
                <Controller as={Input} control={control} name="lastName" placeholder="ระบุ" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              เลขประจำตัวผู้เสียภาษี:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.taxId && 'error'}
                help={errors.taxId ? errors.taxId.message : undefined}
              >
                <Controller as={Input} control={control} name="taxId" placeholder="0000000000000" />
              </Form.Item>
            </Col>
            <Col span={12}>
              ประเภทลูกค้า:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.from && 'error'}
                help={errors.from ? errors.from.message : undefined}
              >
                <Controller
                  name="from"
                  control={control}
                  render={({ onChange, value }) => (
                    <Select
                      value={value || undefined}
                      onChange={(valueOnChange) => {
                        onChange(valueOnChange);
                      }}
                      style={{ width: '100%' }}
                      placeholder="เลือก"
                    >
                      <Option value={1}>Others</Option>
                      <Option value={2}>GIZTIX EXPRESS</Option>
                      <Option value={3}>GIZTIX ONE (ENTERPRISE) - BTV</Option>
                      <Option value={4}>GIZTIX ONE (ENTERPRISE) - CBM</Option>
                      <Option value={5}>GIZTIX ONE</Option>
                      <Option value={6}>GIZTIX ONE (ENTERPRISE) - GIGAMOVE</Option>
                      <Option value={7}>GIZTIX ONE (ENTERPRISE) - NICHIREI</Option>
                      <Option value={8}>GIZTIX ONE (ENTERPRISE) - SCGH</Option>
                      <Option value={9}>GIZTIX ONE (ENTERPRISE) - BLF</Option>
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              ที่อยู่:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.address && 'error'}
                help={errors.address ? errors.address.message : undefined}
              >
                <Controller as={Input} control={control} name="address" placeholder="ระบุ" />
              </Form.Item>
            </Col>
            <Col span={12}>
              สาขา:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.branch && 'error'}
                help={errors.branch ? errors.branch.message : undefined}
              >
                <Controller as={Input} control={control} name="branch" placeholder="ระบุ" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={6}>
              จังหวัด:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.province && 'error'}
                help={errors.province ? errors.province.message : undefined}
              >
                <Controller
                  name="province"
                  control={control}
                  render={({ onChange, value, onBlur }) => (
                    <Select
                      value={value}
                      showSearch
                      filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(valueChange) => {
                        onChange(valueChange);
                        setValue('district', '');
                        setValue('subDistrict', '');
                      }}
                      onBlur={() => onBlur()}
                    >
                      <Option value="">เลือกจังหวัด</Option>
                      {provinces.map((item) => (
                        <Option key={`${item.id}${item.name}`} value={`${item.name}`}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              เขต/อำเภอ:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.district && 'error'}
                help={errors.district ? errors.district.message : undefined}
              >
                <Controller
                  name="district"
                  control={control}
                  render={({ onChange, value, onBlur }) => (
                    <Select
                      value={value}
                      showSearch
                      filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(valueChange) => {
                        onChange(valueChange);
                        setValue('subDistrict', '');
                      }}
                      onBlur={() => onBlur()}
                      disabled={!watchProvinceField}
                    >
                      <Option value="">เลือกเขต/อำเภอ</Option>
                      {districts.map((item) => (
                        <Option key={`${item.id}${item.name}`} value={`${item.name}`}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              แขวง/ตำบล:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.subDistrict && 'error'}
                help={errors.subDistrict ? errors.subDistrict.message : undefined}
              >
                <Controller
                  name="subDistrict"
                  control={control}
                  render={({ onChange, value, onBlur }) => (
                    <Select
                      value={value}
                      showSearch
                      filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(valueChange) => onChange(valueChange)}
                      onBlur={() => onBlur()}
                      disabled={!watchDistrictField}
                    >
                      <Option value="">เลือกแขวง/ตำบล</Option>
                      {subDistricts.map((item) => (
                        <Option key={`${item.id}${item.name}`} value={`${item.name}`}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              รหัสไปรษณีย์:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.postCode && 'error'}
                help={errors.postCode ? errors.postCode.message : undefined}
              >
                <Controller as={Input} control={control} name="postCode" placeholder="12345" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={24}>
              รูปแบบรับใบแจ้งหนี้:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.businessInvoiceType && 'error'}
                help={errors.businessInvoiceType ? errors.businessInvoiceType.message : undefined}
              >
                <Controller
                  name="businessInvoiceType"
                  control={control}
                  render={({ onChange, value }) => (
                    <>
                      <Radio.Group
                        value={value}
                        onChange={(valueOnChange) => onChange(valueOnChange.target.value)}
                      >
                        <Row>
                          <Radio value={1}>1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลทุกสิ้นเดือน</Radio>
                        </Row>
                        <Row>
                          <Radio value={2}>1 ใบแจ้งหนี้ ต่อ 1 งาน และวางบิลทุกวันหลังจบงาน</Radio>
                        </Row>
                        <Row>
                          <Radio value={3}>
                            1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลตามวันที่กำหนด (โปรดระบุวันที่)
                          </Radio>
                        </Row>
                        {watchBusinessInvoiceType === 3 && (
                          <Row style={{ marginLeft: 30 }}>
                            <Col span={6}>
                              <Form.Item
                                validateStatus={errors.businessInvoiceDateRangeStart && 'error'}
                                help={
                                  errors.businessInvoiceDateRangeStart
                                    ? errors.businessInvoiceDateRangeStart.message
                                    : undefined
                                }
                              >
                                <Controller
                                  name="businessInvoiceDateRangeStart"
                                  control={control}
                                  render={({ onChange: onChangeInput, value: valueInput }) => (
                                    <Input
                                      value={valueInput || undefined}
                                      onChange={(valueChange) => {
                                        onChangeInput(valueChange);
                                        trigger(['businessInvoiceDateRangeEnd']);
                                      }}
                                    />
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2} style={{ textAlign: 'center' }}>
                              <Text style={{ fontSize: 20 }}>ถึง</Text>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                validateStatus={errors.businessInvoiceDateRangeEnd && 'error'}
                                help={
                                  errors.businessInvoiceDateRangeEnd
                                    ? errors.businessInvoiceDateRangeEnd.message
                                    : undefined
                                }
                              >
                                <Controller
                                  name="businessInvoiceDateRangeEnd"
                                  control={control}
                                  render={({ onChange: onChangeInput, value: valueInput }) => (
                                    <Input
                                      value={valueInput || undefined}
                                      onChange={(valueChange) => {
                                        onChangeInput(valueChange);
                                        trigger(['businessInvoiceDateRangeStart']);
                                      }}
                                    />
                                  )}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Radio value={4}>
                            1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลทุกสิ้นเดือน
                          </Radio>
                        </Row>
                        <Row>
                          <Radio value={5}>
                            1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลตามวันที่กำหนด
                            (โปรดระบุวันที่)
                          </Radio>
                        </Row>
                        {watchBusinessInvoiceType === 5 && (
                          <Row style={{ marginLeft: 30 }}>
                            <Col span={6}>
                              <Form.Item
                                validateStatus={errors.businessInvoiceDateRangeStart && 'error'}
                                help={
                                  errors.businessInvoiceDateRangeStart
                                    ? errors.businessInvoiceDateRangeStart.message
                                    : undefined
                                }
                              >
                                <Controller
                                  name="businessInvoiceDateRangeStart"
                                  control={control}
                                  render={({ onChange: onChangeInput, value: valueInput }) => (
                                    <Input
                                      value={valueInput || undefined}
                                      onChange={(valueChange) => {
                                        onChangeInput(valueChange);
                                        trigger(['businessInvoiceDateRangeEnd']);
                                      }}
                                    />
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2} style={{ textAlign: 'center' }}>
                              <Text style={{ fontSize: 20 }}>ถึง</Text>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                validateStatus={errors.businessInvoiceDateRangeEnd && 'error'}
                                help={
                                  errors.businessInvoiceDateRangeEnd
                                    ? errors.businessInvoiceDateRangeEnd.message
                                    : undefined
                                }
                              >
                                <Controller
                                  name="businessInvoiceDateRangeEnd"
                                  control={control}
                                  render={({ onChange: onChangeInput, value: valueInput }) => (
                                    <Input
                                      value={valueInput || undefined}
                                      onChange={(valueChange) => {
                                        onChangeInput(valueChange);
                                        trigger(['businessInvoiceDateRangeStart']);
                                      }}
                                    />
                                  )}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                      </Radio.Group>
                    </>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={24}>
              รอบการชำระลูกหนี้<Text customType="danger">*</Text>
              <Controller
                name="businessPayRoundRound"
                control={control}
                render={({ onChange, value }) => (
                  <>
                    <Radio.Group
                      value={value}
                      onChange={(valueOnChange) => onChange(valueOnChange.target.value)}
                      style={{ width: '100%' }}
                    >
                      <Row>
                        <Col span={6}>
                          <Radio
                            value="1"
                            onChange={() => setValue('businessPayRoundRoundAfterInvoice', '0')}
                          >
                            <Text>ทุกวันที่</Text>
                          </Radio>
                        </Col>
                        <Col span={6}>
                          <Radio
                            value="2"
                            onChange={() => setValue('businessPayRoundRoundDate', '0')}
                          >
                            <Text>หลังจากวันแจ้งหนี้</Text>
                          </Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                    <Row>
                      <Col span={6} style={{ marginLeft: 20 }}>
                        <Form.Item
                          validateStatus={errors.businessPayRoundRoundDate && 'error'}
                          help={
                            errors.businessPayRoundRoundDate
                              ? errors.businessPayRoundRoundDate.message
                              : undefined
                          }
                        >
                          <Controller
                            name="businessPayRoundRoundDate"
                            control={control}
                            render={({ onChange: onChangeInput, value: valueInput }) => (
                              <>
                                <Input
                                  value={valueInput || undefined}
                                  onChange={(valueChange) => {
                                    onChangeInput(valueChange);
                                    setValue('businessPayRoundRound', '1');
                                    setValue('businessPayRoundRoundAfterInvoice', '0');
                                    trigger(['businessPayRoundRoundAfterInvoice']);
                                  }}
                                  style={{ width: 120, marginRight: 5 }}
                                />
                                <Text>ของเดือน</Text>
                              </>
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6} style={{ marginLeft: 5 }}>
                        <Form.Item
                          validateStatus={errors.businessPayRoundRoundAfterInvoice && 'error'}
                          help={
                            errors.businessPayRoundRoundAfterInvoice
                              ? errors.businessPayRoundRoundAfterInvoice.message
                              : undefined
                          }
                        >
                          <Controller
                            name="businessPayRoundRoundAfterInvoice"
                            control={control}
                            render={({ onChange: onChangeInput, value: valueInput }) => (
                              <>
                                <Input
                                  value={valueInput || undefined}
                                  onChange={(valueChange) => {
                                    onChangeInput(valueChange);
                                    setValue('businessPayRoundRound', '2');
                                    setValue('businessPayRoundRoundDate', '0');
                                    trigger(['businessPayRoundRoundDate']);
                                  }}
                                  style={{ width: 120, marginRight: 5 }}
                                />
                                <Text>วัน</Text>
                              </>
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              />
            </Col>
          </Row>

          <Divider />

          <Row>
            <Col span={24}>
              <TextHeader>ข้อมูลการติดต่อ</TextHeader>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  เบอร์โทรศัพท์:<Text customType="danger">*</Text>
                  <Form.Item
                    validateStatus={errors.phone && 'error'}
                    help={errors.phone ? errors.phone.message : undefined}
                  >
                    <Controller
                      as={Input}
                      control={control}
                      name="phone"
                      placeholder="08xxxxxxxx"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  อีเมล{' '}
                  <Text customType="grey">เพื่อใช้ในการรับเอกสาร (กด Enter เพื่อเพิ่มอีเมล)</Text>:
                  <Text customType="danger">*</Text>
                  <Form.Item validateStatus={errors.email && 'error'} style={{ marginBottom: 15 }}>
                    <Input
                      value={inputAddEmail}
                      onChange={(event) => setInputAddEmail(event.target.value)}
                      placeholder="example@email.com"
                      onPressEnter={(event) => {
                        const valueEmail = event.currentTarget.value;
                        if (valueEmail) {
                          const emails = [
                            ...fieldsEmail.map((item) => item.text as string),
                            valueEmail,
                          ];

                          if (!checkValidEmail(valueEmail)) {
                            setError('email', {
                              type: 'qnique',
                              message: 'รูปแบบอีเมลไม่ถูกต้อง',
                            });
                            return;
                          }

                          if (!checkUniqueEmail(emails)) {
                            setError('email', {
                              type: 'qnique',
                              message: 'อีเมลต้องไม่ซ้ำกัน',
                            });
                            return;
                          }

                          append({ text: valueEmail });
                          setInputAddEmail('');
                        }
                      }}
                    />
                  </Form.Item>
                  {fieldsEmail.map((item, index) => (
                    <span key={item.id}>
                      <input
                        type="hidden"
                        value={item.text}
                        name={`email[${index}].text`}
                        ref={register()}
                      />

                      <StyledTag visible closable onClose={() => remove(index)}>
                        {item.text}
                      </StyledTag>
                    </span>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider />

          <Row>
            <Col span={24}>
              <TextHeader>ข้อมูลบัญชีธนาคาร</TextHeader>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            <Col span={6}>
              ชื่อธนาคาร:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.bankName && 'error'}
                help={errors.bankName ? errors.bankName.message : undefined}
              >
                <Controller as={Input} control={control} name="bankName" placeholder="ระบุ" />
              </Form.Item>
            </Col>
            <Col span={6}>
              สาขา:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.bankBranch && 'error'}
                help={errors.bankBranch ? errors.bankBranch.message : undefined}
              >
                <Controller as={Input} control={control} name="bankBranch" placeholder="ระบุ" />
              </Form.Item>
            </Col>
            <Col span={6}>
              ชื่อบัญชี:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.accountName && 'error'}
                help={errors.accountName ? errors.accountName.message : undefined}
              >
                <Controller as={Input} control={control} name="accountName" placeholder="ระบุ" />
              </Form.Item>
            </Col>
            <Col span={6}>
              เลขที่บัญชี:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.accountNumber && 'error'}
                help={errors.accountNumber ? errors.accountNumber.message : undefined}
              >
                <Controller
                  as={Input}
                  control={control}
                  name="accountNumber"
                  placeholder="1234567890"
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row>
            <Col span={24}>
              <TextHeader>ข้อมูลบัญชีธนาคารของลูกค้า</TextHeader>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                validateStatus={errors.customerBankMode && 'error'}
                help={errors.customerBankMode ? errors.customerBankMode.message : undefined}
                style={{ marginBottom: 10 }}
              >
                <Controller
                  name="customerBankMode"
                  control={control}
                  render={({ onChange: onChangeInput, value: valueInput }) => (
                    <>
                      <Radio.Group
                        value={valueInput}
                        onChange={(valueChange) => {
                          onChangeInput(valueChange.target.value);
                          trigger(['customerAccountNumber']);
                          trigger(['customerBankPromptPayMode']);
                          trigger(['customerBankMode']);
                        }}
                      >
                        <Radio value={CustomerBankMode.BANK_ACCOUNT}>บัญชีธนาคาร</Radio>
                        <Radio value={CustomerBankMode.PROMPT_PAY}>PromptPay</Radio>
                      </Radio.Group>
                    </>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          {watch('customerBankMode') === CustomerBankMode.BANK_ACCOUNT && (
            <Row gutter={[16, 0]}>
              <Col span={12}>
                ชื่อธนาคาร:<Text customType="danger">*</Text>
                <Form.Item
                  validateStatus={errors.customerBankId && 'error'}
                  help={errors.customerBankId ? errors.customerBankId.message : undefined}
                >
                  <Controller
                    name="customerBankId"
                    control={control}
                    render={({ onChange: onChangeInput, value: valueInput }) => (
                      <>
                        <SelectBanksField
                          value={valueInput || undefined}
                          onChange={(valueChange) => {
                            onChangeInput(valueChange.bankId);
                            setValue('customerBankCode', valueChange.bankCode);
                          }}
                        />
                      </>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                ชื่อบัญชี:<Text customType="danger">*</Text>
                <Form.Item
                  validateStatus={errors.customerAccountName && 'error'}
                  help={errors.customerAccountName ? errors.customerAccountName.message : undefined}
                >
                  <Controller
                    as={Input}
                    control={control}
                    name="customerAccountName"
                    placeholder="ระบุ"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                เลขที่บัญชี:<Text customType="danger">*</Text>
                <Form.Item
                  validateStatus={errors.customerAccountNumber && 'error'}
                  help={
                    errors.customerAccountNumber ? errors.customerAccountNumber.message : undefined
                  }
                >
                  <Controller
                    as={Input}
                    control={control}
                    name="customerAccountNumber"
                    placeholder="1234567890"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          {watch('customerBankMode') === CustomerBankMode.PROMPT_PAY && (
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item
                  validateStatus={errors.customerBankPromptPayMode && 'error'}
                  help={
                    errors.customerBankPromptPayMode
                      ? errors.customerBankPromptPayMode.message
                      : undefined
                  }
                >
                  <Controller
                    name="customerBankPromptPayMode"
                    control={control}
                    render={({ onChange: onChangeInput, value: valueInput }) => (
                      <>
                        <Radio.Group
                          value={valueInput}
                          onChange={(valueChange) => {
                            onChangeInput(valueChange.target.value);
                            trigger(['customerAccountNumber']);
                            trigger(['customerBankPromptPayMode']);
                            trigger(['customerBankMode']);
                          }}
                        >
                          <Radio value={CustomerBankPromptPayMode.TELEPHONE_NUMBER}>
                            เบอร์โทรศัพท์
                          </Radio>
                          <Radio value={CustomerBankPromptPayMode.ID_CARD_NUMBER}>
                            เลขประจำตัวประชาชน
                          </Radio>
                        </Radio.Group>
                      </>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                ชื่อบัญชี:<Text customType="danger">*</Text>
                <Form.Item
                  validateStatus={errors.customerAccountName && 'error'}
                  help={errors.customerAccountName ? errors.customerAccountName.message : undefined}
                >
                  <Controller
                    as={Input}
                    control={control}
                    name="customerAccountName"
                    placeholder="ระบุ"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                {watch('customerBankPromptPayMode') === CustomerBankPromptPayMode.TELEPHONE_NUMBER
                  ? 'เบอร์โทรศัพท์'
                  : 'เลขประจำตัวประชาชน'}
                :<Text customType="danger">*</Text>
                <Form.Item
                  validateStatus={errors.customerAccountNumber && 'error'}
                  help={
                    errors.customerAccountNumber ? errors.customerAccountNumber.message : undefined
                  }
                >
                  <Controller
                    as={Input}
                    control={control}
                    name="customerAccountNumber"
                    placeholder={
                      watch('customerBankPromptPayMode') ===
                      CustomerBankPromptPayMode.TELEPHONE_NUMBER
                        ? 'เบอร์โทรศัพท์ 10 หลัก'
                        : 'เลขประจำตัวประชาชน 13 หลัก'
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Forms;
