import React from 'react';
import { Tag } from 'shared/components';
import { TagColorType } from 'shared/components/Tag';

type TagStatusProps = {
  status: number;
};

const getTextColorStatus = (
  status: number,
): {
  color: TagColorType | undefined;
  text: string | number;
  outline: boolean;
} => {
  switch (status) {
    case 3:
      return { color: 'black', text: 'กำลังไปคลัง GSC', outline: false };
    case 5:
      return { color: 'blue', text: 'คงคลัง Giztix', outline: false };
    case 6:
      return { color: 'red', text: 'ยกเลิก', outline: false };
    case 7:
      return { color: 'blue--light', text: 'ออกจัดส่งลูกค้า', outline: false };
    case 8:
      return { color: 'green', text: 'ส่งสำเร็จ', outline: false };
    case 9:
      return { color: 'grey', text: 'คืนคลังลูกค้า', outline: false };
    default:
      return { color: undefined, text: status, outline: false };
  }
};

const TagStatus: React.FC<TagStatusProps> = (props) => {
  const { status } = props;
  const textColor = getTextColorStatus(status);

  return (
    <Tag customColor={textColor.color} outline={textColor.outline}>
      {textColor.text}
    </Tag>
  );
};

export default TagStatus;
