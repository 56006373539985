import React from 'react';
import { ParcelCostingStatusText, ParcelCostingStatusType } from 'shared/constants/status';
import { StyledTag } from './Styles';

type TagStatusProps = {
  status: 1 | 2 | 3 | 4 | 5 | 6;
};

const convertToStatusType = (status: TagStatusProps['status']): string => {
  switch (status) {
    case 1:
      return ParcelCostingStatusType.WAIT_AGENT_CONFIRM;
    case 2:
      return ParcelCostingStatusType.WAITING_CONFIRM;
    case 3:
      return ParcelCostingStatusType.CONFIRM;
    case 4:
      return ParcelCostingStatusType.REJECT;
    case 5:
      return ParcelCostingStatusType.EDIT;
    case 6:
      return ParcelCostingStatusType.CANCEL;
    default:
      return status;
  }
};

const getTextStatus = (statusName: string) => {
  return ParcelCostingStatusText[statusName];
};

const TagStatus: React.FC<TagStatusProps> = (props) => {
  const { status } = props;
  const statusType = convertToStatusType(status);
  const text = getTextStatus(statusType);
  return <StyledTag type={statusType}>{text}</StyledTag>;
};

export default TagStatus;
