import gql from 'graphql-tag';

export interface CreateReportData {
  createReport: {
    _id: string;
    option_select: {
      date_type: string;
      payment_type: string;
      chartOfAccountLV3: string;
    };
  };
}

export interface CreateReportVars {
  report_type: string;
  name: string;
  description?: string;
  date_range: string;
  option_select: {
    date_type: string | null;
    payment_type: string | null;
    chartOfAccountLV3: string | null;
    department: string | null;
    customer?: string;
  };
}

export default gql`
  mutation CreateReport(
    $report_type: String
    $name: String
    $description: String
    $date_range: String
    $option_select: inputOptionSelect
  ) {
    createReport(
      createReport: {
        report_type: $report_type
        name: $name
        description: $description
        date_range: $date_range
        option_select: $option_select
      }
    ) {
      _id
      option_select {
        date_type
        payment_type
        chartOfAccountLV3
      }
    }
  }
`;
