import styled from 'styled-components';
import { Checkbox } from 'antd';

export const ToolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledCheckbox = styled(Checkbox)`
  transform: scale(1.2);
  margin-right: 10px;
`;
