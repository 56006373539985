/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { Spin, Table as TableComponent } from 'shared/components';
import { ParcelDetailData } from 'shared/graphql/query/parcelDetail';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { mixin, color } from 'shared/utils/styles';

export const StyledTable = styled(TableComponent)`
  & thead > tr > th {
    border-top: 1px solid ${color.border};
  }

  & tr > th.divider,
  tr > td.divider {
    border-right: 1px solid ${color.border};
  }

  ${mixin.backgroundTableOdd}
`;

interface TableProps {
  data: ParcelDetailData;
  loading: boolean;
}

type DataTableType = {
  key: string;
  column1: string;
  column2: string;
  column3: string;
  column4: string;
  column5: number;
  column6: string;
  column7: string;
  column8: string;
};

const Table: React.FC<TableProps> = (props) => {
  const { data, loading } = props;

  const columns = [
    {
      title: 'รหัสสินค้า',
      dataIndex: 'column1',
      width: 150,
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'column2',
      width: 250,
    },
    {
      title: 'วันที่ส่งสำเร็จ',
      dataIndex: 'column3',
      width: 150,
    },
    {
      title: 'น้ำหนัก',
      dataIndex: 'column4',
      width: 80,
    },
    {
      title: 'CBM',
      dataIndex: 'column5',
      width: 80,
    },
    {
      title: 'ต้นทุนรวม (บาท)',
      dataIndex: 'column6',
      width: 150,
    },
    {
      title: 'รายได้รวม (บาท)',
      dataIndex: 'column7',
      width: 150,
    },
    {
      title: 'ยอด COD',
      dataIndex: 'column8',
      width: 150,
    },
  ];

  let content: ReactNode = <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  let tableData: DataTableType[] = [];
  if (data?.parcelDetail) {
    const { parcels } = data.parcelDetail;
    tableData = parcels.map(
      (item): DataTableType => {
        return {
          key: uuidv4(),
          column1: item.trackingNumber || '-',
          column2: item.itemName || '-',
          column3: item.deliveredDate ? formatDate(item.deliveredDate) : '-',
          column4: item.weight ? `${item.weight} กก.` : '-',
          column5: item.cbm || 0,
          column6: item.cost ? formatCurrency(item.cost) : '-',
          column7: item.parcelRevenue?.totalRevenue
            ? formatCurrency(item.parcelRevenue.totalRevenue)
            : '-',
          column8: item.cod ? formatCurrency(item.cod) : '-',
        };
      },
    );

    content = (
      <>
        <StyledTable
          pagination={false}
          columns={columns}
          dataSource={tableData}
          size="small"
          scroll={{ y: 300 }}
          loading={loading}
        />
      </>
    );
  }

  return <div>{content}</div>;
};

export default Table;
