import styled from 'styled-components';
import { Button, Checkbox } from 'antd';

export const Container = styled.div`
  padding: 0px 20px 20px;
`;

export const ToolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledCheckbox = styled(Checkbox)`
  transform: scale(1.2);
  margin-right: 10px;
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;
