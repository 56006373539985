import gql from 'graphql-tag';
import { PaymentStatus } from 'views/AccountPayment/common/model/paymentStatus.model';

export interface PaymentList {
  datamaster_service_item_id: string;
  _id: string;
  list_name: string;
  list_number: string;
  qty: number;
  price: number;
  total_before_discount: number;
  discount: number;
  total_price: number;
  vat: number;
  wht: number;
  advance_type: boolean;
  unit: string;
}

export interface AllWht {
  wht: number;
  price: number;
  total_wht: number;
}

export interface Payment {
  _id: string;
  payment_number: string;
  payee: {
    _id: string;
    full_name: string;
  };
  vendor: {
    _id: string;
    full_name: string;
  };
  round_paid: string;
  employee: {
    _id: string;
    full_name: string;
    department: string;
  };
  payment_status: PaymentStatus;
  payment_type: string;
  number_ref: string;
  system_request: string;
  create_date: string;
  service_date: string;
  grand_total_price: number;
  payment_list: PaymentList[];
  re_total_before_discount: number;
  non_total_before_discount: number;
  inc_total_before_discount: number;
  re_total_discount: number;
  non_total_discount: number;
  inc_total_discount: number;
  re_total_after_discount: number;
  non_total_after_discount: number;
  inc_total_after_discount: number;
  total_vat: number;
  re_total: number;
  non_total: number;
  inc_total: number;
  total_price: number;
  all_wht: AllWht[];
  payment_remark: string;
  reject_remark: string;
  cancel_remark: string;
  save_status: number;
  invoice_date: string;
  invoice_due_date: string;
  vendor_invoice_id: string;
  document: string;
  department: string;
  fixed_asset_check: number;
}

export interface PaymentData {
  payment: Payment;
}

export interface PaymentVars {
  _id: string;
}

export default gql`
  query Payment($_id: ID!) {
    payment(_id: $_id) {
      _id
      payment_number
      payee {
        _id
        full_name
      }
      vendor {
        _id
        full_name
      }
      round_paid
      employee {
        _id
        full_name
        department
      }
      payment_status
      payment_type
      number_ref
      system_request
      create_date
      service_date
      grand_total_price
      payment_list {
        datamaster_service_item_id
        _id
        list_name
        list_number
        qty
        price
        total_before_discount
        discount
        total_price
        vat
        wht
        advance_type
        unit
      }
      re_total_before_discount
      non_total_before_discount
      inc_total_before_discount
      re_total_discount
      non_total_discount
      inc_total_discount
      re_total_after_discount
      non_total_after_discount
      inc_total_after_discount
      total_vat
      re_total
      non_total
      inc_total
      total_price
      all_wht {
        wht
        price
        total_wht
      }
      payment_remark
      reject_remark
      cancel_remark
      save_status
      invoice_date
      invoice_due_date
      vendor_invoice_id
      document
      department
      fixed_asset_check
    }
  }
`;
