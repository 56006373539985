/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode } from 'react';
import { Select, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import PAYMENT_VOUCHERS_QUERY, {
  PaymentVouchersData,
  PaymentVouchersVars,
} from 'shared/graphql/query/paymentVouchers';

const { Option } = Select;

type SelectPaymentVoucherFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const SelectPaymentVoucherField: React.FC<SelectPaymentVoucherFieldProps> = (props) => {
  const { value, onChange } = props;
  const [voucherNumber, setVoucherNumber] = useState('');
  const { loading, data } = useQuery<PaymentVouchersData, PaymentVouchersVars>(
    PAYMENT_VOUCHERS_QUERY,
    {
      variables: {
        voucher_number: voucherNumber,
        showData: 10,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  let options: ReactNode[] = [];
  if (data && data.paymentVouchers) {
    const { paymentVouchers } = data.paymentVouchers;
    options = paymentVouchers.map((paymentVoucher) => (
      <Option key={paymentVoucher._id} value={paymentVoucher._id}>
        {paymentVoucher.voucher_number}
      </Option>
    ));
  }

  return (
    <Select
      style={{
        width: '100%',
      }}
      value={value}
      allowClear
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      showSearch
      placeholder="ค้นหา"
      onSearch={(valueSearch) => setVoucherNumber(valueSearch)}
      onChange={(valueChange) => onChange && onChange(valueChange)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};

export default SelectPaymentVoucherField;
