import React, { useState, useContext } from 'react';
import {
  CodProvider,
  CodContext,
  SelectedCodItemType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/context/CodContext';
import { Route, useRouteMatch } from 'react-router-dom';
import { Row, Col } from 'antd';
import { CodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/codStatus.model';
import { CodItemStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/codItemStatus.model';
import { Container, StyledButton } from './Styles';
import CodListTable from './CodListTable';
import CodItemListTable from './CodItemListTable';
import CodTabs from './CodTabs';
import CodLastTabs from './CodLastTabs';
import CodItemTabs from './CodItemTabs';
import ModalCreateCod from './ModalCreatePaymentCod';
import ModalCodDetail from './CodListTable/ModalCodDetail';
import ModalCodItemDetail from './CodItemListTable/ModalCodItemDetail';

const CodWrapper: React.FC = () => {
  return (
    <CodProvider>
      <Cod />
    </CodProvider>
  );
};

const Cod: React.FC = () => {
  const [tabCod, setTabCod] = useState<CodStatus>(CodStatus.OPS_PENDING);
  const [tabCodItem, setTabCodItem] = useState<CodItemStatus>(CodItemStatus.WAIT_TO_PAY);
  const [tabName, setTabName] = useState<'cod' | 'codItem'>('cod');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { selectedCodItems, setSelectedCodItems } = useContext(CodContext);
  const { path } = useRouteMatch();

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={16}>
          <Row>
            <Col style={{ marginRight: 20 }}>
              <CodTabs
                tab={tabCod}
                name={tabName}
                onChange={(value) => {
                  setTabCod(value);
                  setTabName('cod');
                  setCurrentPage(1);
                }}
              />
            </Col>
            <Col style={{ marginRight: 20 }}>
              <CodItemTabs
                tab={tabCodItem}
                name={tabName}
                onChange={(value) => {
                  setTabCodItem(value);
                  setTabName('codItem');
                  setCurrentPage(1);
                }}
              />
            </Col>
            <Col>
              <CodLastTabs
                tab={tabCod}
                name={tabName}
                onChange={(value) => {
                  setTabCod(value);
                  setTabName('cod');
                  setCurrentPage(1);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row justify="end">
            <Col>
              <StyledButton
                onClick={() => {
                  setSelectedCodItems(
                    (prevState): SelectedCodItemType => ({
                      ...prevState,
                      isOpenModal: true,
                    }),
                  );
                }}
                disabled={selectedCodItems.codItemsId.length === 0}
                color="green"
              >
                สร้างการทำจ่าย
              </StyledButton>
            </Col>
          </Row>
        </Col>
      </Row>

      {tabName === 'cod' && (
        <CodListTable
          status={tabCod}
          pageSize={pageSize}
          onChangePageSize={(value) => setPageSize(value)}
          currentPage={currentPage}
          onChangeCurrentPage={(value) => setCurrentPage(value)}
        />
      )}
      {tabName === 'codItem' && (
        <CodItemListTable
          status={tabCodItem}
          pageSize={pageSize}
          onChangePageSize={(value) => setPageSize(value)}
          currentPage={currentPage}
          onChangeCurrentPage={(value) => setCurrentPage(value)}
        />
      )}

      <Route
        exact
        path={`${path}/detail/:codId`}
        render={(routeProps) => (
          <ModalCodDetail codId={routeProps.match.params.codId} goBack={path} />
        )}
      />
      <Route
        exact
        path={`${path}/detail-item/:codItemId`}
        render={(routeProps) => (
          <ModalCodItemDetail codItemId={routeProps.match.params.codItemId} goBack={path} />
        )}
      />

      <ModalCreateCod />
    </Container>
  );
};

export default CodWrapper;
