import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SubTabs } from 'shared/components';
import BILLING_NOTE_STATUS_QUERY, {
  BillingNoteStatusData,
} from 'shared/graphql/query/billingNoteStatus';
import { BillingNotesStatus } from 'views/AccountReceive/common/model/billingNoteStatus.model';

interface BillingNoteTabsProps {
  name: string;
  tab: BillingNotesStatus;
  onChange: (tab: BillingNotesStatus) => void;
}

const BillingNoteTabs: React.FC<BillingNoteTabsProps> = (props) => {
  const { tab, onChange, name } = props;
  const { data, refetch } = useQuery<BillingNoteStatusData>(BILLING_NOTE_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const checkSelectTab = (id: BillingNotesStatus) => {
    return id === tab && name === 'billingNote';
  };

  const selectTab = (index: BillingNotesStatus) => {
    refetch();
    onChange(index);
  };

  const tabs = [
    {
      id: BillingNotesStatus.WAITING,
      name: 'รอวางบิล',
      color: 'badge-grey',
      count: data?.billingNoteStatus.waiting || 0,
      divider: false,
    },
    {
      id: BillingNotesStatus.BILLING_NOTE,
      name: 'วางบิลแล้ว',
      color: 'badge-grey',
      count: data?.billingNoteStatus.billing_note || 0,
      divider: false,
    },
    {
      id: BillingNotesStatus.COMPLETED,
      name: 'ปิดบิลแล้ว',
      color: 'badge-grey',
      count: data?.billingNoteStatus.completed || 0,
      divider: true,
    },
    {
      id: BillingNotesStatus.CANCEL,
      name: 'ยกเลิก',
      color: 'badge-grey',
      count: data?.billingNoteStatus.cancel || 0,
      divider: false,
    },
    {
      id: BillingNotesStatus.EDIT,
      name: 'แก้ไข',
      color: 'badge-grey',
      count: data?.billingNoteStatus.edit || 0,
      divider: false,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default BillingNoteTabs;
