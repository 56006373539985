import gql from 'graphql-tag';
import { ReceiptStatus } from 'views/AccountReceive/common/model/receiptStatus.model';

export interface Payer {
  _id: string;
  full_name: string;
}

export interface BillingNote {
  bill_number: string;
}

export interface Invoice {
  invoice_number: string;
  grand_total_price: number;
}

export interface Employee {
  full_name: string;
}

export interface Receipt {
  _id: string;
  create_date: string;
  receipt_number: string;
  receipt_status: ReceiptStatus;
  total_receipt: number;
  payer: Payer;
  receipt_date: string;
  billing_note: BillingNote;
  invoice: Invoice;
  employee: Employee;
  last_update: string;
}

export interface Receipts {
  receipts: Receipt[];
  totalDocument: number;
}

export interface ReceiptsData {
  receipts: Receipts;
}

export interface ReceiptsVars {
  _id?: string;
  receipt_status?: ReceiptStatus;
  receipt_number?: string;
  payer?: string;
  employee?: string;
  billing_note?: string;
  invoice?: string;
  create_date?: string;
  receipt_date?: string;
  last_update?: string;
  page?: number;
  showData?: number;
  order_by?: string;
  order_type?: '1' | '-1' | '';
}

export default gql`
  query Receipts(
    $_id: ID
    $receipt_status: Int
    $receipt_number: String
    $payer: ID
    $employee: ID
    $billing_note: ID
    $invoice: ID
    $create_date: String
    $receipt_date: String
    $last_update: String
    $page: Int
    $showData: Int
    $order_by: String
    $order_type: String
  ) {
    receipts(
      params: {
        _id: $_id
        receipt_status: $receipt_status
        receipt_number: $receipt_number
        payer: $payer
        employee: $employee
        billing_note: $billing_note
        invoice: $invoice
        create_date: $create_date
        receipt_date: $receipt_date
        last_update: $last_update
      }
      page: $page
      showData: $showData
      sort: { order_by: $order_by, order_type: $order_type }
    ) {
      receipts {
        _id
        create_date
        receipt_number
        receipt_status
        total_receipt
        payer {
          _id
          full_name
        }
        receipt_date
        billing_note {
          bill_number
        }
        invoice {
          invoice_number
          grand_total_price
        }
        employee {
          full_name
        }
        last_update
      }
      totalDocument
    }
  }
`;
