/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Row, Col, Divider, message, Form, Input } from 'antd';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import formatCurrency from 'shared/utils/formatCurrency';
import { useMutation } from '@apollo/react-hooks';
import { Controller, useForm } from 'react-hook-form';
import { FormValues } from 'views/AccountReceive/Routes/Receipt/ReceiptsTable/ModalReceiptDetail/model/formValues.model';
import {
  ReceiptContext,
  SelectedReceiptDetailType,
} from 'views/AccountReceive/common/context/ReceiptContext';
import SEND_EMAIL_RECEIPT_MUTATION, {
  UpdateReceiptData,
  UpdateReceiptVars,
} from 'shared/graphql/mutation/updateReceipt';
import { Button, Text } from 'shared/components';
import { StyledModal } from './Styles';

type ModalConfirmSendEmailProps = {
  visible: boolean;
  onCancel: () => void;
};

const { TextArea } = Input;

const validateSchema = yup.object().shape({
  cancelRemark: yup.string().required(),
});

const ModalConfirmSendEmail: React.FC<ModalConfirmSendEmailProps> = (props) => {
  const { onCancel, visible } = props;
  const { selectedReceiptDetail, setSelectedReceiptDetail } = useContext(ReceiptContext);
  console.log('selectedReceiptDetail', selectedReceiptDetail);
  const { receiptId, systemRequest, totalReceipt, receiptNumber } = selectedReceiptDetail;

  const methods = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      cancelRemark: '',
    },
  });
  const { control, errors, reset, watch } = methods;
  const watchCancelRemark = watch('cancelRemark');

  const [updateReceipt, { loading }] = useMutation<UpdateReceiptData, UpdateReceiptVars>(
    SEND_EMAIL_RECEIPT_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.updateReceipt) {
          message.success('ยกเลิกใบเสร็จแล้ว');
          onCancel();
          reset();
          setSelectedReceiptDetail(
            (prevState): SelectedReceiptDetailType => ({
              ...prevState,
              isOpenModal: false,
            }),
          );
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['Receipt', 'ReceiptStatus', 'Receipts', 'EmailLogs'],
    },
  );

  const confirmUpdate = () => {
    updateReceipt({
      variables: {
        _id: receiptId,
        receipt_status: 2,
        cancel_remark: watchCancelRemark,
      },
    });
  };
  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันการยกเลิกใบเสร็จใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmUpdate()}
          disabled={!watchCancelRemark || loading}
          loading={loading}
        >
          ใช่, ยกเลิกใบเสร็จ
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>เลขที่ใบเสร็จ:</Col>
        <Col span={18}>
          <Text strong>{receiptNumber}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>ประเภทงาน:</Col>
        <Col span={18}>
          <Text strong>{systemRequest}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>รวมมูลค่า:</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(totalReceipt, '0')}
          </Text>
        </Col>
      </Row>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Row>
        <Col span={24}>
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Text>เหตุผลยกเลิกใบเสร็จ</Text>
            </Col>
            <Col span={24}>
              <Form.Item validateStatus={errors.cancelRemark && 'error'}>
                <Controller
                  style={{ width: '100%' }}
                  as={TextArea}
                  name="cancelRemark"
                  control={control}
                  placeholder="ระบุ"
                  rows={4}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmSendEmail;
