/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { ReactNode } from 'react';
import { Select, Empty } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@apollo/react-hooks';
import DATA_MASTER_SERVICE_CATEGORY_QUERY, {
  DataMasterServiceCategoryData,
} from 'shared/graphql/query/dataMasterServiceCategory';

const { Option } = Select;

type SelectDataMasterServiceCategoryFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
};

const defaultProps = {
  disabled: false,
};

const SelectDataMasterServiceCategoryField: React.FC<SelectDataMasterServiceCategoryFieldProps> = (
  props,
) => {
  const { value, onChange, placeholder, disabled } = props;
  const { data, loading } = useQuery<DataMasterServiceCategoryData>(
    DATA_MASTER_SERVICE_CATEGORY_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  let options: ReactNode[] = [];
  if (data?.dataMasterServiceCategory) {
    const { dataMasterServiceCategory } = data;
    options = dataMasterServiceCategory.map((item) => (
      <Option key={uuidv4()} value={item._id}>
        {`${item.service_category_name.th} ${
          item.truck_size_name.th ? `(${item.truck_size_name.th} ${item.truck_type_name.th})` : ''
        }`}
      </Option>
    ));
  }

  return (
    <Select
      style={{
        width: '100%',
      }}
      value={value && options.length > 0 ? value : undefined}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      placeholder={placeholder || 'เลือกประเภทการจ่าย'}
      onChange={(valueChange) => onChange && onChange(valueChange)}
      disabled={disabled}
    >
      {options}
    </Select>
  );
};

SelectDataMasterServiceCategoryField.defaultProps = defaultProps;

export default SelectDataMasterServiceCategoryField;
