import styled from 'styled-components';
import { Modal } from 'antd';
import { Button } from 'shared/components';

export const StyledModal = styled(Modal)`
  &.custom-modal.ant-modal {
    width: 100% !important;
    max-width: 700px;
  }
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
`;
