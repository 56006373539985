export const businessInvoiceType = (value: number | string): string => {
  switch (value) {
    case '':
      return '-';
    case '1' || 1:
      return '1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลทุกสิ้นเดือน';
    case '2' || 2:
      return '1 ใบแจ้งหนี้ ต่อ 1 งาน และวางบิลทุกวันหลังจบงาน';
    case '3' || 3:
      return '1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลตามวันที่กำหนด';
    case '4' || 4:
      return '1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลทุกสิ้นเดือน';
    case '5' || 5:
      return '1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลตามวันที่กำหนด';
    default:
      return '-';
  }
};

export default businessInvoiceType;
