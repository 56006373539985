/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, message, Row, Typography } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { CostingContext } from 'views/Costing/common/context/CostingContext';
import UPDATE_COSTING_MUTATION, {
  UpdateCostingData,
  UpdateCostingVars,
} from 'shared/graphql/mutation/updateCosting';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button } from 'shared/components';
import { TextProfitColor } from 'views/Costing/common/components';
import { useHistory } from 'react-router-dom';
import { StyledModal } from './Styles';

const { Text } = Typography;

type ModalConfirmCheckedProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmChecked: React.FC<ModalConfirmCheckedProps> = (props) => {
  const { onCancel, visible } = props;
  const history = useHistory();
  const { selectedCostingDetail } = useContext(CostingContext);
  const { modalConfirmEdit, costingId, backUrl } = selectedCostingDetail;
  const {
    costingNumber,
    numberRef,
    totalAfterDiscountCost,
    totalAfterDiscountSale,
    profit,
  } = modalConfirmEdit;

  const closeModalHandler = () => {
    history.push(backUrl);
  };

  const [updateCosting, { loading }] = useMutation<UpdateCostingData, UpdateCostingVars>(
    UPDATE_COSTING_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.updateCosting._id) {
          message.success('อนุมัติคำร้องขอแก้ไขแล้ว');
          onCancel();
          closeModalHandler();
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['Costing', 'CostingStatus', 'Costings'],
    },
  );

  const confirmEdit = () => {
    updateCosting({
      variables: {
        _id: costingId,
        costing_status: 4,
        confirm_edit: true,
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="คุณต้องการอนุมัติคำร้องขอแก้ไขใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmEdit()}
          loading={loading}
        >
          ใช่, ต้องการอนุมัติแก้ไข
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={4}>
          <Row>
            <Col span={24}>รหัสต้นทุน/ขาย</Col>
            <Col span={24}>
              <Text strong>{costingNumber}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={5}>
          <Row>
            <Col span={24}>รหัสงานขนส่ง</Col>
            <Col span={24}>
              <Text strong>{numberRef}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={5}>
          <Row>
            <Col span={24}>ต้นทุนก่อนภาษี</Col>
            <Col span={24}>
              <Text strong>{formatCurrency(totalAfterDiscountCost)}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={5}>
          <Row>
            <Col span={24}>รายได้ก่อนภาษี</Col>
            <Col span={24}>
              <Text strong>{formatCurrency(totalAfterDiscountSale)}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={5}>
          <Row>
            <Col span={24}>กำไร - ขาดทุน</Col>
            <Col span={24}>
              <Text strong>
                <TextProfitColor value={profit} />
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmChecked;
