import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';
import { CheckCircleOutlined, StopOutlined, RollbackOutlined } from '@ant-design/icons';
import {
  ApprovePaymentContext,
  SelectedApprovePaymentDetailType,
} from 'views/AccountPayment/common/context/ApprovePaymentContext';
import { AppContext } from 'shared/context/AppContext';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountPayment/Routes/ApprovePayment/AppTable/ModalAppDetail/model/formValues.model';
import { AppStatus } from 'views/AccountPayment/common/model/appStatus.model';
import { StyledButton, TextHeader, Container } from './Styles';
import ModalConfirmUpdate from './ModalConfirmUpdate';
import ModalSavePaid from './ModalSavePaid';
import ModalConfirmUpdateRoundPaid from './ModalConfirmUpdateRoundPaid';
import ModalConfirmCancelRoundPaid from './ModalConfirmCancelRoundPaid';
import ModalConfirmBackStatusRoundPaid from './ModalConfirmBackStatusRoundPaid';

const Header: React.FC = () => {
  const { handleSubmit } = useFormContext<FormValues>();
  const [isOpenModalConfirmUpdateRoundPaid, setOpenModalConfirmUpdateRoundPaid] = useState<boolean>(
    false,
  );
  const [
    isOpenModalBackStatusRoundPaid,
    setOpenModalConfirmBackStatusRoundPaid,
  ] = useState<boolean>(false);
  const [isOpenModalCancelRoundPaid, setOpenModalConfirmCancelRoundPaid] = useState<boolean>(false);
  const [isOpenModalConfirmUpdate, setOpenModalConfirmUpdate] = useState<boolean>(false);
  const [isOpenModalSavePaid, setOpenModalSavePaid] = useState<boolean>(false);
  const { selectedApprovePaymentDetail, setSelectedApprovePaymentDetail } = useContext(
    ApprovePaymentContext,
  );
  const { isLoading, appNumber, appStatus } = selectedApprovePaymentDetail;
  const { userDetail } = useContext(AppContext);
  const { email } = userDetail;
  const supportEmail = [
    'supattra@giztix.com',
    'anusorn.k@giztix.com',
    'lamduan.s@giztix.com',
    'prachpurim.b@giztix.com',
  ];

  const closeModalHandler = () => {
    setSelectedApprovePaymentDetail(
      (prevState): SelectedApprovePaymentDetailType => ({
        ...prevState,
        isOpenModal: false,
      }),
    );
  };

  const onSubmitBackStatusRoundPaidApp = handleSubmit(() => {
    setOpenModalConfirmBackStatusRoundPaid(true);
  });

  const onSubmitCancelRoundPaidApp = handleSubmit(() => {
    setOpenModalConfirmCancelRoundPaid(true);
  });

  const onSubmitUpdateRoundPaidApp = handleSubmit(() => {
    setOpenModalConfirmUpdateRoundPaid(true);
  });

  const onSubmitUpdateApp = handleSubmit(() => {
    setOpenModalConfirmUpdate(true);
  });

  const onSubmitSaveApp = handleSubmit(() => {
    setOpenModalSavePaid(true);
  });

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>{`รหัสรอบจ่าย${appNumber ? `: ${appNumber}` : ''}`}</TextHeader>
        </Col>
        <Col>
          {supportEmail.includes(email) && (
            <>
              {appStatus === AppStatus.PENDING || appStatus === AppStatus.APPROVED ? (
                <StyledButton
                  disabled={isLoading}
                  icon={<StopOutlined />}
                  color="red--light"
                  onClick={onSubmitCancelRoundPaidApp}
                >
                  ยกเลิกรอบจ่าย
                </StyledButton>
              ) : null}

              {appStatus === AppStatus.PAID ? (
                <StyledButton
                  disabled={isLoading}
                  icon={<RollbackOutlined />}
                  color="blue--light"
                  onClick={onSubmitBackStatusRoundPaidApp}
                >
                  ถอยสถานะ
                </StyledButton>
              ) : null}
            </>
          )}

          {appStatus === AppStatus.PENDING && (
            <StyledButton
              disabled={isLoading}
              icon={<CheckCircleOutlined />}
              color="green--light"
              onClick={onSubmitUpdateRoundPaidApp}
            >
              บันทึกรอบจ่าย
            </StyledButton>
          )}

          {appStatus === AppStatus.PENDING && (
            <StyledButton
              disabled={isLoading}
              icon={<CheckCircleOutlined />}
              color="blue"
              onClick={onSubmitUpdateApp}
            >
              อนุมัติจ่าย
            </StyledButton>
          )}

          {appStatus === AppStatus.APPROVED && (
            <StyledButton
              disabled={isLoading}
              icon={<CheckCircleOutlined />}
              color="green"
              onClick={onSubmitSaveApp}
            >
              บันทึกการจ่าย
            </StyledButton>
          )}

          <ModalConfirmCancelRoundPaid
            visible={isOpenModalCancelRoundPaid}
            onCancel={() => setOpenModalConfirmCancelRoundPaid(false)}
          />

          <ModalConfirmBackStatusRoundPaid
            visible={isOpenModalBackStatusRoundPaid}
            onCancel={() => setOpenModalConfirmBackStatusRoundPaid(false)}
          />

          <ModalConfirmUpdateRoundPaid
            visible={isOpenModalConfirmUpdateRoundPaid}
            onCancel={() => setOpenModalConfirmUpdateRoundPaid(false)}
          />

          <ModalConfirmUpdate
            visible={isOpenModalConfirmUpdate}
            onCancel={() => setOpenModalConfirmUpdate(false)}
          />

          <ModalSavePaid
            visible={isOpenModalSavePaid}
            onCancel={() => setOpenModalSavePaid(false)}
          />

          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
