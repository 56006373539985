import styled from 'styled-components';
import { Row, Form } from 'antd';
import { color, mixin } from 'shared/utils/styles';
import { Table } from 'shared/components';

export const StyledTable = styled(Table)`
  & thead > tr > th {
    border-top: 1px solid ${color.border};
  }

  & tr > th.divider,
  tr > td.divider {
    border-right: 1px solid ${color.border};
  }

  ${mixin.backgroundTableOdd}
`;

export const StyledDetailContainer = styled.div`
  border: 1px solid ${color.border};
`;

export const StyledRowDetail = styled(Row)`
  & .ant-col {
    padding: 2px 8px;
  }

  &.divider {
    border-bottom: 1px solid ${color.border};
  }

  & .text-right {
    text-align: right;
  }

  & .column-1 {
    width: 220px;
    maxwidth: 220px;
  }

  & .column-2 {
    width: 145px;
  }

  & .column-3 {
    width: 110px;
  }

  & .column-4 {
    width: 130px;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;
