import React from 'react';
import PropTypes from 'prop-types';
import { SaveStatusType, SaveStatusText } from 'shared/constants/status';
import { StyledTag } from './Styles';

const propTypes = {
  status: PropTypes.number.isRequired,
};

const convertToStatusType = (status) => {
  switch (status) {
    case 1:
      return SaveStatusType.WAITING;
    case 2:
      return SaveStatusType.SAVED;
    default:
      return status;
  }
};

const getTextStatus = (statusName) => {
  return SaveStatusText[statusName];
};

const TagSaveStatus = (props) => {
  const { status, ...otherProps } = props;
  const statusType = convertToStatusType(status);
  const text = getTextStatus(statusType);

  return (
    <StyledTag type={statusType} {...otherProps}>
      {text}
    </StyledTag>
  );
};

TagSaveStatus.propTypes = propTypes;

export default TagSaveStatus;
