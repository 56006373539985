/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import UPDATE_VENDOR_MUTATION, {
  UpdateVendorData,
  UpdateVendorVars,
} from 'shared/graphql/mutation/updateVendor';
import { Button, Text } from 'shared/components';
import { VendorStatus } from 'views/Setting/common/model/vendorStatus.model';
import { useHistory } from 'react-router-dom';
import { StyledModal } from './Styles';

type ModalConfirmDeleteVendorProps = {
  visible: boolean;
  onCancel: () => void;
  vendor: {
    fullName: string;
    id: string;
    number: string;
  };
};

const ModalConfirmDeleteVendor: React.FC<ModalConfirmDeleteVendorProps> = (props) => {
  const { vendor, onCancel, visible } = props;
  const history = useHistory();

  const [updateVendor, { loading: updateVendorLoading }] = useMutation<
    UpdateVendorData,
    UpdateVendorVars
  >(UPDATE_VENDOR_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.updateVendor._id) {
        onCancel();
        message.success('ลบบัญชีเจ้าหนี้แล้ว');
        history.push('/setting/creditor/individual');
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['Vendor'],
  });

  const submitUpdateVendor = () => {
    updateVendor({
      variables: {
        _id: vendor.id,
        vendor_status: VendorStatus.DELETE,
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันการลบบัญชีเจ้าหนี้ - บุคคล ใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<DeleteOutlined />}
          color="red"
          onClick={() => submitUpdateVendor()}
          loading={updateVendorLoading}
        >
          ลบบัญชีเจ้าหนี้
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={updateVendorLoading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>หมายเลขบัญชีเจ้าหนี้:</Col>
        <Col span={18}>
          <Text strong>{vendor.number || '-'}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>ชื่อ - นามสกุล:</Col>
        <Col span={18}>
          <Text strong>{vendor.fullName}</Text>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmDeleteVendor;
