/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext, ReactNode } from 'react';
import { Input, DatePicker, message, Row, Col, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { formatDate, formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import APPS_QUERY, { AppsData, AppsVars } from 'shared/graphql/query/apps';
import {
  ApprovePaymentContext,
  SelectedApprovePaymentDetailType,
} from 'views/AccountPayment/common/context/ApprovePaymentContext';
import {
  Text,
  Button,
  TableSearch,
  TextExpand,
  SelectPaymentVoucherField,
} from 'shared/components';
import { AppStatus } from 'views/AccountPayment/common/model/appStatus.model';
import { ToolsContainer } from './Styles';
import ModalAppDetail from './ModalAppDetail';

const { Option } = Select;
const { RangePicker } = DatePicker;

type AppsTableProps = {
  status: AppStatus;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type AppsDataTable = {
  key: number;
  tools: ReactNode;
  createDate: string;
  appNumber: string;
  paymentType: string;
  roundPaid: string;
  paymentVoucherNumber: ReactNode;
  grandTotalPrice: ReactNode;
  lastUpdate: string;
};

const AppsTable: React.FC<AppsTableProps> = (props) => {
  const { status, pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [createDate, setCreateDate] = useState<string>('');
  const [roundPaid, setRoundPaid] = useState<string>('');
  const [appNumber, setAppNumber] = useState<string>('');
  const [paymentType, setPaymentType] = useState<'OPEX' | 'COGs' | 'Advance' | ''>('');
  const [paymentVoucherId, setPaymentVoucherId] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('');

  const { setSelectedApprovePaymentDetail } = useContext(ApprovePaymentContext);

  const { loading, error, data } = useQuery<AppsData, AppsVars>(APPS_QUERY, {
    variables: {
      create_date: createDate,
      app_number: appNumber,
      page: currentPage,
      showData: pageSize,
      app_status: status,
      payment_type: paymentType || undefined,
      payment_voucher: paymentVoucherId,
      round_paid: roundPaid,
      order_by: orderBy,
      order_type: orderType,
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'วันที่เอกสาร',
      dataIndex: 'createDate',
      sortName: 'create_date',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) => {
            const formatRangDate =
              values === null
                ? ''
                : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

            setCreateDate(formatRangDate);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'รหัสรอบจ่าย',
      dataIndex: 'appNumber',
      sortName: 'app_number',
      width: 150,
      search: (
        <Input
          allowClear
          value={appNumber}
          placeholder="ค้นหา"
          onChange={(event) => setAppNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'ประเภทการจ่าย',
      dataIndex: 'paymentType',
      width: 150,
      search: (
        <Select
          value={paymentType}
          onChange={(value) => setPaymentType(value)}
          style={{ width: '100%' }}
        >
          <Option value="">ทั้งหมด</Option>
          <Option value="OPEX">OPEX</Option>
          <Option value="COGs">COGs</Option>
          <Option value="Advance">Advance</Option>
        </Select>
      ),
    },
    {
      title: 'จำนวนตั้งเบิก (บาท)',
      dataIndex: 'grandTotalPrice',
      width: 150,
      align: 'right',
    },
    {
      title: 'รอบจ่าย',
      dataIndex: 'roundPaid',
      sortName: 'round_paid',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) => {
            const formatRangDate =
              values === null
                ? ''
                : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

            setRoundPaid(formatRangDate);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'รหัสใบสำคัญจ่าย',
      dataIndex: 'paymentVoucherNumber',
      width: 150,
      search: <SelectPaymentVoucherField onChange={(value) => setPaymentVoucherId(value)} />,
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'lastUpdate',
      sortName: 'last_update',
      width: 150,
    },
  ];

  let appsData: AppsDataTable[] = [];
  let totalDocument = 0;

  if (data?.apps) {
    const { apps } = data.apps;
    appsData = apps.map(
      (item, index): AppsDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <Button
              onClick={() => {
                setSelectedApprovePaymentDetail(
                  (prevState): SelectedApprovePaymentDetailType => ({
                    ...prevState,
                    appId: item._id,
                    isOpenModal: true,
                    paymentType: item.payment_type,
                  }),
                );
              }}
              color="grey--text-only"
              icon={<SearchOutlined />}
              size="small"
            />
          </ToolsContainer>
        ),
        createDate: !item.create_date ? '-' : formatDate(item.create_date),
        appNumber: item.app_number || '-',
        paymentType: item.payment_type,
        paymentVoucherNumber: (
          <TextExpand expand={!loading}>
            {item.payment_voucher?.map((voucher) => voucher.voucher_number).join(', ') || '-'}
          </TextExpand>
        ),
        grandTotalPrice: (
          <Text strong customType="primary">
            {formatCurrency(item.grand_total_price)}
          </Text>
        ),
        roundPaid: formatDate(item.round_paid),
        lastUpdate: formatDateTime(item.last_update),
      }),
    );

    totalDocument = data.apps.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={appsData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value: number) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />

      <ModalAppDetail />
    </>
  );
};

export default AppsTable;
