/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useState, useContext } from 'react';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Input, Col, Row, message, Divider } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import {
  CodInContext,
  SelectedCodItemType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/CodInContext';
import UPDATE_COD_ITEM_MUTATION, {
  UpdateCodItemData,
  UpdateCodItemVars,
} from 'shared/graphql/mutation/updateCodItem';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { formatDateTime } from 'shared/utils/dateTime';
import { StyledModal } from './Styles';

const { TextArea } = Input;

type ModalConfirmRejectProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmReject: React.FC<ModalConfirmRejectProps> = (props) => {
  const { onCancel, visible } = props;
  const [rejectRemark, setRejectRemark] = useState<string>('');
  const { selectedCodItem, setSelectedCodItem } = useContext(CodInContext);
  const {
    codItemId,
    customerFullName,
    bookingNumber,
    codInDate,
    vendorFullName,
    totalCod,
  } = selectedCodItem;

  const closeModalHandler = () => {
    setSelectedCodItem(
      (prevState): SelectedCodItemType => ({
        ...prevState,
        isOpenModal: false,
      }),
    );
  };

  const [updateCodItem, { loading }] = useMutation<UpdateCodItemData, UpdateCodItemVars>(
    UPDATE_COD_ITEM_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.updateCodItem._id) {
          message.success('ปฏิเสธรายการยอด COD แล้ว');
          onCancel();
          closeModalHandler();
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['Cod', 'CodStatus', 'Cods'],
    },
  );

  const confirmReject = () => {
    updateCodItem({
      variables: {
        _id: codItemId,
        status: 4,
        reject_remark: rejectRemark,
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="คุณต้องการปฎิเสธ รายการยอด COD ใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="red--light"
          onClick={() => confirmReject()}
          loading={loading}
          disabled={rejectRemark === ''}
        >
          ใช่, ต้องการปฎิเสธ
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
      afterClose={() => setRejectRemark('')}
    >
      <Row gutter={[0, 16]}>
        <Col span={6}>
          <Row>
            <Col span={24}>ชื่อลูกค้า:</Col>
            <Col span={24}>
              <Text strong>{customerFullName}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Col span={24}>Booking Number:</Col>
            <Col span={24}>
              <Text strong>{bookingNumber}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Col span={24}>วันที่แจ้งโอน:</Col>
            <Col span={24}>
              <Text strong>{codInDate === '-' ? '-' : formatDateTime(codInDate)}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Col span={24}>ชื่อ 3PL:</Col>
            <Col span={24}>
              <Text strong>{vendorFullName}</Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <Row>
            <Col span={24}>จำนวนที่ 3PL ต้องโอน (บาท):</Col>
            <Col span={24}>
              <Text strong customType="primary" style={{ fontSize: 24 }}>
                {formatCurrency(totalCod)}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Row>
        <Col span={24}>
          เหตุผลการปฎิเสธ:<Text type="danger">*</Text>
        </Col>
        <Col span={24}>
          <TextArea
            value={rejectRemark}
            onChange={(event) => setRejectRemark(event.target.value)}
            placeholder="ระบุ"
            rows={2}
          />
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmReject;
