/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Row, Col, message, Skeleton } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CUSTOMER_QUERY, { CustomerData, CustomerVars } from 'shared/graphql/query/customer';
import {
  CustomerBankMode,
  CustomerBankPromptPayMode,
  FormValues,
} from 'views/Setting/Routes/Customer/Routes/CustomerDetail/formValues.model';
import Forms from 'views/Setting/Routes/Customer/Routes/CustomerDetail/InputCustomerForm';
import validateSchema from 'views/Setting/Routes/Customer/Routes/CustomerDetail/validateSchema';
import { StyledPageHeader } from './Styles';
import ModalConfirmEdit from './ModalConfirmEdit';

type Params = {
  id: string;
};

const CustomerEdit: React.FC = () => {
  const { id } = useParams<Params>();
  const history = useHistory();

  const [isOpenModalConfirmEdit, setOpenModalConfirmEdit] = useState<boolean>(false);

  const { data, loading, error } = useQuery<CustomerData, CustomerVars>(CUSTOMER_QUERY, {
    variables: {
      _id: id,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = <Skeleton />;

  const methods = useForm<FormValues>({
    mode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver(validateSchema),
    defaultValues: {
      customerId: '',
      from: 1,
      firstName: '',
      lastName: '',
      email: [],
      phone: '',
      taxId: '',
      branch: 'สำนักงานใหญ่',
      address: '',
      province: '',
      district: '',
      subDistrict: '',
      postCode: '',
      businessInvoiceType: 1,
      businessInvoiceDateRangeStart: '',
      businessInvoiceDateRangeEnd: '',
      bankName: '',
      bankBranch: '',
      accountName: '',
      accountNumber: '',
      businessPayRoundRound: '1',
      businessPayRoundRoundDate: '',
      businessPayRoundRoundAfterInvoice: '',
      customerBankMode: CustomerBankMode.BANK_ACCOUNT,
      customerBankCode: '',
      customerBankPromptPayMode: CustomerBankPromptPayMode.TELEPHONE_NUMBER,
      customerBankId: '',
      customerAccountName: '',
      customerAccountNumber: '',
    },
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (!loading && !error && data?.customer) {
      const {
        _id,
        from,
        first_name,
        last_name,
        full_name,
        tax_id,
        branch,
        phone,
        email,
        address,
        district,
        sub_district,
        province,
        postcode,
        business_invoice_type,
        business_invoice_date_range,
        payment_bank_account,
        business_pay_round,
        customer_bank_account,
      } = data.customer;

      reset({
        customerId: _id,
        from: from || 1,
        firstName: first_name || full_name,
        lastName: last_name || '',
        email: email.map((item) => ({ text: item })),
        phone: phone[0],
        taxId: tax_id || '',
        branch: branch || '',
        address: address || '',
        province,
        district,
        subDistrict: sub_district,
        postCode: postcode,
        businessInvoiceType: business_invoice_type || 1,
        businessInvoiceDateRangeStart: business_invoice_date_range.start || '0',
        businessInvoiceDateRangeEnd: business_invoice_date_range.end || '0',
        bankName: payment_bank_account.bank_name || '',
        bankBranch: payment_bank_account.bank_branch || '',
        accountName: payment_bank_account.account_name || '',
        accountNumber: payment_bank_account.account_number || '',
        businessPayRoundRound:
          business_pay_round.round_date && +business_pay_round.round_date === 0 ? '2' : '1',
        businessPayRoundRoundDate: business_pay_round.round_date || '0',
        businessPayRoundRoundAfterInvoice: business_pay_round.after_invoice || '0',
        customerBankMode:
          customer_bank_account?.mode === 3
            ? CustomerBankMode.PROMPT_PAY
            : CustomerBankMode.BANK_ACCOUNT,
        customerBankCode: customer_bank_account?.bank_id?.bank_code,
        customerBankPromptPayMode:
          customer_bank_account?.promptPay?.type || CustomerBankPromptPayMode.TELEPHONE_NUMBER,
        customerBankId: customer_bank_account?.bank_id?._id || '',
        customerAccountName: customer_bank_account?.account_name || '',
        customerAccountNumber: customer_bank_account?.account_number || '',
      });
    }
  }, [error, loading, reset, data?.customer]);

  const submit = handleSubmit(() => {
    setOpenModalConfirmEdit(true);
  });

  if (data?.customer) {
    const { number } = data.customer;

    content = (
      <>
        <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3', marginBottom: 10 }}>
          <Col span={12}>
            <StyledPageHeader onBack={() => history.goBack()} title={number || 'ไม่มีหมายเลข'} />
          </Col>
          <Col span={12}>
            <Row justify="end">
              <Col>
                <Button color="green" onClick={submit}>
                  บันทึก
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <FormProvider {...methods}>
          <Forms />
          <ModalConfirmEdit
            visible={isOpenModalConfirmEdit}
            onCancel={() => setOpenModalConfirmEdit(false)}
          />
        </FormProvider>
      </>
    );
  }

  return content;
};

export default CustomerEdit;
