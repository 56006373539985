import gql from 'graphql-tag';
import { JvStatus } from 'views/Setting/common/model/jvStatus.model';

interface Jvs {
  _id: string;
  jv_number: string;
  jv_status: JvStatus;
  description: string;
  code: string;
  account_name: string;
  value: number;
  document_date: string;
  last_update: string;
}

export interface JvsData {
  jvs: {
    jvs: Jvs[];
    totalDocument: number;
  };
}

export interface JvsVars {
  jv_status?: JvStatus;
  jv_number?: string;
  description?: string;
  code?: string;
  account_name?: string;
  value?: number;
  document_date?: string;
  showData?: number;
  page?: number;
  order_by?: string;
  order_type?: '1' | '-1' | '';
}

export default gql`
  query Jvs(
    $jv_status: Int
    $jv_number: String
    $description: String
    $code: String
    $account_name: String
    $value: Float
    $document_date: String
    $showData: Int
    $page: Int
    $order_by: String
    $order_type: String
  ) {
    jvs(
      params: {
        jv_status: $jv_status
        jv_number: $jv_number
        description: $description
        code: $code
        account_name: $account_name
        value: $value
        document_date: $document_date
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      jvs {
        _id
        jv_number
        jv_status
        description
        code
        account_name
        value
        document_date
        last_update
      }
      totalDocument
    }
  }
`;
