import gql from 'graphql-tag';

export interface FollowDebtHistoryData {
  followDebtHistory: {
    _id: string;
    invoice: {
      _id: string;
      invoice_number: string;
      due_date: string;
      overDueDays: number;
      nextFollowDate: string;
      receipt_date: string;
      payer: {
        _id: string;
        number: string;
        full_name: string;
        business_invoice_type: number;
        business_pay_round: {
          round_date: string;
          after_invoice: string;
        };
        tax_id: string;
        phone: string[];
        email: string[];
        full_address: string;
      };
      create_date: string;
      grand_total_price: number;
    };
    followDetail: {
      _id: string;
      no: number;
      cancelStatus: boolean;
      historyLogs: {
        _id: string;
        followStatus: string;
        followStatusDetail: string;
        action: string;
        actionDetail: string;
        nextfollowDate: string;
        detail: string;
        employee: {
          first_name: string;
        };
        status: number;
        createdAt: string;
        updatedAt: string;
      }[];
    }[];
    sendEmailHistorys: {
      no: number;
      sendDate: string;
      email: string[];
      employee: {
        first_name: string;
      };
    }[];
    createdAt: string;
    updatedAt: string;
  };
}

export interface FollowDebtHistoryVars {
  invoice: string;
}

export default gql`
  query FollowDebtHistory($invoice: ID!) {
    followDebtHistory(invoice: $invoice) {
      _id
      invoice {
        _id
        invoice_number
        due_date
        overDueDays
        nextFollowDate
        receipt_date
        payer {
          _id
          number
          full_name
          business_invoice_type
          business_pay_round {
            round_date
            after_invoice
          }
          tax_id
          phone
          email
          full_address
        }
        create_date
        grand_total_price
      }
      followDetail {
        _id
        no
        cancelStatus
        historyLogs {
          _id
          followStatus
          followStatusDetail
          action
          actionDetail
          nextfollowDate
          detail
          employee {
            first_name
          }
          status
          createdAt
          updatedAt
        }
      }
      sendEmailHistorys {
        no
        sendDate
        email
        employee {
          first_name
        }
      }
      createdAt
      updatedAt
    }
  }
`;
