/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useEffect, useContext, ReactNode } from 'react';
import moment from 'moment';
import { Row, Col, Select, Input, DatePicker, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { formatDate, formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import INVOICES_QUERY, {
  InvoicesData,
  InvoicesVars,
  InvoicesList,
} from 'shared/graphql/query/invoices';
import { InvoiceStatus } from 'views/AccountReceive/common/model/invoiceStatus.model';
import {
  InvoiceContext,
  SelectedInvoicesType,
  SelectedInvoiceDetailType,
} from 'views/AccountReceive/common/context/InvoiceContext';
import { Button, Text, TableSearch, SelectSystemRequestField } from 'shared/components';
import { SelectBusinessTypeField } from 'views/AccountReceive/common/components';
import SelectCustomersField from 'views/AccountReceive/Routes/Invoice/SelectCustomersField';
import SelectEmployeeField from 'views/AccountReceive/Routes/Invoice/SelectEmployeeField';
import { BusinessInvoiceType } from 'views/AccountReceive/common/model/businessInvoiceType.model';
import { ToolsContainer, StyledCheckbox } from './Styles';
import ModalInvoiceDetail from './ModalInvoiceDetail';

const { Option } = Select;
const { RangePicker } = DatePicker;

type InvoicesTableProps = {
  tab: InvoiceStatus;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type InvoicesDataTable = {
  key: number;
  tools: ReactNode;
  draftDateColumn: string;
  serviceDateColumn: string;
  dueDateColumn: string;
  receiptDateColumn: string;
  invoiceNumberColumn: string;
  grandTotalPriceColumn: ReactNode;
  payerFullNameColumn: string;
  businessInvoiceTypeColumn: string;
  systemRequestColumn: string;
  employeeFullNameColumn: string;
  createDateColumn: string;
  lastUpdateDateColumn: string;
};

const InvoicesTable: React.FC<InvoicesTableProps> = (props) => {
  const { tab, pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [draftDate, setDraftDate] = useState('');
  const [dueDate, setDueDate] = useState<string>('');
  const [receiptDate, setReceiptDate] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [systemRequest, setSystemRequest] = useState<string>('');
  const [serviceDate, setServiceDate] = useState<string>('');
  const [createDate, setCreateDate] = useState<string>('');
  const [payerId, setPayerId] = useState<string>('');
  const [employeeId, setEmployeeId] = useState<string>('');
  const [businessInvoiceType, setBusinessInvoiceType] = useState<BusinessInvoiceType>();
  const [businessInvoiceDateRange, setBusinessInvoiceDateRange] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('');

  const { selectedInvoices, setSelectedInvoices, setSelectedInvoiceDetail } = useContext(
    InvoiceContext,
  );

  useEffect(() => {
    if (selectedInvoices.invoicesId.length === 0) {
      setSelectedInvoices(
        (prevState): SelectedInvoicesType => ({
          ...prevState,
          payerId: '',
          dueDate: '',
        }),
      );
    }
  }, [selectedInvoices.invoicesId.length, setSelectedInvoices]);

  const { loading, error, data } = useQuery<InvoicesData, InvoicesVars>(INVOICES_QUERY, {
    variables: {
      service_date: serviceDate,
      invoice_number: invoiceNumber,
      payer: payerId,
      employee: employeeId,
      system_request: systemRequest,
      due_date: dueDate,
      invoice_status: tab,
      business_invoice_type: businessInvoiceType,
      business_invoice_date_range: +businessInvoiceDateRange,
      page: currentPage,
      showData: pageSize,
      order_by: orderBy,
      order_type: orderType,
      draft_date: draftDate,
      receipt_date: receiptDate,
      create_date: createDate,
    },
    fetchPolicy: 'cache-and-network',
  });

  const onCheckAllChange = (check: boolean) => {
    if (data) {
      const selectedInvoiceId = selectedInvoices.invoicesId.map((item) => item);
      const currentInvoiceId = data.invoices.invoices.map((item) => item._id);
      const differenceInvoice = _.differenceWith(currentInvoiceId, selectedInvoiceId, _.isEqual);

      if (check) {
        setSelectedInvoices(
          (prevState): SelectedInvoicesType => ({
            ...prevState,
            invoicesId: [...selectedInvoiceId, ...differenceInvoice],
          }),
        );
      }

      if (!check) {
        const filterInvoiceId = selectedInvoiceId.filter(
          (item) => !currentInvoiceId.includes(item),
        );

        setSelectedInvoices(
          (prevState): SelectedInvoicesType => ({
            ...prevState,
            invoicesId: filterInvoiceId,
          }),
        );
      }
    }
  };

  const selectInvoices = (_item: InvoicesList, checked: boolean, value: string) => {
    if (checked) {
      if (selectedInvoices.invoicesId.length === 0) {
        setSelectedInvoices(
          (prevState): SelectedInvoicesType => ({
            ...prevState,
            invoicesId: [],
          }),
        );
      }

      setSelectedInvoices(
        (prevState): SelectedInvoicesType => ({
          ...prevState,
          invoicesId: [...prevState.invoicesId, value],
        }),
      );
    } else {
      const invoicesIdFilter = selectedInvoices.invoicesId.filter(
        (invoiceId) => invoiceId !== value,
      );
      setSelectedInvoices(
        (prevState): SelectedInvoicesType => ({
          ...prevState,
          invoicesId: [...invoicesIdFilter],
        }),
      );
    }
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
      search: (tab === InvoiceStatus.INVOICE ||
        tab === InvoiceStatus.BILLING_NOTE ||
        tab === InvoiceStatus.CANCEL) && (
        <StyledCheckbox
          onChange={(event) => onCheckAllChange(event.target.checked)}
          checked={data?.invoices.invoices.every(
            (item) => selectedInvoices.invoicesId.indexOf(item._id) !== -1,
          )}
          value={selectedInvoices.invoicesId}
        />
      ),
    },
    ...(tab === 2 || tab === 3 || tab === 4 || tab === 6
      ? [
          {
            title: 'วันที่เอกสาร',
            dataIndex: 'serviceDateColumn',
            sortName: 'service_date',
            width: 160,
            search: (
              <RangePicker
                onChange={(values) =>
                  values === null
                    ? setServiceDate('')
                    : setServiceDate(
                        `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                      )
                }
                format="YYYY/MM/DD"
                placeholder={['เริ่มต้น', 'สิ้นสุด']}
              />
            ),
          },
        ]
      : []),
    ...(tab === 1
      ? [
          {
            title: 'วันที่สร้างแบบร่าง',
            dataIndex: 'draftDateColumn',
            sortName: 'draft_date',
            width: 160,
            search: (
              <RangePicker
                onChange={(values) =>
                  values === null
                    ? setDraftDate('')
                    : setDraftDate(
                        `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                      )
                }
                format="YYYY/MM/DD"
                placeholder={['เริ่มต้น', 'สิ้นสุด']}
              />
            ),
          },
        ]
      : []),
    {
      title: tab === 1 ? 'รหัสแบบร่าง' : 'รหัสใบแจ้งหนี้',
      dataIndex: 'invoiceNumberColumn',
      sortName: 'invoice_number',
      width: 150,
      search: (
        <Input
          allowClear
          value={invoiceNumber}
          placeholder="ค้นหา"
          onChange={(event) => setInvoiceNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'มูลค่าใบแจ้งหนี้',
      dataIndex: 'grandTotalPriceColumn',
      width: 100,
      align: 'right',
    },
    ...(tab === 2 || tab === 3 || tab === 4 || tab === 6
      ? [
          {
            title: 'วันที่ครบกำหนดชำระ',
            dataIndex: 'dueDateColumn',
            sortName: 'due_date',
            width: 160,
            search: (
              <RangePicker
                onChange={(values) =>
                  values === null
                    ? setDueDate('')
                    : setDueDate(
                        `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                      )
                }
                format="YYYY/MM/DD"
                placeholder={['เริ่มต้น', 'สิ้นสุด']}
              />
            ),
          },
        ]
      : []),
    ...(tab === 4
      ? [
          {
            title: 'วันที่รับชำระ',
            dataIndex: 'receiptDateColumn',
            sortName: 'receipt_date',
            width: 160,
            search: (
              <RangePicker
                onChange={(values) =>
                  values === null
                    ? setReceiptDate('')
                    : setReceiptDate(
                        `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                      )
                }
                format="YYYY/MM/DD"
                placeholder={['เริ่มต้น', 'สิ้นสุด']}
              />
            ),
          },
        ]
      : []),
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'payerFullNameColumn',
      width: 300,
      search: <SelectCustomersField onChange={(value: string) => setPayerId(value)} />,
    },
    {
      title: 'รูปแบบรับใบแจ้งหนี้',
      dataIndex: 'businessInvoiceTypeColumn',
      width: 300,
      search: (
        <SelectBusinessTypeField
          onChange={(value) => {
            setBusinessInvoiceType(value.businessInvoiceType);
            setBusinessInvoiceDateRange(value.businessDate);
          }}
        />
      ),
    },
    {
      title: 'ประเภทงาน',
      dataIndex: 'systemRequestColumn',
      width: 200,
      search: (
        <SelectSystemRequestField
          onChange={(value) => {
            setSystemRequest(value);
          }}
        />
      ),
    },
    {
      title: 'ผู้สร้าง',
      dataIndex: 'employeeFullNameColumn',
      width: 250,
      search: <SelectEmployeeField onChange={(value: string) => setEmployeeId(value)} />,
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'createDateColumn',
      sortName: 'create_date',
      width: 200,
      search: (
        <RangePicker
          onChange={(values) =>
            values === null
              ? setCreateDate('')
              : setCreateDate(
                  `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                )
          }
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
          ranges={{
            วันนี้: [moment(), moment()],
            สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
            เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
            ปีนี้: [moment().startOf('year'), moment().endOf('year')],
          }}
        />
      ),
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'lastUpdateDateColumn',
      sortName: 'last_update',
      width: 150,
    },
  ];

  let invoicesData: InvoicesDataTable[] = [];
  let totalDocument = 0;

  if (data && data.invoices) {
    const { invoices } = data.invoices;
    invoicesData = invoices.map(
      (item, index): InvoicesDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            {(tab === InvoiceStatus.INVOICE ||
              tab === InvoiceStatus.BILLING_NOTE ||
              tab === InvoiceStatus.CANCEL) && (
              <StyledCheckbox
                checked={selectedInvoices.invoicesId.indexOf(item._id) !== -1}
                value={item._id}
                onChange={(event) => selectInvoices(item, event.target.checked, event.target.value)}
              />
            )}
            <Button
              onClick={() => {
                setSelectedInvoiceDetail(
                  (prevState): SelectedInvoiceDetailType => ({
                    ...prevState,
                    invoiceId: item._id,
                    isOpenModal: true,
                    invoiceNumber: item.invoice_number,
                  }),
                );
              }}
              color="grey--text-only"
              icon={<SearchOutlined />}
              size="small"
            />
          </ToolsContainer>
        ),
        draftDateColumn: !item.draft_date ? '-' : formatDate(item.draft_date),
        serviceDateColumn: !item.service_date ? '-' : formatDate(item.service_date),
        invoiceNumberColumn: item.invoice_number || '-',
        grandTotalPriceColumn: (
          <Text strong customType="primary">
            {formatCurrency(item.grand_total_price)}
          </Text>
        ),
        dueDateColumn: !item.due_date ? '-' : formatDate(item.due_date),
        receiptDateColumn: !item.receipt_date ? '-' : formatDate(item.receipt_date),
        payerFullNameColumn: item.payer.full_name || '-',
        businessInvoiceTypeColumn: item.business_invoice_type || '-',
        systemRequestColumn: item.system_request || '-',
        employeeFullNameColumn: item.employee.full_name || '-',
        createDateColumn: formatDateTime(item.create_date) || '-',
        lastUpdateDateColumn: formatDateTime(item.last_update) || '-',
      }),
    );

    totalDocument = data.invoices.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={invoicesData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value: number) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />

      <ModalInvoiceDetail />
    </>
  );
};

export default InvoicesTable;
