import styled from 'styled-components';
import { Layout } from 'antd';

const { Sider } = Layout;

export const StyledLayout = styled.div`
  .ant-menu-inline > .ant-menu-item {
    line-height: 42px;
  }

  .ant-menu-inline-collapsed > .ant-menu-item .anticon {
    line-height: 50px;
  }

  .ant-menu-item .anticon {
    margin-right: 16px;
  }
`;

export const StyledSider = styled(Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
`;

export const Logo = styled.div`
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
`;

export const VersionContainer = styled.div`
  position: absolute;
  bottom: 5px;
  color: #ffffffa6;
  font-size: 18px;
  text-align: center;
  width: 100%;

  ${(props) =>
    !props.collapsed &&
    `
    text-align: right;
    padding-right: 10px;
  `}
`;
