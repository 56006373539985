import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';
import { PaymentOrderContext } from 'views/AccountPayment/common/context/PaymentOrderContext';
import { Container, StyledButton, TextHeader } from './Styles';

const Header = () => {
  const { selectedPayments: selectedPaymentsContext } = useContext(PaymentOrderContext);
  const [selectedPayments, setSelectedPayments] = selectedPaymentsContext;
  const { handleSubmit } = useFormikContext();

  const closeModalHandler = () => {
    setSelectedPayments((prevState) => ({
      ...prevState,
      isOpenModal: false,
    }));
  };

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>สร้างใบสำคัญจ่าย</TextHeader>
        </Col>
        <Col>
          <StyledButton
            disabled={selectedPayments.isLoading}
            icon={<CheckCircleOutlined />}
            type="green"
            onClick={handleSubmit}
          >
            สร้างใบสำคัญจ่าย
          </StyledButton>
          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
