/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { formatDateTime } from 'shared/utils/dateTime';
import UPDATE_PAYMENT_COD_MUTATION, {
  UpdatePaymentCodData,
  UpdatePaymentCodVars,
} from 'shared/graphql/mutation/updatePaymentCod';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { StyledModal } from './Styles';

type ModalConfirmDeleteCodProps = {
  paymentCodId: string;
  codsId: string[];
  cod: {
    customerFullName: string;
    codInNumber: string;
    pickUpDate: string;
    bookingNumber: string;
    totalCod: number;
  };
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmDeleteCod: React.FC<ModalConfirmDeleteCodProps> = (props) => {
  const { onCancel, visible, codsId, cod, paymentCodId } = props;
  const [updatePaymentCod, { loading }] = useMutation<UpdatePaymentCodData, UpdatePaymentCodVars>(
    UPDATE_PAYMENT_COD_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.updatePaymentCod._id) {
          message.success('ลบรายการ COD แล้ว');
          onCancel();
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['PaymentCod'],
    },
  );

  const confirmUpdate = () => {
    updatePaymentCod({
      variables: {
        _id: paymentCodId,
        cod: codsId,
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันลบรายการ COD หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<DeleteOutlined />}
          color="red"
          onClick={() => confirmUpdate()}
          loading={loading}
        >
          ลบรายการ COD
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Col span={24}>
        <Row gutter={[8, 0]}>
          <Col span={4}>ชื่อลูกค้า</Col>
          <Col span={5}>รหัส COD-IN</Col>
          <Col span={5}>วันที่รับสินค้า</Col>
          <Col span={5}>Booking Number</Col>
          <Col span={4}>ยอดรวม COD (บาท)</Col>
        </Row>

        <Row gutter={[8, 0]} key={cod.codInNumber}>
          <Col span={4}>
            <Text strong>{cod.customerFullName || '-'}</Text>
          </Col>
          <Col span={5}>
            <Text strong>{cod.codInNumber || '-'}</Text>
          </Col>
          <Col span={5}>
            <Text strong>{cod.pickUpDate ? formatDateTime(cod.pickUpDate) : '-'}</Text>
          </Col>
          <Col span={5}>
            <Text strong>{cod.bookingNumber || '-'}</Text>
          </Col>
          <Col span={4}>
            <Text strong customType="primary">
              {formatCurrency(cod.totalCod)}
            </Text>
          </Col>
        </Row>
      </Col>
    </StyledModal>
  );
};

export default ModalConfirmDeleteCod;
