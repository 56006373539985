/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { Button } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledPageHeader } from './Styles';
import { FormValues } from '../model/formValues.model';
import Forms from '../Forms';
import validateSchema from '../validateSchema';
import ModalConfirmCreateVendor from './ModalConfirmCreateVendor';

const CreateIndividual: React.FC = () => {
  const history = useHistory();
  const [isOpenModalConfirmCreate, setOpenModalConfirmCreate] = useState<boolean>(false);

  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validateSchema),
    defaultValues: {
      fullName: '',
      systemRequest: '',
      taxId: '',
      address: '',
      country: 'ไทย',
      province: '',
      district: '',
      subDistrict: '',
      postCode: '',
      phone: '',
      staffPhone: '',
      email: [],
      docAddress: '',
      docTaxId: '',
      docProvince: '',
      docDistrict: '',
      docSubDistrict: '',
      docPostcode: '',
      bankName: '',
      bankBranch: '',
      accountName: '',
      accountNumber: '',
      chkSelectAddress: false,
    },
  });

  const { handleSubmit } = methods;

  const submitCreateVendor = handleSubmit(() => {
    setOpenModalConfirmCreate(true);
  });

  return (
    <>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3', marginBottom: 10 }}>
        <Col span={12}>
          <StyledPageHeader onBack={() => history.goBack()} title="เพิ่มบัญชีเจ้าหนี้บุคคล" />
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Col>
              <Button color="green" onClick={submitCreateVendor}>
                สร้างบัญชีเจ้าหนี้
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <FormProvider {...methods}>
        <Forms />
        <ModalConfirmCreateVendor
          visible={isOpenModalConfirmCreate}
          onCancel={() => setOpenModalConfirmCreate(false)}
        />
      </FormProvider>
    </>
  );
};

export default CreateIndividual;
