import gql from 'graphql-tag';
import { CodParcelOrderStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codParcelOrderStatus.model';

export interface ParcelOrderData {
  parcelOrder: {
    _id: string;
    cod: string;
    cod_in_number: string;
    cod_status: CodParcelOrderStatus;
    vendor: {
      _id: string;
      full_name: string;
    };
    shipment_number: string;
    booking_number: string;
    tracking_number: string;
    customer: {
      full_name: string;
    };
    delivered_date: string;
    cod_price: number;
    cod_in_date: string;
    cod_remark: string;
  };
}

export interface ParcelOrderVars {
  _id: string;
}

export default gql`
  query ParcelOrder($_id: ID) {
    parcelOrder(_id: $_id) {
      _id
      cod
      cod_in_number
      cod_status
      vendor {
        _id
        full_name
      }
      shipment_number
      booking_number
      tracking_number
      customer {
        full_name
      }
      delivered_date
      cod_price
      cod_in_date
      cod_remark
    }
  }
`;
