import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { buildDateFormat, buildDateFromNow } from 'shared/utils/buildDate';
import Logo from 'shared/components/Logo';
import version from 'shared/config/version';
import Header from './Header';
import LinkMenu from './LinkMenu';
import { StyledLayout, StyledSider, VersionContainer } from './Styles';

const { Content } = Layout;

class LayoutContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: !!localStorage.getItem('collapsed'),
    };
  }

  toggle = () => {
    const { collapsed } = this.state;
    if (collapsed) {
      localStorage.removeItem('collapsed');
    } else {
      localStorage.setItem('collapsed', true);
    }

    this.setState({
      collapsed: !collapsed,
    });
  };

  selectMenu = (menu) => {
    const { history } = this.props;
    history.push(menu.key);
  };

  render() {
    const { collapsed } = this.state;
    const { history, children, textHeader } = this.props;

    return (
      <StyledLayout>
        <Layout>
          <StyledSider
            trigger={null}
            collapsible
            collapsed={collapsed}
            collapsedWidth={54}
            width={230}
          >
            <Logo clicked={() => history.push('/')} size={collapsed ? 'small' : undefined} />
            <LinkMenu />
            <VersionContainer collapsed={collapsed}>
              v{version}
              {process.env.REACT_APP_STAGE === 'development' && (
                <>
                  <div>{`(${collapsed ? 'DEV' : 'Mode: Development'})`}</div>
                  {!collapsed && (
                    <div title={buildDateFormat}>{`Build Date: ${buildDateFromNow}`}</div>
                  )}
                </>
              )}
            </VersionContainer>
          </StyledSider>
          <Layout style={{ marginLeft: collapsed ? 54 : 230 }}>
            <Header textHeader={textHeader} collapsed={collapsed} toggle={this.toggle} />
            <Content style={{ background: '#fff' }}>{children}</Content>
          </Layout>
        </Layout>
      </StyledLayout>
    );
  }
}

export default withRouter(LayoutContainer);
