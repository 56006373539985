/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Tabs } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { Button } from 'shared/components';
import moment from 'moment';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormValues } from 'views/Setting/Routes/ChartOfAccounts/JV/JvDetail/model/formValues.model';
import { color } from 'shared/utils/styles';
import { Container } from './Styles';
import Forms from '../Forms';
import validateSchema from '../validateSchema';
import ModalConfirmCreate from './ModalConfirmCreate';
import ModalConfirmCancel from './ModalConfirmCancel';

const { TabPane } = Tabs;

const CreateJv: React.FC = () => {
  const [isOpenModalConfirmCreate, setOpenModalConfirmCreate] = useState<boolean>(false);
  const [isOpenModalConfirmCancel, setOpenModalConfirmCancel] = useState<boolean>(false);
  const [isItem, setIsItem] = useState<boolean>(false);
  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validateSchema),
    defaultValues: {
      description: '',
      documentDate: moment().format('YYYY/MM/DD'),
      chartOfAccountList: [],
    },
  });

  const { handleSubmit } = methods;

  const submitCreate = handleSubmit(() => {
    setOpenModalConfirmCreate(true);
  });

  return (
    <Container>
      <Tabs
        defaultActiveKey="1"
        animated={false}
        tabBarExtraContent={
          <>
            <Button
              disabled={!isItem}
              color="green"
              onClick={submitCreate}
              style={{ marginRight: 10 }}
            >
              สร้าง
            </Button>{' '}
            <Button onClick={() => setOpenModalConfirmCancel(true)}>ยกเลิก</Button>
          </>
        }
      >
        <TabPane
          tab={
            <div style={{ display: 'flex', lineHeight: 1 }}>
              <FileOutlined
                style={{
                  color: color.primary,
                  marginRight: 5,
                  fontWeight: 'bold',
                }}
              />
              <div style={{ fontWeight: 'bold' }}>สร้างรายการ JV</div>
            </div>
          }
          key="1"
        >
          <FormProvider {...methods}>
            <Forms isItem={(value) => (value ? setIsItem(true) : setIsItem(false))} />
            <ModalConfirmCreate
              visible={isOpenModalConfirmCreate}
              onCancel={() => setOpenModalConfirmCreate(false)}
            />
            <ModalConfirmCancel
              visible={isOpenModalConfirmCancel}
              onCancel={() => setOpenModalConfirmCancel(false)}
            />
          </FormProvider>
        </TabPane>
      </Tabs>
    </Container>
  );
};

export default CreateJv;
