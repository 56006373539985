import styled from 'styled-components';
import { Tag } from 'antd';
import { font } from 'shared/utils/styles';

export const TextHeader = styled.div`
  ${font.size(24)};
  ${font.medium};
  margin-bottom: 10px;
`;

export const StyledTag = styled(Tag)`
  &.ant-tag {
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 8px;
  }
`;
