import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import { StyledButton } from './Styles';

export type ButtonType =
  | 'green'
  | 'green--outline'
  | 'green--light'
  | 'red'
  | 'red--light'
  | 'red--outline'
  | 'grey--text-only'
  | 'blue'
  | 'blue--outline'
  | 'blue--light'
  | undefined;

type CustomProps = {
  color?: ButtonType;
} & ButtonProps;

const Button: React.FC<CustomProps> = (props) => {
  const { children, color, ...otherProps } = props;

  return (
    <StyledButton color={color} {...otherProps}>
      {children}
    </StyledButton>
  );
};

export default Button;
