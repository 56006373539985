import React from 'react';
import formatCurrency from 'shared/utils/formatCurrency';
import { InvoiceList } from 'shared/graphql/query/invoice';
import { StyledTable } from './Styles';

type ExpandedRowProps = {
  data: InvoiceList[];
};

const columns = [
  {
    title: 'รหัสบริการ',
    dataIndex: 'column1',
    width: 130,
  },
  {
    title: 'รายละเอียดบริการ',
    dataIndex: 'column2',
  },
  {
    title: 'จำนวน',
    dataIndex: 'column3',
    width: 100,
    align: 'right',
  },
  {
    title: 'ราคาต่อหน่วย',
    dataIndex: 'column4',
    width: 110,
    align: 'right',
  },
  {
    title: 'ราคารวม',
    dataIndex: 'column5',
    width: 110,
    align: 'right',
    className: 'divider',
  },
  {
    title: 'ส่วนลด',
    dataIndex: 'column6',
    width: 110,
    align: 'right',
  },
  {
    title: 'รวมหลังส่วนลด',
    dataIndex: 'column7',
    width: 120,
    align: 'right',
    className: 'divider',
  },
  {
    title: 'เรียกคืน',
    dataIndex: 'column8',
    width: 145,
    align: 'right',
    fixed: 'right',
  },
  {
    title: 'ยกเว้นภาษี',
    dataIndex: 'column9',
    width: 110,
    align: 'right',
    fixed: 'right',
  },
  {
    title: 'ภาษีมูลค่าเพิ่ม',
    dataIndex: 'column10',
    width: 130,
    align: 'right',
    fixed: 'right',
  },
];

const ExpandedRow: React.FC<ExpandedRowProps> = (props) => {
  const { data } = props;
  const invoiceList = data.map((item, index) => {
    return {
      key: index,
      column1: item.list_number,
      column2: item.list_name,
      column3: item.qty,
      column4: formatCurrency(item.price),
      column5: formatCurrency(item.total_before_discount),
      column6: formatCurrency(item.discount),
      column7: formatCurrency(item.total_price),
      column8:
        item.advance_type && item.vat === 0 && item.wht === 0
          ? formatCurrency(item.total_price)
          : '-',
      column9: !item.advance_type && item.vat === 0 ? formatCurrency(item.total_price) : '-',
      column10: !item.advance_type && item.vat !== 0 ? formatCurrency(item.total_price) : '-',
    };
  });

  return (
    <div style={{ marginTop: 10 }}>
      <StyledTable
        scroll={{ x: 'max-content' }}
        pagination={false}
        columns={columns}
        dataSource={invoiceList}
        size="small"
      />
    </div>
  );
};

export default ExpandedRow;
