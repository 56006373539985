/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext, ReactNode } from 'react';
import { Input, DatePicker, message, Row, Col, Select } from 'antd';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { formatDate, formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import { Text, Button, TableSearch } from 'shared/components';
import RECEIPTS_QUERY, { ReceiptsData, ReceiptsVars, Receipt } from 'shared/graphql/query/receipts';
import {
  ReceiptContext,
  SelectedReceiptDetailType,
  SelectedReceiptsType,
} from 'views/AccountReceive/common/context/ReceiptContext';
import { SelectCustomersField, SelectEmployeeField } from 'views/AccountReceive/common/components';
import { ReceiptStatus } from 'views/AccountReceive/common/model/receiptStatus.model';
import SelectBillingNoteField from 'views/AccountReceive/Routes/Receipt/SelectBillingNoteField';
import SelectInvoiceField from 'views/AccountReceive/Routes/Receipt/SelectInvoiceField';
import ModalReceiptDetail from './ModalReceiptDetail';
import { ToolsContainer, StyledCheckbox } from './Styles';

const { Option } = Select;
const { RangePicker } = DatePicker;

type ReceiptsTableProps = {
  status: ReceiptStatus;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type ReceiptsDataTable = {
  key: number;
  tools: ReactNode;
  receiptDate: string;
  receiptNumber: string;
  totalReceipt: ReactNode;
  payerFullName: string;
  billNumber: string;
  invoiceNumber: string;
  employeeFullName: string;
  createDate: string;
  lastUpdate: string;
};

const ReceiptsTable: React.FC<ReceiptsTableProps> = (props) => {
  const { status, pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [receiptDate, setReceiptDate] = useState<string>('');
  const [receiptNumber, setReceiptNumber] = useState<string>('');
  const [billingNoteId, setBillingNoteId] = useState<string>('');
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [payerId, setPayerId] = useState<string>('');
  const [employeeId, setEmployeeId] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('');
  const [createDate, setCreateDate] = useState<string>('');

  const { setSelectedReceiptDetail, selectedReceipts, setSelectedReceipts } = useContext(
    ReceiptContext,
  );

  const { loading, error, data } = useQuery<ReceiptsData, ReceiptsVars>(RECEIPTS_QUERY, {
    variables: {
      receipt_date: receiptDate,
      receipt_number: receiptNumber,
      payer: payerId,
      employee: employeeId,
      billing_note: billingNoteId,
      invoice: invoiceId,
      page: currentPage,
      showData: pageSize,
      receipt_status: status,
      order_by: orderBy,
      order_type: orderType,
      create_date: createDate,
    },
    fetchPolicy: 'cache-and-network',
  });

  const onCheckAllChange = (check: boolean) => {
    if (data) {
      const selectedReceiptId = selectedReceipts.receiptsId.map((item) => item);
      const currentReceiptId = data.receipts.receipts.map((item) => item._id);
      const differenceReceipt = _.differenceWith(currentReceiptId, selectedReceiptId, _.isEqual);

      if (check) {
        setSelectedReceipts(
          (prevState): SelectedReceiptsType => ({
            ...prevState,
            receiptsId: [...selectedReceiptId, ...differenceReceipt],
          }),
        );
      }

      if (!check) {
        const filterReceiptId = selectedReceiptId.filter(
          (item) => !currentReceiptId.includes(item),
        );

        setSelectedReceipts(
          (prevState): SelectedReceiptsType => ({
            ...prevState,
            receiptsId: filterReceiptId,
          }),
        );
      }
    }
  };

  const selectReceipts = (_item: Receipt, checked: boolean, value: string) => {
    if (checked) {
      if (selectedReceipts.receiptsId.length === 0) {
        setSelectedReceipts(
          (prevState): SelectedReceiptsType => ({
            ...prevState,
            receiptsId: [],
          }),
        );
      }

      setSelectedReceipts(
        (prevState): SelectedReceiptsType => ({
          ...prevState,
          receiptsId: [...prevState.receiptsId, value],
        }),
      );
    } else {
      const receiptsIdFilter = selectedReceipts.receiptsId.filter(
        (receiptsId) => receiptsId !== value,
      );
      setSelectedReceipts(
        (prevState): SelectedReceiptsType => ({
          ...prevState,
          receiptsId: [...receiptsIdFilter],
        }),
      );
    }
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
      search: status === ReceiptStatus.COMPLETE && (
        <StyledCheckbox
          onChange={(event) => onCheckAllChange(event.target.checked)}
          checked={data?.receipts.receipts.every(
            (item) => selectedReceipts.receiptsId.indexOf(item._id) !== -1,
          )}
          value={selectedReceipts.receiptsId}
        />
      ),
    },
    {
      title: 'วันที่เอกสาร',
      dataIndex: 'receiptDate',
      sortName: 'receipt_date',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) => {
            if (values === null) {
              setReceiptDate('');
              return;
            }

            setReceiptDate(`${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'รหัสใบเสร็จ',
      dataIndex: 'receiptNumber',
      sortName: 'receipt_number',
      width: 150,
      search: (
        <Input
          allowClear
          value={receiptNumber}
          placeholder="ค้นหา"
          onChange={(event) => setReceiptNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'มูลค่าใบเสร็จ',
      dataIndex: 'totalReceipt',
      width: 150,
      align: 'right',
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'payerFullName',
      width: 300,
      search: <SelectCustomersField onChange={(value: string) => setPayerId(value)} />,
    },
    {
      title: 'รหัสใบวางบิล',
      dataIndex: 'billNumber',
      sortName: 'bill_number',
      width: 150,
      search: <SelectBillingNoteField onChange={(value: string) => setBillingNoteId(value)} />,
    },
    {
      title: 'รหัสใบแจ้งหนี้',
      dataIndex: 'invoiceNumber',
      sortName: 'invoice_number',
      width: 150,
      search: <SelectInvoiceField onChange={(value: string) => setInvoiceId(value)} />,
    },
    {
      title: 'ผู้สร้าง',
      dataIndex: 'employeeFullName',
      width: 250,
      search: <SelectEmployeeField onChange={(value: string) => setEmployeeId(value)} />,
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'createDate',
      sortName: 'create_date',
      width: 200,
      search: (
        <RangePicker
          onChange={(values) =>
            values === null
              ? setCreateDate('')
              : setCreateDate(
                  `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                )
          }
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
          ranges={{
            วันนี้: [moment(), moment()],
            สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
            เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
            ปีนี้: [moment().startOf('year'), moment().endOf('year')],
          }}
        />
      ),
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'lastUpdate',
      sortName: 'last_update',
      width: 150,
    },
  ];

  let receiptsDataSource: ReceiptsDataTable[] = [];
  let totalDocument = 0;

  if (data && data.receipts) {
    const { receipts } = data.receipts;
    receiptsDataSource = receipts.map(
      (item, index): ReceiptsDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            {item.receipt_status === ReceiptStatus.COMPLETE && (
              <StyledCheckbox
                checked={selectedReceipts.receiptsId.indexOf(item._id) !== -1}
                value={item._id}
                onChange={(event) => selectReceipts(item, event.target.checked, event.target.value)}
              />
            )}
            <Button
              onClick={() => {
                setSelectedReceiptDetail(
                  (prevState): SelectedReceiptDetailType => ({
                    ...prevState,
                    receiptId: item._id,
                    isOpenModal: true,
                  }),
                );
              }}
              color="grey--text-only"
              icon={<SearchOutlined />}
              size="small"
            />
          </ToolsContainer>
        ),
        receiptDate: !item.receipt_date ? '-' : formatDate(item.receipt_date),
        receiptNumber: item.receipt_number || '-',
        totalReceipt: (
          <Text strong customType="primary">
            {formatCurrency(item.total_receipt)}
          </Text>
        ),
        payerFullName: item.payer.full_name || '-',
        billNumber: item.billing_note.bill_number || '-',
        invoiceNumber: item.invoice.invoice_number || '-',
        employeeFullName: item.employee.full_name || '-',
        createDate: item.create_date ? formatDateTime(item.create_date) : '-',
        lastUpdate: item.last_update ? formatDateTime(item.last_update) : '-',
      }),
    );

    totalDocument = data.receipts.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={receiptsDataSource}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value: number) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />

      <ModalReceiptDetail />
    </>
  );
};

export default ReceiptsTable;
