import gql from 'graphql-tag';

export interface CreatePaymentVoucherData {
  createPaymentVoucher: {
    _id: string;
  };
}

export interface CreatePaymentVoucherVars {
  voucher_status?: number;
  payee?: string;
  due_date?: string;
  payment_method?: string;
  payment?: string[];
  payment_type: string;
  voucher_remark?: string;
  bank_name?: string;
  bank_branch?: string;
  account_name?: string;
  account_number?: string;
}

export default gql`
  mutation createPaymentVoucher(
    $voucher_status: Int!
    $payee: ID!
    $due_date: String!
    $payment_method: String!
    $payment: [ID]!
    $payment_type: String!
    $voucher_remark: String
    $bank_name: String
    $bank_branch: String
    $account_name: String
    $account_number: String
  ) {
    createPaymentVoucher(
      createPaymentVoucher: {
        voucher_status: $voucher_status
        payee: $payee
        due_date: $due_date
        payment_method: $payment_method
        payment: $payment
        payment_type: $payment_type
        voucher_remark: $voucher_remark
        bank_account: {
          bank_name: $bank_name
          bank_branch: $bank_branch
          account_name: $account_name
          account_number: $account_number
        }
      }
    ) {
      _id
    }
  }
`;
