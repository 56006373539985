/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Select, message, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import EMPLOYEES_QUERY, { EmployeesData, Employees } from 'shared/graphql/query/employees';

const { Option } = Select;

type SelectDepartmentFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
};

const defaultProps = {
  disabled: false,
};

const SelectDepartmentField: React.FC<SelectDepartmentFieldProps> = (props) => {
  const { value, onChange, disabled, style } = props;
  // const [department, setDepartment] = useState<string>('');
  const [employees, setEmployees] = useState<Employees[]>([]);
  const { data, loading, error } = useQuery<EmployeesData>(EMPLOYEES_QUERY, {
    variables: {
      showData: 1000,
      page: 1,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && data?.employees) {
      setEmployees([...data.employees.employees]);
    }
  }, [data?.employees, error, loading]);

  const uniqDepartment = _.uniqBy(employees, 'department');

  const options = uniqDepartment.map((employee) => (
    <Option key={employee._id} value={employee.department}>
      {employee.department}
    </Option>
  ));

  return (
    <Select
      style={{
        width: '100%',
        ...style,
      }}
      value={value}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      placeholder="- เลือก -"
      showSearch
      onChange={(valueChange) => {
        if (onChange) {
          onChange(valueChange || '');
        }
      }}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
    >
      {options}
    </Select>
  );
};

SelectDepartmentField.defaultProps = defaultProps;

export default SelectDepartmentField;
