import gql from 'graphql-tag';
import { ReceiptStatus } from 'views/AccountReceive/common/model/receiptStatus.model';

export interface Payer {
  _id: string;
  full_name: string;
  business_invoice_type: number;
  address: string;
  tax_id: string;
  phone: string[];
  email: string[];
}

export interface Invoice {
  _id: string;
  invoice_number: string;
  system_request: string;
}

export interface BillingNote {
  _id: string;
  bill_number: string;
}

export interface Receipt {
  _id: string;
  receipt_number: string;
  receipt_status: ReceiptStatus;
  payer: Payer;
  channel: string;
  bank_name: string;
  account_number: string;
  create_date: string;
  receipt_date: string;
  total_receipt: number;
  invoice: Invoice;
  billing_note: BillingNote;
  document: string;
  proof_of_payment: string[];
  cancel_remark: string;
}

export interface ReceiptData {
  receipt: Receipt;
}

export interface ReceiptVars {
  _id: string;
}

export default gql`
  query Receipt($_id: ID!) {
    receipt(_id: $_id) {
      _id
      receipt_number
      receipt_status
      payer {
        _id
        full_name
        business_invoice_type
        address
        tax_id
        phone
        email
      }
      channel
      bank_name
      account_number
      create_date
      receipt_date
      total_receipt
      invoice {
        _id
        invoice_number
        system_request
      }
      billing_note {
        _id
        bill_number
      }
      document
      proof_of_payment
      cancel_remark
    }
  }
`;
