/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  ApprovePaymentContext,
  SelectedApprovePaymentDetailType,
} from 'views/AccountPayment/common/context/ApprovePaymentContext';
import { Spin } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { message } from 'antd';
import APP_QUERY, { AppData, AppVars } from 'shared/graphql/query/app';
import Header from './Header';
import { StyledModal } from './Styles';
import AppDetail from './AppDetail';
import { FormValues } from './model/formValues.model';

const ModalAppDetailWrapper: React.FC = () => {
  const { selectedApprovePaymentDetail, setSelectedApprovePaymentDetail } = useContext(
    ApprovePaymentContext,
  );
  const { isOpenModal } = selectedApprovePaymentDetail;

  return (
    <StyledModal
      visible={isOpenModal}
      onCancel={() => {
        setSelectedApprovePaymentDetail(
          (prevState): SelectedApprovePaymentDetailType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalAppDetail />
    </StyledModal>
  );
};

const validateSchema = yup.object().shape({
  appId: yup.string().required(),
  roundPaid: yup.string().required(),
});

const ModalAppDetail: React.FC = () => {
  const { selectedApprovePaymentDetail, setSelectedApprovePaymentDetail } = useContext(
    ApprovePaymentContext,
  );
  const { isLoading, appId } = selectedApprovePaymentDetail;
  const { data, loading, error } = useQuery<AppData, AppVars>(APP_QUERY, {
    variables: {
      _id: appId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      appId: '',
      roundPaid: '',
    },
  });

  const { register, reset } = methods;

  useEffect(() => {
    setSelectedApprovePaymentDetail(
      (prevState): SelectedApprovePaymentDetailType => ({
        ...prevState,
        isLoading: loading,
      }),
    );

    if (!loading && !error && data?.app) {
      const { _id, app_number, app_status, grand_total_price, round_paid, create_date } = data.app;

      setSelectedApprovePaymentDetail(
        (prevState): SelectedApprovePaymentDetailType => ({
          ...prevState,
          appNumber: app_number,
          appStatus: app_status,
          grandTotalPrice: grand_total_price,
          createDate: create_date,
          modalConfirmUpdate: {
            appNumber: app_number,
            grandTotalPrice: grand_total_price,
          },
        }),
      );

      setTimeout(() => {
        reset({
          appId: _id,
          roundPaid: round_paid,
        });
      });
    }

    return () => {
      setSelectedApprovePaymentDetail(
        (prevState): SelectedApprovePaymentDetailType => ({
          ...prevState,
          isLoading: true,
        }),
      );
    };
  }, [data?.app, error, loading, reset, setSelectedApprovePaymentDetail]);

  useEffect(() => {
    register('appId');
  }, [register]);

  return (
    <Spin spinning={isLoading}>
      <FormProvider {...methods}>
        <form>
          <Header />
          <AppDetail />
        </form>
      </FormProvider>
    </Spin>
  );
};

export default ModalAppDetailWrapper;
