import React from 'react';
import PropTypes from 'prop-types';
import { PaymentVoucherStatusType, PaymentVoucherStatusText } from 'shared/constants/status';
import { StyledTag } from './Styles';

const propTypes = {
  status: PropTypes.number.isRequired,
};

const convertToStatusType = (status) => {
  switch (status) {
    case 1:
      return PaymentVoucherStatusType.RECHECK;
    case 2:
      return PaymentVoucherStatusType.PENDING_APPROVAL;
    case 3:
      return PaymentVoucherStatusType.WAITING_PAYMENT_RECORD;
    case 4:
      return PaymentVoucherStatusType.SUCCESSFUL_PAYMENT;
    case 5:
      return PaymentVoucherStatusType.REJECT;
    case 6:
      return PaymentVoucherStatusType.CANCEL;
    case 7:
      return PaymentVoucherStatusType.EDIT;
    default:
      return status;
  }
};

const getTextStatus = (statusName) => {
  return PaymentVoucherStatusText[statusName];
};

const TagStatus = (props) => {
  const { status } = props;
  const statusType = convertToStatusType(status);
  const text = getTextStatus(statusType);

  return <StyledTag type={statusType}>{text}</StyledTag>;
};

TagStatus.propTypes = propTypes;

export default TagStatus;
