import React from 'react';
import { SubTabs } from 'shared/components';

interface ChartOfAccountsTabProps {
  name: string;
  tab: number;
  onChange: (tab: number) => void;
}

const ChartOfAccountsTab: React.FC<ChartOfAccountsTabProps> = (props) => {
  const { tab, onChange, name } = props;

  const checkSelectTab = (id: number) => {
    return id === tab && name === 'category';
  };

  const selectTab = (index: number) => {
    onChange(index);
  };

  const tabs = [
    {
      id: 1,
      name: 'หมวด1 - สินทรัพย์',
      color: 'badge-grey',
      count: 0,
      active: false,
      divider: false,
    },
    {
      id: 2,
      name: 'หมวด2 - หนี้สิน',
      color: 'badge-grey',
      count: 0,
      active: false,
      divider: false,
    },
    {
      id: 3,
      name: 'หมวด3 - ส่วนของผู้ถือหุ้น',
      color: 'badge-grey',
      count: 0,
      active: false,
      divider: false,
    },
    {
      id: 4,
      name: 'หมวด4 - รายได้',
      color: 'badge-grey',
      count: 0,
      active: false,
      divider: false,
    },
    {
      id: 5,
      name: 'หมวด5 - ค่าใช้จ่าย',
      color: 'badge-grey',
      count: 0,
      active: false,
      divider: false,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default ChartOfAccountsTab;
