import gql from 'graphql-tag';

export interface LogARStatusData {
  logARStatus: {
    outstandingDebt: number;
  };
}

export default gql`
  query LogARStatus {
    logARStatus {
      outstandingDebt
    }
  }
`;
