/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { Button } from 'shared/components';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalCreatePaymentOrder/model/formValues.model';
import CHECK_VENDOR_INVOICE_ID_QUERY, {
  CheckVendorInvoiceIDData,
  CheckVendorInvoiceIDVars,
} from 'shared/graphql/query/checkVendorInvoiceID';
import { useFormContext } from 'react-hook-form';
import { StyledModal } from './Styles';
import ModalConfirmCreatePaymentOrder from '../ModalConfirmCreatePaymentOrder';

type ModalCheckVendorInvoiceProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalCheckVendorInvoice: React.FC<ModalCheckVendorInvoiceProps> = (props) => {
  const { onCancel, visible } = props;
  const { getValues } = useFormContext<FormValues>();
  const allFieldValues = getValues();
  const [isOpenModalConfirmCreatePaymentOrder, setOpenModalConfirmCreatePaymentOrder] = useState<
    boolean
  >(false);

  const { data, loading } = useQuery<CheckVendorInvoiceIDData, CheckVendorInvoiceIDVars>(
    CHECK_VENDOR_INVOICE_ID_QUERY,
    {
      variables: {
        vendor_invoice_id: allFieldValues.vendorInvoice,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const confirmCreate = () => {
    onCancel();
    setOpenModalConfirmCreatePaymentOrder(true);
  };

  return (
    <>
      <StyledModal
        className="custom-modal"
        title={`Vendor Invoice นี้ถูกสร้างไปแล้วที่ ${data?.checkVendorInvoiceID?.payment_number}`}
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button
            key="okButton"
            icon={<CheckCircleOutlined />}
            color="green"
            onClick={() => confirmCreate()}
            loading={loading}
          >
            ใช่, สร้างใบสั่งจ่าย
          </Button>,
          <Button
            key="cancelButton"
            icon={<CloseCircleOutlined />}
            onClick={onCancel}
            disabled={loading}
          >
            ยกเลิก
          </Button>,
        ]}
      >
        <Row>
          <Col>ต้องการสร้างใหม่หรือไม่? </Col>
        </Row>
      </StyledModal>
      <ModalConfirmCreatePaymentOrder
        visible={isOpenModalConfirmCreatePaymentOrder}
        onCancel={() => setOpenModalConfirmCreatePaymentOrder(false)}
      />
    </>
  );
};

export default ModalCheckVendorInvoice;
