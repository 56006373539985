import styled from 'styled-components';
import { Button, Checkbox, Modal, Row } from 'antd';
import { color, font, mixin } from 'shared/utils/styles';
import { Table } from 'shared/components';

export const Container = styled.div`
  padding: 0 20px 20px;
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

export const StyledTable = styled(Table)`
  ${mixin.backgroundTableEven}

  & .ant-table thead > tr > th {
    vertical-align: top;
    background-color: transparent;
  }

  & .table-header {
    &__text {
      padding-bottom: 6px;
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  transform: scale(1.2);
  margin-right: 10px;
`;

export const ToolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledModal = styled(Modal)`
  top: 0;
  padding: 20px;

  &.custom-modal.ant-modal {
    width: 100% !important;
    max-width: 1366px;
  }

  & .ant-modal-body {
    padding: 0 20px 20px;
  }

  & .ant-modal-body p {
    margin: 0;
  }
`;

export const CostingNumberContainer = styled.div`
  display: flex;
  align-items: center;

  & .style-popover {
    margin-left: 5px;
    margin-right: 2px;
  }

  & .ant-btn {
    padding: 1px;
    background-color: transparent;
    border: none;
    fill: #8c8c8c;
  }

  & svg {
    width: 15px;
    height: 15px;
  }

  & Button:hover {
    background-color: ${color.backgroundPrimary};
    fill: ${color.white};
  }
`;

export const TotalAfterDiscountContainer = styled.div`
  & div:hover {
    ${font.bold}
  }
`;

export const StyledPopoverRow = styled(Row)`
  font-size: 18px;

  & .column-1 {
    width: 80px;
  }

  & .column-2 {
    width: 80px;
  }

  & .column-3 {
    width: 80px;
  }

  & .text-right {
    text-align: right;
  }

  &.divider {
    border-bottom: 1px solid ${color.border};
  }
`;

export const ContentRejectContainer = styled.div`
  width: 300px;
`;
