import React from 'react';
import { StyledTag } from './Styles';

export type TagColorType =
  | 'blue'
  | 'blue--light'
  | 'green'
  | 'green--light'
  | 'red'
  | 'red--light'
  | 'grey'
  | 'black';

type CustomProps = {
  customColor?: TagColorType;
  outline?: boolean;
};

const Tag: React.FC<CustomProps> = (props) => {
  const { children, customColor, outline, ...otherProps } = props;

  return (
    <StyledTag type={customColor} outline={outline ? 1 : 0} {...otherProps}>
      {children}
    </StyledTag>
  );
};

export default Tag;
