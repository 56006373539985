/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import { Skeleton } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import INVOICE_QUERY, { InvoiceData, InvoiceVars } from 'shared/graphql/query/invoice';
import { PageResultError, TabPane } from 'shared/components';
import s3 from 'shared/config/s3';
import {
  DebtTrackerContext,
  SelectedDebtTrackerHistory,
} from 'views/DebtTracker/common/context/DebtTrackerContext';
import { StyledModal } from './Styles';
import Header from './Header';
import InvoiceDetail from './InvoiceDetail';
import DebtTrackerHistory from './DebtTrackerHistory';

type Params = {
  invoiceId: string;
};

const ModalDebtTrackerHistory: React.FC = () => {
  const { invoiceId } = useParams<Params>();
  const history = useHistory();

  const { setSelectedDebtTrackerHistory } = useContext(DebtTrackerContext);

  const { data, error } = useQuery<InvoiceData, InvoiceVars>(INVOICE_QUERY, {
    variables: {
      _id: invoiceId,
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = <Skeleton />;

  if (error) {
    content = <PageResultError title="เกิดข้อผิดพลาด" message={error.message} />;
  }

  if (data?.invoice) {
    const { invoice_number, document, receipt } = data.invoice;
    const receiptValue = {
      receiptDate: receipt?.receipt_date,
      receiptDocument: `${s3}/${receipt?.document}`,
    };

    content = (
      <>
        <Header invoiceNumber={invoice_number} invoiceDocument={document} receipt={receiptValue} />
        <InvoiceDetail />
        <TabPane
          tabs={[
            {
              key: '1',
              text: 'บันทึกการติดตามหนี้',
              component: <DebtTrackerHistory />,
            },
          ]}
        />
      </>
    );
  }

  return (
    <StyledModal
      visible
      onCancel={() => {
        history.goBack();
        setSelectedDebtTrackerHistory(
          (prevState): SelectedDebtTrackerHistory => ({
            ...prevState,
            isEditDebtTrackerHistory: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      {content}
    </StyledModal>
  );
};

export default ModalDebtTrackerHistory;
