/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, ReactNode } from 'react';
import { message, Row, Col, Select, Input, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useRouteMatch, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { Button, TableSearch, SelectCustomerField, Text } from 'shared/components';
import { formatDate } from 'shared/utils/dateTime';
import PARCELS_LIST_QUERY, {
  ParcelsListData,
  ParcelsListVars,
} from 'shared/graphql/query/parcelsList';
import styled from 'styled-components';
import formatCurrency from 'shared/utils/formatCurrency';
import { ListParcelStatus } from './ListParcelStatus';
import ListParcelCard from './ListParcelCard';
import TagStatus from './TagStatus';

const ToolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const { Option } = Select;
const { RangePicker } = DatePicker;

type ListParcelTableProps = {
  tab: ListParcelStatus;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type ListParcelDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: string;
  column4: ReactNode;
  column5: ReactNode;
  column6: ReactNode;
  column7: string;
};

const ListParcelTable: React.FC<ListParcelTableProps> = (props) => {
  const match = useRouteMatch();

  const { tab, pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [deliveredDate, setDeliveredDate] = useState<string>('');
  const [customerId, setCustomerId] = useState<string>('');
  const [trackingNumber, setTrackingNumber] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('deliveredDate');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('1');

  const { loading, error, data } = useQuery<ParcelsListData, ParcelsListVars>(PARCELS_LIST_QUERY, {
    variables: {
      parcelStatus: tab,
      deliveredDate,
      trackingNumber,
      customer: customerId,
      status: Number(status),
      showData: pageSize,
      page: currentPage,
      order_by: orderBy === '' ? 'deliveredDate' : orderBy,
      order_type: orderType === '' ? '1' : orderType,
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'วันที่ส่งสำเร็จ',
      dataIndex: 'column1',
      width: 250,
      search: (
        <RangePicker
          onChange={(values) => {
            if (values) {
              const formatRangeDate = `${values[0]?.format('YYYY-MM-DD')}:${values[1]?.format(
                'YYYY-MM-DD',
              )}`;
              setDeliveredDate(formatRangeDate);
              onChangeCurrentPage(1);
            } else {
              setDeliveredDate('');
              onChangeCurrentPage(1);
            }
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'Tracking Number',
      dataIndex: 'column2',
      width: 250,
      search: (
        <Input
          value={trackingNumber}
          placeholder="ค้นหา"
          onChange={(event) => {
            setTrackingNumber(event.target.value);
            onChangeCurrentPage(1);
          }}
          allowClear
        />
      ),
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'column3',
      width: 300,
      search: (
        <SelectCustomerField
          value={customerId}
          onChange={(value) => {
            setCustomerId(value);
            onChangeCurrentPage(1);
          }}
        />
      ),
    },
    {
      title: 'สถานะ',
      dataIndex: 'column4',
      width: 150,
      search: (
        <Select
          value={status}
          style={{ width: '100%' }}
          onChange={(value) => {
            setStatus(value);
            onChangeCurrentPage(1);
          }}
        >
          <Option value="">ทั้งหมด</Option>
          <Option value="3">กำลังไปคลัง GSC</Option>
          <Option value="5">คงคลัง Giztix</Option>
          <Option value="6">ยกเลิก</Option>
          <Option value="7">ออกจัดส่งลูกค้า</Option>
          <Option value="8">ส่งสำเร็จ</Option>
          <Option value="9">คืนคลังลูกค้า</Option>
        </Select>
      ),
    },
    {
      title: 'ต้นทุน TR (บาท)',
      dataIndex: 'column5',
      width: 150,
      align: 'right',
    },
    {
      title: 'รายได้ (บาท)',
      dataIndex: 'column6',
      width: 150,
      align: 'right',
    },
    {
      title: 'เลข Invoice',
      dataIndex: 'column7',
      width: 150,
      align: 'right',
    },
  ];

  let listParcelDataTable: ListParcelDataTable[] = [];
  let totalDocument = 0;

  if (data?.parcelsList) {
    const { parcels } = data.parcelsList;
    listParcelDataTable = parcels.map(
      (item, index): ListParcelDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <Link to={`${match.path}/detail/${item._id}`}>
              <Button color="grey--text-only" icon={<SearchOutlined />} size="small" />
            </Link>
          </ToolsContainer>
        ),
        column1: item.deliveredDate ? formatDate(item.deliveredDate) : '-',
        column2: item.trackingNumber || '-',
        column3: item.customer?.full_name || '-',
        column4: <TagStatus status={item.status} />,
        column5: <Text strong>{item.cost ? formatCurrency(item.cost) : '-'}</Text>,
        column6: (
          <Text strong>
            {item.parcelRevenue?.totalRevenue
              ? formatCurrency(item.parcelRevenue?.totalRevenue)
              : '-'}
          </Text>
        ),
        column7: item.invoice?.invoice_number || '-',
      }),
    );

    totalDocument = data.parcelsList.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <ListParcelCard data={data} loading={loading} />

      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={listParcelDataTable}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />
    </>
  );
};

export default ListParcelTable;
