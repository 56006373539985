import gql from 'graphql-tag';

export interface ShipmentList {
  _id: string;
  shipment_number: string;
  booking_number: string;
  cod_price: number;
}

export interface CodItem {
  _id: string;
  cod_in_number: string;
  total_cod: number;
  cod_in_date: string;
  last_update: string;
  shipment_list: ShipmentList[];
}

export interface BuildPaymentNewCod {
  total_cod: number;
  cod_item: CodItem[];
}

export interface BuildPaymentCodData {
  buildPaymentNewCod: BuildPaymentNewCod;
}

export interface BuildPaymentCodVars {
  cod_item: string[];
}

export default gql`
  query BuildPaymentNewCod($cod_item: [ID]) {
    buildPaymentNewCod(cod_item: $cod_item) {
      _id
      cod_out_number
      total_cod
      cod_item {
        _id
        cod_in_number
        total_cod
        cod_in_date
        last_update
        shipment_list {
          _id
          shipment_number
          booking_number
          cod_price
        }
      }
    }
  }
`;
