import React from 'react';
import { Tag } from 'shared/components';
import { TagColorType } from 'shared/components/Tag';
import { PaymentStatus } from 'views/AccountPayment/common/model/paymentStatus.model';

type TagStatusPaymentProps = {
  status: PaymentStatus;
};

const getTextColorStatus = (
  status: PaymentStatus,
): {
  color: TagColorType | undefined;
  text: string | number;
  outline: boolean;
} => {
  switch (status) {
    case 1:
      return { color: 'grey', text: 'รอตรวจสอบ', outline: false };
    case 2:
      return { color: 'black', text: 'รอสั่งจ่าย', outline: false };
    case 3:
      return { color: 'blue', text: 'สั่งจ่ายแล้ว', outline: false };
    case 4:
      return { color: 'red--light', text: 'ปฏิเสธ', outline: false };
    case 5:
      return { color: 'red', text: 'ยกเลิก', outline: false };
    case 6:
      return { color: 'green', text: 'ทำจ่ายแล้ว', outline: false };
    case 7:
      return { color: 'red--light', text: 'แก้ไข', outline: false };
    default:
      return { color: undefined, text: status, outline: false };
  }
};

const TagStatusPayment: React.FC<TagStatusPaymentProps> = (props) => {
  const { status } = props;
  const textColor = getTextColorStatus(status);

  return (
    <Tag customColor={textColor.color} outline={textColor.outline}>
      {textColor.text}
    </Tag>
  );
};

export default TagStatusPayment;
