import gql from 'graphql-tag';
import { PaymentCodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/paymentCodStatus.model';

export interface UpdatePaymentCodData {
  updatePaymentCod: {
    _id: string;
  };
}

export interface UpdatePaymentCodVars {
  _id: string;
  payment_cod_status?: PaymentCodStatus;
  payment_method?: string;
  bank_account?: {
    bank_name: string;
    bank_branch: string;
    account_name: string;
    account_number: string;
  };
  payment_cod_remark?: string;
  proof_slip?: string;
  paid_date?: string;
  bank_ref?: string;
  slip_remark?: string;
  cod?: string[];
}

export default gql`
  mutation UpdatePaymentCod(
    $_id: ID!
    $payment_cod_status: Int
    $payment_method: String
    $bank_name: String
    $bank_branch: String
    $account_name: String
    $account_number: String
    $payment_cod_remark: String
    $proof_slip: String
    $paid_date: String
    $bank_ref: String
    $slip_remark: String
    $cod: [ID]
  ) {
    updatePaymentCod(
      _id: $_id
      update: {
        payment_cod_status: $payment_cod_status
        payment_method: $payment_method
        bank_account: {
          bank_name: $bank_name
          bank_branch: $bank_branch
          account_name: $account_name
          account_number: $account_number
        }
        payment_cod_remark: $payment_cod_remark
        proof_slip: $proof_slip
        paid_date: $paid_date
        bank_ref: $bank_ref
        slip_remark: $slip_remark
        cod: $cod
      }
    ) {
      _id
    }
  }
`;
