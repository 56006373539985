import React, { useState, ReactChild } from 'react';

export interface SelectedPaymentVouchersType {
  vouchersId: string[];
  payeeId: string;
  isOpenModal: boolean;
  isLoading: boolean;
  modalConfirmCreateApp: {
    grandTotalPrice: number;
    paymentVouchers: {
      createDate: string;
      paymentVoucherNumber: string;
      roundPaid: string;
      payee: string;
      grandTotalPrice: number;
    }[];
  };
  paymentType: string;
}

export interface SelectedPaymentVoucherDetailType {
  paymentVoucherId: string;
  voucherNumber: string; // display detail modal
  voucherStatus: null | number; // display detail modal
  isOpenModal: boolean;
  isLoading: boolean;
  proofSlip: string; // display upload modal
  dueDate: string; // display upload modal
  payeeFullName: string; // display upload modal
  grandTotalPrice: number; // display upload modal
  createDate: string;
  allWht: []; // default tab payment wht
  slipRemark: string;
  payeeId: string;
  paymentType: string;
}

interface PaymentVoucherProps {
  children: ReactChild;
}

interface PaymentVoucher {
  selectedPaymentVouchers: [
    SelectedPaymentVouchersType,
    React.Dispatch<React.SetStateAction<SelectedPaymentVouchersType>>,
  ];
  selectedPaymentVoucherDetail: [
    SelectedPaymentVoucherDetailType,
    React.Dispatch<React.SetStateAction<SelectedPaymentVoucherDetailType>>,
  ];
}

const PaymentVoucherContext = React.createContext({} as PaymentVoucher);

const PaymentVoucherProvider: React.FC<PaymentVoucherProps> = (props) => {
  const { children } = props;
  const [selectedPaymentVouchers, setSelectedPaymentVouchers] = useState<
    SelectedPaymentVouchersType
  >({
    vouchersId: [],
    payeeId: '',
    isOpenModal: false,
    isLoading: false,
    modalConfirmCreateApp: {
      grandTotalPrice: 0,
      paymentVouchers: [],
    },
    paymentType: '',
  });

  const [selectedPaymentVoucherDetail, setSelectedPaymentVoucherDetail] = useState<
    SelectedPaymentVoucherDetailType
  >({
    paymentVoucherId: '',
    voucherNumber: '', // display detail modal
    voucherStatus: null, // display detail modal
    isOpenModal: false,
    isLoading: true,
    proofSlip: '', // display upload modal
    dueDate: '', // display upload modal
    payeeFullName: '', // display upload modal
    grandTotalPrice: 0, // display upload modal
    createDate: '',
    allWht: [], // default tab payment wht
    slipRemark: '',
    payeeId: '',
    paymentType: '',
  });

  const store: PaymentVoucher = {
    selectedPaymentVouchers: [selectedPaymentVouchers, setSelectedPaymentVouchers],
    selectedPaymentVoucherDetail: [selectedPaymentVoucherDetail, setSelectedPaymentVoucherDetail],
  };

  return <PaymentVoucherContext.Provider value={store}>{children}</PaymentVoucherContext.Provider>;
};

export { PaymentVoucherContext, PaymentVoucherProvider };
