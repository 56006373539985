import gql from 'graphql-tag';

export interface ParcelCostStatusData {
  parcelCostStatus: {
    waitAgentConfirm: number;
    waitingConfirm: number;
    confirm: number;
    reject: number;
    edit: number;
    cancel: number;
  };
}

export default gql`
  query ParcelCostStatus {
    parcelCostStatus {
      waitAgentConfirm
      waitingConfirm
      confirm
      reject
      edit
      cancel
    }
  }
`;
