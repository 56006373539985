/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message, Divider } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { v4 as uuidv4 } from 'uuid';
import { formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import {
  CodContext,
  SelectedCodItemType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/context/CodContext';
import CREATE_PAYMENT_COD_MUTATION, {
  CreatePaymentCodData,
  CreatePaymentCodVars,
} from 'shared/graphql/mutation/createPaymentNewCod';
import { Button, Text } from 'shared/components';
import { FormValues } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/Routes/Cod/ModalCreatePaymentCod/model/formValues.model';
import { useFormContext } from 'react-hook-form';
import { StyledModal } from './Styles';

type ModalConfirmCreateCodProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmCreateCod: React.FC<ModalConfirmCreateCodProps> = (props) => {
  const { onCancel, visible } = props;
  const { reset, watch } = useFormContext<FormValues>();
  const { selectedCodItems, setSelectedCodItems } = useContext(CodContext);

  const [createPaymentCod, { loading }] = useMutation<CreatePaymentCodData, CreatePaymentCodVars>(
    CREATE_PAYMENT_COD_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.createPaymentNewCod._id) {
          message.success('สร้างการทำจ่ายแล้ว');
          onCancel();
          setSelectedCodItems(
            (prevState): SelectedCodItemType => ({
              ...prevState,
              isOpenModal: false,
              codItemsId: [],
            }),
          );
          reset();
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['NewCodItems', 'NewCodItemStatus'],
    },
  );

  const confirmCreateCod = () => {
    createPaymentCod({
      variables: {
        customer: selectedCodItems.customerId,
        cod_item: selectedCodItems.codItemsId.map((codItem) => codItem),
        payment_cod_remark: watch('paymentCodRemark'),
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="สร้างการทำจ่ายรายการ COD ใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmCreateCod()}
          loading={loading}
        >
          ใช่,สร้างการทำจ่าย
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
      destroyOnClose
    >
      <Row>
        <Col span={24}>
          <Row gutter={[16, 0]} justify="space-between">
            <Col span={12}>
              <Row gutter={[16, 0]}>
                <Col>
                  ชื่อลูกค้า <Text strong>{selectedCodItems.customerFullName}</Text>
                </Col>
                <Col>
                  ทั้งหมด
                  <Text
                    strong
                  >{` ${selectedCodItems.confirmPaymentCod.codItems.length} รายการ`}</Text>
                </Col>
              </Row>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              มูลค่า COD รวม (บาท):
              <Text strong customType="primary" style={{ marginLeft: 10 }}>
                {formatCurrency(selectedCodItems.confirmPaymentCod.totalCod)}
              </Text>
            </Col>
          </Row>
          <Divider style={{ marginBottom: 10 }} />
          <Row gutter={[16, 0]}>
            <Col span={6}>
              <Text>รหัสCOD-IN</Text>
            </Col>
            <Col span={6}>
              <Text>ยอดรวม COD (บาท)</Text>
            </Col>
            <Col span={6}>
              <Text>Shipment Number</Text>
            </Col>
            <Col span={6}>
              <Text>วันที่แจ้งโอน</Text>
            </Col>
          </Row>
          {selectedCodItems.confirmPaymentCod.codItems.length > 0
            ? selectedCodItems.confirmPaymentCod.codItems.map((item) => (
                <Row gutter={[16, 0]} key={uuidv4()}>
                  <Col span={6}>
                    <Text strong>{item.codNumber}</Text>
                  </Col>
                  <Col span={6}>
                    <Text strong customType="primary">
                      {item.totalCodItem ? formatCurrency(item.totalCodItem) : '-'}
                    </Text>
                  </Col>
                  <Col span={6}>
                    <Text strong>{`${item.shipmentList.length} รหัส`}</Text>
                  </Col>
                  <Col span={6}>
                    <Text strong>{item.codInDate ? formatDateTime(item.codInDate) : '-'}</Text>
                  </Col>
                </Row>
              ))
            : '-'}
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmCreateCod;
