import gql from 'graphql-tag';

export interface Customer {
  _id: string;
  full_name: string;
  address: string;
  tax_id: string;
  phone: string[];
  email: string[];
}

export interface CodItem {
  _id: string;
  vendor: {
    _id: string;
    full_name: string;
  };
  total_cod: number;
  cod_in_date: string;
  delivered_date: string;
}

export interface Cod {
  _id: string;
  cod_in_number: string;
  pick_up_date: string;
  delivered_date: string;
  booking_number: string;
  total_cod: number;
  due_date: string;
  customer: {
    _id: string;
    full_name: string;
  };
  cod_item: CodItem[];
  confirm_status: number;
  total_vendor: number;
}

export interface BuildPaymentCod {
  total_cod: number;
  customer: Customer;
  cod: Cod[];
}

export interface BuildPaymentCodData {
  buildPaymentCod: BuildPaymentCod;
}

export interface BuildPaymentCodVars {
  cod: string[];
}

export default gql`
  query BuildPaymentCod($cod: [ID]) {
    buildPaymentCod(cod: $cod) {
      total_cod
      customer {
        _id
        full_name
        address
        tax_id
        phone
        email
      }
      cod {
        _id
        cod_in_number
        pick_up_date
        delivered_date
        booking_number
        total_cod
        due_date
        customer {
          _id
          full_name
        }
        cod_item {
          _id
          vendor {
            _id
            full_name
          }
          total_cod
          cod_in_date
          delivered_date
        }
        confirm_status
        total_vendor
      }
    }
  }
`;
