import React from 'react';
import { matchPath, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import Tabs from 'shared/components/Tabs';
import { PageNotFound } from 'shared/components';
import CostingParcelList from './Routes/ParcelCostList';

type Tabs = '';

const CostingParcel: React.FC<RouteComponentProps> = (props) => {
  const { history, match, location } = props;

  const selectTab = (tabIndex: Tabs) => {
    const getUrl = match.path;
    const path = tabIndex ? `/${tabIndex}` : '';
    history.push(`${getUrl}${path}`);
  };

  const checkTabsActive = (tabIndex: Tabs) => {
    return !!matchPath(location.pathname, `${match.path}/${tabIndex}`);
  };

  const tabs = [
    {
      name: 'ต้นทุน/ขาย',
      icon: 'file',
      active: checkTabsActive(''),
      clicked: () => selectTab(''),
    },
  ];

  return (
    <>
      {match.isExact && <Redirect to={`${match.path}`} />}

      <Tabs menu={tabs} />
      <Switch>
        <Route path={`${match.path}`} component={CostingParcelList} />
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default CostingParcel;
