import gql from 'graphql-tag';

export interface CodOutParcelStatusData {
  codOutStatus: {
    waiting: number;
    paid: number;
  };
}

export default gql`
  query CodOutParcelStatus {
    codOutStatus {
      waiting
      paid
    }
  }
`;
