/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext, ReactNode } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Input, DatePicker, message, Row, Col, Select, Modal } from 'antd';
import { SearchOutlined, FileExcelOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import baseUrl from 'shared/config/baseUrl';
import CODS_QUERY, { CodsData, CodsVars, Cod } from 'shared/graphql/query/cods';
import {
  CodInContext,
  SelectedCodsInType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/CodInContext';
import {
  Text,
  Button,
  SelectCustomersField,
  SelectPaymentCodField,
  TableSearch,
} from 'shared/components';
import { CodInStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/model/codInStatus.model';
import { ToolsContainer, StyledCheckbox } from './Styles';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

interface CodInListTableProps extends RouteComponentProps {
  status: CodInStatus;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
}

type CodInListDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: string;
  column4: string;
  column5: string;
  column6: ReactNode;
  column7: string;
  column8: string;
  column9: string;
};

const CodInListTable: React.FC<CodInListTableProps> = (props) => {
  const { status, pageSize, onChangePageSize, currentPage, onChangeCurrentPage, match } = props;
  const [payerId, setPayerId] = useState<string>('');
  const [codInNumber, setCodInNumber] = useState<string>('');
  const [pickUpDate, setPickUpDate] = useState<string>('');
  const [deliveredDate, setDeliveredDate] = useState<string>('');
  const [bookingNumber, setBookingNumber] = useState<string>('');
  const [dueDate, setDueDate] = useState<string>('');
  const [paymentCodId, setPaymentCodId] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('');

  const { selectedCodsIn, setSelectedCodsIn } = useContext(CodInContext);

  const { loading, error, data } = useQuery<CodsData, CodsVars>(CODS_QUERY, {
    variables: {
      cod_status: status,
      customer: payerId,
      cod_in_number: codInNumber,
      pick_up_date: pickUpDate,
      delivered_date: deliveredDate,
      booking_number: bookingNumber,
      due_date: dueDate,
      payment_cod: paymentCodId,
      order_by: orderBy,
      order_type: orderType,
      page: currentPage,
      showData: pageSize,
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'column1',
      sortName: 'customer.full_name',
      width: 300,
      search: <SelectCustomersField onChange={(value: string) => setPayerId(value)} />,
    },
    {
      title: 'รหัส COD-IN',
      dataIndex: 'column2',
      sortName: 'cod_in_number',
      width: 150,
      search: (
        <Input
          allowClear
          value={codInNumber}
          placeholder="ค้นหา"
          onChange={(event) => setCodInNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'วันที่รับสินค้า',
      dataIndex: 'column3',
      sortName: 'pick_up_date',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) => {
            const formatRangDate =
              values === null
                ? ''
                : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

            setPickUpDate(formatRangDate);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'วันที่ส่งสำเร็จ',
      dataIndex: 'column4',
      sortName: 'delivered_date',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) => {
            const formatRangDate =
              values === null
                ? ''
                : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

            setDeliveredDate(formatRangDate);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'Booking Number',
      sortName: 'booking_number',
      dataIndex: 'column5',
      width: 150,
      search: (
        <Input
          allowClear
          value={bookingNumber}
          placeholder="ค้นหา"
          onChange={(event) => setBookingNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'ยอดรวม COD (บาท)',
      dataIndex: 'column6',
      width: 150,
      align: 'right',
    },
    {
      title: 'วันที่ต้องทำจ่าย',
      dataIndex: 'column7',
      sortName: 'due_date',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) => {
            const formatRangDate =
              values === null
                ? ''
                : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

            setDueDate(formatRangDate);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: '3PL ทั้งหมด',
      dataIndex: 'column8',
      width: 80,
    },
    {
      title: 'รหัส COD-OUT',
      dataIndex: 'column9',
      width: 150,
      search: <SelectPaymentCodField onChange={(value: string) => setPaymentCodId(value)} />,
    },
  ];

  let codInListData: CodInListDataTable[] = [];
  let totalDocument = 0;

  const selectCodsIn = (item: Cod, checked: boolean, value: string) => {
    if (checked) {
      if (selectedCodsIn.codsInId.length === 0) {
        setSelectedCodsIn(
          (prevState): SelectedCodsInType => ({
            ...prevState,
            codsInId: [],
            customerId: item.customer._id,
            customerFullName: item.customer.full_name,
          }),
        );
      }

      setSelectedCodsIn(
        (prevState): SelectedCodsInType => ({
          ...prevState,
          codsInId: [...prevState.codsInId, value],
        }),
      );
    } else {
      const codsIdFilter = selectedCodsIn.codsInId.filter((codsInId) => codsInId !== value);
      setSelectedCodsIn(
        (prevState): SelectedCodsInType => ({
          ...prevState,
          codsInId: [...codsIdFilter],
        }),
      );
    }
  };

  const showConfirmDownloadFile = (codInId: string) => {
    const modal = {
      title: 'ยืนยัน',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          ต้องการ <Text strong>ดาวน์โหลดไฟล์</Text> ใช่หรือไม่?
        </>
      ),
      okText: 'ดาวน์โหลด',
      onOk() {
        window.location.href = `${baseUrl}/report-COD-01/${codInId}`;
      },
      cancelText: 'ยกเลิก',
    };

    confirm(modal);
  };

  if (data?.cods) {
    const { cods } = data.cods;
    codInListData = cods.map(
      (item, index): CodInListDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            {status === CodInStatus.WAITING && (
              <StyledCheckbox
                checked={selectedCodsIn.codsInId.indexOf(item._id) !== -1}
                disabled={
                  selectedCodsIn.codsInId.length > 0 &&
                  item.customer._id !== selectedCodsIn.customerId
                }
                value={item._id}
                onChange={(event) => selectCodsIn(item, event.target.checked, event.target.value)}
              />
            )}

            <Link to={`${match.url}/3pl/${item._id}`}>
              <Button color="grey--text-only" icon={<SearchOutlined />} size="small" />
            </Link>

            {status === CodInStatus.PENDING && (
              <Button
                onClick={() => showConfirmDownloadFile(item._id)}
                color="grey--text-only"
                icon={<FileExcelOutlined />}
                size="small"
              />
            )}
          </ToolsContainer>
        ),
        column1: item.customer.full_name || '-',
        column2: item.cod_in_number || '-',
        column3: formatDateTime(item.pick_up_date),
        column4: formatDateTime(item.delivered_date),
        column5: item.booking_number || '-',
        column6: (
          <Text strong customType="primary">
            {formatCurrency(item.total_cod)}
          </Text>
        ),
        column7: formatDateTime(item.due_date),
        column8: `${item.confirm_status}/${item.total_vendor}`,
        column9: item.payment_cod?.cod_out_number || '-',
      }),
    );

    totalDocument = data.cods.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={codInListData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value: number) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />
    </>
  );
};

export default withRouter(CodInListTable);
