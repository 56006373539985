import React from 'react';
import { Tag } from 'shared/components';
import { TagColorType } from 'shared/components/Tag';

type TagStatusProps = {
  status: number;
};

const getTextColorStatus = (
  status: number,
): {
  color: TagColorType | undefined;
  text: string | number;
  outline: boolean;
} => {
  switch (status) {
    case 1:
      return { color: 'blue', text: 'รอสร้างใบแจ้งหนี้', outline: false };
    case 2:
      return { color: 'green', text: 'สร้างใบแจ้งหนี้แล้ว', outline: false };
    case 3:
      return { color: 'red', text: 'ยกเลิก', outline: false };
    default:
      return { color: undefined, text: status, outline: false };
  }
};

const TagStatus: React.FC<TagStatusProps> = (props) => {
  const { status } = props;
  const textColor = getTextColorStatus(status);

  return (
    <Tag customColor={textColor.color} outline={textColor.outline}>
      {textColor.text}
    </Tag>
  );
};

export default TagStatus;
