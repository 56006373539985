type EnumLiteralsOf<T extends unknown> = T[keyof T];
export const CodParcelOrderStatus = Object.freeze({
  WAITING_3PL: 1 as const,
  PENDING: 2 as const,
  WAIT_TO_PAY: 3 as const,
  REJECT: 4 as const,
  CANCEL: 5 as const,
  COD_OUT: 6 as const,
  PAID: 7 as const,
});

export type CodParcelOrderStatus = EnumLiteralsOf<typeof CodParcelOrderStatus>;
