/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext, ReactNode } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Input, DatePicker, message, Row, Col, Select, Modal, Popover } from 'antd';
import { SearchOutlined, FileExcelOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@apollo/react-hooks';
import { formatDateTime, formatDate } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import baseUrl from 'shared/config/baseUrl';
import COD_OUT_PARCELS_QUERY, {
  CodOutParcelsData,
  CodOutParcelsVars,
  CodOutParcels,
} from 'shared/graphql/query/codOutParcels';
import {
  PaymentCodContext,
  SelectedPaymentCodDetailType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/context/PaymentCodContext';
import { Text, Button, SelectCustomersField, TableSearch } from 'shared/components';
import { CodOutStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codOutStatus.model';
import { ToolsContainer } from './Styles';
import ModalPaymentCodDetail from './ModalPaymentCodDetail';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

interface PaymentCodListTableProps extends RouteComponentProps {
  status: CodOutStatus;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
}

type PaymentCodListDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: ReactNode;
  column4: ReactNode;
  column5: ReactNode;
  column6: string;
  column7: string;
  column8: string;
};

const PaymentCodListTable: React.FC<PaymentCodListTableProps> = (props) => {
  const { status, pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [customerId, setCustomerId] = useState<string>('');
  const [codInNumber, setCodInNumber] = useState<string>('');
  const [codOutNumber, setCodOutNumber] = useState<string>('');
  const [trackingNumber, setTrackingNumber] = useState<string>('');
  const [paidDate, setPaidDate] = useState<string>('');
  const [bankRef, setBankRef] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('');

  const { setSelectedPaymentCodDetail } = useContext(PaymentCodContext);

  const { loading, error, data } = useQuery<CodOutParcelsData, CodOutParcelsVars>(
    COD_OUT_PARCELS_QUERY,
    {
      variables: {
        cod_out_status: status,
        customer: customerId,
        cod_in_number: codInNumber,
        cod_out_number: codOutNumber,
        paid_date: paidDate,
        tracking_number: trackingNumber,
        bank_ref: bankRef,
        order_by: orderBy,
        order_type: orderType,
        page: currentPage,
        showData: pageSize,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const showConfirmDownloadFile = (value: string) => {
    const modal = {
      title: 'ยืนยัน',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          ต้องการ <Text strong>ดาวน์โหลดไฟล์</Text> ใช่หรือไม่?
        </>
      ),
      okText: 'ดาวน์โหลด',
      onOk() {
        window.location.href = `${baseUrl}/codOut/CodOutParcelExcel/${value}`;
      },
      cancelText: 'ยกเลิก',
    };

    confirm(modal);
  };

  const contentPopOverCod = (value: string[]) => {
    return (
      <div>
        <div style={{ maxHeight: 200, overflow: 'auto' }}>
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            <Row gutter={[8, 0]} style={{ fontSize: 16 }}>
              <Col span={24}>
                <Text strong>รหัส COD-IN</Text>
              </Col>
            </Row>
            {value.map((item) => (
              <Row gutter={[8, 0]} key={uuidv4()} style={{ fontSize: 16 }}>
                <Col span={24}>
                  <Text>{item}</Text>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const popOverCod = (value: string[]) => {
    if (value.length === 1) {
      return <Text>{value[0]}</Text>;
    }
    if (value.length > 1) {
      return (
        <Popover placement="bottom" content={contentPopOverCod(value)}>
          <div style={{ display: 'inline' }}>
            <Text strong customType="primary">
              {value.length} รายการ
            </Text>
          </div>
        </Popover>
      );
    }
    return '-';
  };

  const contentPopOverTrackingNumber = (value: CodOutParcels['parcel_orders']) => {
    return (
      <div style={{ width: 450 }}>
        <div style={{ maxHeight: 200, overflow: 'auto' }}>
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            <Row gutter={[8, 0]} style={{ fontSize: 16 }}>
              <Col span={8}>
                <Text strong>Tracking Number</Text>
              </Col>
              <Col span={8}>
                <Text strong>Delivered Date</Text>
              </Col>
              <Col span={8} style={{ textAlign: 'right' }}>
                <Text strong>COD Value</Text>
              </Col>
            </Row>
            {value.map((item) => (
              <Row gutter={[8, 0]} key={uuidv4()} style={{ fontSize: 16 }}>
                <Col span={8}>
                  <Text>{item.tracking_number}</Text>
                </Col>
                <Col span={8}>
                  <Text>{item.delivered_date ? formatDateTime(item.delivered_date) : '-'}</Text>
                </Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  <Text>{item.cod_price ? formatCurrency(item.cod_price) : '-'}</Text>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const popOverTrackingNumber = (
    valueTrackingNumber: string[],
    parcelOrders: CodOutParcels['parcel_orders'],
  ) => {
    if (valueTrackingNumber.length === 1) {
      return <Text>{valueTrackingNumber[0]}</Text>;
    }
    if (valueTrackingNumber.length > 1) {
      return (
        <Popover placement="bottom" content={contentPopOverTrackingNumber(parcelOrders)}>
          <div style={{ display: 'inline' }}>
            <Text strong customType="primary">
              {valueTrackingNumber.length} Tracking Number
            </Text>
          </div>
        </Popover>
      );
    }
    return '-';
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'column1',
      width: 300,
      search: <SelectCustomersField onChange={(value: string) => setCustomerId(value)} />,
    },
    {
      title: 'รหัส COD-OUT',
      dataIndex: 'column2',
      sortName: 'cod_out_number',
      width: 180,
      search: (
        <Input
          allowClear
          value={codOutNumber}
          placeholder="ค้นหา"
          onChange={(event) => setCodOutNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'รหัส COD-IN',
      dataIndex: 'column3',
      sortName: 'cod_in_number',
      width: 180,
      search: (
        <Input
          allowClear
          value={codInNumber}
          placeholder="ค้นหา"
          onChange={(event) => setCodInNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'ยอดรวม COD (บาท)',
      dataIndex: 'column4',
      sortName: 'total_cod',
      width: 150,
      align: 'right',
    },
    {
      title: 'Tracking Number',
      dataIndex: 'column5',
      width: 250,
      search: (
        <Input
          allowClear
          value={trackingNumber}
          placeholder="ค้นหา"
          onChange={(event) => setTrackingNumber(event.target.value)}
        />
      ),
    },
    ...(status === CodOutStatus.PAID
      ? [
          {
            title: 'วันที่จ่าย',
            dataIndex: 'column6',
            width: 250,
            search: (
              <RangePicker
                onChange={(values) => {
                  const formatRangDate =
                    values === null
                      ? ''
                      : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

                  setPaidDate(formatRangDate);
                }}
                format="YYYY/MM/DD"
                placeholder={['เริ่มต้น', 'สิ้นสุด']}
              />
            ),
          },
          {
            title: 'Bank Ref',
            dataIndex: 'column7',
            width: 180,
            search: (
              <Input
                allowClear
                value={bankRef}
                placeholder="ค้นหา"
                onChange={(event) => setBankRef(event.target.value)}
              />
            ),
          },
        ]
      : []),
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'column8',
      sortName: 'last_update',
      width: 180,
    },
  ];

  let paymentCodListData: PaymentCodListDataTable[] = [];
  let totalDocument = 0;

  if (data?.codOutParcels) {
    const { codOutParcels } = data.codOutParcels;
    paymentCodListData = codOutParcels.map(
      (item, index): PaymentCodListDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <Button
              onClick={() => {
                setSelectedPaymentCodDetail(
                  (prevState): SelectedPaymentCodDetailType => ({
                    ...prevState,
                    paymentCodId: item._id,
                    isOpenModal: true,
                    paymentCodStatus: item.cod_out_status,
                    paymentCodNumber: item.cod_out_number,
                  }),
                );
              }}
              color="grey--text-only"
              icon={<SearchOutlined />}
              size="small"
            />
            <Button
              onClick={() => showConfirmDownloadFile(item._id)}
              color="grey--text-only"
              icon={<FileExcelOutlined />}
              size="small"
            />
          </ToolsContainer>
        ),
        column1: item.customer?.full_name || '-',
        column2: item.cod_out_number || '-',
        column3: popOverCod(item.cod_in_number),
        column4: (
          <Text strong customType="success">
            {formatCurrency(item.total_cod)}
          </Text>
        ),
        column5: popOverTrackingNumber(item.tracking_number, item.parcel_orders),
        column6: item.paid_date ? formatDate(item.paid_date) : '-',
        column7: item.bank_ref || '-',
        column8: item.last_update ? formatDateTime(item.last_update) : '-',
      }),
    );

    totalDocument = data.codOutParcels.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={paymentCodListData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value: number) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />
      <ModalPaymentCodDetail />
    </>
  );
};

export default withRouter(PaymentCodListTable);
