/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import { AppContext } from 'shared/context/AppContext';
import {
  PaymentOrderContext,
  SelectedPaymentDetailType,
} from 'views/AccountPayment/common/context/PaymentOrderContext';
import EMPLOYEE_QUERY, { EmployeeData, EmployeeVars } from 'shared/graphql/query/employee';
import PAYMENT_QUERY, { PaymentData, PaymentVars } from 'shared/graphql/query/payment';
import PAYMENT_DOCUMENTS_QUERY, {
  PaymentDocumentsData,
  PaymentDocumentsVars,
} from 'shared/graphql/query/paymentDocuments';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalDetailPaymentOrder/model/formValues.model';
import Header from './Header';
import DetailPaymentOrder from './DetailPaymentOrder';
import { StyledModal } from './Styles';

const ModalDetailPaymentOrderWrapper = () => {
  const { selectedPaymentDetail: detailPaymentOrderContext } = useContext(PaymentOrderContext);
  const [selectedPaymentDetail, setSelectedPaymentDetail] = detailPaymentOrderContext;
  const { isOpenModal } = selectedPaymentDetail;

  return (
    <StyledModal
      visible={isOpenModal}
      onCancel={() => {
        setSelectedPaymentDetail(
          (prevState): SelectedPaymentDetailType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalDetailPaymentOrder />
    </StyledModal>
  );
};

const validateSchema = yup.object().shape({
  paymentType: yup.string().required(),
  systemRequest: yup.string().required(),
  paymentInvoiceDate: yup.string().required(),
  invoiceDueDate: yup.string().required(),
  serviceDate: yup.string().required(),
  department: yup.string().when('paymentType', {
    is: (value) => value !== 'COGs',
    then: yup.string().required('กรุณากรอกข้อมูล'),
    otherwise: yup.string().nullable(),
  }),
  employeeId: yup.string().required(),
  employeeFullName: yup.string(),
  payeeId: yup.string().required(),
  roundPaidDate: yup.string().required(),
  numberRef: yup.string().nullable(),
  paymentList: yup
    .array()
    .of(
      yup.object().shape({
        dataMasterId: yup.string().required(),
        listNumber: yup.string().required(),
        listName: yup.string().required(),
        qty: yup.number().positive().required(),
        price: yup.number().positive().required(),
        discount: yup
          .number()
          .min(0)
          .test(
            'moreNumber',
            'discount can not more number total price after discount',
            function (discount) {
              const qtyNumber = Number(this.parent.qty) || 0;
              const priceNumber = Number(this.parent.price) || 0;
              const discountNumber = Number(discount) || 0;
              const totalPrice = qtyNumber * priceNumber;

              if (discountNumber) {
                return discountNumber <= totalPrice;
              }

              return true;
            },
          ),
        vatCondition: yup.string().required(),
        wht: yup.number().required(),
      }),
    )
    .required(),
  paymentRemark: yup.string().nullable(),
  cancelRemark: yup.string().nullable(),
  rejectRemark: yup.string().nullable(),
  vendorInvoice: yup.string().nullable(),
  paymentNumber: yup.string(),
  saveStatus: yup.number(),
  vendorId: yup.string().nullable(),
  fixedAssetCheck: yup.string().when('paymentType', {
    is: (value) => value === 'OPEX',
    then: yup.string().required('กรุณากรอกข้อมูล'),
    otherwise: yup.string().nullable(),
  }),
});

const initialValue = {
  employeeId: '',
  employeeFullName: '',
  department: '',
  payeeId: '',
  roundPaidDate: '',
  numberRef: '',
  paymentType: '',
  systemRequest: '',
  paymentInvoiceDate: '',
  invoiceDueDate: '',
  serviceDate: moment().format('YYYY/MM/DD'),
  paymentList: [],
  paymentRemark: '',
  cancelRemark: '',
  rejectRemark: '',
  payeeName: '',
  grandTotalPrice: 0,
  otherDocument: [],
  vendorInvoice: '',
  paymentNumber: '',
  saveStatus: 1,
  vendorId: '',
  fixedAssetCheck: '',
};

const getVatCondition = (advanceType: boolean, vat: number) => {
  if (advanceType === true && vat === 0) {
    return 'advance';
  }

  if (advanceType === false && vat === 0) {
    return 'nonVat';
  }

  if (advanceType === false && vat === 7) {
    return 'vat';
  }

  return undefined;
};

const ModalDetailPaymentOrder: React.FC = () => {
  const { selectedPaymentDetail: detailPaymentOrderContext } = useContext(PaymentOrderContext);
  const [selectedPaymentDetail] = detailPaymentOrderContext;
  const { userDetail } = useContext(AppContext);

  const { data: employeeData } = useQuery<EmployeeData, EmployeeVars>(EMPLOYEE_QUERY, {
    variables: {
      _id: userDetail.employeeId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: paymentData } = useQuery<PaymentData, PaymentVars>(PAYMENT_QUERY, {
    variables: {
      _id: selectedPaymentDetail.paymentId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: paymentDocumentsData } = useQuery<PaymentDocumentsData, PaymentDocumentsVars>(
    PAYMENT_DOCUMENTS_QUERY,
    {
      variables: {
        payment_id: selectedPaymentDetail.paymentId,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const methods = useForm<FormValues>({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(validateSchema),
    defaultValues: initialValue,
  });

  const { reset, register } = methods;

  useEffect(() => {
    register('payeeName');
    register('grandTotalPrice');
    register('paymentStatus');
    register('paymentNumber');
    register('employeeFullName');
    register('otherDocument');
  }, [register]);

  useEffect(() => {
    if (paymentData?.payment) {
      const {
        employee,
        payment_number,
        payment_status,
        payment_type,
        payee,
        round_paid,
        system_request,
        number_ref,
        payment_remark,
        reject_remark,
        cancel_remark,
        payment_list,
        service_date,
        invoice_date,
        invoice_due_date,
        vendor_invoice_id,
        grand_total_price,
        save_status,
        vendor,
        department,
        fixed_asset_check,
      } = paymentData.payment;

      setTimeout(() => {
        reset({
          employeeId: employee._id,
          employeeFullName: employee.full_name,
          department,
          paymentNumber: payment_number,
          paymentStatus: payment_status,
          paymentType: payment_type,
          payeeId: payee?._id,
          payeeName: payee?.full_name,
          roundPaidDate: round_paid,
          systemRequest: system_request,
          numberRef: number_ref,
          paymentRemark: payment_remark,
          rejectRemark: reject_remark,
          cancelRemark: cancel_remark,
          paymentList: payment_list.map((item) => ({
            dataMasterId: item.datamaster_service_item_id,
            vatCondition: getVatCondition(item.advance_type, item.vat),
            discount: item.discount,
            listName: item.list_name,
            listNumber: item.list_number,
            price: item.price,
            qty: item.qty,
            wht: item.wht,
          })),
          serviceDate: service_date,
          paymentInvoiceDate: invoice_date,
          invoiceDueDate: invoice_due_date,
          vendorInvoice: vendor_invoice_id,
          grandTotalPrice: grand_total_price,
          saveStatus: save_status,
          otherDocument: paymentDocumentsData?.paymentDocuments.documents.map(
            (itemOtherDocument) => itemOtherDocument.document_file,
          ),
          vendorId: vendor?._id,
          fixedAssetCheck: fixed_asset_check ? fixed_asset_check.toString() : '',
        });
      });
    }
  }, [
    employeeData?.employee.department,
    employeeData?.employee.full_name,
    paymentData?.payment,
    paymentDocumentsData?.paymentDocuments.documents,
    reset,
    userDetail.employeeId,
  ]);

  return (
    <FormProvider {...methods}>
      <form>
        <Header />
        <DetailPaymentOrder />
      </form>
    </FormProvider>
  );
};

export default ModalDetailPaymentOrderWrapper;
