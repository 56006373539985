/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode } from 'react';
import { Select, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import PAYMENT_CODS_QUERY, {
  PaymentCodsData,
  PaymentCodsVars,
} from 'shared/graphql/query/paymentCods';

const { Option } = Select;

type SelectPaymentCodFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const SelectPaymentCodField: React.FC<SelectPaymentCodFieldProps> = (props) => {
  const { value, onChange } = props;
  const [codOutNumber, setCodOutNumber] = useState<string>('');
  const { data, loading } = useQuery<PaymentCodsData, PaymentCodsVars>(PAYMENT_CODS_QUERY, {
    variables: {
      cod_out_number: codOutNumber,
      showData: 10,
    },
    fetchPolicy: 'cache-and-network',
  });

  let options: ReactNode[] = [];
  if (data && data.paymentCods) {
    const { paymentCods } = data.paymentCods;
    options = paymentCods.map((item) => (
      <Option key={item._id} value={item._id}>
        {item.cod_out_number}
      </Option>
    ));
  }

  return (
    <Select
      allowClear
      style={{
        width: '100%',
      }}
      value={value}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      showSearch
      placeholder="ค้นหา"
      onSearch={(valueSearch) => setCodOutNumber(valueSearch)}
      onChange={(valueChange) => onChange && onChange(valueChange)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};

export default SelectPaymentCodField;
