import gql from 'graphql-tag';
import { JvStatus } from 'views/Setting/common/model/jvStatus.model';

export interface JvData {
  jv: {
    _id: string;
    jv_number: string;
    jv_status: JvStatus;
    description: string;
    document_date: string;
    code: string;
    account_name: string;
    value: number;
    chartOfAccount: {
      _id: string;
    };
  };
}

export interface JvVars {
  _id: string;
}

export default gql`
  query Jv($_id: ID!) {
    jv(_id: $_id) {
      _id
      jv_number
      jv_status
      description
      document_date
      code
      account_name
      value
      chartOfAccount {
        _id
      }
    }
  }
`;
