import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import {
  PaymentVoucherContext,
  SelectedPaymentVouchersType,
} from 'views/AccountPayment/common/context/PaymentVoucherContext';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountPayment/Routes/PaymentVoucher/ModalCreateApp/model/formValues.model';
import { StyledButton, TextHeader, Container } from './Styles';
import ModalConfirmCreateApp from './ModalConfirmCreateApp';

const Header: React.FC = () => {
  const { handleSubmit } = useFormContext<FormValues>();
  const [isOpenModalConfirmCreateApp, setOpenModalConfirmCreateApp] = useState<boolean>(false);
  const { selectedPaymentVouchers: selectedPaymentVouchersContext } = useContext(
    PaymentVoucherContext,
  );
  const [selectedPaymentVouchers, setSelectedPaymentVouchers] = selectedPaymentVouchersContext;
  const { isLoading } = selectedPaymentVouchers;

  const closeModalHandler = () => {
    setSelectedPaymentVouchers(
      (prevState): SelectedPaymentVouchersType => ({
        ...prevState,
        isOpenModal: false,
      }),
    );
  };

  const onSubmitCreateApp = handleSubmit(() => {
    setOpenModalConfirmCreateApp(true);
  });

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>สร้างรอบจ่าย</TextHeader>
        </Col>
        <Col>
          <StyledButton
            disabled={isLoading}
            icon={<CheckCircleOutlined />}
            color="green"
            onClick={onSubmitCreateApp}
          >
            สร้างรอบจ่าย
          </StyledButton>

          <ModalConfirmCreateApp
            visible={isOpenModalConfirmCreateApp}
            onCancel={() => setOpenModalConfirmCreateApp(false)}
          />

          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
