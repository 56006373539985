import React, { useState, ReactChild } from 'react';

export interface SelectedCustomer {
  isOpenModal: boolean;
}

interface Customer {
  selectedCustomer: SelectedCustomer;
  setSelectedCustomer: (params: (cb: SelectedCustomer) => SelectedCustomer) => void;
}

type CustomerProps = {
  children: ReactChild;
};

const CustomerContext = React.createContext({} as Customer);

const CustomerProvider: React.FC<CustomerProps> = (props) => {
  const { children } = props;
  const [selectedCustomer, setSelectedCustomer] = useState<SelectedCustomer>({
    isOpenModal: false,
  });

  const store = {
    selectedCustomer,
    setSelectedCustomer,
  };

  return <CustomerContext.Provider value={store}>{children}</CustomerContext.Provider>;
};

export { CustomerContext, CustomerProvider };
