/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { ReactNode } from 'react';
import { Select, message } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import REPORT_NAME_QUERY, { ReportNameData } from 'shared/graphql/query/reportName';

const { Option } = Select;

type SelectReportTypeFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
};

const defaultProps = {
  disabled: false,
};

const SelectReportTypeField: React.FC<SelectReportTypeFieldProps> = (props) => {
  const { value, onChange, disabled, ...otherProps } = props;
  const { data, loading } = useQuery<ReportNameData>(REPORT_NAME_QUERY, {
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  let options: ReactNode[] = [];
  if (data?.reportName) {
    const { reportName } = data;
    options = reportName.map((report) => (
      <Option key={report._id} value={report.report_type}>
        {report.report_type}
      </Option>
    ));
  }

  return (
    <Select
      style={{
        width: '100%',
      }}
      value={value}
      loading={loading}
      placeholder="เลือก"
      onChange={(valueChange) => onChange && onChange(valueChange)}
      disabled={disabled}
      allowClear
      {...otherProps}
    >
      {options}
    </Select>
  );
};

SelectReportTypeField.defaultProps = defaultProps;

export default SelectReportTypeField;
