/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect } from 'react';
import { Typography, Divider, Row, Col, Input, Tabs, Skeleton, Alert, message, Radio } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { useFormikContext } from 'formik';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { color } from 'shared/utils/styles';
import { PaymentVoucherContext } from 'views/AccountPayment/common/context/PaymentVoucherContext';
import PAYMENT_VOUCHER_QUERY from 'shared/graphql/query/paymentVoucher';
import PAYMENT_WHT_QUERY from 'shared/graphql/query/paymentWHT';
import { StyledFormItem } from './Styles';
import PaymentList from './PaymentList';
import SelectFieldSearchVendor from './SelectFieldSearchVendor';
import SelectFieldPaymentMethod from './SelectFieldPaymentMethod';
import SelectFieldBankAccount from './SelectFieldBankAccount';
import TagStatus from './TagStatus';
import PaymentWHT from './PaymentWHT';

const { Text } = Typography;
const { TabPane } = Tabs;
const InputGroup = Input.Group;

const PaymentDetail = () => {
  const {
    errors,
    touched,
    submitCount,
    values,
    setValues,
    handleBlur,
    handleChange,
  } = useFormikContext();
  const { selectedPaymentVoucherDetail: selectedPaymentVoucherDetailContext } = useContext(
    PaymentVoucherContext,
  );
  const [
    selectedPaymentVoucherDetail,
    setSelectedPaymentVoucherDetail,
  ] = selectedPaymentVoucherDetailContext;
  const { paymentVoucherId, isOpenModal, voucherStatus } = selectedPaymentVoucherDetail;

  const { loading, data } = useQuery(PAYMENT_VOUCHER_QUERY, {
    variables: { _id: paymentVoucherId },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading: paymentWhtLoading, error: paymentWhtError, data: paymentWhtData } = useQuery(
    PAYMENT_WHT_QUERY,
    {
      variables: {
        voucher_id: paymentVoucherId,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (!paymentWhtLoading && !paymentWhtError && paymentWhtData && paymentWhtData.paymentWHT) {
      const {
        _id,
        doc_type,
        wht_type,
        book_number,
        running_number,
        wht_order,
        wht_list,
      } = paymentWhtData.paymentWHT;

      const whtList = wht_list.map((item) => ({
        description: item.description,
        date: item.date === null ? '' : item.date,
        amount: item.amount === null ? '' : item.amount,
        tax: item.tax === null ? '' : item.tax,
        input1: item.input1 === null ? '' : item.input1,
        input2: item.input2 === null ? '' : item.input2,
        isHasInput1: /^[5]\.|^[6]\.|^\(1\.4\)|^\(2\.5\)/g.test(item.description),
      }));

      const formData = {
        ...values,
        paymentWhtId: _id,
        docType: doc_type,
        whtType: wht_type,
        bookNumber: book_number,
        runningNumber: running_number,
        whtOrder: wht_order,
        whtList: [...whtList],
      };

      setValues(formData);
    }
  }, [paymentWhtData, paymentWhtError, paymentWhtLoading]);

  useEffect(() => {
    if (data && data.paymentVoucher) {
      const {
        _id,
        grand_total_price,
        payee,
        payment_method,
        voucher_status,
        voucher_number,
        create_date,
        voucher_remark,
        bank_account: { bank_name, bank_branch, account_name, account_number },
        all_wht,
        save_status,
      } = data.paymentVoucher;

      const formData = {
        ...values,
        payeeId: payee._id || '',
        payeeFullName: payee.full_name,
        paymentMethod: payment_method || '',
        bankName: bank_name || '',
        bankBranch: bank_branch || '',
        accountName: account_name || '',
        accountNumber: account_number || '',
        voucherRemark: voucher_remark || '',
        saveStatus: save_status || '',
      };

      setValues(formData);
      setSelectedPaymentVoucherDetail((prevState) => ({
        ...prevState,
        paymentVoucherId: _id,
        voucherStatus: voucher_status,
        payeeFullName: payee.full_name,
        grandTotalPrice: grand_total_price,
        voucherNumber: voucher_number,
        createDate: create_date,
        allWht: all_wht.map((item) => ({
          wht: item.wht,
          price: item.price,
          totalWht: item.total_wht,
        })),
      }));
    }

    if (isOpenModal) {
      setSelectedPaymentVoucherDetail((prevState) => ({
        ...prevState,
        isLoading: loading,
      }));
    }

    return () => {
      if (!isOpenModal) {
        setSelectedPaymentVoucherDetail((prevState) => ({
          ...prevState,
          isLoading: true,
          voucherStatus: null,
          payeeFullName: '',
          grandTotalPrice: '',
          voucherNumber: '',
          create_date: '',
        }));
      }
    };
  }, [data, loading, isOpenModal]);

  let content = <Skeleton />;
  if (data && data.paymentVoucher) {
    const {
      create_date,
      voucher_number,
      grand_total_price,
      voucher_status,
      proof_slip,
      reject_remark: rejectRemark,
      cancel_remark: cancelRemark,
      payee: {
        _id,
        email,
        tax_id: taxId,
        phone,
        address,
        sub_district: subDistrict,
        district,
        province,
        postcode,
        full_address: fullAddress,
      },
    } = data.paymentVoucher;

    const vendorAddress =
      fullAddress ||
      `${address} แขวง/ตำบล ${subDistrict} เขต/อำเภอ ${district} จังหวัด ${province} ${postcode}`;

    content = (
      <>
        {Object.keys(errors).length > 0 && submitCount > 0 && (
          <Alert
            message="กรุณากรอกข้อมูลให้ครบถ้วน"
            banner
            style={{ margin: '-20px -20px 10px' }}
          />
        )}

        <Text strong>เงื่อนไขใบสำคัญจ่าย</Text>
        <Divider style={{ margin: '10px 0' }} />
        <Row gutter={[32, 16]}>
          <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    ผู้รับเงิน <Text type="grey">(เจ้าหนี้)</Text>
                    <Text type="danger">*</Text>
                  </Col>
                  <Col span={24}>
                    <InputGroup compact>
                      <SelectFieldSearchVendor />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    ช่องทางการจ่าย<Text type="danger">*</Text>
                    <StyledFormItem
                      validateStatus={errors.paymentMethod && touched.paymentMethod && 'error'}
                    >
                      <SelectFieldPaymentMethod />
                    </StyledFormItem>
                  </Col>
                  <Col span={12}>
                    บัญชีที่ต้องการโอน<Text type="danger">*</Text>
                    <StyledFormItem
                      validateStatus={errors.accountNumber && touched.accountNumber && 'error'}
                    >
                      <SelectFieldBankAccount payeeId={_id} />
                    </StyledFormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
            <Row>
              <Col span={10}>ที่อยู่:</Col>
              <Col span={14}>
                <Text strong>{vendorAddress}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เลขประจำตัวผู้เสียภาษี:</Col>
              <Col span={14}>
                <Text strong>{!taxId ? '-' : taxId}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เบอร์โทรศัพท์:</Col>
              <Col span={14}>
                <Text strong>{phone.length === 0 ? '-' : phone.join(', ')}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>อีเมล:</Col>
              <Col span={14}>
                <Text strong>{email.length === 0 ? '-' : email.join(', ')}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col span={8}>วันที่เอกสาร:</Col>
              <Col span={16}>
                <Text strong>{!create_date ? '-' : formatDate(create_date)}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รหัสใบสำคัญจ่าย:</Col>
              <Col span={16}>
                <Text strong>{!voucher_number ? '-' : voucher_number}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>มูลค่าใบสำคัญจ่าย:</Col>
              <Col span={16}>
                <Text strong>{formatCurrency(grand_total_price, 0)}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>สถานะ:</Col>
              <Col span={16}>
                <TagStatus status={voucher_status} />
              </Col>
            </Row>
            {voucher_status === 5 && (
              <Row>
                <Col span={8}>เหตุผลปฏิเสธ:</Col>
                <Col span={16}>
                  <Text strong>{rejectRemark || '-'}</Text>
                </Col>
              </Row>
            )}
            {voucher_status === 6 && (
              <Row>
                <Col span={8}>เหตุผลยกเลิก:</Col>
                <Col span={16}>
                  <Text strong>{cancelRemark || '-'}</Text>
                </Col>
              </Row>
            )}
            <Row>
              <Col span={8}>หลักฐานการจ่าย:</Col>
              <Col span={16}>
                {!proof_slip ? (
                  '-'
                ) : (
                  <a rel="noopener noreferrer" target="_blank" href={proof_slip}>
                    เอกสารหลักฐานการจ่าย
                  </a>
                )}
              </Col>
            </Row>
            {(voucher_status === 3 || voucher_status === 4) && (
              <Row>
                <Radio.Group
                  value={values.saveStatus}
                  onChange={handleChange}
                  name="saveStatus"
                  onBlur={handleBlur}
                >
                  <Radio value={1}>รอบันทึกบัญชี</Radio>
                  <Radio value={2}>บันทึกบัญชี</Radio>
                </Radio.Group>
              </Row>
            )}
          </Col>
        </Row>
        <Divider style={{ margin: 0, marginTop: -5 }} />
        <Tabs defaultActiveKey="1" animated={false} destroyInactiveTabPane>
          <TabPane
            tab={
              <span>
                รายการใบสั่งจ่าย{' '}
                <CheckCircleOutlined
                  style={{
                    color: `${color.success}`,
                    marginLeft: 5,
                  }}
                />
              </span>
            }
            key="1"
          >
            <PaymentList />
          </TabPane>
          <TabPane
            tab={
              <span>
                ภาษีหัก ณ ที่จ่าย{' '}
                <CheckCircleOutlined
                  style={{
                    color: values.paymentWhtId ? color.success : color.grey,
                    marginLeft: 5,
                  }}
                />
              </span>
            }
            key="2"
            disabled={!values.paymentWhtId && (voucherStatus === 5 || voucherStatus === 6)}
          >
            <PaymentWHT />
          </TabPane>
        </Tabs>
      </>
    );
  }

  return content;
};

export default PaymentDetail;
