import React from 'react';
import IconBoxUsdSolid from 'shared/assets/icons/box-usd-solid.svg';
import IconHandHoldingUsdSolid from 'shared/assets/icons/hand-holding-usd-solid.svg';
import IconUsdCircle from 'shared/assets/icons/usd-circle.svg';
import IconMoneyCheckAltSolid from 'shared/assets/icons/money-check-alt-solid.svg';
import Icon from '@ant-design/icons';
import styled from 'styled-components';
import formatCurrency from 'shared/utils/formatCurrency';
import { Text } from 'shared/components';
import { ParcelsListData } from 'shared/graphql/query/parcelsList';
import CardIcon from './CardIcon';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const CardIconStyle = styled(CardIcon)`
  align-items: center;
  margin-right: 20px;

  &:last-child {
    margin-right: 0px;
  }
`;

interface ListParcelCardProps {
  data?: ParcelsListData | undefined;
  loading: boolean;
}

const ListParcelCard: React.FC<ListParcelCardProps> = (props) => {
  const { data, loading } = props;

  return (
    <CardContainer>
      <CardIconStyle
        title="จำนวนสินค้าทั้งหมด (ชิ้น)"
        icon={<Icon component={IconBoxUsdSolid} />}
        text={data?.parcelsList.totalParcel ? `${data?.parcelsList.totalParcel} ชิ้น` : '-'}
        loading={loading}
      />
      <CardIconStyle
        title="ต้นทุนรายชิ้น(เกิดขึ้นจริง / เป้าหมาย)"
        icon={<Icon component={IconHandHoldingUsdSolid} />}
        text={`${
          data?.parcelsList.totalCostAvg ? formatCurrency(data?.parcelsList.totalCostAvg) : '-'
        } / ${
          data?.parcelsList.parcelTarget ? formatCurrency(data?.parcelsList.parcelTarget) : '-'
        } บาท`}
        loading={loading}
      />
      <CardIconStyle
        title="ราคาต้นทุนค่าขนส่งรวม"
        icon={<Icon component={IconUsdCircle} />}
        text={
          data?.parcelsList.totalCost ? `${formatCurrency(data?.parcelsList.totalCost)} บาท` : '-'
        }
        loading={loading}
      />
      <CardIconStyle
        title="รายได้รวม"
        icon={<Icon component={IconMoneyCheckAltSolid} />}
        text={
          <Text customType="success">
            {data?.parcelsList.totalRevenue
              ? `${formatCurrency(data?.parcelsList.totalRevenue)} บาท`
              : '-'}
          </Text>
        }
        loading={loading}
      />
    </CardContainer>
  );
};

export default ListParcelCard;
