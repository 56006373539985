/* eslint-disable no-underscore-dangle */
import React, { useState, ReactChild, useEffect } from 'react';
import useCurrentUser from 'shared/hook/useCurrentUser';
import { getStoredAuthToken } from 'shared/utils/authToken';

export interface UserDetailType {
  _id: string;
  name: string;
  employeeId: string;
  email: string;
}

interface App {
  userDetail: UserDetailType;
  setUserDetail: (params: (cb: UserDetailType) => UserDetailType) => void;
}

type AppProps = {
  children: ReactChild;
};

const AppContext = React.createContext({} as App);

const AppProvider: React.FC<AppProps> = (props) => {
  const { children } = props;
  const token = getStoredAuthToken();
  const { data: currentUserData } = useCurrentUser();

  const [userDetail, setUserDetail] = useState<UserDetailType>({
    _id: '',
    name: '',
    employeeId: '',
    email: '',
  });

  useEffect(() => {
    if (currentUserData?.currentUser && token) {
      setUserDetail({
        _id: currentUserData.currentUser._id,
        name: currentUserData.currentUser.name,
        employeeId: currentUserData.currentUser.employee,
        email: currentUserData.currentUser.email,
      });
    }
  }, [currentUserData?.currentUser, token]);

  const store = {
    userDetail,
    setUserDetail,
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
