/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import formatCurrency from 'shared/utils/formatCurrency';
import { Col, message, Popover, Row, Table as TableAntd, Typography } from 'antd';
import { Spin } from 'shared/components';
import { useQuery } from '@apollo/react-hooks';
import COSTING_QUERY, { CostingData, CostingVars } from 'shared/graphql/query/costing';
import { CostingContext } from 'views/Costing/common/context/CostingContext';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { StyledTable, TextTotalPrice } from './Styles';

const { Text } = Typography;
const CostingTable: React.FC = () => {
  const { selectedCostingDetail } = useContext(CostingContext);
  const { costingId } = selectedCostingDetail!;
  const { data } = useQuery<CostingData, CostingVars>(COSTING_QUERY, {
    variables: {
      _id: costingId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = <Spin />;
  if (data && data.costing) {
    const {
      costing_list,
      total_before_discount,
      total_discount,
      total_after_discount,
      total_vat,
      all_wht,
      grand_total_price,
    } = data.costing;
    const columnsTableService = [
      {
        title: '',
        children: [
          {
            title: 'รายการ',
            dataIndex: 'column1',
            key: 'column1',
            width: 200,
          },
          {
            title: 'จำนวน',
            dataIndex: 'column2',
            key: 'column2',
            width: 50,
            align: 'right',
          },
        ],
      },
      {
        title: 'รายการต้นทุน',
        children: [
          {
            title: 'ราคาต่อหน่วย',
            dataIndex: 'column3',
            key: 'column3',
            width: 100,
            align: 'right',
          },
          {
            title: 'ราคารวม',
            dataIndex: 'column4',
            key: 'column4',
            width: 100,
            align: 'right',
          },
          {
            title: 'ภาษีมูลค่าเพิ่ม',
            dataIndex: 'column5',
            key: 'column5',
            width: 100,
            align: 'right',
          },
          {
            title: 'หัก ณ ที่จ่าย',
            dataIndex: 'column6',
            key: 'column6',
            width: 100,
            align: 'right',
          },
          {
            title: 'ราคาต้นทุน',
            dataIndex: 'column7',
            key: 'column7',
            width: 100,
            align: 'right',
          },
        ],
      },
      {
        title: 'รายการขาย',
        children: [
          {
            title: 'ราคาต่อหน่วย',
            dataIndex: 'column8',
            key: 'column8',
            width: 100,
            align: 'right',
          },
          {
            title: 'ราคารวม',
            dataIndex: 'column9',
            key: 'column9',
            width: 100,
            align: 'right',
          },
          {
            title: 'ส่วนลด',
            dataIndex: 'column10',
            key: 'column10',
            width: 100,
            align: 'right',
          },
          {
            title: 'ราคาหลังลด',
            dataIndex: 'column11',
            key: 'column11',
            width: 100,
            align: 'right',
          },
          {
            title: 'ภาษีมูลค่าเพิ่ม',
            dataIndex: 'column12',
            key: 'column12',
            width: 100,
            align: 'right',
          },
          {
            title: 'หัก ณ ที่จ่าย',
            dataIndex: 'column13',
            key: 'column13',
            width: 100,
            align: 'right',
          },
          {
            title: 'ราคาขาย',
            dataIndex: 'column14',
            key: 'column14',
            width: 100,
            align: 'right',
          },
        ],
      },
    ];

    const serviceGroup = _.chain(costing_list)
      .groupBy('service_category_name')
      .map((value, key) => ({
        serviceCategoryName: key,
        data: value,
      }))
      .value();

    const dataTableService = serviceGroup.reduce((total: any[], group) => {
      const rowHeader = {
        key: uuidv4(),
        column1: <Text strong>{group.serviceCategoryName}</Text>,
      };

      const rowService = group.data.map((item) => ({
        key: uuidv4(),
        column1: item.list_name,
        column2: item.qty,
        column3: formatCurrency(item.price.cost, '-'),
        column4: formatCurrency(item.total_before_discount.cost, '-'),
        column5: formatCurrency(item.total_vat.cost, '-'),
        column6: formatCurrency(item.total_wht.cost, '-'),
        column7: formatCurrency(item.grand_total_price.cost, '-'),
        column8: formatCurrency(item.price.sale, '-'),
        column9: formatCurrency(item.total_before_discount.sale, '-'),
        column10: formatCurrency(item.discount.sale, '-'),
        column11: formatCurrency(item.total_price.sale, '-'),
        column12: formatCurrency(item.total_vat.sale, '-'),
        column13: formatCurrency(item.total_wht.sale, '-'),
        column14: formatCurrency(item.grand_total_price.sale, '-'),
      }));

      return [...total, rowHeader, ...rowService];
    }, []);

    const columnsTableTotalPrice = [
      {
        title: '',
        children: [
          {
            title: 'รายการ',
            dataIndex: 'column1',
            key: 'column1',
            width: 250,
          },
        ],
      },
      {
        title: 'รายการต้นทุน',
        children: [
          {
            title: 'ราคาต่อหน่วย',
            dataIndex: 'column2',
            key: 'column2',
            width: 100,
            align: 'right',
          },
          {
            title: 'ราคารวม',
            dataIndex: 'column3',
            key: 'column3',
            width: 100,
            align: 'right',
          },
          {
            title: 'ภาษีมูลค่าเพิ่ม',
            dataIndex: 'column4',
            key: 'column4',
            width: 100,
            align: 'right',
          },
          {
            title: 'หัก ณ ที่จ่าย',
            dataIndex: 'column5',
            key: 'column5',
            width: 100,
            align: 'right',
          },
          {
            title: 'ราคาต้นทุน',
            dataIndex: 'column6',
            key: 'column6',
            width: 100,
            align: 'right',
          },
        ],
      },
      {
        title: 'รายการขาย',
        children: [
          {
            title: 'ราคาต่อหน่วย',
            dataIndex: 'column7',
            key: 'column7',
            width: 100,
            align: 'right',
          },
          {
            title: 'ราคารวม',
            dataIndex: 'column8',
            key: 'column8',
            width: 100,
            align: 'right',
          },
          {
            title: 'ส่วนลด',
            dataIndex: 'column9',
            key: 'column9',
            width: 100,
            align: 'right',
          },
          {
            title: 'ราคาหลังลด',
            dataIndex: 'column10',
            key: 'column10',
            width: 100,
            align: 'right',
          },
          {
            title: 'ภาษีมูลค่าเพิ่ม',
            dataIndex: 'column11',
            key: 'column11',
            width: 100,
            align: 'right',
          },
          {
            title: 'หัก ณ ที่จ่าย',
            dataIndex: 'column12',
            key: 'column12',
            width: 100,
            align: 'right',
          },
          {
            title: 'ราคาขาย',
            dataIndex: 'column13',
            key: 'column13',
            width: 100,
            align: 'right',
          },
        ],
      },
    ];

    const rowAllWHT = all_wht.map((item) => ({
      key: uuidv4(),
      column1: `รวมหัก ณ ที่จ่าย ${item.wht}%`,
      column2: formatCurrency(0, '-'),
      column3: formatCurrency(0, '-'),
      column4: formatCurrency(0, '-'),
      column5:
        item.total_wht.cost > 0 ? (
          <Popover placement="top" content={<div>{formatCurrency(item.price.cost)}</div>}>
            <Text>{`${formatCurrency(item.total_wht.cost)}`}</Text>
          </Popover>
        ) : (
          '-'
        ),
      column6: formatCurrency(0, '-'),
      column7: formatCurrency(0, '-'),
      column8: formatCurrency(0, '-'),
      column9: formatCurrency(0, '-'),
      column10: formatCurrency(0, '-'),
      column11: formatCurrency(0, '-'),
      column12:
        item.total_wht.sale > 0 ? (
          <Popover placement="top" content={<div>{formatCurrency(item.price.sale)}</div>}>
            <Text>{`${formatCurrency(item.total_wht.sale)}`}</Text>
          </Popover>
        ) : (
          '-'
        ),
      column13: formatCurrency(0, '-'),
    }));

    const dataTableTotalPrice = [
      {
        key: uuidv4(),
        column1: 'ราคารวมก่อนส่วนลด',
        column2: formatCurrency(0, '-'),
        column3: formatCurrency(total_before_discount.cost, '-'),
        column4: formatCurrency(0, '-'),
        column5: formatCurrency(0, '-'),
        column6: formatCurrency(0, '-'),
        column7: formatCurrency(0, '-'),
        column8: formatCurrency(total_before_discount.sale, '-'),
        column9: formatCurrency(0, '-'),
        column10: formatCurrency(0, '-'),
        column11: formatCurrency(0, '-'),
        column12: formatCurrency(0, '-'),
        column13: formatCurrency(0, '-'),
      },
      {
        key: uuidv4(),
        column1: 'รวมส่วนลด',
        column2: formatCurrency(0, '-'),
        column3: formatCurrency(0, '-'),
        column4: formatCurrency(0, '-'),
        column5: formatCurrency(0, '-'),
        column6: formatCurrency(0, '-'),
        column7: formatCurrency(0, '-'),
        column8: formatCurrency(0, '-'),
        column9: formatCurrency(total_discount.sale, '-'),
        column10: formatCurrency(0, '-'),
        column11: formatCurrency(0, '-'),
        column12: formatCurrency(0, '-'),
        column13: formatCurrency(0, '-'),
      },
      {
        key: uuidv4(),
        column1: 'ราคารวมหลังส่วนลด',
        column2: formatCurrency(0, '-'),
        column3: formatCurrency(total_after_discount.cost, '-'),
        column4: formatCurrency(0, '-'),
        column5: formatCurrency(0, '-'),
        column6: formatCurrency(0, '-'),
        column7: formatCurrency(0, '-'),
        column8: formatCurrency(0, '-'),
        column9: formatCurrency(0, '-'),
        column10: formatCurrency(total_after_discount.sale, '-'),
        column11: formatCurrency(0, '-'),
        column12: formatCurrency(0, '-'),
        column13: formatCurrency(0, '-'),
      },
      {
        key: uuidv4(),
        column1: 'รวมภาษีมูลค่าเพิ่ม 7%',
        column2: formatCurrency(0, '-'),
        column3: formatCurrency(0, '-'),
        column4: formatCurrency(total_vat.cost, '-'),
        column5: formatCurrency(0, '-'),
        column6: formatCurrency(0, '-'),
        column7: formatCurrency(0, '-'),
        column8: formatCurrency(0, '-'),
        column9: formatCurrency(0, '-'),
        column10: formatCurrency(0, '-'),
        column11: formatCurrency(total_vat.sale, '-'),
        column12: formatCurrency(0, '-'),
        column13: formatCurrency(0, '-'),
      },
      ...rowAllWHT,
    ];

    content = (
      <div style={{ overflowX: 'auto' }}>
        <StyledTable
          className="table-1"
          columns={columnsTableService}
          dataSource={dataTableService}
          bordered
          pagination={false}
        />

        <StyledTable
          className="table-2"
          style={{ marginTop: 15 }}
          columns={columnsTableTotalPrice}
          dataSource={dataTableTotalPrice}
          bordered
          pagination={false}
          summary={() => {
            return (
              <TableAntd.Summary.Row>
                <TableAntd.Summary.Cell colSpan={6} index={1}>
                  <Row justify="end" align="middle" gutter={[16, 0]}>
                    <Col>
                      <Text strong>ต้นทุนสุทธิ / Net Cost</Text>
                    </Col>
                    <Col>
                      <Text strong>
                        <TextTotalPrice>{`${formatCurrency(
                          grand_total_price.cost,
                        )} บาท`}</TextTotalPrice>
                      </Text>
                    </Col>
                  </Row>
                </TableAntd.Summary.Cell>
                <TableAntd.Summary.Cell colSpan={7} index={2}>
                  <Row justify="end" align="middle" gutter={[16, 0]}>
                    <Col>
                      <Text strong>รายได้สุทธิ / Net Revenue</Text>
                    </Col>
                    <Col>
                      <Text strong>
                        <TextTotalPrice>{`${formatCurrency(
                          grand_total_price.sale,
                        )} บาท`}</TextTotalPrice>
                      </Text>
                    </Col>
                  </Row>
                </TableAntd.Summary.Cell>
              </TableAntd.Summary.Row>
            );
          }}
        />
      </div>
    );
  }

  return content;
};

export default CostingTable;
