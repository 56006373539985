import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SubTabs } from 'shared/components';
import RECEIPT_STATUS_QUERY, { ReceiptStatusData } from 'shared/graphql/query/receiptStatus';
import { ReceiptStatus } from 'views/AccountReceive/common/model/receiptStatus.model';

type ReceiptTabsProps = {
  name: string;
  tab: ReceiptStatus;
  onChange: (tab: ReceiptStatus) => void;
};

const ReceiptTabs: React.FC<ReceiptTabsProps> = (props) => {
  const { tab, onChange, name } = props;
  const { data, refetch } = useQuery<ReceiptStatusData>(RECEIPT_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const checkSelectTab = (id: ReceiptStatus) => {
    return id === tab && name === 'receipt';
  };

  const selectTab = (index: ReceiptStatus) => {
    refetch();
    onChange(index);
  };

  const tabs = [
    {
      id: ReceiptStatus.COMPLETE,
      name: 'ออกใบเสร็จแล้ว',
      color: 'badge-grey',
      count: data?.receiptStatus.complete || 0,
      divider: true,
    },
    {
      id: ReceiptStatus.CANCEL,
      name: 'ยกเลิก',
      color: 'badge-grey',
      count: data?.receiptStatus.cancel || 0,
      divider: false,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default ReceiptTabs;
