import gql from 'graphql-tag';
import { CodItemStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/codItemStatus.model';

export interface CodItems {
  _id: string;
  customer: {
    _id: string;
    full_name: string;
    address: string;
    tax_id: string;
    phone: string[];
    email: string[];
  };
  shipment_list: {
    shipment_number: string;
  }[];
  cod: {
    _id: string;
    cod_in_number: string;
  };
  payment_cod: {
    _id: string;
    cod_out_number: string;
  };
  ops_employee: {
    _id: string;
    full_name: string;
  };
  status: CodItemStatus;
  total_cod: number;
  due_date: string;
  cod_in_date: string;
  document: string[];
  create_date: string;
  last_update: string;
}

export interface CodItemsData {
  newCodItems: {
    codItems: CodItems[];
    totalDocument: number;
  };
}

export interface CodItemsVars {
  _id?: string;
  customer?: string;
  payment_cod?: string;
  cod_in_number?: string;
  shipment_numbers?: string;
  status?: CodItemStatus;
  due_date?: string;
  order_by?: string;
  order_type?: '1' | '-1' | '';
  page?: number;
  showData?: number;
}

export default gql`
  query NewCodItems(
    $_id: ID
    $customer: ID
    $payment_cod: ID
    $cod_in_number: String
    $shipment_numbers: String
    $status: Int
    $due_date: String
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
  ) {
    newCodItems(
      params: {
        _id: $_id
        customer: $customer
        payment_cod: $payment_cod
        cod_in_number: $cod_in_number
        shipment_numbers: $shipment_numbers
        status: $status
        due_date: $due_date
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      codItems {
        _id
        customer {
          _id
          full_name
          address
          tax_id
          email
          phone
        }
        shipment_list {
          shipment_number
        }
        cod {
          _id
          cod_in_number
        }
        payment_cod {
          _id
          cod_out_number
        }
        ops_employee {
          _id
          full_name
        }
        total_cod
        due_date
        cod_in_date
        document
        create_date
        last_update
      }
      totalDocument
    }
  }
`;
