import gql from 'graphql-tag';

export interface CodStatusData {
  newCodStatus: {
    ops_pending: number;
    pending: number;
    confirm: number;
    reject: number;
    cancel: number;
  };
}

export default gql`
  query NewCodStatus {
    newCodStatus {
      ops_pending
      pending
      confirm
      reject
      cancel
    }
  }
`;
