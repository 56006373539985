/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode } from 'react';
import INVOICES_QUERY, { InvoicesData, InvoicesVars } from 'shared/graphql/query/invoices';
import CUSTOMER_QUERY, { CustomerData, CustomerVars } from 'shared/graphql/query/customer';
import { useQuery } from '@apollo/react-hooks';
import { Input, DatePicker, message, Row, Col } from 'antd';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate, formatDateTime } from 'shared/utils/dateTime';
import { Text } from 'shared/components';
import { StyledModal, StyledTable, StyledCheckbox, ToolsContainer } from './Styles';
import Header from './Header';

const { RangePicker } = DatePicker;

type Invoice = {
  id: string;
  serviceDate: string;
  invoiceNumber: string;
  grandTotalPrice: number;
};

type ModalAddInvoiceWrapperProps = {
  visible: boolean;
  customerId: string;
  invoices?: Invoice[];
  onCancel: () => void;
  onOk: (value: Invoice[]) => void;
};

type ModalAddInvoiceProps = {
  customerId: string;
  invoices: Invoice[];
  onCancel: () => void;
  onOk: (value: Invoice[]) => void;
};

type InvoicesDataTable = {
  key: number;
  tools: ReactNode;
  serviceDateColumn: string;
  dueDateColumn: string;
  invoiceNumberColumn: string;
  grandTotalPriceColumn: ReactNode;
  lastUpdateDateColumn: string;
};

const ModalAddInvoiceWrapper: React.FC<ModalAddInvoiceWrapperProps> = (props) => {
  const { visible, customerId, onCancel, invoices, onOk } = props;
  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalAddInvoice
        onCancel={onCancel}
        customerId={customerId}
        invoices={invoices || []}
        onOk={onOk}
      />
    </StyledModal>
  );
};

const ModalAddInvoice: React.FC<ModalAddInvoiceProps> = (props) => {
  const { onCancel, customerId, invoices, onOk } = props;
  const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>(invoices);
  const [serviceDate, setServiceDate] = useState<string>('');
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [dueDate, setDueDate] = useState<string>('');
  const [pageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data: customerData } = useQuery<CustomerData, CustomerVars>(CUSTOMER_QUERY, {
    variables: {
      _id: customerId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading, data } = useQuery<InvoicesData, InvoicesVars>(INVOICES_QUERY, {
    variables: {
      service_date: serviceDate,
      invoice_number: invoiceNumber,
      due_date: dueDate,
      invoice_status: 2,
      page: currentPage,
      showData: pageSize,
      payer: customerId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">วันที่เอกสาร</div>
          <div>
            <RangePicker
              onChange={(values) =>
                values === null
                  ? setServiceDate('')
                  : setServiceDate(
                      `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                    )
              }
              format="YYYY/MM/DD"
              placeholder={['เริ่มต้น', 'สิ้นสุด']}
            />
          </div>
        </div>
      ),
      dataIndex: 'serviceDateColumn',
      width: 160,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">รหัสใบแจ้งหนี้</div>
          <div>
            <Input
              allowClear
              value={invoiceNumber}
              placeholder="ค้นหา"
              onChange={(event) => setInvoiceNumber(event.target.value)}
            />
          </div>
        </div>
      ),
      dataIndex: 'invoiceNumberColumn',
      width: 150,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">มูลค่าใบแจ้งหนี้</div>
        </div>
      ),
      dataIndex: 'grandTotalPriceColumn',
      width: 100,
      align: 'right',
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">วันที่ครบกำหนดชำระ</div>
          <div>
            <RangePicker
              onChange={(values) =>
                values === null
                  ? setDueDate('')
                  : setDueDate(
                      `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                    )
              }
              format="YYYY/MM/DD"
              placeholder={['เริ่มต้น', 'สิ้นสุด']}
            />
          </div>
        </div>
      ),
      dataIndex: 'dueDateColumn',
      width: 160,
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'lastUpdateDateColumn',
      width: 150,
    },
  ];

  const selectInvoices = (checked: boolean, value: Invoice) => {
    if (checked) {
      setSelectedInvoices((prevState) => {
        return [...prevState, value];
      });
    } else {
      const invoicesFilter = selectedInvoices.filter((invoice) => invoice.id !== value.id);
      setSelectedInvoices([...invoicesFilter]);
    }
  };

  let invoicesData: InvoicesDataTable[] = [];
  let totalDocument = 0;

  if (data && data.invoices) {
    invoicesData = data.invoices.invoices.map(
      (item, index): InvoicesDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <StyledCheckbox
              disabled={item.payer._id !== customerId}
              checked={selectedInvoices.map((invoice) => invoice.id).indexOf(item._id) !== -1}
              value={item._id}
              onChange={(event) =>
                selectInvoices(event.target.checked, {
                  id: item._id,
                  invoiceNumber: item.invoice_number,
                  grandTotalPrice: item.grand_total_price,
                  serviceDate: item.create_date,
                })
              }
            />
          </ToolsContainer>
        ),
        serviceDateColumn: !item.service_date ? '-' : formatDate(item.service_date),
        invoiceNumberColumn: item.invoice_number || '-',
        grandTotalPriceColumn: (
          <Text strong customType="primary">
            {formatCurrency(item.grand_total_price)}
          </Text>
        ),
        dueDateColumn: !item.due_date ? '-' : formatDate(item.due_date),
        lastUpdateDateColumn: formatDateTime(item.last_update) || '-',
      }),
    );

    totalDocument = data.invoices.totalDocument;
  }

  return (
    <div>
      <Header
        onSubmit={() => onOk(selectedInvoices)}
        onCancel={onCancel}
        btnDisabled={selectedInvoices.length === 0}
      />

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row>
            <Col span={8}>
              ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>
              <div>
                <Input value={customerData?.customer.full_name || '-'} disabled />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <StyledTable
            scroll={{ x: 'max-content' }}
            columns={columns}
            dataSource={invoicesData}
            loading={loading}
            pagination={{
              current: currentPage,
              pageSize,
              total: totalDocument,
              showSizeChanger: false,
              onChange: (value: number) => setCurrentPage(value),
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ModalAddInvoiceWrapper;
