import React, { useState } from 'react';
import { Button, Col, Form, Input, Popover, Radio } from 'antd';
import formatCurrency from 'shared/utils/formatCurrency';
import IconFilter from 'shared/assets/icons/filter.svg';
import { StyledButtonFilter, StyledPopoverFilterRow } from './Styles';

const InputProfitFilter = (props) => {
  const { onChange } = props;
  const [form] = Form.useForm();
  const [startNumber, setStartNumber] = useState('');
  const [endNumber, setEndNumber] = useState('');
  const [numberProfit, setNumberProfit] = useState();
  const [radioButton, setRadioButton] = useState();
  const [visible, setVisible] = useState(false);

  const clearNumberProfit = () => {
    setNumberProfit('');
    setStartNumber('');
    setEndNumber('');
    form.setFieldsValue({ inputNumber1: '', inputNumber2: '' });
  };

  const submit = () => {
    if (radioButton === '>' && startNumber === '' && endNumber === '') {
      onChange('>0');
    }
    if (radioButton === '<' && startNumber === '' && endNumber === '') {
      onChange('<0');
    }
    if (radioButton === '>' && startNumber !== '' && endNumber !== '') {
      onChange(`${startNumber}:${endNumber}`);
      setNumberProfit(`${formatCurrency(+startNumber)} - ${formatCurrency(+endNumber)}`);
    }
    if (radioButton === '<' && startNumber !== '' && endNumber !== '') {
      onChange(`-${endNumber}:-${startNumber}`);
      setNumberProfit(`${formatCurrency(+startNumber)} - ${formatCurrency(+endNumber)}`);
    }
    if (radioButton === '>' && startNumber !== '' && endNumber === '') {
      onChange(`>=${startNumber}`);
      setNumberProfit(`${formatCurrency(+startNumber)} - ...`);
    }
    if (radioButton === '<' && startNumber !== '' && endNumber === '') {
      onChange(`<=-${startNumber}`);
      setNumberProfit(`${formatCurrency(+startNumber)} - ...`);
    }
    if (radioButton === '>' && startNumber === '' && endNumber !== '') {
      onChange(`1:${endNumber}`);
      setNumberProfit(`1.00 - ${formatCurrency(+endNumber)}`);
    }
    if (radioButton === '<' && startNumber === '' && endNumber !== '') {
      onChange(`-${endNumber}:-1`);
      setNumberProfit(`1.00 - ${formatCurrency(+endNumber)}`);
    }
    if (radioButton === '=0') {
      onChange('=0');
    }
    if (radioButton === 'all') {
      onChange('');
    }
  };

  const textProfit = () => {
    switch (radioButton) {
      case '>':
        return 'กำไร';
      case '<':
        return 'ขาดทุน';
      case '=0':
        return 'เท่าทุน';
      case 'all':
        return 'ทั้งหมด';
      default:
        return 'ทั้งหมด';
    }
  };

  const contentProfit = (
    <Form form={form}>
      <StyledPopoverFilterRow>
        <Col span={12} className="text-title">
          กำไร - ขาดทุน
        </Col>
      </StyledPopoverFilterRow>
      <StyledPopoverFilterRow>
        <Radio.Group
          onChange={(event) => {
            setRadioButton(event.target.value);
          }}
          buttonStyle="solid"
        >
          <Radio.Button
            value=">"
            onClick={() => {
              clearNumberProfit();
            }}
          >
            กำไร
          </Radio.Button>
          <Radio.Button
            value="<"
            onClick={() => {
              clearNumberProfit();
            }}
          >
            ขาดทุน
          </Radio.Button>
          <Radio.Button
            value="=0"
            onClick={() => {
              clearNumberProfit();
            }}
          >
            เท่าทุน
          </Radio.Button>
          <Radio.Button
            value="all"
            onClick={() => {
              clearNumberProfit();
            }}
          >
            ทั้งหมด
          </Radio.Button>
        </Radio.Group>
      </StyledPopoverFilterRow>
      {radioButton === '>' || radioButton === '<' ? (
        <>
          <StyledPopoverFilterRow className="divider">
            <Col span={12} className="text-title">
              ช่วงเงิน
            </Col>
          </StyledPopoverFilterRow>
          <StyledPopoverFilterRow>
            <Col style={{ width: 150 }}>ตั้งแต่</Col>
            <Col style={{ width: 120 }}>สิ้นสุด</Col>
          </StyledPopoverFilterRow>
          <StyledPopoverFilterRow justify="center">
            <Col style={{ width: 120 }}>
              <Form.Item
                rules={[
                  {
                    pattern: RegExp('^[0-9]+$'),
                    message: 'รูปแบบไม่ถูกต้อง',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (
                        getFieldValue('inputNumber2') < value &&
                        getFieldValue('inputNumber2') !== '' &&
                        value !== ''
                      ) {
                        return Promise.reject(new Error(`ตัวเลขต้องน้อยกว่า ${endNumber}`));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                name="inputNumber1"
              >
                <Input
                  allowClear
                  value={startNumber}
                  onChange={(event) => setStartNumber(event.target.value)}
                />
              </Form.Item>
            </Col>
            <Col style={{ textAlign: 'center', width: 30 }}>-</Col>
            <Col style={{ width: 120 }}>
              <Form.Item
                rules={[
                  {
                    pattern: RegExp('^[0-9]+$'),
                    message: 'รูปแบบไม่ถูกต้อง',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (
                        getFieldValue('inputNumber1') > value &&
                        getFieldValue('inputNumber1') !== '' &&
                        value !== ''
                      ) {
                        return Promise.reject(new Error(`ตัวเลขต้องมากกว่า ${startNumber}`));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                name="inputNumber2"
              >
                <Input
                  allowClear
                  value={endNumber}
                  onChange={(event) => setEndNumber(event.target.value)}
                />
              </Form.Item>
            </Col>
          </StyledPopoverFilterRow>
        </>
      ) : null}
      <StyledPopoverFilterRow>
        <Col span={24}>
          <Button
            onClick={() => {
              submit();
              setVisible(false);
            }}
            type="green"
            style={{ width: '100%', marginTop: 10 }}
            disabled={!radioButton}
          >
            ตกลง
          </Button>
        </Col>
      </StyledPopoverFilterRow>
    </Form>
  );

  const visibleChangeHandler = (isVisible) => {
    setVisible(isVisible);
  };

  return (
    <Input.Group compact>
      <Input
        style={{ width: '80%', textAlign: 'left' }}
        value={numberProfit ? `${textProfit()} (${numberProfit})` : textProfit()}
        placeholder=""
        readOnly
      />
      <Popover
        trigger="click"
        placement="bottomRight"
        content={contentProfit}
        visible={visible}
        onVisibleChange={visibleChangeHandler}
      >
        <StyledButtonFilter icon={<IconFilter />} style={{ width: '20%' }} />
      </Popover>
    </Input.Group>
  );
};

export default InputProfitFilter;
