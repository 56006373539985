import gql from 'graphql-tag';

export interface DataMasterServiceCategoryData {
  dataMasterServiceCategory: {
    _id: string;
    service_category_name: {
      th: string;
    };
    truck_size_name: {
      th: string | null;
    };
    truck_type_name: {
      th: string | null;
    };
  }[];
}

export default gql`
  query DataMasterServiceCategory {
    dataMasterServiceCategory {
      _id
      service_category_name {
        th
      }
      truck_size_name {
        th
      }
      truck_type_name {
        th
      }
    }
  }
`;
