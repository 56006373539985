import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Header from './Header';
import { StyledModal } from './Styles';
import { FormValues } from './formUtil/formValues.model';
import validateSchema from './formUtil/validateSchema';
import initialValue from './formUtil/initialValue';
import ParcelCreate from './ParcelCreate';

interface ModalParcelCreateWrapperProps {
  isVisible: boolean;
  onCancel: () => void;
}

interface ModalParcelCreateProps {
  onCancel: () => void;
}

const ModalParcelCreateWrapper: React.FC<ModalParcelCreateWrapperProps> = (props) => {
  const { isVisible, onCancel } = props;
  return (
    <StyledModal
      visible={isVisible}
      onCancel={() => {
        onCancel();
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalParcelCreate onCancel={onCancel} />
    </StyledModal>
  );
};

const ModalParcelCreate: React.FC<ModalParcelCreateProps> = (props) => {
  const { onCancel } = props;

  const methods = useForm<FormValues>({
    mode: 'onChange',
    shouldFocusError: true,
    shouldUnregister: false,
    resolver: yupResolver(validateSchema),
    defaultValues: initialValue,
  });

  return (
    <FormProvider {...methods}>
      <Header onCancel={onCancel} />
      <ParcelCreate />
    </FormProvider>
  );
};

export default ModalParcelCreateWrapper;
