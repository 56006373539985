/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import BANK_ACCOUNTS_QUERY, {
  BankAccountsData,
  BankAccounts,
} from 'shared/graphql/query/bankAccounts';

const { Option } = Select;

type BankAccountValue = {
  bankName: string;
  bankBranch: string;
  accountName: string;
  accountNumber: string;
};

type SelectBankAccountsFieldProps = {
  value?: string;
  onChange?: (value: BankAccountValue) => void;
};

const SelectBankAccountsField: React.FC<SelectBankAccountsFieldProps> = (props) => {
  const { value, onChange } = props;
  const [bankAccounts, setBankAccounts] = useState<BankAccounts[]>([]);
  const { data, loading, error } = useQuery<BankAccountsData>(BANK_ACCOUNTS_QUERY, {
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && data?.bankAccounts) {
      setBankAccounts([...data.bankAccounts.bankAccounts]);
    }
  }, [data?.bankAccounts, error, loading]);

  const options = bankAccounts.map((bankAccount, index) => (
    <Option key={bankAccount.account_number} value={index}>
      {`${bankAccount.account_number} (${bankAccount.bank_name})`}
    </Option>
  ));

  const selectedBankAccount = (valueInput: string) => {
    const bankAccount = bankAccounts.filter((_, i) => i === +valueInput);
    if (onChange && bankAccount.length === 1) {
      const { bank_name, account_number, account_name, bank_branch } = bankAccount[0];
      onChange({
        bankName: bank_name,
        bankBranch: bank_branch,
        accountName: account_name,
        accountNumber: account_number,
      });
    }
  };

  return (
    <Select
      style={{
        width: '100%',
      }}
      value={value}
      loading={loading}
      placeholder="เลือก"
      onChange={(valueChange) => selectedBankAccount(valueChange)}
    >
      {options}
    </Select>
  );
};

export default SelectBankAccountsField;
