/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext, ReactNode, useEffect } from 'react';
import { withRouter, RouteComponentProps, useRouteMatch, Link } from 'react-router-dom';
import { Input, DatePicker, message, Row, Col, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { formatDateTime, formatDate } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import PARCEL_ORDERS_QUERY, {
  ParcelOrdersData,
  ParcelOrdersVars,
  ParcelOrders,
} from 'shared/graphql/query/parcelOrders';
import PARCEL_ORDER_ALL_QUERY, {
  ParcelOrderAllData,
  ParcelOrderAllVars,
} from 'shared/graphql/query/parcelOrderAll';
import {
  CodContext,
  SelectedCodItemDetailType,
  SelectedCodItemType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/context/CodContext';
import { Text, Button, SelectCustomersField, TableSearch } from 'shared/components';
import { CodParcelOrderStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codParcelOrderStatus.model';
import { ToolsContainer, StyledCheckbox } from './Styles';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface CodItemListTableProps extends RouteComponentProps {
  status: CodParcelOrderStatus;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
}

type CodItemListDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: ReactNode;
  column4: string;
  column5: string;
  column6: string;
  column7: string;
  column8: string;
  column9: string;
};

const CodItemListTable: React.FC<CodItemListTableProps> = (props) => {
  const { status, pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [customerId, setCustomerId] = useState<string>('');
  const [codInNumber, setCodInNumber] = useState<string>('');
  const [trackingNumber, setTrackingNumber] = useState<string>('');
  const [dueDate, setDueDate] = useState<string>('');
  const [codInDate, setCodInDate] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('');

  const { selectedCodItems, setSelectedCodItems, setSelectedCodItemDetail } = useContext(
    CodContext,
  );

  const { path } = useRouteMatch();

  const { loading, error, data } = useQuery<ParcelOrdersData, ParcelOrdersVars>(
    PARCEL_ORDERS_QUERY,
    {
      variables: {
        cod_status: status,
        customer: customerId,
        cod_in_number: codInNumber,
        tracking_number: trackingNumber,
        due_date: dueDate,
        cod_in_date: codInDate,
        order_by: orderBy,
        order_type: orderType,
        page: currentPage,
        showData: pageSize,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const {
    data: parcelOrderAllData,
    loading: parcelOrderAllLoading,
    error: parcelOrderAllError,
  } = useQuery<ParcelOrderAllData, ParcelOrderAllVars>(PARCEL_ORDER_ALL_QUERY, {
    variables: {
      cod_status: status,
      customer: customerId,
      cod_in_number: codInNumber,
      tracking_number: trackingNumber,
      due_date: dueDate,
      cod_in_date: codInDate,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (selectedCodItems.codItemsId.length > 0) {
      const filter = parcelOrderAllData?.parcelOrderAll.parcelOrders
        .filter((item) => selectedCodItems.codItemsId.includes(item._id))
        .map((cod) => cod.cod_price);
      setSelectedCodItems(
        (prevState): SelectedCodItemType => ({
          ...prevState,
          codPrice: filter || [],
        }),
      );
    }
  }, [parcelOrderAllData?.parcelOrderAll.parcelOrders, selectedCodItems.codItemsId]);

  const selectCodItems = (item: ParcelOrders, checked: boolean, value: string) => {
    if (checked) {
      if (selectedCodItems.codItemsId.length === 0) {
        setSelectedCodItems(
          (prevState): SelectedCodItemType => ({
            ...prevState,
            codItemsId: [],
            customerId: item.customer?._id,
            customerFullName: item.customer?.full_name,
            customerAddress: item.customer?.address,
            customerTaxId: item.customer?.tax_id,
            customerPhone: item.customer?.phone,
            customerEmail: item.customer?.email,
          }),
        );
      }

      setSelectedCodItems(
        (prevState): SelectedCodItemType => ({
          ...prevState,
          codItemsId: [...prevState.codItemsId, value],
        }),
      );
    } else {
      const codItemsIdFilter = selectedCodItems.codItemsId.filter(
        (codItemId) => codItemId !== value,
      );
      setSelectedCodItems(
        (prevState): SelectedCodItemType => ({
          ...prevState,
          codItemsId: [...codItemsIdFilter],
        }),
      );
    }
  };

  const onCheckAllForOneCustomer = (check: boolean) => {
    if (customerId && parcelOrderAllData && !parcelOrderAllLoading && !parcelOrderAllError) {
      const selectedCodItemsId = selectedCodItems.codItemsId.map((item) => item);
      const filterCustomerId = parcelOrderAllData.parcelOrderAll.parcelOrders.filter(
        (item) => item?.customer?._id === customerId,
      );
      const currentCodItemsId = filterCustomerId.map((item) => item._id);

      const differenceCodItemsId = _.differenceWith(
        currentCodItemsId,
        selectedCodItemsId,
        _.isEqual,
      );

      if (check) {
        setSelectedCodItems(
          (prevState): SelectedCodItemType => ({
            ...prevState,
            codItemsId: [...selectedCodItemsId, ...differenceCodItemsId],
            customerId,
            customerFullName: parcelOrderAllData.parcelOrderAll.parcelOrders[0].customer.full_name,
            customerAddress: parcelOrderAllData.parcelOrderAll.parcelOrders[0].customer.address,
            customerTaxId: parcelOrderAllData.parcelOrderAll.parcelOrders[0].customer.tax_id,
            customerPhone: parcelOrderAllData.parcelOrderAll.parcelOrders[0].customer.phone,
            customerEmail: parcelOrderAllData.parcelOrderAll.parcelOrders[0].customer.email,
          }),
        );
      }

      if (!check) {
        const filterCodItemsId = selectedCodItemsId.filter(
          (item) => !currentCodItemsId.includes(item),
        );

        setSelectedCodItems(
          (prevState): SelectedCodItemType => ({
            ...prevState,
            codItemsId: filterCodItemsId,
          }),
        );
      }
    }
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
      search: customerId && (
        <StyledCheckbox
          onChange={(event) => {
            onCheckAllForOneCustomer(event.target.checked);
          }}
          checked={
            parcelOrderAllData?.parcelOrderAll.parcelOrders.every((item) =>
              selectedCodItems.codItemsId.includes(item._id),
            ) && parcelOrderAllData?.parcelOrderAll.parcelOrders.length > 0
          }
          value={selectedCodItems.codItemsId}
        />
      ),
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'column1',
      width: 300,
      search: (
        <SelectCustomersField
          onChange={(value: string) => {
            if (!value) {
              setSelectedCodItems(
                (prevState): SelectedCodItemType => ({
                  ...prevState,
                  codItemsId: [],
                  customerId: '',
                  customerFullName: '',
                  customerAddress: '',
                  customerTaxId: '',
                  customerPhone: [],
                  customerEmail: [],
                }),
              );
            }
            setCustomerId(value);
            onCheckAllForOneCustomer(false);
          }}
        />
      ),
    },
    {
      title: 'รหัส COD-IN',
      dataIndex: 'column2',
      sortName: 'cod_in_number',
      width: 180,
      search: (
        <Input
          allowClear
          value={codInNumber}
          placeholder="ค้นหา"
          onChange={(event) => {
            setCodInNumber(event.target.value);
            onCheckAllForOneCustomer(false);
          }}
        />
      ),
    },
    {
      title: 'มูลค่า COD',
      dataIndex: 'column3',
      sortName: 'cod_price',
      width: 150,
      align: 'right',
    },
    {
      title: 'Tracking Number',
      dataIndex: 'column4',
      width: 250,
      search: (
        <Input
          allowClear
          value={trackingNumber}
          placeholder="ค้นหา"
          onChange={(event) => {
            setTrackingNumber(event.target.value);
            onCheckAllForOneCustomer(false);
          }}
        />
      ),
    },
    {
      title: 'วันที่ต้องทำจ่าย',
      dataIndex: 'column5',
      width: 250,
      search: (
        <RangePicker
          onChange={(values) => {
            const formatRangDate =
              values === null
                ? ''
                : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

            setDueDate(formatRangDate);
            onCheckAllForOneCustomer(false);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'วันที่แจ้งโอน',
      dataIndex: 'column6',
      width: 250,
      search: (
        <RangePicker
          onChange={(values) => {
            const formatRangDate =
              values === null
                ? ''
                : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

            setCodInDate(formatRangDate);
            onCheckAllForOneCustomer(false);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'ผู้ตรวจสอบ',
      dataIndex: 'column7',
      width: 180,
    },
    ...(status === CodParcelOrderStatus.COD_OUT
      ? [
          {
            title: 'รหัสCOD-OUT',
            dataIndex: 'column8',
            sortName: 'cod_out_number',
            width: 180,
          },
        ]
      : []),
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'column9',
      sortName: 'updatedAt',
      width: 180,
    },
  ];

  let codItemListData: CodItemListDataTable[] = [];
  let totalDocument = 0;

  if (data?.parcelOrders) {
    const { parcelOrders } = data.parcelOrders;
    codItemListData = parcelOrders.map(
      (item, index): CodItemListDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            {status === CodParcelOrderStatus.WAIT_TO_PAY && (
              <StyledCheckbox
                checked={selectedCodItems.codItemsId.indexOf(item._id) !== -1}
                disabled={
                  selectedCodItems?.codItemsId.length > 0 &&
                  item.customer?._id !== selectedCodItems?.customerId
                }
                value={item._id}
                onChange={(event) => selectCodItems(item, event.target.checked, event.target.value)}
              />
            )}
            <Link to={`${path}/detail-item/${item._id}`}>
              <Button
                onClick={() => {
                  setSelectedCodItemDetail(
                    (prevState): SelectedCodItemDetailType => ({
                      ...prevState,
                      codItemId: item._id,
                      codItemStatus: item.cod_status,
                      trackingNumber: item.tracking_number,
                    }),
                  );
                }}
                color="grey--text-only"
                icon={<SearchOutlined />}
                size="small"
              />
            </Link>
          </ToolsContainer>
        ),
        column1: item.customer?.full_name || '-',
        column2: item.cod_in_number || '-',
        column3: (
          <Text strong customType="success">
            {formatCurrency(item.cod_price) || '-'}
          </Text>
        ),
        column4: item.tracking_number || '-',
        column5: item.due_date ? formatDate(item.due_date) : '-',
        column6: item.cod_in_date ? formatDateTime(item.cod_in_date) : '-',
        column7: item.checked_by?.full_name || '-',
        column8: item.cod_out_number || '-',
        column9: item.updatedAt ? formatDateTime(item.updatedAt) : '-',
      }),
    );

    totalDocument = data.parcelOrders.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={codItemListData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value: number) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />
    </>
  );
};

export default withRouter(CodItemListTable);
