import React, { useRef } from 'react';
import { Col, message, Row } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import SINGLE_UPLOAD_MUTATION from 'shared/graphql/mutation/singleUpload';
import CREATE_COSTING_DOCUMENT_MUTATION, {
  CreateCostingDocumentData,
  CreateCostingDocumentVars,
} from 'shared/graphql/mutation/createCostingDocument';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { Text } from 'shared/components';
import DocumentList from './DocumentList';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

type CostingOtherDocument = {
  costingId: string;
};

const CostingOtherDocument: React.FC<CostingOtherDocument> = (props) => {
  const { costingId } = props;
  const filePondRef = useRef<any>();
  const [createCostingDocument] = useMutation<CreateCostingDocumentData, CreateCostingDocumentVars>(
    CREATE_COSTING_DOCUMENT_MUTATION,
    {
      update: () => {
        message.success('เพิ่มไฟล์เอกสารแล้ว');
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['CostingDocuments'],
    },
  );
  const [singleUpload] = useMutation(SINGLE_UPLOAD_MUTATION, {
    update: (_cache, { data }) => {
      const { path } = data.singleUpload;
      createCostingDocument({
        variables: {
          costing_id: costingId,
          document_file: path,
        },
      });
    },
  });

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          อัพโหลดเอกสารทั่วไป
          <div>
            <Text customType="grey">
              (รูปแบบไฟล์ที่รองรับ .jpg , .jpeg , .png , .pdf , และขนาดไฟล์ไม่เกิน 2Mb.)
            </Text>
          </div>
        </Col>
        <Col span={24}>
          <FilePond
            ref={filePondRef}
            server={{
              process: (_fieldName, file, _metadata, _load, error) => {
                singleUpload({
                  variables: {
                    file,
                  },
                })
                  .then(() => {
                    filePondRef.current.removeFile();
                  })
                  .catch((err) => {
                    message.error(err.message);
                    error(err);
                  });
              },
            }}
            acceptedFileTypes={['image/jpeg', 'image/png', 'application/pdf']}
            maxFileSize="2MB"
          />
        </Col>
      </Row>
      <DocumentList costingId={costingId} />
    </>
  );
};

export default CostingOtherDocument;
