/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { useFormikContext } from 'formik';
import PAYEE_QUERY from 'shared/graphql/query/payee';

const { Option } = Select;

const SelectFieldBankAccount = () => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext();
  const { loading, error, data } = useQuery(PAYEE_QUERY, {
    variables: {
      _id: values.payeeId,
    },
  });
  const options =
    loading || error
      ? null
      : data.payee.bank_account
          .filter((item) => item.account_number !== '')
          .map((item) => {
            const textOption = `${item.account_number} ${
              item.bank_name ? `(${item.bank_name})` : ''
            }`;

            return (
              <Option title={textOption} key={item.account_number} value={item.account_number}>
                {textOption}
              </Option>
            );
          });

  const onChange = (value) => {
    const bankAccount = data.payee.bank_account.find((item) => item.account_number === value);

    if (!bankAccount) {
      setFieldValue('bankName', '');
      setFieldValue('bankBranch', '');
      setFieldValue('accountName', '');
      setFieldValue('accountNumber', '');
      return;
    }

    const {
      bank_name: bankName,
      bank_branch: bankBranch,
      account_name: accountName,
      account_number: accountNumber,
    } = bankAccount;
    setFieldValue('bankName', bankName);
    setFieldValue('bankBranch', bankBranch);
    setFieldValue('accountName', accountName);
    setFieldValue('accountNumber', accountNumber);
  };

  return (
    <Select
      value={values.accountNumber}
      style={{ width: '100%' }}
      loading={loading}
      onChange={onChange}
      onBlur={() => setFieldTouched('accountNumber')}
      name="accountNumber"
    >
      <Option value="">เลือกบัญชีที่ต้องการโอน</Option>
      {options}
    </Select>
  );
};

export default SelectFieldBankAccount;
