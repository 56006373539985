/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode } from 'react';
import CUSTOMER_QUERY, { CustomerData, CustomerVars } from 'shared/graphql/query/customer';
import CODS_QUERY, { CodsData, CodsVars } from 'shared/graphql/query/cods';
import { useQuery } from '@apollo/react-hooks';
import { Input, DatePicker, message, Row, Col } from 'antd';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDateTime } from 'shared/utils/dateTime';
import { Text } from 'shared/components';
import { StyledModal, StyledTable, StyledCheckbox, ToolsContainer } from './Styles';
import Header from './Header';

const { RangePicker } = DatePicker;

type Cod = {
  id: string;
  codNumber: string;
  pickUpDate: string;
  deliveredDate: string;
  bookingNumber: string;
  totalCod: number;
  dueDate: string;
  confirmStatus: number;
  totalVendor: number;
  customerFullName: string;
};

type ModalAddCodWrapperProps = {
  visible: boolean;
  customerId: string;
  cods?: Cod[];
  onCancel: () => void;
  onOk: (value: Cod[]) => void;
};

type ModalAddCodProps = {
  customerId: string;
  cods: Cod[];
  onCancel: () => void;
  onOk: (value: Cod[]) => void;
};

type CodInListDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: string;
  column4: string;
  column5: ReactNode;
  column6: string;
  column7: string;
};

const ModalAddCodWrapper: React.FC<ModalAddCodWrapperProps> = (props) => {
  const { visible, onCancel, cods, onOk, customerId } = props;
  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalAddCod onCancel={onCancel} cods={cods || []} onOk={onOk} customerId={customerId} />
    </StyledModal>
  );
};

const ModalAddCod: React.FC<ModalAddCodProps> = (props) => {
  const { onCancel, cods, onOk, customerId } = props;
  const [selectedCodsIn, setSelectedCodsIn] = useState<Cod[]>(cods);
  const [codInNumber, setCodInNumber] = useState<string>('');
  const [pickUpDate, setPickUpDate] = useState<string>('');
  const [deliveredDate, setDeliveredDate] = useState<string>('');
  const [bookingNumber, setBookingNumber] = useState<string>('');
  const [dueDate, setDueDate] = useState<string>('');
  const [pageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data: customerData } = useQuery<CustomerData, CustomerVars>(CUSTOMER_QUERY, {
    variables: {
      _id: customerId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading, data } = useQuery<CodsData, CodsVars>(CODS_QUERY, {
    variables: {
      customer: customerId,
      cod_in_number: codInNumber,
      pick_up_date: pickUpDate,
      delivered_date: deliveredDate,
      booking_number: bookingNumber,
      due_date: dueDate,
      cod_status: 2,
      page: currentPage,
      showData: pageSize,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">รหัส COD-IN</div>
          <div>
            <Input
              allowClear
              value={codInNumber}
              placeholder="ค้นหา"
              onChange={(event) => setCodInNumber(event.target.value)}
            />
          </div>
        </div>
      ),
      dataIndex: 'column1',
      width: 160,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">วันที่รับสินค้า</div>
          <div>
            <RangePicker
              onChange={(values) => {
                const formatRangDate =
                  values === null
                    ? ''
                    : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

                setPickUpDate(formatRangDate);
              }}
              format="YYYY/MM/DD"
              placeholder={['เริ่มต้น', 'สิ้นสุด']}
            />
          </div>
        </div>
      ),
      dataIndex: 'column2',
      width: 150,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">วันที่ส่งสำเร็จ</div>
          <div>
            <RangePicker
              onChange={(values) => {
                const formatRangDate =
                  values === null
                    ? ''
                    : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

                setDeliveredDate(formatRangDate);
              }}
              format="YYYY/MM/DD"
              placeholder={['เริ่มต้น', 'สิ้นสุด']}
            />
          </div>
        </div>
      ),
      dataIndex: 'column3',
      width: 160,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">Booking Number</div>
          <div>
            <Input
              allowClear
              value={bookingNumber}
              placeholder="ค้นหา"
              onChange={(event) => setBookingNumber(event.target.value)}
            />
          </div>
        </div>
      ),
      dataIndex: 'column4',
      width: 150,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">ยอดรวม COD (บาท)</div>
        </div>
      ),
      dataIndex: 'column5',
      width: 150,
      align: 'right',
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">วันที่ต้องทำจ่าย</div>
          <div>
            <RangePicker
              onChange={(values) => {
                const formatRangDate =
                  values === null
                    ? ''
                    : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

                setDueDate(formatRangDate);
              }}
              format="YYYY/MM/DD"
              placeholder={['เริ่มต้น', 'สิ้นสุด']}
            />
          </div>
        </div>
      ),
      dataIndex: 'column6',
      width: 160,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">3PL ทั้งหมด</div>
        </div>
      ),
      dataIndex: 'column7',
      width: 100,
    },
  ];

  const selectCodsIn = (checked: boolean, value: Cod) => {
    if (checked) {
      setSelectedCodsIn((prevState) => {
        return [...prevState, value];
      });
    } else {
      const codsInFilter = selectedCodsIn.filter((cod) => cod.id !== value.id);
      setSelectedCodsIn([...codsInFilter]);
    }
  };

  let codsInDataTable: CodInListDataTable[] = [];
  let totalDocument = 0;

  if (data?.cods) {
    const { cods: codsData } = data.cods;
    codsInDataTable = codsData.map(
      (item, index): CodInListDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <StyledCheckbox
              checked={selectedCodsIn.map((cod) => cod.id).indexOf(item._id) !== -1}
              value={item._id}
              onChange={(event) =>
                selectCodsIn(event.target.checked, {
                  id: item._id,
                  codNumber: item.cod_in_number,
                  pickUpDate: item.pick_up_date,
                  deliveredDate: item.delivered_date,
                  bookingNumber: item.booking_number,
                  totalCod: item.total_cod,
                  dueDate: item.due_date,
                  confirmStatus: item.confirm_status,
                  totalVendor: item.total_vendor,
                  customerFullName: item.customer.full_name,
                })
              }
            />
          </ToolsContainer>
        ),
        column1: item.cod_in_number || '-',
        column2: formatDateTime(item.pick_up_date) || '-',
        column3: formatDateTime(item.delivered_date) || '-',
        column4: item.booking_number || '-',
        column5: (
          <Text strong customType="primary">
            {formatCurrency(item.total_cod)}
          </Text>
        ),
        column6: formatDateTime(item.due_date) || '-',
        column7: `${item.confirm_status}/${item.total_vendor}`,
      }),
    );

    totalDocument = data?.cods.totalDocument;
  }

  return (
    <div>
      <Header
        onSubmit={() => onOk(selectedCodsIn)}
        onCancel={onCancel}
        btnDisabled={selectedCodsIn.length === 0}
      />

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row>
            <Col span={8}>
              ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>
              <div>
                <Input value={customerData?.customer.full_name || '-'} disabled />
              </div>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <StyledTable
            scroll={{ x: 'max-content' }}
            columns={columns}
            dataSource={codsInDataTable}
            loading={loading}
            pagination={{
              current: currentPage,
              pageSize,
              total: totalDocument,
              showSizeChanger: false,
              onChange: (value: number) => setCurrentPage(value),
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ModalAddCodWrapper;
