import gql from 'graphql-tag';

export interface CreateParcelDetailData {
  createParcelDetail: {
    _id: string;
  };
}

export interface CreateParcelDetailVars {
  customer: string;
  pickupRangeDate: string;
}

export default gql`
  mutation CreateParcelDetail($customer: ID, $pickupRangeDate: String) {
    createParcelDetail(create: { customer: $customer, pickupRangeDate: $pickupRangeDate }) {
      _id
    }
  }
`;
