import gql from 'graphql-tag';

export enum ReportType {
  AR = 'AR',
  AP = 'AP',
  CT = 'CT',
  JV = 'JV',
  COD = 'COD',
  LOG_AR = 'logAR',
}

export enum ReportNameAll {
  // AR
  AR_SUMMARY = 'AR SUMMARY',
  INVOICE_DAILY_SUMMARY = 'INVOICE DAILY SUMMARY',
  INVOICE_TRANSACTION_REPORT = 'INVOICE Transaction Report',
  CUS_DEBT_SUMMARY = 'CUS DEBT Summary',
  CUS_DEBT_TRANSACTIONS = 'CUS DEBT Transactions',
  RECEIPT_TRANSACTIONS = 'RECEIPT Transactions',
  // AP
  AP_TRANSACTIONS = 'AP Transactions',
  VENDOR_DEBT_TRANSACTIONS = 'VENDOR DEBT Transactions',
  APP_TRANSACTIONS = 'APP Transactions',
  APPROVE_SUMMARY = 'Approve Summary',
  // CT
  CT_TRANSACTIONS = 'CT Transactions',
  PARCEL_DETAIL = 'Parcel Detail',
  // JV
  JV_TRANSACTIONS = 'JV Transactions',
  // COD
  COD_TRANSACTIONS = 'COD Transactions',
  COD_PARCEL_TRANSACTIONS = 'COD Parcel Transactions',
  // logAR
  LOG_AR_TRANSACTIONS = 'logAR Transactions',
}

export interface ReportName {
  _id: string;
  report_type: ReportType;
  report_name: {
    name: ReportNameAll;
    description: string;
    criteria: {
      option_name: string;
      option_type: 'DATE' | 'MONTH' | '';
      option_range: boolean;
      option_select: string[];
    }[];
  }[];
}

export interface ReportNameData {
  reportName: ReportName[];
}

export default gql`
  query ReportName {
    reportName {
      _id
      report_type
      report_name {
        name
        description
        criteria {
          option_name
          option_type
          option_range
          option_select
        }
      }
    }
  }
`;
