/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Select, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import PAYMENTS_QUERY from 'shared/graphql/query/payments';

const { Option } = Select;
const SelectPaymentField = (props) => {
  const [paymentNumber, setPaymentNumber] = useState('');
  const { loading, error, data } = useQuery(PAYMENTS_QUERY, {
    variables: {
      payment_number: paymentNumber,
      showData: 10,
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Select
      style={{
        width: '100%',
      }}
      allowClear
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      showSearch
      placeholder="ค้นหา"
      onSearch={(value) => setPaymentNumber(value)}
      onChange={(value) => props.onChange(value)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {loading || error
        ? []
        : data.payments.payments.map((item) => (
            <Option key={item._id} value={item._id}>
              {item.payment_number}
            </Option>
          ))}
    </Select>
  );
};

export default SelectPaymentField;
