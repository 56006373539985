import gql from 'graphql-tag';

interface BillingInvoice {
  invoice: string;
}

export interface CalBillingNoteVars {
  _id?: string;
  bill_invoice?: BillingInvoice[];
}

export interface InvoiceDetail {
  costing: {
    checked_date: string;
    costing_number: string;
    completed_date: string;
  };
  number_ref: string;
  grand_total_price: number;
}

interface Invoice {
  _id: string;
  invoice_number: string;
  system_request?: string;
  grand_total_price: number;
  total_price: number;
  create_date: string;
  service_date: string;
  due_date: string;
  invoice_detail: InvoiceDetail[];
}

export interface CalBillingNoteData {
  calBillingNote: {
    bill_invoice: {
      invoice: Invoice;
    }[];
    grand_total_price: number;
  };
}

export default gql`
  query CalBillingNote($_id: ID, $bill_invoice: [inputCalBillInvoice]) {
    calBillingNote(_id: $_id, bill_invoice: $bill_invoice) {
      bill_invoice {
        invoice {
          _id
          invoice_number
          system_request
          grand_total_price
          total_price
          create_date
          service_date
          due_date
          invoice_detail {
            costing {
              checked_date
              costing_number
              completed_date
            }
            number_ref
            grand_total_price
          }
        }
      }
      grand_total_price
    }
  }
`;
