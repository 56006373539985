import gql from 'graphql-tag';
import { JvStatus } from 'views/Setting/common/model/jvStatus.model';

export interface UpdateJvData {
  updateJV: {
    _id: string;
    jv_status: JvStatus;
  };
}

export interface UpdateJvVars {
  _id: string;
  jv_status?: JvStatus;
  description: string;
  chartOfAccount: string;
  code: string;
  account_name: string;
  value: number;
  document_date: string;
  cancel_remark?: string;
}

export default gql`
  mutation UpdateJv(
    $_id: ID
    $jv_status: Int
    $description: String
    $chartOfAccount: ID
    $code: String
    $account_name: String
    $value: Float
    $document_date: String
    $cancel_remark: String
  ) {
    updateJV(
      _id: $_id
      update: {
        jv_status: $jv_status
        description: $description
        chartOfAccount: $chartOfAccount
        code: $code
        account_name: $account_name
        value: $value
        document_date: $document_date
        cancel_remark: $cancel_remark
      }
    ) {
      _id
      jv_status
    }
  }
`;
