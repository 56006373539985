import React, { useContext, useRef } from 'react';
import { Row, Col, Typography, Button, DatePicker, message, Input } from 'antd';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import formatCurrency from 'shared/utils/formatCurrency';
import { useMutation } from '@apollo/react-hooks';
import SINGLE_UPLOAD_MUTATION from 'shared/graphql/mutation/singleUpload';
import s3 from 'shared/config/s3';
import { PaymentVoucherContext } from 'views/AccountPayment/common/context/PaymentVoucherContext';
import moment from 'moment';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { StyledModal, StyledButton, FileContainer } from './Styles';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);
const { Text } = Typography;
const { TextArea } = Input;
const ModalAddDocument = (props) => {
  const filePondRef = useRef();
  const { visible, onCancel, showConfirm } = props;
  const { selectedPaymentVoucherDetail: selectedPaymentVoucherDetailContext } = useContext(
    PaymentVoucherContext,
  );
  const [
    selectedPaymentVoucherDetail,
    setSelectedPaymentVoucherDetail,
  ] = selectedPaymentVoucherDetailContext;
  const {
    grandTotalPrice,
    payeeFullName,
    proofSlip,
    dueDate,
    slipRemark,
  } = selectedPaymentVoucherDetail;
  const [singleUpload] = useMutation(SINGLE_UPLOAD_MUTATION, {
    update: (_cache, { data }) => {
      const { path } = data.singleUpload;
      setSelectedPaymentVoucherDetail((prevState) => ({
        ...prevState,
        proofSlip: path,
      }));
    },
  });

  let renderUploadFile = (
    <FilePond
      ref={filePondRef}
      maxFiles={1}
      server={{
        process: (fieldName, file, metadata, load, error) => {
          singleUpload({
            variables: {
              file,
            },
          }).catch((err) => {
            message.error(err.message);
            error(err);
          });
        },
      }}
      acceptedFileTypes={['image/jpeg', 'image/png', 'application/pdf']}
      maxFileSize="2MB"
    />
  );

  if (proofSlip) {
    renderUploadFile = (
      <FileContainer>
        <Button
          onClick={() =>
            setSelectedPaymentVoucherDetail((prevState) => ({
              ...prevState,
              proofSlip: '',
            }))
          }
          icon={<DeleteOutlined />}
          type="red--outline"
          style={{ marginRight: 10 }}
        />
        <a href={`${s3}/${proofSlip}`} target="_blank" rel="noopener noreferrer">
          {proofSlip}
        </a>
      </FileContainer>
    );
  }

  const onChangeDueDate = (date, dateString) => {
    setSelectedPaymentVoucherDetail((prevState) => ({
      ...prevState,
      dueDate: dateString,
    }));
  };

  const onChangeSlipRemark = (value) => {
    setSelectedPaymentVoucherDetail((prevState) => ({
      ...prevState,
      slipRemark: value,
    }));
  };

  return (
    <StyledModal
      title={
        <Row justify="space-between" align="middle">
          <Col>บันทึกการจ่าย</Col>
          <Col>
            <StyledButton
              onClick={showConfirm}
              icon={<SaveOutlined />}
              type="green"
              disabled={!proofSlip || !dueDate}
            >
              บันทึกการจ่าย
            </StyledButton>
            <StyledButton onClick={onCancel}>ปิด</StyledButton>
          </Col>
        </Row>
      }
      visible={visible}
      onCancel={onCancel}
      closable={false}
      className="custom-modal"
      footer={null}
      loading
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row>
            <Col span={6}>
              ผู้รับเงิน <Text type="grey">(เจ้าหนี้)</Text>:
            </Col>
            <Col span={18}>
              <Text strong>{payeeFullName}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6}>มูลค่าใบสำคัญจ่าย:</Col>
            <Col span={18}>
              <Text strong>{formatCurrency(grandTotalPrice)}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              วันที่จ่าย:<Text type="danger">*</Text>
            </Col>
            <Col span={18}>
              <DatePicker
                format="YYYY/MM/DD"
                value={!dueDate ? '' : moment(dueDate)}
                onChange={onChangeDueDate}
                style={{ width: 200 }}
                placeholder="วันที่จ่าย"
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          แนบไฟล์ หลักฐานการจ่าย:<Text type="danger">*</Text>{' '}
          <Text type="grey">
            (รูปแบบไฟล์ที่รองรับ .jpg , .jpeg , .png , .pdf , และขนาดไฟล์ไม่เกิน 2Mb.)
          </Text>
        </Col>
        <Col span={24}>{renderUploadFile}</Col>
        <Col span={24}>หมายเหตุ:</Col>
        <Col span={24}>
          <Input
            value={slipRemark}
            onChange={(event) => {
              onChangeSlipRemark(event.target.value);
            }}
            name="paymentRemark"
            placeholder="ระบุ"
            rows={3}
          />
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalAddDocument;
