/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Col, message, Modal, Row, Typography } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import COSTING_DOCUMENTS_QUERY, {
  CostingDocumentsData,
  CostingDocumentsVars,
} from 'shared/graphql/query/costingDocuments';
import DELETE_COSTING_DOCUMENTS_MUTATION, {
  DeleteCostingDocumentsData,
  DeleteCostingDocumentsVars,
} from 'shared/graphql/mutation/deleteCostingDocuments';
import { Button, Spin } from 'shared/components';
import { FileContainer } from './Styles';

const { confirm } = Modal;
const { Text } = Typography;

type DocumentListProps = {
  costingId: string;
};

const DocumentList: React.FC<DocumentListProps> = (props) => {
  const { costingId } = props;
  const [deleteCostingDocuments, { loading: deleteCostingDocumentsLoading }] = useMutation<
    DeleteCostingDocumentsData,
    DeleteCostingDocumentsVars
  >(DELETE_COSTING_DOCUMENTS_MUTATION, {
    update: () => {
      message.success('ลบไฟล์เอกสารแล้ว');
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['CostingDocuments'],
  });
  const { loading, data } = useQuery<CostingDocumentsData, CostingDocumentsVars>(
    COSTING_DOCUMENTS_QUERY,
    {
      variables: {
        costing_id: costingId,
      },
      onError: (err) => {
        message.error(err.message);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const showConfirmDeleteDocument = (id: string) => {
    const modal = {
      title: 'ยืนยัน',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          ต้องการ <Text strong>ลบไฟล์เอกสาร</Text> ใช่หรือไม่?
        </>
      ),
      okText: 'ตกลง',
      onOk() {
        deleteCostingDocuments({
          variables: {
            _id: [id],
          },
        });
      },
      cancelText: 'ยกเลิก',
    };

    confirm(modal);
  };

  let documentList = null;
  if (data && data.costingDocuments) {
    const { documents } = data.costingDocuments;
    documentList = documents.map((item) => (
      <Col key={item._id} span={24}>
        <FileContainer>
          <Button
            onClick={() => showConfirmDeleteDocument(item._id)}
            icon={<DeleteOutlined />}
            color="red--outline"
            style={{ marginRight: 10 }}
          />
          <a href={item.document_file} target="_blank" rel="noopener noreferrer">
            {item.document_file}
          </a>
        </FileContainer>
      </Col>
    ));
  }

  return (
    <Spin spinning={loading || deleteCostingDocumentsLoading}>
      <Row gutter={[16, 16]}>{documentList}</Row>
    </Spin>
  );
};

export default DocumentList;
