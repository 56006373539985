/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'antd';
import { Button } from 'shared/components';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppContext } from 'shared/context/AppContext';
import { DebtTrackerContext } from 'views/DebtTracker/common/context/DebtTrackerContext';
import { FormValues } from 'views/DebtTracker/Routes/DebtTrackerDetail/ModalDebtTrackerHistory/DebtTrackerHistory/DebtTrackerHistoryForm/formValues.model';
import validateSchema from 'views/DebtTracker/Routes/DebtTrackerDetail/ModalDebtTrackerHistory/DebtTrackerHistory/DebtTrackerHistoryForm/validateSchema';
import InputDebtTrackerHistoryForm from 'views/DebtTracker/Routes/DebtTrackerDetail/ModalDebtTrackerHistory/DebtTrackerHistory/DebtTrackerHistoryForm/InputDebtTrackerHistoryForm';
import SEARCH_FOLLOW_DEBT_HISTORY_LOG_QUERY, {
  SearchFollowDebtHistoryLogData,
  SearchFollowDebtHistoryLogVars,
} from 'shared/graphql/query/searchFollowDebtHistoryLog';
import ModalConfirmEdit from './ModalConfirmEdit';

const DebtTrackerHistoryEdit: React.FC = () => {
  const { userDetail } = useContext(AppContext);
  const { selectedDebtTrackerHistory, debtTrackerHistoryValue } = useContext(DebtTrackerContext);

  const [isOpenModalConfirmEdit, setOpenModalConfirmEdit] = useState<boolean>(false);

  const { data, error, loading } = useQuery<
    SearchFollowDebtHistoryLogData,
    SearchFollowDebtHistoryLogVars
  >(SEARCH_FOLLOW_DEBT_HISTORY_LOG_QUERY, {
    variables: {
      historyLogID: selectedDebtTrackerHistory.historyLogId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const methods = useForm<FormValues>({
    mode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver(validateSchema),
    defaultValues: {
      logARId: '',
      invoiceId: '',
      followDebtHistoryId: '',
      followDetailId: '',
      historyLogId: '',
      followStatus: '',
      followStatusDetail: '',
      action: '',
      actionDetail: '',
      nextFollowDate: moment().format('YYYY/MM/DD'),
      detail: '',
      employeeId: '',
      status: 0,
    },
  });

  const { handleSubmit, formState, reset } = methods;
  const { isDirty } = formState;

  useEffect(() => {
    if (!loading && !error && data?.searchFollowDebtHistoryLog) {
      const {
        _id,
        followDetailID,
        followStatus,
        followStatusDetail,
        action,
        actionDetail,
        nextfollowDate,
        detail,
      } = data.searchFollowDebtHistoryLog;

      reset({
        employeeId: userDetail.employeeId,
        followDebtHistoryId: debtTrackerHistoryValue.followDebtHistoryId,
        followDetailId: followDetailID,
        historyLogId: _id,
        followStatus,
        followStatusDetail,
        action,
        actionDetail,
        nextFollowDate: nextfollowDate,
        detail,
      });
    }
  }, [
    data?.searchFollowDebtHistoryLog,
    debtTrackerHistoryValue.followDebtHistoryId,
    error,
    loading,
    reset,
    userDetail.employeeId,
  ]);

  const submit = handleSubmit(() => {
    setOpenModalConfirmEdit(true);
  });

  return (
    <>
      <FormProvider {...methods}>
        <InputDebtTrackerHistoryForm />
        <Row justify="end">
          <Col>
            <Button disabled={!isDirty} color="green" onClick={submit}>
              บันทึกการตามหนี้
            </Button>
          </Col>
        </Row>
        <ModalConfirmEdit
          visible={isOpenModalConfirmEdit}
          onCancel={() => setOpenModalConfirmEdit(false)}
        />
      </FormProvider>
    </>
  );
};

export default DebtTrackerHistoryEdit;
