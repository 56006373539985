import gql from 'graphql-tag';

export interface UpdatePaymentVoucherData {
  updatePaymentVoucher: {
    _id: string;
    voucher_status: number;
  };
}

export interface UpdatePaymentVoucherVars {
  _id?: string;
  voucher_status?: number;
  proof_slip?: string;
  due_date?: string;
  voucher_remark?: string;
  reject_remark?: string;
  cancel_remark?: string;
  slip_remark?: string;
  save_status?: number;
  payment?: string[];
}

export default gql`
  mutation UpdatePaymentVoucher(
    $_id: ID!
    $voucher_status: Int
    $proof_slip: String
    $due_date: String
    $voucher_remark: String
    $reject_remark: String
    $cancel_remark: String
    $slip_remark: String
    $save_status: Int
    $payment: [ID]
  ) {
    updatePaymentVoucher(
      _id: $_id
      updatePaymentVoucher: {
        voucher_status: $voucher_status
        proof_slip: $proof_slip
        due_date: $due_date
        voucher_remark: $voucher_remark
        reject_remark: $reject_remark
        cancel_remark: $cancel_remark
        slip_remark: $slip_remark
        save_status: $save_status
        payment: $payment
      }
    ) {
      _id
      voucher_status
    }
  }
`;
