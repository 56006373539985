/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import {
  ApprovePaymentContext,
  SelectedApprovePaymentDetailType,
} from 'views/AccountPayment/common/context/ApprovePaymentContext';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountPayment/Routes/ApprovePayment/AppTable/ModalAppDetail/model/formValues.model';
import UPDATE_APP_MUTATION, {
  UpdateAppData,
  UpdateAppVars,
} from 'shared/graphql/mutation/updateApp';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { formatDate } from 'shared/utils/dateTime';
import { StyledModal } from './Styles';

type ModalConfirmUpdateProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmUpdate: React.FC<ModalConfirmUpdateProps> = (props) => {
  const { onCancel, visible } = props;
  const { selectedApprovePaymentDetail, setSelectedApprovePaymentDetail } = useContext(
    ApprovePaymentContext,
  );
  const { watch } = useFormContext<FormValues>();
  const { modalConfirmUpdate, appId } = selectedApprovePaymentDetail;
  const { grandTotalPrice, appNumber } = modalConfirmUpdate;

  const [updateApp, { loading }] = useMutation<UpdateAppData, UpdateAppVars>(UPDATE_APP_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.updateApp._id) {
        message.success('อนุมัติจ่ายแล้ว');
        onCancel();
        setSelectedApprovePaymentDetail(
          (prevState): SelectedApprovePaymentDetailType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }
    },
    onError: (err) => {
      const textError = err.graphQLErrors.length > 0 ? err.graphQLErrors[0].message : err.message;
      message.error(textError);
    },
    refetchQueries: () => ['App', 'AppStatus', 'Apps'],
  });

  const confirmUpdate = () => {
    updateApp({
      variables: {
        _id: appId,
        app_status: 2,
        // round_paid: watch('roundPaid'),
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="อนุมัติการจ่ายหรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="blue"
          onClick={() => confirmUpdate()}
          loading={loading}
        >
          อนุมัติจ่าย
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>วันที่รอบจ่าย:</Col>
        <Col span={18}>
          <Text strong>{formatDate(watch('roundPaid'))}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>รหัสรอบจ่าย:</Col>
        <Col span={18}>
          <Text strong>{appNumber}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>มูลค่ารอบจ่าย (บาท):</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(grandTotalPrice, '0')}
          </Text>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmUpdate;
