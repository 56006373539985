import React from 'react';
import { Tag } from 'shared/components';
import { TagColorType } from 'shared/components/Tag';
import { PaymentCodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/paymentCodStatus.model';

type TagPaymentCodStatusProps = {
  status: PaymentCodStatus;
};

const getTextColorStatus = (
  status: PaymentCodStatus,
): {
  color: TagColorType | undefined;
  text: string | number;
  outline: boolean;
} => {
  switch (status) {
    case 1:
      return { color: 'blue', text: 'รอบันทึกการจ่าย', outline: false };
    case 2:
      return { color: 'green', text: 'จ่ายเรียบร้อย', outline: false };
    default:
      return { color: undefined, text: status, outline: false };
  }
};

const TagPaymentCodStatus: React.FC<TagPaymentCodStatusProps> = (props) => {
  const { status } = props;
  const textColor = getTextColorStatus(status);

  return (
    <Tag customColor={textColor.color} outline={textColor.outline}>
      {textColor.text}
    </Tag>
  );
};

export default TagPaymentCodStatus;
