import gql from 'graphql-tag';
import { CodParcelStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codParcelStatus.model';

export interface UpdateCodParcelData {
  updateCodParcel: {
    _id: string;
    cod_status: CodParcelStatus;
  };
}

export interface UpdateCodParcelVars {
  _id: string;
  document?: string[];
  cod_status?: CodParcelStatus;
  cod_in_date?: string;
  cod_remark?: string;
  reject_remark?: string;
}

export default gql`
  mutation UpdateCodParcel(
    $_id: ID!
    $document: [String]
    $cod_status: Int
    $cod_in_date: String
    $cod_remark: String
    $reject_remark: String
  ) {
    updateCodParcel(
      _id: $_id
      update: {
        document: $document
        cod_status: $cod_status
        cod_in_date: $cod_in_date
        cod_remark: $cod_remark
        reject_remark: $reject_remark
      }
    ) {
      _id
    }
  }
`;
