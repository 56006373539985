import React, { useContext, useState } from 'react';
import { Col, Row } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { CostingContext } from 'views/Costing/common/context/CostingContext';
import { useHistory } from 'react-router-dom';
import { CostingStatus } from 'views/Costing/common/model/costingStatus.model';
import { Container, StyledButton, TextHeader } from './Styles';
import ModalConfirmReject from './ModalConfirmReject';
import ModalConfirmChecked from './ModalConfirmChecked';
import ModalConfirmRejectEdit from './ModalConfirmRejectEdit';
import ModalConfirmEdit from './ModalConfirmEdit';

const Header: React.FC = () => {
  const history = useHistory();
  const [isOpenModalConfirmReject, setOpenModalConfirmReject] = useState<boolean>(false);
  const [isOpenModalConfirmRejectEdit, setOpenModalConfirmRejectEdit] = useState<boolean>(false);
  const [isOpenModalConfirmChecked, setOpenModalConfirmChecked] = useState<boolean>(false);
  const [isOpenModalConfirmEdit, setOpenModalConfirmEdit] = useState<boolean>(false);
  const { selectedCostingDetail } = useContext(CostingContext);
  const { costingNumber, costingStatus, isLoading, backUrl, editRequest } = selectedCostingDetail!;

  const closeModalHandler = () => {
    history.push(backUrl);
  };

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>รหัสใบต้นทุน-ราคาขาย: {costingNumber}</TextHeader>
        </Col>
        <Col>
          {costingStatus === CostingStatus.CHECKING && (
            <>
              <StyledButton
                disabled={isLoading}
                icon={<CheckCircleOutlined />}
                color="green"
                onClick={() => setOpenModalConfirmChecked(true)}
              >
                ตรวจสอบแล้ว
              </StyledButton>
              <ModalConfirmChecked
                visible={isOpenModalConfirmChecked}
                onCancel={() => setOpenModalConfirmChecked(false)}
              />
            </>
          )}

          {costingStatus === CostingStatus.CHECKING && (
            <>
              <StyledButton
                disabled={isLoading}
                icon={<CloseCircleOutlined />}
                color="red--light"
                onClick={() => setOpenModalConfirmReject(true)}
              >
                ปฏิเสธ
              </StyledButton>
              <ModalConfirmReject
                visible={isOpenModalConfirmReject}
                onCancel={() => setOpenModalConfirmReject(false)}
              />
            </>
          )}

          {costingStatus === CostingStatus.CHECKED && editRequest && (
            <>
              <StyledButton
                disabled={isLoading}
                icon={<CheckCircleOutlined />}
                color="green"
                onClick={() => setOpenModalConfirmEdit(true)}
              >
                อนุมัติแก้ไข
              </StyledButton>
              <StyledButton
                disabled={isLoading}
                icon={<CloseCircleOutlined />}
                color="red--light"
                onClick={() => setOpenModalConfirmRejectEdit(true)}
              >
                ปฏิเสธ
              </StyledButton>

              <ModalConfirmEdit
                visible={isOpenModalConfirmEdit}
                onCancel={() => setOpenModalConfirmEdit(false)}
              />

              <ModalConfirmRejectEdit
                visible={isOpenModalConfirmRejectEdit}
                onCancel={() => setOpenModalConfirmRejectEdit(false)}
              />
            </>
          )}
          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
