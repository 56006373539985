import styled from 'styled-components';
import { Table } from 'antd';
import { mixin } from 'shared/utils/styles';

export const StyledTable = styled(Table)`
  ${mixin.backgroundTableEven}

  & .ant-table thead > tr > th {
    vertical-align: top;
    background-color: transparent;
  }

  & .table-header {
    &__search {
      padding-top: 6px;
    }
  }

  & .ant-table-row-expand-icon {
    margin-bottom: 9px;
  }
`;

export const ToolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
