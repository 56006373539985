import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SubTabs } from 'shared/components';
import APP_STATUS_QUERY, { AppStatusData } from 'shared/graphql/query/appStatus';
import { AppStatus } from 'views/AccountPayment/common/model/appStatus.model';

interface AppTabsProps {
  name: string;
  tab: AppStatus;
  onChange: (tab: AppStatus) => void;
}

const AppTabs: React.FC<AppTabsProps> = (props) => {
  const { tab, onChange, name } = props;
  const { data, refetch } = useQuery<AppStatusData>(APP_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const checkSelectTab = (id: AppStatus) => {
    return id === tab && name === 'app';
  };

  const selectTab = (index: AppStatus) => {
    refetch();
    onChange(index);
  };

  const tabs = [
    {
      id: AppStatus.PENDING,
      name: 'รออนุมัติจ่าย',
      color: 'badge-grey',
      count: data?.appStatus.pending || 0,
      divider: false,
    },
    {
      id: AppStatus.APPROVED,
      name: 'รอบันทึกการจ่าย',
      color: 'badge-grey',
      count: data?.appStatus.approved || 0,
      divider: false,
    },
    {
      id: AppStatus.PAID,
      name: 'จ่ายเรียบร้อย',
      color: 'badge-grey',
      count: data?.appStatus.paid || 0,
      divider: true,
    },
    {
      id: AppStatus.CANCEL,
      name: 'ยกเลิก',
      color: 'badge-grey',
      count: data?.appStatus.cancel || 0,
      divider: false,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default AppTabs;
