import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalDetailPaymentOrder/model/formValues.model';

type VatCondition = 'vat' | 'advance' | 'nonVat';

const useVatCondition = () => {
  const { watch } = useFormContext<FormValues>();
  const allFormValue = watch();

  const getVatCondition = (vatCondition: VatCondition) => {
    if (vatCondition === 'advance') {
      return {
        advanceType: true,
        vat: 0,
      };
    }

    if (vatCondition === 'nonVat') {
      return {
        advanceType: false,
        vat: 0,
      };
    }

    if (vatCondition === 'vat') {
      return {
        advanceType: false,
        vat: 7,
      };
    }

    return undefined;
  };

  const getPaymentList = () => {
    const { paymentList } = allFormValue;
    const paymentListData = [...paymentList];
    return paymentListData.map((item) => {
      const vatCondition = getVatCondition(item.vatCondition);

      return {
        ...item,
        price: item.price ? +item.price : 0,
        wht: item.wht ? +item.wht : 0,
        qty: item.qty ? +item.qty : 0,
        discount: item.discount ? +item.discount : 0,
        advanceType: vatCondition?.advanceType || false,
        vat: vatCondition?.vat || 0,
      };
    });
  };

  return {
    getPaymentList: getPaymentList(),
  };
};

export default useVatCondition;
