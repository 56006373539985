/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext, ReactNode } from 'react';
import { Input, DatePicker, Row, Col, Select, Modal } from 'antd';
import { SearchOutlined, FileExcelOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import {
  CodInContext,
  SelectedCodItemType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/CodInContext';
import { Text, Button, TableSearch } from 'shared/components';
import TagCodItemStatus from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/components/TagCodItemStatus';
import { CodItem } from 'shared/graphql/query/cod';
import { CodItemStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/model/codItemStatus.model';
import moment from 'moment';
import { CodInStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/model/codInStatus.model';
import baseUrl from 'shared/config/baseUrl';
import { ToolsContainer } from './Styles';
import ModalCodItemDetail from './ModalCodItemDetail';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

type CodIn3PLTableProps = {
  data: {
    codInId: string;
    codStatus: CodInStatus;
    codItems: CodItem[];
    customerFullName: string;
    bookingNumber: string;
    codInNumber: string;
    totalCod: number;
  };
};

type CodIn3PLDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: ReactNode;
  column3: ReactNode;
  column4: string;
  column5: string;
  column6: string;
  column7: string;
};

const CodIn3PLTable: React.FC<CodIn3PLTableProps> = (props) => {
  const { data } = props;
  const { codItems, codStatus, bookingNumber, customerFullName, codInId } = data;
  const { selectedCodItem, setSelectedCodItem } = useContext(CodInContext);
  const [codInDate, setCodInDate] = useState<string>('');
  const [deliveredDate, setDeliveredDate] = useState<string>('');
  const [pickUpDate, setPickUpDate] = useState<string>('');
  const [vendorName, setVendorName] = useState<string>('');
  const [codItemStatus, setCodItemStatus] = useState<CodItemStatus | ''>('');

  const checkDateBetween = (date: string, rangDate: string) => {
    const splitDate = rangDate.split(':');
    return moment(date, 'YYYY/MM/DD').isBetween(splitDate[0], splitDate[1], undefined, '[]');
  };

  const getCodItemsSearch = () => {
    let codItemSearch = [...codItems];
    if (vendorName) {
      codItemSearch = codItemSearch.filter(
        (cod) =>
          cod.vendor.full_name &&
          _.includes(cod.vendor.full_name.toString().toLowerCase(), vendorName),
      );
    }

    if (codItemStatus) {
      codItemSearch = codItemSearch.filter((cod) => cod.status === codItemStatus);
    }

    if (codInDate) {
      codItemSearch = codItemSearch.filter((cod) => checkDateBetween(cod.cod_in_date, codInDate));
    }

    if (pickUpDate) {
      codItemSearch = codItemSearch.filter((cod) => checkDateBetween(cod.pick_up_date, pickUpDate));
    }

    if (deliveredDate) {
      codItemSearch = codItemSearch.filter((cod) =>
        checkDateBetween(cod.delivered_date, deliveredDate),
      );
    }

    return codItemSearch;
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'ชื่อ 3PL',
      dataIndex: 'column1',
      width: 300,
      search: (
        <Input
          allowClear
          value={vendorName}
          placeholder="ค้นหา"
          onChange={(event) => setVendorName(event.target.value)}
        />
      ),
    },
    {
      title: 'สถานะ',
      dataIndex: 'column2',
      width: 150,
      search: (
        <Select
          value={codItemStatus}
          onChange={(value) => setCodItemStatus(value)}
          style={{ width: '100%' }}
        >
          <Option value="">ทั้งหมด</Option>
          <Option value={1}>รอส่งยอด</Option>
          <Option value={2}>รอตรวจสอบ</Option>
          <Option value={3}>ตรวจสอบแล้ว</Option>
          <Option value={4}>ปฏิเสธ</Option>
        </Select>
      ),
    },
    {
      title: 'จำนวนที่ 3PL ต้องโอน (บาท)',
      dataIndex: 'column3',
      width: 150,
      align: 'right',
    },
    {
      title: 'วันที่แจ้งโอน',
      dataIndex: 'column4',
      width: 150,
      search: (
        <RangePicker
          onChange={(values) => {
            const formatRangDate =
              values === null
                ? ''
                : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

            setCodInDate(formatRangDate);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'วันที่รับสินค้า',
      dataIndex: 'column5',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) => {
            const formatRangDate =
              values === null
                ? ''
                : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

            setPickUpDate(formatRangDate);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'วันที่ส่งสำเร็จ',
      dataIndex: 'column6',
      width: 150,
      search: (
        <RangePicker
          onChange={(values) => {
            const formatRangDate =
              values === null
                ? ''
                : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

            setDeliveredDate(formatRangDate);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'column7',
      width: 150,
    },
  ];

  const showConfirmDownloadFile = (codItemId: string) => {
    const modal = {
      title: 'ยืนยัน',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          ต้องการ <Text strong>ดาวน์โหลดไฟล์</Text> ใช่หรือไม่?
        </>
      ),
      okText: 'ดาวน์โหลด',
      onOk() {
        window.location.href = `${baseUrl}/report-COD-01/${codInId}/${codItemId}`;
      },
      cancelText: 'ยกเลิก',
    };

    confirm(modal);
  };

  const codsData: CodIn3PLDataTable[] = getCodItemsSearch().map(
    (item, index): CodIn3PLDataTable => ({
      key: index,
      tools: (
        <ToolsContainer>
          <>
            <Button
              onClick={() => {
                setSelectedCodItem(
                  (prevState): SelectedCodItemType => ({
                    ...prevState,
                    codItemId: item._id,
                    isOpenModal: true,
                    codItemStatus: item.status,
                    vendorFullName: item.vendor.full_name,
                    document: item.document,
                    codInDate: item.cod_in_date,
                    customerFullName,
                    bookingNumber,
                    totalCod: item.total_cod,
                    remark: item.remark,
                    rejectRemark: item.reject_remark,
                  }),
                );
              }}
              color="grey--text-only"
              icon={<SearchOutlined />}
              size="small"
              disabled={item.status === CodItemStatus.PENDING}
              title="ดูรายละเอียด"
            />

            {codStatus === CodInStatus.PENDING && (
              <Button
                onClick={() => showConfirmDownloadFile(item._id)}
                color="grey--text-only"
                icon={<FileExcelOutlined />}
                size="small"
                title="ดาวน์โหลดไฟล์ Excel"
                disabled={item.status === CodItemStatus.PENDING}
              />
            )}
          </>
        </ToolsContainer>
      ),
      column1: item.vendor.full_name || '-',
      column2: <TagCodItemStatus status={item.status} />,
      column3: (
        <Text strong customType="primary">
          {formatCurrency(item.total_cod)}
        </Text>
      ),
      column4: item.cod_in_date === '-' ? item.cod_in_date : formatDateTime(item.cod_in_date),
      column5: formatDateTime(item.pick_up_date),
      column6: formatDateTime(item.delivered_date),
      column7: formatDateTime(item.last_update),
    }),
  );

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({codItems.length})</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch columns={columns} dataSource={codsData} pagination={false} />

      <ModalCodItemDetail
        visible={selectedCodItem.isOpenModal}
        onCancel={() =>
          setSelectedCodItem(
            (prevState): SelectedCodItemType => ({
              ...prevState,
              isOpenModal: false,
            }),
          )
        }
      />
    </>
  );
};

export default CodIn3PLTable;
