import React from 'react';
import { Tag } from 'shared/components';
import { TagColorType } from 'shared/components/Tag';
import { CodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/codStatus.model';

type TagCodStatusProps = {
  status: CodStatus;
};

const getTextColorStatus = (
  status: CodStatus,
): {
  color: TagColorType | undefined;
  text: string | number;
  outline: boolean;
} => {
  switch (status) {
    case 1:
      return { color: undefined, text: 'รอส่งยอด', outline: false };
    case 2:
      return { color: 'blue--light', text: 'รอตรวจสอบ', outline: false };
    default:
      return { color: undefined, text: status, outline: false };
  }
};

const TagCodStatus: React.FC<TagCodStatusProps> = (props) => {
  const { status } = props;
  const textColor = getTextColorStatus(status);

  return (
    <Tag customColor={textColor.color} outline={textColor.outline}>
      {textColor.text}
    </Tag>
  );
};

export default TagCodStatus;
