type EnumLiteralsOf<T extends unknown> = T[keyof T];
export const AppStatus = Object.freeze({
  PENDING: 1 as const,
  APPROVED: 2 as const,
  PAID: 3 as const,
  EDIT: 4 as const,
  CANCEL: 5 as const,
});

export type AppStatus = EnumLiteralsOf<typeof AppStatus>;
