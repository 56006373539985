import React from 'react';
import { Row, Col } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { StyledButton, TextHeader, Container } from './Styles';

type HeaderProps = {
  onCancel: () => void;
  onSubmit: () => void;
  btnDisabled: boolean;
};

const Header: React.FC<HeaderProps> = (props) => {
  const { onCancel, onSubmit, btnDisabled } = props;

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>รายการรหัสบัญชี</TextHeader>
        </Col>

        <Col>
          <StyledButton
            disabled={btnDisabled}
            icon={<PlusCircleOutlined />}
            color="green"
            onClick={onSubmit}
          >
            เพิ่มรายการ
          </StyledButton>
          <StyledButton onClick={onCancel}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
