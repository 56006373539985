import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import {
  BillingNoteContext,
  SelectedInvoicesType,
} from 'views/AccountReceive/common/context/BillingNoteContext';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountReceive/Routes/BillingNote/ModalCreateBillingNote/formValues.model';
import { StyledButton, TextHeader, Container } from './Styles';
import ModalConfirmCreateBillingNote from './ModalConfirmCreateBillingNote';

const Header: React.FC = () => {
  const { handleSubmit } = useFormContext<FormValues>();
  const [isOpenModalConfirmCreateBillingNote, setOpenModalConfirmCreateBillingNote] = useState<
    boolean
  >(false);
  const { selectedInvoices, setSelectedInvoices } = useContext(BillingNoteContext);
  const { isLoading } = selectedInvoices;

  const closeModalHandler = () => {
    setSelectedInvoices(
      (prevState): SelectedInvoicesType => ({
        ...prevState,
        isOpenModal: false,
      }),
    );
  };

  const onSubmitCreateBillingNote = handleSubmit(() => {
    setOpenModalConfirmCreateBillingNote(true);
  });

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>สร้างใบวางบิล</TextHeader>
        </Col>

        <Col>
          <StyledButton disabled={isLoading} color="blue--outline">
            ตัวอย่างเอกสาร
          </StyledButton>

          <StyledButton
            disabled={isLoading}
            icon={<CheckCircleOutlined />}
            color="green"
            onClick={onSubmitCreateBillingNote}
          >
            สร้างใบวางบิล
          </StyledButton>

          <ModalConfirmCreateBillingNote
            visible={isOpenModalConfirmCreateBillingNote}
            onCancel={() => setOpenModalConfirmCreateBillingNote(false)}
          />

          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
