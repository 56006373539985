import gql from 'graphql-tag';

export interface CancelFollowDebtHistoryData {
  cancelFollowDebtHistory: {
    _id: string;
  };
}

export interface CancelFollowDebtHistoryVars {
  _id: string;
  followDetail: string;
  cancelStatus: boolean;
}

export default gql`
  mutation CancelFollowDebtHistory($_id: ID!, $followDetail: ID!, $cancelStatus: Boolean!) {
    cancelFollowDebtHistory(_id: $_id, followDetail: $followDetail, cancelStatus: $cancelStatus) {
      _id
    }
  }
`;
