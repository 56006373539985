import gql from 'graphql-tag';

export interface EmployeesVars {
  _id?: string;
  full_name?: string;
  page?: number;
  showData?: number;
}

export interface Employees {
  _id: string;
  full_name: string;
  department: string;
  phone: string;
  confirmTranferBankBBL: boolean;
}

export interface EmployeesData {
  employees: {
    employees: Employees[];
  };
}

export default gql`
  query Employees($_id: ID, $full_name: String, $page: Int, $showData: Int) {
    employees(
      params: { _id: $_id, full_name: $full_name, employee_status: 1 }
      page: $page
      showData: $showData
    ) {
      employees {
        _id
        full_name
        department
        phone
        confirmTranferBankBBL
      }
    }
  }
`;
