import gql from 'graphql-tag';

export interface ParcelData {
  parcel: {
    _id: string;
    trackingNumber: string;
    customer: {
      _id: string;
      full_name: string;
    };
    itemName: string;
    width: number;
    length: number;
    height: number;
    weight: number;
    status: number;
    deliveredDate: string;
    parcelCost: {
      jobId: string;
      jobType: string;
      refNumber: string;
      totalCostAvg: number;
    }[];
    parcelRevenue: {
      totalRevenue: number;
      jobId: string;
    };
  };
}

export interface ParcelVars {
  _id: string;
}

export default gql`
  query Parcel($_id: ID) {
    parcel(_id: $_id) {
      _id
      trackingNumber
      customer {
        _id
        full_name
      }
      itemName
      width
      length
      height
      weight
      status
      deliveredDate
      parcelCost {
        jobId
        jobType
        refNumber
        totalCostAvg
      }
      parcelRevenue {
        totalRevenue
        jobId
      }
    }
  }
`;
