import React, { useContext } from 'react';
import {
  InvoiceContext,
  CreateInvoiceFromExcelType,
} from 'views/AccountReceive/common/context/InvoiceContext';
import { useForm, FormProvider } from 'react-hook-form';
import { FormValues } from 'views/AccountReceive/Routes/Invoice/ModalCreateInvoiceFromExcel/model/formValues.model';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Header from './Header';
import CreateInvoiceFromExcel from './CreateInvoiceFromExcel';
import { StyledModal } from './Styles';

const validateSchema = yup.object().shape({
  payerId: yup.string().required(),
  file: yup.string().required(),
});

const initialValue = {
  payerId: '',
  file: '',
};

const ModalCreateInvoiceFromExcelWrapper: React.FC = () => {
  const { createInvoiceFromExcel, setCreateInvoiceFromExcel } = useContext(InvoiceContext);

  return (
    <StyledModal
      visible={createInvoiceFromExcel.isOpenModal}
      onCancel={() => {
        setCreateInvoiceFromExcel(
          (prevState): CreateInvoiceFromExcelType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalCreateInvoiceFromExcel />
    </StyledModal>
  );
};

const ModalCreateInvoiceFromExcel: React.FC = () => {
  const methods = useForm<FormValues>({
    mode: 'onChange',
    shouldFocusError: true,
    shouldUnregister: false,
    resolver: yupResolver(validateSchema),
    defaultValues: initialValue,
  });

  return (
    <FormProvider {...methods}>
      <Header />
      <CreateInvoiceFromExcel />
    </FormProvider>
  );
};

export default ModalCreateInvoiceFromExcelWrapper;
