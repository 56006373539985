import gql from 'graphql-tag';

export interface CreateInvoiceFromExcelData {
  createInvoiceFromExcel: {
    inProgress: boolean;
  };
}

export interface CreateInvoiceFromExcelVars {
  filePath: string;
  payer: string;
}

export default gql`
  mutation CreateInvoiceFromExcel($filePath: String, $payer: String) {
    createInvoiceFromExcel(filePath: $filePath, payer: $payer) {
      inProgress
    }
  }
`;
