/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { ApprovePaymentContext } from 'views/AccountPayment/common/context/ApprovePaymentContext';
import UPDATE_APP_MUTATION, {
  UpdateAppData,
  UpdateAppVars,
} from 'shared/graphql/mutation/updateApp';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { formatDate } from 'shared/utils/dateTime';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountPayment/Routes/ApprovePayment/AppTable/ModalAppDetail/Header/ModalSavePaid/model/formValues.model';
import { StyledModal } from './Styles';

type ModalConfirmUpdateProps = {
  visible: boolean;
  onCompleted: () => void;
  onCancel: () => void;
};

const ModalConfirmUpdate: React.FC<ModalConfirmUpdateProps> = (props) => {
  const { onCancel, visible, onCompleted } = props;
  const { selectedApprovePaymentDetail } = useContext(ApprovePaymentContext);
  const { grandTotalPrice, appNumber, createDate } = selectedApprovePaymentDetail;
  const { getValues, reset } = useFormContext<FormValues>();
  const allFieldValues = getValues();

  const [updateApp, { loading }] = useMutation<UpdateAppData, UpdateAppVars>(UPDATE_APP_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.updateApp._id) {
        message.success('อนุมัติจ่ายแล้ว');
        reset();
        onCompleted();
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['App', 'AppStatus', 'Apps'],
  });

  const confirmUpdate = () => {
    updateApp({
      variables: {
        _id: allFieldValues.id,
        bank_ref: allFieldValues.bankRef,
        proof_slip: allFieldValues.proofSlip,
        paid_date: allFieldValues.paidDate,
        slip_remark: allFieldValues.slipRemark,
        app_status: 3,
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="บันทึกการจ่ายหรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmUpdate()}
          loading={loading}
        >
          บันทึกการจ่าย
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>วันที่เอกสาร:</Col>
        <Col span={18}>
          <Text strong>{formatDate(createDate)}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>รหัสรอบจ่าย:</Col>
        <Col span={18}>
          <Text strong>{appNumber}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>มูลค่ารอบจ่าย (บาท):</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(grandTotalPrice, '0')}
          </Text>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmUpdate;
