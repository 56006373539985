/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode } from 'react';
import PAYMENTS_QUERY, { PaymentsData, PaymentsVars } from 'shared/graphql/query/payments';
import PAYEE_QUERY, { PayeeData, PayeeVars, Payee } from 'shared/graphql/query/payee';
import { useQuery } from '@apollo/react-hooks';
import { Input, DatePicker, message, Row, Col } from 'antd';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { Text } from 'shared/components';
import SelectEmployeeField from 'views/AccountPayment/common/components/SelectEmployeeField';
import { StyledModal, StyledTable, StyledCheckbox, ToolsContainer } from './Styles';
import Header from './Header';

const { RangePicker } = DatePicker;
type Payment = {
  id: string;
  createDate: string;
  paymentNumber: string;
  totalPrice: number;
};

type ModalAddPaymentWrapperProps = {
  visible: boolean;
  payeeId: string;
  paymentType: string;
  payments?: Payment[];
  onCancel: () => void;
  onOk: (value: Payment[]) => void;
};

type ModalAddPaymentProps = {
  payeeId: string;
  paymentType: string;
  payments: Payment[];
  onCancel: () => void;
  onOk: (value: Payment[]) => void;
};

type PaymentsDataTable = {
  key: number;
  tools: ReactNode;
  createDateColumn: string;
  paymentNumberColumn: string;
  roundPaidColumn: string;
  paymentTypeColumn: string;
  employeeColumn: string;
  numberRefColumn: string;
  totalPriceColumn: ReactNode;
};

const ModalAddPaymentWrapper: React.FC<ModalAddPaymentWrapperProps> = (props) => {
  const { visible, payeeId, paymentType, onCancel, payments, onOk } = props;
  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalAddPayment
        onCancel={onCancel}
        payeeId={payeeId}
        paymentType={paymentType}
        payments={payments || []}
        onOk={onOk}
      />
    </StyledModal>
  );
};

const ModalAddPayment: React.FC<ModalAddPaymentProps> = (props) => {
  const { onCancel, payeeId, paymentType, payments, onOk } = props;
  const [selectedPayments, setSelectedPayments] = useState<Payment[]>(payments);
  const [createDate, setCreateDate] = useState<string>('');
  const [paymentNumber, setPaymentNumber] = useState<string>('');
  const [roundPaid, setRoundPaid] = useState<string>('');
  const [employeeId, setEmployeeId] = useState<string>('');
  const [pageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data: payeeData } = useQuery<PayeeData, PayeeVars>(PAYEE_QUERY, {
    variables: {
      _id: payeeId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading, data } = useQuery<PaymentsData, PaymentsVars>(PAYMENTS_QUERY, {
    variables: {
      create_date: createDate,
      payment_number: paymentNumber,
      round_paid: roundPaid,
      employee: employeeId,
      payment_status: 2,
      page: currentPage,
      showData: pageSize,
      payee: payeeId,
      payment_type: paymentType || '-',
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">วันที่เอกสาร</div>
          <div>
            <RangePicker
              onChange={(values) =>
                values === null
                  ? setCreateDate('')
                  : setCreateDate(
                      `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                    )
              }
              format="YYYY/MM/DD"
              placeholder={['เริ่มต้น', 'สิ้นสุด']}
            />
          </div>
        </div>
      ),
      dataIndex: 'createDateColumn',
      width: 160,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">รหัสใบสั่งจ่าย</div>
          <div>
            <Input
              allowClear
              value={paymentNumber}
              placeholder="ค้นหา"
              onChange={(event) => setPaymentNumber(event.target.value)}
            />
          </div>
        </div>
      ),
      dataIndex: 'paymentNumberColumn',
      width: 150,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">รอบจ่าย</div>
          <div>
            <RangePicker
              onChange={(values) =>
                values === null
                  ? setRoundPaid('')
                  : setRoundPaid(
                      `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                    )
              }
              format="YYYY/MM/DD"
              placeholder={['เริ่มต้น', 'สิ้นสุด']}
            />
          </div>
        </div>
      ),
      dataIndex: 'roundPaidColumn',
      width: 150,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">ประเภทการจ่าย</div>
        </div>
      ),
      dataIndex: 'paymentTypeColumn',
      width: 150,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">ผู้ตั้งเบิก</div>
          <div>
            <SelectEmployeeField onChange={(value: string) => setEmployeeId(value)} />
          </div>
        </div>
      ),
      dataIndex: 'employeeColumn',
      width: 250,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">รหัส Shipment/JOB/Booking</div>
          <div>
            {/* <Input
              allowClear
              value={paymentNumber}
              placeholder="ค้นหา"
              onChange={(event) => setPaymentNumber(event.target.value)}
            /> */}
          </div>
        </div>
      ),
      dataIndex: 'numberRefColumn',
      width: 150,
    },
    {
      title: (
        <div className="table-header">
          จำนวนตั้งเบิก (บาท){' '}
          <div>
            {/* <Input
              allowClear
              value={paymentNumber}
              placeholder="ค้นหา"
              onChange={(event) => setPaymentNumber(event.target.value)}
            /> */}
          </div>
        </div>
      ),
      dataIndex: 'totalPriceColumn',
      width: 150,
    },
  ];

  const selectPayments = (checked: boolean, value: Payment) => {
    if (checked) {
      setSelectedPayments((prevState) => {
        return [...prevState, value];
      });
    } else {
      const paymentsFilter = selectedPayments.filter((payment) => payment.id !== value.id);
      setSelectedPayments([...paymentsFilter]);
    }
  };

  let paymentsData: PaymentsDataTable[] = [];
  let totalDocument = 0;

  if (data?.payments) {
    paymentsData = data.payments.payments.map(
      (item, index): PaymentsDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <StyledCheckbox
              disabled={item.payee._id !== payeeId}
              checked={selectedPayments.map((payment) => payment.id).indexOf(item._id) !== -1}
              value={item._id}
              onChange={(event) =>
                selectPayments(event.target.checked, {
                  id: item._id,
                  createDate: item.create_date,
                  paymentNumber: item.payment_number,
                  totalPrice: item.total_price,
                })
              }
            />
          </ToolsContainer>
        ),
        createDateColumn: !item.create_date ? '-' : formatDate(item.create_date),
        paymentNumberColumn: item.payment_number || '-',
        roundPaidColumn: !item.round_paid ? '-' : formatDate(item.round_paid),
        paymentTypeColumn: item.payment_type || '-',
        employeeColumn: item.employee.full_name || '-',
        numberRefColumn: item.number_ref || '-',
        totalPriceColumn: (
          <Text customType="primary" strong>
            {formatCurrency(item.total_price)}
          </Text>
        ),
      }),
    );
    totalDocument = data.payments.totalDocument;
  }
  const payeeType = (payee: Payee) => {
    if (payee.payee_type === 2) {
      return {
        id: payee._id,
        fullName: payee.employee.full_name,
      };
    }
    return {
      id: payee._id,
      fullName: payee.vendor.full_name,
    };
  };

  return (
    <div>
      <Header
        onSubmit={() => onOk(selectedPayments)}
        onCancel={onCancel}
        btnDisabled={selectedPayments.length === 0}
      />

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row>
            <Col span={8}>
              ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>
              <div>
                <Input
                  value={payeeData?.payee ? payeeType(payeeData.payee).fullName : '-'}
                  disabled
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <StyledTable
            scroll={{ x: 'max-content' }}
            columns={columns}
            dataSource={paymentsData}
            loading={loading}
            pagination={{
              current: currentPage,
              pageSize,
              total: totalDocument,
              showSizeChanger: false,
              onChange: (value: number) => setCurrentPage(value),
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ModalAddPaymentWrapper;
