import styled from 'styled-components';
import { font } from 'shared/utils/styles';
import { Table } from 'shared/components';

export const TextTotalPrice = styled.span`
  ${font.bold}
  ${font.size(24)}
`;

export const StyledTable = styled(Table)`
  & th.ant-table-cell {
    white-space: nowrap;
  }

  &.table-1 {
    &
      .ant-table-thead
      tr:nth-child(2)
      th:not(:first-child):not(:nth-child(2)):not(:nth-child(7)):not(:last-child) {
      border-right: 0 !important;
    }

    & .ant-table-tbody td:not(:first-child):not(:nth-child(2)):not(:nth-child(7)):not(:last-child) {
      border-right: 0 !important;
    }
  }

  &.table-2 {
    & .ant-table-thead tr:nth-child(2) th:not(:first-child):not(:nth-child(6)):not(:last-child) {
      border-right: 0 !important;
    }

    & .ant-table-tbody td:not(:first-child):not(:nth-child(6)):not(:last-child) {
      border-right: 0 !important;
    }
  }
`;
