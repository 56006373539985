import styled from 'styled-components';
import { Checkbox } from 'antd';
import { mixin, color } from 'shared/utils/styles';
import { Table } from 'shared/components';

export const StyledTable = styled(Table)`
  & thead > tr > th {
    border-top: 1px solid ${color.border};
  }

  & tr > th.divider,
  tr > td.divider {
    border-right: 1px solid ${color.border};
  }

  ${mixin.backgroundTableOdd}
`;

export const StyledCheckbox = styled(Checkbox)`
  transform: scale(1.2);
  margin-right: 10px;
`;
