import gql from 'graphql-tag';
import { CodOutStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codOutStatus.model';

export interface CodOutParcels {
  _id: string;
  cod_out_number: string;
  cod_out_status: CodOutStatus;
  paid_date: string;
  bank_ref: string;
  customer: {
    _id: string;
    full_name: string;
  };
  total_cod: number;
  cod_in_number: string[];
  tracking_number: string[];
  parcel_orders: {
    tracking_number: string;
    delivered_date: string;
    cod_price: number;
  }[];
  last_update: string;
}

export interface CodOutParcelsData {
  codOutParcels: {
    codOutParcels: CodOutParcels[];
    totalDocument: number;
  };
}

export interface CodOutParcelsVars {
  _id?: string;
  customer?: string;
  cod_out_number?: string;
  cod_out_status?: CodOutStatus;
  tracking_number?: string;
  cod_in_number?: string;
  paid_date?: string;
  bank_ref?: string;
  order_by?: string;
  order_type?: '1' | '-1' | '';
  page?: number;
  showData?: number;
}

export default gql`
  query CodOutParcels(
    $_id: ID
    $customer: ID
    $cod_out_number: String
    $cod_out_status: Int
    $tracking_number: String
    $cod_in_number: String
    $paid_date: String
    $bank_ref: String
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
  ) {
    codOutParcels(
      params: {
        _id: $_id
        customer: $customer
        cod_out_number: $cod_out_number
        cod_out_status: $cod_out_status
        tracking_number: $tracking_number
        cod_in_number: $cod_in_number
        paid_date: $paid_date
        bank_ref: $bank_ref
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      codOutParcels {
        _id
        cod_out_number
        cod_out_status
        paid_date
        bank_ref
        customer {
          _id
          full_name
        }
        total_cod
        cod_in_number
        tracking_number
        parcel_orders {
          tracking_number
          delivered_date
          cod_price
        }
        last_update
      }
      totalDocument
    }
  }
`;
