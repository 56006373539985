/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Row, Col, Skeleton, Divider } from 'antd';
import Icon from '@ant-design/icons';
import IconUsdCircle from 'shared/assets/icons/usd-circle.svg';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import LOG_AR_QUERY, { LogARData, LogARVars } from 'shared/graphql/query/logAR';
import { PageResultError, TabPane, Text } from 'shared/components';
import { color } from 'shared/utils/styles';
import formatCurrency from 'shared/utils/formatCurrency';
import { Container, StyledPageHeader } from './Styles';
import DueDateTable from './DueDateTable';
import OverDueDateTable from './OverDueDateTable';
import SuccessTable from './SuccessTable';

type Params = {
  id: string;
};

const DebtTrackerDetail: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<Params>();

  const { data, error } = useQuery<LogARData, LogARVars>(LOG_AR_QUERY, {
    variables: {
      _id: id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const businessInvoiceType = (value: number) => {
    switch (value) {
      case 1:
        return '1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลทุกสิ้นเดือน';
      case 2:
        return '1 ใบแจ้งหนี้ ต่อ 1 งาน และวางบิลทุกวันหลังจบงาน';
      case 3:
        return '1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลตามวันที่กำหนด';
      case 4:
        return '1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลทุกสิ้นเดือน';
      case 5:
        return '1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลตามวันที่กำหนด';
      default:
        return '-';
    }
  };

  const businessPayRound = (roundDate: string, afterInvoice: string) => {
    if (roundDate === null && afterInvoice === null) {
      return '-';
    }
    if (roundDate !== null && +roundDate > 0) {
      return `ทุกวันที่ ${roundDate} ของเดือน`;
    }
    if (afterInvoice !== null && +afterInvoice > 0) {
      return `หลังจากวันที่แจ้งหนี้ ${afterInvoice} วัน`;
    }
  };

  let content = (
    <Container>
      <Skeleton />
    </Container>
  );

  if (error) {
    content = <PageResultError title="เกิดข้อผิดพลาด" message={error.message} />;
  }

  if (data?.logAR) {
    const { customer, totalDebt, totalDebtOverdue } = data.logAR;

    content = (
      <Container>
        <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3', marginBottom: 10 }}>
          <Col span={12}>
            <StyledPageHeader
              onBack={() => history.push('/debt-tracker')}
              title={`${customer.number || '-'}: ${customer.full_name || '-'}`}
            />
          </Col>
        </Row>

        <Row gutter={[32, 0]} style={{ marginTop: 10, marginBottom: 10 }}>
          <Col span={8}>
            <Row gutter={[0, 8]}>
              <Col span={8}>
                <Text>รหัสลูกค้า:</Text>
              </Col>
              <Col span={16}>
                <Text strong>{customer.number || '-'}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 8]}>
              <Col span={8}>
                <Text>ชื่อลูกค้า (ลูกหนี้):</Text>
              </Col>
              <Col span={16}>
                <Text strong>{customer.full_name || '-'}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 8]}>
              <Col span={8}>
                <Text>รูปแบบรับใบแจ้งหนี้:</Text>
              </Col>
              <Col span={16}>
                <Text strong>
                  {customer.business_invoice_type
                    ? businessInvoiceType(customer.business_invoice_type)
                    : '-'}
                </Text>
              </Col>
            </Row>
            <Row gutter={[0, 8]}>
              <Col span={8}>
                <Text>รอบการชำระลูกหนี้:</Text>
              </Col>
              <Col span={16}>
                <Text strong>
                  {businessPayRound(
                    customer.business_pay_round.round_date,
                    customer.business_pay_round.after_invoice,
                  )}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={9}>
            <Row gutter={[0, 8]}>
              <Col span={8}>
                <Text>เลขประจำตัวผู้เสียภาษี:</Text>
              </Col>
              <Col span={16}>
                <Text strong>{customer.tax_id || '-'}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 8]}>
              <Col span={8}>
                <Text>เบอร์โทรศัพท์:</Text>
              </Col>
              <Col span={16}>
                <Text strong>{customer.phone.length > 0 ? customer.phone.join(', ') : '-'}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 8]}>
              <Col span={8}>
                <Text>อีเมล:</Text>
              </Col>
              <Col span={16}>
                <Text strong>{customer.email.length > 0 ? customer.email.join(', ') : '-'}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 8]}>
              <Col span={8}>
                <Text>ที่อยู่:</Text>
              </Col>
              <Col span={16}>
                <Text strong>{`${customer?.address || ''} ${customer?.sub_district || ''} ${
                  customer?.district || ''
                } ${customer?.province || ''} ${customer?.postcode || ''}`}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col>
                <Text>มูลค่าหนี้รวม (บาท):</Text>
              </Col>
            </Row>
            <Row gutter={[0, 6]} align="middle">
              <Col style={{ marginRight: 5 }}>
                <Text style={{ fontSize: 29 }}>
                  <Icon component={IconUsdCircle} style={{ display: 'flex' }} />
                </Text>
              </Col>
              <Col>
                <Text strong style={{ fontSize: 24 }}>
                  {totalDebt ? formatCurrency(+totalDebt) : '-'}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text>ยอดเกินกำหนดชำระ (บาท):</Text>
              </Col>
            </Row>
            <Row gutter={[0, 6]} align="middle">
              <Col style={{ marginRight: 5 }}>
                <Text style={{ fontSize: 29 }}>
                  <Icon
                    component={IconUsdCircle}
                    style={{ display: 'flex', color: color.primary }}
                  />
                </Text>
              </Col>
              <Col>
                <Text strong style={{ fontSize: 24, color: color.primary }}>
                  {totalDebtOverdue ? formatCurrency(+totalDebtOverdue) : '-'}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider style={{ marginBottom: 0, marginTop: 10 }} />

        <TabPane
          tabs={[
            {
              key: '1',
              text: 'ตามกำหนดชำระ',
              component: <DueDateTable customerId={customer._id} />,
            },
            {
              key: '2',
              text: 'เกินกำหนดชำระ',
              component: <OverDueDateTable customerId={customer._id} />,
            },
            {
              key: '3',
              text: 'ตามหนี้สำเร็จ',
              component: <SuccessTable customerId={customer._id} />,
            },
          ]}
        />
      </Container>
    );
  }

  return content;
};

export default DebtTrackerDetail;
