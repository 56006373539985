/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { Button } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledPageHeader } from './Styles';
import { CustomerBankMode, CustomerBankPromptPayMode, FormValues } from '../formValues.model';
import Forms from '../InputCustomerForm';
import validateSchema from '../validateSchema';
import ModalConfirmCreateVendor from './ModalConfirmCreate';

const CustomerCreate: React.FC = () => {
  const history = useHistory();

  const [isOpenModalConfirmCreate, setOpenModalConfirmCreate] = useState<boolean>(false);

  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validateSchema),
    defaultValues: {
      customerId: '',
      from: 1,
      firstName: '',
      lastName: '',
      email: [],
      phone: '',
      taxId: '',
      branch: 'สำนักงานใหญ่',
      address: '',
      province: '',
      district: '',
      subDistrict: '',
      postCode: '',
      businessInvoiceType: 1,
      businessInvoiceDateRangeStart: '',
      businessInvoiceDateRangeEnd: '',
      bankName: '',
      bankBranch: '',
      accountName: '',
      accountNumber: '',
      businessPayRoundRound: '1',
      businessPayRoundRoundDate: '0',
      businessPayRoundRoundAfterInvoice: '0',
      customerBankMode: CustomerBankMode.BANK_ACCOUNT,
      customerBankCode: '',
      customerBankPromptPayMode: CustomerBankPromptPayMode.TELEPHONE_NUMBER,
      customerBankId: '',
      customerAccountName: '',
      customerAccountNumber: '',
    },
  });

  const { handleSubmit } = methods;

  const submit = handleSubmit(() => {
    setOpenModalConfirmCreate(true);
  });

  return (
    <>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3', marginBottom: 10 }}>
        <Col span={12}>
          <StyledPageHeader onBack={() => history.goBack()} title="เพิ่มบัญชีลูกหนี้" />
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Col>
              <Button color="green" onClick={submit}>
                สร้างบัญชีลูกหนี้
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <FormProvider {...methods}>
        <Forms />
        <ModalConfirmCreateVendor
          visible={isOpenModalConfirmCreate}
          onCancel={() => setOpenModalConfirmCreate(false)}
        />
      </FormProvider>
    </>
  );
};

export default CustomerCreate;
