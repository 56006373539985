type EnumLiteralsOf<T extends unknown> = T[keyof T];
export const BillingNotesStatus = Object.freeze({
  WAITING: 1 as const,
  BILLING_NOTE: 2 as const,
  EDIT: 3 as const,
  CANCEL: 5 as const,
  COMPLETED: 6 as const,
});

export type BillingNotesStatus = EnumLiteralsOf<typeof BillingNotesStatus>;
