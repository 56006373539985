import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AccountPayment from 'views/AccountPayment';
import AccountReceive from 'views/AccountReceive';
import Costing from 'views/Costing';
import Reports from 'views/Reports';
import CashOnDelivery from 'views/CashOnDelivery';
import Login from 'views/Auth/Login';
import Setting from 'views/Setting';
import DebtTracker from 'views/DebtTracker';
import { PageNotFound } from 'shared/components';
import { getStoredAuthToken } from 'shared/utils/authToken';
import CostingParcel from 'views/CostingParcel';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = getStoredAuthToken();
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

const LoginRoute = ({ component: Component, ...rest }) => {
  const token = getStoredAuthToken();
  return (
    <Route
      {...rest}
      render={(props) => (!token ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};

const Routes = () => (
  <Switch>
    <Redirect exact from="/" to="/ct" />

    <PrivateRoute path="/ap" component={AccountPayment} />

    <PrivateRoute path="/costing-parcel" component={CostingParcel} />

    <PrivateRoute path="/ct" component={Costing} />

    <PrivateRoute path="/cod" component={CashOnDelivery} />

    <PrivateRoute path="/ar" component={AccountReceive} />

    <PrivateRoute path="/debt-tracker" component={DebtTracker} />

    <PrivateRoute path="/reports" component={Reports} />

    <PrivateRoute path="/setting" component={Setting} />

    <LoginRoute path="/login" component={Login} />
    <Route component={PageNotFound} />
  </Switch>
);

export default Routes;
