import styled from 'styled-components';
import { Card } from 'antd';
import { font } from 'shared/utils/styles';

export const StyledCard = styled(Card)`
  margin: 100px;

  @media (max-width: 767px) {
    margin: 20px;
  }
`;

export const StyledLogo = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

export const TextHeader = styled.h3`
  text-align: center;
  ${font.medium}
  ${font.size(24)}
`;
