import gql from 'graphql-tag';

export interface SendEmailReceiptData {
  sendEmailReceipt: {
    data: number;
    error: number;
  };
}

export interface SendEmailReceiptVars {
  _id: string;
  type: 'copy' | 'original';
}

export default gql`
  mutation SendEmailReceipt($_id: ID, $type: ID) {
    sendEmailReceipt(_id: $_id, type: $type) {
      data
      error
    }
  }
`;
