type EnumLiteralsOf<T extends unknown> = T[keyof T];
export const CustomerBankMode = Object.freeze({
  BANK_ACCOUNT: 1 as const,
  PROMPT_PAY: 2 as const,
});

export type CustomerBankMode = EnumLiteralsOf<typeof CustomerBankMode>;

export const CustomerBankPromptPayMode = Object.freeze({
  TELEPHONE_NUMBER: 1 as const,
  ID_CARD_NUMBER: 2 as const,
});

export type CustomerBankPromptPayMode = EnumLiteralsOf<typeof CustomerBankPromptPayMode>;

export interface Email {
  text: string;
}

export interface FormValues {
  customerId: string;
  from: number;
  firstName: string;
  lastName: string;
  email: Email[];
  phone: string;
  taxId: string;
  branch: string;
  address: string;
  province: string;
  district: string;
  subDistrict: string;
  postCode: string;
  businessInvoiceType: number;
  businessInvoiceDateRangeStart: string;
  businessInvoiceDateRangeEnd: string;
  bankName: string;
  bankBranch: string;
  accountName: string;
  accountNumber: string;
  businessPayRoundRound: string;
  businessPayRoundRoundDate: string;
  businessPayRoundRoundAfterInvoice: string;
  customerBankMode: CustomerBankMode | number;
  customerBankPromptPayMode: CustomerBankPromptPayMode | number;
  customerBankId: string;
  customerBankCode: string;
  customerAccountName: string;
  customerAccountNumber: string;
}
