import gql from 'graphql-tag';

export interface BankAccount {
  bank_name: string;
  bank_branch: string;
  account_name: string;
  account_number: string;
}

export interface Vendor {
  _id: string;
  system_request: string | null;
  address: string;
  sub_district: string;
  district: string;
  province: string;
  postcode: string;
  full_address: string;
  tax_id: string | null;
  phone: string[];
  email: string[];
  full_name: string;
  branch: string | null;
  country: string | null;
  staff_phone: string | null;
  doc_address: string | null;
  doc_tax_id: string | null;
  doc_province: string | null;
  doc_district: string | null;
  doc_sub_district: string | null;
  doc_postcode: string | null;
  bank_account: BankAccount[];
  checkbox_address_company: boolean;
  number: string;
}

export interface VendorData {
  vendor: Vendor;
}

export interface VendorVars {
  _id: string;
}

export default gql`
  query Vendor($_id: ID!) {
    vendor(_id: $_id) {
      _id
      system_request
      address
      sub_district
      district
      province
      postcode
      full_address
      tax_id
      phone
      email
      full_name
      branch
      country
      staff_phone
      doc_address
      doc_tax_id
      doc_province
      doc_district
      doc_sub_district
      doc_postcode
      checkbox_address_company
      bank_account {
        bank_name
        bank_branch
        account_name
        account_number
      }
      number
    }
  }
`;
