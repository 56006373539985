/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { ReactNode } from 'react';
import { Select, Empty } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@apollo/react-hooks';
import PAYMENT_TYPE_QUERY, { PaymentTypeData } from 'shared/graphql/query/paymentType';

const { Option } = Select;

type SelectPaymentTypeFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
};

const SelectPaymentTypeField: React.FC<SelectPaymentTypeFieldProps> = (props) => {
  const { value, onChange, placeholder, disabled } = props;
  const { data, loading } = useQuery<PaymentTypeData>(PAYMENT_TYPE_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  let options: ReactNode[] = [];
  if (data?.paymentType) {
    const { payment_type } = data.paymentType;
    options = payment_type.map((item) => (
      <Option key={uuidv4()} value={item}>
        {item}
      </Option>
    ));
  }

  return (
    <Select
      style={{
        width: '100%',
      }}
      value={value || undefined}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      placeholder={placeholder || 'เลือกประเภทการจ่าย'}
      showSearch
      onSearch={(valueSearch) => onChange && onChange(valueSearch)}
      onChange={(valueChange) => onChange && onChange(valueChange)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
      allowClear
    >
      {options}
    </Select>
  );
};

export default SelectPaymentTypeField;
