/* eslint-disable no-underscore-dangle */
import React, { useState, useContext } from 'react';
import { Row, Col, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import {
  PaymentCodContext,
  SelectedPaymentCodDetailType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/context/PaymentCodContext';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/Routes/PaymentCod/PaymentCodListTable/ModalPaymentCodDetail/model/formValues.model';
import { CodOutStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codOutStatus.model';
import { useMutation } from '@apollo/react-hooks';
import COD_OUT_PARCEL_BBL_TRANSFER_VALIDATION_MUTATION, {
  CodOutParcelBBLTransferValidationData,
  CodOutParcelBBLTransferValidationVars,
} from 'shared/graphql/mutation/codOutParcelBBLTransferValidation';
import { StyledButton, TextHeader, Container } from './Styles';
import ModalPayCOD from './ModalPayCOD';

const Header: React.FC = () => {
  const { trigger, watch, setValue } = useFormContext<FormValues>();
  const [isOpenModalPayCod, setIsOpenModalPayCod] = useState<boolean>(false);
  const { selectedPaymentCodDetail, setSelectedPaymentCodDetail } = useContext(PaymentCodContext);
  const { isLoading, paymentCodNumber, paymentCodStatus, paymentCodId } = selectedPaymentCodDetail;

  const [
    codOutParcelBBLTransferValidation,
    { loading: codOutParcelBBLTransferValidationLoading },
  ] = useMutation<CodOutParcelBBLTransferValidationData, CodOutParcelBBLTransferValidationVars>(
    COD_OUT_PARCEL_BBL_TRANSFER_VALIDATION_MUTATION,
    {
      update: (_cache, { data: dataUpdate }) => {
        if (dataUpdate?.codOutParcelBBLTransferValidation?.data?._id) {
          setValue(
            'bblTransferValidationId',
            dataUpdate.codOutParcelBBLTransferValidation.data._id,
          );
          message.success('สร้าง Transaction เรียบร้อยแล้ว');
          openModalPayCod();
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
    },
  );

  const closeModalHandler = () => {
    setSelectedPaymentCodDetail(
      (prevState): SelectedPaymentCodDetailType => ({
        ...prevState,
        isOpenModal: false,
      }),
    );
  };

  const openModalPayCod = () => {
    trigger(['paymentMethod', 'accountNumber']).then((valid) => {
      if (valid) {
        setIsOpenModalPayCod(true);
      }
    });
  };

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>{`รหัส COD-OUT ${
            paymentCodNumber ? `: ${paymentCodNumber}` : ''
          }`}</TextHeader>
        </Col>
        <Col>
          {paymentCodStatus === CodOutStatus.WAITING && (
            <>
              <StyledButton
                disabled={isLoading || codOutParcelBBLTransferValidationLoading}
                loading={codOutParcelBBLTransferValidationLoading}
                icon={<SaveOutlined />}
                color="green"
                onClick={() => {
                  if (selectedPaymentCodDetail.totalCod <= 2000000) {
                    if (watch('isBankBBL')) {
                      if (selectedPaymentCodDetail.customerBankAccountNumber) {
                        codOutParcelBBLTransferValidation({
                          variables: {
                            _id: paymentCodId,
                          },
                        });
                      } else {
                        message.error('กรุณากรอกข้อมูลบัญชีธนาคารของลูกค้า');
                      }
                    } else {
                      openModalPayCod();
                    }
                  } else {
                    message.error('มูลค่ารวม COD ห้ามเกิน 2,000,000 บาท');
                  }
                }}
              >
                บันทึกการจ่าย
              </StyledButton>
            </>
          )}

          <ModalPayCOD visible={isOpenModalPayCod} onCancel={() => setIsOpenModalPayCod(false)} />

          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
