import React from 'react';
import { WarningFilled } from '@ant-design/icons';
import { Result } from 'antd';
import { Container } from './Styles';

type PageResultErrorProps = {
  title: string;
  message: string;
  style?: React.CSSProperties;
};

const PageResultError: React.FC<PageResultErrorProps> = (props) => {
  const { title, message, style, ...otherProps } = props;

  return (
    <Container style={style}>
      <Result
        icon={<WarningFilled />}
        status="warning"
        title={title}
        subTitle={message}
        {...otherProps}
      />
    </Container>
  );
};

export default PageResultError;
