/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect } from 'react';
import { Typography, Divider, Row, Col, Select, Input, Tabs, Skeleton, Alert, message } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { useFormikContext } from 'formik';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { color } from 'shared/utils/styles';
import { Tag } from 'shared/components';
import CAL_PAYMENT_VOUCHER_QUERY from 'shared/graphql/query/calPaymentVoucher';
import PAYEE_QUERY from 'shared/graphql/query/payee';
import { PaymentOrderContext } from 'views/AccountPayment/common/context/PaymentOrderContext';
import { StyledFormItem } from './Styles';
import PaymentList from './PaymentList';
import SelectFieldSearchVendor from './SelectFieldSearchVendor';
import SelectFieldBankAccount from './SelectFieldBankAccount';

const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;
const InputGroup = Input.Group;

const PaymentDetail = () => {
  const {
    setFieldValue,
    values,
    errors,
    touched,
    setFieldTouched,
    submitCount,
  } = useFormikContext();

  const { selectedPayments: selectedPaymentsContext } = useContext(PaymentOrderContext);
  const [, setSelectedPayments] = selectedPaymentsContext;

  const { loading, error, data } = useQuery(CAL_PAYMENT_VOUCHER_QUERY, {
    variables: { payment: values.payments.map((payment) => payment.id) },
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      message.error(err.message);
    },
  });

  const { loading: payeeLoading, error: payeeError, data: payeeData } = useQuery(PAYEE_QUERY, {
    variables: {
      _id: values.payeeId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (error || payeeError || loading || payeeLoading) {
      setSelectedPayments((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
    } else {
      setSelectedPayments((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  }, [error, loading, setSelectedPayments, payeeError, payeeLoading]);

  let grandTotalPrice = 0;
  let content = <Skeleton />;
  if (data && data.calPaymentVoucher && payeeData && payeeData.payee) {
    grandTotalPrice = data.calPaymentVoucher.grand_total_price;
    const {
      address,
      sub_district: subDistrict,
      district,
      province,
      postcode,
      full_address: fullAddress,
      email,
      tax_id: taxId,
      phone,
    } = payeeData.payee;

    const vendorAddress =
      !fullAddress ||
      `${address} แขวง/ตำบล ${subDistrict} เขต/อำเภอ ${district} จังหวัด ${province} ${postcode}`;

    content = (
      <>
        {Object.keys(errors).length > 0 && submitCount > 0 && (
          <Alert
            message="กรุณากรอกข้อมูลให้ครบถ้วน"
            banner
            style={{ margin: '-20px -20px 10px' }}
          />
        )}

        <Text strong>เงื่อนไขใบสำคัญจ่าย</Text>
        <Divider style={{ margin: '10px 0' }} />
        <Row gutter={[32, 16]}>
          <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    ผู้รับเงิน <Text type="grey">(เจ้าหนี้)</Text>
                    <Text type="danger">*</Text>
                  </Col>
                  <Col span={24}>
                    <InputGroup compact>
                      <SelectFieldSearchVendor />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    ช่องทางการจ่าย<Text type="danger">*</Text>
                    <StyledFormItem
                      validateStatus={errors.paymentMethod && touched.paymentMethod && 'error'}
                    >
                      <Select
                        value={values.paymentMethod}
                        style={{ width: '100%' }}
                        onChange={(value) => setFieldValue('paymentMethod', value)}
                        onBlur={() => setFieldTouched('paymentMethod')}
                        name="paymentMethod"
                      >
                        <Option value="">เลือกช่องทางการจ่าย</Option>
                        <Option value="โอนเงิน/เงินสด">โอนเงิน/เงินสด</Option>
                      </Select>
                    </StyledFormItem>
                  </Col>
                  <Col span={12}>
                    บัญชีที่ต้องการโอน<Text type="danger">*</Text>
                    <StyledFormItem
                      validateStatus={errors.accountNumber && touched.accountNumber && 'error'}
                    >
                      <SelectFieldBankAccount />
                    </StyledFormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
            <Row>
              <Col span={10}>ที่อยู่:</Col>
              <Col span={14}>
                <Text strong>{vendorAddress}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เลขประจำตัวผู้เสียภาษี:</Col>
              <Col span={14}>
                <Text strong>{!taxId ? '-' : taxId}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เบอร์โทรศัพท์:</Col>
              <Col span={14}>
                <Text strong>{phone && phone.length === 0 ? '-' : phone.join(', ')}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>อีเมล:</Col>
              <Col span={14}>
                <Text strong>{email && email.length === 0 ? '-' : email.join(', ')}</Text>
              </Col>
            </Row>
          </Col>

          <Col span={8}>
            <Row>
              <Col span={8}>วันที่เอกสาร:</Col>
              <Col span={16}>
                <Text strong>{values.dueDate ? formatDate(values.dueDate) : '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รหัสใบสำคัญจ่าย:</Col>
              <Col span={16}>
                <Text strong>-</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>มูลค่าใบสำคัญจ่าย:</Col>
              <Col span={16}>
                <Text strong>{formatCurrency(grandTotalPrice)}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>สถานะ:</Col>
              <Col span={16}>
                <Tag>กำลังสร้าง...</Tag>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider style={{ margin: 0, marginTop: -5 }} />

        <Tabs defaultActiveKey="1" animated={false}>
          <TabPane
            tab={
              <span>
                รายการใบสั่งจ่าย{' '}
                <CheckCircleOutlined
                  style={{
                    color: values.payments.length > 0 ? `${color.success}` : `${color.grey}`,
                    marginLeft: 5,
                  }}
                />
              </span>
            }
            key="1"
          >
            <PaymentList />
          </TabPane>
        </Tabs>
      </>
    );
  }

  return content;
};

export default PaymentDetail;
