import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SubTabs } from 'shared/components';
import PAYMENT_VOUCHER_STATUS_QUERY from 'shared/graphql/query/paymentVoucherStatus';

const PaymentVoucherTabs = ({ tab, onChange }) => {
  const { loading, error, data, refetch } = useQuery(PAYMENT_VOUCHER_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const checkSelectTab = (id) => {
    return id === tab;
  };

  const selectTab = (index) => {
    refetch();
    onChange(index);
  };

  const isFetching = (loading || error) && data === undefined;

  const tabs = [
    {
      id: 1,
      name: 'รอรอบจ่าย',
      color: 'badge-grey',
      status: 'pending',
    },
    {
      id: 3,
      name: 'สร้างรอบจ่ายแล้ว',
      color: 'badge-grey',
      status: 'confirm',
    },
    {
      id: 4,
      name: 'ทำจ่ายแล้ว',
      color: 'badge-grey',
      status: 'complete',
      divider: true,
    },
    {
      id: 5,
      name: 'ปฏิเสธ',
      color: 'badge-grey',
      status: 'reject',
    },
    {
      id: 6,
      name: 'ยกเลิก',
      color: 'badge-grey',
      status: 'cancel',
    },
    {
      id: 7,
      name: 'แก้ไข',
      color: 'badge-grey',
      status: 'edit',
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: isFetching ? 0 : data.paymentVoucherStatus[item.status],
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default PaymentVoucherTabs;
