/* eslint-disable no-underscore-dangle */
import React, { ReactNode } from 'react';
import { Select, Empty } from 'antd';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;

type SelectChartOfAccountOpexFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const SelectChartOfAccountOpexField: React.FC<SelectChartOfAccountOpexFieldProps> = (props) => {
  const { value, onChange } = props;

  const chartOfAccounts = [
    {
      code: '5310000-00',
      account_name: 'ค่าใช้จ่ายสำนักงานและพนักงาน',
    },
    {
      code: '5320000-00',
      account_name: 'ค่าใช้จ่ายคลังสินค้าและพนักงาน',
    },
    {
      code: '5330000-00',
      account_name: 'ค่าเสื่อมราคา',
    },
    {
      code: '5340000-00',
      account_name: 'ค่าใช้จ่ายด้านเทคโนโลยี',
    },
    {
      code: '5350000-00',
      account_name: 'ค่าใช้จ่ายด้านการตลาด',
    },
    {
      code: '5360000-00',
      account_name: 'ค่าใช้จ่ายที่เกี่ยวกับการส่งเสริมการขาย',
    },
  ];

  const options: ReactNode[] = chartOfAccounts.map((item) => (
    <Option key={uuidv4()} value={item.code}>
      {item.code}: {item.account_name}
    </Option>
  ));

  return (
    <Select
      style={{
        width: '100%',
      }}
      value={value}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      placeholder="- เลือก -"
      showSearch
      onChange={(valueChange) => {
        if (onChange) {
          onChange(valueChange || '');
        }
      }}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};

export default SelectChartOfAccountOpexField;
