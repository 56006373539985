/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Button } from 'shared/components';
import { Container } from './Styles';
import ReportListTable from './ReportListTable';
import ReportTabs from './ReportTabs';
import ModalCreateReport from './ModalCreateReport';

const ReportsWrapper: React.FC = () => {
  return (
    <>
      <Report />
    </>
  );
};

const Report: React.FC = () => {
  const [isOpenModalCreateReport, setOpenModalCreateReport] = useState<boolean>(false);
  const [tabReport, setTabReport] = useState<number>(1);
  const [tabName, setTabName] = useState<'report'>('report');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={12}>
          <Row>
            <Col>
              <ReportTabs
                tab={tabReport}
                name={tabName}
                onChange={(value) => {
                  setTabReport(value);
                  setTabName('report');
                  setCurrentPage(1);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Col>
              <Button color="green" onClick={() => setOpenModalCreateReport(true)}>
                สร้างรายงาน
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {tabName === 'report' && (
        <ReportListTable
          pageSize={pageSize}
          onChangePageSize={(value) => setPageSize(value)}
          currentPage={currentPage}
          onChangeCurrentPage={(value) => setCurrentPage(value)}
        />
      )}

      <ModalCreateReport
        visible={isOpenModalCreateReport}
        onCancel={() => setOpenModalCreateReport(false)}
      />
    </Container>
  );
};

export default ReportsWrapper;
