import gql from 'graphql-tag';

export interface ParcelStatusData {
  parcelStatus: {
    waitParcelDetail: number;
    waitInvoice: number;
    createInvoice: number;
    cancel: number;
  };
}

export default gql`
  query ParcelStatus {
    parcelStatus {
      waitParcelDetail
      waitInvoice
      createInvoice
      cancel
    }
  }
`;
