import gql from 'graphql-tag';

export interface CreateJvData {
  createJV: {
    _id: string;
  };
}

export interface CreateJvVars {
  description: string;
  chartOfAccount: string;
  code: string;
  account_name: string;
  value: number;
  document_date: string;
}

export default gql`
  mutation CreateJv(
    $description: String
    $chartOfAccount: ID
    $code: String
    $account_name: String
    $value: Float
    $document_date: String
  ) {
    createJV(
      create: {
        description: $description
        chartOfAccount: $chartOfAccount
        code: $code
        account_name: $account_name
        value: $value
        document_date: $document_date
      }
    ) {
      _id
    }
  }
`;
