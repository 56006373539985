import React, { useRef, useContext } from 'react';
import { Row, Col, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import SINGLE_UPLOAD_MUTATION, {
  SingleUploadData,
  SingleUploadVars,
} from 'shared/graphql/mutation/singleUpload';
import CREATE_PAYMENT_DOCUMENT_MUTATION, {
  CreatePaymentDocumentData,
  CreatePaymentDocumentVars,
} from 'shared/graphql/mutation/createPaymentDocument';
import { PaymentOrderContext } from 'views/AccountPayment/common/context/PaymentOrderContext';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { Text } from 'shared/components';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalDetailPaymentOrder/model/formValues.model';
import { PaymentStatus } from 'views/AccountPayment/common/model/paymentStatus.model';
import DocumentList from './DocumentList';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const UploadDocument: React.FC = () => {
  const { errors, watch } = useFormContext<FormValues>();
  const filePondRef = useRef<any>();
  const { selectedPaymentDetail: detailPaymentOrderContext } = useContext(PaymentOrderContext);
  const [selectedPaymentDetail] = detailPaymentOrderContext;
  const watchPaymentStatus = watch('paymentStatus');

  const [createPaymentDocument] = useMutation<CreatePaymentDocumentData, CreatePaymentDocumentVars>(
    CREATE_PAYMENT_DOCUMENT_MUTATION,
    {
      update: () => {
        message.success('เพิ่มไฟล์เอกสารแล้ว');
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['PaymentDocuments'],
    },
  );

  const [singleUpload] = useMutation<SingleUploadData, SingleUploadVars>(SINGLE_UPLOAD_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.singleUpload) {
        const { path } = data.singleUpload;
        createPaymentDocument({
          variables: {
            payment_id: selectedPaymentDetail.paymentId,
            document_type: 2, // เอกสารอื่นๆ
            document_file: path,
          },
        });
      }
    },
  });

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          อัพโหลดเอกสาร
          {errors.otherDocument && <Text customType="danger">(กรุณาอัพโหลดเอกสาร)</Text>}
          <div>
            <Text customType="grey">
              (รูปแบบไฟล์ที่รองรับ .jpg , .jpeg , .png , .pdf , และขนาดไฟล์ไม่เกิน 2Mb.)
            </Text>
          </div>
        </Col>
        <Col span={24}>
          <FilePond
            ref={filePondRef}
            server={{
              process: (_fieldName, file, _metadata, _load, error) => {
                singleUpload({
                  variables: {
                    file: file as File,
                  },
                })
                  .then(() => {
                    filePondRef.current.removeFile();
                  })
                  .catch((err) => {
                    message.error(err.message);
                    error(err);
                  });
              },
            }}
            acceptedFileTypes={['image/jpeg', 'image/png', 'application/pdf']}
            maxFileSize="2MB"
            allowBrowse={
              watchPaymentStatus === PaymentStatus.REVIEW ||
              watchPaymentStatus === PaymentStatus.PENDING ||
              watchPaymentStatus === PaymentStatus.COMPLETE ||
              watchPaymentStatus === PaymentStatus.PAID
            }
            allowDrop={
              watchPaymentStatus === PaymentStatus.REVIEW ||
              watchPaymentStatus === PaymentStatus.PENDING ||
              watchPaymentStatus === PaymentStatus.COMPLETE ||
              watchPaymentStatus === PaymentStatus.PAID
            }
          />
        </Col>
      </Row>

      <DocumentList paymentId={selectedPaymentDetail.paymentId} />
    </>
  );
};

export default UploadDocument;
