/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode, useContext } from 'react';
import CODS_ITEMS_QUERY, { CodItemsData, CodItemsVars } from 'shared/graphql/query/newCodItems';
import { useQuery } from '@apollo/react-hooks';
import { Input, message, Row, Col, Popover } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDateTime } from 'shared/utils/dateTime';
import { Text, TableSearch } from 'shared/components';
import { CodContext } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/context/CodContext';
import { CodItemStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/codItemStatus.model';
import { StyledModal, StyledCheckbox, ToolsContainer } from './Styles';
import Header from './Header';

type Cods = {
  id: string;
};

type ModalAddCodWrapperProps = {
  visible: boolean;
  cods?: Cods[];
  onCancel: () => void;
  onOk: (value: Cods[]) => void;
};

type ModalAddCodProps = {
  cods: Cods[];
  onCancel: () => void;
  onOk: (value: Cods[]) => void;
};

type CodListDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: ReactNode;
  column3: ReactNode;
  column4: string;
  column5: string;
  column6: string;
};

const ModalAddCodWrapper: React.FC<ModalAddCodWrapperProps> = (props) => {
  const { visible, onCancel, cods, onOk } = props;
  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalAddCod onCancel={onCancel} cods={cods || []} onOk={onOk} />
    </StyledModal>
  );
};

const ModalAddCod: React.FC<ModalAddCodProps> = (props) => {
  const { onCancel, cods, onOk } = props;
  const [selectedCods, setSelectedCods] = useState<Cods[]>(cods);
  const [shipmentNumber, setShipmentNumber] = useState<string>('');
  const [codInNumber, setCodInNumber] = useState<string>('');
  const [pageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { selectedCodItems } = useContext(CodContext);

  const { loading, data } = useQuery<CodItemsData, CodItemsVars>(CODS_ITEMS_QUERY, {
    variables: {
      customer: selectedCodItems.customerId,
      cod_in_number: codInNumber,
      shipment_numbers: shipmentNumber,
      status: CodItemStatus.WAIT_TO_PAY,
      page: currentPage,
      showData: pageSize,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const contentPopOverShipmentNumber = (value: string[]) => {
    return (
      <>
        <Row>
          <Col>
            <Text>Shipment No: </Text>
          </Col>
        </Row>
        {value.map((item) => (
          <Row key={uuidv4()}>
            <Col>
              <Text strong>{item}</Text>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const popOverShipmentNumber = (value: string[]) => {
    if (value.length === 1) {
      return <Text>{value[0]}</Text>;
    }
    if (value.length > 1) {
      return (
        <Popover placement="rightTop" content={contentPopOverShipmentNumber(value)}>
          <div style={{ display: 'inline' }}>
            <Text>{value.length} รหัส</Text>
          </div>
        </Popover>
      );
    }
    return '-';
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'รหัส COD-IN',
      dataIndex: 'column1',
      width: 150,
      search: (
        <Input
          allowClear
          value={codInNumber}
          placeholder="ค้นหา"
          onChange={(event) => setCodInNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'ยอดรวม COD (บาท)',
      dataIndex: 'column2',
      width: 150,
      align: 'right',
    },
    {
      title: 'Shipment Number',
      dataIndex: 'column3',
      width: 150,
      search: (
        <Input
          allowClear
          value={shipmentNumber}
          placeholder="ค้นหา"
          onChange={(event) => setShipmentNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'วันที่แจ้งโอน',
      dataIndex: 'column4',
      width: 150,
    },
    {
      title: 'ผู้ตรวจสอบ',
      dataIndex: 'column5',
      width: 150,
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'column6',
      width: 150,
    },
  ];

  const selectCods = (checked: boolean, value: Cods) => {
    if (checked) {
      setSelectedCods((prevState) => {
        return [...prevState, value];
      });
    } else {
      const codsInFilter = selectedCods.filter((cod) => cod.id !== value.id);
      setSelectedCods([...codsInFilter]);
    }
  };

  let codListDataTable: CodListDataTable[] = [];
  let totalDocument = 0;

  if (data?.newCodItems) {
    const { codItems } = data.newCodItems;
    codListDataTable = codItems.map(
      (item, index): CodListDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <StyledCheckbox
              checked={selectedCods.map((cod) => cod.id).indexOf(item._id) !== -1}
              value={item._id}
              onChange={(event) =>
                selectCods(event.target.checked, {
                  id: item._id,
                })
              }
            />
          </ToolsContainer>
        ),
        column1: item.cod?.cod_in_number || '-',
        column2: (
          <Text strong customType="primary">
            {item.total_cod ? formatCurrency(item.total_cod) : '-'}
          </Text>
        ),
        column3: popOverShipmentNumber(
          item.shipment_list.map((shipment) => shipment.shipment_number),
        ),
        column4: item.cod_in_date ? formatDateTime(item.cod_in_date) : '-',
        column5: item.ops_employee?.full_name || '-',
        column6: item.last_update ? formatDateTime(item.last_update) : '-',
      }),
    );
    totalDocument = data?.newCodItems.totalDocument;
  }

  return (
    <div>
      <Header
        onSubmit={() => onOk(selectedCods)}
        onCancel={onCancel}
        btnDisabled={selectedCods.length === 0}
      />

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row>
            <Col span={8}>
              ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>
              <div>
                <Input value={selectedCodItems.customerFullName || '-'} disabled />
              </div>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <TableSearch
            columns={columns}
            dataSource={codListDataTable}
            loading={loading}
            pagination={{
              current: currentPage,
              pageSize,
              total: totalDocument,
              showSizeChanger: false,
              onChange: (value: number) => setCurrentPage(value),
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ModalAddCodWrapper;
