import styled from 'styled-components';
import { PageHeader } from 'antd';
import { font } from 'shared/utils/styles';

export const StyledPageHeader = styled(PageHeader)`
  padding: 0px;

  & .ant-page-header-heading {
    height: 50px;
  }

  & .ant-page-header-heading-title {
    ${font.size(22)}
    ${font.bold}
  }
`;

export default StyledPageHeader;
