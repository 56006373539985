/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, useContext } from 'react';
import classNames from 'classnames';
import { useQuery } from '@apollo/react-hooks';
import { Row, Col, Input } from 'antd';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { Button, Text, Spin } from 'shared/components';
import { useFormContext, Controller } from 'react-hook-form';
import INVOICE_QUERY, { InvoiceData, InvoiceVars, InvoiceList } from 'shared/graphql/query/invoice';
import { BillingNoteContext } from 'views/AccountReceive/common/context/BillingNoteContext';
import { FormValues } from 'views/AccountReceive/Routes/BillingNote/InvoicesTable/ModalInvoiceDetail/model/formValues.model';
import { v4 as uuidv4 } from 'uuid';
import { StyledTable, StyledRowDetail, StyledDetailContainer } from './Styles';
import ExpandedRow from './ExpandedRow';

const { TextArea } = Input;

type InvoiceDataTable = {
  key: string;
  tools: ReactNode;
  column1: ReactNode;
  column2: ReactNode;
  column3: ReactNode;
  column4: ReactNode;
  column5: ReactNode;
  column6: ReactNode;
  invoiceList: InvoiceList[];
};

const columns = [
  {
    title: 'เครื่องมือ',
    dataIndex: 'tools',
    width: 70,
  },
  {
    title: 'วันที่อนุมัติ',
    dataIndex: 'column1',
    width: 110,
  },
  {
    title: 'รหัสต้นทุน/ขาย',
    dataIndex: 'column2',
    width: 200,
  },
  {
    title: 'รหัสงานขนส่ง',
    dataIndex: 'column3',
    width: 200,
  },
  {
    title: 'วันที่เริ่มงาน',
    dataIndex: 'column4',
    width: 160,
  },
  {
    title: 'วันที่งานเสร็จสิ้น',
    dataIndex: 'column5',
    width: 160,
  },
  {
    title: 'ราคาขาย',
    dataIndex: 'column6',
    width: 130,
    align: 'right',
  },
];

const InvoiceTable: React.FC = () => {
  const { selectedInvoiceDetail } = useContext(BillingNoteContext);
  const { invoiceId } = selectedInvoiceDetail;
  const { control } = useFormContext<FormValues>();

  const { error, data, loading } = useQuery<InvoiceData, InvoiceVars>(INVOICE_QUERY, {
    variables: {
      _id: invoiceId,
    },
    fetchPolicy: 'cache-and-network',
  });

  let content: ReactNode = <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  let invoiceData: InvoiceDataTable[] = [];
  if (data && data.invoice) {
    const {
      invoice_detail,
      all_wht,
      re_total_before_discount,
      non_total_before_discount,
      inc_total_before_discount,
      re_total_discount,
      non_total_discount,
      inc_total_discount,
      re_total_after_discount,
      non_total_after_discount,
      inc_total_after_discount,
      total_vat,
      re_total,
      non_total,
      inc_total,
      total_price,
      grand_total_price,
    } = data.invoice;

    invoiceData = invoice_detail.map(
      (item): InvoiceDataTable => {
        const {
          costing,
          service_date,
          number_ref,
          grand_total_price: grandTotalPriceInvoice,
        } = item;

        return {
          key: uuidv4(),
          tools: (
            <Button
              disabled
              title="ลบ"
              color="red--outline"
              icon={<DeleteOutlined />}
              size="small"
            />
          ),
          column1: costing?.checked_date ? formatDate(costing.checked_date) : '-',
          column2: costing?.costing_number || '-',
          column3: number_ref,
          column4: service_date ? formatDate(service_date) : '-',
          column5: costing?.completed_date ? formatDate(costing.completed_date) : '-',
          column6:
            grandTotalPriceInvoice !== null ? (
              <Text customType="primary" strong>
                {formatCurrency(grandTotalPriceInvoice)}
              </Text>
            ) : (
              '-'
            ),
          invoiceList: item.invoice_list,
        };
      },
    );

    const whtList = all_wht.map((item, index, arr) => {
      const { wht, price, total_wht: totalWht } = item;
      const isLastItem = index === arr.length - 1;
      return (
        <StyledRowDetail key={item.wht} className={classNames({ divider: isLastItem })}>
          <Col className="column-1">{`หัก ณ ที่จ่าย ${wht}% (${formatCurrency(price)})`}</Col>
          <Col className="column-2 text-right" />
          <Col className="column-3 text-right" />
          <Col className="column-4 text-right">{formatCurrency(totalWht, '-')}</Col>
        </StyledRowDetail>
      );
    });

    content = (
      <>
        <StyledTable
          scroll={{ x: 'max-content' }}
          pagination={false}
          columns={columns}
          dataSource={invoiceData}
          size="small"
          loading={loading}
          expandable={{
            expandedRowRender: (record: { invoiceList: InvoiceList[] }) => (
              <ExpandedRow data={record.invoiceList} />
            ),
          }}
        />

        <Row justify="space-between" style={{ marginTop: 10 }}>
          <Col>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                หมายเหตุ <Text customType="grey">(ไม่บังคับ)</Text>
                <Controller
                  control={control}
                  name="invoiceRemark"
                  placeholder="ระบุ"
                  render={({ value, onChange }) => (
                    <TextArea
                      placeholder="ระบุ"
                      rows={4}
                      value={value || undefined}
                      onChange={(event) => (event ? onChange(event.target.value) : '-')}
                    />
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <StyledDetailContainer>
              <StyledRowDetail>
                <Col className="column-1">ราคารวมก่อนส่วนลด</Col>
                <Col className="column-2 text-right">
                  {formatCurrency(re_total_before_discount, '-')}
                </Col>
                <Col className="column-3 text-right">
                  {formatCurrency(non_total_before_discount, '-')}
                </Col>
                <Col className="column-4 text-right">
                  {formatCurrency(inc_total_before_discount, '-')}
                </Col>
              </StyledRowDetail>
              <StyledRowDetail className="divider">
                <Col className="column-1">ส่วนลด</Col>
                <Col className="column-2 text-right">{formatCurrency(re_total_discount, '-')}</Col>
                <Col className="column-3 text-right">{formatCurrency(non_total_discount, '-')}</Col>
                <Col className="column-4 text-right">{formatCurrency(inc_total_discount, '-')}</Col>
              </StyledRowDetail>

              <StyledRowDetail>
                <Col className="column-1">ราคารวมหลังส่วนลด</Col>
                <Col className="column-2 text-right">
                  {formatCurrency(re_total_after_discount, '-')}
                </Col>
                <Col className="column-3 text-right">
                  {formatCurrency(non_total_after_discount, '-')}
                </Col>
                <Col className="column-4 text-right">
                  {formatCurrency(inc_total_after_discount, '-')}
                </Col>
              </StyledRowDetail>

              <StyledRowDetail className="divider">
                <Col className="column-1">ภาษีมูลค่าเพิ่ม 7%</Col>
                <Col className="column-2 text-right">-</Col>
                <Col className="column-3 text-right">-</Col>
                <Col className="column-4 text-right">{formatCurrency(total_vat, '-')}</Col>
              </StyledRowDetail>

              <StyledRowDetail>
                <Col className="column-1">รวมเป็นเงิน</Col>
                <Col className="column-2 text-right">{formatCurrency(re_total, '-')}</Col>
                <Col className="column-3 text-right">{formatCurrency(non_total, '-')}</Col>
                <Col className="column-4 text-right">{formatCurrency(inc_total, '-')}</Col>
              </StyledRowDetail>

              <StyledRowDetail className="divider">
                <Col className="column-1">
                  <Text strong>จำนวนรวมทั้งสิ้น</Text>
                </Col>
                <Col className="column-2 text-right" />
                <Col className="column-3 text-right" />
                <Col className="column-4 text-right">
                  <Text strong>{formatCurrency(total_price, '-')}</Text>
                </Col>
              </StyledRowDetail>

              {whtList}

              <StyledRowDetail>
                <Col className="column-1">
                  <Text strong>รวมชำระทั้งสิ้น</Text>
                </Col>
                <Col className="column-2 text-right" />
                <Col className="column-3 text-right" />
                <Col className="column-4 text-right">
                  <Text strong style={{ fontSize: 24 }}>
                    {formatCurrency(grand_total_price)}
                  </Text>
                </Col>
              </StyledRowDetail>
            </StyledDetailContainer>
          </Col>
        </Row>
      </>
    );
  }

  if (error) {
    content = error.message;
  }

  return <div>{content}</div>;
};

export default InvoiceTable;
