import React, { useContext, useState } from 'react';
import { Row, Col } from 'antd';
import { MailOutlined, StopOutlined } from '@ant-design/icons';
import {
  ReceiptContext,
  SelectedReceiptDetailType,
} from 'views/AccountReceive/common/context/ReceiptContext';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountReceive/Routes/Receipt/ReceiptsTable/ModalReceiptDetail/model/formValues.model';
import { ReceiptStatus } from 'views/AccountReceive/common/model/receiptStatus.model';
import { AppContext } from 'shared/context/AppContext';
import { StyledButton, TextHeader, Container } from './Styles';
import ModalConfirmSendEmail from './ModalConfirmSendEmail';
import ModalConfirmCancelReceipt from './ModalConfirmCancelReceipt';

const Header: React.FC = () => {
  const { selectedReceiptDetail, setSelectedReceiptDetail } = useContext(ReceiptContext);
  const { isLoading, receiptNumber, document, receiptStatus } = selectedReceiptDetail;
  const { handleSubmit } = useFormContext<FormValues>();
  const [isOpenModalConfirmSendEmail, setIsOpenModalConfirmSendEmail] = useState<boolean>(false);
  const [isOpenModalConfirmCancelReceipt, setIsOpenModalConfirmCancelReceipt] = useState<boolean>(
    false,
  );
  const { userDetail } = useContext(AppContext);
  const { email } = userDetail;
  const supportEmail = [
    'supattra@giztix.com',
    'anusorn.k@giztix.com',
    'lamduan.s@giztix.com',
    'prachpurim.b@giztix.com',
  ];

  const closeModalHandler = () => {
    setSelectedReceiptDetail(
      (prevState): SelectedReceiptDetailType => ({
        ...prevState,
        isOpenModal: false,
        document: '',
      }),
    );
  };

  const onSubmitCancelReceipt = handleSubmit(() => {
    setIsOpenModalConfirmCancelReceipt(true);
  });

  const onSubmitSendEmailReceipt = handleSubmit(() => {
    setIsOpenModalConfirmSendEmail(true);
  });

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>{`รหัสใบเสร็จ${receiptNumber ? `: ${receiptNumber}` : ''}`}</TextHeader>
        </Col>
        <Col>
          <>
            {supportEmail.includes(email) && receiptStatus === ReceiptStatus.COMPLETE && (
              <StyledButton
                disabled={isLoading}
                icon={<StopOutlined />}
                color="red--light"
                onClick={onSubmitCancelReceipt}
              >
                ยกเลิกใบเสร็จ
              </StyledButton>
            )}
            <StyledButton
              disabled={isLoading}
              color="blue--outline"
              onClick={() => window.open(document)}
            >
              เอกสารใบเสร็จรับเงิน
            </StyledButton>

            {receiptStatus === ReceiptStatus.COMPLETE && (
              <StyledButton
                disabled={isLoading}
                icon={<MailOutlined />}
                color="green"
                onClick={onSubmitSendEmailReceipt}
              >
                ส่งใบเสร็จให้ลูกค้า
              </StyledButton>
            )}

            <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>

            <ModalConfirmSendEmail
              visible={isOpenModalConfirmSendEmail}
              onCancel={() => setIsOpenModalConfirmSendEmail(false)}
            />

            <ModalConfirmCancelReceipt
              visible={isOpenModalConfirmCancelReceipt}
              onCancel={() => setIsOpenModalConfirmCancelReceipt(false)}
            />
          </>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
