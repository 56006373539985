import React from 'react';
import { Container, LeftContainer, RightContainer, TextTitle, TextContent } from './Styles';

interface CardIconProps {
  title: string;
  text: string | React.ReactNode;
  icon: React.ReactNode;
  color?: string;
}

const CardIcon: React.FC<CardIconProps> = (props) => {
  const { title, text, icon, color, ...otherProps } = props;
  return (
    <Container {...otherProps}>
      <LeftContainer>{icon}</LeftContainer>
      <RightContainer>
        <TextTitle>{title}</TextTitle>
        <TextContent style={{ color: color || 'unset' }}>{text}</TextContent>
      </RightContainer>
    </Container>
  );
};

export default CardIcon;
