/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useState, useEffect } from 'react';
import { ModalTopButton, Text, TabPane, FileList } from 'shared/components';
import { CheckCircleOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { Row, Col, Divider, Skeleton, Spin, Popover } from 'antd';
import { useHistory } from 'react-router-dom';
import { color } from 'shared/utils/styles';
import { v4 as uuidv4 } from 'uuid';
import { formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import TagCodStatus from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/components/TagCodStatus';
import { CodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/codStatus.model';
import {
  CodContext,
  SelectedCodDetailType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/context/CodContext';
import { useQuery } from '@apollo/react-hooks';
import COD_QUERY, { CodData, CodVars } from 'shared/graphql/query/newCod';
import CardIcon from './CardIcon';
import ModalConfirmChecked from './ModalConfirmChecked';
import ModalConfirmReject from './ModalConfirmReject';

interface ModalCodDetailProps {
  codId: string;
  goBack: string;
}

const ModalCodDetailWrapper: React.FC<ModalCodDetailProps> = (props) => {
  const { codId, goBack } = props;
  const [isOpenModalConfirmChecked, setOpenModalConfirmChecked] = useState<boolean>(false);
  const [isOpenModalConfirmReject, setOpenModalConfirmReject] = useState<boolean>(false);
  const { selectedCodDetail } = useContext(CodContext);
  const { codStatus, codNumber } = selectedCodDetail;
  const history = useHistory();

  return (
    <ModalTopButton
      visible
      onCancel={() => {
        history.push(goBack);
      }}
      title={`รหัสCOD-IN: ${codNumber}`}
      width={900}
      btnHeader={[
        {
          text: 'ตรวจสอบแล้ว',
          clicked: () => setOpenModalConfirmChecked(true),
          color: 'green',
          icon: <CheckCircleOutlined />,
          visible: codStatus === CodStatus.PENDING,
        },
        {
          text: 'ปฏิเสธ',
          clicked: () => setOpenModalConfirmReject(true),
          color: 'red--light',
          visible: codStatus === CodStatus.PENDING,
        },
      ]}
    >
      <ModalCodDetail codId={codId} goBack={goBack} />
      <ModalConfirmChecked
        visible={isOpenModalConfirmChecked}
        onCancel={() => setOpenModalConfirmChecked(false)}
        goBack={goBack}
      />
      <ModalConfirmReject
        visible={isOpenModalConfirmReject}
        onCancel={() => setOpenModalConfirmReject(false)}
        goBack={goBack}
      />
    </ModalTopButton>
  );
};

const ModalCodDetail: React.FC<ModalCodDetailProps> = (props) => {
  const { codId } = props;
  const { setSelectedCodDetail } = useContext(CodContext);
  const { loading, error, data } = useQuery<CodData, CodVars>(COD_QUERY, {
    variables: {
      _id: codId,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && data?.newCod) {
      const { total_cod, vendor, shipment_numbers, cod_in_date } = data.newCod;
      setSelectedCodDetail(
        (prevState): SelectedCodDetailType => ({
          ...prevState,
          modalConfirm: {
            totalCod: total_cod,
            vendorFullName: vendor?.full_name,
            shipmentNumbers: shipment_numbers,
            codInDate: cod_in_date,
          },
        }),
      );
    }
  }, [data?.newCod, error, loading, setSelectedCodDetail]);

  const contentPopOverShipmentNumber = (value: string[]) => {
    return (
      <>
        <Row>
          <Col>
            <Text>Shipment No: </Text>
          </Col>
        </Row>
        {value.map((item) => (
          <Row key={uuidv4()}>
            <Col>
              <Text strong>{item}</Text>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const popOverShipmentNumber = (value: string[]) => {
    if (value.length === 1) {
      return <Text>{value[0]}</Text>;
    }
    if (value.length > 1) {
      return (
        <Popover placement="rightTop" content={contentPopOverShipmentNumber(value)}>
          <div style={{ display: 'inline' }}>
            <Text>{value.length} รหัส</Text>
          </div>
        </Popover>
      );
    }
    return '-';
  };

  let content = <Skeleton />;

  if (data?.newCod) {
    const {
      total_cod,
      cod_status,
      vendor,
      shipment_numbers,
      cod_in_date,
      document,
      cod_item,
      reject_remark,
    } = data.newCod;

    content = (
      <>
        <Row gutter={[32, 16]}>
          <Col
            span={12}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CardIcon
              title="จำนวนที่ 3PL ต้องโอน (บาท)"
              text={<Text customType="primary">{`${formatCurrency(total_cod)} บาท`}</Text>}
              icon={<DollarCircleOutlined />}
            />
          </Col>
          {cod_status === CodStatus.PENDING && (
            <Col
              span={12}
              style={{
                borderLeft: `1px dashed ${color.grey}`,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Row>
                <Col span={24}>สถานะ</Col>
                <Col span={24}>{cod_status && <TagCodStatus status={cod_status} />}</Col>
              </Row>
            </Col>
          )}
        </Row>

        <Divider style={{ marginBottom: 10, marginTop: -5 }} />

        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Row>
              <Col span={8}>ชื่อ 3PL:</Col>
              <Col span={16}>
                <Text strong>{vendor?.full_name || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Shipment No.:</Col>
              <Col span={16}>
                <Text strong>{popOverShipmentNumber(shipment_numbers)}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>วันที่แจ้งโอน:</Col>
              <Col span={16}>
                <Text strong>{cod_in_date ? formatDateTime(cod_in_date) : '-'}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            {cod_status === CodStatus.REJECT && (
              <Row>
                <Col span={8}>เหตุผลการปฎิเสธ:</Col>
                <Col span={16}>
                  <Text strong>{reject_remark || '-'}</Text>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Divider style={{ marginBottom: 0, marginTop: 10 }} />

        <TabPane
          tabs={[
            {
              key: '1',
              text: 'หลักฐานการโอน',
              isShowIcon: true,
              component: (
                <>
                  {document.length === 0 ? (
                    '-'
                  ) : (
                    <div>
                      <FileList files={document} />
                    </div>
                  )}
                </>
              ),
            },
            {
              key: '2',
              text: 'รายละเอียดงาน',
              isShowIcon: true,
              component: (
                <>
                  <Row gutter={[16, 0]}>
                    <Col span={6}>ชื่อลูกค้า</Col>
                    <Col span={18}>
                      <Row gutter={[16, 0]}>
                        <Col span={10}>Shipment No</Col>
                        <Col span={9}>Booking Number</Col>
                        <Col span={5} style={{ textAlign: 'right' }}>
                          มูลค่า COD
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {cod_item.length === 0
                    ? '-'
                    : cod_item.map((item) => (
                        <React.Fragment key={uuidv4()}>
                          <Row gutter={[16, 0]}>
                            <Col span={6}>{item.customer?.full_name || '-'}</Col>
                            <Col span={18}>
                              {item.shipment_list.map((itemShipment) => (
                                <Row key={uuidv4()} gutter={[16, 0]}>
                                  <Col span={10}>
                                    <Text strong>{itemShipment.shipment_number || '-'}</Text>
                                  </Col>
                                  <Col span={9}>
                                    <Text strong>{itemShipment.booking_number || '-'}</Text>
                                  </Col>
                                  <Col span={5} style={{ textAlign: 'right' }}>
                                    <Text customType="primary" strong>
                                      {itemShipment.cod_price
                                        ? formatCurrency(itemShipment.cod_price)
                                        : '-'}
                                    </Text>
                                  </Col>
                                </Row>
                              ))}
                            </Col>
                          </Row>
                          <Row justify="end">
                            <Col span={18}>
                              <Row gutter={[16, 0]} justify="end">
                                <Col span={14}>
                                  <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row gutter={[16, 0]} justify="end">
                            <Col span={18}>
                              <Row gutter={[16, 0]} align="middle" justify="end">
                                <Col span={9}>
                                  <Text strong>รวมมูลค่า COD</Text>
                                </Col>
                                <Col span={5} style={{ textAlign: 'right' }}>
                                  <Text strong customType="success">
                                    {item.total_cod ? formatCurrency(+item.total_cod) : '-'}
                                  </Text>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          {item.shipment_list.length !== 0 && (
                            <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                          )}
                        </React.Fragment>
                      ))}
                </>
              ),
            },
          ]}
        />
      </>
    );
  }

  if (error) {
    content = <div>{error.message}</div>;
  }

  return <Spin spinning={loading}>{content}</Spin>;
};

export default ModalCodDetailWrapper;
