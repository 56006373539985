/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message, Form, Input } from 'antd';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/react-hooks';
import {
  ApprovePaymentContext,
  SelectedApprovePaymentDetailType,
} from 'views/AccountPayment/common/context/ApprovePaymentContext';
import { Controller, useForm } from 'react-hook-form';
import { FormValues } from 'views/AccountPayment/Routes/ApprovePayment/AppTable/ModalAppDetail/model/formValues.model';
import UPDATE_APP_MUTATION, {
  UpdateAppData,
  UpdateAppVars,
} from 'shared/graphql/mutation/updateApp';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { StyledModal } from './Styles';

type ModalConfirmCancelRoundPaidProps = {
  visible: boolean;
  onCancel: () => void;
};

const { TextArea } = Input;

const validateSchema = yup.object().shape({
  remark: yup.string().required(),
});

const ModalConfirmCancelRoundPaid: React.FC<ModalConfirmCancelRoundPaidProps> = (props) => {
  const { onCancel, visible } = props;
  const { selectedApprovePaymentDetail, setSelectedApprovePaymentDetail } = useContext(
    ApprovePaymentContext,
  );
  const { modalConfirmUpdate, appId } = selectedApprovePaymentDetail;
  const { grandTotalPrice, appNumber } = modalConfirmUpdate;

  const methods = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      remark: '',
    },
  });
  const { control, errors, getValues, watch } = methods;
  const watchCancelRemark = watch('remark');

  const [updateApp, { loading }] = useMutation<UpdateAppData, UpdateAppVars>(UPDATE_APP_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.updateApp._id) {
        message.success('ยกเลิกรอบจ่ายแล้ว');
        onCancel();
        setSelectedApprovePaymentDetail(
          (prevState): SelectedApprovePaymentDetailType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }
    },
    onError: (err) => {
      const textError = err.graphQLErrors.length > 0 ? err.graphQLErrors[0].message : err.message;
      message.error(textError);
    },
    refetchQueries: () => ['App', 'AppStatus', 'Apps'],
  });

  const confirmUpdate = () => {
    console.log(appId, getValues('remark'));
    updateApp({
      variables: {
        _id: appId,
        app_status: 5,
        cancle_remark: getValues('remark'),
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ต้องการยกเลิกรอบจ่าย?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="blue"
          onClick={() => confirmUpdate()}
          disabled={!watchCancelRemark || loading}
          loading={loading}
        >
          ใช่, ต้องการยกเลิกรอบจ่าย
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>รหัสรอบจ่าย:</Col>
        <Col span={18}>
          <Text strong>{appNumber}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>มูลค่ารอบจ่าย (บาท):</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(grandTotalPrice, '0')}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>เหตุผลยกเลิกรอบจ่าย:</Col>
        <Col span={18}>
          <Form.Item validateStatus={errors.remark && 'error'}>
            <Controller
              style={{ width: '100%' }}
              as={TextArea}
              name="remark"
              control={control}
              placeholder="ระบุ"
              rows={4}
            />
          </Form.Item>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmCancelRoundPaid;
