import gql from 'graphql-tag';

export interface DeletePaymentDocumentsVars {
  _id: string[];
}

export interface DeletePaymentDocumentsData {
  deletePaymentDocuments: {
    _id: string;
  };
}

export default gql`
  mutation DeletePaymentDocuments($_id: [ID]) {
    deletePaymentDocuments(_id: $_id) {
      _id
    }
  }
`;
