/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Row, Col, Form, Input, Button, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Logo from 'shared/assets/images/logo-giztix.svg';
import { storeAuthToken } from 'shared/utils/authToken';
import { StyledLogo, TextHeader, StyledCard } from './Styles';
import loginMutation from './graphql/mutation/login';

const onFinish = (signIn, email, password) => {
  signIn({
    variables: {
      email,
      password,
    },
  });
};

const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [messageError, setMessageError] = useState('');

  const [signIn, { loading }] = useMutation(loginMutation, {
    update: (_cache, { data }) => {
      storeAuthToken(data.login.token);
      window.location.href = '/';
    },
    onError: (error) => {
      let textError =
        error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : error.message;
      if (textError === 'User not found.') {
        textError = 'ไม่พบอีเมลผู้ใช้งาน';
      } else if (textError === 'Password is incorrect.') {
        textError = 'อีเมลหรือรหัสผ่านไม่ถูกต้อง';
      } else if (textError === 'Email is invalid.') {
        textError = 'รูปแบบอีเมลไม่ถูกต้อง';
      }

      setMessageError(textError);
    },
  });

  return (
    <Row>
      <Col xl={8} lg={6} md={6} sm={24} xs={24} />
      <Col xl={8} lg={12} md={12} sm={24} xs={24}>
        <StyledCard>
          <StyledLogo>
            <Logo width={200} />
          </StyledLogo>
          <TextHeader>Giztix Billing System</TextHeader>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={() => {
              setMessageError('');
              onFinish(signIn, email, password);
            }}
          >
            {messageError && (
              <Alert message={messageError} type="error" style={{ marginBottom: 20 }} />
            )}
            <Form.Item name="email">
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Form.Item>
            <Form.Item name="password">
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{ marginTop: 20 }}
              loading={loading}
              disabled={!email || !password}
            >
              เข้าสู่ระบบ
            </Button>
          </Form>
        </StyledCard>
      </Col>
      <Col xl={8} lg={6} md={6} sm={24} xs={24} />
    </Row>
  );
};

export default Login;
