/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext, ReactNode } from 'react';
import { Input, DatePicker, message, Row, Col, Select, Modal } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, FileExcelOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import PAYMENT_CODS_QUERY, {
  PaymentCodsData,
  PaymentCodsVars,
} from 'shared/graphql/query/paymentCods';
import {
  PaymentCodContext,
  SelectedPaymentCodDetailType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/PaymentCodContext';
import {
  Text,
  Button,
  TextExpand,
  SelectCustomersField,
  SelectCodFieldOld,
  SelectBookingField,
  TableSearch,
} from 'shared/components';
import { PaymentCodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/model/paymentCodStatus.model';
import baseUrl from 'shared/config/baseUrl';
import { ToolsContainer } from './Styles';
import ModalPaymentCodDetail from './ModalPaymentCodDetail';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

type PaymentCodListTableProps = {
  status: PaymentCodStatus;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type PaymentCodListDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: ReactNode;
  column4: ReactNode;
  column5: ReactNode;
  column6: string;
  column7: string;
  column8: string;
};

const PaymentCodListTable: React.FC<PaymentCodListTableProps> = (props) => {
  const { status, pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [customerId, setCustomerId] = useState<string>('');
  const [codOutNumber, setCodOutNumber] = useState<string>('');
  const [codInId, setCodInId] = useState<string>('');
  const [bookingCodInId, setBookingCodInId] = useState<string>('');
  const [paidDate, setPaidDate] = useState<string>('');
  const [bankRef, setBankRef] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('');

  const { setSelectedPaymentCodDetail } = useContext(PaymentCodContext);

  const { loading, error, data } = useQuery<PaymentCodsData, PaymentCodsVars>(PAYMENT_CODS_QUERY, {
    variables: {
      payment_cod_status: status,
      customer: customerId,
      cod_out_number: codOutNumber,
      cod: codInId || bookingCodInId,
      paid_date: paidDate,
      bank_ref: bankRef,
      showData: pageSize,
      page: currentPage,
      order_by: orderBy,
      order_type: orderType,
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'column1',
      sortName: 'customer.full_name',
      width: 200,
      search: <SelectCustomersField onChange={(value: string) => setCustomerId(value)} />,
    },
    {
      title: 'รหัส COD-OUT',
      dataIndex: 'column2',
      sortName: 'cod_out_number',
      width: 150,
      search: (
        <Input
          allowClear
          value={codOutNumber}
          placeholder="ค้นหา"
          onChange={(event) => setCodOutNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'รหัส COD-IN',
      dataIndex: 'column3',
      width: 150,
      search: (
        <SelectCodFieldOld
          value={codInId || undefined}
          onChange={(value) => {
            setCodInId(value);
            setBookingCodInId('');
          }}
        />
      ),
    },
    {
      title: 'Booking Number',
      dataIndex: 'column4',
      width: 150,
      search: (
        <SelectBookingField
          value={bookingCodInId || undefined}
          onChange={(value) => {
            setBookingCodInId(value);
            setCodInId('');
          }}
        />
      ),
    },
    {
      title: 'ยอดรวม COD (บาท)',
      dataIndex: 'column5',
      sortName: 'total_cod',
      width: 150,
      align: 'right',
    },
    ...(status === 1
      ? [
          {
            title: 'อัพเดทล่าสุด',
            dataIndex: 'column6',
            sortName: 'last_update',
            width: 150,
          },
        ]
      : []),
    ...(status === 2
      ? [
          {
            title: 'วันที่จ่าย',
            dataIndex: 'column7',
            sortName: 'paid_date',
            width: 150,
            search: (
              <RangePicker
                onChange={(values) => {
                  const formatRangDate =
                    values === null
                      ? ''
                      : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

                  setPaidDate(formatRangDate);
                }}
                format="YYYY/MM/DD"
                placeholder={['เริ่มต้น', 'สิ้นสุด']}
              />
            ),
          },
        ]
      : []),
    ...(status === 2
      ? [
          {
            title: 'Bank Ref',
            dataIndex: 'column8',
            width: 150,
            search: (
              <Input
                allowClear
                value={bankRef}
                placeholder="ค้นหา"
                onChange={(event) => setBankRef(event.target.value)}
              />
            ),
          },
        ]
      : []),
  ];

  let billingNotesData: PaymentCodListDataTable[] = [];
  let totalDocument = 0;

  const showConfirmDownloadFile = (paymentCodId: string) => {
    const modal = {
      title: 'ยืนยัน',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          ต้องการ <Text strong>ดาวน์โหลดไฟล์</Text> ใช่หรือไม่?
        </>
      ),
      okText: 'ดาวน์โหลด',
      onOk() {
        window.location.href = `${baseUrl}/report-COD-02/${paymentCodId}`;
      },
      cancelText: 'ยกเลิก',
    };

    confirm(modal);
  };

  if (data?.paymentCods) {
    const { paymentCods } = data.paymentCods;
    billingNotesData = paymentCods.map(
      (item, index): PaymentCodListDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <Button
              onClick={() => {
                setSelectedPaymentCodDetail(
                  (prevState): SelectedPaymentCodDetailType => ({
                    ...prevState,
                    paymentCodId: item._id,
                    isOpenModal: true,
                  }),
                );
              }}
              color="grey--text-only"
              icon={<SearchOutlined />}
              size="small"
            />
            {status === PaymentCodStatus.PAID && (
              <Button
                onClick={() => showConfirmDownloadFile(item._id)}
                color="grey--text-only"
                icon={<FileExcelOutlined />}
                size="small"
              />
            )}
          </ToolsContainer>
        ),
        column1: item.customer.full_name || '-',
        column2: item.cod_out_number || '-',
        column3: (
          <TextExpand expand={!loading}>
            {item.cod.map((codItem) => codItem.cod_in_number).join(', ')}
          </TextExpand>
        ),
        column4: (
          <TextExpand expand={!loading}>
            {item.cod.map((codItem) => codItem.booking_number).join(', ')}
          </TextExpand>
        ),
        column5: (
          <Text strong customType="primary">
            {formatCurrency(item.total_cod)}
          </Text>
        ),
        column6: formatDateTime(item.last_update) || '-',
        column7: formatDateTime(item.paid_date) || '-',
        column8: item.bank_ref || '-',
      }),
    );

    totalDocument = data.paymentCods.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={billingNotesData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value: number) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />

      <ModalPaymentCodDetail />
    </>
  );
};

export default PaymentCodListTable;
