/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import { useQuery } from '@apollo/react-hooks';
import { Row, Col, Input } from 'antd';
import { DeleteOutlined, PlusCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useFormContext, Controller } from 'react-hook-form';
import BUILD_APP_QUERY, {
  BuildAppData,
  BuildAppVars,
  Payment,
} from 'shared/graphql/query/buildApp';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { Button, Text, Spin } from 'shared/components';
import { FormValues } from 'views/AccountPayment/Routes/PaymentVoucher/ModalCreateApp/model/formValues.model';
import ModalAddPaymentVoucher from 'views/AccountPayment/Routes/PaymentVoucher/ModalCreateApp/PaymentVoucherCreateApp/PaymentVoucherList/ModalAddPaymentVoucher';
import { StyledTable, StyledFormItem, StyledDetailContainer, StyledRowDetail } from './Styles';
import ExpandedRow from './ExpandedRow';

const { TextArea } = Input;

type PaymentVoucherDataTable = {
  key: string;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: string;
  column4: string;
  column5: ReactNode;
  payment: Payment[];
};

const columns = [
  {
    title: 'เครื่องมือ',
    dataIndex: 'tools',
    width: 70,
  },
  {
    title: 'วันที่เอกสาร',
    dataIndex: 'column1',
    width: 180,
  },
  {
    title: 'รหัสใบสำคัญจ่าย',
    dataIndex: 'column2',
    width: 200,
  },
  {
    title: 'รอบจ่าย',
    dataIndex: 'column3',
    width: 130,
    align: 'right',
  },
  {
    title: 'ผู้รับเงิน',
    dataIndex: 'column4',
    width: 300,
  },
  {
    title: 'จำนวนตั้งเบิก (บาท)',
    dataIndex: 'column5',
    width: 160,
  },
  {
    title: '',
  },
];

const PaymentVoucherList: React.FC = () => {
  const { getValues, setValue, control, watch } = useFormContext<FormValues>();
  const [isOpenModalAddPaymentVoucher, setOpenModalAddPaymentVoucher] = useState<boolean>(false);
  const vouchersId = getValues('vouchersId') ? getValues('vouchersId') : [];
  const watchVouchersId = watch('vouchersId');

  const { error, data, loading } = useQuery<BuildAppData, BuildAppVars>(BUILD_APP_QUERY, {
    variables: {
      payment_voucher: vouchersId,
    },
    fetchPolicy: 'cache-and-network',
  });

  let content: ReactNode = <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  let paymentVoucherData: PaymentVoucherDataTable[] = [];
  if (data && data.buildApp) {
    const {
      payment_voucher,
      re_total_before_discount,
      non_total_before_discount,
      inc_total_before_discount,
      re_total_discount,
      non_total_discount,
      inc_total_discount,
      re_total_after_discount,
      non_total_after_discount,
      inc_total_after_discount,
      total_vat,
      re_total,
      non_total,
      inc_total,
      total_price,
      all_wht,
      grand_total_price,
    } = data.buildApp;

    paymentVoucherData = payment_voucher.map((item) => {
      return {
        key: item._id,
        tools: (
          <Button
            disabled={getValues('vouchersId').length < 2}
            title="ลบ"
            color="red--outline"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => {
              const filterPaymentVoucher = getValues('vouchersId').filter(
                (voucherId) => voucherId !== item._id,
              );
              setValue('vouchersId', filterPaymentVoucher);
            }}
          />
        ),
        column1: formatDate(item.create_date),
        column2: item.voucher_number || '-',
        column3: formatDate(item.round_paid),
        column4: item.payee.full_name || '-',
        column5: (
          <Text customType="primary" strong>
            {formatCurrency(item.grand_total_price)}
          </Text>
        ),
        payment: item.payment,
      };
    });

    const whtList = all_wht.map((item, index, arr) => {
      const { wht, price, total_wht: totalWht } = item;
      const isLastItem = index === arr.length - 1;
      return (
        <StyledRowDetail key={item.wht} className={classNames({ divider: isLastItem })}>
          <Col className="column-1">{`หัก ณ ที่จ่าย ${wht}% (${formatCurrency(price)})`}</Col>
          <Col className="column-2 text-right" />
          <Col className="column-3 text-right" />
          <Col className="column-4 text-right">{formatCurrency(totalWht, '-')}</Col>
        </StyledRowDetail>
      );
    });

    content = (
      <>
        <StyledTable
          scroll={{ x: 'max-content' }}
          pagination={false}
          columns={columns}
          dataSource={paymentVoucherData}
          size="small"
          expandable={{
            expandedRowRender: (record: { payment: Payment[] }) => (
              <ExpandedRow data={record.payment} />
            ),
          }}
          loading={loading}
        />
        <Row justify="space-between" style={{ marginTop: 10 }}>
          <Col>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button
                  color="blue--light"
                  icon={<PlusCircleOutlined />}
                  onClick={() => setOpenModalAddPaymentVoucher(true)}
                >
                  เพิ่มรายการ
                </Button>
              </Col>
              <Col span={24}>
                หมายเหตุ <Text customType="grey">(ไม่บังคับ)</Text>
                <StyledFormItem>
                  <Controller
                    control={control}
                    name="appRemark"
                    render={({ onChange, value }) => (
                      <TextArea
                        value={value || undefined}
                        placeholder="ระบุ"
                        rows={4}
                        onChange={(event) => {
                          onChange(event.target.value);
                        }}
                      />
                    )}
                  />
                </StyledFormItem>
              </Col>
            </Row>
          </Col>
          <Col>
            <StyledDetailContainer>
              <StyledRowDetail>
                <Col className="column-1">ราคารวมก่อนส่วนลด</Col>
                <Col className="column-2 text-right">
                  {formatCurrency(re_total_before_discount, '-')}
                </Col>
                <Col className="column-3 text-right">
                  {formatCurrency(non_total_before_discount, '-')}
                </Col>
                <Col className="column-4 text-right">
                  {formatCurrency(inc_total_before_discount, '-')}
                </Col>
              </StyledRowDetail>
              <StyledRowDetail className="divider">
                <Col className="column-1">ส่วนลด</Col>
                <Col className="column-2 text-right">{formatCurrency(re_total_discount, '-')}</Col>
                <Col className="column-3 text-right">{formatCurrency(non_total_discount, '-')}</Col>
                <Col className="column-4 text-right">{formatCurrency(inc_total_discount, '-')}</Col>
              </StyledRowDetail>

              <StyledRowDetail>
                <Col className="column-1">ราคารวมหลังส่วนลด</Col>
                <Col className="column-2 text-right">
                  {formatCurrency(re_total_after_discount, '-')}
                </Col>
                <Col className="column-3 text-right">
                  {formatCurrency(non_total_after_discount, '-')}
                </Col>
                <Col className="column-4 text-right">
                  {formatCurrency(inc_total_after_discount, '-')}
                </Col>
              </StyledRowDetail>
              <StyledRowDetail className="divider">
                <Col className="column-1">ภาษีมูลค่าเพิ่ม 7%</Col>
                <Col className="column-2 text-right">-</Col>
                <Col className="column-3 text-right">-</Col>
                <Col className="column-4 text-right">{formatCurrency(total_vat, '-')}</Col>
              </StyledRowDetail>

              <StyledRowDetail>
                <Col className="column-1">รวมเป็นเงิน</Col>
                <Col className="column-2 text-right">{formatCurrency(re_total, '-')}</Col>
                <Col className="column-3 text-right">{formatCurrency(non_total, '-')}</Col>
                <Col className="column-4 text-right">{formatCurrency(inc_total, '-')}</Col>
              </StyledRowDetail>
              <StyledRowDetail className="divider">
                <Col className="column-1">
                  <Text strong>จำนวนรวมทั้งสิ้น</Text>
                </Col>
                <Col className="column-2 text-right" />
                <Col className="column-3 text-right" />
                <Col className="column-4 text-right">
                  <Text strong>{formatCurrency(total_price, '-')}</Text>
                </Col>
              </StyledRowDetail>

              {whtList}

              <StyledRowDetail>
                <Col className="column-1">
                  <Text strong>รวมชำระทั้งสิ้น</Text>
                </Col>
                <Col className="column-2 text-right" />
                <Col className="column-3 text-right" />
                <Col className="column-4 text-right">
                  <Text strong style={{ fontSize: 24 }}>
                    {formatCurrency(grand_total_price)}
                  </Text>
                </Col>
              </StyledRowDetail>
            </StyledDetailContainer>
          </Col>
        </Row>

        <ModalAddPaymentVoucher
          visible={isOpenModalAddPaymentVoucher}
          onOk={(paymentVouchers) => {
            setValue(
              'vouchersId',
              paymentVouchers.map((paymentVoucher) => paymentVoucher.id),
            );

            setOpenModalAddPaymentVoucher(false);
          }}
          onCancel={() => setOpenModalAddPaymentVoucher(false)}
          paymentVouchers={payment_voucher.map((paymentVoucher) => ({
            id: paymentVoucher._id,
            createDate: paymentVoucher.create_date,
            paymentVoucherNumber: paymentVoucher.voucher_number,
            roundPaid: paymentVoucher.round_paid,
            payee: paymentVoucher.payee.full_name,
            grandTotalPrice: paymentVoucher.grand_total_price,
          }))}
        />
      </>
    );
  }

  if (error) {
    content = error.message;
  }

  return <div>{watchVouchersId && content}</div>;
};

export default PaymentVoucherList;
