import React from 'react';
import formatCurrency from 'shared/utils/formatCurrency';
import { Text } from 'shared/components';

type TextProfitColor = {
  value: number;
};

type TextTypeProps = 'success' | 'danger' | undefined;

const TextProfitColor: React.FC<TextProfitColor> = (props) => {
  const { value } = props;

  let type: TextTypeProps;
  let formatNumber: string = formatCurrency(value);
  if (value < 0) {
    type = 'danger';
    formatNumber = `(${formatCurrency(value * -1)})`;
  } else if (value > 0) {
    type = 'success';
  }

  return <Text customType={type}>{formatNumber}</Text>;
};

export default TextProfitColor;
