import gql from 'graphql-tag';

export interface InvoiceStatusData {
  invoiceStatus: {
    draft: number;
    invoice: number;
    billing_note: number;
    receipt: number;
    cancel: number;
    edit: number;
  };
}

export default gql`
  query InvoiceStatus {
    invoiceStatus {
      draft
      invoice
      billing_note
      receipt
      cancel
      edit
    }
  }
`;
