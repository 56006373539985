/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import UPDATE_PAYMENT_VOUCHER_MUTATION, {
  UpdatePaymentVoucherData,
  UpdatePaymentVoucherVars,
} from 'shared/graphql/mutation/updatePaymentVoucher';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { formatDate } from 'shared/utils/dateTime';
import { StyledModal } from './Styles';

type ModalConfirmCreatePaymentProps = {
  paymentVoucherId: string;
  payments: {
    id: string;
    createDate: string;
    paymentNumber: string;
    totalPrice: number;
  }[];
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmAddPayment: React.FC<ModalConfirmCreatePaymentProps> = (props) => {
  const { onCancel, visible, payments, paymentVoucherId } = props;
  const [updatePaymentVoucher, { loading }] = useMutation<
    UpdatePaymentVoucherData,
    UpdatePaymentVoucherVars
  >(UPDATE_PAYMENT_VOUCHER_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.updatePaymentVoucher._id) {
        message.success('เพิ่มรายการแล้ว');
        onCancel();
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => [
      'PaymentVoucher',
      'PaymentVoucherStatus',
      'PaymentVouchers',
      'PaymentStatus',
    ],
  });

  const confirmUpdate = () => {
    updatePaymentVoucher({
      variables: {
        _id: paymentVoucherId,
        payment: payments.map((item) => item.id),
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันการเพิ่มใบสั่งจ่ายหรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmUpdate()}
          loading={loading}
        >
          เพิ่มใบสั่งจ่าย
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          ทั้งหมด <Text strong>{`${payments.length} รายการ`}</Text>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 0]}>
            <Col span={6}>วันที่เอกสาร</Col>
            <Col span={6}>รหัสใบสั่งจ่าย</Col>
            <Col span={6}>จำนวนตั้งเบิก (บาท)</Col>
          </Row>

          {payments.map((payment) => (
            <Row gutter={[8, 0]}>
              <Col span={6}>
                <Text strong>{formatDate(payment.createDate)}</Text>
              </Col>
              <Col span={6}>
                <Text strong>{payment.paymentNumber}</Text>
              </Col>
              <Col span={6}>
                <Text strong customType="primary">
                  {formatCurrency(payment.totalPrice)}
                </Text>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmAddPayment;
