import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { message } from 'antd';
import { AppProvider } from 'shared/context/AppContext';
import PDPA from 'shared/components/PDPA';
import { QueryClient, QueryClientProvider } from 'react-query';
import NormalizeStyles from './NormalizeStyles';
import BaseStyles from './BaseStyles';
import Routes from './Routes';
import 'filepond/dist/filepond.min.css';
import 'react-image-lightbox/style.css';
import './fontStyles.css';
import './style.css';

library.add(fas, fal);
message.config({
  duration: 3,
  maxCount: 5,
});

const queryClient = new QueryClient();

const App = () => (
  <>
    <NormalizeStyles />
    <BaseStyles />
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <PDPA />
        <Routes />
      </AppProvider>
    </QueryClientProvider>
  </>
);

export default App;
