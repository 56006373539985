/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  PaymentCodContext,
  SelectedPaymentCodDetailType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/context/PaymentCodContext';
import { Spin } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { message } from 'antd';
import PAYMENT_COD_QUERY, {
  PaymentCodData,
  PaymentCodVars,
} from 'shared/graphql/query/paymentNewCod';
import { PaymentCodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/paymentCodStatus.model';
import Header from './Header';
import { StyledModal } from './Styles';
import PaymentCodDetail from './PaymentCodDetail';
import { FormValues } from './model/formValues.model';

const ModalPaymentCodDetailWrapper: React.FC = () => {
  const { selectedPaymentCodDetail, setSelectedPaymentCodDetail } = useContext(PaymentCodContext);
  const { isOpenModal } = selectedPaymentCodDetail;

  return (
    <StyledModal
      visible={isOpenModal}
      onCancel={() => {
        setSelectedPaymentCodDetail(
          (prevState): SelectedPaymentCodDetailType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalPaymentCodDetail />
    </StyledModal>
  );
};

const validateSchema = yup.object().shape({
  paymentCodId: yup.string().required(),
  paymentMethod: yup.string().required(),
  bankName: yup.string().required(),
  bankBranch: yup.string(),
  accountName: yup.string().required(),
  accountNumber: yup.string().required(),
  paidDate: yup.string().required(),
  bankRef: yup.string().required(),
  proofSlip: yup.string().required(),
  slipRemark: yup.string(),
});

const ModalPaymentCodDetail: React.FC = () => {
  const { selectedPaymentCodDetail, setSelectedPaymentCodDetail } = useContext(PaymentCodContext);
  const { isLoading, paymentCodId } = selectedPaymentCodDetail;
  const { data, loading, error } = useQuery<PaymentCodData, PaymentCodVars>(PAYMENT_COD_QUERY, {
    variables: {
      _id: paymentCodId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validateSchema),
    defaultValues: {
      paymentCodId: '',
      paymentMethod: '',
      bankName: '',
      bankBranch: '',
      accountName: '',
      accountNumber: '',
      proofSlip: '',
      paidDate: '',
      slipRemark: '',
      bankRef: '',
    },
  });

  const { register, reset } = methods;

  useEffect(() => {
    setSelectedPaymentCodDetail(
      (prevState): SelectedPaymentCodDetailType => ({
        ...prevState,
        isLoading: loading,
      }),
    );

    if (!loading && !error && data?.paymentNewCod) {
      const {
        _id,
        payment_cod_status,
        cod_out_number,
        customer,
        total_cod,
        bank_account,
        payment_method,
      } = data.paymentNewCod;

      setSelectedPaymentCodDetail(
        (prevState): SelectedPaymentCodDetailType => ({
          ...prevState,
          paymentCodStatus: payment_cod_status,
          paymentCodNumber: cod_out_number,
          customerId: customer._id,
          customerFullName: customer.full_name,
          totalCod: total_cod,
        }),
      );

      const defaultData =
        payment_cod_status === PaymentCodStatus.PAID
          ? {
              bankName: bank_account.bank_name,
              bankBranch: bank_account.bank_branch,
              accountNumber: bank_account.account_number,
              accountName: bank_account.account_name,
              paymentMethod: payment_method,
            }
          : {};

      setTimeout(() => {
        reset({
          paymentCodId: _id,
          ...defaultData,
        });
      });
    }

    return () => {
      setSelectedPaymentCodDetail(
        (prevState): SelectedPaymentCodDetailType => ({
          ...prevState,
          isLoading: true,
        }),
      );
    };
  }, [data?.paymentNewCod, error, loading, reset, setSelectedPaymentCodDetail]);

  useEffect(() => {
    register('paymentCodId');
    register('bankName');
    register('bankBranch');
    register('accountName');
    register('proofSlip');
  }, [register]);

  return (
    <Spin spinning={isLoading}>
      <FormProvider {...methods}>
        <form>
          <Header />
          <PaymentCodDetail />
        </form>
      </FormProvider>
    </Spin>
  );
};

export default ModalPaymentCodDetailWrapper;
