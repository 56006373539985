import gql from 'graphql-tag';

export default gql`
  query PaymentWHT($voucher_id: ID!) {
    paymentWHT(voucher_id: $voucher_id) {
      _id
      doc_type
      wht_type
      book_number
      running_number
      wht_order
      wht_list {
        description
        date
        amount
        tax
        input1
        input2
      }
      document
      customer {
        _id
        full_name
        tax_id
        address
      }
      vendor {
        _id
        full_name
        tax_id
        address
      }
    }
  }
`;
