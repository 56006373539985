/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import formatCurrency from 'shared/utils/formatCurrency';
import { Text } from 'shared/components';
import { ShipmentList } from 'shared/graphql/query/buildPaymentNewCod';
import { StyledTable } from './Styles';

type ExpandedRowProps = {
  data: ShipmentList[];
};

const columns = [
  {
    title: 'Shipment No.',
    dataIndex: 'column1',
    width: 160,
  },
  {
    title: 'Booking Number',
    dataIndex: 'column2',
    width: 160,
  },
  {
    title: 'จำนวนที่ 3PL ต้องโอน (บาท)',
    dataIndex: 'column3',
    width: 200,
    align: 'right',
  },
  {
    title: '',
  },
];

const ExpandedRow: React.FC<ExpandedRowProps> = (props) => {
  const { data } = props;

  const shipmentListData = data?.map((item, index) => {
    const { shipment_number, booking_number, cod_price } = item;

    return {
      key: index,
      column1: shipment_number || '-',
      column2: booking_number || '-',
      column3: (
        <Text customType="primary" strong>
          {cod_price ? formatCurrency(cod_price) : '-'}
        </Text>
      ),
    };
  });

  return (
    <div style={{ marginTop: 10 }}>
      <StyledTable
        scroll={{ x: 'max-content' }}
        pagination={false}
        columns={columns}
        dataSource={shipmentListData}
        size="small"
      />
    </div>
  );
};

export default ExpandedRow;
