import React, { useState } from 'react';
import { PaymentCodProvider } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/context/PaymentCodContext';
import { Row, Col } from 'antd';
import { PaymentCodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/paymentCodStatus.model';
import { Container } from './Styles';
import PaymentCodTabs from './PaymentCodTabs';
import PaymentCodListTable from './PaymentCodListTable';

const PaymentCodWrapper: React.FC = () => {
  return (
    <PaymentCodProvider>
      <PaymentCod />
    </PaymentCodProvider>
  );
};

const PaymentCod: React.FC = () => {
  const [tabPaymentCod, setTabPaymentCod] = useState<PaymentCodStatus>(PaymentCodStatus.WAITING);
  const [tabName, setTabName] = useState<'paymentCod'>('paymentCod');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={16}>
          <Row>
            <Col style={{ marginRight: 20 }}>
              <PaymentCodTabs
                tab={tabPaymentCod}
                name={tabName}
                onChange={(value) => {
                  setTabPaymentCod(value);
                  setTabName('paymentCod');
                  setCurrentPage(1);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {tabName === 'paymentCod' && (
        <PaymentCodListTable
          status={tabPaymentCod}
          pageSize={pageSize}
          onChangePageSize={(value) => setPageSize(value)}
          currentPage={currentPage}
          onChangeCurrentPage={(value) => setCurrentPage(value)}
        />
      )}
    </Container>
  );
};

export default PaymentCodWrapper;
