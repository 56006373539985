/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message, Divider } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { BillingNoteContext } from 'views/AccountReceive/common/context/BillingNoteContext';
import CREATE_BILLING_NOTE_MUTATION, {
  CreateBillingNoteData,
  CreateBillingNoteVars,
} from 'shared/graphql/mutation/createBillingNote';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { formatDate } from 'shared/utils/dateTime';
import { FormValues } from 'views/AccountReceive/Routes/BillingNote/ModalCreateBillingNote/formValues.model';
import { useFormContext } from 'react-hook-form';
import { StyledModal } from './Styles';

type ModalConfirmCreateBillingNoteProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmCreateBillingNote: React.FC<ModalConfirmCreateBillingNoteProps> = (props) => {
  const { onCancel, visible } = props;
  const { getValues } = useFormContext<FormValues>();
  const { selectedInvoices, setSelectedInvoices } = useContext(BillingNoteContext);
  const { modalConfirmCreateBillingNote } = selectedInvoices;
  const {
    payerFullName,
    businessInvoiceType,
    grandTotalPrice,
    invoices,
  } = modalConfirmCreateBillingNote;

  const [createBillingNote, { loading }] = useMutation<
    CreateBillingNoteData,
    CreateBillingNoteVars
  >(CREATE_BILLING_NOTE_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.createBillingNote._id) {
        message.success('สร้างใบวางบิลแล้ว');
        onCancel();
        setSelectedInvoices((prevState) => ({
          ...prevState,
          isOpenModal: false,
          invoicesId: [],
        }));
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['Invoice', 'InvoiceStatus', 'Invoices', 'BillingNoteStatus'],
  });

  const confirmCreateBillingNote = () => {
    createBillingNote({
      variables: {
        bill_invoice: getValues('invoicesId').map((invoiceId) => ({ invoice: invoiceId })),
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันสร้างใบวางบิลใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmCreateBillingNote()}
          loading={loading}
        >
          ใช่,สร้างใบวางบิล
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>
          ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>:
        </Col>
        <Col span={18}>
          <Text strong>{payerFullName}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>รูปแบบรับใบแจ้งหนี้:</Col>
        <Col span={18}>
          <Text strong>{businessInvoiceType}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>มูลค่าใบแจ้งหนี้รวม:</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(grandTotalPrice, '0')}
          </Text>
        </Col>
      </Row>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Row>
        <Col span={24}>
          ทั้งหมด <Text strong>{`${invoices.length} รายการ`}</Text>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 0]}>
            <Col span={4}>วันที่เอกสาร</Col>
            <Col span={4}>รหัสใบแจ้งหนี้</Col>
            <Col span={4}>มูลค่าใบแจ้งหนี้</Col>
            <Col span={6}>วันที่ครบกำหนดชำระ</Col>
          </Row>

          {invoices.map((invoice) => (
            <Row gutter={[8, 0]} key={invoice.invoiceNumber}>
              <Col span={4}>
                <Text strong>{invoice.serviceDate ? formatDate(invoice.serviceDate) : '-'}</Text>
              </Col>
              <Col span={4}>
                <Text strong>{invoice.invoiceNumber || '-'}</Text>
              </Col>
              <Col span={4}>
                <Text strong customType="primary">
                  {formatCurrency(invoice.grandTotalPrice)}
                </Text>
              </Col>
              <Col span={6}>
                <Text strong>{invoice.dueDate || '-'}</Text>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmCreateBillingNote;
