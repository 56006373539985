import styled from 'styled-components';
import { Typography } from 'antd';
import { mixin } from 'shared/utils/styles';

const { Text } = Typography;
export const TextButton = styled(Text)`
  ${mixin.clickable}
`;

export default TextButton;
