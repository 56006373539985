import moment from 'moment';

const toBuddhistYear = (momentDateTime, format) => {
  const christianYear = momentDateTime.format('YYYY');
  const buddhistYear = (parseInt(christianYear, 10) + 543).toString();
  return momentDateTime
    .format(format.replace('YYYY', buddhistYear).replace('YY', buddhistYear.substring(2, 4)))
    .replace(christianYear, buddhistYear);
};

export const formatDate = (date, format = 'YYYY/MM/DD') =>
  date ? toBuddhistYear(moment(date), format) : date;

export const formatDateTime = (date, format = 'YYYY/MM/DD - HH:mm:ss') =>
  date ? toBuddhistYear(moment(date), format) : date;

export const formatDateTimeForAPI = (date) => (date ? moment(date).utc().format() : date);
