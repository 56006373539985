import React, { Component } from 'react';
import { Redirect, Route, matchPath } from 'react-router-dom';
import Tabs from 'shared/components/Tabs';
import Layout from 'shared/container/Layout';
import Invoice from './Routes/Invoice';
import BillingNote from './Routes/BillingNote';
import Receipt from './Routes/Receipt';

class AccountReceive extends Component {
  selectTab = (tabIndex) => {
    const { history, match } = this.props;
    const getUrl = match.path;
    history.push(`${getUrl}/${tabIndex}`);
  };

  checkTabsActive = (tabIndex) => {
    const { match, location } = this.props;
    return !!matchPath(location.pathname, `${match.path}/${tabIndex}`);
  };

  render() {
    const { match } = this.props;
    const tabs = [
      {
        name: 'ใบแจ้งหนี้',
        icon: 'file',
        active: this.checkTabsActive('invoice'),
        clicked: () => this.selectTab('invoice'),
      },
      {
        name: 'ใบวางบิล',
        icon: 'file',
        active: this.checkTabsActive('billing-note'),
        clicked: () => this.selectTab('billing-note'),
      },
      {
        name: 'ใบเสร็จ',
        icon: 'file',
        active: this.checkTabsActive('receipt'),
        clicked: () => this.selectTab('receipt'),
      },
    ];

    return (
      <>
        {match.isExact && <Redirect to={`${match.path}/invoice`} />}
        <Layout textHeader="บัญชีรายรับ">
          <Tabs menu={tabs} />
          <Route path={`${match.path}/invoice`} component={Invoice} />
          <Route path={`${match.path}/billing-note`} component={BillingNote} />
          <Route path={`${match.path}/receipt`} component={Receipt} />
        </Layout>
      </>
    );
  }
}

export default AccountReceive;
