/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode, useEffect } from 'react';
import DATA_MASTER_SERVICE_ITEM_QUERY, {
  DataMasterServiceItemByChartOfAccountData,
  DataMasterServiceItemByChartOfAccountVars,
} from 'shared/graphql/query/dataMasterServiceItemByChartOfAccount';
import { useQuery } from '@apollo/react-hooks';
import { Input, message, Row, Col, Radio, Select } from 'antd';
import { Text, TableSearch } from 'shared/components';
import { StyledModal, StyledRadio, ToolsContainer } from './Styles';
import Header from './Header';

const { Option } = Select;

type DataMasterService = {
  id: string;
  dataMasterServiceItemNumber: string;
  serviceItemName: string;
};

type OnCancelFunc = () => void;
type OnOkFunc = (value: DataMasterService) => void;

type ModalAddServiceWrapperProps = {
  visible: boolean;
  onCancel: OnCancelFunc;
  onOk: OnOkFunc;
};

type ModalAddServiceProps = {
  onCancel: OnCancelFunc;
  onOk: OnOkFunc;
};

type DataMasterServiceItemsTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
};

const ModalAddServiceWrapper: React.FC<ModalAddServiceWrapperProps> = (props) => {
  const { visible, onCancel, onOk } = props;
  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalAddService onCancel={onCancel} onOk={onOk} />
    </StyledModal>
  );
};

const serviceModeFive = '607fa19880488fde2cb89dae'; // ค่าใช้จ่าย
const serviceModeOne = '607fa03780488fde2cb86204'; // สินทรัพย์
const serviceModeTwo = '607fa0ad80488fde2cb8750c'; // หนี้สิน

const ModalAddService: React.FC<ModalAddServiceProps> = (props) => {
  const { onCancel, onOk } = props;
  const [selectedDataMasterService, setSelectedDataMasterService] = useState<DataMasterService>();
  const [serviceItemName, setServiceItemName] = useState<string>('');
  const [dataMasterServiceItemNumber, setDatamasterServiceItemNumber] = useState<string>('');
  const [selectDataMasterServiceCategory, setSelectDataMasterServiceCategory] = useState<string>(
    serviceModeFive,
  );
  const [modeValue, setModeValue] = useState<string>('60d99d4ddce21a972b14ab7c');
  const [pageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const setMode = (value: string) => {
    switch (value) {
      case serviceModeFive:
        return setModeValue('60d99d4ddce21a972b14ab7c');
      case serviceModeOne:
        return setModeValue('60d99b3cc7c47b969218180b');
      case serviceModeTwo:
        return setModeValue('60d99bb731c8b696af010329');
      default:
        return '';
    }
  };

  useEffect(() => {
    setMode(selectDataMasterServiceCategory);
  }, [selectDataMasterServiceCategory]);

  const { loading, data } = useQuery<
    DataMasterServiceItemByChartOfAccountData,
    DataMasterServiceItemByChartOfAccountVars
  >(DATA_MASTER_SERVICE_ITEM_QUERY, {
    variables: {
      code: dataMasterServiceItemNumber,
      account_name: serviceItemName,
      mode: modeValue,
      page: currentPage,
      showData: pageSize,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 100,
    },
    {
      title: 'รหัส',
      dataIndex: 'column1',
      width: 150,
      search: (
        <Input
          value={dataMasterServiceItemNumber}
          placeholder="ค้นหา"
          onChange={(event) => setDatamasterServiceItemNumber(event.target.value)}
          allowClear
        />
      ),
    },
    {
      title: 'รายการ',
      dataIndex: 'column2',
      search: (
        <Input
          value={serviceItemName}
          placeholder="ค้นหา"
          onChange={(event) => setServiceItemName(event.target.value)}
          allowClear
        />
      ),
    },
  ];

  const selectDataMasterService = (value: DataMasterService) => {
    setSelectedDataMasterService({
      id: value.id,
      dataMasterServiceItemNumber: value.dataMasterServiceItemNumber,
      serviceItemName: value.serviceItemName,
    });
  };

  let dataMasterServiceItemsTable: DataMasterServiceItemsTable[] = [];
  let totalDocument = 0;

  if (data?.DataMasterServiceItemByChartOfAccount) {
    const { dataMasterServiceItems } = data.DataMasterServiceItemByChartOfAccount;
    dataMasterServiceItemsTable = dataMasterServiceItems.map(
      (item, index): DataMasterServiceItemsTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <StyledRadio
              value={item}
              onChange={() => {
                selectDataMasterService({
                  id: item._id,
                  dataMasterServiceItemNumber: item.datamaster_service_item_number,
                  serviceItemName: item.service_item_name.th,
                });
              }}
            />
          </ToolsContainer>
        ),
        column1: item.datamaster_service_item_number,
        column2: item.service_item_name.th,
      }),
    );

    totalDocument = data.DataMasterServiceItemByChartOfAccount.totalDocument;
  }

  return (
    <div>
      <Header
        onSubmit={() => {
          if (selectedDataMasterService) {
            onOk(selectedDataMasterService);
          }
        }}
        onCancel={onCancel}
        btnDisabled={!selectedDataMasterService}
      />

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row>
            <Col span={8}>
              ประเภทการจ่าย<Text customType="danger">*</Text>
              <div>
                <Select
                  value={selectDataMasterServiceCategory}
                  onChange={(value) => setSelectDataMasterServiceCategory(value)}
                  style={{ width: '100%' }}
                >
                  <Option value={serviceModeFive}>ค่าใช้จ่าย</Option>
                  <Option value={serviceModeOne}>สินทรัพย์</Option>
                  <Option value={serviceModeTwo}>หนี้สิน</Option>
                </Select>
                {/* <SelectDataMasterServiceCategoryField
                  value={selectDataMasterServiceCategory}
                  disabled
                /> */}
              </div>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Radio.Group style={{ width: '100%' }}>
            <TableSearch
              columns={columns}
              dataSource={dataMasterServiceItemsTable}
              loading={loading}
              pagination={{
                current: currentPage,
                pageSize,
                total: totalDocument,
                showSizeChanger: false,
                onChange: (value: number) => setCurrentPage(value),
              }}
            />
          </Radio.Group>
        </Col>
      </Row>
    </div>
  );
};

export default ModalAddServiceWrapper;
