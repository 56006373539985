import gql from 'graphql-tag';

export interface Employee {
  _id: string;
  department: string;
  full_name: string;
  email: string;
  phone: string;
  create_date: string;
  last_update: string;
}

export interface EmployeeData {
  employee: Employee;
}

export interface EmployeeVars {
  _id: string;
}

export default gql`
  query Employee($_id: ID!) {
    employee(_id: $_id) {
      _id
      department
      full_name
      email
      phone
      create_date
      last_update
    }
  }
`;
