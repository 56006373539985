import styled from 'styled-components';
import { Button } from 'antd';

export const StyledButtonFilter = styled(Button)`
  &.ant-btn {
    padding: 1px;
  }

  &.ant-btn-icon-only {
    height: 33px;
  }

  & svg {
    fill: #8c8c8c;
    width: 15px;
    height: 15px;
  }
`;

export default StyledButtonFilter;
