/* eslint-disable no-underscore-dangle */
import React, { useEffect, useContext, useState } from 'react';
import { Row, Col, Skeleton, Divider } from 'antd';
import { CloseCircleOutlined, FormOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { v4 as uuidv4 } from 'uuid';
import { PageResultError, Text, Button } from 'shared/components';
import { formatDateTime, formatDate } from 'shared/utils/dateTime';
import { color } from 'shared/utils/styles';
import FOLLOW_DEBT_HISTORY_QUERY, {
  FollowDebtHistoryData,
  FollowDebtHistoryVars,
} from 'shared/graphql/query/followDebtHistory';
import {
  DebtTrackerContext,
  DebtTrackerHistoryValue,
  SelectedDebtTrackerHistory,
} from 'views/DebtTracker/common/context/DebtTrackerContext';
import { LineDashedHead, LineDashedItem, Dot } from './Styles';
import ModalConfirmCancel from './ModalConfirmCancel';

type Params = {
  invoiceId: string;
};

const DebtTrackerHistoryDetail: React.FC = () => {
  const { invoiceId } = useParams<Params>();

  const {
    debtTrackerHistoryValue,
    setDebtTrackerHistoryValue,
    setSelectedDebtTrackerHistory,
  } = useContext(DebtTrackerContext);

  const [isOpenModalConfirmCancel, setOpenModalConfirmCancel] = useState<boolean>(false);
  const [followDetailId, setFollowDetailId] = useState<string>('');

  const { data, error, loading } = useQuery<FollowDebtHistoryData, FollowDebtHistoryVars>(
    FOLLOW_DEBT_HISTORY_QUERY,
    {
      variables: {
        invoice: invoiceId,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (!loading && !error && !data?.followDebtHistory) {
      setDebtTrackerHistoryValue(
        (prevState): DebtTrackerHistoryValue => ({
          ...prevState,
          followDebtHistoryId: '',
          followDetailAmount: 0,
        }),
      );
    }

    if (!loading && !error && data?.followDebtHistory) {
      setDebtTrackerHistoryValue(
        (prevState): DebtTrackerHistoryValue => ({
          ...prevState,
          followDebtHistoryId: data.followDebtHistory._id,
          followDetailAmount: data.followDebtHistory.followDetail.length,
        }),
      );
    }
  }, [data?.followDebtHistory, error, loading, setDebtTrackerHistoryValue]);

  let content = <Skeleton />;

  if (error) {
    content = <PageResultError title="เกิดข้อผิดพลาด" message={error.message} />;
  }

  if (data?.followDebtHistory === null) {
    content = (
      <Row justify="center" style={{ marginTop: 30 }}>
        <Text strong style={{ color: '#989898' }}>
          - ไม่มีข้อมูลประวัติการตามหนี้ -
        </Text>
      </Row>
    );
  }

  if (data?.followDebtHistory) {
    const { followDetail, sendEmailHistorys } = data.followDebtHistory;

    content = (
      <>
        {debtTrackerHistoryValue.receiptDate && (
          <LineDashedItem>
            <Row>
              <Col span={3}>
                <Text strong>
                  {formatDateTime(debtTrackerHistoryValue.receiptDate).slice(0, 10) +
                    formatDateTime(debtTrackerHistoryValue.receiptDate).slice(12, 18)}{' '}
                  น.
                </Text>
              </Col>
              <Col span={1}>
                <Dot color={color.success} />
              </Col>
              <Col span={20}>
                <Row>
                  <Col span={18}>
                    <Text strong>ติดตามหนี้สำเร็จแล้ว</Text>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      textAlign: 'right',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    <Text style={{ fontSize: 13, marginRight: 3 }}>
                      ผู้บันทึก: <Text strong>System</Text>
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Text>ลูกหนี้ได้ทำการชำระหนี้เรียบร้อยแล้ว</Text>
                </Row>
              </Col>
            </Row>
            <Divider style={{ marginLeft: 104, marginTop: 15, marginBottom: 15 }} />
          </LineDashedItem>
        )}

        {followDetail.length > 0 &&
          followDetail
            .map((item, index) => (
              <>
                <LineDashedHead>
                  <Row justify="end" style={{ marginBottom: 7 }}>
                    <Col span={13} style={{}}>
                      <Text
                        strong
                        style={{
                          fontSize: 21,
                          opacity: item.cancelStatus ? 0.5 : 1,
                        }}
                      >
                        บันทึกการติดตามหนี้ ครั้งที่ {item.no}
                      </Text>
                    </Col>
                    <Col span={7} style={{ textAlign: 'right' }}>
                      {index === followDetail.length - 1 &&
                      !item.cancelStatus &&
                      !debtTrackerHistoryValue.receiptDate ? (
                        <>
                          <Button
                            type="link"
                            icon={<CloseCircleOutlined />}
                            style={{ color: color.danger, paddingRight: 0 }}
                            onClick={() => {
                              setFollowDetailId(item._id);
                              setOpenModalConfirmCancel(true);
                            }}
                          >
                            <Text>ยกเลิก</Text>
                          </Button>
                          <Button
                            type="link"
                            icon={<FormOutlined />}
                            style={{ color: color.primary, paddingRight: 3 }}
                            onClick={() => {
                              setSelectedDebtTrackerHistory(
                                (prevState): SelectedDebtTrackerHistory => ({
                                  ...prevState,
                                  isEditDebtTrackerHistory: true,
                                  historyLogId: item.historyLogs[item.historyLogs.length - 1]._id,
                                }),
                              );
                            }}
                          >
                            <Text>แก้ไข</Text>
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </LineDashedHead>
                {item.historyLogs.length > 0 &&
                  item.historyLogs
                    .map((itemHistoryLog, indexHistoryLog) => (
                      <LineDashedItem key={uuidv4()}>
                        <Row>
                          <Col span={3}>
                            <Row>
                              <Text strong>
                                {formatDateTime(itemHistoryLog.createdAt).slice(0, 10)}
                              </Text>
                            </Row>
                            <Row>
                              <Text strong>
                                {formatDateTime(itemHistoryLog.createdAt).slice(13, 18)}
                                &nbsp;น.
                              </Text>
                            </Row>
                            {itemHistoryLog.status === 2 && !item.cancelStatus && (
                              <Row>
                                <Text customType="grey">แก้ไข {indexHistoryLog}</Text>
                              </Row>
                            )}
                            {item.cancelStatus && (
                              <Row>
                                <Text customType="danger">ถูกยกเลิก</Text>
                              </Row>
                            )}
                          </Col>
                          <Col span={1}>
                            <Dot color="#3378B7" />
                          </Col>
                          <Col span={20} style={{ opacity: item.cancelStatus ? 0.5 : 1 }}>
                            <Row>
                              <Col span={6}>
                                <Text strong>สถานะการติดต่อ:</Text>
                              </Col>
                              <Col span={12}>
                                <Text>{itemHistoryLog.followStatusDetail || '-'}</Text>
                              </Col>
                              <Col
                                span={6}
                                style={{
                                  textAlign: 'right',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                }}
                              >
                                <Text style={{ fontSize: 13, marginRight: 3 }}>
                                  ผู้บันทึก:&nbsp;
                                  <Text strong>{itemHistoryLog.employee?.first_name || '-'}</Text>
                                </Text>
                              </Col>
                            </Row>
                            <Row justify="end">
                              <Col span={18} style={{ backgroundColor: '#FFF3D3', padding: 5 }}>
                                <Text>{itemHistoryLog.actionDetail || '-'}</Text>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={6}>
                                <Text strong>รายละเอียด:</Text>
                              </Col>
                              <Col span={18}>
                                <Text>{itemHistoryLog.detail || '-'}</Text>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={6}>
                                <Text strong>นัดหมายครั้งต่อไป:</Text>
                              </Col>
                              <Col span={18}>
                                <Text>{formatDate(itemHistoryLog.nextfollowDate || '-')}</Text>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {(index !== 0 || indexHistoryLog !== 0) && (
                          <Divider style={{ marginLeft: 104, marginTop: 15, marginBottom: 15 }} />
                        )}
                      </LineDashedItem>
                    ))
                    .reverse()}
              </>
            ))
            .reverse()}

        {sendEmailHistorys.length > 0 && (
          <Divider style={{ marginLeft: 104, marginTop: 15, marginBottom: 15 }} />
        )}

        {sendEmailHistorys.length > 0 &&
          sendEmailHistorys
            .map((item, index) => (
              <LineDashedItem key={uuidv4()}>
                <Row>
                  <Col span={3}>
                    <Text strong>
                      {formatDateTime(item.sendDate).slice(0, 10) +
                        formatDateTime(item.sendDate).slice(12, 18)}{' '}
                      น.
                    </Text>
                  </Col>
                  <Col span={1}>
                    <Dot color="#3378B7" />
                  </Col>
                  <Col span={20}>
                    <Row>
                      <Col span={18}>
                        <Text strong>ระบบส่ง Email ติดตามหนี้ ครั้งที่ {item.no}</Text>
                      </Col>
                      <Col
                        span={6}
                        style={{
                          textAlign: 'right',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        <Text style={{ fontSize: 13, marginRight: 3 }}>
                          ผู้บันทึก: <Text strong>{item.employee?.first_name || '-'}</Text>
                        </Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={22}>
                        <Text>
                          ระบบส่งการติดตามหนี้ไปยังอีเมล &nbsp;
                          {item.email && item.email.length === 0 ? '-' : item.email.join(', ')}{' '}
                          เรียบร้อยแล้ว
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {index !== 0 && (
                  <Divider style={{ marginLeft: 104, marginTop: 15, marginBottom: 15 }} />
                )}
              </LineDashedItem>
            ))
            .reverse()}
        <ModalConfirmCancel
          visible={isOpenModalConfirmCancel}
          onCancel={() => setOpenModalConfirmCancel(false)}
          followDetailId={followDetailId}
        />
      </>
    );
  }

  return content;
};

export default DebtTrackerHistoryDetail;
