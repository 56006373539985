import * as yup from 'yup';

const validateSchema = yup.object().shape({
  startNumber: yup
    .string()
    .required('กรุณากรอกข้อมูล')
    .matches(/^[0-9]+$/, 'รูปแบบไม่ถูกต้อง'),
  endNumber: yup
    .string()
    .required('กรุณากรอกข้อมูล')
    .matches(/^[0-9]+$/, 'รูปแบบไม่ถูกต้อง')
    .test('endNumber', '', function (value) {
      const { parent, createError } = this;
      if (Number(value) <= Number(parent.startNumber)) {
        return createError({
          message: `ต้องมากกว่า ${parent.startNumber}`,
        });
      }
      return true;
    }),
});

export default validateSchema;
