import gql from 'graphql-tag';

export interface ConfirmReceipt {
  proof_of_payment: string[];
}

export interface ConfirmReceiptData {
  confirmReceipt: ConfirmReceipt;
}

export interface ConfirmReceiptVars {
  billing_id: string;
}

export default gql`
  query ConfirmReceipt($billing_id: ID!) {
    confirmReceipt(billing_id: $billing_id) {
      proof_of_payment
    }
  }
`;
