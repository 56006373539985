import gql from 'graphql-tag';

export interface CreateConfirmReceiptData {
  createConfirmReceipt: {
    _id: string;
  };
}

export interface CreateConfirmReceiptVars {
  invoice: string[];
  channel: string;
  receipt_date: string;
  bank_name: string;
  account_number: string;
  receipt_price: number;
  proof_of_payment: string[];
  receipt_discount?: number;
  bank_fee?: number;
  other_expenses?: number;
  all_wht?: {
    wht: number;
    total_wht: number;
  }[];
  other_income?: number;
  interest_income?: number;
  receipt_value: number;
}

export default gql`
  mutation CreateConfirmReceipt(
    $invoice: [ID]
    $channel: String
    $receipt_date: String
    $bank_name: String
    $account_number: String
    $receipt_price: Float
    $receipt_discount: Float
    $bank_fee: Float
    $other_expenses: Float
    $all_wht: [inputAllWHT]
    $other_income: Float
    $interest_income: Float
    $proof_of_payment: [String]
    $receipt_value: Float
  ) {
    createConfirmReceipt(
      createConfirmReceipt: {
        invoice: $invoice
        channel: $channel
        receipt_date: $receipt_date
        bank_name: $bank_name
        account_number: $account_number
        receipt_price: $receipt_price
        receipt_discount: $receipt_discount
        bank_fee: $bank_fee
        other_expenses: $other_expenses
        all_wht: $all_wht
        other_income: $other_income
        interest_income: $interest_income
        proof_of_payment: $proof_of_payment
        receipt_value: $receipt_value
      }
    ) {
      _id
    }
  }
`;
