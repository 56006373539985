import React, { useContext, useEffect } from 'react';
import {
  CodContext,
  SelectedCodItemType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/context/CodContext';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormValues } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/Routes/Cod/ModalCreatePaymentCod/model/formValues.model';
import Header from './Header';
import CreatePaymentCod from './CreatePaymentCod';
import { StyledModal } from './Styles';

const ModalCreatePaymentCodWrapper = () => {
  const { selectedCodItems, setSelectedCodItems } = useContext(CodContext);
  const { isOpenModal } = selectedCodItems;

  return (
    <StyledModal
      visible={isOpenModal}
      onCancel={() => {
        setSelectedCodItems(
          (prevState): SelectedCodItemType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalCreatePaymentCod />
    </StyledModal>
  );
};

const ModalCreatePaymentCod: React.FC = () => {
  const validateSchema = yup.object().shape({
    paymentCodRemark: yup.string().nullable(),
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      paymentCodRemark: '',
    },
  });

  const { reset } = methods;

  useEffect(() => {
    setTimeout(() => {
      reset({
        paymentCodRemark: '',
      });
    }, 0);
  }, [reset]);

  return (
    <FormProvider {...methods}>
      <Header />
      <CreatePaymentCod />
    </FormProvider>
  );
};

export default ModalCreatePaymentCodWrapper;
