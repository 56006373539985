import gql from 'graphql-tag';

export default gql`
  query CurrentUser {
    currentUser {
      _id
      email
      name
      phone
      status
      permission
      create_date
      last_update
      employee
    }
  }
`;
