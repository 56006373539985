/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { Typography } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import PAYMENT_VOUCHER_QUERY from 'shared/graphql/query/paymentVoucher';
import PAYMENT_WHT_QUERY from 'shared/graphql/query/paymentWHT';
import { PaymentVoucherContext } from 'views/AccountPayment/common/context/PaymentVoucherContext';
import { StyledTable } from './Styles';

const { Text } = Typography;
const columns = [
  {
    dataIndex: 'column1',
  },
  {
    dataIndex: 'column2',
  },
  {
    dataIndex: 'column3',
  },
  {
    dataIndex: 'column4',
  },
];

const TableDetail = () => {
  const { selectedPaymentVoucherDetail: selectedPaymentVoucherDetailContext } = useContext(
    PaymentVoucherContext,
  );
  const [selectedPaymentVoucherDetail] = selectedPaymentVoucherDetailContext;
  const { paymentVoucherId } = selectedPaymentVoucherDetail;

  const { data: paymentVoucherData } = useQuery(PAYMENT_VOUCHER_QUERY, {
    variables: {
      _id: paymentVoucherId,
    },
  });

  const { data: paymentWHTData } = useQuery(PAYMENT_WHT_QUERY, {
    variables: {
      voucher_id: paymentVoucherId,
    },
  });

  let vendor = {
    fullName: '',
    taxId: '',
    address: '',
  };

  let customer = {
    fullName: '',
    taxId: '',
    address: '',
  };

  const giztix = {
    fullName: 'บริษัท จิซทิกซ์ จำกัด',
    address:
      'เลขที่ 101 , ตึก ทรู ดิจิทัล พาร์ค ชั้น 15 ห้องเลขที่ 1507-1509 ถนน สุขุมวิท แขวงบางจาก เขตพระโขนง กรุงเทพ 10260',
    taxId: '0105558124767',
  };

  if (paymentWHTData && paymentWHTData.paymentWHT) {
    const { paymentWHT } = paymentWHTData;

    if (paymentWHT.vendor) {
      vendor = {
        fullName: paymentWHT.vendor.full_name,
        taxId: paymentWHT.vendor.tax_id,
        address: paymentWHT.vendor.address,
      };
    }

    if (paymentWHT.customer) {
      customer = {
        fullName: paymentWHT.customer.full_name,
        taxId: paymentWHT.customer.tax_id,
        address: paymentWHT.customer.address,
      };
    }
  }

  let dataTable = [];
  if (paymentVoucherData && paymentVoucherData.paymentVoucher) {
    const { payment } = paymentVoucherData.paymentVoucher;
    const paymentType = payment[0].payment_type;
    if (paymentType === 'Advance Payment') {
      dataTable = [
        {
          title: 'ผู้มีหน้าที่หัก ณ ที่จ่าย:',
          ...customer,
        },
        {
          title: 'กระทำการแทน:',
          ...giztix,
        },
        {
          title: 'ผู้ถูกหักภาษี ณ ที่จ่าย:',
          ...vendor,
        },
      ];
    } else {
      dataTable = [
        {
          title: 'ผู้มีหน้าที่หัก ณ ที่จ่าย:',
          ...giztix,
        },
        {
          title: 'กระทำการแทน:',
          fullName: '',
          address: '',
          taxId: '',
        },
        {
          title: 'ผู้ถูกหักภาษี ณ ที่จ่าย:',
          ...vendor,
        },
      ];
    }
  }

  const dataToTable = dataTable.map((item, index) => ({
    key: index,
    column1: <Text strong>{item.title}</Text>,
    column2: (
      <>
        <Text strong>{item.fullName || '-'}</Text>
        <div>{item.address}</div>
      </>
    ),
    column3: <Text strong>เลขประจำตัวผู้เสียภาษีอากร:</Text>,
    column4: item.taxId || '-',
  }));

  return (
    <StyledTable
      bordered
      dataSource={dataToTable}
      columns={columns}
      pagination={false}
      showHeader={false}
    />
  );
};

export default TableDetail;
