import React from 'react';
import { Redirect, Route, matchPath, RouteComponentProps, Switch } from 'react-router-dom';
import Tabs from 'shared/components/Tabs';
import { PageNotFound } from 'shared/components';
import Cod from './Routes/Cod';
import PaymentCod from './Routes/PaymentCod';
import Dashboard from './Routes/Dashboard';

type Tabs = 'cod-in' | 'cod-out' | 'dashboard';

const CashOnDeliveryByParcel: React.FC<RouteComponentProps> = (props) => {
  const { history, match, location } = props;

  const selectTab = (tabIndex: Tabs) => {
    const getUrl = match.path;
    history.push(`${getUrl}/${tabIndex}`);
  };

  const checkTabsActive = (tabIndex: Tabs) => {
    return !!matchPath(location.pathname, `${match.path}/${tabIndex}`);
  };

  const tabs = [
    {
      name: 'COD-IN',
      icon: 'file',
      active: checkTabsActive('cod-in'),
      clicked: () => selectTab('cod-in'),
    },
    {
      name: 'COD-OUT',
      icon: 'file',
      active: checkTabsActive('cod-out'),
      clicked: () => selectTab('cod-out'),
    },
    {
      name: 'Dashboard',
      icon: 'file',
      active: checkTabsActive('dashboard'),
      clicked: () => selectTab('dashboard'),
    },
  ];

  return (
    <>
      <Tabs menu={tabs} />
      <Switch>
        {match.isExact && <Redirect to={`${match.path}/cod-in`} />}
        <Route path={`${match.path}/cod-in`} component={Cod} />
        <Route path={`${match.path}/cod-out`} component={PaymentCod} />
        <Route path={`${match.path}/dashboard`} component={Dashboard} />
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default CashOnDeliveryByParcel;
