/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, message, Row, Typography } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { ParcelCostingContext } from 'views/Costing/common/context/ParcelCostingContext';
import CONFIRM_PARCEL_COST_MUTATION, {
  ConfirmParcelCostData,
  ConfirmParcelCostVars,
} from 'shared/graphql/mutation/confirmParcelCost';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button } from 'shared/components';
import { useHistory } from 'react-router-dom';
import { StyledModal } from './Styles';

const { Text } = Typography;

type ModalConfirmCheckedProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmChecked: React.FC<ModalConfirmCheckedProps> = (props) => {
  const { onCancel, visible } = props;
  const history = useHistory();
  const { selectedCostingDetail } = useContext(ParcelCostingContext);
  const { modalConfirmChecked, costingId, backUrl } = selectedCostingDetail;
  const { costingNumber, refNumber, totalAfterDiscount } = modalConfirmChecked;

  const closeModalHandler = () => {
    history.push(backUrl);
  };

  const [confirmParcelCost, { loading }] = useMutation<
    ConfirmParcelCostData,
    ConfirmParcelCostVars
  >(CONFIRM_PARCEL_COST_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.confirmParcelCost._id) {
        message.success('เปลี่ยนสถานะต้นทุน/ขายแล้ว');
        onCancel();
        closeModalHandler();
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['ParcelCost', 'ParcelCostStatus', 'ParcelCostList'],
  });

  const confirmReturn = () => {
    confirmParcelCost({
      variables: {
        _id: costingId,
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ตรวจสอบรายการราคาแล้วใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmReturn()}
          loading={loading}
        >
          ตรวจสอบแล้ว
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={5}>
          <Row>
            <Col span={24}>รหัสต้นทุน/ขาย</Col>
            <Col span={24}>
              <Text strong>{costingNumber || '-'}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={14}>
          <Row>
            <Col span={24}>รหัสงานขนส่ง</Col>
            <Col span={24}>
              <Text strong>{refNumber || '-'}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={5}>
          <Row>
            <Col span={24}>ต้นทุนก่อนภาษี</Col>
            <Col span={24}>
              <Text strong>{formatCurrency(totalAfterDiscount)}</Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmChecked;
