export const STYLE_CALENDAR_MARGIN = {
  marginTop: '20px',
};

export const STYLE_MODAL_HEADER = {
  display: 'grid',
  gridTemplateColumns: '2fr 1fr 1fr',
};

export const STYLE_MODAL_TITLE = {
  fontSize: '18px',
};

export const STYLE_MODAL_ADD_LINE = {
  width: '100%',
  borderBottom: '1px solid rgb(235 235 235)',
  marginTop: '10px',
  marginBottom: '10px',
};
