import gql from 'graphql-tag';

export interface CustomersNameAndBranchVars {
  full_name?: string;
  showData?: number;
}

interface Customers {
  _id: string;
  full_name: string;
}

export interface CustomersNameAndBranchData {
  customersNameAndBranch: {
    customers: Customers[];
  };
}

export default gql`
  query CustomersNameAndBranch($full_name: String, $showData: Int) {
    customersNameAndBranch(params: { full_name: $full_name }, showData: $showData) {
      customers {
        _id
        full_name
      }
    }
  }
`;
