/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode } from 'react';
import { Select, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import VENDORS_QUERY, { VendorsData, VendorsVars } from 'shared/graphql/query/vendors';

const { Option } = Select;

type SelectVendorsFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const SelectVendorsField: React.FC<SelectVendorsFieldProps> = (props) => {
  const { value, onChange } = props;
  const [fullName, setFullName] = useState<string>('');
  const { data, loading } = useQuery<VendorsData, VendorsVars>(VENDORS_QUERY, {
    variables: {
      full_name: fullName,
      showData: 10,
    },
    fetchPolicy: 'cache-and-network',
  });

  let options: ReactNode[] = [];
  if (data && data.vendors) {
    const { vendors } = data.vendors;
    options = vendors.map((vendor) => (
      <Option key={vendor._id} value={vendor._id}>
        {vendor.full_name}
      </Option>
    ));
  }

  return (
    <Select
      allowClear
      style={{
        width: '100%',
      }}
      value={value}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      showSearch
      placeholder="ค้นหา"
      onSearch={(valueSearch) => setFullName(valueSearch)}
      onChange={(valueChange) => onChange && onChange(valueChange)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};

export default SelectVendorsField;
