import gql from 'graphql-tag';

export interface AppStatusData {
  appStatus: {
    pending: number;
    approved: number;
    paid: number;
    edit: number;
    cancel: number;
  };
}

export default gql`
  query AppStatus {
    appStatus {
      pending
      approved
      paid
      edit
      cancel
    }
  }
`;
