import gql from 'graphql-tag';

export interface Employee {
  _id: string;
  department: string;
  title: string;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  phone: string;
  create_date: string;
}

export interface BankAccount {
  bank_name: string;
  bank_branch: string;
  account_name: string;
  account_number: string;
}

export interface Payee {
  _id: string;
  number?: string;
  title?: string;
  first_name?: string;
  last_name?: string;
  full_name: string;
  email: string[];
  phone: string[];
  tax_id: string;
  address: string;
  sub_district: string;
  district: string;
  province: string;
  postcode: string;
  full_address: string;
  bank_account: BankAccount[];
  create_date: string;
  last_update: string;
}

export interface Payer {
  _id: string;
  from: number;
  number: string;
  status: number;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string[];
  phone: string[];
  tax_id: string;
  branch?: string;
  address?: string;
  sub_district?: string;
  district?: string;
  province?: string;
  postcode?: string;
  full_address?: string;
  create_date: string;
  last_update: string;
}

export interface Payment {
  _id?: string;
  payment_number?: string;
}

export interface Invoice {
  _id?: string;
  invoice_number?: string;
}

export interface Price {
  cost: number;
  sale: number;
}

export interface Discount {
  cost: number;
  sale: number;
}

export interface Vat {
  cost: number;
  sale: number;
}

export interface TotalVat {
  cost: number;
  sale: number;
}

export interface Wht {
  cost: number;
  sale: number;
}

export interface TotalBeforeDiscount {
  cost: number;
  sale: number;
}

export interface TotalPrice {
  cost: number;
  sale: number;
}

export interface GrandTotalPrice {
  cost: number;
  sale: number;
}

export interface CostingList {
  service_category_name: string;
  advance_type: boolean;
  list_number: string;
  list_name: string;
  qty: number;
  unit: string;
  price: Price;
  discount: Discount;
  vat: Vat;
  total_vat: TotalVat;
  wht: Wht;
  total_before_discount: TotalBeforeDiscount;
  total_price: TotalPrice;
  grand_total_price: GrandTotalPrice;
}

export interface ReTotalBeforeDiscount {
  cost: number;
  sale: number;
}

export interface NonTotal {
  cost: number;
  sale: number;
}

export interface IncTotalBeforeDiscount {
  cost: number;
  sale: number;
}

export interface ReTotalDiscount {
  cost: number;
  sale: number;
}

export interface NonTotalDiscount {
  cost: number;
  sale: number;
}

export interface IncTotalDiscount {
  cost: number;
  sale: number;
}

export interface ReTotalAfterDiscount {
  cost: number;
  sale: number;
}

export interface NonTotalAfterDiscount {
  cost: number;
  sale: number;
}

export interface IncTotalAfterDiscount {
  cost: number;
  sale: number;
}

export interface TotalVat2 {
  cost: number;
  sale: number;
}

export interface ReTotal {
  cost: number;
  sale: number;
}

export interface IncTotal {
  cost: number;
  sale: number;
}

export interface TotalBeforeDiscount2 {
  cost: number;
  sale: number;
}

export interface TotalDiscount {
  cost: number;
  sale: number;
}

export interface TotalAfterDiscount {
  cost: number;
  sale: number;
}

export interface TotalPrice2 {
  cost: number;
  sale: number;
}

export interface GrandTotalPrice2 {
  cost: number;
  sale: number;
}

export interface ShippingPrice {
  cost: number;
  sale: number;
}

export interface ServicePrice {
  cost: number;
  sale: number;
}

export interface ReservePrice {
  cost: number;
  sale: number;
}

export interface GroupPrice {
  shipping_price: ShippingPrice;
  service_price: ServicePrice;
  reserve_price: ReservePrice;
}

export interface SendHistory {
  timestamp: string;
}

type EnumLiteralsOf<T extends unknown> = T[keyof T];
export const CostingsStatus = Object.freeze({
  CHECKING: 1 as const,
  CHECKED: 2 as const,
  REJECT: 3 as const,
  EDIT: 4 as const,
});

export type CostingsStatus = EnumLiteralsOf<typeof CostingsStatus>;

export interface CostingsList {
  _id: string;
  costing_number: string;
  costing_status: CostingsStatus;
  service_type: string;
  system_request: string;
  number_ref: string;
  ref_id: string;
  employee: Employee;
  payee: Payee;
  payer: Payer;
  service_date: string;
  completed_date: string;
  payment: Payment;
  invoice: Invoice;
  costing_list: CostingList[];
  re_total_before_discount: ReTotalBeforeDiscount;
  non_total: NonTotal;
  inc_total_before_discount: IncTotalBeforeDiscount;
  re_total_discount: ReTotalDiscount;
  non_total_discount: NonTotalDiscount;
  inc_total_discount: IncTotalDiscount;
  re_total_after_discount: ReTotalAfterDiscount;
  non_total_after_discount: NonTotalAfterDiscount;
  inc_total_after_discount: IncTotalAfterDiscount;
  total_vat: TotalVat2;
  re_total: ReTotal;
  inc_total: IncTotal;
  total_before_discount: TotalBeforeDiscount2;
  total_discount: TotalDiscount;
  total_after_discount: TotalAfterDiscount;
  total_price: TotalPrice2;
  profit: number;
  net_profit: number;
  grand_total_price: GrandTotalPrice2;
  group_price: GroupPrice;
  edit_status: number;
  revenue: string;
  net_revenue: string;
  costing_remark?: string;
  reject_remark: string;
  send_history: SendHistory[];
  last_send: string;
  checked_date?: string;
  create_date: string;
  last_update: string;
}

export interface Costings {
  costings: CostingsList[];
  totalDocument: number;
}

export interface CostingsData {
  costings: Costings;
}

export interface CostingsVars {
  last_send?: string;
  costing_number?: string;
  costing_status?: CostingsStatus;
  number_ref?: string;
  payer?: string;
  payee?: string;
  employee?: string;
  payment?: string;
  profit?: string;
  edit_status?: number;
  revenue?: string;
  net_revenue?: string;
  invoice?: string;
  last_update?: string;
  order_by?: string;
  order_type?: string;
  page?: number;
  showData?: number;
}

export default gql`
  query Costings(
    $last_send: String
    $costing_number: String
    $costing_status: Int
    $number_ref: String
    $payer: ID
    $payee: ID
    $employee: ID
    $payment: ID
    $profit: String
    $edit_status: Int
    $revenue: String
    $net_revenue: String
    $invoice: ID
    $last_update: String
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
  ) {
    costings(
      params: {
        last_send: $last_send
        costing_number: $costing_number
        costing_status: $costing_status
        number_ref: $number_ref
        payer: $payer
        payee: $payee
        employee: $employee
        payment: $payment
        profit: $profit
        edit_status: $edit_status
        revenue: $revenue
        net_revenue: $net_revenue
        invoice: $invoice
        last_update: $last_update
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      costings {
        _id
        costing_number
        costing_status
        service_type
        system_request
        number_ref
        ref_id
        employee {
          _id
          department
          title
          first_name
          last_name
          full_name
          email
          phone
          create_date
        }
        payee {
          _id
          number
          external_system_id
          title
          first_name
          last_name
          full_name
          email
          phone
          tax_id
          authorized_capital
          branch
          address
          sub_district
          district
          province
          postcode
          full_address
          address_location {
            lat
            lng
          }
          bank_account {
            bank_name
            bank_branch
            account_name
            account_number
          }
          create_date
          last_update
        }
        payer {
          _id
          from
          external_system_id
          number
          status
          first_name
          last_name
          full_name
          email
          phone
          tax_id
          authorized_capital
          branch
          address
          sub_district
          district
          province
          postcode
          full_address
          address_location {
            lat
            lng
          }
          create_date
          last_update
        }
        service_date
        completed_date
        payment {
          _id
          payment_number
        }
        invoice {
          _id
          invoice_number
        }
        costing_list {
          datamaster_service_item_id
          service_category_name
          advance_type
          list_number
          list_name
          qty
          unit
          price {
            cost
            sale
          }
          discount {
            cost
            sale
          }
          vat {
            cost
            sale
          }
          total_vat {
            cost
            sale
          }
          wht {
            cost
            sale
          }
          total_vat {
            cost
            sale
          }
          total_before_discount {
            cost
            sale
          }
          total_price {
            cost
            sale
          }
          grand_total_price {
            cost
            sale
          }
        }
        re_total_before_discount {
          cost
          sale
        }
        non_total {
          cost
          sale
        }
        inc_total_before_discount {
          cost
          sale
        }
        re_total_discount {
          cost
          sale
        }
        non_total_discount {
          cost
          sale
        }
        inc_total_discount {
          cost
          sale
        }
        re_total_after_discount {
          cost
          sale
        }
        non_total_after_discount {
          cost
          sale
        }
        inc_total_after_discount {
          cost
          sale
        }
        total_vat {
          cost
          sale
        }
        re_total {
          cost
          sale
        }
        non_total {
          cost
          sale
        }
        inc_total {
          cost
          sale
        }
        total_before_discount {
          cost
          sale
        }
        total_discount {
          cost
          sale
        }
        total_after_discount {
          cost
          sale
        }
        total_price {
          cost
          sale
        }
        profit
        net_profit
        grand_total_price {
          cost
          sale
        }
        group_price {
          shipping_price {
            cost
            sale
          }
          service_price {
            cost
            sale
          }
          reserve_price {
            cost
            sale
          }
        }
        edit_status
        revenue
        net_revenue
        costing_remark
        reject_remark
        send_history {
          timestamp
        }
        last_send
        checked_date
        create_date
        last_update
      }
      totalDocument
    }
  }
`;
