/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import { message } from 'antd';
import { Spin } from 'shared/components';
import { useQuery } from '@apollo/react-hooks';
import PARCEL_COST_QUERY, { ParcelCostData, ParcelCostVars } from 'shared/graphql/query/parcelCost';
import { ParcelCostingContext } from 'views/Costing/common/context/ParcelCostingContext';
import ServiceTable from './ServiceTable';
import TotalPriceTable from './TotalPriceTable';

const ParcelCostingTable: React.FC = () => {
  const { selectedCostingDetail } = useContext(ParcelCostingContext);
  const { costingId } = selectedCostingDetail!;

  const { data } = useQuery<ParcelCostData, ParcelCostVars>(PARCEL_COST_QUERY, {
    variables: {
      _id: costingId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = <Spin />;
  if (data && data.parcelCost) {
    content = (
      <div style={{ overflowX: 'auto' }}>
        <ServiceTable parcelCostData={data} />
        <TotalPriceTable parcelCostData={data} />
      </div>
    );
  }

  return content;
};

export default ParcelCostingTable;
