import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  top: 0px;
  padding: 20px;

  &.custom-modal.ant-modal {
    width: 100% !important;
    max-width: 1400px;
  }

  & .ant-modal-body {
    padding: 0px 20px 20px;
  }

  & .ant-modal-body p {
    margin: 0;
  }
`;

export default StyledModal;
