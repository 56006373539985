/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useState, useEffect } from 'react';
import { ModalTopButton, Text, TabPane, FileList } from 'shared/components';
import { CheckCircleOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { Row, Col, Divider, Skeleton, Spin, Popover } from 'antd';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { color } from 'shared/utils/styles';
import { formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import TagCodStatus from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/components/TagCodStatus';
import { CodParcelStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codParcelStatus.model';
import {
  CodContext,
  SelectedCodDetailType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/context/CodContext';
import { useQuery } from '@apollo/react-hooks';
import COD_PARCEL_QUERY, {
  CodParcelData,
  CodParcelVars,
  ParcelOrder,
} from 'shared/graphql/query/codParcel';
import CardIcon from './CardIcon';
import ModalConfirmChecked from './ModalConfirmChecked';
import ModalConfirmReject from './ModalConfirmReject';

interface ModalCodDetailProps {
  codId: string;
  goBack: string;
}

const ModalCodDetailWrapper: React.FC<ModalCodDetailProps> = (props) => {
  const { codId, goBack } = props;
  const [isOpenModalConfirmChecked, setOpenModalConfirmChecked] = useState<boolean>(false);
  const [isOpenModalConfirmReject, setOpenModalConfirmReject] = useState<boolean>(false);
  const { selectedCodDetail } = useContext(CodContext);
  const { codStatus, codNumber } = selectedCodDetail;
  const history = useHistory();

  return (
    <ModalTopButton
      visible
      onCancel={() => {
        history.push(goBack);
      }}
      title={`รหัสCOD-IN: ${codNumber}`}
      width={1200}
      btnHeader={[
        {
          text: 'ตรวจสอบแล้ว',
          clicked: () => setOpenModalConfirmChecked(true),
          color: 'green',
          icon: <CheckCircleOutlined />,
          visible: codStatus === CodParcelStatus.PENDING,
        },
        {
          text: 'ปฏิเสธ',
          clicked: () => setOpenModalConfirmReject(true),
          color: 'red--light',
          visible: codStatus === CodParcelStatus.PENDING,
        },
      ]}
    >
      <ModalCodDetail codId={codId} goBack={goBack} />
      <ModalConfirmChecked
        visible={isOpenModalConfirmChecked}
        onCancel={() => setOpenModalConfirmChecked(false)}
        goBack={goBack}
      />
      <ModalConfirmReject
        visible={isOpenModalConfirmReject}
        onCancel={() => setOpenModalConfirmReject(false)}
        goBack={goBack}
      />
    </ModalTopButton>
  );
};

const ModalCodDetail: React.FC<ModalCodDetailProps> = (props) => {
  const { codId } = props;
  const { setSelectedCodDetail } = useContext(CodContext);
  const { loading, error, data } = useQuery<CodParcelData, CodParcelVars>(COD_PARCEL_QUERY, {
    variables: {
      _id: codId,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && data?.codParcel) {
      const { total_cod, vendor, parcel_orders, cod_in_date } = data.codParcel;
      setSelectedCodDetail(
        (prevState): SelectedCodDetailType => ({
          ...prevState,
          modalConfirm: {
            totalCod: total_cod,
            vendorFullName: vendor?.full_name,
            shipmentNumbers: parcel_orders?.map((item) => item.shipment_number),
            codInDate: cod_in_date,
          },
        }),
      );
    }
  }, [data?.codParcel, error, loading, setSelectedCodDetail]);

  const contentPopOver = (value: string[]) => {
    return (
      <>
        <div style={{ maxHeight: 200, overflow: 'auto' }}>
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            <Row style={{ fontSize: 16 }}>
              <Col>
                <Text strong>Shipment No: </Text>
              </Col>
            </Row>
            {value.map((item) => (
              <Row key={uuidv4()} style={{ fontSize: 16 }}>
                <Col>
                  <Text>{item}</Text>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </>
    );
  };

  const popOver = (value: string[]) => {
    if (value.length === 1) {
      return <Text>{value[0]}</Text>;
    }
    if (value.length > 1) {
      return (
        <Popover placement="rightTop" content={contentPopOver(value)}>
          <div style={{ display: 'inline' }}>
            <Text customType="primary">{value.length} รหัส</Text>
          </div>
        </Popover>
      );
    }
    return '-';
  };

  let content = <Skeleton />;

  if (data?.codParcel) {
    const {
      total_cod,
      cod_status,
      vendor,
      cod_in_date,
      document,
      parcel_orders,
      paid_qr_detail,
      reject_remark,
    } = data.codParcel;

    const parcelOrdersGroupByCustomer = _.chain(parcel_orders)
      .groupBy((groupByItem) => groupByItem.customer._id)
      .map((value, key) => {
        return {
          customerId: key,
          customerFullName: value[0].customer.full_name,
          value,
        };
      })
      .value();

    const sumCodPriceCustomer = (value: ParcelOrder[]) => {
      const reduceCodPrice = value
        .map((item) => item.cod_price)
        .reduce((total, item) => total + item);
      return formatCurrency(reduceCodPrice);
    };

    content = (
      <>
        <Row gutter={[32, 16]}>
          <Col
            span={12}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CardIcon
              title="จำนวนที่ 3PL ต้องโอน (บาท)"
              text={<Text customType="primary">{`${formatCurrency(total_cod)} บาท`}</Text>}
              icon={<DollarCircleOutlined />}
            />
          </Col>
          {cod_status === CodParcelStatus.PENDING && (
            <Col
              span={12}
              style={{
                borderLeft: `1px dashed ${color.grey}`,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Row>
                <Col span={24}>สถานะ</Col>
                <Col span={24}>{cod_status && <TagCodStatus status={cod_status} />}</Col>
              </Row>
            </Col>
          )}
        </Row>

        <Divider style={{ marginBottom: 10, marginTop: -5 }} />

        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Row>
              <Col span={8}>ชื่อ 3PL:</Col>
              <Col span={16}>
                <Text strong>{vendor?.full_name || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Shipment Number:</Col>
              <Col span={16}>
                <Text strong>
                  {popOver(_.uniq(parcel_orders.map((item) => item.shipment_number)))}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>วันที่แจ้งโอน:</Col>
              <Col span={16}>
                <Text strong>{cod_in_date ? formatDateTime(cod_in_date) : '-'}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={8}>Reference 1:</Col>
              <Col span={16}>
                <Text strong>{paid_qr_detail.qr_ref1 || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Reference 2:</Col>
              <Col span={16}>
                <Text strong>{paid_qr_detail.qr_ref2 || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>ชื่อผู้โอน:</Col>
              <Col span={16}>
                <Text strong>{paid_qr_detail.qr_from_name || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>BBL Bank Ref:</Col>
              <Col span={16}>
                <Text strong>{paid_qr_detail.qr_bank_ref || '-'}</Text>
              </Col>
            </Row>
            {cod_status === CodParcelStatus.REJECT && (
              <Row>
                <Col span={8}>เหตุผล:</Col>
                <Col span={16}>
                  <Text strong>{reject_remark || '-'}</Text>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Divider style={{ marginBottom: 0, marginTop: 10 }} />

        <TabPane
          tabs={[
            {
              key: '1',
              text: 'หลักฐานการโอน',
              isShowIcon: true,
              component: (
                <>
                  {document.length === 0 ? (
                    '-'
                  ) : (
                    <div>
                      <FileList files={document} />
                    </div>
                  )}
                </>
              ),
            },
            {
              key: '2',
              text: 'รายละเอียดงาน',
              isShowIcon: true,
              component: (
                <div style={{ maxHeight: 200, overflow: 'auto' }}>
                  <div style={{ marginLeft: 10, marginRight: 10 }}>
                    <Row gutter={[16, 0]}>
                      <Col span={6} style={{ fontWeight: 'bold' }}>
                        ชื่อลูกค้า
                      </Col>
                      <Col span={18}>
                        <Row>
                          <Col span={9} style={{ fontWeight: 'bold' }}>
                            Shipment / Trip Number
                          </Col>
                          <Col span={6} style={{ fontWeight: 'bold' }}>
                            Delivered Date
                          </Col>
                          <Col span={6} style={{ fontWeight: 'bold' }}>
                            Tracking Number
                          </Col>
                          <Col span={3} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                            มูลค่า COD
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {parcelOrdersGroupByCustomer.length > 0 &&
                      parcelOrdersGroupByCustomer.map((item) => (
                        <>
                          <Row gutter={[16, 0]}>
                            <Col span={6}>{item.customerFullName || '-'}</Col>
                            <Col span={18}>
                              {item.value.map((itemValue) => (
                                <>
                                  <Row>
                                    <Col span={9}>{itemValue.shipment_number || '-'}</Col>
                                    <Col span={6}>
                                      {formatDateTime(itemValue.delivered_date) || '-'}
                                    </Col>
                                    <Col span={6}>{itemValue.tracking_number || '-'}</Col>
                                    <Col span={3} style={{ textAlign: 'right' }}>
                                      {formatCurrency(itemValue.cod_price) || '-'}
                                    </Col>
                                  </Row>
                                </>
                              ))}
                            </Col>
                          </Row>
                          <Row gutter={[16, 0]} justify="end">
                            <Col span={18}>
                              <Row justify="end">
                                <Col span={9}>
                                  <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row gutter={[16, 0]} justify="end">
                            <Col span={18}>
                              <Row align="middle" justify="end">
                                <Col span={6}>
                                  <Text strong>รวมมูลค่า COD</Text>
                                </Col>
                                <Col span={3} style={{ textAlign: 'right' }}>
                                  <Text strong customType="success">
                                    {sumCodPriceCustomer(item.value)}
                                  </Text>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          {item.value.length !== 0 && (
                            <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                          )}
                        </>
                      ))}
                  </div>
                </div>
              ),
            },
          ]}
        />
      </>
    );
  }

  if (error) {
    content = <div>{error.message}</div>;
  }

  return <Spin spinning={loading}>{content}</Spin>;
};

export default ModalCodDetailWrapper;
