import gql from 'graphql-tag';

export interface CreateCodOutParcelData {
  createCodOutParcel: {
    _id: string;
  };
}

export interface CreateCodOutParcelVars {
  parcel_orders: string[];
  cod_out_remark?: string;
}

export default gql`
  mutation CreateCodOutParcel($parcel_orders: [ID], $cod_out_remark: String) {
    createCodOutParcel(
      createCodOutParcel: { parcel_orders: $parcel_orders, cod_out_remark: $cod_out_remark }
    ) {
      _id
    }
  }
`;
