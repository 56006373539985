/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode } from 'react';
import { Select, Empty, message } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import CUSTOMERS_QUERY, { CustomersData, CustomersVars } from 'shared/graphql/query/customers';

const { Option } = Select;

type SelectFieldSearchCustomersProps = {
  value?: {
    key: string;
    label: string;
  };
  disabled?: boolean;
  onChange?: (params: { key: string; value: string; label: string }) => void;
  onBlur?: () => void;
};

const SelectFieldSearchCustomers: React.FC<SelectFieldSearchCustomersProps> = (props) => {
  const { value, disabled, onChange, onBlur } = props;
  const [fullName, setFullName] = useState<string>('');
  const { loading, data } = useQuery<CustomersData, CustomersVars>(CUSTOMERS_QUERY, {
    variables: {
      full_name: fullName,
      showData: 10,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  let options: ReactNode[] = [];
  if (data && data.customers) {
    const { customers } = data.customers;
    options = customers.map((customer) => (
      <Option key={customer._id} value={customer._id}>
        {customer.full_name}
      </Option>
    ));
  }

  return (
    <Select
      style={{
        width: '100%',
      }}
      showSearch
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      placeholder="ค้นหาลูกค้า"
      onSearch={(valueSearch) => setFullName(valueSearch)}
      onChange={(valueChange) => {
        if (onChange) {
          onChange(valueChange);
          setFullName('');
        }
      }}
      onBlur={onBlur}
      optionFilterProp="children"
      filterOption={false}
      labelInValue
      value={
        value && {
          key: value.key,
          value: value.key,
          label: value.label,
        }
      }
      disabled={disabled}
    >
      {options}
    </Select>
  );
};

export default SelectFieldSearchCustomers;
