import React, { useState, useContext } from 'react';
import { Row, Col, message } from 'antd';
import {
  CheckCircleOutlined,
  StopOutlined,
  CloseCircleOutlined,
  SaveOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  PaymentOrderContext,
  SelectedPaymentDetailType,
} from 'views/AccountPayment/common/context/PaymentOrderContext';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalDetailPaymentOrder/model/formValues.model';
import CHECK_VENDOR_INVOICE_ID_QUERY, {
  CheckVendorInvoiceIDData,
  CheckVendorInvoiceIDVars,
} from 'shared/graphql/query/checkVendorInvoiceID';
import UPDATE_PAYMENT_MUTATION, {
  UpdatePaymentData,
  UpdatePaymentVars,
} from 'shared/graphql/mutation/updatePayment';
import { PaymentStatus } from 'views/AccountPayment/common/model/paymentStatus.model';
import useVatCondition from 'views/AccountPayment/Routes/PaymentOrder/ModalDetailPaymentOrder/hooks/useVatCondition';
import { StyledButton, TextHeader, Container } from './Styles';
import ModalCheckVendorInvoice from './ModalCheckVendorInvoice';
import ModalConfirmCancel from './ModalConfirmCancel';
import ModalConfirmReject from './ModalConfirmReject';
import ModalConfirmPending from './ModalConfirmPending';
import ModalConfirmReturn from './ModalConfirmReturn';
import ModalConfirmCreatePaymentVoucher from './ModalConfirmCreatePaymentVoucher';

const Header: React.FC = () => {
  const { handleSubmit, watch, formState } = useFormContext<FormValues>();
  const [isOpenModalCheckVendorInvoice, setOpenModalCheckVendorInvoice] = useState<boolean>(false);
  const [isOpenModalConfirmCancel, setOpenModalConfirmCancel] = useState<boolean>(false);
  const [isOpenModalConfirmReject, setOpenModalConfirmReject] = useState<boolean>(false);
  const [isOpenModalConfirmPending, setOpenModalConfirmPending] = useState<boolean>(false);
  const [isOpenModalConfirmReturn, setOpenModalConfirmReturn] = useState<boolean>(false);
  const [
    isOpenModalConfirmCreatePaymentVoucher,
    setOpenModalConfirmCreatePaymentVoucher,
  ] = useState<boolean>(false);
  const { selectedPaymentDetail: detailPaymentOrderContext } = useContext(PaymentOrderContext);
  const [selectedPaymentDetail, setSelectedPaymentDetail] = detailPaymentOrderContext;
  const { getPaymentList } = useVatCondition();
  const watchAllField = watch();

  const { isDirty } = formState;

  const [checkVendorInvoice] = useLazyQuery<CheckVendorInvoiceIDData, CheckVendorInvoiceIDVars>(
    CHECK_VENDOR_INVOICE_ID_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (dataCompleted) => {
        if (
          dataCompleted?.checkVendorInvoiceID?._id &&
          dataCompleted?.checkVendorInvoiceID.payment_number !== watchAllField.paymentNumber
        ) {
          setOpenModalCheckVendorInvoice(true);
        }

        if (
          dataCompleted?.checkVendorInvoiceID === null ||
          (dataCompleted?.checkVendorInvoiceID?._id &&
            dataCompleted?.checkVendorInvoiceID.payment_number === watchAllField.paymentNumber)
        ) {
          updateSave();
        }
      },
    },
  );

  const [updatePayment] = useMutation<UpdatePaymentData, UpdatePaymentVars>(
    UPDATE_PAYMENT_MUTATION,
    {
      update: (_cache, { data: updatePaymentData }) => {
        if (updatePaymentData?.updatePayment._id) {
          message.success('บันทึกข้อมูลใบสั่งจ่ายแล้ว');
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['Payment', 'PaymentStatus', 'Payments'],
    },
  );

  const updateSave = () => {
    updatePayment({
      variables: {
        _id: selectedPaymentDetail.paymentId,
        payee: watchAllField.payeeId,
        payment_type: watchAllField.paymentType,
        payment_remark: watchAllField.paymentRemark,
        save_status: watchAllField.saveStatus,
        round_paid: watchAllField.roundPaidDate,
        invoice_date: watchAllField.paymentInvoiceDate,
        invoice_due_date: watchAllField.invoiceDueDate,
        vendor_invoice_id: watchAllField.vendorInvoice,
        number_ref: watchAllField.numberRef,
        payment_list: getPaymentList.map((item) => ({
          datamaster_service_item_id: item.dataMasterId,
          advance_type: item.advanceType,
          list_number: item.listNumber,
          list_name: item.listName,
          qty: item.qty,
          unit: '-',
          price: item.price,
          discount: item.discount,
          vat: item.vat,
          wht: item.wht,
        })),
        vendor: watchAllField.vendorId !== '' ? watchAllField.vendorId : null,
        department: watchAllField.paymentType !== 'COGs' ? watchAllField.department : null,
        service_date: watchAllField.serviceDate,
        fixed_asset_check: Number(watchAllField.fixedAssetCheck),
      },
    });
  };

  const closeModalHandler = () => {
    setSelectedPaymentDetail(
      (prevState): SelectedPaymentDetailType => ({
        ...prevState,
        isOpenModal: false,
      }),
    );
  };

  const onSubmitUpdatePaymentOrder = handleSubmit((values) => {
    if (values.vendorInvoice) {
      checkVendorInvoice({
        variables: {
          vendor_invoice_id: values.vendorInvoice,
        },
      });
    } else {
      updateSave();
    }
  });

  const onSubmitConfirmCancel = () => {
    setOpenModalConfirmCancel(true);
  };

  const onSubmitConfirmReject = () => {
    setOpenModalConfirmReject(true);
  };

  const onSubmitConfirmPending = handleSubmit(() => {
    setOpenModalConfirmPending(true);
  });

  const onSubmitConfirmReturn = () => {
    setOpenModalConfirmReturn(true);
  };

  const onSubmitConfirmCreatePaymentVoucher = handleSubmit(() => {
    setOpenModalConfirmCreatePaymentVoucher(true);
  });

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>ใบสั่งจ่าย: {watchAllField.paymentNumber}</TextHeader>
        </Col>

        <Col>
          {(watchAllField.paymentStatus === PaymentStatus.REVIEW ||
            watchAllField.paymentStatus === PaymentStatus.PENDING ||
            watchAllField.paymentStatus === PaymentStatus.REJECT) && (
            <StyledButton icon={<StopOutlined />} color="red" onClick={onSubmitConfirmCancel}>
              ยกเลิกการใช้
            </StyledButton>
          )}
          {(watchAllField.paymentStatus === PaymentStatus.REVIEW ||
            watchAllField.paymentStatus === PaymentStatus.PENDING) && (
            <StyledButton
              icon={<CloseCircleOutlined />}
              color="red--light"
              onClick={onSubmitConfirmReject}
            >
              ปฏิเสธ
            </StyledButton>
          )}
          {(watchAllField.paymentStatus === PaymentStatus.REVIEW ||
            watchAllField.paymentStatus === PaymentStatus.PENDING ||
            watchAllField.paymentStatus === PaymentStatus.COMPLETE) && (
            <StyledButton
              disabled={!isDirty}
              icon={<SaveOutlined />}
              color="green--light"
              onClick={onSubmitUpdatePaymentOrder}
            >
              บันทึก
            </StyledButton>
          )}
          {watchAllField.paymentStatus === PaymentStatus.REJECT && (
            <StyledButton icon={<RedoOutlined />} onClick={onSubmitConfirmReturn}>
              ใช้ใบสั่งจ่ายอีกครั้ง
            </StyledButton>
          )}
          {watchAllField.paymentStatus === PaymentStatus.REVIEW && (
            <StyledButton
              disabled={isDirty}
              icon={<CheckCircleOutlined />}
              color="green"
              onClick={onSubmitConfirmPending}
            >
              ตรวจสอบแล้ว
            </StyledButton>
          )}
          {watchAllField.paymentStatus === PaymentStatus.PENDING && (
            <StyledButton
              disabled={isDirty}
              icon={<CheckCircleOutlined />}
              color="green"
              onClick={onSubmitConfirmCreatePaymentVoucher}
            >
              สร้างใบสำคัญจ่าย
            </StyledButton>
          )}

          <ModalCheckVendorInvoice
            visible={isOpenModalCheckVendorInvoice}
            onCancel={() => setOpenModalCheckVendorInvoice(false)}
          />
          <ModalConfirmCancel
            visible={isOpenModalConfirmCancel}
            onCancel={() => setOpenModalConfirmCancel(false)}
          />
          <ModalConfirmReject
            visible={isOpenModalConfirmReject}
            onCancel={() => setOpenModalConfirmReject(false)}
          />
          <ModalConfirmPending
            visible={isOpenModalConfirmPending}
            onCancel={() => setOpenModalConfirmPending(false)}
          />
          <ModalConfirmReturn
            visible={isOpenModalConfirmReturn}
            onCancel={() => setOpenModalConfirmReturn(false)}
          />
          <ModalConfirmCreatePaymentVoucher
            visible={isOpenModalConfirmCreatePaymentVoucher}
            onCancel={() => setOpenModalConfirmCreatePaymentVoucher(false)}
          />

          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
