/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, useContext, useState } from 'react';
import { Row, Col, Input } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { DeleteOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDateTime, formatDate } from 'shared/utils/dateTime';
import { Button, Text, Spin } from 'shared/components';
import PAYMENT_COD_QUERY, {
  PaymentCodData,
  PaymentCodVars,
  CodItem,
} from 'shared/graphql/query/paymentCod';
import { v4 as uuidv4 } from 'uuid';
import { PaymentCodContext } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/PaymentCodContext';
import { ModalAddCod } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/components';
import { PaymentCodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/model/paymentCodStatus.model';
import { StyledTable } from './Styles';
import ExpandedRow from './ExpandedRow';
import ModalConfirmAddCod from './ModalConfirmAddCod';
import ModalConfirmDeleteCod from './ModalConfirmDeleteCod';

const { TextArea } = Input;

type CodDataTable = {
  key: string;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: string;
  column4: string;
  column5: string;
  column6: ReactNode;
  column7: string;
  codItems: CodItem[];
};

const columns = [
  {
    title: 'เครื่องมือ',
    dataIndex: 'tools',
    width: 70,
  },
  {
    title: 'ชื่อลูกค้า',
    dataIndex: 'column1',
    width: 200,
  },
  {
    title: 'รหัส COD-IN',
    dataIndex: 'column2',
    width: 130,
  },
  {
    title: 'วันที่รับสินค้า',
    dataIndex: 'column3',
    width: 180,
  },
  {
    title: 'วันที่ส่งสำเร็จ',
    dataIndex: 'column4',
    width: 180,
  },
  {
    title: 'Booking Number',
    dataIndex: 'column5',
    width: 180,
  },
  {
    title: 'ยอดรวม COD (บาท)',
    dataIndex: 'column6',
    align: 'right',
    width: 150,
  },
  {
    title: 'วันที่ต้องทำจ่าย',
    dataIndex: 'column7',
    width: 110,
  },
  {
    title: '',
  },
];

type SelectedCod = {
  id: string;
  customerFullName: string;
  codInNumber: string;
  pickUpDate: string;
  bookingNumber: string;
  totalCod: number;
};

const PaymentCodDetailTable: React.FC = () => {
  const [isOpenModalAddCod, setOpenModalAddCod] = useState<boolean>(false);
  const [isOpenModalConfirmAddCod, setOpenModalConfirmAddCod] = useState<boolean>(false);
  const [isOpenModalDeleteCod, setOpenModalDeleteCod] = useState<boolean>(false);
  const [selectedCodDelete, setSelectedCodDelete] = useState<SelectedCod>({
    id: '',
    customerFullName: '',
    codInNumber: '',
    pickUpDate: '',
    bookingNumber: '',
    totalCod: 0,
  });
  const [selectedCodAdd, setSelectedCodAdd] = useState<SelectedCod[]>([]);
  const [codsIdSendUpdate, setCodsIdSendUpdate] = useState<string[]>([]);
  const { selectedPaymentCodDetail } = useContext(PaymentCodContext);
  const { paymentCodId, paymentCodStatus } = selectedPaymentCodDetail;
  const { error, data, loading } = useQuery<PaymentCodData, PaymentCodVars>(PAYMENT_COD_QUERY, {
    variables: {
      _id: paymentCodId,
    },
    fetchPolicy: 'cache-and-network',
  });

  let content: ReactNode = <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  let codData: CodDataTable[] = [];
  if (data?.paymentCod) {
    const { cod, customer, payment_cod_remark } = data.paymentCod;

    codData = cod.map(
      (item): CodDataTable => {
        const {
          _id,
          customer: customerCod,
          cod_in_number,
          pick_up_date,
          delivered_date,
          booking_number,
          total_cod,
          due_date,
        } = item;

        return {
          key: uuidv4(),
          tools: (
            <Button
              disabled={cod.length < 2 || paymentCodStatus !== PaymentCodStatus.WAITING}
              title="ลบ"
              color="red--outline"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => {
                setCodsIdSendUpdate(
                  cod.map((codItem) => codItem._id).filter((codId) => codId !== _id),
                );
                setSelectedCodDelete({
                  id: _id,
                  customerFullName: customerCod.full_name,
                  codInNumber: cod_in_number,
                  pickUpDate: pick_up_date,
                  bookingNumber: booking_number,
                  totalCod: total_cod,
                });
                setOpenModalDeleteCod(true);
              }}
            />
          ),
          column1: customerCod.full_name,
          column2: cod_in_number,
          column3: pick_up_date ? formatDateTime(pick_up_date) : '-',
          column4: delivered_date ? formatDateTime(delivered_date) : '-',
          column5: booking_number || '-',
          column6: (
            <Text customType="primary" strong>
              {formatCurrency(total_cod, '-')}
            </Text>
          ),
          column7: due_date ? formatDate(due_date) : '',
          codItems: item.cod_item,
        };
      },
    );

    content = (
      <>
        <StyledTable
          pagination={false}
          columns={columns}
          dataSource={codData}
          size="small"
          loading={loading}
          expandable={{
            expandedRowRender: (record: { codItems: CodItem[] }) => (
              <ExpandedRow data={record.codItems} />
            ),
          }}
        />

        <ModalConfirmDeleteCod
          paymentCodId={paymentCodId}
          cod={selectedCodDelete}
          codsId={codsIdSendUpdate}
          visible={isOpenModalDeleteCod}
          onCancel={() => setOpenModalDeleteCod(false)}
        />

        <Row justify="space-between" style={{ marginTop: 10 }}>
          <Col>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button
                  disabled={paymentCodStatus !== PaymentCodStatus.WAITING}
                  color="blue--light"
                  icon={<PlusCircleOutlined />}
                  onClick={() => setOpenModalAddCod(true)}
                >
                  เพิ่มรายการ
                </Button>
              </Col>
              <Col span={24}>
                หมายเหตุ <Text customType="grey">(ไม่บังคับ)</Text>
                <TextArea value={payment_cod_remark} placeholder="ระบุ" rows={4} disabled />
              </Col>
            </Row>
          </Col>
        </Row>

        <ModalAddCod
          visible={isOpenModalAddCod}
          onOk={(valueCods) => {
            const oldCods: SelectedCod[] = cod.map((codItem) => ({
              id: codItem._id,
              customerFullName: codItem.customer.full_name,
              codInNumber: codItem.cod_in_number,
              pickUpDate: codItem.pick_up_date,
              bookingNumber: codItem.booking_number,
              totalCod: codItem.total_cod,
            }));

            const newCods = valueCods.map((codItem) => ({
              id: codItem.id,
              customerFullName: codItem.customerFullName,
              codInNumber: codItem.codNumber,
              pickUpDate: codItem.pickUpDate,
              bookingNumber: codItem.bookingNumber,
              totalCod: codItem.totalCod,
            }));

            setSelectedCodAdd([...oldCods, ...newCods]);
            setOpenModalAddCod(false);
            setOpenModalConfirmAddCod(true);
          }}
          onCancel={() => setOpenModalAddCod(false)}
          customerId={customer._id}
        />

        <ModalConfirmAddCod
          paymentCodId={paymentCodId}
          cods={selectedCodAdd}
          visible={isOpenModalConfirmAddCod}
          onCancel={() => setOpenModalConfirmAddCod(false)}
        />
      </>
    );
  }

  if (error) {
    content = error.message;
  }

  return <div>{content}</div>;
};

export default PaymentCodDetailTable;
