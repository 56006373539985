/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode } from 'react';
import CHART_OF_ACCOUNTS_QUERY, {
  ChartOfAccountsData,
  ChartOfAccountsVars,
} from 'shared/graphql/query/chartOfAccounts';
import { useQuery } from '@apollo/react-hooks';
import { Input, message, Row, Col, Radio, Select } from 'antd';
import { Text, TableSearch, SelectChartOfAccountField } from 'shared/components';
import { StyledModal, StyledRadio, ToolsContainer } from './Styles';
import Header from './Header';

const { Option } = Select;

type ChartOfAccountItem = {
  mode: string;
  chartOfAccountId: string;
  code: string;
  accountName: string;
};

type OnCancelFunc = () => void;
type OnOkFunc = (value: ChartOfAccountItem) => void;

type ModalAddItemWrapperProps = {
  visible: boolean;
  onCancel: OnCancelFunc;
  onOk: OnOkFunc;
};

type ModalAddItemProps = {
  onCancel: OnCancelFunc;
  onOk: OnOkFunc;
};

type ChartOfAccountDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: string;
};

const ModalAddItemWrapper: React.FC<ModalAddItemWrapperProps> = (props) => {
  const { visible, onCancel, onOk } = props;
  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalAddItem onCancel={onCancel} onOk={onOk} />
    </StyledModal>
  );
};

const ModalAddItem: React.FC<ModalAddItemProps> = (props) => {
  const { onCancel, onOk } = props;
  const [selectedItem, setSelectedItem] = useState<ChartOfAccountItem>();
  const [mode, setMode] = useState<string>('60d99b3cc7c47b969218180b');
  const [code, setCode] = useState<string>('');
  const [accountNameId, setAccountNameId] = useState<string>('');
  const [typeAccount, setTypeAccount] = useState<string>();
  const [pageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { loading, data } = useQuery<ChartOfAccountsData, ChartOfAccountsVars>(
    CHART_OF_ACCOUNTS_QUERY,
    {
      variables: {
        _id: accountNameId,
        mode,
        code,
        type: typeof typeAccount === 'string' ? +typeAccount : undefined,
        page: currentPage,
        showData: pageSize,
      },
      onError: (err) => {
        message.error(err.message);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'รหัสบัญชี',
      dataIndex: 'column1',
      width: 150,
      search: (
        <Input
          value={code}
          placeholder="ค้นหา"
          onChange={(event) => setCode(event.target.value)}
          allowClear
        />
      ),
    },
    {
      title: 'ชื่อบัญชี',
      dataIndex: 'column2',
      width: 350,
      search: (
        <SelectChartOfAccountField
          onChange={(value: string) => {
            setAccountNameId(value);
          }}
          mode={mode}
        />
      ),
    },
    {
      title: 'ประเภทบัญชี',
      dataIndex: 'column3',
      width: 200,
      search: (
        <Select
          allowClear
          value={typeAccount || undefined}
          style={{ width: 120 }}
          placeholder="ค้นหา"
          onChange={(value) => {
            setTypeAccount(value);
          }}
        >
          <Option value="1">บัญชีคุม</Option>
          <Option value="2">บัญชีย่อย</Option>
        </Select>
      ),
    },
  ];

  let chartOfAccountDataTable: ChartOfAccountDataTable[] = [];
  let totalDocument = 0;

  if (data?.chartOfAccounts) {
    const { chartOfAccounts } = data.chartOfAccounts;
    chartOfAccountDataTable = chartOfAccounts.map(
      (item, index): ChartOfAccountDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <StyledRadio
              value={item}
              onChange={() => {
                setSelectedItem({
                  mode: item.mode,
                  chartOfAccountId: item._id,
                  code: item.code,
                  accountName: item.account_name,
                });
              }}
            />
          </ToolsContainer>
        ),
        column1: item.code,
        column2: item.account_name,
        column3: item.type === 1 ? 'บัญชีคุม' : 'บัญชีย่อย',
      }),
    );

    totalDocument = data.chartOfAccounts.totalDocument;
  }

  return (
    <div>
      <Header
        onSubmit={() => {
          if (selectedItem) {
            onOk(selectedItem);
          }
        }}
        onCancel={onCancel}
        btnDisabled={!selectedItem}
      />

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[16, 0]}>
            <Col>
              <Text strong>หมวดบัญชี</Text>
            </Col>
            <Col span={16}>
              <Select
                defaultValue="60d99b3cc7c47b969218180b"
                value={mode}
                style={{ width: 120 }}
                onChange={(value) => {
                  setMode(value);
                  setCurrentPage(1);
                }}
              >
                <Option value="60d99b3cc7c47b969218180b">สินทรัพย์</Option>
                <Option value="60d99bb731c8b696af010329">หนี้สิน</Option>
                <Option value="60d99bdc4e963196c1cb8b2b">ส่วนของผู้ถือหุ้น</Option>
                <Option value="60d99c0b90b04a96e7b25ffb">รายได้</Option>
                <Option value="60d99d4ddce21a972b14ab7c">ค่าใช้จ่าย</Option>
              </Select>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Radio.Group style={{ width: '100%' }}>
            <TableSearch
              columns={columns}
              dataSource={chartOfAccountDataTable}
              loading={loading}
              pagination={{
                current: currentPage,
                pageSize,
                total: totalDocument,
                showSizeChanger: false,
                onChange: (value: number) => setCurrentPage(value),
              }}
            />
          </Radio.Group>
        </Col>
      </Row>
    </div>
  );
};

export default ModalAddItemWrapper;
