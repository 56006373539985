import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountReceive/Routes/Receipt/BillingNotesTable/ModalCreateReceipt/model/formValues.model';

const useSumTotalPrice = () => {
  const { getValues } = useFormContext<FormValues>();
  const allFormValue = getValues();

  const sumTotalPriceLeft = (): number => {
    const { receiptPrice, receiptDiscount, bankFee, otherExpenses, allWht } = allFormValue;

    const sumAllWht = !allWht
      ? 0
      : allWht
          .map((item) => item.totalWht || 0)
          .reduce((total, item) => {
            return total + +item;
          }, 0);

    return +[receiptPrice || 0, receiptDiscount || 0, bankFee || 0, otherExpenses || 0, sumAllWht]
      .reduce((total, item) => {
        return total + +item;
      }, 0)
      .toFixed(2);
  };

  const sumTotalPriceRight = (): number => {
    const { otherIncome, interestIncome, totalPaid } = allFormValue;
    return +[totalPaid || 0, otherIncome || 0, interestIncome || 0]
      .reduce((total, item) => {
        return total + +item;
      }, 0)
      .toFixed(2);
  };

  return {
    totalPriceLeft: sumTotalPriceLeft(),
    totalPriceRight: sumTotalPriceRight(),
  };
};

export default useSumTotalPrice;
