import { css } from 'styled-components';
import Color from 'color';

import {
  PaymentOrderStatusType,
  PaymentVoucherStatusType,
  InvoiceStatusType,
  BillingNoteStatusType,
  ReceiptStatusType,
  SaveStatusType,
  CostingStatusType,
  ParcelCostingStatusType,
} from 'shared/constants/status';

export const color = {
  primary: '#3377b7', // Blue
  success: '#5DBA5B', // green
  danger: '#D90101', // red
  white: '#FFFFFF',
  grey: '#e3e3e3',
  black: '#000000',
  backgroundPrimary: '#3377b7',
  backgroundLightPrimary: '#D0E8FF',
  backgroundSuccess: '#5DBA5B',
  backgroundLightSuccess: '#E5FFE5',
  backgroundDanger: '#D90101',
  backgroundLightDanger: '#FFD9D9',
  backgroundGrey: '#e3e3e3',
  backgroundBlack: '#000000',
  border: '#F0F0F0',
};

export const paymentOrderStatusColor = {
  [PaymentOrderStatusType.REVIEW]: {
    backgroundColor: color.backgroundGrey,
    color: color.black,
  },
  [PaymentOrderStatusType.WAIT]: {
    backgroundColor: color.backgroundBlack,
    color: color.white,
  },
  [PaymentOrderStatusType.COMPLETE]: {
    backgroundColor: color.backgroundLightPrimary,
    color: color.primary,
  },
  [PaymentOrderStatusType.REJECT]: {
    backgroundColor: color.backgroundLightDanger,
    color: color.danger,
  },
  [PaymentOrderStatusType.CANCEL]: {
    backgroundColor: color.backgroundDanger,
    color: color.white,
  },
  [PaymentOrderStatusType.PAID]: {
    backgroundColor: color.backgroundSuccess,
    color: color.white,
  },
  [PaymentOrderStatusType.EDIT]: {
    backgroundColor: color.backgroundLightDanger,
    color: color.danger,
  },
};

export const paymentVoucherStatusColor = {
  [PaymentVoucherStatusType.RECHECK]: {
    backgroundColor: color.backgroundLightPrimary,
    color: color.primary,
  },
  [PaymentVoucherStatusType.PENDING_APPROVAL]: {
    backgroundColor: color.backgroundPrimary,
    color: color.white,
  },
  [PaymentVoucherStatusType.WAITING_PAYMENT_RECORD]: {
    backgroundColor: color.backgroundLightSuccess,
    color: color.success,
  },
  [PaymentVoucherStatusType.SUCCESSFUL_PAYMENT]: {
    backgroundColor: color.backgroundSuccess,
    color: color.white,
  },
  [PaymentVoucherStatusType.REJECT]: {
    backgroundColor: color.backgroundLightDanger,
    color: color.danger,
  },
  [PaymentVoucherStatusType.CANCEL]: {
    backgroundColor: color.backgroundDanger,
    color: color.white,
  },
  [PaymentVoucherStatusType.EDIT]: {
    backgroundColor: color.backgroundLightDanger,
    color: color.danger,
  },
};

export const invoiceStatusColor = {
  [InvoiceStatusType.DRAFT]: {
    backgroundColor: color.backgroundGrey,
    color: color.black,
  },
  [InvoiceStatusType.WAITING]: {
    backgroundColor: color.backgroundLightPrimary,
    color: color.primary,
  },
  [InvoiceStatusType.BILLING_NOTE]: {
    backgroundColor: color.backgroundPrimary,
    color: color.white,
  },
  [InvoiceStatusType.REJECT]: {
    backgroundColor: color.backgroundLightDanger,
    color: color.danger,
  },
  [InvoiceStatusType.CANCEL]: {
    backgroundColor: color.backgroundDanger,
    color: color.white,
  },
};

export const billingNoteStatusColor = {
  [BillingNoteStatusType.BILLING_NOTE]: {
    backgroundColor: color.backgroundGrey,
    color: color.black,
  },
  [BillingNoteStatusType.WAITING]: {
    backgroundColor: color.backgroundLightSuccess,
    color: color.success,
  },
  [BillingNoteStatusType.COMPLETE]: {
    backgroundColor: color.backgroundSuccess,
    color: color.white,
  },
  [BillingNoteStatusType.REJECT]: {
    backgroundColor: color.backgroundLightDanger,
    color: color.danger,
  },
  [BillingNoteStatusType.CANCEL]: {
    backgroundColor: color.backgroundDanger,
    color: color.white,
  },
};

export const receiptStatusColor = {
  [ReceiptStatusType.COMPLETE]: {
    backgroundColor: color.backgroundSuccess,
    color: color.white,
  },
  [ReceiptStatusType.CANCEL]: {
    backgroundColor: color.backgroundDanger,
    color: color.white,
  },
};

export const saveStatusColor = {
  [SaveStatusType.WAITING]: {
    backgroundColor: color.backgroundGrey,
    color: color.black,
  },
  [SaveStatusType.SAVED]: {
    backgroundColor: color.backgroundSuccess,
    color: color.white,
  },
};

export const costingStatusColor = {
  [CostingStatusType.CHECKING]: {
    backgroundColor: color.backgroundLightPrimary,
    color: color.primary,
  },
  [CostingStatusType.CHECKED]: {
    backgroundColor: color.backgroundSuccess,
    color: color.white,
  },
  [CostingStatusType.REJECT]: {
    backgroundColor: color.backgroundLightDanger,
    color: color.danger,
  },
  [CostingStatusType.EDIT]: {
    backgroundColor: color.backgroundLightDanger,
    color: color.danger,
  },
};

export const parcelCostingStatusColor = {
  [ParcelCostingStatusType.WAIT_AGENT_CONFIRM]: {
    backgroundColor: color.backgroundLightPrimary,
    color: color.primary,
  },
  [ParcelCostingStatusType.WAITING_CONFIRM]: {
    backgroundColor: color.backgroundLightPrimary,
    color: color.primary,
  },
  [ParcelCostingStatusType.CONFIRM]: {
    backgroundColor: color.backgroundSuccess,
    color: color.white,
  },
  [ParcelCostingStatusType.REJECT]: {
    backgroundColor: color.backgroundLightDanger,
    color: color.danger,
  },
  [ParcelCostingStatusType.EDIT]: {
    backgroundColor: color.backgroundLightDanger,
    color: color.danger,
  },
  [ParcelCostingStatusType.CANCEL]: {
    backgroundColor: color.backgroundLightDanger,
    color: color.danger,
  },
};

export const font = {
  regular: 'font-family: "DBHeaventRegular"; font-weight: normal;',
  medium: 'font-family: "DBHeaventMedium"; font-weight: normal;',
  bold: 'font-family: "DBHeaventBold"; font-weight: normal;',
  black: 'font-family: "DBHeaventBlack"; font-weight: normal;',
  size: (size) => `font-size: ${size}px;`,
};

export const mixin = {
  clickable: css`
    cursor: pointer;
    user-select: none;
  `,
  rgba: (colorValue, opacity) => Color(colorValue).alpha(opacity).string(),
  backgroundTableOdd: css`
    table tr:nth-child(odd) td {
      background-color: #f9f9f9;
    }
  `,
  backgroundTableEven: css`
    table tr:nth-child(even) td {
      background-color: #f9f9f9;
    }
  `,
};
