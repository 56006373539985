import styled from 'styled-components';
import { color } from 'shared/utils/styles';

export const LineDashedHead = styled.div`
  position: relative;
  overflow: hidden;
  line-height: 1.3;
  &:not(:first-child) {
    &::after {
      content: '';
      position: absolute;
      width: 0px;
      height: 100%;
      top: 1px;
      left: 87px;
      border-right: 1px dashed ${color.grey};
    }
  }
`;

export const LineDashedItem = styled.div`
  position: relative;
  overflow: hidden;
  line-height: 1.3;
  &:not(:last-child) {
    &::after {
      content: '';
      position: absolute;
      width: 0px;
      height: 100%;
      top: 24px;
      left: 87px;
      border-right: 1px dashed ${color.grey};
    }
  }
`;

export const Dot = styled.div`
  position: absolute;
  width: 9px;
  height: 9px;
  left: 4px;
  top: 8px;
  border-radius: 25px;
  background-color: ${(props) => `${props.color}`};
`;
