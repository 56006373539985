import React, { useState } from 'react';
import { Row, Col, Input, Popover, Form, Select } from 'antd';
import { Button, Text } from 'shared/components';
import IconFilter from 'shared/assets/icons/filter.svg';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BusinessInvoiceType } from 'views/AccountReceive/common/model/businessInvoiceType.model';
import { StyledButtonFilter } from './Styles';

type SelectBusinessTypeFieldProps = {
  onChange: (value: {
    businessInvoiceType: BusinessInvoiceType | undefined;
    businessDate: string;
  }) => void;
};

type FormValues = {
  businessInvoiceType: BusinessInvoiceType | string;
  businessDate: string;
};

const validateSchema = yup.object().shape({
  businessInvoiceType: yup.string(),
  businessDate: yup
    .number()
    .when('businessInvoiceType', {
      is: (value) => value === '3' || value === '5',
      then: yup.number().typeError('รูปแบบไม่ถูกต้อง').required('กรุณากรอกข้อมูล'),
    })
    .min(1, 'รูปแบบไม่ถูกต้อง')
    .max(31, 'รูปแบบไม่ถูกต้อง'),
});

const SelectBusinessTypeField: React.FC<SelectBusinessTypeFieldProps> = (props) => {
  const [textBusinessType, setTextBusinessType] = useState('');
  const [visible, setVisible] = useState(false);
  const { onChange } = props;

  const { getValues, watch, control, errors, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      businessInvoiceType: '',
      businessDate: '',
    },
  });

  const watchFields = watch(['businessInvoiceType', 'businessDate']);

  const typeGuard = (value: BusinessInvoiceType | string) => {
    if (typeof value === 'string') {
      return undefined;
    }

    return value;
  };

  const submit = () => {
    setTextBusinessType(text(getValues('businessInvoiceType')));

    onChange({
      businessInvoiceType: typeGuard(getValues('businessInvoiceType')),
      businessDate: getValues('businessDate'),
    });
    setVisible(false);
  };

  const text = (value: BusinessInvoiceType | string) => {
    switch (value) {
      case '':
        return 'ทั้งหมด';
      case 1:
        return '1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลทุกสิ้นเดือน';
      case 2:
        return '1 ใบแจ้งหนี้ ต่อ 1 งาน และวางบิลทุกวันหลังจบงาน';
      case 3:
        return '1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลตามวันที่กำหนด';
      case 4:
        return '1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลทุกสิ้นเดือน';
      case 5:
        return '1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลตามวันที่กำหนด';
      default:
        return 'ทั้งหมด';
    }
  };

  const content = (
    <Form>
      <Row gutter={[0, 8]} style={{ width: 300 }}>
        <Col span={24}>
          <Text strong>เลือกรูปแบบรับใบแจ้งหนี้</Text>
          <Controller
            as={Select}
            name="businessInvoiceType"
            control={control}
            style={{ width: 300 }}
            options={[
              { value: '', label: 'ทั้งหมด' },
              { value: 1, label: '1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลทุกสิ้นเดือน' },
              { value: 2, label: '1 ใบแจ้งหนี้ ต่อ 1 งาน และวางบิลทุกวันหลังจบงาน' },
              { value: 3, label: '1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลตามวันที่กำหนด' },
              {
                value: 4,
                label: '1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลทุกสิ้นเดือน',
              },
              {
                value: 5,
                label: '1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลตามวันที่กำหนด',
              },
            ]}
          />
        </Col>
        {watchFields &&
          (getValues('businessInvoiceType') === 3 || getValues('businessInvoiceType') === 5) && (
            <>
              <Col span={24}>
                <Text strong>ระบุวันที่</Text>
                <Text type="danger">*</Text>
              </Col>
              <Col span={24}>
                <Row gutter={[8, 0]}>
                  <Col>วันที่</Col>
                  <Col>
                    <Form.Item
                      validateStatus={errors.businessDate && 'error'}
                      help={errors.businessDate && errors.businessDate.message}
                    >
                      <Controller
                        as={Input}
                        name="businessDate"
                        control={control}
                        style={{ width: 50 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </>
          )}
        <Col span={24}>
          <Button onClick={handleSubmit(submit)} color="green" style={{ width: '100%' }}>
            ตกลง
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const visibleChangeHandler = (isVisible: boolean) => {
    setVisible(isVisible);
  };

  return (
    <Input.Group compact>
      <Input
        style={{ width: '80%', textAlign: 'left' }}
        value={textBusinessType || ''}
        placeholder="ทั้งหมด"
        readOnly
      />
      <Popover
        trigger="click"
        placement="bottomRight"
        content={content}
        visible={visible}
        onVisibleChange={visibleChangeHandler}
      >
        <StyledButtonFilter icon={<IconFilter />} style={{ width: '10%' }} />
      </Popover>
    </Input.Group>
  );
};

export default SelectBusinessTypeField;
