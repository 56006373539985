import gql from 'graphql-tag';
import { CodItemStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/codItemStatus.model';

export interface UpdateCodItemData {
  updateCodItem: {
    _id: string;
    status: CodItemStatus;
  };
}

export interface UpdateCodItemVars {
  _id: string;
  status?: CodItemStatus;
  remark?: string;
  reject_remark?: string;
}

export default gql`
  mutation UpdateCodItem($_id: ID!, $remark: String, $reject_remark: String, $status: Int) {
    updateCodItem(
      _id: $_id
      update: { remark: $remark, reject_remark: $reject_remark, status: $status }
    ) {
      _id
      status
    }
  }
`;
