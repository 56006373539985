/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode } from 'react';
import { Select, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import CUSTOMERS_QUERY, { CustomersData, CustomersVars } from 'shared/graphql/query/customers';

const { Option } = Select;

type SelectEmployeeFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const SelectEmployeeField: React.FC<SelectEmployeeFieldProps> = (props) => {
  const { value, onChange } = props;
  const [fullName, setFullName] = useState<string>('');
  const { data, loading } = useQuery<CustomersData, CustomersVars>(CUSTOMERS_QUERY, {
    variables: {
      full_name: fullName,
      showData: 10,
    },
    fetchPolicy: 'cache-and-network',
  });

  let options: ReactNode[] = [];
  if (data && data.customers) {
    const { customers } = data.customers;
    options = customers.map((customer) => (
      <Option key={customer._id} value={customer._id}>
        {customer.full_name}
      </Option>
    ));
  }

  return (
    <Select
      allowClear
      style={{
        width: '100%',
      }}
      value={value}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      showSearch
      placeholder="ค้นหา"
      onSearch={(valueSearch) => setFullName(valueSearch)}
      onChange={(valueChange) => onChange && onChange(valueChange)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};

export default SelectEmployeeField;
