import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ArrowContainer, Container, ArrowWrapper } from './Styles';

type OrderType = '1' | '-1' | '';

type SortHeaderTableProps = {
  orderBy: string;
  orderType: OrderType;
  sortName: string;
  onSort: (value: { orderBy: string; orderType: OrderType }) => void;
  children: ReactNode;
};

const SortHeaderTable: React.FC<SortHeaderTableProps> = (props) => {
  const { children, orderType, orderBy, sortName, onSort } = props;

  const selectedSort = () => {
    if (sortName !== orderBy) {
      onSort({
        orderBy: sortName,
        orderType: '1',
      });

      return;
    }

    if (orderType === '') {
      onSort({
        orderBy: sortName,
        orderType: '1',
      });
    } else if (orderType === '1') {
      onSort({
        orderBy: sortName,
        orderType: '-1',
      });
    } else {
      onSort({
        orderBy: '',
        orderType: '',
      });
    }
  };

  const getActive = (type: OrderType) => {
    return orderType === type && orderBy === sortName;
  };

  return (
    <Container onClick={() => selectedSort()}>
      <span>{children}</span>
      <ArrowWrapper>
        <ArrowContainer>
          <FontAwesomeIcon
            icon={['fas', 'sort-up']}
            className={classNames('arrow', [{ active: getActive('1') }])}
          />
          <FontAwesomeIcon
            icon={['fas', 'sort-down']}
            className={classNames('arrow', [{ active: getActive('-1') }])}
          />
        </ArrowContainer>
      </ArrowWrapper>
    </Container>
  );
};

export default SortHeaderTable;
