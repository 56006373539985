import gql from 'graphql-tag';
import { ParcelCostingStatus } from '../../../views/Costing/common/model/parcelCostingStatus.model';

export type ParcelCostListData = {
  parcelCostList: {
    parcelsCost: {
      _id: string;
      createdAt: string;
      costing_number: string;
      refNumber: string;
      total_price: string;
      vendor: {
        _id: string;
        full_name: string;
      };
      employee: {
        full_name: string;
      };
      payment: {
        payment_number: string;
      }[];
      updatedAt: string;
    }[];
    totalPage: number;
    totalDocument: number;
  };
};

export type ParcelCostListVar = {
  _id?: string;
  createdAt?: string;
  costing_number?: string;
  costing_status?: ParcelCostingStatus;
  refNumber?: string;
  vendor?: string;
  order_by?: string;
  order_type?: string;
  page?: number;
  showData?: number;
};

export default gql`
  query ParcelCostList(
    $_id: ID
    $createdAt: String
    $costing_number: String
    $refNumber: String
    $vendor: ID
    $costing_status: Int
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
  ) {
    parcelCostList(
      params: {
        _id: $_id
        createdAt: $createdAt
        costing_number: $costing_number
        refNumber: $refNumber
        vendor: $vendor
        costing_status: $costing_status
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      parcelsCost {
        _id
        createdAt
        costing_number
        refNumber
        vendor {
          _id
          full_name
        }
        employee {
          full_name
        }
        payment {
          payment_number
        }
        updatedAt
        total_price
      }
      totalPage
      totalDocument
    }
  }
`;
