/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';
import { Divider, Row, Col, Tabs, Skeleton, message } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import formatCurrency from 'shared/utils/formatCurrency';
import { color } from 'shared/utils/styles';
import { Tag, Text } from 'shared/components';
import { useFormContext } from 'react-hook-form';
import CUSTOMER_QUERY, { CustomerData, CustomerVars } from 'shared/graphql/query/customer';
import CAL_BILLING_NOTE_QUERY, {
  CalBillingNoteData,
  CalBillingNoteVars,
} from 'shared/graphql/query/calBillingNote';
import { FormValues } from 'views/AccountReceive/Routes/BillingNote/ModalCreateBillingNote/formValues.model';
import {
  BillingNoteContext,
  SelectedInvoicesType,
} from 'views/AccountReceive/common/context/BillingNoteContext';
import SelectFieldSearchCustomers from './SelectFieldSearchCustomers';
import InvoiceList from './InvoiceList';

const { TabPane } = Tabs;

const InvoiceCreateBillingNote: React.FC = () => {
  const { selectedInvoices, setSelectedInvoices } = useContext(BillingNoteContext);
  const { getValues, watch } = useFormContext<FormValues>();
  const watchFields = watch(['payerId', 'payerFullName', 'invoicesId']);
  const invoicesId = getValues('invoicesId')
    ? getValues('invoicesId').map((invoiceId: string) => ({ invoice: invoiceId }))
    : [];

  const { data: customerData } = useQuery<CustomerData, CustomerVars>(CUSTOMER_QUERY, {
    variables: {
      _id: getValues('payerId'),
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: calBillingNoteQueryData, loading, error } = useQuery<
    CalBillingNoteData,
    CalBillingNoteVars
  >(CAL_BILLING_NOTE_QUERY, {
    variables: {
      bill_invoice: invoicesId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && calBillingNoteQueryData?.calBillingNote) {
      const { payerFullName, businessInvoiceType } = selectedInvoices;
      const { grand_total_price, bill_invoice } = calBillingNoteQueryData.calBillingNote;
      setSelectedInvoices(
        (prevState): SelectedInvoicesType => ({
          ...prevState,
          modalConfirmCreateBillingNote: {
            payerFullName,
            businessInvoiceType,
            grandTotalPrice: grand_total_price,
            invoices: bill_invoice.map((invoice) => ({
              serviceDate: invoice.invoice.service_date,
              invoiceNumber: invoice.invoice.invoice_number || '-',
              grandTotalPrice: invoice.invoice.grand_total_price,
              dueDate: invoice.invoice.due_date,
            })),
          },
        }),
      );
    }
  }, [calBillingNoteQueryData?.calBillingNote, error, loading]);

  let content = <Skeleton />;
  if (customerData?.customer && calBillingNoteQueryData?.calBillingNote) {
    const { address, tax_id, email, phone } = customerData.customer;
    const { grand_total_price } = calBillingNoteQueryData.calBillingNote;

    content = (
      <>
        <Text strong>เงื่อนไขใบแจ้งหนี้</Text>
        <Divider style={{ margin: '10px 0' }} />
        <Row gutter={[32, 16]}>
          <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>
                    <Text customType="danger">*</Text>
                  </Col>
                  <Col span={24}>
                    {watchFields && (
                      <SelectFieldSearchCustomers
                        value={{
                          key: getValues('payerId'),
                          label: getValues('payerFullName'),
                        }}
                        disabled
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={24}>รูปแบบรับใบแจ้งหนี้:</Col>
                  <Col span={24}>
                    <Text strong>{selectedInvoices.businessInvoiceType}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
            <Row>
              <Col span={10}>ประเภทงาน:</Col>
              <Col span={14}>
                <Text strong>{selectedInvoices.systemRequest}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>ที่อยู่วางบิล:</Col>
              <Col span={14}>
                <Text strong>{address || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เลขประจำตัวผู้เสียภาษี:</Col>
              <Col span={14}>
                <Text strong>{tax_id || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เบอร์โทรศัพท์:</Col>
              <Col span={14}>
                <Text strong>{phone.length === 0 ? '-' : phone.join(', ')}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>อีเมล:</Col>
              <Col span={14}>
                <Text strong>{email.length === 0 ? '-' : email.join(', ')}</Text>
              </Col>
            </Row>
          </Col>

          <Col span={8}>
            <Row>
              <Col span={8}>วันที่เอกสาร:</Col>
              <Col span={16}>
                <Text strong>-</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รหัสใบวางบิล:</Col>
              <Col span={16}>
                <Text strong>-</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รวมมูลค่าใบวางบิล:</Col>
              <Col span={16}>
                <Text strong customType="primary">
                  {formatCurrency(grand_total_price)}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>สถานะ:</Col>
              <Col span={16}>
                <Tag customColor="blue">กำลังสร้าง...</Tag>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider style={{ margin: 0, marginTop: -5 }} />

        <Tabs defaultActiveKey="1" animated={false}>
          <TabPane
            tab={
              <span>
                รายละเอียดงาน{' '}
                <CheckCircleOutlined
                  style={{
                    color: color.success,
                    marginLeft: 5,
                  }}
                />
              </span>
            }
            key="1"
          >
            <InvoiceList />
          </TabPane>
        </Tabs>
      </>
    );
  }

  return content;
};

export default InvoiceCreateBillingNote;
