import { FunctionCreateRangeYearToToDay } from './Dashboard.Functions';

export const THIS_MONTH = new Date().getMonth() + 1;
export const THIS_YEAR = new Date().getFullYear();

export const SYNTAX_MONTH = 'month';
export const SYNTAX_YEAR = 'year';
export const SYNTAX_DIVIDED = '/';

export const TEXT_CALENDAR_UNIT = 'ชิ้น';
export const TEXT_CALENDAR_BATH = 'บาท';

export const TEXT_MODAL_TITLE = 'รายการรอจ่าย COD ของวันที่ [date]';
export const TEXT_MODAL_CLOSE = 'Close';
export const TEXT_MODAL_WAITING_TOTLE = `ยอดรอโอน COD รวม(${TEXT_CALENDAR_BATH})`;
export const TEXT_MODAL_HAVE_TO_DO_DATE = 'วันที่ต้องทำจ่าย';
export const TEXT_MODAL_PRODUCT_COUNT = `จำนวนสินค้า(${TEXT_CALENDAR_UNIT})`;

export const TEXT_CALENDAR_CELL_TITLE = 'จำนวนสินค้ารอส่ง COD';
export const TEXT_CALENDAR_CELL_FOOTER = `ยอดที่เหลือ [count] ${TEXT_CALENDAR_BATH}`;
export const TEXT_CALENDAR_NO_DATA = 'ยังไม่มีรายการ';

export const THIS_CALENDAR_MONTH = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
];

export const THIS_CALENDAR_YEAR = FunctionCreateRangeYearToToDay(2021);
