import gql from 'graphql-tag';

export interface BillingNoteStatusData {
  billingNoteStatus: {
    waiting: number;
    billing_note: number;
    edit: number;
    cancel: number;
    completed: number;
  };
}

export default gql`
  query BillingNoteStatus {
    billingNoteStatus {
      waiting
      billing_note
      edit
      cancel
      completed
    }
  }
`;
