import styled from 'styled-components';
import { Result } from 'antd';
import { font } from 'shared/utils/styles';

const ResultStyle = styled(Result)`
  &.ant-result {
    padding-top: 100px;
  }

  .ant-result-title {
    ${font.size(66)}
  }

  .ant-result-subtitle {
    ${font.size(20)}
  }
`;

export default ResultStyle;
