/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Row, Col, Input } from 'antd';
import { DeleteOutlined, PlusCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useFormContext, Controller } from 'react-hook-form';
import BUILD_PAYMENT_COD_QUERY, {
  BuildPaymentCodData,
  BuildPaymentCodVars,
  CodItem,
} from 'shared/graphql/query/buildPaymentCod';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { Button, Text, Spin } from 'shared/components';
import { FormValues } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/Routes/CodIn/CodInList/ModalCreateCod/model/formValues.model';
import { ModalAddCod } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/components';
import { StyledTable, StyledFormItem } from './Styles';
import ExpandedRow from './ExpandedRow';

const { TextArea } = Input;

type CodInDataTable = {
  key: string;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: string;
  column4: string;
  column5: string;
  column6: ReactNode;
  column7: string;
  codItem: CodItem[];
};

const columns = [
  {
    title: 'เครื่องมือ',
    dataIndex: 'tools',
    width: 70,
  },
  {
    title: 'ชื่อลูกค้า',
    dataIndex: 'column1',
    width: 300,
  },
  {
    title: 'รหัส COD-IN',
    dataIndex: 'column2',
    width: 200,
  },
  {
    title: 'วันที่รับสินค้า',
    dataIndex: 'column3',
    width: 130,
  },
  {
    title: 'วันที่ส่งสำเร็จ',
    dataIndex: 'column4',
    width: 160,
  },
  {
    title: 'Booking Number',
    dataIndex: 'column5',
    width: 160,
  },
  {
    title: 'ยอดรวม COD (บาท)',
    dataIndex: 'column6',
    width: 160,
    align: 'right',
  },
  {
    title: 'วันที่ต้องทำจ่าย',
    dataIndex: 'column7',
    width: 160,
  },
];

const CodInList: React.FC = () => {
  const { getValues, setValue, control, watch } = useFormContext<FormValues>();
  const [isOpenModalAddCodIn, setOpenModalAddCodIn] = useState<boolean>(false);
  const codsInId = getValues('codsInId')
    ? getValues('codsInId').map((codInId: string) => codInId)
    : [];

  const watchCodsInId = watch('codsInId');

  const { error, data, loading } = useQuery<BuildPaymentCodData, BuildPaymentCodVars>(
    BUILD_PAYMENT_COD_QUERY,
    {
      variables: {
        cod: codsInId,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  let content: ReactNode = <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  let codInDataTable: CodInDataTable[] = [];
  if (data?.buildPaymentCod) {
    const { cod } = data.buildPaymentCod;

    codInDataTable = cod.map((item) => {
      return {
        key: item._id,
        tools: (
          <Button
            disabled={getValues('codsInId').length < 2}
            title="ลบ"
            color="red--outline"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => {
              const filterInvoice = getValues('codsInId').filter((codInId) => codInId !== item._id);
              setValue('codsInId', filterInvoice);
            }}
          />
        ),
        column1: item.customer.full_name || '-',
        column2: item.cod_in_number || '-',
        column3: formatDate(item.pick_up_date) || '-',
        column4: formatDate(item.delivered_date) || '-',
        column5: item.booking_number || '-',
        column6: (
          <Text customType="primary" strong>
            {formatCurrency(item.total_cod)}
          </Text>
        ),
        column7: formatDate(item.due_date) || '-',
        codItem: item.cod_item,
      };
    });

    content = (
      <>
        <StyledTable
          scroll={{ x: 'max-content' }}
          pagination={false}
          columns={columns}
          dataSource={codInDataTable}
          size="small"
          expandable={{
            expandedRowRender: (record: { codItem: CodItem[] }) => (
              <ExpandedRow data={record.codItem} />
            ),
          }}
          loading={loading}
        />
        <Row justify="space-between" style={{ marginTop: 10 }}>
          <Col>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button
                  color="blue--light"
                  icon={<PlusCircleOutlined />}
                  onClick={() => setOpenModalAddCodIn(true)}
                >
                  เพิ่มรายการ
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            หมายเหตุ <Text customType="grey">(ไม่บังคับ)</Text>
            <StyledFormItem>
              <Controller
                control={control}
                name="paymentCodRemark"
                render={({ onChange, value }) => (
                  <TextArea
                    value={value || undefined}
                    placeholder="ระบุ"
                    rows={4}
                    onChange={(event) => {
                      onChange(event.target.value);
                    }}
                  />
                )}
              />
            </StyledFormItem>
          </Col>
        </Row>

        <ModalAddCod
          visible={isOpenModalAddCodIn}
          onOk={(cods) => {
            setValue(
              'codsInId',
              cods.map((codItem) => codItem.id),
            );
            setOpenModalAddCodIn(false);
          }}
          onCancel={() => setOpenModalAddCodIn(false)}
          cods={cod.map((codItem) => ({
            id: codItem._id,
            codNumber: codItem.cod_in_number,
            pickUpDate: codItem.pick_up_date,
            deliveredDate: codItem.delivered_date,
            bookingNumber: codItem.booking_number,
            totalCod: codItem.total_cod,
            dueDate: codItem.due_date,
            confirmStatus: codItem.confirm_status,
            totalVendor: codItem.total_vendor,
            customerFullName: codItem.customer.full_name,
          }))}
          customerId={getValues('customerId')}
        />
      </>
    );
  }

  if (error) {
    content = error.message;
  }

  return <div>{watchCodsInId && content}</div>;
};

export default CodInList;
