type EnumLiteralsOf<T extends unknown> = T[keyof T];
export const CodParcelStatus = Object.freeze({
  WAITING3PL: 1 as const,
  PENDING: 2 as const,
  CONFIRM: 3 as const,
  REJECT: 4 as const,
  CANCEL: 5 as const,
});

export type CodParcelStatus = EnumLiteralsOf<typeof CodParcelStatus>;
