import React, { useContext, useState } from 'react';
import { Row, Col } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import {
  PaymentOrderContext,
  CreatePaymentInsuranceType,
} from 'views/AccountPayment/common/context/PaymentOrderContext';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalCreatePaymentInsurance/model/formValues.model';
import { useFormContext } from 'react-hook-form';
import { StyledButton, TextHeader, Container } from './Styles';
import ModalConfirm from './ModalConfirm';

const Header: React.FC = () => {
  const { createPaymentInsurance: createPaymentInsuranceContext } = useContext(PaymentOrderContext);
  const [, setCreatePaymentInsurance] = createPaymentInsuranceContext;
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);

  const { handleSubmit } = useFormContext<FormValues>();

  const onSubmit = handleSubmit(() => {
    setOpenModalConfirm(true);
  });

  return (
    <>
      <Container>
        <Row justify="space-between" align="middle">
          <Col>
            <TextHeader>สร้างใบสั่งจ่ายค่าประกัน</TextHeader>
          </Col>
          <Col>
            <StyledButton icon={<CheckCircleOutlined />} color="green" onClick={onSubmit}>
              สร้างใบสั่งจ่าย
            </StyledButton>
            <StyledButton
              onClick={() => {
                setCreatePaymentInsurance(
                  (prevState): CreatePaymentInsuranceType => ({
                    ...prevState,
                    isOpenModal: false,
                  }),
                );
              }}
            >
              ปิด
            </StyledButton>
          </Col>
        </Row>
        <ModalConfirm visible={openModalConfirm} onCancel={() => setOpenModalConfirm(false)} />
      </Container>
    </>
  );
};

export default Header;
