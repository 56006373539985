import styled from 'styled-components';
import { Button, Checkbox, Modal, Form, Table } from 'antd';
import { color } from 'shared/utils/styles';

export const Container = styled.div`
  padding: 0px 20px 20px;
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  transform: scale(1.2);
  margin-right: 10px;
`;

export const ToolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledModal = styled(Modal)`
  top: 0px;
  padding: 20px;

  &.custom-modal.ant-modal {
    width: 100% !important;
    max-width: 1366px;
  }

  & .ant-modal-body {
    padding: 0px 20px 20px;
  }

  & .ant-modal-body p {
    margin: 0;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

export const StyledTable = styled(Table)`
  & thead > tr > th {
    border-top: 1px solid ${color.border};
  }

  & tr > th.divider,
  tr > td.divider {
    border-right: 1px solid ${color.border};
  }
`;
