/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Row, Col, Modal, Typography } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button } from 'shared/components';
import s3 from 'shared/config/s3';
import { FileContainer } from './Styles';

const { confirm } = Modal;
const { Text } = Typography;

type DocumentListProps = {
  filesPath: string[];
  onDelete?: (path: string) => void;
};

const DocumentList: React.FC<DocumentListProps> = (props) => {
  const { filesPath, onDelete } = props;

  const showConfirmDeleteDocument = (path: string) => {
    const modal = {
      title: 'ยืนยัน',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          ต้องการ <Text strong>ลบไฟล์เอกสาร</Text> ใช่หรือไม่?
        </>
      ),
      okText: 'ตกลง',
      onOk() {
        if (onDelete) {
          onDelete(path);
        }
      },
      cancelText: 'ยกเลิก',
    };

    confirm(modal);
  };

  const documentList = filesPath.map((path) => (
    <Col key={path} span={24}>
      <FileContainer>
        {onDelete && (
          <Button
            onClick={() => showConfirmDeleteDocument(path)}
            icon={<DeleteOutlined />}
            color="red--outline"
            style={{ marginRight: 10 }}
          />
        )}
        <a href={`${s3}/${path}`} target="_blank" rel="noopener noreferrer">
          {`${s3}/${path}`}
        </a>
      </FileContainer>
    </Col>
  ));

  return <Row gutter={[16, 16]}>{documentList}</Row>;
};

export default DocumentList;
