type EnumLiteralsOf<T extends unknown> = T[keyof T];
export const InvoiceStatus = Object.freeze({
  DRAFT: 1 as const,
  INVOICE: 2 as const,
  BILLING_NOTE: 3 as const,
  RECEIPT: 4 as const,
  CANCEL: 5 as const,
  EDIT: 6 as const,
});

export type InvoiceStatus = EnumLiteralsOf<typeof InvoiceStatus>;
