import styled from 'styled-components';
import { Button } from 'shared/components';
import { font, color } from 'shared/utils/styles';

export const Container = styled.div`
  border-bottom: 1px solid ${color.grey};
  margin: 0px -20px 10px;
  padding: 10px 10px 10px 20px;
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export const TextHeader = styled.div`
  ${font.size(24)}
  ${font.medium}
  color: ${color.black}
`;
