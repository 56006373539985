/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Col, Row, message } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Text } from 'shared/components';
import { useMutation } from '@apollo/react-hooks';
import UPDATE_CUSTOMER_MUTATION, {
  UpdateCustomerData,
  UpdateCustomerVars,
} from 'shared/graphql/mutation/updateCustomer';
import { useFormContext } from 'react-hook-form';
import {
  CustomerBankMode,
  FormValues,
} from 'views/Setting/Routes/Customer/Routes/CustomerDetail/formValues.model';
import { StyledModal } from './Styles';

type ModalConfirmEditProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmEdit: React.FC<ModalConfirmEditProps> = (props) => {
  const { onCancel, visible } = props;

  const { handleSubmit, watch } = useFormContext<FormValues>();

  const [updateCustomer, { loading }] = useMutation<UpdateCustomerData, UpdateCustomerVars>(
    UPDATE_CUSTOMER_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.updateCustomer._id) {
          onCancel();
          message.success('แก้ไขบัญชีลูกหนี้แล้ว');
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['Customer', 'Customers'],
    },
  );

  const submit = handleSubmit((values) => {
    updateCustomer({
      variables: {
        _id: values.customerId,
        from: values.from,
        first_name: values.firstName,
        last_name: values.lastName,
        full_name: `${values.firstName} ${values.lastName}`,
        email: values.email.map((item) => item.text),
        phone: [values.phone],
        tax_id: values.taxId,
        branch: values.branch,
        address: values.address,
        sub_district: values.subDistrict,
        district: values.district,
        province: values.province,
        postcode: values.postCode,
        business_invoice_type: values.businessInvoiceType,
        business_invoice_date_range: {
          start: values.businessInvoiceDateRangeStart,
          end: values.businessInvoiceDateRangeEnd,
        },
        payment_bank_account: {
          bank_name: values.bankName,
          bank_branch: values.bankBranch,
          account_name: values.accountName,
          account_number: values.accountNumber,
        },
        business_pay_round: {
          round_date: values.businessPayRoundRoundDate ? values.businessPayRoundRoundDate : '0',
          after_invoice: values.businessPayRoundRoundAfterInvoice
            ? values.businessPayRoundRoundAfterInvoice
            : '0',
        },
        customer_bank_account: {
          mode:
            values.customerBankMode === CustomerBankMode.PROMPT_PAY
              ? 3
              : values.customerBankMode === CustomerBankMode.BANK_ACCOUNT &&
                values.customerBankCode === '002'
              ? 1
              : 2,
          bank_id:
            values.customerBankMode === CustomerBankMode.BANK_ACCOUNT
              ? values.customerBankId
              : undefined,
          account_name: values.customerAccountName,
          account_number: values.customerAccountNumber,
          promptPay:
            values.customerBankMode === CustomerBankMode.PROMPT_PAY
              ? {
                  type: values.customerBankPromptPayMode,
                  account_number: values.customerAccountNumber,
                }
              : undefined,
        },
      },
    });
  });

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันแก้ไขบัญชีเจ้าหนี้ - บริษัท ใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => submit()}
          loading={loading}
        >
          ใช่, แก้ไขบัญชีเจ้าหนี้
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>ชื่อ:</Col>
        <Col span={18}>
          <Text strong>{watch('firstName') || '-'}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>นามสกุล:</Col>
        <Col span={18}>
          <Text strong>{watch('lastName') || '-'}</Text>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmEdit;
