/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode, useContext } from 'react';
import PARCEL_ORDERS_QUERY, {
  ParcelOrdersData,
  ParcelOrdersVars,
} from 'shared/graphql/query/parcelOrders';
import PARCEL_ORDER_ALL_QUERY, {
  ParcelOrderAllData,
  ParcelOrderAllVars,
} from 'shared/graphql/query/parcelOrderAll';
import UPDATE_COD_OUT_PARCEL_MUTATION, {
  UpdateCodOutParcelData,
  UpdateCodOutParcelVars,
} from 'shared/graphql/mutation/updateCodOutParcel';
import { useQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import { Input, message, Row, Col, DatePicker } from 'antd';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDateTime } from 'shared/utils/dateTime';
import { Text, TableSearch } from 'shared/components';
import { PaymentCodContext } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/context/PaymentCodContext';
import { CodParcelOrderStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codParcelOrderStatus.model';
import { StyledModal, StyledCheckbox, ToolsContainer } from './Styles';
import Header from './Header';

const { RangePicker } = DatePicker;

type Cods = {
  id: string;
};

type ModalAddCodWrapperProps = {
  visible: boolean;
  onCancel: () => void;
  cods?: Cods[];
};

type ModalAddCodProps = {
  onCancel: () => void;
  cods: Cods[];
};

type CodListDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: ReactNode;
  column3: string;
  column4: string;
  column5: string;
  column6: string;
};

const ModalAddCodWrapper: React.FC<ModalAddCodWrapperProps> = (props) => {
  const { visible, onCancel, cods } = props;
  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalAddCod onCancel={onCancel} cods={cods || []} />
    </StyledModal>
  );
};

const ModalAddCod: React.FC<ModalAddCodProps> = (props) => {
  const { onCancel, cods } = props;
  const [selectedCods, setSelectedCods] = useState<Cods[]>([]);
  const [trackingNumber, setTrackingNumber] = useState<string>('');
  const [codInNumber, setCodInNumber] = useState<string>('');
  const [codInDate, setCodInDate] = useState<string>('');
  const [pageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { selectedPaymentCodDetail } = useContext(PaymentCodContext);
  const { paymentCodId, customerId, customerFullName } = selectedPaymentCodDetail;

  const { loading, data } = useQuery<ParcelOrdersData, ParcelOrdersVars>(PARCEL_ORDERS_QUERY, {
    variables: {
      customer: customerId,
      cod_in_number: codInNumber,
      tracking_number: trackingNumber,
      cod_status: CodParcelOrderStatus.WAIT_TO_PAY,
      cod_in_date: codInDate,
      page: currentPage,
      showData: pageSize,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: parcelOrderAllData,
    loading: parcelOrderAllLoading,
    error: parcelOrderAllError,
  } = useQuery<ParcelOrderAllData, ParcelOrderAllVars>(PARCEL_ORDER_ALL_QUERY, {
    variables: {
      cod_status: CodParcelOrderStatus.WAIT_TO_PAY,
      customer: customerId,
      cod_in_number: codInNumber,
      tracking_number: trackingNumber,
      cod_in_date: codInDate,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [updateCodOutParcel, { loading: updateCodOutParcelLoading }] = useMutation<
    UpdateCodOutParcelData,
    UpdateCodOutParcelVars
  >(UPDATE_COD_OUT_PARCEL_MUTATION, {
    update: (_cache, { data: dataUpdate }) => {
      if (dataUpdate?.updateCodOutParcel._id) {
        message.success('เพิ่มรายการแล้ว');
        onCancel();
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['CodOutParcel', 'CodOutParcels'],
  });

  const selectCods = (checked: boolean, value: Cods) => {
    if (checked) {
      setSelectedCods((prevState) => {
        return [...prevState, value];
      });
    } else {
      const codsInFilter = selectedCods.filter((cod) => cod.id !== value.id);
      setSelectedCods([...codsInFilter]);
    }
  };

  const onCheckAllForOneCustomer = (check: boolean) => {
    if (parcelOrderAllData && !parcelOrderAllLoading && !parcelOrderAllError) {
      if (check) {
        setSelectedCods(() => {
          return parcelOrderAllData.parcelOrderAll.parcelOrders.map((item) => ({ id: item._id }));
        });
      }

      if (!check) {
        setSelectedCods([]);
      }
    }
  };

  const onSubmit = () => {
    updateCodOutParcel({
      variables: {
        _id: paymentCodId,
        parcel_orders: [...cods.map((item) => item.id), ...selectedCods.map((item) => item.id)],
      },
    });
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
      search: (
        <StyledCheckbox
          onChange={(event) => {
            onCheckAllForOneCustomer(event.target.checked);
          }}
          checked={
            parcelOrderAllData?.parcelOrderAll.parcelOrders.every((item) =>
              selectedCods.map((itemSelectedCod) => itemSelectedCod.id).includes(item._id),
            ) && parcelOrderAllData?.parcelOrderAll.parcelOrders.length > 0
          }
          value={selectedCods.map((itemSelectCod) => itemSelectCod.id)}
        />
      ),
    },
    {
      title: 'รหัส COD-IN',
      dataIndex: 'column1',
      width: 180,
      search: (
        <Input
          allowClear
          value={codInNumber}
          placeholder="ค้นหา"
          onChange={(event) => {
            setCodInNumber(event.target.value);
          }}
        />
      ),
    },
    {
      title: 'ยอดรวม COD (บาท)',
      dataIndex: 'column2',
      width: 150,
      align: 'right',
    },
    {
      title: 'Tracking Number',
      dataIndex: 'column3',
      width: 180,
      search: (
        <Input
          allowClear
          value={trackingNumber}
          placeholder="ค้นหา"
          onChange={(event) => {
            setTrackingNumber(event.target.value);
          }}
        />
      ),
    },
    {
      title: 'วันที่แจ้งโอน',
      dataIndex: 'column4',
      width: 250,
      search: (
        <RangePicker
          onChange={(values) => {
            const formatRangDate =
              values === null
                ? ''
                : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

            setCodInDate(formatRangDate);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'ผู้ตรวจสอบ',
      dataIndex: 'column5',
      width: 180,
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'column6',
      width: 180,
    },
  ];

  let codListDataTable: CodListDataTable[] = [];
  let totalDocument = 0;

  if (data?.parcelOrders) {
    const { parcelOrders } = data.parcelOrders;
    codListDataTable = parcelOrders.map(
      (item, index): CodListDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <StyledCheckbox
              checked={selectedCods.map((cod) => cod.id).indexOf(item._id) !== -1}
              value={item._id}
              onChange={(event) =>
                selectCods(event.target.checked, {
                  id: item._id,
                })
              }
            />
          </ToolsContainer>
        ),
        column1: item.cod_in_number || '-',
        column2: (
          <Text strong customType="primary">
            {item.cod_price ? formatCurrency(item.cod_price) : '-'}
          </Text>
        ),
        column3: item.tracking_number || '-',
        column4: item.cod_in_date ? formatDateTime(item.cod_in_date) : '-',
        column5: item.checked_by?.full_name || '-',
        column6: item.updatedAt ? formatDateTime(item.updatedAt) : '-',
      }),
    );
    totalDocument = data?.parcelOrders.totalDocument;
  }

  return (
    <div>
      <Header
        onSubmit={onSubmit}
        onCancel={onCancel}
        btnDisabled={selectedCods.length === 0 || updateCodOutParcelLoading}
      />

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row>
            <Col span={8}>
              ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>
              <div>
                <Input value={customerFullName || '-'} disabled />
              </div>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <TableSearch
            columns={columns}
            dataSource={codListDataTable}
            loading={loading}
            pagination={{
              current: currentPage,
              pageSize,
              total: totalDocument,
              showSizeChanger: false,
              onChange: (value: number) => setCurrentPage(value),
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ModalAddCodWrapper;
