import React from 'react';
import { Row, Col, message } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import CREATE_PARCEL_DETAIL_MUTATION, {
  CreateParcelDetailData,
  CreateParcelDetailVars,
} from 'shared/graphql/mutation/createParcelDetail';
import { StyledButton, TextHeader, Container } from './Styles';
import { FormValues } from '../formUtil/formValues.model';

interface HeaderProps {
  onCancel: () => void;
}

const Header: React.FC<HeaderProps> = (props) => {
  const { onCancel } = props;
  const { handleSubmit, reset } = useFormContext<FormValues>();

  const [createParcelDetail, { loading }] = useMutation<
    CreateParcelDetailData,
    CreateParcelDetailVars
  >(CREATE_PARCEL_DETAIL_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.createParcelDetail._id) {
        message.success('สร้าง Parcel Detail แล้ว');
        reset();
        onCancel();
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['ParcelsList', 'ParcelStatus'],
  });

  const onSubmit = handleSubmit((values) => {
    createParcelDetail({
      variables: {
        customer: values.customerId,
        pickupRangeDate: values.pickupDate,
      },
    });
  });

  return (
    <>
      <Container>
        <Row justify="space-between" align="middle">
          <Col>
            <TextHeader>สร้าง Parcel detail</TextHeader>
          </Col>
          <Col>
            <StyledButton
              icon={<CheckCircleOutlined />}
              color="green"
              onClick={onSubmit}
              loading={loading}
            >
              สร้าง Parcel detail
            </StyledButton>
            <StyledButton
              onClick={() => {
                onCancel();
              }}
            >
              ปิด
            </StyledButton>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Header;
