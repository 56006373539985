import React, { useState, ReactNode } from 'react';
import { Empty } from 'antd';
import { StyledTable } from './Styles';
import SortHeaderTable from '../SortHeaderTable';

type Column = {
  title: string;
  dataIndex?: string;
  sortName?: string;
  width?: number;
  search?: ReactNode;
};

type Pagination = {
  current: number;
  pageSize: number;
  total: number;
  showSizeChanger: boolean;
  onChange: (value: number) => void;
};

type OrderType = '1' | '-1' | '';

type TableSearchProps = {
  columns: Column[];
  onSort?: (value: { orderBy: string; orderType: OrderType }) => void;
  dataSource: any[];
  loading?: boolean;
  pagination: Pagination | false;
};

const defaultProps = {
  loading: false,
};

const TableSearch: React.FC<TableSearchProps> = (props) => {
  const { columns, onSort, ...otherProps } = props;
  const [orderBy, setOrderBy] = useState('');
  const [orderType, setOrderType] = useState<OrderType>('');

  const renderColumns = columns.map((column) => ({
    ...column,
    title: (
      <div className="table-header">
        <div className="table-header__text">
          {column.sortName ? (
            <SortHeaderTable
              orderBy={orderBy}
              orderType={orderType}
              sortName={column.sortName || ''}
              onSort={(value) => {
                setOrderBy(value.orderBy);
                setOrderType(value.orderType);

                if (onSort) {
                  onSort({
                    orderBy: value.orderBy,
                    orderType: value.orderType,
                  });
                }
              }}
            >
              {column.title}
            </SortHeaderTable>
          ) : (
            column.title
          )}
        </div>
        {column.search && <div className="table-header__search">{column.search}</div>}
      </div>
    ),
    dataIndex: column.dataIndex,
  }));

  return (
    <StyledTable
      columns={renderColumns}
      scroll={{ x: 'max-content' }}
      locale={{
        emptyText: <Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
      }}
      {...otherProps}
    />
  );
};

TableSearch.defaultProps = defaultProps;

export default TableSearch;
