import axios from 'axios';
// import { getStoredAuthToken } from 'shared/utils/authToken';
import baseUrl from './baseUrl';

// const headers = getStoredAuthToken()
//   ? {
//       Authorization: `Bearer ${getStoredAuthToken()}`,
//     }
//   : undefined;

export default axios.create({
  baseURL: baseUrl,
});

export const axiosExpress = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_EXPRESS_URL,
  headers: {
    Authorization: `giztix ${process.env.REACT_APP_EXPRESS_TOKEN}`,
  },
});
