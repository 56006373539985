import gql from 'graphql-tag';

export interface SearchFollowDebtHistoryLogData {
  searchFollowDebtHistoryLog: {
    _id: string;
    followDetailID: string;
    followStatus: string;
    followStatusDetail: string;
    action: string;
    actionDetail: string;
    nextfollowDate: string;
    detail: string;
    employee: {
      _id: string;
      first_name: string;
    };
    status: number;
    createdAt: string;
    updatedAt: string;
  };
}

export interface SearchFollowDebtHistoryLogVars {
  historyLogID: string;
}

export default gql`
  query SearchFollowDebtHistoryLog($historyLogID: ID!) {
    searchFollowDebtHistoryLog(historyLogID: $historyLogID) {
      _id
      followDetailID
      followStatus
      followStatusDetail
      action
      actionDetail
      nextfollowDate
      detail
      employee {
        _id
        first_name
      }
      status
      createdAt
      updatedAt
    }
  }
`;
