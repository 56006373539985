/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Modal, Typography, Row } from 'antd';
import { Button } from 'shared/components';
import { PaymentOrderContext } from 'views/AccountPayment/common/context/PaymentOrderContext';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalDetailPaymentOrder/model/formValues.model';

const { Text } = Typography;

type ModalConfirmCreateVoucherProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmCreatePaymentVoucher: React.FC<ModalConfirmCreateVoucherProps> = (props) => {
  const { onCancel, visible } = props;
  const {
    selectedPaymentDetail: selectedPaymentDetailContext,
    selectedPayments: selectedPaymentsContext,
  } = useContext(PaymentOrderContext);
  const [selectedPaymentDetail, setSelectedPaymentDetail] = selectedPaymentDetailContext;
  const [, setSelectedPayments] = selectedPaymentsContext;
  const { watch } = useFormContext<FormValues>();

  const watchAllField = watch();

  const closeModalHandler = () => {
    setSelectedPaymentDetail((prevState) => ({
      ...prevState,
      isOpenModal: false,
    }));
  };

  const confirmCreateVoucher = () => {
    setSelectedPayments((prevState) => ({
      ...prevState,
      payments: [
        { id: selectedPaymentDetail.paymentId, paymentNumber: watchAllField.paymentNumber },
      ],
      payeeId: watchAllField.payeeId,
      dueDate: watchAllField.roundPaidDate,
      payeeFullName: watchAllField.payeeName,
      paymentType: watchAllField.paymentType,
    }));

    setTimeout(() => {
      setSelectedPayments((prevState) => ({
        ...prevState,
        isOpenModal: true,
      }));

      onCancel();
      closeModalHandler();
    }, 0);
  };

  return (
    <Modal
      title="สร้างใบสำคัญจ่ายหรือไม่?"
      visible={visible}
      onCancel={onCancel}
      okButtonProps={{
        icon: <CheckCircleOutlined />,
        color: 'green',
      }}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmCreateVoucher()}
        >
          สร้างใบสำคัญจ่าย
        </Button>,
        <Button key="cancelButton" icon={<CloseCircleOutlined />} onClick={onCancel}>
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>รหัสใบสั่งจ่าย:</Col>
        <Col span={18}>
          <Text strong>{watchAllField.paymentNumber}</Text>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalConfirmCreatePaymentVoucher;
