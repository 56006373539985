import React from 'react';
import { Row, Col, Skeleton, Form, Input } from 'antd';
import { Text, SelectPayeesField } from 'shared/components';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalCreatePaymentInsurance/model/formValues.model';
import { useFormContext, Controller } from 'react-hook-form';

const CreatePaymentInsurance: React.FC = () => {
  const { control, errors } = useFormContext<FormValues>();

  let content = <Skeleton />;

  content = (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row justify="space-between">
            <Col span={24}>
              ผู้รับเงิน <Text customType="grey">(เจ้าหนี้)</Text>
              <Text type="danger">*</Text>
            </Col>
            <Col span={12}>
              <Form.Item validateStatus={errors.payeeId && 'error'} style={{ marginBottom: 10 }}>
                <Controller
                  control={control}
                  name="payeeId"
                  render={({ onChange, value }) => (
                    <SelectPayeesField
                      value={value || undefined}
                      onChange={(valueChange) => {
                        onChange(valueChange ? valueChange.payeeId : '');
                      }}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col span={24}>
              <Text>รหัสใบสั่งจ่าย</Text>
              <Text type="danger">*</Text>
            </Col>
            <Col span={12}>
              <Form.Item
                validateStatus={errors.paymentNumber && 'error'}
                style={{ marginBottom: 10 }}
              >
                <Controller control={control} name="paymentNumber" as={Input} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );

  return content;
};

export default CreatePaymentInsurance;
