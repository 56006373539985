import gql from 'graphql-tag';
import { ReceiptStatus } from 'views/AccountReceive/common/model/receiptStatus.model';

export interface UpdateReceiptData {
  updateReceipt: {
    _id: string;
    receipt_status: ReceiptStatus;
  };
}

export interface UpdateReceiptVars {
  _id: string;
  receipt_status?: ReceiptStatus;
  cancel_remark?: string;
}

export default gql`
  mutation updateReceipt($_id: ID!, $receipt_status: Int!, $cancel_remark: String) {
    updateReceipt(
      _id: $_id
      updateReceipt: { receipt_status: $receipt_status, cancel_remark: $cancel_remark }
    ) {
      _id
      receipt_status
    }
  }
`;
