import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  PaymentOrderContext,
  CreatePaymentOrderType,
} from 'views/AccountPayment/common/context/PaymentOrderContext';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalCreatePaymentOrder/model/formValues.model';
import CHECK_VENDOR_INVOICE_ID_QUERY, {
  CheckVendorInvoiceIDData,
  CheckVendorInvoiceIDVars,
} from 'shared/graphql/query/checkVendorInvoiceID';
import { StyledButton, TextHeader, Container } from './Styles';
import ModalConfirmCreatePaymentOrder from './ModalConfirmCreatePaymentOrder';
import ModalCheckVendorInvoice from './ModalCheckVendorInvoice';

const Header: React.FC = () => {
  const { handleSubmit, getValues } = useFormContext<FormValues>();
  const [isOpenModalConfirmCreatePaymentOrder, setOpenModalConfirmCreatePaymentOrder] = useState<
    boolean
  >(false);
  const [isOpenModalCheckVendorInvoice, setOpenModalCheckVendorInvoice] = useState<boolean>(false);
  const { createPaymentOrder: createPaymentOrderContext } = useContext(PaymentOrderContext);
  const [, setCreatePaymentOrder] = createPaymentOrderContext;
  const allFieldValues = getValues();

  const [checkVendorInvoice, { data }] = useLazyQuery<
    CheckVendorInvoiceIDData,
    CheckVendorInvoiceIDVars
  >(CHECK_VENDOR_INVOICE_ID_QUERY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.checkVendorInvoiceID?._id) {
      setOpenModalCheckVendorInvoice(true);
    }

    if (data?.checkVendorInvoiceID === null) {
      setOpenModalConfirmCreatePaymentOrder(true);
    }
  }, [data?.checkVendorInvoiceID]);

  const closeModalHandler = () => {
    setCreatePaymentOrder(
      (prevState): CreatePaymentOrderType => ({
        ...prevState,
        isOpenModal: false,
      }),
    );
  };

  const onSubmitCreatePaymentOrder = handleSubmit((values) => {
    if (values.vendorInvoice) {
      checkVendorInvoice({
        variables: {
          vendor_invoice_id: values.vendorInvoice,
        },
      });
    } else {
      setOpenModalConfirmCreatePaymentOrder(true);
    }
  });

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>สร้างใบสั่งจ่าย</TextHeader>
        </Col>

        <Col>
          <StyledButton
            disabled={allFieldValues.paymentList.length === 0}
            icon={<CheckCircleOutlined />}
            color="green"
            onClick={onSubmitCreatePaymentOrder}
          >
            สร้างใบสั่งจ่าย
          </StyledButton>

          <ModalConfirmCreatePaymentOrder
            visible={isOpenModalConfirmCreatePaymentOrder}
            onCancel={() => setOpenModalConfirmCreatePaymentOrder(false)}
          />
          <ModalCheckVendorInvoice
            visible={isOpenModalCheckVendorInvoice}
            onCancel={() => setOpenModalCheckVendorInvoice(false)}
          />

          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
