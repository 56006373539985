import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledTabs from './Styles';

const propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      clicked: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

const tabs = (props) => {
  const { menu } = props;
  const menuList = menu.map((item) => (
    <li
      key={item.name}
      className={classNames({ active: item.active })}
      onMouseUp={item.clicked}
      role="tab"
    >
      <FontAwesomeIcon icon={[item.active ? 'fas' : 'fal', item.icon]} />
      {item.name}
    </li>
  ));

  return (
    <StyledTabs {...props}>
      <ul role="menu">{menuList}</ul>
    </StyledTabs>
  );
};

tabs.propTypes = propTypes;

export default tabs;
