import gql from 'graphql-tag';

export interface CheckVendorInvoiceIDVars {
  vendor_invoice_id?: string | null;
}

export interface CheckVendorInvoiceIDData {
  checkVendorInvoiceID: {
    _id: string;
    payment_number: string;
  } | null;
}

export default gql`
  query CheckVendorInvoiceID($vendor_invoice_id: String) {
    checkVendorInvoiceID(vendor_invoice_id: $vendor_invoice_id) {
      _id
      payment_number
    }
  }
`;
