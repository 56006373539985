import gql from 'graphql-tag';

export interface Banks {
  _id: string;
  bank_code: string;
  bank_name_th: string;
  bank_name_en: string;
}

export interface BanksData {
  banks: {
    banks: Banks[];
    totalDocument: number;
  };
}

export interface BanksVars {
  _id?: string;
  bank_name_th?: string;
  showData?: number;
}

export default gql`
  query Banks($_id: ID, $bank_name_th: String, $showData: Int) {
    banks(params: { _id: $_id, bank_name_th: $bank_name_th }, showData: $showData) {
      banks {
        _id
        bank_code
        bank_name_th
        bank_name_en
      }
    }
  }
`;
