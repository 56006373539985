import styled from 'styled-components';

const StyledLogo = styled.a`
  display: block;
  width: 130px;
  height: auto;
  margin: 20px auto 10px;

  ${(props) =>
    props.size === 'small' &&
    `
    width: 35px;
  `}

  & svg {
    width: 100%;
    height: 100%;
  }
`;

export default StyledLogo;
