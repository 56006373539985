/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode, useEffect } from 'react';
import { Select, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import BANKS_QUERY, { BanksData, BanksVars, Banks } from 'shared/graphql/query/banks';

const { Option } = Select;

type ValueType = {
  bankId: string;
  bankCode: string;
  bankName: string;
};

type SelectBanksFieldProps = {
  value?: string;
  onChange?: (value: ValueType) => void;
};

const SelectBanksField: React.FC<SelectBanksFieldProps> = (props) => {
  const { value, onChange } = props;
  const [fullName, setFullName] = useState<string>('');
  const [banks, setBanks] = useState<Banks[]>([]);
  const { data, loading, error } = useQuery<BanksData, BanksVars>(BANKS_QUERY, {
    variables: {
      bank_name_th: fullName,
      showData: 200,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && data?.banks) {
      setBanks([...data.banks.banks]);
    }
  }, [data?.banks, loading, error]);

  const options: ReactNode[] = banks.map((item) => (
    <Option key={item._id} value={item._id}>
      {item.bank_name_th}
    </Option>
  ));

  const selectedBank = (valueInput: string) => {
    const bankFilter = banks.filter((item) => item._id === valueInput);
    if (onChange && bankFilter.length === 1) {
      const { _id, bank_code, bank_name_th } = bankFilter[0];
      onChange({
        bankId: _id,
        bankCode: bank_code,
        bankName: bank_name_th,
      });
    }
  };

  return (
    <Select
      style={{
        width: '100%',
      }}
      value={value || undefined}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      showSearch
      placeholder="ค้นหา"
      onSearch={(valueSearch) => setFullName(valueSearch)}
      onChange={(valueChange) => {
        if (onChange) {
          selectedBank(valueChange);
          setFullName('');
        }
      }}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};

export default SelectBanksField;
