import styled from 'styled-components';
import { Modal, Button } from 'antd';

export const StyledModal = styled(Modal)`
  & .ant-modal-header {
    padding: 10px 10px 10px 20px;
  }

  &.custom-modal.ant-modal {
    width: 100% !important;
    max-width: 660px;
  }

  & .ant-modal-body {
    padding: 20px;

    & p {
      margin: 0;
    }
  }

  & .ant-modal-body p {
    margin: 0;
  }
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
`;

export const FileContainer = styled.div`
  border: 1px solid #e3e3e3;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
`;
