/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Form, Divider, Checkbox, Select } from 'antd';
import { Text, SelectSystemRequestField } from 'shared/components';
import useProvince from 'shared/hook/useProvince';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import {
  FormValues,
  Email,
} from 'views/Setting/Routes/Creditor/Company/CompanyDetail/model/formValues.model';
import { TextHeader, StyledTag } from './Styles';

const { Option } = Select;

const Forms: React.FC = () => {
  const {
    errors,
    control,
    watch,
    setValue,
    register,
    trigger,
    setError,
  } = useFormContext<FormValues>();
  const watchProvinceField = watch('province');
  const watchDistrictField = watch('district');
  const watchCountryField = watch('country');
  const watchDocProvinceField = watch('docProvince');
  const watchDocDistrictField = watch('docDistrict');
  const watchChkSelectAddressField = watch('chkSelectAddress');
  const [inputAddEmail, setInputAddEmail] = useState<string>('');

  const { provinces, districts, subDistricts, setProvince, setDistrict } = useProvince();
  const {
    provinces: docProvinces,
    districts: docDistricts,
    subDistricts: docSubDistricts,
    setProvince: setDocProvince,
    setDistrict: setDocDistrict,
  } = useProvince();

  const { fields: fieldsEmail, append, remove } = useFieldArray<Email>({
    control,
    name: 'email',
  });

  useEffect(() => {
    if (watchProvinceField) {
      const findProvince = provinces.find((province) => province.name === watchProvinceField);
      if (findProvince) {
        setProvince(findProvince.id);
      }
    }
  }, [provinces, setProvince, watchProvinceField]);

  useEffect(() => {
    if (watchDistrictField) {
      const findDistrict = districts.find((district) => district.name === watchDistrictField);
      if (findDistrict) {
        setDistrict(findDistrict.id);
      }
    }
  }, [districts, setDistrict, watchDistrictField]);

  useEffect(() => {
    if (watchDocProvinceField) {
      const findProvince = docProvinces.find((province) => province.name === watchDocProvinceField);
      if (findProvince) {
        setDocProvince(findProvince.id);
      }
    }
  }, [docProvinces, setDocProvince, watchDocProvinceField]);

  useEffect(() => {
    if (watchDocDistrictField) {
      const findDistrict = docDistricts.find((district) => district.name === watchDocDistrictField);
      if (findDistrict) {
        setDocDistrict(findDistrict.id);
      }
    }
  }, [docDistricts, setDocDistrict, watchDocDistrictField]);

  useEffect(() => {
    if (fieldsEmail.length) trigger('email');
  }, [fieldsEmail.length, trigger]);

  const checkUniqueEmail = (emails: string[]) => {
    return new Set(emails).size === emails.length;
  };

  const checkValidEmail = (email: string) => {
    const validateEmail = /^(([a-zA-Z0-9]+([\_\.][a-zA-Z0-9]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
    if (validateEmail.test(email)) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <TextHeader>ข้อมูลบริษัท</TextHeader>
        </Col>
      </Row>

      <Row>
        <Col span={20}>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              ชื่อบริษัท:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.fullName && 'error'}
                help={errors.fullName ? errors.fullName.message : undefined}
              >
                <Controller as={Input} control={control} name="fullName" placeholder="ระบุ" />
              </Form.Item>
            </Col>
            <Col span={6}>
              ประเภทงาน:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.systemRequest && 'error'}
                help={errors.systemRequest ? errors.systemRequest.message : undefined}
              >
                <Controller
                  name="systemRequest"
                  control={control}
                  render={({ onChange, value }) => (
                    <SelectSystemRequestField
                      value={value}
                      onChange={(valueChange) => {
                        onChange(valueChange);
                      }}
                    />
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              เลขประจำตัวผู้เสียภาษี:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.taxId && 'error'}
                help={errors.taxId ? errors.taxId.message : undefined}
              >
                <Controller as={Input} control={control} name="taxId" placeholder="0000000000000" />
              </Form.Item>
            </Col>

            <Col span={12}>
              ที่อยู่บริษัท:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.address && 'error'}
                help={errors.address ? errors.address.message : undefined}
              >
                <Controller as={Input} control={control} name="address" placeholder="ระบุ" />
              </Form.Item>
            </Col>
            <Col span={6}>
              สาขา:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.branch && 'error'}
                help={errors.branch ? errors.branch.message : undefined}
              >
                <Controller
                  name="branch"
                  control={control}
                  render={({ onChange, value, onBlur }) => (
                    <Select
                      value={value}
                      showSearch
                      filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(valueChange) => {
                        onChange(valueChange);
                      }}
                      onBlur={() => onBlur()}
                    >
                      <Option value="">เลือกสาขา</Option>
                      <Option value="สำนักงานใหญ่">สำนักงานใหญ่</Option>
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              ประเทศ:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.country && 'error'}
                help={errors.country ? errors.country.message : undefined}
              >
                <Controller
                  name="country"
                  control={control}
                  render={({ onChange, value, onBlur }) => (
                    <Select
                      value={value}
                      showSearch
                      filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(valueChange) => {
                        onChange(valueChange);
                        setValue('province', '');
                        setValue('district', '');
                        setValue('subDistrict', '');
                      }}
                      onBlur={() => onBlur()}
                    >
                      <Option value="">เลือกประเทศ</Option>
                      <Option value="ไทย">ไทย</Option>
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              จังหวัด:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.province && 'error'}
                help={errors.province ? errors.province.message : undefined}
              >
                <Controller
                  name="province"
                  control={control}
                  render={({ onChange, value, onBlur }) => (
                    <Select
                      value={value}
                      showSearch
                      filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(valueChange) => {
                        onChange(valueChange);
                        setValue('district', '');
                        setValue('subDistrict', '');
                      }}
                      onBlur={() => onBlur()}
                      disabled={!watchCountryField}
                    >
                      <Option value="">เลือกจังหวัด</Option>
                      {provinces.map((item) => (
                        <Option key={`${item.id}${item.name}`} value={`${item.name}`}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              เขต/อำเภอ:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.district && 'error'}
                help={errors.district ? errors.district.message : undefined}
              >
                <Controller
                  name="district"
                  control={control}
                  render={({ onChange, value, onBlur }) => (
                    <Select
                      value={value}
                      showSearch
                      filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(valueChange) => {
                        onChange(valueChange);
                        setValue('subDistrict', '');
                      }}
                      onBlur={() => onBlur()}
                      disabled={!watchProvinceField}
                    >
                      <Option value="">เลือกเขต/อำเภอ</Option>
                      {districts.map((item) => (
                        <Option key={`${item.id}${item.name}`} value={`${item.name}`}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              แขวง/ตำบล:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.subDistrict && 'error'}
                help={errors.subDistrict ? errors.subDistrict.message : undefined}
              >
                <Controller
                  name="subDistrict"
                  control={control}
                  render={({ onChange, value, onBlur }) => (
                    <Select
                      value={value}
                      showSearch
                      filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(valueChange) => onChange(valueChange)}
                      onBlur={() => onBlur()}
                      disabled={!watchDistrictField}
                    >
                      <Option value="">เลือกแขวง/ตำบล</Option>
                      {subDistricts.map((item) => (
                        <Option key={`${item.id}${item.name}`} value={`${item.name}`}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              รหัสไปรษณีย์:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.postCode && 'error'}
                help={errors.postCode ? errors.postCode.message : undefined}
              >
                <Controller as={Input} control={control} name="postCode" placeholder="12345" />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row>
            <Col span={24}>
              <TextHeader>ข้อมูลการติดต่อ</TextHeader>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            <Col span={6}>
              เบอร์โทรศัพท์:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.phone && 'error'}
                help={errors.phone ? errors.phone.message : undefined}
              >
                <Controller as={Input} control={control} name="phone" placeholder="08xxxxxxxx" />
              </Form.Item>
            </Col>
            <Col span={6}>
              เบอร์เจ้าหน้าที่ประสานงาน:
              <Form.Item
                validateStatus={errors.staffPhone && 'error'}
                help={errors.staffPhone ? errors.staffPhone.message : undefined}
              >
                <Controller
                  as={Input}
                  control={control}
                  name="staffPhone"
                  placeholder="08xxxxxxxx"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              อีเมล <Text customType="grey">เพื่อใช้ในการรับเอกสาร (กด Enter เพื่อเพิ่มอีเมล)</Text>
              :<Text customType="danger">*</Text>
              <Form.Item validateStatus={errors.email && 'error'} style={{ marginBottom: 15 }}>
                <Input
                  value={inputAddEmail}
                  onChange={(event) => setInputAddEmail(event.target.value)}
                  placeholder="example@email.com"
                  onPressEnter={(event) => {
                    const valueEmail = event.currentTarget.value;
                    if (valueEmail) {
                      const emails = [
                        ...fieldsEmail.map((item) => item.text as string),
                        valueEmail,
                      ];

                      if (!checkValidEmail(valueEmail)) {
                        setError('email', {
                          type: 'qnique',
                          message: 'รูปแบบอีเมลไม่ถูกต้อง',
                        });
                        return;
                      }

                      if (!checkUniqueEmail(emails)) {
                        setError('email', {
                          type: 'qnique',
                          message: 'อีเมลต้องไม่ซ้ำกัน',
                        });
                        return;
                      }

                      append({ text: valueEmail });
                      setInputAddEmail('');
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              {fieldsEmail.map((item, index) => (
                <span key={item.id}>
                  <input
                    type="hidden"
                    value={item.text}
                    name={`email[${index}].text`}
                    ref={register()}
                  />

                  <StyledTag visible closable onClose={() => remove(index)}>
                    {item.text}
                  </StyledTag>
                </span>
              ))}
            </Col>
          </Row>

          <Divider />

          <Row>
            <Col span={24}>
              <TextHeader>ข้อมูลที่อยู่สำหรับเอกสารทางบัญชี</TextHeader>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Controller
                control={control}
                name="chkSelectAddress"
                render={({ onChange, value }) => (
                  <Checkbox
                    value={value}
                    checked={value}
                    onChange={(event) => {
                      onChange(event.target.checked);
                    }}
                  >
                    ใช้ที่อยู่เดียวกับที่อยู่บริษัท
                  </Checkbox>
                )}
              />
            </Col>
          </Row>

          <Row
            gutter={[16, 0]}
            style={{ display: watchChkSelectAddressField ? 'none' : undefined }}
          >
            <Col span={12}>
              ที่อยู่บริษัท:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.docAddress && 'error'}
                help={errors.docAddress ? errors.docAddress.message : undefined}
              >
                <Controller as={Input} control={control} name="docAddress" placeholder="ระบุ" />
              </Form.Item>
            </Col>
            <Col span={12}>
              เลขประจำตัวผู้เสียภาษี:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.docTaxId && 'error'}
                help={errors.docTaxId ? errors.docTaxId.message : undefined}
              >
                <Controller
                  as={Input}
                  control={control}
                  name="docTaxId"
                  placeholder="0000000000000"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              จังหวัด:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.docProvince && 'error'}
                help={errors.docProvince ? errors.docProvince.message : undefined}
              >
                <Controller
                  name="docProvince"
                  control={control}
                  render={({ onChange, value, onBlur }) => (
                    <Select
                      value={value}
                      showSearch
                      filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(valueChange) => {
                        onChange(valueChange);
                        setValue('docDistrict', '');
                        setValue('docSubDistrict', '');
                      }}
                      onBlur={() => onBlur()}
                    >
                      <Option value="">เลือกจังหวัด</Option>
                      {docProvinces.map((item) => (
                        <Option key={`${item.id}${item.name}`} value={`${item.name}`}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              เขต/อำเภอ:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.docDistrict && 'error'}
                help={errors.docDistrict ? errors.docDistrict.message : undefined}
              >
                <Controller
                  name="docDistrict"
                  control={control}
                  render={({ onChange, value, onBlur }) => (
                    <Select
                      value={value}
                      showSearch
                      filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(valueChange) => {
                        onChange(valueChange);
                        setValue('docSubDistrict', '');
                      }}
                      onBlur={() => onBlur()}
                      disabled={!watchDocProvinceField}
                    >
                      <Option value="">เลือกเขต/อำเภอ</Option>
                      {docDistricts.map((item) => (
                        <Option key={`${item.id}${item.name}`} value={`${item.name}`}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              แขวง/ตำบล:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.docSubDistrict && 'error'}
                help={errors.docSubDistrict ? errors.docSubDistrict.message : undefined}
              >
                <Controller
                  name="docSubDistrict"
                  control={control}
                  render={({ onChange, value, onBlur }) => (
                    <Select
                      value={value}
                      showSearch
                      filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(valueChange) => onChange(valueChange)}
                      onBlur={() => onBlur()}
                      disabled={!watchDocDistrictField}
                    >
                      <Option value="">เลือกแขวง/ตำบล</Option>
                      {docSubDistricts.map((item) => (
                        <Option key={`${item.id}${item.name}`} value={`${item.name}`}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              รหัสไปรษณีย์:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.docPostcode && 'error'}
                help={errors.docPostcode ? errors.docPostcode.message : undefined}
              >
                <Controller as={Input} control={control} name="docPostcode" placeholder="12345" />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row>
            <Col span={24}>
              <TextHeader>ข้อมูลบัญชีธนาคาร</TextHeader>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            <Col span={6}>
              ชื่อธนาคาร:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.bankName && 'error'}
                help={errors.bankName ? errors.bankName.message : undefined}
              >
                <Controller as={Input} control={control} name="bankName" placeholder="ระบุ" />
              </Form.Item>
            </Col>
            <Col span={6}>
              สาขา:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.bankBranch && 'error'}
                help={errors.bankBranch ? errors.bankBranch.message : undefined}
              >
                <Controller as={Input} control={control} name="bankBranch" placeholder="ระบุ" />
              </Form.Item>
            </Col>
            <Col span={6}>
              ชื่อบัญชี:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.accountName && 'error'}
                help={errors.accountName ? errors.accountName.message : undefined}
              >
                <Controller as={Input} control={control} name="accountName" placeholder="ระบุ" />
              </Form.Item>
            </Col>
            <Col span={6}>
              เลขที่บัญชี:<Text customType="danger">*</Text>
              <Form.Item
                validateStatus={errors.accountNumber && 'error'}
                help={errors.accountNumber ? errors.accountNumber.message : undefined}
              >
                <Controller
                  as={Input}
                  control={control}
                  name="accountNumber"
                  placeholder="1234567890"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Forms;
