/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, useContext, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { DeleteOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDateTime } from 'shared/utils/dateTime';
import { Button, Text, Spin } from 'shared/components';
import COD_OUT_PARCEL_QUERY, {
  CodOutParcelData,
  CodOutParcelVars,
} from 'shared/graphql/query/codOutParcel';
import { v4 as uuidv4 } from 'uuid';
import { PaymentCodContext } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/context/PaymentCodContext';
import { CodOutStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codOutStatus.model';
import { StyledTable } from './Styles';
import ModalAddCod from './ModalAddCod';
import ModalConfirmDelete from './ModalConfirmDelete';

type PaymentCodDataTable = {
  key: string;
  tools: ReactNode;
  column1: string;
  column2: ReactNode;
  column3: string;
  column4: string;
  column5: string;
};

const columns = [
  {
    title: 'เครื่องมือ',
    dataIndex: 'tools',
    width: 80,
  },
  {
    title: 'รหัส COD-IN',
    dataIndex: 'column1',
    width: 180,
  },
  {
    title: 'ยอดรวม COD (บาท)',
    dataIndex: 'column2',
    align: 'right',
    width: 150,
  },
  {
    title: 'Tracking Number',
    dataIndex: 'column3',
    width: 180,
  },
  {
    title: 'วันที่แจ้งโอน',
    dataIndex: 'column4',
    width: 180,
  },
  {
    title: 'วันที่อัพเดต',
    dataIndex: 'column5',
    width: 180,
  },
  {
    title: '',
  },
];

const PaymentCodDetailTable: React.FC = () => {
  const { selectedPaymentCodDetail } = useContext(PaymentCodContext);
  const { paymentCodId } = selectedPaymentCodDetail;
  const [isOpenModalAddCod, setOpenModalAddCod] = useState<boolean>(false);
  const [isOpenModalConfirmDelete, setOpenModalConfirmDelete] = useState<boolean>(false);
  const [codItemDelete, setCodItemDelete] = useState<string>('');

  const { error, data, loading } = useQuery<CodOutParcelData, CodOutParcelVars>(
    COD_OUT_PARCEL_QUERY,
    {
      variables: {
        _id: paymentCodId,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  let content: ReactNode = <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  let paymentCodData: PaymentCodDataTable[] = [];
  if (data?.codOutParcel) {
    const { parcel_orders, cod_out_status } = data.codOutParcel;
    paymentCodData = parcel_orders.map(
      (item): PaymentCodDataTable => {
        return {
          key: uuidv4(),
          tools: (
            <Button
              disabled={parcel_orders.length === 1 || cod_out_status === CodOutStatus.PAID}
              title="ลบ"
              color="red--outline"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => {
                setOpenModalConfirmDelete(true);
                setCodItemDelete(item._id);
              }}
            />
          ),
          column1: item.cod_in_number || '-',
          column2: (
            <Text customType="primary" strong>
              {item.cod_price ? formatCurrency(item.cod_price) : '-'}
            </Text>
          ),
          column3: item.tracking_number || '-',
          column4: item.cod_in_date ? formatDateTime(item.cod_in_date) : '-',
          column5: item.updatedAt ? formatDateTime(item.updatedAt) : '-',
        };
      },
    );

    content = (
      <>
        <StyledTable
          pagination={false}
          columns={columns}
          dataSource={paymentCodData}
          size="small"
          scroll={{ y: 300 }}
          loading={loading}
        />
        <Row justify="space-between" style={{ marginTop: 10 }}>
          <Col>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button
                  color="blue--light"
                  icon={<PlusCircleOutlined />}
                  onClick={() => setOpenModalAddCod(true)}
                  disabled={cod_out_status === CodOutStatus.PAID}
                >
                  เพิ่มรายการ
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <ModalAddCod
          visible={isOpenModalAddCod}
          onCancel={() => setOpenModalAddCod(false)}
          cods={parcel_orders.map((codItem) => ({
            id: codItem._id,
          }))}
        />
        <ModalConfirmDelete
          visible={isOpenModalConfirmDelete}
          onCancel={() => setOpenModalConfirmDelete(false)}
          cods={parcel_orders.map((codItem) => ({
            id: codItem._id,
          }))}
          codItemDelete={codItemDelete}
        />
      </>
    );
  }

  if (error) {
    content = error.message;
  }

  return <div>{content}</div>;
};

export default PaymentCodDetailTable;
