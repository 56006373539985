import gql from 'graphql-tag';
import { BillingNotesStatus } from 'views/AccountReceive/common/model/billingNoteStatus.model';
import { InvoiceStatus } from 'views/AccountReceive/common/model/invoiceStatus.model';

export interface Costing {
  _id: string;
  costing_number: string;
  checked_date: string;
  completed_date: string;
}

export interface InvoiceDetail {
  costing: Costing;
  number_ref: string;
  ref_id: string;
  service_date: string;
  grand_total_price: number;
}

export interface Invoice {
  _id: string;
  create_date: string;
  service_date: string;
  invoice_number: string;
  grand_total_price: number;
  receipt_date: string;
  invoice_detail: InvoiceDetail[];
  due_date: string;
  invoice_status: InvoiceStatus;
}

export interface BillInvoice {
  invoice: Invoice;
}

export interface Payer {
  _id: string;
  full_name: string;
  number: string;
  email: string[];
  phone: string[];
  tax_id: string;
  full_address: string;
}

export interface BillingNote {
  _id: string;
  bill_status: BillingNotesStatus;
  business_invoice_type: string;
  bill_number: string;
  system_request: string;
  bill_invoice: BillInvoice[];
  payer: Payer;
  create_date: string;
  service_date: string;
  grand_total_price: number;
  paid: number;
  unpaid: number;
  document: string;
  cancel_remark: string;
}

export interface BillingNoteData {
  billingNote: BillingNote;
}

export interface BillingNoteVars {
  _id: string;
}

export default gql`
  query BillingNote($_id: ID!) {
    billingNote(_id: $_id) {
      _id
      bill_status
      business_invoice_type
      bill_number
      system_request
      bill_invoice {
        invoice {
          _id
          create_date
          service_date
          invoice_number
          grand_total_price
          receipt_date
          invoice_status
          invoice_detail {
            costing {
              _id
              costing_number
              checked_date
              completed_date
            }
            number_ref
            ref_id
            service_date
            grand_total_price
          }
          due_date
        }
      }
      payer {
        _id
        full_name
        number
        email
        phone
        tax_id
        full_address
      }
      create_date
      service_date
      grand_total_price
      document
      cancel_remark
    }
  }
`;
