/* eslint-disable no-underscore-dangle */
import React, { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';

import { Spin } from 'shared/components';
import updateObject from 'shared/utils/updateObject';
import { PaymentOrderContext } from 'views/AccountPayment/common/context/PaymentOrderContext';
import Header from './Header';
import PaymentDetail from './PaymentDetail';
import ModalConfirmCreateVoucher from './ModalConfirmCreateVoucher';

const informationSchema = yup.object().shape({
  payments: yup.array().required('กรุณาเลือกรายการใบสั่งจ่าย'),
  paymentMethod: yup.string().required('กรุณาเลือกช่องทางการจ่าย'),
  payeeId: yup.string().required('กรุณาเลือกผู้รับเงิน'),
  dueDate: yup.string().required('กรุณาเลือกวันที่เอกสาร'),
  accountNumber: yup.string().required('กรุณาเลือกบัญชีที่ต้องการโอน'),
  voucherRemark: yup.string().nullable(),
});

const initialValues = {
  paymentMethod: '',
  payments: [],
  payeeId: '',
  payeeFullName: '',
  dueDate: '',
  voucherRemark: '',
  bankName: '',
  bankBranch: '',
  accountName: '',
  accountNumber: '',
  paymentType: '',
};

const PaymentOrderSelected = () => {
  const [initialForm, setInitialForm] = useState(initialValues);
  const [isOpenModalConfirmCreateVoucher, setOpenModalConfirmCreateVoucher] = useState(false);
  const { selectedPayments: selectedPaymentsContext } = useContext(PaymentOrderContext);
  const [selectedPayments, setSelectedPayments] = selectedPaymentsContext;
  const { payments, payeeId, dueDate, isLoading, payeeFullName, paymentType } = selectedPayments;

  useEffect(() => {
    if (selectedPayments.isOpenModal) {
      const formData = {
        payeeId,
        payments,
        dueDate,
        payeeFullName,
        paymentType,
      };

      setInitialForm((prevState) => updateObject(prevState, formData));
    }

    return () => {
      if (!selectedPayments.isOpenModal) {
        setSelectedPayments((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
      }
    };
  }, [selectedPayments.isOpenModal]);

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={informationSchema}
      enableReinitialize
      onSubmit={() => {
        setOpenModalConfirmCreateVoucher(true);
      }}
    >
      {() => {
        return (
          <>
            <Spin spinning={isLoading}>
              <Header />
              <PaymentDetail />
            </Spin>

            <ModalConfirmCreateVoucher
              visible={isOpenModalConfirmCreateVoucher}
              onCancel={() => setOpenModalConfirmCreateVoucher(false)}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default PaymentOrderSelected;
