/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  PaymentCodContext,
  SelectedPaymentCodDetailType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/context/PaymentCodContext';
import { Spin } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { message } from 'antd';
import COD_OUT_PARCEL_QUERY, {
  CodOutParcelData,
  CodOutParcelVars,
} from 'shared/graphql/query/codOutParcel';
import { CodOutStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codOutStatus.model';
import Header from './Header';
import { StyledModal } from './Styles';
import PaymentCodDetail from './PaymentCodDetail';
import { FormValues } from './model/formValues.model';

const ModalPaymentCodDetailWrapper: React.FC = () => {
  const { selectedPaymentCodDetail, setSelectedPaymentCodDetail } = useContext(PaymentCodContext);
  const { isOpenModal } = selectedPaymentCodDetail;

  return (
    <StyledModal
      visible={isOpenModal}
      onCancel={() => {
        setSelectedPaymentCodDetail(
          (prevState): SelectedPaymentCodDetailType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalPaymentCodDetail />
    </StyledModal>
  );
};

const validateSchema = yup.object().shape({
  paymentCodId: yup.string().required(),
  paymentMethod: yup.string().required(),
  bankName: yup.string().required(),
  bankBranch: yup.string(),
  accountName: yup.string().required(),
  accountNumber: yup.string().required(),
  paidDate: yup.string().when('isBankBBL', {
    is: (value) => value === false,
    then: yup.string().required(),
  }),
  bankRef: yup.string().when('isBankBBL', {
    is: (value) => value === false,
    then: yup.string().required(),
  }),
  proofSlip: yup.string().when('isBankBBL', {
    is: (value) => value === false,
    then: yup.string().required(),
  }),
  slipRemark: yup.string(),
  isBankBBL: yup.boolean(),
  approver: yup.string().when('isBankBBL', {
    is: (value) => value === true,
    then: yup.string().required(),
  }),
  approverPhoneNumber: yup.string().when('isBankBBL', {
    is: (value) => value === true,
    then: yup.string().required(),
  }),
  otp: yup.string().when('isBankBBL', {
    is: (value) => value === true,
    then: yup
      .string()
      .matches(/^\d{6}$/, 'รูปแบบไม่ถูกต้อง')
      .required(),
  }),
});

const ModalPaymentCodDetail: React.FC = () => {
  const { selectedPaymentCodDetail, setSelectedPaymentCodDetail } = useContext(PaymentCodContext);
  const { isLoading, paymentCodId } = selectedPaymentCodDetail;
  const { data, loading, error } = useQuery<CodOutParcelData, CodOutParcelVars>(
    COD_OUT_PARCEL_QUERY,
    {
      variables: {
        _id: paymentCodId,
      },
      onError: (err) => {
        message.error(err.message);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validateSchema),
    shouldUnregister: false,
    defaultValues: {
      paymentCodId: '',
      paymentMethod: '',
      bankName: '',
      bankBranch: '',
      accountName: '',
      accountNumber: '',
      proofSlip: '',
      paidDate: '',
      slipRemark: '',
      bankRef: '',
      isBankBBL: false,
      approver: '',
      approverPhoneNumber: '',
      verifyUri: '',
      otp: '',
    },
  });

  const { register, reset, watch, setValue } = methods;
  const watchIsBankBBL = watch('isBankBBL');
  const watchBankName = watch('bankName');
  const watchBankBranch = watch('bankBranch');
  const watchAccountName = watch('accountName');
  const watchAccountNumber = watch('accountNumber');

  useEffect(() => {
    if (watchBankName === 'กรุงเทพ') {
      setValue('isBankBBL', true);
    } else {
      setValue('isBankBBL', false);
    }
  }, [setValue, watchBankName]);

  useEffect(() => {
    setSelectedPaymentCodDetail(
      (prevState): SelectedPaymentCodDetailType => ({
        ...prevState,
        isLoading: loading,
      }),
    );

    if (!loading && !error && data?.codOutParcel) {
      const {
        _id,
        cod_out_status,
        cod_out_number,
        customer,
        total_cod,
        bank_account,
        payment_method,
      } = data.codOutParcel;

      setSelectedPaymentCodDetail(
        (prevState): SelectedPaymentCodDetailType => ({
          ...prevState,
          paymentCodStatus: cod_out_status,
          paymentCodNumber: cod_out_number,
          customerId: customer._id,
          customerFullName: customer.full_name,
          totalCod: total_cod,
          customerBankAccountNumber: customer?.customer_bank_account?.account_number,
        }),
      );

      const defaultData =
        cod_out_status === CodOutStatus.PAID
          ? {
              bankName: bank_account.bank_name,
              bankBranch: bank_account.bank_branch,
              accountNumber: bank_account.account_number,
              accountName: bank_account.account_name,
              paymentMethod: payment_method,
            }
          : {};

      setTimeout(() => {
        reset({
          paymentCodId: _id,
          isBankBBL: watchIsBankBBL,
          bankName: watchBankName,
          bankBranch: watchBankBranch,
          accountName: watchAccountName,
          accountNumber: watchAccountNumber,
          ...defaultData,
        });
      });
    }

    return () => {
      setSelectedPaymentCodDetail(
        (prevState): SelectedPaymentCodDetailType => ({
          ...prevState,
          isLoading: true,
        }),
      );
    };
  }, [data?.codOutParcel, error, loading, reset, setSelectedPaymentCodDetail]);

  useEffect(() => {
    register('paymentCodId');
    register('bankName');
    register('bankBranch');
    register('accountName');
    register('proofSlip');
  }, [register]);

  return (
    <Spin spinning={isLoading}>
      <FormProvider {...methods}>
        <form>
          <Header />
          <PaymentCodDetail />
        </form>
      </FormProvider>
    </Spin>
  );
};

export default ModalPaymentCodDetailWrapper;
