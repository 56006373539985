import gql from 'graphql-tag';

export interface EditFollowDebtHistoryData {
  editFollowDebtHistory: {
    _id: string;
  };
}

export interface EditFollowDebtHistoryVars {
  _id: string;
  followDetail: string;
  followStatus: string;
  followStatusDetail: string;
  action: string;
  actionDetail: string;
  nextfollowDate: string;
  detail: string;
  employee: string;
  status: number;
}

export default gql`
  mutation EditFollowDebtHistory(
    $_id: ID!
    $followDetail: ID!
    $followStatus: String
    $followStatusDetail: String
    $action: String
    $actionDetail: String
    $nextfollowDate: String
    $detail: String
    $employee: ID
    $status: Int
  ) {
    editFollowDebtHistory(
      _id: $_id
      followDetail: $followDetail
      update: {
        followStatus: $followStatus
        followStatusDetail: $followStatusDetail
        action: $action
        actionDetail: $actionDetail
        nextfollowDate: $nextfollowDate
        detail: $detail
        employee: $employee
        status: $status
      }
    ) {
      _id
    }
  }
`;
