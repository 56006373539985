import React from 'react';
import { SubTabs } from 'shared/components';

interface CompanyTabsProps {
  name: string;
  tab: number;
  onChange: (tab: number) => void;
}

const CompanyTabs: React.FC<CompanyTabsProps> = (props) => {
  const { tab, onChange, name } = props;

  const checkSelectTab = (id: number) => {
    return id === tab && name === 'vendor';
  };

  const selectTab = (index: number) => {
    onChange(index);
  };

  const tabs = [
    {
      id: 1,
      name: 'ตารางรายการบัญชีเจ้าหนี้-บริษัท',
      color: 'badge-grey',
      count: 0,
      active: false,
      divider: false,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default CompanyTabs;
