import React, { useContext, useEffect } from 'react';
import {
  BillingNoteContext,
  SelectedInvoicesType,
} from 'views/AccountReceive/common/context/BillingNoteContext';
import { Spin } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormValues } from 'views/AccountReceive/Routes/BillingNote/ModalCreateBillingNote/formValues.model';
import Header from './Header';
import InvoiceCreateBillingNote from './InvoiceCreateBillingNote';
import { StyledModal } from './Styles';

const ModalCreateBillingNoteWrapper = () => {
  const { setSelectedInvoices, selectedInvoices } = useContext(BillingNoteContext);
  const { isOpenModal } = selectedInvoices;

  return (
    <StyledModal
      visible={isOpenModal}
      onCancel={() => {
        setSelectedInvoices(
          (prevState): SelectedInvoicesType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalCreateBillingNote />
    </StyledModal>
  );
};

const ModalCreateBillingNote: React.FC = () => {
  const { selectedInvoices } = useContext(BillingNoteContext);
  const { isLoading, invoicesId, payerId, payerFullName } = selectedInvoices;
  const validateSchema = yup.object().shape({
    invoicesId: yup.array().required(),
    payerId: yup.string().required(),
    payerFullName: yup.string().required(),
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      invoicesId: [],
      payerId,
      payerFullName,
    },
  });

  const { register, handleSubmit, reset } = methods;

  useEffect(() => {
    register('invoicesId');
    register('payerId');
    register('payerFullName');
  }, [register]);

  useEffect(() => {
    setTimeout(() => {
      reset({
        invoicesId,
        payerId,
        payerFullName,
      });
    }, 0);
  }, [invoicesId, payerFullName, payerId, reset]);

  const onSubmit = () => {
    alert('submit');
  };

  return (
    <Spin spinning={isLoading}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Header />
          <InvoiceCreateBillingNote />
        </form>
      </FormProvider>
    </Spin>
  );
};

export default ModalCreateBillingNoteWrapper;
