/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  ReceiptContext,
  SelectedBillingNoteDetailType,
} from 'views/AccountReceive/common/context/ReceiptContext';
import { Spin } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { message, Steps, Divider } from 'antd';
import BILLING_NOTE_QUERY, {
  BillingNoteData,
  BillingNoteVars,
} from 'shared/graphql/query/billingNote';
import Header from './Header';
import { StyledModal } from './Styles';
import SelectBillInvoiceStep from './SelectBillInvoiceStep';
import PaymentStep from './PaymentStep';
import RecheckStep from './RecheckStep';
import { FormValues } from './model/formValues.model';
import HeaderStep from './HeaderStep';

const { Step } = Steps;

const ModalCreateReceiptWrapper: React.FC = () => {
  const { selectedBillingNoteDetail, setSelectedBillingNoteDetail } = useContext(ReceiptContext);
  const { isOpenModal } = selectedBillingNoteDetail;

  return (
    <StyledModal
      visible={isOpenModal}
      onCancel={() => {
        setSelectedBillingNoteDetail(
          (prevState): SelectedBillingNoteDetailType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      {isOpenModal && <ModalCreateReceipt />}
    </StyledModal>
  );
};

const validateSchema = yup.object().shape({
  billInvoices: yup.array().required(),
  bankName: yup.string().required(),
  accountNumber: yup.string().required(),
  channel: yup.string().required(),
  receiptDate: yup.string().required(),
  wrongBank: yup.boolean().required(),
  proofOfPayment: yup.array().required(),
  receiptPrice: yup.number().positive().required(),
  receiptDiscount: yup.number().positive(),
  bankFee: yup.number().positive(),
  otherExpenses: yup.number().positive(),
  allWht: yup.array().of(
    yup.object().shape(
      {
        wht: yup
          .number()
          .positive()
          .max(100)
          .when('totalWht', {
            is: (value) => value,
            then: yup.number().required(),
          }),
        totalWht: yup
          .number()
          .positive()
          .when('wht', {
            is: (value) => value,
            then: yup.number().required(),
          }),
      },
      [['wht', 'totalWht']],
    ),
  ),
  otherIncome: yup.number().positive(),
  interestIncome: yup.number().positive(),
  totalPaid: yup.number().positive(),
});

type Step = 0 | 1 | 2;

const initialValue = {
  billInvoices: [],
  bankName: '',
  accountNumber: '',
  channel: '',
  receiptDate: '',
  wrongBank: false,
  proofOfPayment: [],
  receiptPrice: undefined,
  receiptDiscount: undefined,
  bankFee: undefined,
  otherExpenses: undefined,
  otherIncome: undefined,
  interestIncome: undefined,
  totalPaid: 0,
  unPaid: 0,
  allWht: [
    {
      wht: undefined,
      totalWht: undefined,
    },
    {
      wht: undefined,
      totalWht: undefined,
    },
    {
      wht: undefined,
      totalWht: undefined,
    },
  ],
  receiptValue: undefined,
};

const ModalCreateReceipt: React.FC = () => {
  const [activeStep, setActiveStep] = useState<Step>(0);
  const { selectedBillingNoteDetail, setSelectedBillingNoteDetail } = useContext(ReceiptContext);
  const { isLoading, billingNoteId } = selectedBillingNoteDetail;
  const { data, loading, error } = useQuery<BillingNoteData, BillingNoteVars>(BILLING_NOTE_QUERY, {
    variables: {
      _id: billingNoteId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const methods = useForm<FormValues>({
    mode: 'onChange',
    shouldFocusError: true,
    shouldUnregister: false,
    resolver: yupResolver(validateSchema),
    defaultValues: initialValue,
  });

  const { register, reset } = methods;

  useEffect(() => {
    setSelectedBillingNoteDetail(
      (prevState): SelectedBillingNoteDetailType => ({
        ...prevState,
        isLoading: loading,
      }),
    );

    if (!loading && !error && data?.billingNote) {
      const {
        bill_number,
        payer,
        bill_status,
        service_date,
        grand_total_price,
        unpaid,
      } = data.billingNote;

      setSelectedBillingNoteDetail(
        (prevState): SelectedBillingNoteDetailType => ({
          ...prevState,
          billNumber: bill_number,
          billStatus: bill_status,
          payerId: payer._id,
          payerFullName: payer.full_name,
          serviceDate: service_date,
          grandTotalPrice: grand_total_price,
          unPaid: unpaid,
        }),
      );
    }

    return () => {
      setSelectedBillingNoteDetail(
        (prevState): SelectedBillingNoteDetailType => ({
          ...prevState,
          isLoading: true,
        }),
      );
    };
  }, [data?.billingNote, error, loading, setSelectedBillingNoteDetail]);

  useEffect(() => {
    register('billInvoices');
  }, [register]);

  const resetForm = () => {
    reset(initialValue);
  };

  const displayStep = (isShow: boolean) => {
    return isShow ? 'block' : 'none';
  };

  return (
    <Spin spinning={isLoading}>
      <FormProvider {...methods}>
        <form>
          <Header
            onReset={() => resetForm()}
            activeStep={activeStep}
            onChangeStep={(value) => setActiveStep(value)}
          />
          <HeaderStep activeStep={activeStep} />
          <Divider style={{ margin: '15px 0px 10px 0px' }} />

          <div style={{ display: displayStep(activeStep === 0) }}>
            <SelectBillInvoiceStep />
          </div>

          {activeStep > 0 && (
            <div style={{ display: displayStep(activeStep === 1) }}>
              <PaymentStep />
            </div>
          )}

          <div style={{ display: displayStep(activeStep === 2) }}>
            <RecheckStep />
          </div>
        </form>
      </FormProvider>
    </Spin>
  );
};

export default ModalCreateReceiptWrapper;
