import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import {
  CodInContext,
  SelectedCodsInType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/CodInContext';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/Routes/CodIn/CodInList/ModalCreateCod/model/formValues.model';
import { StyledButton, TextHeader, Container } from './Styles';
import ModalConfirmCreateCod from './ModalConfirmCreateCod';

const Header: React.FC = () => {
  const { handleSubmit } = useFormContext<FormValues>();
  const [isOpenModalConfirmCreateCod, setOpenModalConfirmCreateCod] = useState<boolean>(false);
  const { selectedCodsIn, setSelectedCodsIn } = useContext(CodInContext);
  const { isLoading } = selectedCodsIn;

  const closeModalHandler = () => {
    setSelectedCodsIn(
      (prevState): SelectedCodsInType => ({
        ...prevState,
        isOpenModal: false,
      }),
    );
  };

  const onSubmitCreateCod = handleSubmit(() => {
    setOpenModalConfirmCreateCod(true);
  });

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>ทำจ่าย COD</TextHeader>
        </Col>

        <Col>
          <StyledButton
            disabled={isLoading}
            icon={<CheckCircleOutlined />}
            color="green"
            onClick={onSubmitCreateCod}
          >
            สร้างการทำจ่าย
          </StyledButton>

          <ModalConfirmCreateCod
            visible={isOpenModalConfirmCreateCod}
            onCancel={() => setOpenModalConfirmCreateCod(false)}
          />

          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
