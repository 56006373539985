/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { PaymentCodContext } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/PaymentCodContext';
import PAY_COD_MUTATION, { PayCODData, PayCODVars } from 'shared/graphql/mutation/payCOD';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/Routes/PaymentCod/PaymentCodListTable/ModalPaymentCodDetail/model/formValues.model';
import { StyledModal } from './Styles';

type ModalConfirmUpdateProps = {
  visible: boolean;
  onCancel: () => void;
  onCompleted: () => void;
};

const ModalConfirmUpdate: React.FC<ModalConfirmUpdateProps> = (props) => {
  const { onCancel, visible, onCompleted } = props;
  const { selectedPaymentCodDetail } = useContext(PaymentCodContext);
  const { totalCod, codOutNumber, paymentCodId } = selectedPaymentCodDetail;
  const { getValues, reset } = useFormContext<FormValues>();
  const allFieldValues = getValues();

  const [payCod, { loading }] = useMutation<PayCODData, PayCODVars>(PAY_COD_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.payCOD._id) {
        message.success('บันทึกการจ่ายรายการ COD แล้ว');
        reset();
        onCompleted();
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['PaymentCodStatus', 'PaymentCods'],
  });

  const confirmUpdate = () => {
    payCod({
      variables: {
        _id: paymentCodId,
        payment_method: allFieldValues.paymentMethod,
        bank_ref: allFieldValues.bankRef,
        proof_slip: allFieldValues.proofSlip,
        paid_date: allFieldValues.paidDate,
        slip_remark: allFieldValues.slipRemark === '' ? undefined : allFieldValues.slipRemark,
        bank_account: {
          bank_name: allFieldValues.bankName,
          bank_branch: allFieldValues.bankBranch || undefined,
          account_name: allFieldValues.accountName,
          account_number: allFieldValues.accountNumber,
        },
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="บันทึกการจ่ายรายการ COD ใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmUpdate()}
          loading={loading}
        >
          ยืนยัน
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>รหัส COD:</Col>
        <Col span={18}>
          <Text strong>{codOutNumber}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>มูลค่ารอบจ่าย (บาท):</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(totalCod, '0')}
          </Text>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmUpdate;
