import formatCurrency from 'shared/utils/formatCurrency';

const formatCurrencyProfitLoss = (value: number): number | string => {
  if (value < 0) {
    return `(${formatCurrency(value * -1)})`;
  }

  return formatCurrency(value);
};

export default formatCurrencyProfitLoss;
