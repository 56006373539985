import React from 'react';
import { Divider, Row, Col, Tabs, Skeleton } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { color } from 'shared/utils/styles';
import { Tag, Text } from 'shared/components';
import InvoiceList from './InvoiceList';

const { TabPane } = Tabs;

const CreateInvoice: React.FC = () => {
  let content = <Skeleton />;

  content = (
    <>
      <Text strong>เงื่อนไขใบแจ้งหนี้</Text>
      <Divider style={{ margin: '10px 0' }} />
      <Row gutter={[32, 16]}>
        <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>
                  <Text customType="danger">*</Text>
                </Col>
                <Col span={24}>
                  {/* {watchFields && (
                    <SelectFieldSearchCustomers
                      value={{
                        key: getValues('customerId'),
                        label: getValues('customerFullName'),
                      }}
                      onChange={(value) => {
                        setValue('customerId', value.value);
                        setValue('customerFullName', value.label);
                      }}
                      // disabled
                    />
                  )} */}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>รูปแบบรับใบแจ้งหนี้:</Col>
                <Col span={24}>
                  {/* <Text strong>1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลทุกสิ้นเดือน</Text> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
          <Row>
            <Col span={10}>ประเภทงาน:</Col>
            <Col span={14}>{/* <Text strong>Giztix Online Truck</Text> */}</Col>
          </Row>
          <Row>
            <Col span={10}>ที่อยู่วางบิล:</Col>
            <Col span={14}>
              {/* <Text strong>
                123/2 หมู่ 7 แขวง/ตำบลในเมือง เขต/อำเภอในเมือง จังหวัดสมุทรปราการ 100001
              </Text> */}
            </Col>
          </Row>
          <Row>
            <Col span={10}>เลขประจำตัวผู้เสียภาษี:</Col>
            <Col span={14}>{/* <Text strong>1235482615498</Text> */}</Col>
          </Row>
          <Row>
            <Col span={10}>เบอร์โทรศัพท์:</Col>
            <Col span={14}>{/* <Text strong>01-2345-6789</Text> */}</Col>
          </Row>
          <Row>
            <Col span={10}>อีเมล:</Col>
            <Col span={14}>{/* <Text strong>user@email.com, user@email.com</Text> */}</Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            <Col span={8}>วันที่เอกสาร:</Col>
            <Col span={16}>
              <Text strong>-</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>รหัสใบวางบิล:</Col>
            <Col span={16}>
              <Text strong>-</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>รวมมูลค่าใบวางบิล:</Col>
            <Col span={16}>
              {/* <Text strong customType="primary">
                {formatCurrency(1302)}
              </Text> */}
            </Col>
          </Row>
          <Row>
            <Col span={8}>สถานะ:</Col>
            <Col span={16}>
              <Tag customColor="blue">กำลังสร้าง...</Tag>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider style={{ margin: 0, marginTop: -5 }} />

      <Tabs defaultActiveKey="1" animated={false}>
        <TabPane
          tab={
            <span>
              รายละเอียดงาน{' '}
              <CheckCircleOutlined
                style={{
                  color: color.success,
                  marginLeft: 5,
                }}
              />
            </span>
          }
          key="1"
        >
          <InvoiceList />
        </TabPane>
      </Tabs>
    </>
  );

  return content;
};

export default CreateInvoice;
