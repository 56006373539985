import gql from 'graphql-tag';

export default gql`
  query CalPaymentVoucher($payment: [ID]!) {
    calPaymentVoucher(payment: $payment) {
      payment {
        _id
        create_date
        payment_number
        round_paid
        employee {
          full_name
        }
        number_ref
        grand_total_price
      }
      re_total_before_discount
      non_total_before_discount
      inc_total_before_discount
      re_total_discount
      non_total_discount
      inc_total_discount
      re_total_after_discount
      non_total_after_discount
      inc_total_after_discount
      total_vat
      re_total
      non_total
      inc_total
      total_price
      grand_total_price
      all_wht {
        wht
        price
        total_wht
      }
    }
  }
`;
