/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { Col, Divider, message, Popover, Row, Skeleton, Tabs } from 'antd';
import Icon, { CheckCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { Tag, Text } from 'shared/components';

import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { color } from 'shared/utils/styles';
import IconFileInvoiceDollar from 'shared/assets/icons/file-invoice-dollar.svg';
import IconHandHoldingUSD from 'shared/assets/icons/hand-holding-usd.svg';
import IconChartLine from 'shared/assets/icons/chart-line.svg';
import IconHourGlassHalf from 'shared/assets/icons/hourglass-half.svg';
import { CostingContext } from 'views/Costing/common/context/CostingContext';
import COSTING_QUERY, { CostingData, CostingVars, PaidType } from 'shared/graphql/query/costing';
import { TextProfitColor } from 'views/Costing/common/components';
import IconHistory from 'shared/assets/icons/history.svg';
import { v4 as uuidv4 } from 'uuid';
import TagStatus from './TagStatus';
import { CardContainer, StyledButtonIcon, StyledCardIcon } from './Styles';
import CostingOtherDocument from './CostingOtherDocument';
import CostingTable from './CostingTable';

const { TabPane } = Tabs;

const CostingDetail: React.FC = () => {
  const { selectedCostingDetail } = useContext(CostingContext);
  const { costingId } = selectedCostingDetail!;

  const { data } = useQuery<CostingData, CostingVars>(COSTING_QUERY, {
    variables: {
      _id: costingId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const contentHistory = (
    value: {
      timestamp: string;
    }[],
  ) => {
    return value.map((item, index) => {
      return (
        <div key={uuidv4()}>
          <Text strong>ครั้งที่ {index + 1}:</Text> ส่งเมื่อ {item.timestamp}
        </div>
      );
    });
  };

  const getPaidTypeText = (paidType: PaidType) => {
    switch (paidType) {
      case PaidType.CREDIT_CARD:
        return 'บัตรเครดิต';
      case PaidType.CASH:
        return 'เงินสด';
      case PaidType.INVOICE:
        return 'ออกใบแจ้งหนี้';
      default:
        return paidType;
    }
  };

  let content = <Skeleton />;
  if (data && data.costing) {
    const {
      costing_status,
      total_after_discount,
      profit,
      payee,
      payer,
      employee,
      costing_remark,
      last_send,
      costing_number,
      reject_remark,
      number_ref,
      send_history,
      edit_request,
      paid_type,
    } = data.costing;

    content = (
      <>
        <CardContainer>
          <StyledCardIcon
            title="ต้นทุนก่อนภาษี / Cost Before VAT"
            text={`${formatCurrency(total_after_discount.cost)} บาท`}
            icon={<Icon component={IconFileInvoiceDollar} />}
          />

          <StyledCardIcon
            title="รายได้ก่อนภาษี / Revenue before VAT"
            text={`${formatCurrency(total_after_discount.sale)} บาท`}
            icon={<Icon component={IconHandHoldingUSD} />}
          />

          <StyledCardIcon
            title="กำไรขั้นต้น / Gross Profit"
            text={<TextProfitColor value={profit} />}
            icon={<Icon component={IconChartLine} />}
          />

          <StyledCardIcon
            title="เวลาในการดำเนินงาน"
            text="-"
            icon={<Icon component={IconHourGlassHalf} />}
          />
        </CardContainer>

        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[32, 16]}>
          <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={8}>
                    ชื่อลูกค้า{' '}
                    <Text customType="grey" strong>
                      (ลูกหนี้)
                    </Text>
                    :
                  </Col>
                  <Col span={16}>
                    <Text strong>{payer.full_name}</Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={8}>
                    ผู้รับเงิน <Text customType="grey">(เจ้าหนี้)</Text>:
                  </Col>
                  <Col span={16}>
                    <Text strong>{payee.full_name}</Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={8}>ช่องทางการชำระเงิน:</Col>
                  <Col span={16}>
                    <Text strong>{getPaidTypeText(paid_type)}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={8}>ผู้ส่งราคา:</Col>
                  <Col span={16}>
                    <Text strong>{employee.full_name}</Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={8}>หมายเหตุ:</Col>
                  <Col span={16}>
                    <Text strong>{costing_remark || '-'}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={8}>
            <Row>
              <Col span={8}>วันที่ส่งรายการ:</Col>
              <Col span={16}>
                <Text strong>
                  {formatDate(last_send)}{' '}
                  {send_history.length > 0 && (
                    <Popover placement="rightTop" content={contentHistory(send_history)}>
                      <StyledButtonIcon
                        icon={<Icon component={IconHistory} />}
                        size="small"
                        shape="circle"
                      />
                    </Popover>
                  )}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รหัสต้นทุน/ขาย:</Col>
              <Col span={16}>
                <Text strong>{costing_number}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รหัสงานขนส่ง:</Col>
              <Col span={16}>
                <Text strong>{number_ref}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>สถานะ:</Col>
              <Col span={16}>
                {edit_request && costing_status === 2 && (
                  <Tag customColor="red--light">ยื่นคำร้อง</Tag>
                )}

                {!edit_request || costing_status === 4 ? (
                  <TagStatus status={costing_status} />
                ) : null}
              </Col>
            </Row>

            {costing_status === 3 && (
              <Row>
                <Col span={8}>เหตุผลปฏิเสธ:</Col>
                <Col span={16}>
                  <Text strong>{reject_remark || '-'}</Text>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Divider style={{ margin: 0, marginTop: -5 }} />

        <Tabs defaultActiveKey="1" animated={false} destroyInactiveTabPane>
          <TabPane
            tab={
              <span>
                รายการจ่าย{' '}
                <CheckCircleOutlined style={{ color: `${color.success}`, marginLeft: 5 }} />
              </span>
            }
            key="1"
          >
            <CostingTable />
          </TabPane>
          <TabPane
            tab={
              <span>
                เอกสารอื่นๆ{' '}
                <CheckCircleOutlined style={{ color: `${color.success}`, marginLeft: 5 }} />
              </span>
            }
            key="2"
          >
            <CostingOtherDocument costingId={costingId} />
          </TabPane>
        </Tabs>
      </>
    );
  }

  return content;
};

export default CostingDetail;
