/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Col, Row, message, Divider } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import UPDATE_APP, { UpdateAppData, UpdateAppVars } from 'shared/graphql/mutation/updateApp';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { formatDate } from 'shared/utils/dateTime';
import { StyledModal } from './Styles';

type ModalConfirmAddPaymentVoucherProps = {
  appId: string;
  paymentVouchers: {
    id: string;
    createDate: string;
    voucherNumber: string;
    roundPaid: string;
    payeeFullName: string;
    grandTotalPrice: number;
  }[];
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmAddPaymentVoucher: React.FC<ModalConfirmAddPaymentVoucherProps> = (props) => {
  const { onCancel, visible, paymentVouchers, appId } = props;
  const [updateApp, { loading }] = useMutation<UpdateAppData, UpdateAppVars>(UPDATE_APP, {
    update: (_cache, { data }) => {
      if (data?.updateApp._id) {
        message.success('เพิ่มรายการใบสำคัญจ่ายแล้ว');
        onCancel();
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['App', 'AppStatus', 'Apps'],
  });

  const confirmUpdate = () => {
    updateApp({
      variables: {
        _id: appId,
        payment_voucher: paymentVouchers.map((paymentVoucher) => paymentVoucher.id),
      },
    });
  };

  const sumGrandTotalPrice = (values: number[]) => {
    return values.reduce((total, value) => {
      return total + value;
    }, 0);
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันการเพิ่มใบสำคัญจ่ายหรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmUpdate()}
          loading={loading}
        >
          เพิ่มใบสำคัญจ่าย
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>มูลค่าใบสำคัญจ่าย (บาท):</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(
              sumGrandTotalPrice(paymentVouchers.map((item) => item.grandTotalPrice)),
            )}
          </Text>
        </Col>
      </Row>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Row>
        <Col span={24}>
          ทั้งหมด <Text strong>{`${paymentVouchers.length} รายการ`}</Text>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 0]}>
            <Col span={4}>วันที่เอกสาร</Col>
            <Col span={5}>รหัสใบสำคัญจ่าย</Col>
            <Col span={5}>รอบจ่าย</Col>
            <Col span={5}>ผู้รับเงิน</Col>
            <Col span={5} style={{ textAlign: 'right' }}>
              จำนวนตั้งเบิก (บาท)
            </Col>
          </Row>

          {paymentVouchers.map((paymentVoucher) => (
            <Row gutter={[8, 0]}>
              <Col span={4}>
                <Text strong>{formatDate(paymentVoucher.createDate)}</Text>
              </Col>
              <Col span={5}>
                <Text strong>{paymentVoucher.voucherNumber}</Text>
              </Col>
              <Col span={5}>
                <Text strong>{paymentVoucher.roundPaid}</Text>
              </Col>
              <Col span={5}>
                <Text strong>{paymentVoucher.payeeFullName}</Text>
              </Col>
              <Col span={5} style={{ textAlign: 'right' }}>
                <Text strong customType="primary">
                  {formatCurrency(paymentVoucher.grandTotalPrice)}
                </Text>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmAddPaymentVoucher;
