import React, { ReactChild, useState } from 'react';
import { ParcelCostingStatus } from 'views/Costing/common/model/parcelCostingStatus.model';

export interface ParcelCostingDetailType {
  costingId: string;
  costingNumber: string | null;
  costingStatus: ParcelCostingStatus | null;
  isLoading: boolean;
  backUrl: string;
  editRequest: boolean;
  modalConfirmChecked: {
    costingNumber: string | null;
    refNumber: string | null;
    totalAfterDiscount: number;
  };
  modalConfirmReject: {
    costingNumber: string | null;
    grandTotalPrice: number;
  };
}

interface ParcelCosting {
  selectedCostingDetail: ParcelCostingDetailType;
  setSelectedCostingDetail: (
    params: (cb: ParcelCostingDetailType) => ParcelCostingDetailType,
  ) => void;
}

const ParcelCostingContext = React.createContext({} as ParcelCosting);

type ParcelCostingProps = {
  children: ReactChild;
};

const ParcelCostingProvider: React.FC<ParcelCostingProps> = (props) => {
  const { children } = props;
  const [selectedCostingDetail, setSelectedCostingDetail] = useState<ParcelCostingDetailType>({
    costingId: '',
    costingNumber: null,
    costingStatus: null,
    isLoading: true,
    backUrl: '',
    editRequest: false,
    modalConfirmChecked: {
      costingNumber: null,
      refNumber: null,
      totalAfterDiscount: 0,
    },
    modalConfirmReject: {
      costingNumber: null,
      grandTotalPrice: 0,
    },
  });

  const store = {
    selectedCostingDetail,
    setSelectedCostingDetail,
  };

  return <ParcelCostingContext.Provider value={store}>{children}</ParcelCostingContext.Provider>;
};

export { ParcelCostingContext, ParcelCostingProvider };
