/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { LogARStatus } from 'views/DebtTracker/common/model/logARStatus.model';
import { Container } from './Styles';
import DebtTrackerTable from './DebtTrackerTable';
import DebtTrackerTab from './DebtTrackerTab';

const DebtTrackerList: React.FC = () => {
  const [tab, setTab] = useState<LogARStatus>(LogARStatus.OUTSTANDING_DEBT);
  const [tabName, setTabName] = useState<'debtTracker'>('debtTracker');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={18}>
          <Row>
            <Col>
              <DebtTrackerTab
                tab={tab}
                name={tabName}
                onChange={(value) => {
                  setTab(value);
                  setTabName('debtTracker');
                  setCurrentPage(1);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {tabName === 'debtTracker' && (
        <DebtTrackerTable
          pageSize={pageSize}
          onChangePageSize={(value) => setPageSize(value)}
          currentPage={currentPage}
          onChangeCurrentPage={(value) => setCurrentPage(value)}
        />
      )}
    </Container>
  );
};

export default DebtTrackerList;
