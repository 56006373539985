import gql from 'graphql-tag';
import { CodParcelStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codParcelStatus.model';

export interface CodParcels {
  _id: string;
  cod_in_date: string;
  cod_in_number: string;
  due_date: string;
  delivered_date: string;
  parcel_orders: {
    _id: string;
    tracking_number: string;
    delivered_date: string;
    cod_price: number;
  }[];
  total_cod: number;
  paid_channel: string;
  vendor: {
    _id: string;
    full_name: string;
    phone: string[];
  };
  cod_status: CodParcelStatus;
  last_update: string;
}

export interface CodParcelsData {
  codParcels: {
    codParcels: CodParcels[];
    totalDocument: number;
  };
}

export interface CodParcelsVars {
  _id?: string;
  cod_in_date?: string;
  cod_in_number?: string;
  due_date?: string;
  delivered_date?: string;
  tracking_number?: string;
  paid_channel?: string;
  vendor?: string;
  cod_status?: CodParcelStatus;
  order_by?: string;
  order_type?: '1' | '-1' | '';
  page?: number;
  showData?: number;
}

export default gql`
  query CodParcels(
    $_id: ID
    $cod_in_date: String
    $cod_in_number: String
    $due_date: String
    $delivered_date: String
    $tracking_number: String
    $paid_channel: String
    $vendor: ID
    $cod_status: Int
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
  ) {
    codParcels(
      params: {
        _id: $_id
        cod_in_date: $cod_in_date
        cod_in_number: $cod_in_number
        due_date: $due_date
        delivered_date: $delivered_date
        tracking_number: $tracking_number
        paid_channel: $paid_channel
        vendor: $vendor
        cod_status: $cod_status
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      codParcels {
        _id
        cod_in_date
        cod_in_number
        due_date
        delivered_date
        parcel_orders {
          _id
          tracking_number
          delivered_date
          cod_price
        }
        total_cod
        paid_channel
        vendor {
          _id
          full_name
          phone
        }
        cod_status
        last_update
      }
      totalDocument
    }
  }
`;
