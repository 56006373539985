/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect } from 'react';
import { CostingContext, CostingDetailType } from 'views/Costing/common/context/CostingContext';
import { Spin } from 'shared/components';
import { useQuery } from '@apollo/react-hooks';
import COSTING_QUERY, { CostingData, CostingVars } from 'shared/graphql/query/costing';
import Header from './Header';
import CostingDetail from './CostingDetail';

type ModalCostingDetailProps = {
  costingId: string;
  backUrl: string;
};

const ModalCostingDetail: React.FC<ModalCostingDetailProps> = (props) => {
  const { costingId, backUrl } = props;
  const { selectedCostingDetail, setSelectedCostingDetail } = useContext(CostingContext);
  const { isLoading } = selectedCostingDetail!;
  const { loading, error, data } = useQuery<CostingData, CostingVars>(COSTING_QUERY, {
    variables: {
      _id: costingId,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (costingId) {
      setSelectedCostingDetail!(
        (prevState): CostingDetailType => ({
          ...prevState,
          costingId,
          backUrl,
        }),
      );
    }
  }, [costingId, setSelectedCostingDetail, backUrl]);

  useEffect(() => {
    if (!loading && !error && data && data.costing) {
      const {
        costing_status,
        costing_number,
        profit,
        total_after_discount,
        number_ref,
        edit_request,
      } = data.costing;

      setSelectedCostingDetail!(
        (prevState): CostingDetailType => ({
          ...prevState,
          costingStatus: costing_status,
          costingNumber: costing_number,
          isLoading: false,
          modalConfirmChecked: {
            costingNumber: costing_number,
            numberRef: number_ref,
            totalAfterDiscountCost: total_after_discount.cost,
            totalAfterDiscountSale: total_after_discount.sale,
            profit,
          },
          modalConfirmReject: {
            costingNumber: costing_number,
            numberRef: number_ref,
            totalAfterDiscountCost: total_after_discount.cost,
            totalAfterDiscountSale: total_after_discount.sale,
            profit,
          },
          modalConfirmRejectEdit: {
            costingNumber: costing_number,
            numberRef: number_ref,
            totalAfterDiscountCost: total_after_discount.cost,
            totalAfterDiscountSale: total_after_discount.sale,
            profit,
          },
          modalConfirmEdit: {
            costingNumber: costing_number,
            numberRef: number_ref,
            totalAfterDiscountCost: total_after_discount.cost,
            totalAfterDiscountSale: total_after_discount.sale,
            profit,
          },
          editRequest: edit_request,
        }),
      );
    }

    return () => {
      setSelectedCostingDetail!(
        (prevState): CostingDetailType => ({
          ...prevState,
          isLoading: true,
        }),
      );
    };
  }, [costingId, data, error, loading, setSelectedCostingDetail]);

  return (
    <Spin spinning={isLoading}>
      <Header />
      <CostingDetail />
    </Spin>
  );
};

export default ModalCostingDetail;
