/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import REPORT_NAME_QUERY, {
  ReportName,
  ReportNameAll,
  ReportNameData,
  ReportType,
} from 'shared/graphql/query/reportName';

export enum DateType {
  DATE = 'date',
  MONTH_YEAR = 'monthYear',
  RANGE_DATE = 'rangeDate',
}

type Criteria =
  | {
      optionName: string;
      dateType: DateType;
    }
  | undefined;

type GetReportName = {
  criteria: Criteria;
  reportType: ReportType[];
  reportName: ReportNameAll[];
  optionSelect: string[];
  setReportType: (value?: ReportType) => void;
  setReportName: (reportName?: ReportNameAll) => void;
};

const useGetReportName = (): GetReportName => {
  const [reports, setReports] = useState<ReportName[]>([]);
  const [selectedReportType, setSelectedReportType] = useState<ReportType>();
  const [selectedReportName, setSelectedReportName] = useState<ReportNameAll>();
  const { data, loading, error } = useQuery<ReportNameData>(REPORT_NAME_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && data?.reportName) {
      setReports(data.reportName);
    }
  }, [data?.reportName, error, loading]);

  const getReportType = useCallback(() => {
    return reports.map((item) => item.report_type);
  }, [reports])();

  const getReportName = useCallback(() => {
    const filterReportType = reports.find((item) => item.report_type === selectedReportType);
    if (!filterReportType) {
      return [];
    }

    return filterReportType.report_name.map((item) => item.name);
  }, [reports, selectedReportType])();

  const getOptionSelect = useCallback(() => {
    const filterReportType = reports.find((item) => item.report_type === selectedReportType);

    if (!filterReportType) {
      return [];
    }

    const filterReportName = filterReportType.report_name.find(
      (item) => item.name === selectedReportName,
    );

    if (!filterReportName) {
      return [];
    }

    const filterCriteria = filterReportName.criteria
      .map((item) => item.option_select)
      .find((item) => item.length > 0);

    if (!filterCriteria) {
      return [];
    }

    return filterCriteria;
  }, [reports, selectedReportName, selectedReportType])();

  const onSetReportType = useCallback((value?: ReportType) => {
    setSelectedReportType(value);
  }, []);

  const onSetReportNameName = useCallback((value?: ReportNameAll) => {
    setSelectedReportName(value);
  }, []);

  const getCriteria = useCallback((): Criteria => {
    const filterReportType = reports.find((item) => item.report_type === selectedReportType);

    if (!filterReportType) {
      return undefined;
    }

    const filterReportName = filterReportType.report_name.find(
      (item) => item.name === selectedReportName,
    );

    if (!filterReportName) {
      return undefined;
    }

    const filterCriteria = filterReportName.criteria.find((item) => item.option_type !== '');

    if (!filterCriteria) {
      return undefined;
    }

    const { option_type, option_range, option_name } = filterCriteria;

    if (option_type === 'MONTH' && !option_range) {
      return {
        optionName: option_name,
        dateType: DateType.MONTH_YEAR,
      };
    }

    if (option_type === 'DATE' && option_range) {
      return {
        optionName: option_name,
        dateType: DateType.RANGE_DATE,
      };
    }

    if (option_type === 'DATE' && !option_range) {
      return {
        optionName: option_name,
        dateType: DateType.DATE,
      };
    }

    return undefined;
  }, [reports, selectedReportName, selectedReportType])();

  return {
    reportType: getReportType,
    setReportType: onSetReportType,
    reportName: getReportName,
    setReportName: onSetReportNameName,
    optionSelect: getOptionSelect,
    criteria: getCriteria,
  };
};

export default useGetReportName;
