/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { PaymentCodContext } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/context/PaymentCodContext';
import UPDATE_COD_OUT_PARCEL_MUTATION, {
  UpdateCodOutParcelData,
  UpdateCodOutParcelVars,
} from 'shared/graphql/mutation/updateCodOutParcel';
import COD_OUT_PARCEL_PAY_WITH_BBL_MUTATION, {
  CodOutParcelPayWithBBLData,
  CodOutParcelPayWithBBLVars,
} from 'shared/graphql/mutation/codOutParcelPayWithBBL';
import VERIFY_OTP_MUTATION, {
  VerifyOTPData,
  VerifyOTPDataVars,
} from 'shared/graphql/mutation/verifyOTP';
import COD_OUT_PARCEL_BBL_TRANSFER_CONFIRM_MUTATION, {
  CodOutParcelBBLTransferConfirmData,
  CodOutParcelBBLTransferConfirmVars,
} from 'shared/graphql/mutation/codOutParcelBBLTransferConfirm';
import { AppContext } from 'shared/context/AppContext';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/Routes/PaymentCod/PaymentCodListTable/ModalPaymentCodDetail/model/formValues.model';
import { StyledModal } from './Styles';

type ModalConfirmUpdateProps = {
  visible: boolean;
  onCancel: () => void;
  onCompleted: () => void;
};

const ModalConfirmUpdate: React.FC<ModalConfirmUpdateProps> = (props) => {
  const { onCancel, visible, onCompleted } = props;
  const { userDetail } = useContext(AppContext);
  const { selectedPaymentCodDetail } = useContext(PaymentCodContext);
  const { totalCod, paymentCodNumber, paymentCodId } = selectedPaymentCodDetail;
  const { getValues, reset, watch } = useFormContext<FormValues>();
  const allFieldValues = getValues();
  const watchIsBankBBL = watch('isBankBBL');
  const watchVerifyUri = watch('verifyUri');
  const watchOTP = watch('otp');
  const watchApproverPhoneNumber = watch('approverPhoneNumber');
  const watchBBLTransferValidationId = watch('bblTransferValidationId');

  const [updateCodOutParcel, { loading: updateCodOutParcelLoading }] = useMutation<
    UpdateCodOutParcelData,
    UpdateCodOutParcelVars
  >(UPDATE_COD_OUT_PARCEL_MUTATION, {
    update: (_cache, { data: dataUpdate }) => {
      if (dataUpdate?.updateCodOutParcel._id) {
        message.success('บันทึกการจ่ายเรียบร้อยแล้ว');
        reset();
        onCompleted();
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['CodOutParcel', 'CodOutParcels', 'CodOutParcelStatus'],
  });

  const [codOutParcelPayWithBBL, { loading: codOutParcelPayWithBBLLoading }] = useMutation<
    CodOutParcelPayWithBBLData,
    CodOutParcelPayWithBBLVars
  >(COD_OUT_PARCEL_PAY_WITH_BBL_MUTATION, {
    update: (_cache, { data: dataUpdate }) => {
      if (dataUpdate?.codOutParcelPayWithBBL._id) {
        message.success('บันทึกการจ่ายเรียบร้อยแล้ว');
        reset();
        onCompleted();
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['CodOutParcel', 'CodOutParcels', 'CodOutParcelStatus'],
  });

  const [
    codOutParcelBBLTransferConfirm,
    { loading: codOutParcelBBLTransferConfirmLoading },
  ] = useMutation<CodOutParcelBBLTransferConfirmData, CodOutParcelBBLTransferConfirmVars>(
    COD_OUT_PARCEL_BBL_TRANSFER_CONFIRM_MUTATION,
    {
      update: (_cache, { data: dataUpdate }) => {
        if (dataUpdate?.codOutParcelBBLTransferConfirm?.data?._id) {
          message.success('Transaction Confirm');
          codOutParcelPayWithBBL({
            variables: {
              _id: paymentCodId,
              cod_out_status: 2,
              payment_method: allFieldValues.paymentMethod,
              slip_remark: allFieldValues.slipRemark === '' ? undefined : allFieldValues.slipRemark,
              bank_account: {
                bank_name: allFieldValues.bankName,
                bank_branch: allFieldValues.bankBranch,
                account_name: allFieldValues.accountName,
                account_number: allFieldValues.accountNumber,
              },
              employee_create_transection: userDetail.employeeId,
              employee_approver: allFieldValues.approver,
              transectionId: allFieldValues.bblTransferValidationId,
            },
          });
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
    },
  );

  const [verifyOTP] = useMutation<VerifyOTPData, VerifyOTPDataVars>(VERIFY_OTP_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.verifyOTP.status === 'VERIFIED') {
        codOutParcelBBLTransferConfirm({
          variables: {
            transactionId: watchBBLTransferValidationId,
            employeeConfirmPhone: watchApproverPhoneNumber,
          },
        });
      }
      if (data?.verifyOTP.status === 'WAITING') {
        message.error('OTP ไม่ถูกต้อง');
      }
      if (data?.verifyOTP.status === 'FAILED') {
        message.error('กรุณาส่ง SMS อีกครั้ง');
      }
      if (data?.verifyOTP.status === 'EXPIRED') {
        message.error('OTP หมดอายุ กรุณาส่ง SMS อีกครั้ง');
      }
      if (data?.verifyOTP === null) {
        message.error('OTP ไม่ถูกต้อง');
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
  });

  const confirmUpdate = () => {
    if (watchIsBankBBL) {
      verifyOTP({
        variables: {
          verifyUri: watchVerifyUri,
          code: watchOTP,
        },
      });
    } else {
      updateCodOutParcel({
        variables: {
          _id: paymentCodId,
          cod_out_status: 2,
          payment_method: allFieldValues.paymentMethod,
          bank_ref: allFieldValues.bankRef,
          proof_slip: allFieldValues.proofSlip,
          paid_date: allFieldValues.paidDate,
          slip_remark: allFieldValues.slipRemark === '' ? undefined : allFieldValues.slipRemark,
          bank_account: {
            bank_name: allFieldValues.bankName,
            bank_branch: allFieldValues.bankBranch,
            account_name: allFieldValues.accountName,
            account_number: allFieldValues.accountNumber,
          },
        },
      });
    }
  };

  return (
    <StyledModal
      className="custom-modal"
      title="บันทึกการจ่ายรายการ COD ใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmUpdate()}
          loading={
            updateCodOutParcelLoading ||
            codOutParcelPayWithBBLLoading ||
            codOutParcelBBLTransferConfirmLoading
          }
        >
          ยืนยัน
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={
            updateCodOutParcelLoading ||
            codOutParcelPayWithBBLLoading ||
            codOutParcelBBLTransferConfirmLoading
          }
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>รหัส COD-OUT:</Col>
        <Col span={18}>
          <Text strong>{paymentCodNumber}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>มูลค่า COD รวม(บาท):</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(totalCod, '0')}
          </Text>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmUpdate;
