import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import {
  ReceiptContext,
  SelectedBillingNoteDetailType,
} from 'views/AccountReceive/common/context/ReceiptContext';
import { StyledButton, TextHeader, Container } from './Styles';

const Header: React.FC = () => {
  const { selectedBillingNoteDetail, setSelectedBillingNoteDetail } = useContext(ReceiptContext);
  const { billNumber, billStatus, document, isLoading } = selectedBillingNoteDetail;

  const closeModalHandler = () => {
    setSelectedBillingNoteDetail(
      (prevState): SelectedBillingNoteDetailType => ({
        ...prevState,
        isOpenModal: false,
        document: '',
      }),
    );
  };

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>{`รหัสใบวางบิล${billNumber ? `: ${billNumber}` : ''}`}</TextHeader>
        </Col>
        <Col>
          {billStatus === 6 && (
            <StyledButton
              disabled={isLoading}
              color="blue--outline"
              onClick={() => window.open(document)}
            >
              เอกสารใบวางบิล
            </StyledButton>
          )}

          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
