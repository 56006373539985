import styled from 'styled-components';
import { Text } from 'shared/components';
import { font } from 'shared/utils/styles';

export const ToolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TextHover = styled(Text)`
  padding-right: 0px;
  &:hover {
    ${font.bold}
  }
`;
