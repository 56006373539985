import gql from 'graphql-tag';

export interface CostingStatusData {
  costingStatus: {
    checking: number;
    checked: number;
    reject: number;
    edit: number;
  };
}

export default gql`
  query CostingStatus {
    costingStatus {
      checking
      checked
      reject
      edit
    }
  }
`;
