/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import UPDATE_COD_OUT_PARCEL_MUTATION, {
  UpdateCodOutParcelData,
  UpdateCodOutParcelVars,
} from 'shared/graphql/mutation/updateCodOutParcel';
import { Button } from 'shared/components';
import { PaymentCodContext } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/context/PaymentCodContext';
import { StyledModal } from './Styles';

type Cods = {
  id: string;
};

type ModalConfirmDeleteProps = {
  visible: boolean;
  onCancel: () => void;
  codItemDelete: string;
  cods?: Cods[];
};

const ModalConfirmDelete: React.FC<ModalConfirmDeleteProps> = (props) => {
  const { onCancel, visible, codItemDelete, cods } = props;
  const { selectedPaymentCodDetail } = useContext(PaymentCodContext);
  const { paymentCodId } = selectedPaymentCodDetail;

  const [updateCodOutParcel, { loading: updateCodOutParcelLoading }] = useMutation<
    UpdateCodOutParcelData,
    UpdateCodOutParcelVars
  >(UPDATE_COD_OUT_PARCEL_MUTATION, {
    update: (_cache, { data: dataUpdate }) => {
      if (dataUpdate?.updateCodOutParcel._id) {
        message.success('ลบรายการแล้ว');
        onCancel();
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['CodOutParcel', 'CodOutParcels'],
  });

  const onSubmit = () => {
    const codsId = cods?.map((item) => item.id);
    const filterCodItems = codsId?.filter((item) => item !== codItemDelete);

    updateCodOutParcel({
      variables: {
        _id: paymentCodId,
        parcel_orders: filterCodItems,
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ลบรายการ"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<DeleteOutlined />}
          color="red"
          onClick={() => onSubmit()}
          loading={updateCodOutParcelLoading}
        >
          ตกลง
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={updateCodOutParcelLoading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>ยืนยันการลบรายการนี้หรือไม่ ?</Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmDelete;
