import gql from 'graphql-tag';

export interface ChartOfAccounts {
  _id: string;
  mode: string;
  level: number;
  account_control: string;
  code: string;
  account_name: string;
  type: number;
  enable: boolean;
  create_date: string;
  sub_account: ChartOfAccounts[] | null;
}

export interface ChartOfAccountsData {
  chartOfAccountsLevel: {
    chartOfAccounts: ChartOfAccounts[];
    totalDocument: number;
  };
}

export interface ChartOfAccountsVars {
  _id?: string;
  mode: string;
  code?: string;
  account_name?: string;
  account_control?: string;
  level?: number | undefined;
  type?: number | undefined;
  showData?: number;
  page?: number;
}

export default gql`
  query ChartOfAccountsLevel(
    $_id: String
    $mode: ID!
    $code: String
    $account_name: String
    $account_control: ID
    $level: Int
    $type: Int
    $showData: Int
    $page: Int
  ) {
    chartOfAccountsLevel(
      params: {
        _id: $_id
        mode: $mode
        code: $code
        account_name: $account_name
        account_control: $account_control
        level: $level
        type: $type
      }
      page: $page
      showData: $showData
    ) {
      chartOfAccounts {
        _id
        mode
        level
        account_control
        code
        account_name
        type
        enable
        create_date
        sub_account {
          _id
          mode
          level
          account_control
          code
          account_name
          type
          enable
          create_date
          sub_account {
            _id
            mode
            level
            account_control
            code
            account_name
            type
            enable
            create_date
            sub_account {
              _id
              mode
              level
              account_control
              code
              account_name
              type
              enable
              create_date
              sub_account {
                _id
                mode
                level
                account_control
                code
                account_name
                type
                enable
                create_date
                sub_account {
                  _id
                  mode
                  level
                  account_control
                  code
                  account_name
                  type
                  enable
                  create_date
                }
              }
            }
          }
        }
      }
      totalDocument
    }
  }
`;
