/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  ReceiptContext,
  SelectedReceiptDetailType,
} from 'views/AccountReceive/common/context/ReceiptContext';
import { Spin } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { message } from 'antd';
import RECEIPT_QUERY, { ReceiptData, ReceiptVars } from 'shared/graphql/query/receipt';
import Header from './Header';
import { StyledModal } from './Styles';
import ReceiptDetail from './ReceiptDetail';
import { FormValues } from './model/formValues.model';

const ModalReceiptDetailWrapper: React.FC = () => {
  const { selectedReceiptDetail, setSelectedReceiptDetail } = useContext(ReceiptContext);
  const { isOpenModal } = selectedReceiptDetail;

  return (
    <StyledModal
      visible={isOpenModal}
      onCancel={() => {
        setSelectedReceiptDetail(
          (prevState): SelectedReceiptDetailType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalReceiptDetail />
    </StyledModal>
  );
};

const validateSchema = yup.object().shape({
  receiptId: yup.string().required(),
  payerId: yup.string().required(),
  payerFullName: yup.string().required(),
  sendEmail: yup.string(),
});

const ModalReceiptDetail: React.FC = () => {
  const { selectedReceiptDetail, setSelectedReceiptDetail } = useContext(ReceiptContext);
  const { isLoading, receiptId } = selectedReceiptDetail;
  const { data, loading, error } = useQuery<ReceiptData, ReceiptVars>(RECEIPT_QUERY, {
    variables: {
      _id: receiptId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      receiptId: '',
      payerId: '',
      payerFullName: '',
      sendEmail: 'copy',
    },
  });

  const { register, setValue, reset } = methods;

  useEffect(() => {
    setSelectedReceiptDetail(
      (prevState): SelectedReceiptDetailType => ({
        ...prevState,
        isLoading: loading,
      }),
    );

    if (!loading && !error && data?.receipt) {
      const {
        _id,
        receipt_number,
        receipt_status,
        payer,
        invoice,
        document,
        total_receipt,
      } = data.receipt;

      setSelectedReceiptDetail(
        (prevState): SelectedReceiptDetailType => ({
          ...prevState,
          receiptNumber: receipt_number,
          receiptStatus: receipt_status,
          invoiceId: invoice._id,
          document,
          systemRequest: invoice.system_request,
          totalReceipt: total_receipt,
        }),
      );

      setTimeout(() => {
        reset({
          receiptId: _id,
          payerId: payer._id,
          payerFullName: payer.full_name,
          sendEmail: 'copy',
        });
      });
    }

    return () => {
      setSelectedReceiptDetail(
        (prevState): SelectedReceiptDetailType => ({
          ...prevState,
          isLoading: true,
        }),
      );
    };
  }, [data?.receipt, error, loading, setSelectedReceiptDetail, setValue]);

  useEffect(() => {
    register('receiptId');
    register('payerId');
    register('payerFullName');
  }, [register]);

  return (
    <Spin spinning={isLoading}>
      <FormProvider {...methods}>
        <form>
          <Header />
          <ReceiptDetail />
        </form>
      </FormProvider>
    </Spin>
  );
};

export default ModalReceiptDetailWrapper;
