import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { PageNotFound } from 'shared/components';
import IndividualList from './IndividualList';
import IndividualDetail from './IndividualDetail';

const IndividualWrapper: React.FC<RouteComponentProps> = (props) => {
  return (
    <>
      <Individual {...props} />
    </>
  );
};

const Individual: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;

  return (
    <Switch>
      <Route exact path={`${match.path}/edit/:id`} component={IndividualDetail} />
      <Route exact path={`${match.path}/create`} component={IndividualDetail} />
      <Route exact path={`${match.path}`} component={IndividualList} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default IndividualWrapper;
