/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalCreatePaymentOrder/model/formValues.model';
import { PaymentOrderContext } from 'views/AccountPayment/common/context/PaymentOrderContext';
import CREATE_PAYMENT_MUTATION, {
  CreatePaymentData,
  CreatePaymentVars,
} from 'shared/graphql/mutation/createPayment';
import { useFormContext } from 'react-hook-form';
import useVatCondition from 'views/AccountPayment/Routes/PaymentOrder/ModalCreatePaymentOrder/hooks/useVatCondition';
import { StyledModal } from './Styles';

type ModalConfirmCreateBillingNoteProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmCreatePaymentOrder: React.FC<ModalConfirmCreateBillingNoteProps> = (props) => {
  const { onCancel, visible } = props;
  const { getValues, reset } = useFormContext<FormValues>();
  const allFieldValues = getValues();
  const { getPaymentList } = useVatCondition();
  const { createPaymentOrder: createPaymentOrderContext } = useContext(PaymentOrderContext);
  const [, setCreatePaymentOrder] = createPaymentOrderContext;

  const [createPayment, { loading }] = useMutation<CreatePaymentData, CreatePaymentVars>(
    CREATE_PAYMENT_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.createPayment._id) {
          message.success('สร้างใบสั่งจ่ายแล้ว');
          reset();
          onCancel();
          setCreatePaymentOrder((prevState) => ({
            ...prevState,
            isOpenModal: false,
          }));
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['Payment', 'Payments'],
    },
  );

  const confirmCreatePaymentOrder = () => {
    createPayment({
      variables: {
        payment_status: 1,
        payment_type: allFieldValues.paymentType,
        employee: allFieldValues.employeeId,
        payee: allFieldValues.payeeId,
        round_paid: allFieldValues.roundPaidDate,
        system_request: allFieldValues.systemRequest,
        number_ref: allFieldValues.numberRef,
        payment_remark: allFieldValues.paymentRemark,
        payment_list: getPaymentList.map((item) => ({
          datamaster_service_item_id: item.dataMasterId,
          advance_type: item.advanceType,
          list_number: item.listNumber,
          list_name: item.listName,
          qty: item.qty,
          unit: '-',
          price: item.price,
          discount: item.discount,
          vat: item.vat,
          wht: item.wht,
        })),
        service_date: allFieldValues.serviceDate,
        invoice_date: allFieldValues.paymentInvoiceDate,
        invoice_due_date: allFieldValues.invoiceDueDate,
        document_list: allFieldValues.otherDocument,
        vendor_invoice_id: allFieldValues.vendorInvoice,
        vendor: allFieldValues.vendorId !== '' ? allFieldValues.vendorId : null,
        department: allFieldValues.paymentType !== 'COGs' ? allFieldValues.department : null,
        fixed_asset_check: Number(allFieldValues.fixedAssetCheck),
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันสร้างใบสั่งจ่ายใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmCreatePaymentOrder()}
          loading={loading}
        >
          ใช่, สร้างใบสั่งจ่าย
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>
          ผู้รับเงิน <Text customType="grey">(เจ้าหนี้)</Text>:
        </Col>
        <Col span={18}>
          <Text strong>{allFieldValues.payeeName}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>ประเภทการจ่าย:</Col>
        <Col span={18}>
          <Text strong>{allFieldValues.paymentType}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>เกี่ยวข้องกับ:</Col>
        <Col span={18}>
          <Text strong>{allFieldValues.systemRequest}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>รวมมูลค่าใบสั่งจ่าย::</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(allFieldValues.grandTotalPrice, '0')}
          </Text>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmCreatePaymentOrder;
