/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Row, Col } from 'antd';
import { DeleteOutlined, PlusCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useFormContext } from 'react-hook-form';
import CAL_BILLING_NOTE_QUERY, {
  CalBillingNoteData,
  CalBillingNoteVars,
  InvoiceDetail,
} from 'shared/graphql/query/calBillingNote';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { Button, Text, Spin } from 'shared/components';
import { FormValues } from 'views/AccountReceive/Routes/BillingNote/ModalCreateBillingNote/formValues.model';
import ModalAddInvoice from 'views/AccountReceive/Routes/BillingNote/ModalAddInvoice';
import { StyledTable } from './Styles';
import ExpandedRow from './ExpandedRow';

type InvoiceDataTable = {
  key: string;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: ReactNode;
  column4: string;
  invoiceDetail: InvoiceDetail[];
};

const columns = [
  {
    title: 'เครื่องมือ',
    dataIndex: 'tools',
    width: 70,
  },
  {
    title: 'วันที่เอกสาร',
    dataIndex: 'column1',
    width: 180,
  },
  {
    title: 'รหัสใบแจ้งหนี้',
    dataIndex: 'column2',
    width: 200,
  },
  {
    title: 'มูลค่าใบแจ้งหนี้',
    dataIndex: 'column3',
    width: 130,
    align: 'right',
  },
  {
    title: 'วันที่ครบกำหนดชำระ',
    dataIndex: 'column4',
    width: 160,
  },
  {
    title: '',
  },
];

const InvoiceList: React.FC = () => {
  const { getValues, setValue } = useFormContext<FormValues>();
  const [isOpenModalAddInvoice, setOpenModalAddInvoice] = useState<boolean>(false);
  const invoicesId = getValues('invoicesId')
    ? getValues('invoicesId').map((invoiceId: string) => ({ invoice: invoiceId }))
    : [];

  const { error, data, loading } = useQuery<CalBillingNoteData, CalBillingNoteVars>(
    CAL_BILLING_NOTE_QUERY,
    {
      variables: {
        bill_invoice: invoicesId,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  let content: ReactNode = <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  let invoiceData: InvoiceDataTable[] = [];
  if (data && data.calBillingNote) {
    const { bill_invoice } = data.calBillingNote;

    invoiceData = bill_invoice.map((item) => {
      const { invoice } = item;

      return {
        key: invoice._id,
        tools: (
          <Button
            disabled={getValues('invoicesId').length < 2}
            title="ลบ"
            color="red--outline"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => {
              const filterInvoice = getValues('invoicesId').filter(
                (invoiceId) => invoiceId !== invoice._id,
              );
              setValue('invoicesId', filterInvoice);
            }}
          />
        ),
        column1: formatDate(item.invoice.service_date),
        column2: item.invoice.invoice_number || '-',
        column3: (
          <Text customType="primary" strong>
            {formatCurrency(item.invoice.grand_total_price)}
          </Text>
        ),
        column4: formatDate(item.invoice.due_date),
        invoiceDetail: item.invoice.invoice_detail,
      };
    });

    content = (
      <>
        <StyledTable
          scroll={{ x: 'max-content' }}
          pagination={false}
          columns={columns}
          dataSource={invoiceData}
          size="small"
          expandable={{
            expandedRowRender: (record: { invoiceDetail: InvoiceDetail[] }) => (
              <ExpandedRow data={record.invoiceDetail} />
            ),
          }}
          loading={loading}
        />
        <Row justify="space-between" style={{ marginTop: 10 }}>
          <Col>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button
                  color="blue--light"
                  icon={<PlusCircleOutlined />}
                  onClick={() => setOpenModalAddInvoice(true)}
                >
                  เพิ่มใบแจ้งหนี้
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <ModalAddInvoice
          visible={isOpenModalAddInvoice}
          onOk={(invoices) => {
            setValue(
              'invoicesId',
              invoices.map((invoice) => invoice.id),
            );

            setOpenModalAddInvoice(false);
          }}
          onCancel={() => setOpenModalAddInvoice(false)}
          customerId={getValues('payerId')}
          invoices={bill_invoice.map((invoice) => ({
            id: invoice.invoice._id,
            serviceDate: invoice.invoice.service_date,
            invoiceNumber: invoice.invoice.invoice_number,
            grandTotalPrice: invoice.invoice.grand_total_price,
          }))}
        />
      </>
    );
  }

  if (error) {
    content = error.message;
  }

  return <div>{content}</div>;
};

export default InvoiceList;
