import styled from 'styled-components';
import { Tag } from 'antd';
import { color, font } from 'shared/utils/styles';

type TagCustomProps = {
  type?:
    | 'blue'
    | 'blue--light'
    | 'green'
    | 'green--light'
    | 'red'
    | 'red--light'
    | 'grey'
    | 'black';
  outline?: 1 | 0;
};

export const StyledTag = styled(Tag)<TagCustomProps>`
  ${font.size(20)}
  ${font.regular}
  padding: 0px 10px;
  height: 24px;
  line-height: 1.2;
  border-radius: 4px;
  border: none;
  background-color: ${color.backgroundGrey};
  color: ${color.black};

  ${(props) =>
    props.type === 'blue' &&
    `
    background-color: ${color.backgroundPrimary};
    color: ${color.white};
    
    ${
      props.outline &&
      `
      color: ${color.backgroundPrimary};
      border: 1px solid ${color.backgroundPrimary};
    `
    }
  `}

  ${(props) =>
    props.type === 'blue--light' &&
    `
    background-color: ${color.backgroundLightPrimary};
    color: ${color.primary};

    ${
      props.outline &&
      `
      color: ${color.backgroundLightPrimary};
      border: 1px solid ${color.backgroundLightPrimary};      
    `
    }
  `}

  ${(props) =>
    props.type === 'green' &&
    `
    background-color: ${color.backgroundSuccess};
    color: ${color.white};

    ${
      props.outline &&
      `
      color: ${color.backgroundSuccess};
      border: 1px solid ${color.backgroundSuccess};
    `
    }
  `}

  ${(props) =>
    props.type === 'green--light' &&
    `
    background-color: ${color.backgroundLightSuccess};
    color: ${color.success};

    ${
      props.outline &&
      `
      color: ${color.backgroundLightSuccess};
      border: 1px solid ${color.backgroundLightSuccess};
    `
    }
  `}

  ${(props) =>
    props.type === 'red' &&
    `
    background-color: ${color.backgroundDanger};
    color: ${color.white};

    ${
      props.outline &&
      `
      color: ${color.backgroundDanger};
      border: 1px solid ${color.backgroundDanger};
    `
    }
  `}

  ${(props) =>
    props.type === 'red--light' &&
    `
    background-color: ${color.backgroundLightDanger};
    color: ${color.danger};

    ${
      props.outline &&
      `
      color: ${color.backgroundLightDanger};
      border: 1px solid ${color.backgroundLightDanger};
    `
    }
  `}

  ${(props) =>
    props.type === 'grey' &&
    `
    background-color: ${color.grey};
    color: ${color.black};

    ${
      props.outline &&
      `
      color: ${color.grey};
      border: 1px solid ${color.grey};
    `
    }
  `}

  ${(props) =>
    props.type === 'black' &&
    `
    background-color: ${color.backgroundBlack};
    color: ${color.white};
  `}

  ${(props) =>
    props.outline &&
    `
    background-color: ${color.white};
  `}
`;

export default StyledTag;
