import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SubTabs } from 'shared/components';
import PARCEL_COSTING_STATUS_QUERY, {
  ParcelCostStatusData,
} from 'shared/graphql/query/parcelCostStatus';
import { ParcelCostingStatus } from 'views/Costing/common/model/parcelCostingStatus.model';

interface CostingTabsProps {
  name: string;
  tab: ParcelCostingStatus;
  onChange: (tab: ParcelCostingStatus) => void;
}

const CostingTabs: React.FC<CostingTabsProps> = (props) => {
  const { tab, onChange, name } = props;

  const { data, refetch } = useQuery<ParcelCostStatusData>(PARCEL_COSTING_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const checkSelectTab = (id: ParcelCostingStatus) => {
    return id === tab && name === 'costing';
  };

  const selectTab = (index: ParcelCostingStatus) => {
    refetch();
    onChange(index);
  };

  const tabs = [
    {
      id: ParcelCostingStatus.WAIT_AGENT_CONFIRM,
      name: 'รอ Agent ยืนยัน',
      color: 'badge-grey',
      count: data?.parcelCostStatus.waitAgentConfirm || 0,
      divider: false,
    },
    {
      id: ParcelCostingStatus.WAITING_CONFIRM,
      name: 'รอตรวจสอบ',
      color: 'badge-grey',
      count: data?.parcelCostStatus.waitingConfirm || 0,
      divider: false,
    },
    {
      id: ParcelCostingStatus.CONFIRM,
      name: 'ตรวจสอบแล้ว',
      color: 'badge-grey',
      count: data?.parcelCostStatus.confirm || 0,
      divider: true,
    },
    {
      id: ParcelCostingStatus.REJECT,
      name: 'ปฎิเสธ',
      color: 'badge-grey',
      count: data?.parcelCostStatus.reject || 0,
      divider: false,
    },
    {
      id: ParcelCostingStatus.EDIT,
      name: 'แก้ไข',
      color: 'badge-grey',
      count: data?.parcelCostStatus.edit || 0,
      divider: false,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default CostingTabs;
