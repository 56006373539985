import gql from 'graphql-tag';
import { BillingNotesStatus } from 'views/AccountReceive/common/model/billingNoteStatus.model';
import { BusinessInvoiceType } from 'views/AccountReceive/common/model/businessInvoiceType.model';

export interface BillingNotesList {
  _id: string;
  create_date: string;
  service_date: string;
  bill_number: string;
  grand_total_price: string;
  payer: {
    _id: string;
    full_name: string;
  };
  paid: number;
  unpaid: number;
  due_date: string;
  last_update: string;
  business_invoice_type: string;
  employee: {
    _id: string;
    full_name: string;
  };
  system_request: string;
}

export interface BillingNotesData {
  billingNotes: {
    billingNotes: BillingNotesList[];
    totalDocument: number;
  };
}

export interface BillingNotesVars {
  create_date?: string;
  service_date?: string;
  bill_number?: string;
  grand_total_price?: number;
  due_date?: string;
  payer?: string;
  bill_status?: BillingNotesStatus;
  business_invoice_type?: BusinessInvoiceType;
  business_invoice_date_range?: number;
  showData?: number;
  page?: number;
  system_request?: string;
  employee?: string;
  order_by?: string;
  order_type?: '1' | '-1' | '';
}

export default gql`
  query BillingNotes(
    $create_date: String
    $service_date: String
    $bill_number: String
    $grand_total_price: Float
    $due_date: String
    $payer: ID
    $bill_status: Int
    $business_invoice_type: Int
    $system_request: String
    $employee: ID
    $showData: Int
    $page: Int
    $order_by: String
    $order_type: String
  ) {
    billingNotes(
      params: {
        create_date: $create_date
        service_date: $service_date
        bill_number: $bill_number
        grand_total_price: $grand_total_price
        due_date: $due_date
        payer: $payer
        bill_status: $bill_status
        business_invoice_type: $business_invoice_type
        system_request: $system_request
        employee: $employee
      }
      page: $page
      showData: $showData
      sort: { order_by: $order_by, order_type: $order_type }
    ) {
      billingNotes {
        _id
        create_date
        service_date
        bill_number
        grand_total_price
        payer {
          _id
          full_name
        }
        paid
        unpaid
        due_date
        last_update
        business_invoice_type
        employee {
          _id
          full_name
        }
        system_request
      }
      totalDocument
    }
  }
`;
