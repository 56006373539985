import gql from 'graphql-tag';

export interface PaymentCodStatusData {
  paymentCodStatus: {
    waiting: number;
    paid: number;
  };
}

export default gql`
  query PaymentCodStatus {
    paymentCodStatus {
      waiting
      paid
    }
  }
`;
