/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, DatePicker, message, Row, Select } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { Button, SelectCustomersField, SelectDepartmentField, Text } from 'shared/components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CREATE_REPORT_MUTATION, {
  CreateReportData,
  CreateReportVars,
} from 'shared/graphql/mutation/createReport';
import { ReportNameAll, ReportType } from 'shared/graphql/query/reportName';
import { StyledFormItem, StyledModal } from './Styles';
import { FormValues } from './model/formValues.model';
import useGetReportName, { DateType } from './hooks/useGetReportName';
import SelectChartOfAccountOpexField from './SelectChartOfAccountOpexField';

const { RangePicker } = DatePicker;
const { Option } = Select;

type ModalCreateReportProps = {
  visible: boolean;
  onCancel: () => void;
};

const validateSchema = yup.object().shape({
  reportType: yup.string().required(),
  reportName: yup.string().required(),
  dateRange: yup.string().when('isHasCriteria', {
    is: (value) => value === true,
    then: yup.string().required(),
  }),
  optionSelect: yup.string().when('isHasOptionSelect', {
    is: (value) => value === true,
    then: yup.string().required(),
  }),
  isHasOptionSelect: yup.boolean(),
  isHasCriteria: yup.boolean(),
  customer: yup
    .string()
    .nullable()
    .when('reportName', {
      is: (value) => value === ReportNameAll.PARCEL_DETAIL,
      then: yup.string().required(),
    }),
});

const initialValue = {
  reportType: undefined,
  reportName: undefined,
  dateRange: undefined,
  optionSelect: null,
  isHasCriteria: false,
  isHasOptionSelect: false,
  opexLevel3: null,
  department: null,
  customer: null,
};

const formatDate = 'YYYY-MM-DD';

const ModalCreateReport: React.FC<ModalCreateReportProps> = (props) => {
  const { onCancel, visible } = props;
  const {
    reportType,
    reportName,
    setReportType,
    setReportName,
    optionSelect,
    criteria,
  } = useGetReportName();

  const { reset, errors, control, handleSubmit, watch, setValue, register } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validateSchema),
    defaultValues: initialValue,
  });

  const watchReportTypeField = watch('reportType');
  const watchReportNameField = watch('reportName');

  useEffect(() => {
    register('isHasCriteria');
    register('isHasOptionSelect');
  }, [register]);

  useEffect(() => {
    setReportType(watchReportTypeField || undefined);
    setValue('reportName', undefined);
  }, [setReportType, setValue, watchReportTypeField]);

  useEffect(() => {
    setReportName(watchReportNameField || undefined);
    setValue('optionSelect', undefined);
  }, [setReportName, setValue, watchReportNameField]);

  useEffect(() => {
    setValue('isHasCriteria', !!criteria);
  }, [criteria, setValue]);

  useEffect(() => {
    setValue('isHasOptionSelect', optionSelect.length > 0);
  }, [optionSelect, setValue]);

  const [createReport, { loading }] = useMutation<CreateReportData, CreateReportVars>(
    CREATE_REPORT_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.createReport._id) {
          message.success('สร้างรายงานแล้ว');
          onCancel();
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['Reports'],
    },
  );

  const confirmCreate = handleSubmit((data) => {
    const dataType =
      data.optionSelect === 'วันที่เอกสาร' || data.optionSelect === 'วันที่สร้างเอกสาร';
    const paymentType = data.optionSelect === 'COGs' || data.optionSelect === 'OPEX';

    createReport({
      variables: {
        report_type: data.reportType || '',
        name: data.reportName || '',
        date_range: data.dateRange || '',
        option_select: {
          date_type: dataType ? data.optionSelect : null,
          payment_type: paymentType ? data.optionSelect : null,
          chartOfAccountLV3: data.opexLevel3 ? data.opexLevel3 : null,
          department: data.department ? data.department : null,
          customer: data.customer || undefined,
        },
      },
    });
  });

  return (
    <StyledModal
      className="custom-modal"
      title="สร้างรายงาน"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmCreate()}
          loading={loading}
        >
          สร้างรายงาน
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
      destroyOnClose
      afterClose={() => {
        setReportType();
        setTimeout(() => {
          reset({});
        }, 0);
      }}
    >
      <div style={{ marginTop: -15 }}>
        <Text customType="grey">กรุณาเลือกและตรวจสอบข้อมูลให้ถูกต้องก่อนการกดสร้างรายงาน</Text>
      </div>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          ประเภทรายงาน
          <StyledFormItem validateStatus={errors.reportType && 'error'}>
            <Controller
              control={control}
              name="reportType"
              render={({ onChange, value }) => (
                <Select
                  style={{ width: '100%' }}
                  value={value}
                  placeholder="เลือก"
                  onChange={(valueChange) => {
                    onChange(valueChange);
                  }}
                >
                  {reportType.map((item) => (
                    <Option key={`reportType-${item}`} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </StyledFormItem>
        </Col>

        <Col span={12}>
          ชื่อรายงาน
          <StyledFormItem validateStatus={errors.reportName && 'error'}>
            <Controller
              control={control}
              name="reportName"
              render={({ onChange, value }) => (
                <Select
                  style={{ width: '100%' }}
                  value={value}
                  placeholder="เลือก"
                  onChange={(valueChange) => onChange(valueChange)}
                  disabled={!watchReportTypeField}
                >
                  {reportName.map((item) => (
                    <Option key={`reportName-${item}`} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </StyledFormItem>
        </Col>

        {watchReportTypeField === ReportType.CT &&
          watchReportNameField === ReportNameAll.PARCEL_DETAIL && (
            <Col span={12}>
              ลูกค้า (ลูกหนี้):
              <StyledFormItem validateStatus={errors.customer && 'error'}>
                <Controller
                  control={control}
                  name="customer"
                  render={({ onChange, value }) => (
                    <SelectCustomersField
                      value={value || undefined}
                      onChange={(valueChange) => {
                        onChange(valueChange);
                      }}
                    />
                  )}
                />
              </StyledFormItem>
            </Col>
          )}

        {optionSelect.length > 0 && (
          <Col span={12}>
            {
              { JV: 'ประเภท JV', APP: 'ประเภท APP' }[
                watchReportTypeField === ReportType.JV ? ReportType.JV : 'APP'
              ]
            }

            <StyledFormItem validateStatus={errors.optionSelect && 'error'}>
              <Controller
                control={control}
                name="optionSelect"
                render={({ onChange, value }) => (
                  <Select
                    style={{ width: '100%' }}
                    value={value}
                    placeholder="เลือก"
                    onChange={(valueChange) => onChange(valueChange)}
                  >
                    {optionSelect.map((item) => (
                      <Option key={`optionSelect-${item}`} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                )}
              />
            </StyledFormItem>
          </Col>
        )}

        {criteria?.dateType === DateType.MONTH_YEAR && (
          <Col span={12}>
            {criteria.optionName}
            <StyledFormItem validateStatus={errors.dateRange && 'error'}>
              <Controller
                control={control}
                name="dateRange"
                render={({ onChange }) => (
                  <DatePicker
                    style={{ width: '100%' }}
                    picker="month"
                    format="MM/YYYY"
                    placeholder="เลือก"
                    onChange={(valueChange) => {
                      if (valueChange) {
                        onChange(valueChange.format('YYYY-MM'));
                        return;
                      }

                      onChange(undefined);
                    }}
                  />
                )}
              />
            </StyledFormItem>
          </Col>
        )}

        {criteria?.dateType === DateType.DATE && (
          <Col span={12}>
            {criteria.optionName}
            <StyledFormItem validateStatus={errors.dateRange && 'error'}>
              <Controller
                control={control}
                name="dateRange"
                render={({ onChange }) => (
                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    placeholder="เลือก"
                    onChange={(valueChange) => {
                      if (valueChange) {
                        onChange(valueChange.format(formatDate));
                        return;
                      }

                      onChange(undefined);
                    }}
                  />
                )}
              />
            </StyledFormItem>
          </Col>
        )}

        {criteria?.dateType === DateType.RANGE_DATE && (
          <Col span={12}>
            {criteria.optionName}
            <StyledFormItem validateStatus={errors.dateRange && 'error'}>
              <Controller
                control={control}
                name="dateRange"
                render={({ onChange }) => (
                  <RangePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    placeholder={['เริ่มต้น', 'สิ้นสุด']}
                    onChange={(valueChange) => {
                      if (valueChange) {
                        const rangeDate = `${valueChange[0]?.format(
                          formatDate,
                        )}:${valueChange[1]?.format(formatDate)}`;
                        onChange(rangeDate);
                        return;
                      }

                      onChange(undefined);
                    }}
                  />
                )}
              />
            </StyledFormItem>
          </Col>
        )}

        {watchReportNameField === ReportNameAll.AP_TRANSACTIONS && (
          <Col span={12}>
            เลือกผังบัญชี OPEX ระดับที่ 3
            <StyledFormItem validateStatus={errors.opexLevel3 && 'error'}>
              <Controller
                control={control}
                name="opexLevel3"
                render={({ onChange, value }) => (
                  <SelectChartOfAccountOpexField
                    value={value || undefined}
                    onChange={(valueChange) => onChange(valueChange)}
                  />
                )}
              />
            </StyledFormItem>
          </Col>
        )}

        {watchReportNameField === ReportNameAll.AP_TRANSACTIONS && (
          <Col span={12}>
            แผนกของค่าใช้จ่าย
            <StyledFormItem validateStatus={errors.department && 'error'}>
              <Controller
                control={control}
                name="department"
                render={({ onChange, value }) => (
                  <SelectDepartmentField
                    value={value || undefined}
                    onChange={(valueChange) => {
                      onChange(valueChange);
                    }}
                  />
                )}
              />
            </StyledFormItem>
          </Col>
        )}
      </Row>
    </StyledModal>
  );
};

export default ModalCreateReport;
