/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { StopOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Input, Col, Modal, Row, message, Divider } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { Button, Text } from 'shared/components';
import { BillingNoteContext } from 'views/AccountReceive/common/context/BillingNoteContext';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalDetailPaymentOrder/model/formValues.model';
import UPDATE_BILLINGNOTE_MUTATION, {
  UpdateBillingNoteData,
  UpdateBillingNoteVars,
} from 'shared/graphql/mutation/updateBillingNote';
import { useFormContext, Controller } from 'react-hook-form';
import formatCurrency from 'shared/utils/formatCurrency';
import { BillingNotesStatus } from 'views/AccountReceive/common/model/billingNoteStatus.model';
import { formatDate } from 'shared/utils/dateTime';

type ModalConfirmCancelProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmCancel: React.FC<ModalConfirmCancelProps> = (props) => {
  const { onCancel, visible } = props;
  const { selectedBillingNoteDetail, setSelectedBillingNoteDetail } = useContext(
    BillingNoteContext,
  );
  // const [selectedPaymentDetail, setSelectedPaymentDetail] = selectedPaymentDetailContext;
  const { modalConfirmUpdate, billingNoteId } = selectedBillingNoteDetail;
  const { invoices, businessInvoiceType, payerFullName, grandTotalPrice } = modalConfirmUpdate;
  const { watch, reset, control } = useFormContext<FormValues>();
  const watchCancelRemark = watch('cancelRemark');

  const closeModalHandler = () => {
    setSelectedBillingNoteDetail((prevState) => ({
      ...prevState,
      isOpenModal: false,
    }));
  };

  const [updateBillingNote, { loading }] = useMutation<
    UpdateBillingNoteData,
    UpdateBillingNoteVars
  >(UPDATE_BILLINGNOTE_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.updateBillingNote._id) {
        message.success('เปลี่ยนสถานะใบวางบิลแล้ว');
        onCancel();
        reset();
        closeModalHandler();
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['BillingNote', 'BillingNoteStatus', 'BillingNotes', 'Invoices'],
  });

  const confirmCancel = () => {
    updateBillingNote({
      variables: {
        _id: billingNoteId,
        bill_status: BillingNotesStatus.CANCEL,
        cancel_remark: watchCancelRemark,
      },
    });
  };

  return (
    <Modal
      title="ยกเลิกการใช้ใบวางบิลนี้หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<StopOutlined />}
          color="red"
          onClick={() => confirmCancel()}
          loading={loading}
          disabled={!watchCancelRemark || loading}
        >
          ยกเลิกการใช้
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>
          ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>:
        </Col>
        <Col span={18}>
          <Text strong>{payerFullName}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>รูปแบบรับใบแจ้งหนี้:</Col>
        <Col span={18}>
          <Text strong>{businessInvoiceType}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>มูลค่าใบวางบิล:</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(grandTotalPrice, '0')}
          </Text>
        </Col>
      </Row>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Row>
        <Col span={24}>
          ทั้งหมด <Text strong>{`${invoices.length} รายการ`}</Text>
        </Col>
        <Col span={24}>
          <Row gutter={[24, 0]}>
            <Col span={8}>วันที่เอกสาร</Col>
            <Col span={8}>รหัสใบวางบิล</Col>
            <Col span={8}>มูลค่าใบแจ้งหนี้</Col>
          </Row>

          {invoices.map((item) => (
            <Row gutter={[8, 0]}>
              <Col span={8}>
                <Text strong>{item.createDate ? formatDate(item.createDate) : '-'}</Text>
              </Col>
              <Col span={8}>
                <Text strong>{item.invoiceNumber || '-'}</Text>
              </Col>
              <Col span={8}>
                <Text strong customType="primary">
                  {formatCurrency(item.grandTotalPrice)}
                </Text>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          เหตุผล:<Text type="danger">*</Text>
        </Col>
        <Col span={24}>
          <Controller
            as={Input.TextArea}
            name="cancelRemark"
            control={control}
            placeholder="ระบุเหตุผล"
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalConfirmCancel;
