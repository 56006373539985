import gql from 'graphql-tag';

export interface CreateOTPData {
  createOTP: {
    sessionId: string;
    verifyUri: string;
    destination: string;
    status: string;
    attempt: number;
    expiresAt: string;
    retryAfter: string;
  };
}

export interface CreateOTPDataVars {
  phone: string;
}

export default gql`
  mutation CreateOTP($phone: String!) {
    createOTP(phone: $phone) {
      sessionId
      verifyUri
      destination
      status
      attempt
      expiresAt
      retryAfter
    }
  }
`;
