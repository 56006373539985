import gql from 'graphql-tag';

export interface RejectParcelCostData {
  rejectParcelCost: {
    _id: string;
    refNumber: string;
    costing_number: string;
  };
}

export interface RejectParcelCostVars {
  _id: string;
  reject_remark: string;
}

export default gql`
  mutation RejectParcelCost($_id: ID!, $reject_remark: String!) {
    rejectParcelCost(_id: $_id, reject_remark: $reject_remark) {
      _id
      costing_number
      refNumber
    }
  }
`;
