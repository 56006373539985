import React from 'react';
import { Redirect, Route, matchPath, RouteComponentProps } from 'react-router-dom';
import Tabs from 'shared/components/Tabs';
import Layout from 'shared/container/Layout';
import Report from './Routes/Report';

type Tabs = 'create-download-report';

const CashOnDelivery: React.FC<RouteComponentProps> = (props) => {
  const { history, match, location } = props;

  const selectTab = (tabIndex: Tabs) => {
    const getUrl = match.path;
    history.push(`${getUrl}/${tabIndex}`);
  };

  const checkTabsActive = (tabIndex: Tabs) => {
    return !!matchPath(location.pathname, `${match.path}/${tabIndex}`);
  };

  const tabs = [
    {
      name: 'สร้าง / ดาวน์โหลดรายงาน',
      icon: 'file',
      active: checkTabsActive('create-download-report'),
      clicked: () => selectTab('create-download-report'),
    },
  ];

  return (
    <>
      {match.isExact && <Redirect to={`${match.path}/create-download-report`} />}
      <Layout textHeader="รายงาน / เอกสารสรุป">
        <Tabs menu={tabs} />
        <Route path={`${match.path}/create-download-report`} component={Report} />
      </Layout>
    </>
  );
};

export default CashOnDelivery;
