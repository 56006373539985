import styled from 'styled-components';
import { mixin, font, color } from 'shared/utils/styles';

export const Container = styled.div`
  display: inline-block;
  ${mixin.clickable}
`;

export const ArrowWrapper = styled.div`
  display: inline-block;
`;

export const ArrowContainer = styled.div`
  ${font.size(16)}
  margin-left: 6px;
  position: relative;

  & .arrow {
    color: ${color.grey};

    &:nth-child(2) {
      position: absolute;
      left: 0px;
    }

    &.active {
      color: ${color.primary};
    }
  }
`;
