type EnumLiteralsOf<T extends unknown> = T[keyof T];
export const CodStatus = Object.freeze({
  OPS_PENDING: 1 as const,
  PENDING: 2 as const,
  CONFIRM: 3 as const,
  REJECT: 4 as const,
  CANCEL: 5 as const,
});

export type CodStatus = EnumLiteralsOf<typeof CodStatus>;
