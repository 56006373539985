/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Input, Col, Modal, Typography, Row, message } from 'antd';
import { Button } from 'shared/components';
import { useMutation } from '@apollo/react-hooks';
import { PaymentOrderContext } from 'views/AccountPayment/common/context/PaymentOrderContext';
import UPDATE_PAYMENT_MUTATION, {
  UpdatePaymentData,
  UpdatePaymentVars,
} from 'shared/graphql/mutation/updatePayment';
import formatCurrency from 'shared/utils/formatCurrency';
import { useFormContext, Controller } from 'react-hook-form';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalDetailPaymentOrder/model/formValues.model';

const { Text } = Typography;

type ModalConfirmRejectProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmReject: React.FC<ModalConfirmRejectProps> = (props) => {
  const { onCancel, visible } = props;
  const { selectedPaymentDetail: selectedPaymentDetailContext } = useContext(PaymentOrderContext);
  const [selectedPaymentDetail, setSelectedPaymentDetail] = selectedPaymentDetailContext;
  const { watch, reset, control } = useFormContext<FormValues>();

  const watchEmployeeFullName = watch('employeeFullName');
  const watchPayeeName = watch('payeeName');
  const watchGrandTotalPrice = watch('grandTotalPrice');
  const watchRejectRemark = watch('rejectRemark');

  const closeModalHandler = () => {
    setSelectedPaymentDetail((prevState) => ({
      ...prevState,
      isOpenModal: false,
    }));
  };

  const [updatePayment, { loading }] = useMutation<UpdatePaymentData, UpdatePaymentVars>(
    UPDATE_PAYMENT_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.updatePayment._id) {
          message.success('เปลี่ยนสถานะใบสั่งจ่ายแล้ว');
          onCancel();
          reset();
          closeModalHandler();
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['Payment', 'PaymentStatus', 'Payments'],
    },
  );

  const confirmReject = () => {
    updatePayment({
      variables: {
        _id: selectedPaymentDetail.paymentId,
        payment_status: 4,
        reject_remark: watchRejectRemark,
      },
    });
  };

  return (
    <Modal
      title="ปฏิเสธใบสั่งจ่ายนี้หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CloseCircleOutlined />}
          color="red--light"
          onClick={() => confirmReject()}
          loading={loading}
          disabled={!watchRejectRemark}
        >
          ปฏิเสธ
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>ผู้ตั้งเบิก:</Col>
        <Col span={18}>
          <Text strong>{watchEmployeeFullName}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>ผู้รับเงิน:</Col>
        <Col span={18}>
          <Text strong>{watchPayeeName}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>มูลค่าใบสั่งจ่าย:</Col>
        <Col span={18}>
          <Text strong>{formatCurrency(watchGrandTotalPrice, '0')}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          เหตุผล:<Text type="danger">*</Text>
        </Col>
        <Col span={18}>
          <Controller as={Input} name="rejectRemark" control={control} placeholder="ระบุเหตุผล" />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalConfirmReject;
