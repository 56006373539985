/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, ReactNode } from 'react';
import { message, Row, Col, Select, Input, DatePicker, Modal } from 'antd';
import { ExclamationCircleOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useRouteMatch, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { Button, TableSearch, SelectCustomerField, Text } from 'shared/components';
import { formatDate } from 'shared/utils/dateTime';
import formatCurrencyProfitLoss from 'shared/utils/formatCurrencyProfitLoss';
import PARCEL_DETAIL_LIST_QUERY, {
  ParcelDetailListData,
  ParcelDetailListVars,
} from 'shared/graphql/query/parcelDetailList';
import styled from 'styled-components';
import formatCurrency from 'shared/utils/formatCurrency';
import baseUrl from 'shared/config/baseUrl';
import { ParcelDetailStatus } from './ParcelDetailStatus';

const ToolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

type ParcelDetailTableProps = {
  tab: ParcelDetailStatus;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type ParcelDetailDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: string;
  column4: string;
  column5: string;
  column6: string;
  column7: ReactNode;
};

const ParcelDetailTable: React.FC<ParcelDetailTableProps> = (props) => {
  const match = useRouteMatch();

  const { tab, pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [number, setNumber] = useState<string>('');
  const [createdAt, setCreatedAt] = useState<string>('');
  const [pickupRangeDate, setPickupRangeDate] = useState<string>('');
  const [customerId, setCustomerId] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('1');

  const { loading, error, data } = useQuery<ParcelDetailListData, ParcelDetailListVars>(
    PARCEL_DETAIL_LIST_QUERY,
    {
      variables: {
        status: tab,
        number,
        createdAt,
        pickupRangeDate,
        customer: customerId,
        showData: pageSize,
        page: currentPage,
        order_by: orderBy === '' ? 'createdAt' : orderBy,
        order_type: orderType === '' ? '1' : orderType,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const showConfirmDownloadFile = (value: string) => {
    const modal = {
      title: 'ยืนยัน',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          ต้องการ <Text strong>ดาวน์โหลดไฟล์</Text> ใช่หรือไม่?
        </>
      ),
      okText: 'ดาวน์โหลด',
      onOk() {
        window.location.href = `${baseUrl}/report-parcel-detail/${value}`;
      },
      cancelText: 'ยกเลิก',
    };

    confirm(modal);
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'รหัส PD',
      dataIndex: 'column1',
      width: 150,
      search: (
        <Input
          value={number}
          placeholder="ค้นหา"
          onChange={(event) => setNumber(event.target.value)}
          allowClear
        />
      ),
    },
    {
      title: 'วันที่เอกสาร',
      dataIndex: 'column2',
      width: 250,
      search: (
        <RangePicker
          onChange={(values) => {
            if (values) {
              const formatRangeDate = `${values[0]?.format('YYYY-MM-DD')}:${values[1]?.format(
                'YYYY-MM-DD',
              )}`;
              return setCreatedAt(formatRangeDate);
            }
            setCreatedAt('');
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'ช่วงวันที่ Pickup date',
      dataIndex: 'column3',
      width: 250,
      search: (
        <RangePicker
          onChange={(values) => {
            if (values) {
              const formatRangeDate = `${values[0]?.format('YYYY-MM-DD')}:${values[1]?.format(
                'YYYY-MM-DD',
              )}`;
              return setPickupRangeDate(formatRangeDate);
            }
            setPickupRangeDate('');
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'column4',
      width: 300,
      search: <SelectCustomerField value={customerId} onChange={(value) => setCustomerId(value)} />,
    },
    {
      title: 'ต้นทุนรวม (บาท)',
      dataIndex: 'column5',
      width: 150,
      align: 'right',
    },
    {
      title: 'รายได้รวม (บาท)',
      dataIndex: 'column6',
      width: 150,
      align: 'right',
    },
    ...(tab === ParcelDetailStatus.CREATE_INVOICE || tab === ParcelDetailStatus.CANCEL
      ? [
          {
            title: 'กำไรขาดทุนรวม(บาท)',
            dataIndex: 'column7',
            width: 150,
            align: 'right',
          },
        ]
      : []),
  ];

  let ParcelDetailDataTable: ParcelDetailDataTable[] = [];
  let totalDocument = 0;

  if (data?.parcelDetailList) {
    const { parcelDetails } = data.parcelDetailList;

    ParcelDetailDataTable = parcelDetails.map(
      (item, index): ParcelDetailDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <Link to={`${match.path}/detail/${item._id}`}>
              <Button color="grey--text-only" icon={<SearchOutlined />} size="small" />
            </Link>
            <Button
              onClick={() => showConfirmDownloadFile(item._id)}
              color="grey--text-only"
              icon={<FileExcelOutlined />}
              size="small"
            />
          </ToolsContainer>
        ),
        column1: item.number || '-',
        column2: item.createdAt ? formatDate(item.createdAt) : '-',
        column3: item.pickupRangeDate
          ? `${item.pickupRangeDate.substring(0, 10)} - ${item.pickupRangeDate.substring(11, 21)}`
          : '-',
        column4: item.customer?.full_name || '-',
        column5: item.totalCost ? formatCurrency(item.totalCost) : '-',
        column6: item.totalRevenue ? formatCurrency(item.totalRevenue) : '-',
        column7: (
          <Text
            customType={
              item.totalRevenue - item.totalCost === 0
                ? 'normal'
                : item.totalRevenue - item.totalCost < 0
                ? 'danger'
                : 'success'
            }
          >
            {formatCurrencyProfitLoss(item.totalRevenue - item.totalCost) || '-'}
          </Text>
        ),
      }),
    );

    totalDocument = data.parcelDetailList.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={ParcelDetailDataTable}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />
    </>
  );
};

export default ParcelDetailTable;
