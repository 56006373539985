import gql from 'graphql-tag';

export interface CreateAppData {
  createApp: {
    _id: string;
  };
}

export interface CreateAppDataVars {
  payment_voucher?: string[];
  app_status?: number;
  payment_method?: string;
  payment_method_detail?: string;
  bank_account?: {
    bank_name: string;
    bank_branch?: string;
    account_name: string;
    account_number: string;
  };
  payment_type?: string;
  round_paid?: string;
  app_remark?: string;
}

export default gql`
  mutation CreateApp(
    $payment_voucher: [ID]
    $app_status: Int
    $payment_method: String
    $payment_method_detail: String
    $bank_account: inputBankAccount
    $payment_type: String
    $round_paid: String
    $app_remark: String
  ) {
    createApp(
      createApp: {
        payment_voucher: $payment_voucher
        app_status: $app_status
        payment_method: $payment_method
        payment_method_detail: $payment_method_detail
        bank_account: $bank_account
        payment_type: $payment_type
        round_paid: $round_paid
        app_remark: $app_remark
      }
    ) {
      _id
    }
  }
`;
