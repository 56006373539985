/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Button, Col, DatePicker, Input, message, Popover, Row, Select, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { formatDate, formatDateTime } from 'shared/utils/dateTime';
import { TableSearch, TextExpand } from 'shared/components';
import formatCurrency from 'shared/utils/formatCurrency';
import COSTINGS_QUERY from 'shared/graphql/query/costings';
import { CostingProvider } from 'views/Costing/common/context/CostingContext';
import moment from 'moment';
import IconHistory from 'shared/assets/icons/history.svg';
import IconExclamationCircle from 'shared/assets/icons/exclamation-circle.svg';
import { v4 as uuidv4 } from 'uuid';
import { Link, Route, useHistory, useRouteMatch } from 'react-router-dom';
import {
  Container,
  ContentRejectContainer,
  CostingNumberContainer,
  StyledModal,
  StyledPopoverRow,
  ToolsContainer,
  TotalAfterDiscountContainer,
} from './Styles';
import ModalCostingDetail from './ModalCostingDetail';
import CostingTabs from './CostingTabs';
import SelectCustomerField from './SelectCustomerField';
import SelectVendorField from './SelectVendorField';
import SelectEmployeeField from './SelectEmployeeField';
import InputProfitFilter from './InputProfitFilter';
import SelectPaymentField from './SelectPaymentField';
import SelectInvoiceField from './SelectInvoiceField';

const { Option } = Select;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const CostingListWrapper = () => {
  return (
    <CostingProvider>
      <CostingList />
    </CostingProvider>
  );
};

const CostingList = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const [tab, setTab] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentId, setPaymentId] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [vendorId, setVendorId] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [lastSend, setLastSend] = useState('');
  const [costingNumber, setCostingNumber] = useState('');
  const [numberRef, setNumberRef] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [orderType, setOrderType] = useState('');
  const [profit, setProfit] = useState('');
  const [editStatus, setEditStatus] = useState();
  const [revenue, setRevenue] = useState('');
  const [netRevenue, setNetRevenue] = useState('');

  const { loading, data } = useQuery(COSTINGS_QUERY, {
    variables: {
      costing_number: costingNumber,
      costing_status: tab,
      last_send: lastSend,
      number_ref: numberRef,
      payer: customerId,
      payee: vendorId,
      employee: employeeId,
      profit,
      edit_status: editStatus,
      revenue,
      net_revenue: netRevenue,
      invoice: invoiceId,
      payment: paymentId,
      order_by: orderBy,
      order_type: orderType,
      page: currentPage,
      showData: pageSize,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const contentHistory = (value) => {
    return value.map((item, index) => {
      return (
        <div key={uuidv4()}>
          <Text strong>ครั้งที่ {index + 1}:</Text> ส่งเมื่อ {item.timestamp}
        </div>
      );
    });
  };

  const contentReject = (value) => {
    return (
      <ContentRejectContainer>
        <Text strong>เหตุผลการปฎิเสธ:</Text>
        <br />
        <Text> {value} </Text>
      </ContentRejectContainer>
    );
  };

  const showProfit = (value) => {
    let number = 0;
    if (value < 0) {
      number = value * -1;
      return (
        <Text strong type="danger">
          ({formatCurrency(number)})
        </Text>
      );
    }
    if (value > 0) {
      return (
        <Text strong type="success">
          {formatCurrency(value)}
        </Text>
      );
    }
    return <Text strong>{formatCurrency(value)}</Text>;
  };

  const contentTotalAfterDiscount = (groupPrice, totalDiscount, totalAfterDiscount) => {
    return (
      <>
        <StyledPopoverRow>
          <Col className="column-1">ค่าขนส่ง</Col>
          <Col className="column-2 text-right">
            {formatCurrency(groupPrice.shipping_price.cost, '-')}
          </Col>
          <Col className="column-3 text-right">
            {formatCurrency(groupPrice.shipping_price.sale, '-')}
          </Col>
        </StyledPopoverRow>
        <StyledPopoverRow>
          <Col className="column-1">ค่าบริการเสริม</Col>
          <Col className="column-2 text-right">
            {formatCurrency(groupPrice.service_price.cost, '-')}
          </Col>
          <Col className="column-3 text-right">
            {formatCurrency(groupPrice.service_price.sale, '-')}
          </Col>
        </StyledPopoverRow>
        <StyledPopoverRow>
          <Col className="column-1">ส่วนลด</Col>
          <Col className="column-2 text-right">{formatCurrency(totalDiscount.cost, '-')}</Col>
          <Col className="column-3 text-right">{formatCurrency(totalDiscount.sale, '-')}</Col>
        </StyledPopoverRow>
        <StyledPopoverRow className="divider">
          <Col className="column-1">ค่าสำรองจ่าย</Col>
          <Col className="column-2 text-right">
            {formatCurrency(groupPrice.reserve_price.sale, '-')}
          </Col>
          <Col className="column-3 text-right">
            {formatCurrency(groupPrice.reserve_price.sale, '-')}
          </Col>
        </StyledPopoverRow>
        <StyledPopoverRow>
          <Col className="column-1">ต้นทุนก่อนภาษี</Col>
          <Col className="column-2 text-right">
            <Text strong>{formatCurrency(totalAfterDiscount.cost, '-')}</Text>
          </Col>
          <Col className="column-3 text-right">
            <Text strong>-</Text>
          </Col>
        </StyledPopoverRow>
        <StyledPopoverRow>
          <Col className="column-1">รายได้ก่อนภาษี</Col>
          <Col className="column-2 text-right">
            <Text strong>-</Text>
          </Col>
          <Col className="column-3 text-right">
            <Text strong>{formatCurrency(totalAfterDiscount.sale, '-')}</Text>
          </Col>
        </StyledPopoverRow>
      </>
    );
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'วันที่ส่งรายการ',
      dataIndex: 'lastSendColumn',
      sortName: 'last_send',
      width: 180,
      search: (
        <RangePicker
          onChange={(values) =>
            values === null
              ? setLastSend('')
              : setLastSend(`${values[0].format('YYYY-MM-DD')}:${values[1].format('YYYY-MM-DD')}`)
          }
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
          ranges={{
            วันนี้: [moment(), moment()],
            สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
            เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
            ปีนี้: [moment().startOf('year'), moment().endOf('year')],
          }}
        />
      ),
    },
    {
      title: 'มีการแก้ไข',
      dataIndex: 'editStatusColumn',
      sortName: '_id',
      width: 180,
      search: (
        <Select
          onChange={(value) => {
            setEditStatus(value);
          }}
          placeholder="ค้นหา"
          allowClear
          style={{ width: 150, textAlign: 'left' }}
        >
          <Option value={1}>No</Option>
          <Option value={2}>Yes</Option>
        </Select>
      ),
    },
    {
      title: 'รหัสต้นทุน/ขาย',
      dataIndex: 'costingNumberColumn',
      sortName: '_id',
      width: 180,
      search: (
        <Input
          allowClear
          value={costingNumber}
          placeholder="ค้นหา"
          onChange={(event) => setCostingNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'รหัสงานขนส่ง',
      dataIndex: 'numberRefColumn',
      sortName: 'ref_id',
      width: 180,
      search: (
        <Input
          allowClear
          value={numberRef}
          placeholder="ค้นหา"
          onChange={(event) => setNumberRef(event.target.value)}
        />
      ),
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'payerFullNameColumn',
      width: 300,
      search: <SelectCustomerField onChange={(value) => setCustomerId(value)} />,
    },
    {
      title: 'ต้นทุนก่อนภาษี',
      dataIndex: 'totalAfterDiscountCostColumn',
      sortName: 'total_after_discount.cost',
      width: 150,
      align: 'right',
    },
    {
      title: 'รายได้ก่อนภาษี',
      dataIndex: 'totalAfterDiscountSaleColumn',
      sortName: 'total_after_discount.sale',
      width: 150,
      align: 'right',
    },
    {
      title: 'กำไร - ขาดทุน',
      dataIndex: 'profitColumn',
      width: 180,
      align: 'right',
      search: (
        <InputProfitFilter
          onChange={(value) => {
            setProfit(value);
          }}
        />
      ),
    },
    {
      title: 'อัตรากำไร',
      dataIndex: 'revenueColumn',
      width: 180,
      align: 'right',
      search: (
        <Select
          onChange={(value) => {
            setRevenue(value);
          }}
          placeholder="ค้นหา"
          allowClear
          style={{ width: 150, textAlign: 'left' }}
        >
          <Option value="">ทั้งหมด</Option>
          <Option value="<0">{'<0%'}</Option>
          <Option value="=0">0%</Option>
          <Option value="0:10">0-10%</Option>
          <Option value="10:20">10-20%</Option>
          <Option value="20:30">20-30%</Option>
          <Option value="30:40">30-40%</Option>
          <Option value="40:50">40-50%</Option>
          <Option value="50:60">50-60%</Option>
          <Option value="60:70">60-70%</Option>
          <Option value="70:80">70-80%</Option>
          <Option value="80:90">80-90%</Option>
          <Option value="90:100">90-100%</Option>
        </Select>
      ),
    },
    {
      title: 'อัตรากำไรสุทธิ',
      dataIndex: 'netRevenueColumn',
      width: 180,
      align: 'right',
      search: (
        <Select
          onChange={(value) => {
            setNetRevenue(value);
          }}
          placeholder="ค้นหา"
          allowClear
          style={{ width: 150, textAlign: 'left' }}
        >
          <Option value="">ทั้งหมด</Option>
          <Option value="<0">{'<0%'}</Option>
          <Option value="=0">0%</Option>
          <Option value="0:10">0-10%</Option>
          <Option value="10:20">10-20%</Option>
          <Option value="20:30">20-30%</Option>
          <Option value="30:40">30-40%</Option>
          <Option value="40:50">40-50%</Option>
          <Option value="50:60">50-60%</Option>
          <Option value="60:70">60-70%</Option>
          <Option value="70:80">70-80%</Option>
          <Option value="80:90">80-90%</Option>
          <Option value="90:100">90-100%</Option>
        </Select>
      ),
    },
    {
      title: 'ผู้รับเงิน',
      dataIndex: 'payeeFullNameColumn',
      width: 300,
      search: <SelectVendorField onChange={(value) => setVendorId(value)} />,
    },
    {
      title: 'ผู้ส่งราคา',
      dataIndex: 'employeeFullNameColumn',
      width: 150,
      search: <SelectEmployeeField onChange={(value) => setEmployeeId(value)} />,
    },
    {
      title: 'อ้างอิงรหัสใบสั่งจ่าย',
      dataIndex: 'paymentRefNumberColumn',
      sortName: 'payment.payment_number',
      width: 170,
      search: <SelectPaymentField onChange={(value) => setPaymentId(value)} />,
    },
    {
      title: 'อ้างอิงรหัสใบแจ้งหนี้',
      dataIndex: 'invoiceRefNumberColumn',
      sortName: 'invoice.invoice_number',
      width: 170,
      search: <SelectInvoiceField onChange={(value) => setInvoiceId(value)} />,
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'lastUpdateColumn',
      sortName: 'last_update',
      width: 150,
    },
  ];

  let costingData = [];
  let totalDocument = 0;
  if (data && data.costings) {
    const { costings } = data.costings;
    costingData = costings.map((item, index) => ({
      key: index,
      tools: (
        <ToolsContainer>
          <Link to={`${match.path}/detail/${item._id}`}>
            <Button
              type="grey--text-only"
              icon={<SearchOutlined />}
              size="small"
              style={{ marginRight: 10 }}
            />
          </Link>
        </ToolsContainer>
      ),
      lastSendColumn: formatDate(item.last_send),
      editStatusColumn: item.edit_status === 1 ? 'No' : 'Yes',
      costingNumberColumn: (
        <CostingNumberContainer>
          {item.costing_number}
          <div className="style-popover">
            <Popover placement="rightTop" content={contentHistory(item.send_history)}>
              <Button icon={<IconHistory />} size="small" shape="circle" />
            </Popover>
          </div>
          {item.reject_remark ? (
            <div>
              <Popover placement="rightTop" content={contentReject(item.reject_remark)}>
                <Button icon={<IconExclamationCircle style={{}} />} size="small" shape="circle" />
              </Popover>
            </div>
          ) : null}
        </CostingNumberContainer>
      ),
      numberRefColumn: item.number_ref,
      payerFullNameColumn: item.payer.full_name,
      totalAfterDiscountCostColumn: (
        <TotalAfterDiscountContainer>
          <Popover
            placement="rightTop"
            content={contentTotalAfterDiscount(
              item.group_price,
              item.total_discount,
              item.total_after_discount,
            )}
          >
            <div>
              <Text>{formatCurrency(item.total_after_discount.cost)}</Text>
            </div>
          </Popover>
        </TotalAfterDiscountContainer>
      ),
      totalAfterDiscountSaleColumn: (
        <TotalAfterDiscountContainer>
          <Popover
            placement="rightTop"
            content={contentTotalAfterDiscount(
              item.group_price,
              item.total_discount,
              item.total_after_discount,
            )}
          >
            <div>
              <Text>{formatCurrency(item.total_after_discount.sale)}</Text>
            </div>
          </Popover>
        </TotalAfterDiscountContainer>
      ),
      profitColumn: showProfit(item.profit),
      revenueColumn: item.revenue,
      netRevenueColumn: item.net_revenue,
      payeeFullNameColumn: item.payee.full_name,
      employeeFullNameColumn: item.employee.full_name,
      paymentRefNumberColumn:
        item.payment.length === 0 ? (
          '-'
        ) : (
          <TextExpand expand={!loading}>
            {item.payment.map((payment) => payment.payment_number).join(', ')}
          </TextExpand>
        ),
      invoiceRefNumberColumn:
        item.invoice.length === 0 ? (
          '-'
        ) : (
          <TextExpand expand={!loading}>
            {item.invoice.map((invoice) => invoice.invoice_number).join(', ')}
          </TextExpand>
        ),
      lastUpdateColumn: formatDateTime(item.last_update),
    }));

    totalDocument = data.costings.totalDocument;
  }

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={16}>
          <CostingTabs
            tab={tab}
            name="costing"
            onChange={(value) => {
              setTab(value);
              setCurrentPage(1);
            }}
          />
        </Col>
      </Row>

      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              setCurrentPage(1);
              setPageSize(value);
            }}
            defaultValue={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={costingData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value) => setCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />

      <Route
        except
        path={`${match.path}/detail/:costingId`}
        render={(routeProps) => (
          <StyledModal
            visible
            onCancel={() => {
              history.push(match.url);
            }}
            closable={false}
            className="custom-modal"
            footer={null}
            destroyOnClose
          >
            <ModalCostingDetail costingId={routeProps.match.params.costingId} backUrl={match.url} />
          </StyledModal>
        )}
      />
    </Container>
  );
};

export default CostingListWrapper;
