import gql from 'graphql-tag';
import { CodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/codStatus.model';

export interface UpdateCodData {
  updateNewCod: {
    _id: string;
    status: CodStatus;
  };
}

export interface UpdateCodVars {
  _id: string;
  cod_status?: CodStatus;
  cod_remark?: string;
  reject_remark?: string;
}

export default gql`
  mutation UpdateNewCod($_id: ID!, $cod_remark: String, $reject_remark: String, $cod_status: Int) {
    updateNewCod(
      _id: $_id
      update: { cod_remark: $cod_remark, reject_remark: $reject_remark, cod_status: $cod_status }
    ) {
      _id
      cod_status
    }
  }
`;
