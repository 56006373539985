import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { PageNotFound } from 'shared/components';
// import { CodInProvider } from 'views/CashOnDelivery/common/context/CodInContext';
import JvList from './JvList';
import JvDetail from './JvDetail';

const JvWrapper: React.FC<RouteComponentProps> = (props) => {
  return (
    // <CodInProvider>
    <Jv {...props} />
    // </CodInProvider>
  );
};

const Jv: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;

  return (
    <Switch>
      <Route exact path={`${match.path}/edit/:id`} component={JvDetail} />
      <Route exact path={`${match.path}/create`} component={JvDetail} />
      <Route exact path={`${match.path}`} component={JvList} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default JvWrapper;
