import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { PageNotFound } from 'shared/components';
// import { CodInProvider } from 'views/CashOnDelivery/common/context/CodInContext';
import CompanyList from './CompanyList';
import CompanyDetail from './CompanyDetail';

const CompanyWrapper: React.FC<RouteComponentProps> = (props) => {
  return (
    // <CodInProvider>
    <Company {...props} />
    // </CodInProvider>
  );
};

const Company: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;

  return (
    <Switch>
      <Route exact path={`${match.path}/edit/:id`} component={CompanyDetail} />
      <Route exact path={`${match.path}/create`} component={CompanyDetail} />
      <Route exact path={`${match.path}`} component={CompanyList} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default CompanyWrapper;
