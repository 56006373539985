import styled from 'styled-components';
import { Button } from 'antd';

export const StyledButton = styled(Button)`
  &.ant-btn {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export default StyledButton;
