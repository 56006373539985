import gql from 'graphql-tag';

export interface CostingDocumentsData {
  costingDocuments: {
    documents: {
      _id: string;
      create_date: string;
      document_file: string;
      delete: boolean;
    }[];
  };
}

export interface CostingDocumentsVars {
  costing_id: string;
}

export default gql`
  query CostingDocuments($costing_id: ID!) {
    costingDocuments(params: { costing_id: $costing_id, delete: false }) {
      documents {
        _id
        create_date
        document_file
        delete
      }
    }
  }
`;
