import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';
import {
  CheckCircleOutlined,
  StopOutlined,
  MailOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  BillingNoteContext,
  SelectedBillingNoteDetailType,
} from 'views/AccountReceive/common/context/BillingNoteContext';
import { AppContext } from 'shared/context/AppContext';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountReceive/Routes/BillingNote/BillingNotesTable/ModalBillingNoteDetail/model/formValues.model';
import { BillingNotesStatus } from 'views/AccountReceive/common/model/billingNoteStatus.model';
import { StyledButton, TextHeader, Container } from './Styles';
import ModalConfirmUpdate from './ModalConfirmUpdate';
import ModalConfirmCancel from './ModalConfirmCancel';
import ModalConfirmSendEmail from './ModalConfirmSendEmail';
import ModalConfirmBackStatus from './ModalConfirmBackStatus';

const Header: React.FC = () => {
  const { handleSubmit } = useFormContext<FormValues>();
  const [isOpenModalConfirmUpdate, setIsOpenModalConfirmUpdate] = useState<boolean>(false);
  const [isOpenModalConfirmCancel, setIsOpenModalConfirmCancel] = useState<boolean>(false);
  const [isOpenModalConfirmSendEmail, setIsOpenModalConfirmSendEmail] = useState<boolean>(false);
  const [isOpenModalConfirmBackStatus, setIsOpenModalConfirmBackStatus] = useState<boolean>(false);
  const { selectedBillingNoteDetail, setSelectedBillingNoteDetail } = useContext(
    BillingNoteContext,
  );
  const { isLoading, billNumber, billStatus, document } = selectedBillingNoteDetail;

  const { userDetail } = useContext(AppContext);
  const { email } = userDetail;
  const supportEmail = [
    'supattra@giztix.com',
    'anusorn.k@giztix.com',
    'lamduan.s@giztix.com',
    'prachpurim.b@giztix.com',
  ];

  const closeModalHandler = () => {
    setSelectedBillingNoteDetail(
      (prevState): SelectedBillingNoteDetailType => ({
        ...prevState,
        isOpenModal: false,
        document: '',
      }),
    );
  };

  const onSubmitBackStatusBillingNote = handleSubmit(() => {
    setIsOpenModalConfirmBackStatus(true);
  });

  const onSubmitUpdateBillingNote = handleSubmit(() => {
    setIsOpenModalConfirmUpdate(true);
  });

  const onSubmitCancelBillingNote = handleSubmit(() => {
    setIsOpenModalConfirmCancel(true);
  });

  const onSubmitSendEmailBillingNote = handleSubmit(() => {
    setIsOpenModalConfirmSendEmail(true);
  });

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>{`รหัสใบวางบิล${billNumber ? `: ${billNumber}` : ''}`}</TextHeader>
        </Col>
        <Col>
          {billStatus === BillingNotesStatus.WAITING && (
            <>
              <StyledButton
                disabled={isLoading}
                color="blue--outline"
                onClick={() => window.open(document)}
              >
                ตัวอย่างเอกสาร
              </StyledButton>

              <StyledButton
                disabled={isLoading}
                icon={<StopOutlined />}
                color="red"
                onClick={onSubmitCancelBillingNote}
              >
                ยกเลิกการใช้
              </StyledButton>

              <StyledButton
                disabled={isLoading}
                icon={<CheckCircleOutlined />}
                color="green"
                onClick={onSubmitUpdateBillingNote}
              >
                วางบิลลูกค้า
              </StyledButton>
            </>
          )}

          {(billStatus === BillingNotesStatus.BILLING_NOTE ||
            billStatus === BillingNotesStatus.COMPLETED) && (
            <>
              {supportEmail.includes(email) && (
                <StyledButton
                  disabled={isLoading}
                  icon={<RollbackOutlined />}
                  color="blue--light"
                  onClick={onSubmitBackStatusBillingNote}
                >
                  ถอยสถานะ
                </StyledButton>
              )}
              <StyledButton
                disabled={isLoading}
                color="blue--outline"
                onClick={() => window.open(document)}
              >
                เอกสารใบวางบิล
              </StyledButton>
            </>
          )}

          {billStatus === BillingNotesStatus.BILLING_NOTE && (
            <StyledButton
              disabled={isLoading}
              icon={<MailOutlined />}
              color="green"
              onClick={onSubmitSendEmailBillingNote}
            >
              ส่งใบวางบิลให้ลูกค้า
            </StyledButton>
          )}

          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>

          <ModalConfirmUpdate
            visible={isOpenModalConfirmUpdate}
            onCancel={() => setIsOpenModalConfirmUpdate(false)}
          />

          <ModalConfirmCancel
            visible={isOpenModalConfirmCancel}
            onCancel={() => setIsOpenModalConfirmCancel(false)}
          />

          <ModalConfirmSendEmail
            visible={isOpenModalConfirmSendEmail}
            onCancel={() => setIsOpenModalConfirmSendEmail(false)}
          />

          <ModalConfirmBackStatus
            visible={isOpenModalConfirmBackStatus}
            onCancel={() => setIsOpenModalConfirmBackStatus(false)}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
