import React from 'react';
import PropTypes from 'prop-types';
import StyledLogo from './Styles';
import IconLogo from './assets/logo.svg';
import IconLogoSmall from './assets/logo-small.svg';

const propTypes = {
  size: PropTypes.string,
  clicked: PropTypes.func,
};

const defaultProps = {
  size: undefined,
  clicked: undefined,
};

const logo = (props) => {
  const { size, clicked } = props;
  let icon = <IconLogo />;
  if (size === 'small') {
    icon = <IconLogoSmall />;
  }

  return (
    <StyledLogo {...props} size={size} onClick={clicked}>
      {icon}
    </StyledLogo>
  );
};

logo.propTypes = propTypes;
logo.defaultProps = defaultProps;

export default logo;
