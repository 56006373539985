/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { Text } from 'shared/components';
import { InvoiceDetail } from 'shared/graphql/query/calBillingNote';
import { StyledTable } from './Styles';

type ExpandedRowProps = {
  data: InvoiceDetail[];
};

const columns = [
  {
    title: 'วันที่อนุมัติ',
    dataIndex: 'column1',
    width: 110,
  },
  {
    title: 'รหัสต้นทุน/ขาย',
    dataIndex: 'column2',
    width: 200,
  },
  {
    title: 'รหัสงานขนส่ง',
    dataIndex: 'column3',
    width: 200,
  },
  {
    title: 'วันที่งานเสร็จสิ้น',
    dataIndex: 'column4',
    width: 160,
  },
  {
    title: 'ราคาขาย',
    dataIndex: 'column5',
    width: 130,
    align: 'right',
  },
  {
    title: '',
  },
];

const ExpandedRow: React.FC<ExpandedRowProps> = (props) => {
  const { data } = props;
  const invoiceDetailData = data.map((item, index) => {
    const { costing, number_ref, grand_total_price } = item;

    return {
      key: index,
      column1: costing?.checked_date ? formatDate(costing.checked_date) : '-',
      column2: costing?.costing_number || '-',
      column3: number_ref || '-',
      column4: costing?.completed_date ? formatDate(+costing.completed_date) : '-',
      column5: (
        <Text customType="primary" strong>
          {grand_total_price ? formatCurrency(grand_total_price) : '-'}
        </Text>
      ),
    };
  });

  return (
    <div style={{ marginTop: 10 }}>
      <StyledTable
        scroll={{ x: 'max-content' }}
        pagination={false}
        columns={columns}
        dataSource={invoiceDetailData}
        size="small"
      />
    </div>
  );
};

export default ExpandedRow;
