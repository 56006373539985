import gql from 'graphql-tag';

export interface Payee {
  _id: string;
  full_name: string;
}

export interface Employee {
  _id: string;
  full_name: string;
}

export interface PaymentVoucher {
  _id: string;
  voucher_number: string;
}

export interface Payment {
  _id: string;
  create_date: string;
  service_date: string;
  payment_number: string;
  number_ref: string;
  grand_total_price: number;
  payee: Payee;
  round_paid: string;
  employee: Employee;
  payment_voucher: PaymentVoucher;
  last_update: string;
  payment_status: number;
  payment_type: string;
  payment_remark?: string;
  document: string;
  total_price: number;
  save_status: number;
}

export interface PaymentsData {
  payments: {
    payments: Payment[];
    totalDocument: number;
  };
}

export interface PaymentsVars {
  create_date?: string;
  service_date?: string;
  payment_number?: string;
  payee?: string;
  round_paid?: string;
  employee?: string;
  payment_voucher?: string;
  page?: number;
  showData?: number;
  payment_status?: number;
  order_by?: string;
  order_type?: string;
  number_ref?: string;
  payment_type?: string;
}

export default gql`
  query Payments(
    $create_date: String
    $service_date: String
    $payment_number: String
    $payee: ID
    $round_paid: String
    $employee: ID
    $payment_voucher: ID
    $page: Int
    $showData: Int
    $payment_status: Int
    $order_by: String
    $order_type: String
    $number_ref: String
    $payment_type: String
  ) {
    payments(
      params: {
        create_date: $create_date
        service_date: $service_date
        payment_number: $payment_number
        payee: $payee
        round_paid: $round_paid
        employee: $employee
        payment_voucher: $payment_voucher
        payment_status: $payment_status
        number_ref: $number_ref
        payment_type: $payment_type
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      payments {
        _id
        create_date
        service_date
        payment_number
        number_ref
        grand_total_price
        payee {
          _id
          full_name
        }
        round_paid
        employee {
          _id
          full_name
        }
        payment_voucher {
          _id
          voucher_number
        }
        last_update
        payment_status
        payment_type
        payment_remark
        document
        total_price
        save_status
      }
      totalDocument
    }
  }
`;
