import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SubTabs } from 'shared/components';
import COD_ITEM_STATUS_QUERY, { CodItemStatusData } from 'shared/graphql/query/newCodItemStatus';
import { CodItemStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/codItemStatus.model';

interface CodItemTabsProps {
  name: string;
  tab: CodItemStatus;
  onChange: (tab: CodItemStatus) => void;
}

const CodItemTabs: React.FC<CodItemTabsProps> = (props) => {
  const { tab, onChange, name } = props;
  const { data, refetch } = useQuery<CodItemStatusData>(COD_ITEM_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const checkSelectTab = (id: CodItemStatus) => {
    return id === tab && name === 'codItem';
  };

  const selectTab = (index: CodItemStatus) => {
    refetch();
    onChange(index);
  };

  const tabs = [
    {
      id: CodItemStatus.WAIT_TO_PAY,
      name: 'รอจ่าย COD',
      color: 'badge-grey',
      count: data?.newCodItemStatus.wait_to_pay || 0,
      divider: false,
    },
    {
      id: CodItemStatus.PAID,
      name: 'สั่งจ่ายแล้ว',
      color: 'badge-grey',
      count: data?.newCodItemStatus.paid || 0,
      divider: false,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default CodItemTabs;
