/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { LoadingOutlined } from '@ant-design/icons';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { Text, Spin } from 'shared/components';
import BILLING_NOTE_QUERY, {
  BillingNoteData,
  BillingNoteVars,
  InvoiceDetail,
  BillInvoice,
} from 'shared/graphql/query/billingNote';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { ReceiptContext } from 'views/AccountReceive/common/context/ReceiptContext';
import { FormValues } from 'views/AccountReceive/Routes/Receipt/BillingNotesTable/ModalCreateReceipt/model/formValues.model';
import { StyledTable, StyledCheckbox } from './Styles';
import ExpandedRow from './ExpandedRow';

type InvoiceDataTable = {
  key: string;
  tools: ReactNode;
  column1: ReactNode;
  column2: ReactNode;
  column3: ReactNode;
  column4: ReactNode;
  InvoiceDetail: InvoiceDetail[];
};

const BillingNoteDetailTable: React.FC = () => {
  const [billInvoices, setBillInvoices] = useState<BillInvoice[]>([]);
  const { getValues, setValue, watch } = useFormContext<FormValues>();
  const selectedBillInvoices = getValues('billInvoices') ? getValues('billInvoices') : [];
  const watchBillInvoices = watch('billInvoices');
  const { selectedBillingNoteDetail } = useContext(ReceiptContext);
  const { billingNoteId } = selectedBillingNoteDetail;

  const { error, data, loading } = useQuery<BillingNoteData, BillingNoteVars>(BILLING_NOTE_QUERY, {
    variables: {
      _id: billingNoteId,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && data?.billingNote) {
      const { bill_invoice } = data.billingNote;
      setBillInvoices([...bill_invoice]);
    }
  }, [data?.billingNote, error, loading]);

  const columns = [
    {
      title: (
        <StyledCheckbox
          checked={
            selectedBillInvoices.filter((invoice) => invoice.invoice.invoice_status !== 4)
              .length ===
            billInvoices.filter((invoice) => invoice.invoice.invoice_status !== 4).length
          }
          onChange={(event) => {
            if (event.target.checked) {
              const filterBillInvoices = billInvoices.filter(
                (invoice) => invoice.invoice.invoice_status !== 4,
              );
              setValue('billInvoices', [...filterBillInvoices]);
            } else {
              setValue('billInvoices', []);
            }
          }}
        />
      ),
      dataIndex: 'tools',
      width: 70,
    },
    {
      title: 'วันที่เอกสาร',
      dataIndex: 'column1',
      width: 110,
    },
    {
      title: 'รหัสใบแจ้งหนี้',
      dataIndex: 'column2',
      width: 110,
    },
    {
      title: 'มูลค่าใบแจ้งหนี้',
      dataIndex: 'column3',
      width: 150,
      align: 'right',
    },
    {
      title: '',
      dataIndex: 'column4',
      width: 100,
    },
    {
      title: '',
    },
  ];

  const selectBillInvoice = (checked: boolean, value: BillInvoice) => {
    if (checked) {
      setValue('billInvoices', [...selectedBillInvoices, value]);
    } else {
      const billInvoicesFilter = selectedBillInvoices.filter(
        (invoice) => invoice.invoice._id !== value.invoice._id,
      );
      setValue('billInvoices', [...billInvoicesFilter]);
    }
  };

  let content: ReactNode = <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  let billingNoteData: InvoiceDataTable[] = [];

  if (billInvoices) {
    billingNoteData = billInvoices.map(
      (item): InvoiceDataTable => {
        const {
          invoice: {
            service_date,
            invoice_number,
            grand_total_price,
            invoice_detail,
            invoice_status,
          },
        } = item;

        return {
          key: uuidv4(),
          tools: (
            <StyledCheckbox
              checked={
                selectedBillInvoices
                  .map((invoice) => invoice.invoice._id)
                  .indexOf(item.invoice._id) !== -1 && invoice_status !== 4
              }
              disabled={invoice_status === 4}
              value={item.invoice._id}
              onChange={(event) => {
                selectBillInvoice(event.target.checked, item);
              }}
            />
          ),
          column1: service_date ? formatDate(service_date) : '-',
          column2: invoice_number || '-',
          column3: (
            <>
              <Text strong customType="primary">
                {formatCurrency(grand_total_price)}
              </Text>
            </>
          ),
          column4: invoice_status === 4 ? <Text customType="grey">(รับชำระแล้ว)</Text> : null,
          InvoiceDetail: invoice_detail,
        };
      },
    );

    content = (
      <>
        {watchBillInvoices && (
          <StyledTable
            scroll={{ x: 'max-content' }}
            pagination={false}
            columns={columns}
            dataSource={billingNoteData}
            size="small"
            loading={loading}
            expandable={{
              expandedRowRender: (record: { InvoiceDetail: InvoiceDetail[] }) => (
                <ExpandedRow data={record.InvoiceDetail} />
              ),
            }}
          />
        )}
      </>
    );
  }

  if (error) {
    content = error.message;
  }

  return <div>{content}</div>;
};

export default BillingNoteDetailTable;
