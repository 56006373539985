/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, ReactNode } from 'react';
import { Input, message, Row, Col, Select } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import REPORTS_QUERY, { ReportsData, ReportsVars, Report } from 'shared/graphql/query/reports';
import { Button, TableSearch, SelectReportTypeField } from 'shared/components';
import { formatDateTime } from 'shared/utils/dateTime';
import useGetReportStatus from 'views/Reports/common/hooks/useGetReportStatus';
import { ReportStatus } from 'views/Reports/common/model/reportStatus.model';
import { ToolsContainer } from './Styles';

const { Option } = Select;

type ReportListTableProps = {
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type ReportListDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: string;
  column4: string;
  column5: string;
};

const ReportListTable: React.FC<ReportListTableProps> = (props) => {
  const { pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [name, setName] = useState<string>('');
  const [reportType, setReportType] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('');
  const { reportsStatus } = useGetReportStatus();

  const { loading, error, data } = useQuery<ReportsData, ReportsVars>(REPORTS_QUERY, {
    variables: {
      name,
      report_type: reportType,
      showData: pageSize,
      page: currentPage,
      order_by: orderBy,
      order_type: orderType,
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 120,
    },
    {
      title: 'ประเภทรายงาน',
      dataIndex: 'column1',
      sortName: 'report_type',
      width: 200,
      search: <SelectReportTypeField onChange={(value) => setReportType(value)} />,
    },
    {
      title: 'ชื่อรายงาน',
      dataIndex: 'column2',
      sortName: 'name',
      width: 200,
      search: (
        <Input
          allowClear
          value={name}
          placeholder="ค้นหา"
          onChange={(event) => setName(event.target.value)}
        />
      ),
    },
    {
      title: 'ช่วงรายงาน',
      dataIndex: 'column3',
      sortName: 'date_range',
      width: 200,
    },
    {
      title: 'ชื่อผู้สร้างรายงาน',
      dataIndex: 'column4',
      sortName: 'employee.full_name',
      width: 250,
    },
    {
      title: 'วันที่สร้างรายงาน',
      dataIndex: 'column5',
      sortName: 'create_date',
      width: 150,
    },
    {
      title: '',
    },
  ];

  let reportsData: ReportListDataTable[] = [];
  let totalDocument = 0;

  const renderButton = (report: Report) => {
    const { _id, status, file } = report;

    if (status === ReportStatus.COMPLETED) {
      return (
        <Button size="small" href={`${file}`} target="_blank" color="green--outline">
          ดาวน์โหลด
        </Button>
      );
    }

    const findReport = reportsStatus
      ? reportsStatus.find((item) => {
          return item.id === _id;
        })
      : undefined;

    if (findReport && findReport.status === 2) {
      return (
        <Button size="small" href={`${findReport.file}`} target="_blank" color="green--outline">
          ดาวน์โหลด
        </Button>
      );
    }

    return (
      <Button size="small" href="#" disabled>
        รอดาวน์โหลด
      </Button>
    );
  };

  if (data?.reports) {
    const { reports } = data.reports;
    reportsData = reports.map(
      (item, index): ReportListDataTable => ({
        key: index,
        tools: <ToolsContainer>{renderButton(item)}</ToolsContainer>,
        column1: item.report_type,
        column2: item.name,
        column3: item.date_range,
        column4: item.employee?.full_name || '-',
        column5: formatDateTime(item.create_date),
      }),
    );

    totalDocument = data.reports.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={reportsData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />
    </>
  );
};

export default ReportListTable;
