/* eslint-disable camelcase */
import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'antd';
import {
  SendOutlined,
  StopOutlined,
  RedoOutlined,
  CloseCircleOutlined,
  FileZipOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { useFormikContext } from 'formik';
import { PaymentVoucherContext } from 'views/AccountPayment/common/context/PaymentVoucherContext';
import { StyledButton } from './Styles';
import ModalAddDocument from './ModalAddDocument';
import ModalConfirmReject from './ModalConfirmReject';
import ModalConfirmCancel from './ModalConfirmCancel';
import ModalConfirmWaiting from './ModalConfirmWaiting';
import ModalConfirmPending from './ModalConfirmPending';
import ModalConfirmApprove from './ModalConfirmApprove';
import ModalConfirmComplete from './ModalConfirmComplete';
import ModalCreateApp from 'views/AccountPayment/Routes/PaymentVoucher/ModalCreateApp';

const Header = () => {
  const [isOpenModalConfirmReject, setOpenModalConfirmReject] = useState(false);
  const [isOpenModalConfirmCancel, setOpenModalConfirmCancel] = useState(false);
  const [isOpenModalCreateApp, setOpenModalCreateApp] = useState(false);
  const [isOpenModalConfirmPending, setOpenModalConfirmPending] = useState(false);
  const [isOpenModalConfirmApprove, setOpenModalConfirmApprove] = useState(false);
  const [isOpenModalConfirmComplete, setOpenModalConfirmComplete] = useState(false);
  const [isOpenModalAddDocument, setOpenModalAddDocument] = useState(false);
  const {
    selectedPaymentVoucherDetail: selectedPaymentVoucherDetailContext,
    selectedPaymentVouchers: selectedPaymentVouchersContext,
  } = useContext(PaymentVoucherContext);
  const [
    selectedPaymentVoucherDetail,
    setSelectedPaymentVoucherDetail,
  ] = selectedPaymentVoucherDetailContext;
  const [, setSelectedPaymentVouchers] = selectedPaymentVouchersContext;
  const { voucherStatus, isLoading } = selectedPaymentVoucherDetail;
  const { handleSubmit } = useFormikContext();

  const closeModalHandler = () => {
    setSelectedPaymentVoucherDetail((prevState) => ({
      ...prevState,
      isOpenModal: false,
    }));
  };

  const createAppHandler = () => {
    closeModalHandler();
    setSelectedPaymentVouchers((prevState) => ({
      ...prevState,
      vouchersId: [selectedPaymentVoucherDetail.paymentVoucherId],
      isOpenModal: true,
    }));
  };

  useEffect(() => {
    return () => {
      if (!isOpenModalAddDocument) {
        setSelectedPaymentVoucherDetail((prevState) => ({
          ...prevState,
          proofSlip: '',
          dueDate: '',
        }));
      }
    };
  }, [isOpenModalAddDocument, setSelectedPaymentVoucherDetail]);

  return (
    <Row justify="space-between" align="middle">
      <Col>รหัสใบสำคัญจ่าย: {selectedPaymentVoucherDetail.voucherNumber}</Col>
      <Col>
        {voucherStatus === 1 && (
          <>
            <StyledButton
              disabled={isLoading}
              icon={<StopOutlined />}
              type="red"
              onClick={() => setOpenModalConfirmCancel(true)}
            >
              ยกเลิกการใช้
            </StyledButton>
            <ModalConfirmCancel
              visible={isOpenModalConfirmCancel}
              onCancel={() => setOpenModalConfirmCancel(false)}
            />
          </>
        )}
        {voucherStatus === 1 && (
          <>
            <StyledButton
              disabled={isLoading}
              icon={<CloseCircleOutlined />}
              type="red--light"
              onClick={() => setOpenModalConfirmReject(true)}
            >
              ปฏิเสธ
            </StyledButton>
            <ModalConfirmReject
              visible={isOpenModalConfirmReject}
              onCancel={() => setOpenModalConfirmReject(false)}
            />
          </>
        )}
        {voucherStatus === 5 && (
          <>
            <StyledButton
              disabled={isLoading}
              icon={<RedoOutlined />}
              onClick={() => setOpenModalConfirmPending(true)}
            >
              ใช้ใบสำคัญจ่ายอีกครั้ง
            </StyledButton>
            <ModalConfirmPending
              visible={isOpenModalConfirmPending}
              onCancel={() => setOpenModalConfirmPending(false)}
            />
          </>
        )}
        {voucherStatus === 3 && (
          <>
            {/* <StyledButton
              disabled={isLoading}
              icon={<FileZipOutlined />}
              type="green"
              onClick={() => setOpenModalAddDocument(true)}
            >
              บันทึกการจ่าย
            </StyledButton> */}
            <ModalAddDocument
              showConfirm={() => setOpenModalConfirmComplete(true)}
              visible={isOpenModalAddDocument}
              onCancel={() => setOpenModalAddDocument(false)}
            />
            <ModalConfirmComplete
              visible={isOpenModalConfirmComplete}
              onCancel={() => {
                setOpenModalAddDocument(false);
                setOpenModalConfirmComplete(false);
              }}
            />
          </>
        )}
        {voucherStatus === 2 && (
          <>
            <StyledButton
              disabled={isLoading}
              icon={<SendOutlined />}
              type="blue"
              onClick={() => setOpenModalConfirmApprove(true)}
            >
              อนุมัติจ่าย
            </StyledButton>
            <ModalConfirmApprove
              visible={isOpenModalConfirmApprove}
              onCancel={() => setOpenModalConfirmApprove(false)}
            />
          </>
        )}
        {(voucherStatus === 1 ||
          voucherStatus === 2 ||
          voucherStatus === 3 ||
          voucherStatus === 4) && (
          <StyledButton
            disabled={isLoading}
            icon={<SaveOutlined />}
            type="green--light"
            onClick={handleSubmit}
          >
            บันทึก
          </StyledButton>
        )}
        {voucherStatus === 1 && (
          <>
            <StyledButton
              disabled={isLoading}
              icon={<SendOutlined />}
              type="blue"
              onClick={createAppHandler}
            >
              สร้างรอบจ่าย
            </StyledButton>
          </>
        )}
        <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
      </Col>
    </Row>
  );
};

export default Header;
