/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, ReactNode } from 'react';
import { Input, message, Row, Col, Select, DatePicker } from 'antd';
import { useRouteMatch, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import JVS_QUERY, { JvsData, JvsVars } from 'shared/graphql/query/jvs';
import { JvStatus } from 'views/Setting/common/model/jvStatus.model';
import { Button, TableSearch, Text } from 'shared/components';
import { formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import { SearchOutlined } from '@ant-design/icons';
import { ToolsContainer } from './Styles';

const { Option } = Select;
const { RangePicker } = DatePicker;

type JvTableProps = {
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type JvDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: string;
  column4: string;
  column5: ReactNode;
  column6: string;
  column7: string;
};

const JvTable: React.FC<JvTableProps> = (props) => {
  const match = useRouteMatch();
  const { pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [jvNumber, setJvNumber] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [accountName, setAccountName] = useState<string>('');
  const [documentDate, setDocumentDate] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('1');

  const { loading, error, data } = useQuery<JvsData, JvsVars>(JVS_QUERY, {
    variables: {
      jv_status: JvStatus.USE,
      jv_number: jvNumber,
      description,
      code,
      account_name: accountName,
      document_date: documentDate,
      showData: pageSize,
      page: currentPage,
      order_by: orderBy,
      order_type: orderType,
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'รหัสเอกสาร',
      dataIndex: 'column1',
      width: 150,
      sortName: 'number',
      search: (
        <Input
          allowClear
          placeholder="ค้นหา"
          onChange={(event) => setJvNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'คำอธิบาย',
      dataIndex: 'column2',
      width: 300,
      search: (
        <Input
          allowClear
          placeholder="ค้นหา"
          onChange={(event) => setDescription(event.target.value)}
        />
      ),
    },
    {
      title: 'รหัสบัญชี',
      dataIndex: 'column3',
      width: 150,
      search: (
        <Input allowClear placeholder="ค้นหา" onChange={(event) => setCode(event.target.value)} />
      ),
    },
    {
      title: 'ชื่อบัญชี',
      dataIndex: 'column4',
      width: 300,
      search: (
        <Input
          allowClear
          placeholder="ค้นหา"
          onChange={(event) => setAccountName(event.target.value)}
        />
      ),
    },
    {
      title: 'ยอด',
      dataIndex: 'column5',
      width: 150,
      align: 'right',
    },
    {
      title: 'วันที่เอกสาร',
      dataIndex: 'column6',
      width: 180,
      search: (
        <RangePicker
          onChange={(values) => {
            if (values) {
              const formatRangDate = `${values[0]?.format('YYYY-MM-DD')}:${values[1]?.format(
                'YYYY-MM-DD',
              )}`;
              return setDocumentDate(formatRangDate);
            }
            setDocumentDate('');
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'วันที่แก้ไขล่่าสุด',
      dataIndex: 'column7',
      width: 150,
    },
  ];

  let jvDataTable: JvDataTable[] = [];
  let totalDocument = 0;

  if (data?.jvs) {
    const { jvs } = data.jvs;
    jvDataTable = jvs.map(
      (item, index): JvDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <Link to={`${match.path}/edit/${item._id}`}>
              <Button color="grey--text-only" icon={<SearchOutlined />} size="small" />
            </Link>
          </ToolsContainer>
        ),
        column1: item.jv_number || '-',
        column2: item.description || '-',
        column3: item.code || '-',
        column4: item.account_name || '-',
        column5: (
          <Text strong customType="primary">
            {formatCurrency(item.value)}
          </Text>
        ),
        column6: formatDateTime(item.document_date),
        column7: formatDateTime(item.last_update),
      }),
    );

    totalDocument = data.jvs.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={jvDataTable}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />
    </>
  );
};

export default JvTable;
