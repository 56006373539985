/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { ModalTopButton, Text } from 'shared/components';
import { Row, Col, Divider, Skeleton, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { formatDate } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import { useQuery } from '@apollo/react-hooks';
import PARCEL_QUERY, { ParcelData, ParcelVars } from 'shared/graphql/query/parcel';
import formatCurrencyProfitLoss from 'shared/utils/formatCurrencyProfitLoss';
import TagStatus from './TagStatus';

interface ModalParcelDetailProps {
  parcelId: string;
  goBack: string;
}

const ModalParcelDetail: React.FC<ModalParcelDetailProps> = (props) => {
  const { parcelId, goBack } = props;
  const history = useHistory();

  const { loading, error, data } = useQuery<ParcelData, ParcelVars>(PARCEL_QUERY, {
    variables: {
      _id: parcelId,
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = <Skeleton />;

  if (data?.parcel) {
    const {
      trackingNumber,
      customer,
      itemName,
      width,
      length,
      height,
      weight,
      status,
      parcelCost,
      deliveredDate,
      parcelRevenue,
    } = data.parcel;

    const getCheckParcelRevenueByType = (jobIdCost: string, jobTypeCost: string) => {
      if (jobIdCost === parcelRevenue?.jobId && (jobTypeCost === 'LM' || jobTypeCost === 'RM')) {
        return true;
      }
      return false;
    };

    const getSumTotalCost = _.sumBy(parcelCost, 'totalCostAvg');

    const getSumProfitLoss = () => {
      if (parcelCost.length > 0 && parcelRevenue && parcelRevenue.totalRevenue) {
        return formatCurrencyProfitLoss(parcelRevenue.totalRevenue - getSumTotalCost);
      }
      if (parcelCost.length > 0 && !parcelRevenue) {
        return formatCurrencyProfitLoss(0 - getSumTotalCost);
      }
      if (parcelCost.length === 0 && parcelRevenue && parcelRevenue.totalRevenue) {
        return formatCurrencyProfitLoss(parcelRevenue.totalRevenue - 0);
      }
      return '-';
    };

    const getSumPercentProfitLoss = () => {
      if (parcelCost.length > 0 && parcelRevenue && parcelRevenue.totalRevenue) {
        const profit = parcelRevenue?.totalRevenue - getSumTotalCost;
        const profitLoss = (profit / getSumTotalCost) * 100;
        return profitLoss.toFixed(2);
      }
      if (parcelCost.length > 0 && !parcelRevenue) {
        const profit = 0 - getSumTotalCost;
        const profitLoss = (profit / getSumTotalCost) * 100;
        return profitLoss.toFixed(2);
      }
      if (parcelCost.length === 0 && parcelRevenue && parcelRevenue.totalRevenue) {
        return (100).toFixed(2);
      }

      return '-';
    };

    content = (
      <ModalTopButton
        visible
        onCancel={() => {
          history.push(goBack);
        }}
        title={trackingNumber}
        width={900}
      >
        <>
          <Row gutter={[8, 0]}>
            <Col span={4}>
              <Text>ลูกค้า:</Text>
            </Col>
            <Col span={20}>
              <Text strong>{customer?.full_name || '-'}</Text>
            </Col>
            <Col span={4}>
              <Text>ชื่อสินค้า:</Text>
            </Col>
            <Col span={20}>
              <Text strong>{itemName || '-'}</Text>
            </Col>
            <Col span={4}>
              <Text>ขนาด:</Text>
            </Col>
            <Col span={20}>
              <Text strong>{`${width} x ${length} x ${height} cm.`}</Text>
            </Col>
            <Col span={4}>
              <Text>น้ำหนัก:</Text>
            </Col>
            <Col span={20}>
              <Text strong>{weight} kg.</Text>
            </Col>
            <Col span={4}>
              <Text>สถานะ:</Text>
            </Col>
            <Col span={20}>
              <TagStatus status={status} />
            </Col>
            <Col span={4}>
              <Text>วันที่ส่งสำเร็จ:</Text>
            </Col>
            <Col span={20}>
              <Text strong>{deliveredDate ? formatDate(deliveredDate) : '-'}</Text>
            </Col>
          </Row>

          <Divider style={{ marginTop: 10, marginBottom: 10 }} />

          <div style={{ maxHeight: 'calc(100vh - 600px)', overflowX: 'hidden', overflowY: 'auto' }}>
            <div style={{ position: 'sticky', top: 0, backgroundColor: '#FFF', zIndex: 100 }}>
              <Row gutter={[8, 0]}>
                <Col span={9}>
                  <Text strong>รายการ</Text>
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                  <Text strong>ต้นทุน (บาท)</Text>
                </Col>
                <Col span={5} style={{ textAlign: 'right' }}>
                  <Text strong>รายได้ (บาท)</Text>
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                  <Text strong>กำไร/ขาดทุน (บาท)</Text>
                </Col>
              </Row>
            </div>
            {parcelCost.length > 0 ? (
              parcelCost.map((item) => (
                <Row gutter={[8, 0]} key={uuidv4()}>
                  <Col span={9}>
                    <Text>{item.refNumber || '-'}</Text>
                  </Col>
                  <Col span={4} style={{ textAlign: 'right' }}>
                    <Text>
                      {item.totalCostAvg ? formatCurrency(item.totalCostAvg) : formatCurrency(0)}
                    </Text>
                  </Col>
                  <Col span={5} style={{ textAlign: 'right' }}>
                    <Text>
                      {getCheckParcelRevenueByType(item.jobId, item.jobType)
                        ? formatCurrency(parcelRevenue?.totalRevenue)
                        : formatCurrency(0)}
                    </Text>
                  </Col>
                  <Col span={6} style={{ textAlign: 'right' }}>
                    <Text
                      customType={
                        getCheckParcelRevenueByType(item.jobId, item.jobType) &&
                        parcelRevenue?.totalRevenue - item.totalCostAvg > 0
                          ? 'success'
                          : 'normal'
                      }
                    >
                      {getCheckParcelRevenueByType(item.jobId, item.jobType)
                        ? formatCurrencyProfitLoss(parcelRevenue?.totalRevenue - item.totalCostAvg)
                        : formatCurrencyProfitLoss(0 - item.totalCostAvg)}
                    </Text>
                  </Col>
                </Row>
              ))
            ) : (
              <Text>ไม่มีรายการ</Text>
            )}
          </div>

          <Divider style={{ marginTop: 10, marginBottom: 10 }} />

          <Row gutter={[8, 0]} justify="end">
            <Col span={5} style={{ textAlign: 'right' }}>
              <Text>ต้นทุนค่าขนส่งรวม (บาท) :</Text>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Text strong>
                {parcelCost.length > 0 ? formatCurrency(getSumTotalCost) : formatCurrency(0)}
              </Text>
            </Col>
          </Row>
          <Row gutter={[8, 0]} justify="end">
            <Col span={5} style={{ textAlign: 'right' }}>
              <Text>รายได้รวม (บาท) :</Text>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Text strong>
                {parcelRevenue?.totalRevenue
                  ? formatCurrency(parcelRevenue?.totalRevenue)
                  : formatCurrency(0)}
              </Text>
            </Col>
          </Row>
          <Row gutter={[8, 0]} justify="end">
            <Col span={5} style={{ textAlign: 'right' }}>
              <Text>กำไร/ขาดทุนรวม (บาท) :</Text>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Text
                strong
                customType={
                  parcelRevenue?.totalRevenue - getSumTotalCost > 0 ? 'success' : 'danger'
                }
              >
                {getSumProfitLoss()}
              </Text>
              <Text
                strong
                customType={
                  parcelRevenue?.totalRevenue - getSumTotalCost > 0 ? 'success' : 'danger'
                }
                style={{ fontSize: 16 }}
              >
                &nbsp;&nbsp;({getSumPercentProfitLoss()}%)
              </Text>
            </Col>
          </Row>
        </>
      </ModalTopButton>
    );
  }

  if (error) {
    content = <div>{error.message}</div>;
  }

  return <Spin spinning={loading}>{content}</Spin>;
};

export default ModalParcelDetail;
