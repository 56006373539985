/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode } from 'react';
import PAYMENT_VOUCHERS_QUERY, {
  PaymentVouchersData,
  PaymentVouchersVars,
} from 'shared/graphql/query/paymentVouchers';
import { useQuery } from '@apollo/react-hooks';
import { Input, DatePicker, message, Row, Col } from 'antd';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { Text, SelectPayeesField } from 'shared/components';
import { StyledModal, StyledTable, StyledCheckbox, ToolsContainer } from './Styles';
import Header from './Header';

const { RangePicker } = DatePicker;

type PaymentVoucher = {
  id: string;
  createDate: string;
  paymentVoucherNumber: string;
  roundPaid: string;
  payee: string;
  grandTotalPrice: number;
};

type ModalAddPaymentVoucherWrapperProps = {
  visible: boolean;
  paymentVouchers?: PaymentVoucher[];
  onCancel: () => void;
  onOk: (value: PaymentVoucher[]) => void;
};

type ModalAddPaymentVoucherProps = {
  paymentVouchers: PaymentVoucher[];
  onCancel: () => void;
  onOk: (value: PaymentVoucher[]) => void;
};

type PaymentVoucherDataTable = {
  key: number;
  tools: ReactNode;
  createDateColumn: string;
  paymentVoucherNumberColumn: string;
  roundPaidColumn: string;
  payeeColumn: string;
  grandTotalPriceColumn: ReactNode;
};

const ModalAddPaymentVoucherWrapper: React.FC<ModalAddPaymentVoucherWrapperProps> = (props) => {
  const { visible, onCancel, paymentVouchers, onOk } = props;
  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalAddPaymentVoucher
        onCancel={onCancel}
        paymentVouchers={paymentVouchers || []}
        onOk={onOk}
      />
    </StyledModal>
  );
};

const ModalAddPaymentVoucher: React.FC<ModalAddPaymentVoucherProps> = (props) => {
  const { onCancel, paymentVouchers, onOk } = props;
  const [selectedPaymentVouchers, setSelectedPaymentVouchers] = useState<PaymentVoucher[]>(
    paymentVouchers,
  );
  const [createDate, setCreateDate] = useState<string>('');
  const [paymentVoucherNumber, setPaymentVoucherNumber] = useState<string>('');
  const [roundPaid, setRoundPaid] = useState<string>('');
  const [payeeId, setPayeeId] = useState<string>();
  const [pageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { loading, data } = useQuery<PaymentVouchersData, PaymentVouchersVars>(
    PAYMENT_VOUCHERS_QUERY,
    {
      variables: {
        create_date: createDate,
        voucher_number: paymentVoucherNumber,
        round_paid: roundPaid,
        payee: payeeId,
        voucher_status: 1,
        page: currentPage,
        showData: pageSize,
      },
      onError: (err) => {
        message.error(err.message);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">วันที่เอกสาร</div>
          <div>
            <RangePicker
              onChange={(values) =>
                values === null
                  ? setCreateDate('')
                  : setCreateDate(
                      `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                    )
              }
              format="YYYY/MM/DD"
              placeholder={['เริ่มต้น', 'สิ้นสุด']}
            />
          </div>
        </div>
      ),
      dataIndex: 'createDateColumn',
      width: 160,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">รหัสใบสำคัญจ่าย</div>
          <div>
            <Input
              allowClear
              value={paymentVoucherNumber}
              placeholder="ค้นหา"
              onChange={(event) => setPaymentVoucherNumber(event.target.value)}
            />
          </div>
        </div>
      ),
      dataIndex: 'paymentVoucherNumberColumn',
      width: 150,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">รอบจ่าย</div>
          <div>
            <RangePicker
              onChange={(values) =>
                values === null
                  ? setRoundPaid('')
                  : setRoundPaid(
                      `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                    )
              }
              format="YYYY/MM/DD"
              placeholder={['เริ่มต้น', 'สิ้นสุด']}
            />
          </div>
        </div>
      ),
      dataIndex: 'roundPaidColumn',
      width: 160,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">ผู้รับเงิน</div>
          <div>
            <SelectPayeesField
              onChange={(value) => (value ? setPayeeId(value.payeeId) : setPayeeId(undefined))}
            />
          </div>
        </div>
      ),
      dataIndex: 'payeeColumn',
      width: 150,
    },
    {
      title: (
        <div className="table-header">
          <div className="table-header__text">จำนวนตั้งเบิก (บาท)</div>
        </div>
      ),
      dataIndex: 'grandTotalPriceColumn',
      width: 100,
      align: 'right',
    },
  ];

  const selectPaymentVouchers = (checked: boolean, value: PaymentVoucher) => {
    if (checked) {
      setSelectedPaymentVouchers((prevState) => {
        return [...prevState, value];
      });
    } else {
      const invoicesFilter = selectedPaymentVouchers.filter(
        (paymentVoucher) => paymentVoucher.id !== value.id,
      );
      setSelectedPaymentVouchers([...invoicesFilter]);
    }
  };

  let paymentVouchersData: PaymentVoucherDataTable[] = [];
  let totalDocument = 0;

  if (data && data.paymentVouchers) {
    paymentVouchersData = data.paymentVouchers.paymentVouchers.map(
      (item, index): PaymentVoucherDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <StyledCheckbox
              checked={
                selectedPaymentVouchers
                  .map((paymentVoucher) => paymentVoucher.id)
                  .indexOf(item._id) !== -1
              }
              value={item._id}
              onChange={(event) =>
                selectPaymentVouchers(event.target.checked, {
                  id: item._id,
                  createDate: item.create_date,
                  paymentVoucherNumber: item.voucher_number,
                  roundPaid: item.round_paid,
                  payee: item.payee.full_name,
                  grandTotalPrice: item.grand_total_price,
                })
              }
            />
          </ToolsContainer>
        ),
        createDateColumn: !item.create_date ? '-' : formatDate(item.create_date),
        paymentVoucherNumberColumn: item.voucher_number || '-',
        roundPaidColumn: !item.round_paid ? '-' : formatDate(item.round_paid),
        payeeColumn: !item.payee?.full_name ? '-' : item.payee.full_name,
        grandTotalPriceColumn: (
          <Text strong customType="primary">
            {formatCurrency(item.grand_total_price)}
          </Text>
        ),
      }),
    );

    totalDocument = data.paymentVouchers.totalDocument;
  }

  return (
    <div>
      <Header
        onSubmit={() => onOk(selectedPaymentVouchers)}
        onCancel={onCancel}
        btnDisabled={selectedPaymentVouchers.length === 0}
      />

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <StyledTable
            scroll={{ x: 'max-content' }}
            columns={columns}
            dataSource={paymentVouchersData}
            loading={loading}
            pagination={{
              current: currentPage,
              pageSize,
              total: totalDocument,
              showSizeChanger: false,
              onChange: (value: number) => setCurrentPage(value),
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ModalAddPaymentVoucherWrapper;
