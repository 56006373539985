import gql from 'graphql-tag';

export interface ParcelDetailStatusData {
  parcelDetailStatus: {
    waitInvoice: number;
    createInvoice: number;
    cancel: number;
  };
}

export default gql`
  query ParcelDetailStatus {
    parcelDetailStatus {
      waitInvoice
      createInvoice
      cancel
    }
  }
`;
