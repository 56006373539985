import React, { useContext } from 'react';
import {
  InvoiceContext,
  CreateInvoiceType,
} from 'views/AccountReceive/common/context/InvoiceContext';
import { useForm, FormProvider } from 'react-hook-form';
import Header from './Header';
import CreateInvoice from './CreateInvoice';
import { StyledModal } from './Styles';

type FormValues = {
  invoicesId: string[];
  customerId: string;
  customerFullName: string;
};

const ModalCreateInvoiceWrapper: React.FC = () => {
  const { createInvoice, setCreateInvoice } = useContext(InvoiceContext);

  return (
    <StyledModal
      visible={createInvoice.isOpenModal}
      onCancel={() => {
        setCreateInvoice(
          (prevState): CreateInvoiceType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalCreateInvoice />
    </StyledModal>
  );
};

const ModalCreateInvoice: React.FC = () => {
  // const { selectedInvoices } = useContext(BillingNoteContext);
  // const { isLoading, invoicesId, payerId, payerFullName } = selectedInvoices;
  // const validateSchema = yup.object().shape({
  //   invoicesId: yup.array().required(),
  //   customerId: yup.string().required(),
  //   customerFullName: yup.string().required(),
  // });

  const methods = useForm<FormValues>({
    // resolver: yupResolver(validateSchema),
    // defaultValues: {
    //   invoicesId: [],
    //   customerId: '',
    //   customerFullName: '',
    // },
  });

  const { handleSubmit } = methods;

  // useEffect(() => {
  //   register('invoicesId');
  //   register('customerId');
  //   register('customerFullName');
  // }, [register]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     reset({
  //       invoicesId,
  //       customerId: payerId,
  //       customerFullName: payerFullName,
  //     });
  //   }, 0);
  // }, [invoicesId, payerFullName, payerId, reset]);

  const onSubmit = () => {
    alert('submit');
  };

  return (
    // <Spin spinning={isLoading}>
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header />
        <CreateInvoice />
      </form>
    </FormProvider>
    // </Spin>
  );
};

export default ModalCreateInvoiceWrapper;
