/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useState } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Col, Divider, Input, message, Row, Typography } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { ParcelCostingContext } from 'views/Costing/common/context/ParcelCostingContext';
import REJECT_PARCEL_COST_MUTATION, {
  RejectParcelCostData,
  RejectParcelCostVars,
} from 'shared/graphql/mutation/rejectParcelCost';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button } from 'shared/components';
import { useHistory } from 'react-router-dom';
import { StyledModal } from './Styles';

const { Text } = Typography;
const { TextArea } = Input;
type ModalConfirmRejectProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmReject: React.FC<ModalConfirmRejectProps> = (props) => {
  const { onCancel, visible } = props;
  const history = useHistory();
  const [rejectRemark, setRejectRemark] = useState<string>('');
  const { selectedCostingDetail } = useContext(ParcelCostingContext);
  const { modalConfirmReject, costingId, backUrl } = selectedCostingDetail;
  const { costingNumber, grandTotalPrice } = modalConfirmReject;

  const closeModalHandler = () => {
    history.push(backUrl);
  };

  const [rejectParcelCost, { loading }] = useMutation<RejectParcelCostData, RejectParcelCostVars>(
    REJECT_PARCEL_COST_MUTATION,
    {
      update: (_cache, { data }) => {
        const { _id } = data!.rejectParcelCost;
        if (_id) {
          message.success('เปลี่ยนสถานะต้นทุน/ขายแล้ว');
          onCancel();
          closeModalHandler();
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['ParcelCost', 'ParcelCostStatus', 'ParcelCostList'],
    },
  );

  const confirmReject = () => {
    rejectParcelCost({
      variables: {
        _id: costingId,
        reject_remark: rejectRemark,
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="คุณต้องการปฎิเสธใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CloseCircleOutlined />}
          color="red--light"
          onClick={() => confirmReject()}
          loading={loading}
          disabled={rejectRemark === ''}
        >
          ยืนยันปฎิเสธราคา
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
      afterClose={() => setRejectRemark('')}
    >
      <div>
        ต้องการปฎิเสธราคา <Text strong>รหัสต้นทุน/ขาย: {costingNumber}</Text>
      </div>
      <div>
        ยอดรวม <Text strong>{formatCurrency(grandTotalPrice)} บาท</Text> ใช่หรือไม่?
      </div>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Row>
        <Col span={24}>
          เหตุผลการปฎิเสธ:<Text type="danger">*</Text>
        </Col>
        <Col span={24}>
          <TextArea
            value={rejectRemark}
            onChange={(event) => setRejectRemark(event.target.value)}
            placeholder="ระบุเหตุผล"
            rows={2}
          />
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmReject;
