import gql from 'graphql-tag';

export interface SystemRequestData {
  systemRequest: {
    system_request: string[];
  };
}

export default gql`
  query SystemRequest {
    systemRequest {
      system_request
    }
  }
`;
