import gql from 'graphql-tag';

export interface BankAccounts {
  bank_name: string;
  bank_branch: string;
  account_name: string;
  account_number: string;
}

export interface BankAccountsData {
  bankAccounts: {
    bankAccounts: BankAccounts[];
  };
}

export default gql`
  query BankAccounts {
    bankAccounts {
      bankAccounts {
        bank_name
        bank_branch
        account_name
        account_number
      }
    }
  }
`;
