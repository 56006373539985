import gql from 'graphql-tag';
import { VendorType } from 'views/Setting/common/model/vendorType.model';
import { VendorStatus } from 'views/Setting/common/model/vendorStatus.model';

export interface UpdateVendorData {
  updateVendor: {
    _id: string;
  };
}

export interface UpdateVendorVars {
  _id: string;
  vendor_type?: VendorType;
  full_name?: string;
  system_request?: string;
  tax_id?: string;
  address?: string;
  branch?: string;
  country?: string;
  province?: string;
  district?: string;
  sub_district?: string;
  postcode?: string;
  phone?: string[];
  staff_phone?: string;
  email?: string[];
  doc_address?: string;
  doc_sub_district?: string;
  doc_district?: string;
  doc_province?: string;
  doc_postcode?: string;
  doc_tax_id?: string;
  bank_account?: {
    bank_name: string;
    bank_branch?: string;
    account_name: string;
    account_number: string;
  };
  checkbox_address_company?: boolean;
  vendor_status?: VendorStatus;
}

export default gql`
  mutation UpdateVendor(
    $_id: ID!
    $vendor_type: Int
    $full_name: String
    $system_request: String
    $tax_id: String
    $address: String
    $branch: String
    $country: String
    $province: String
    $district: String
    $sub_district: String
    $postcode: String
    $phone: [String]
    $staff_phone: String
    $email: [String]
    $doc_address: String
    $doc_sub_district: String
    $doc_district: String
    $doc_province: String
    $doc_postcode: String
    $doc_tax_id: String
    $bank_account: [inputBankAccount]
    $checkbox_address_company: Boolean
    $vendor_status: Int
  ) {
    updateVendor(
      _id: $_id
      updateVendor: {
        vendor_type: $vendor_type
        full_name: $full_name
        system_request: $system_request
        tax_id: $tax_id
        address: $address
        branch: $branch
        country: $country
        province: $province
        district: $district
        sub_district: $sub_district
        postcode: $postcode
        phone: $phone
        staff_phone: $staff_phone
        email: $email
        doc_address: $doc_address
        doc_sub_district: $doc_sub_district
        doc_district: $doc_district
        doc_province: $doc_province
        doc_postcode: $doc_postcode
        doc_tax_id: $doc_tax_id
        bank_account: $bank_account
        checkbox_address_company: $checkbox_address_company
        vendor_status: $vendor_status
      }
    ) {
      _id
    }
  }
`;
