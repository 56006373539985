import React from 'react';
import { Input } from 'antd';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface InputNumberFormatProps extends NumberFormatProps {
  style?: React.CSSProperties;
}

const InputNumberFormat: React.FC<InputNumberFormatProps> = (props) => {
  const { style, ...otherProps } = props;
  return (
    <NumberFormat
      thousandSeparator
      decimalScale={2}
      customInput={Input}
      allowNegative={false}
      placeholder="0.00"
      style={{ textAlign: 'right', ...style }}
      {...otherProps}
    />
  );
};

export default InputNumberFormat;
