import * as yup from 'yup';

const validateSchema = yup.object().shape({
  fullName: yup.string().required('กรุณากรอกข้อมูล'),
  systemRequest: yup.string().required('กรุณากรอกข้อมูล'),
  taxId: yup
    .string()
    .matches(/^\d{13}$/, 'รูปแบบไม่ถูกต้อง')
    .required('กรุณากรอกข้อมูล'),
  address: yup.string().required('กรุณากรอกข้อมูล'),
  country: yup.string().required('กรุณากรอกข้อมูล'),
  province: yup.string().required('กรุณากรอกข้อมูล'),
  district: yup.string().required('กรุณากรอกข้อมูล'),
  subDistrict: yup.string().required('กรุณากรอกข้อมูล'),
  postCode: yup
    .string()
    .matches(/^\d{5}$/, 'รูปแบบไม่ถูกต้อง')
    .required('กรุณากรอกข้อมูล'),
  phone: yup
    .string()
    .matches(/^[0]\d{8,9}$/, 'รูปแบบไม่ถูกต้อง')
    .required('กรุณากรอกข้อมูล'),
  staffPhone: yup.string().matches(/(^$|^[0]\d{8,9}$)/, 'รูปแบบไม่ถูกต้อง'),
  email: yup
    .array()
    .required('กรุณากรอกข้อมูล')
    .of(
      yup.object().shape({
        text: yup.string().email(),
      }),
    ),
  docAddress: yup.string().when('chkSelectAddress', {
    is: (value) => !value,
    then: yup.string().required('กรุณากรอกข้อมูล'),
  }),
  docProvince: yup.string().when('chkSelectAddress', {
    is: (value) => !value,
    then: yup.string().required('กรุณากรอกข้อมูล'),
  }),
  docDistrict: yup.string().when('chkSelectAddress', {
    is: (value) => !value,
    then: yup.string().required('กรุณากรอกข้อมูล'),
  }),
  docSubDistrict: yup.string().when('chkSelectAddress', {
    is: (value) => !value,
    then: yup.string().required('กรุณากรอกข้อมูล'),
  }),
  docPostcode: yup.string().when('chkSelectAddress', {
    is: (value) => !value,
    then: yup
      .string()
      .matches(/^\d{5}$/, 'รูปแบบไม่ถูกต้อง')
      .required('กรุณากรอกข้อมูล'),
  }),
  docTaxId: yup.string().when('chkSelectAddress', {
    is: (value) => !value,
    then: yup
      .string()
      .matches(/^\d{13}$/, 'รูปแบบไม่ถูกต้อง')
      .required('กรุณากรอกข้อมูล'),
  }),
  bankName: yup.string().required('กรุณากรอกข้อมูล'),
  bankBranch: yup.string().required('กรุณากรอกข้อมูล'),
  accountName: yup.string().required('กรุณากรอกข้อมูล'),
  accountNumber: yup
    .string()
    .matches(/^[\d]+$/, 'รูปแบบไม่ถูกต้อง')
    .required('กรุณากรอกข้อมูล'),
  chkSelectAddress: yup.boolean(),
});

export default validateSchema;
