import React, { ReactNode, Fragment } from 'react';
import { Row, Col } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { ButtonType } from '../../Button';
import { StyledButton, TextHeader, Container } from './Styles';

type ButtonHeader = {
  text: string;
  clicked?: () => void;
  color?: ButtonType;
  visible?: boolean;
  icon?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
};

type ModalHeaderProps = {
  onClose: () => void;
  title: string;
  btnHeader: ButtonHeader[];
};

const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  const { onClose, title, btnHeader } = props;

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>{title}</TextHeader>
        </Col>

        <Col>
          {btnHeader.map((btn) => (
            <Fragment key={uuidv4()}>
              {btn.visible && (
                <StyledButton
                  icon={btn.icon}
                  color={btn.color}
                  onClick={() => (btn.clicked ? btn.clicked() : {})}
                  loading={btn.loading}
                  disabled={btn.disabled}
                >
                  {btn.text}
                </StyledButton>
              )}
            </Fragment>
          ))}

          <StyledButton onClick={onClose}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default ModalHeader;
