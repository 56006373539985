const initialWhtList = [
  {
    description: '1. เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส ฯลฯ ตามมาตรา 40(1)',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
  },
  {
    description: '2. ค่าธรรมเนียม ค่านายหน้า ฯลฯ ตามมาตรา 40(2)',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
  },
  {
    description: '3. ค่าแห่งลิขสิทธิ์ ฯลฯ ตามมาตรา 40(3)',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
  },
  {
    description: '4. (ก) ค่าดอกเบี้ย ฯลฯ ตามมาตรา 40(4) (ก)',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
  },
  {
    description: '(ข) เงินปันผล เงินส่วนแบ่งกำไร ฯลฯ ตามมาตรา 40(4) (ข)',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
  },
  {
    description:
      '(1) กรณีผู้ได้รับเงินปันผลได้รับเครดิตภาษี โดยจ่ายจากกำไรสุทธิของกิจการที่ต้องเสียภาษีเงินได้นิติบุคคลในอัตราดังนี้',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
  },
  {
    description: '(1.1) อัตราร้อยละ 30 ของกำไรสุทธิ',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
  },
  {
    description: '(1.2) อัตราร้อยละ 25 ของกำไรสุทธิ',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
  },
  {
    description: '(1.3) อัตราร้อยละ 20 ของกำไรสุทธิ',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
  },
  {
    description: '(1.4) อัตราอื่นๆ ระบุ',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: 'ของกำไรสุทธิ',
    isHasInput1: true,
  },
  {
    description: '(2) กรณีผู้ได้รับเงินปันผลไม่ได้รับเครดิตภาษี เนื่องจากจ่ายจาก',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
  },
  {
    description: '(2.1) กำไรสุทธิของกิจการที่ได้รับยกเว้นภาษีเงินได้นิติบุคคล',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
  },
  {
    description:
      '(2.2) เงินปันผลหรือเงินส่วนแบ่งของกำไรที่ได้รับยกเว้นไม่ต้องนำรวมคำนวณเป็นรายได้เพื่อเสียภาษีเงินได้นิติบุคคล',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
  },
  {
    description:
      '(2.3) กำไรสุทธิส่วนที่ได้หักผลขาดทุนสุทธิยกมาไม่เกิน 5 ปี ก่อนรอบระยะเวลาบัญชีปัจจุบัน',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
  },
  {
    description: '(2.4) กำไรที่ได้รับรู้ทางบัญชีโดยวิธีส่วนได้เสีย (equity method)',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
  },
  {
    description: '(2.5) อัตราอื่นๆ (ระบุ)',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
    isHasInput1: true,
  },
  {
    description:
      '5. การจ่ายเงินได้ที่ต้องหักภาษี ณ ที่จ่ายตามคำสั่งกรมสรรพกรที่ออกตามมาตรา 3 เตรส (ระบุ)',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2:
      'เช่น รางวัล ส่วนลด หรือประโยชน์ใดๆ เนื่องจากการส่งเสริมการขายรางวัลในการประกวด การแข่งขัน การชิงโชค ค่าแสดง ของนักแสดงสาธารณะ ค่าจ้างทำของ ค่าโฆษณา ค่าเช่า ค่าขนส่ง ค่าบริการ ค่าเบี้ยประกันวินาศภัย ฯลฯ',
    isHasInput1: true,
  },
  {
    description: '6. อื่นๆ (ระบุ)',
    date: '',
    amount: '',
    tax: '',
    input1: '',
    input2: '',
    isHasInput1: true,
  },
];

export default initialWhtList;
