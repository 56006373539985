/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import { Col } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import formatCurrency from 'shared/utils/formatCurrency';
import { Text, Spin } from 'shared/components';
import BUILD_LIST_PAYMENT_QUERY, {
  BuildListPaymentData,
  BuildListPaymentVars,
} from 'shared/graphql/query/buildListPayment';
import useVatCondition from 'views/AccountPayment/Routes/PaymentOrder/ModalCreatePaymentOrder/hooks/useVatCondition';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalCreatePaymentOrder/model/formValues.model';
import { StyledDetailContainer, StyledRowDetail } from './Styles';

const BuildListPayment: React.FC = () => {
  const { setValue } = useFormContext<FormValues>();
  const { getPaymentList } = useVatCondition();

  const { error, data, loading } = useQuery<BuildListPaymentData, BuildListPaymentVars>(
    BUILD_LIST_PAYMENT_QUERY,
    {
      variables: {
        payment_list: getPaymentList.map((item) => ({
          datamaster_service_item_id: item.dataMasterId,
          advance_type: item.advanceType,
          list_number: item.listNumber,
          list_name: item.listName,
          qty: item.qty,
          price: item.price,
          discount: item.discount,
          vat: item.vat,
          wht: item.wht,
          unit: '-',
        })),
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (!loading && !error && data?.buildListPayment) {
      const { grand_total_price } = data.buildListPayment;
      setValue('grandTotalPrice', grand_total_price);
    }
  }, [data?.buildListPayment, error, loading, setValue]);

  let content = null;
  if (data?.buildListPayment) {
    const {
      re_total_before_discount: reTotalBeforeDiscount,
      non_total_before_discount: nonTotalBeforeDiscount,
      inc_total_before_discount: incTotalBeforeDiscount,
      re_total_discount: reTotalDiscount,
      non_total_discount: nonTotalDiscount,
      inc_total_discount: incTotalDiscount,
      re_total_after_discount: reTotalAfterDiscount,
      non_total_after_discount: nonTotalAfterDiscount,
      inc_total_after_discount: incTotalAfterDiscount,
      total_vat: totalVat,
      re_total: reTotal,
      non_total: nonTotal,
      inc_total: incTotal,
      total_price: totalPrice,
      grand_total_price: grandTotalPrice,
      all_wht: allWht,
    } = data.buildListPayment;

    const whtList = allWht.map((item, index, arr) => {
      const { wht, price, total_wht: totalWht } = item;
      const isLastItem = index === arr.length - 1;
      return (
        <StyledRowDetail key={item.wht} className={classNames({ divider: isLastItem })}>
          <Col className="column-1">{`หัก ณ ที่จ่าย ${wht}% (${formatCurrency(price)})`}</Col>
          <Col className="column-2 text-right" />
          <Col className="column-3 text-right" />
          <Col className="column-4 text-right">{formatCurrency(totalWht, '-')}</Col>
        </StyledRowDetail>
      );
    });

    content = (
      <StyledDetailContainer>
        <StyledRowDetail>
          <Col className="column-1">ราคารวมก่อนส่วนลด</Col>
          <Col className="column-2 text-right">{formatCurrency(reTotalBeforeDiscount, '-')}</Col>
          <Col className="column-3 text-right">{formatCurrency(nonTotalBeforeDiscount, '-')}</Col>
          <Col className="column-4 text-right">{formatCurrency(incTotalBeforeDiscount, '-')}</Col>
        </StyledRowDetail>
        <StyledRowDetail className="divider">
          <Col className="column-1">ส่วนลด</Col>
          <Col className="column-2 text-right">{formatCurrency(reTotalDiscount, '-')}</Col>
          <Col className="column-3 text-right">{formatCurrency(nonTotalDiscount, '-')}</Col>
          <Col className="column-4 text-right">{formatCurrency(incTotalDiscount, '-')}</Col>
        </StyledRowDetail>

        <StyledRowDetail>
          <Col className="column-1">ราคารวมหลังส่วนลด</Col>
          <Col className="column-2 text-right">{formatCurrency(reTotalAfterDiscount, '-')}</Col>
          <Col className="column-3 text-right">{formatCurrency(nonTotalAfterDiscount, '-')}</Col>
          <Col className="column-4 text-right">{formatCurrency(incTotalAfterDiscount, '-')}</Col>
        </StyledRowDetail>
        <StyledRowDetail className="divider">
          <Col className="column-1">ภาษีมูลค่าเพิ่ม 7%</Col>
          <Col className="column-2 text-right">-</Col>
          <Col className="column-3 text-right">-</Col>
          <Col className="column-4 text-right">{formatCurrency(totalVat, '-')}</Col>
        </StyledRowDetail>

        <StyledRowDetail>
          <Col className="column-1">รวมเป็นเงิน</Col>
          <Col className="column-2 text-right">{formatCurrency(reTotal, '-')}</Col>
          <Col className="column-3 text-right">{formatCurrency(nonTotal, '-')}</Col>
          <Col className="column-4 text-right">{formatCurrency(incTotal, '-')}</Col>
        </StyledRowDetail>
        <StyledRowDetail className="divider">
          <Col className="column-1">
            <Text strong>จำนวนรวมทั้งสิ้น</Text>
          </Col>
          <Col className="column-2 text-right" />
          <Col className="column-3 text-right" />
          <Col className="column-4 text-right">
            <Text strong>{formatCurrency(totalPrice, '-')}</Text>
          </Col>
        </StyledRowDetail>

        {whtList}

        <StyledRowDetail>
          <Col className="column-1">
            <Text strong>รวมชำระทั้งสิ้น</Text>
          </Col>
          <Col className="column-2 text-right" />
          <Col className="column-3 text-right" />
          <Col className="column-4 text-right">
            <Text strong style={{ fontSize: 24 }}>
              {formatCurrency(grandTotalPrice)}
            </Text>
          </Col>
        </StyledRowDetail>
      </StyledDetailContainer>
    );
  }

  if (error) {
    content = <div>{error.message}</div>;
  }

  return <Spin spinning={loading}>{content}</Spin>;
};

export default BuildListPayment;
