import gql from 'graphql-tag';

export interface CreatePaymentCodData {
  createPaymentNewCod: {
    _id: string;
  };
}

export interface CreatePaymentCodVars {
  customer?: string;
  cod_item?: string[];
  payment_cod_remark?: string;
}

export default gql`
  mutation CreatePaymentNewCod($customer: ID, $cod_item: [ID], $payment_cod_remark: String) {
    createPaymentNewCod(
      createPaymentCod: {
        customer: $customer
        cod_item: $cod_item
        payment_cod_remark: $payment_cod_remark
      }
    ) {
      _id
    }
  }
`;
