import React, { useContext, useEffect } from 'react';
import {
  PaymentVoucherContext,
  SelectedPaymentVouchersType,
} from 'views/AccountPayment/common/context/PaymentVoucherContext';
import { Spin } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormValues } from 'views/AccountPayment/Routes/PaymentVoucher/ModalCreateApp/model/formValues.model';
import Header from './Header';
import PaymentVoucherCreateApp from './PaymentVoucherCreateApp';
import { StyledModal } from './Styles';

const ModalCreateAppWrapper = () => {
  const { selectedPaymentVouchers: selectedPaymentVouchersContext } = useContext(
    PaymentVoucherContext,
  );
  const [selectedPaymentVouchers, setSelectedPaymentVouchers] = selectedPaymentVouchersContext;
  const { isOpenModal } = selectedPaymentVouchers;

  return (
    <StyledModal
      visible={isOpenModal}
      onCancel={() => {
        setSelectedPaymentVouchers(
          (prevState): SelectedPaymentVouchersType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalCreateBillingNote />
    </StyledModal>
  );
};

const ModalCreateBillingNote: React.FC = () => {
  const { selectedPaymentVouchers: selectedPaymentVouchersContext } = useContext(
    PaymentVoucherContext,
  );
  const [selectedPaymentVouchers] = selectedPaymentVouchersContext;

  const { isLoading, vouchersId } = selectedPaymentVouchers;
  const validateSchema = yup.object().shape({
    vouchersId: yup.array().required(),
    paymentMethod: yup.string().required(),
    accountNumber: yup.string().required(),
    paymentType: yup.string().required(),
    roundPaid: yup.string().required(),
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      vouchersId: [],
      paymentMethod: '',
      bankName: '',
      bankBranch: '',
      accountNumber: '',
      accountName: '',
      paymentType: '',
      roundPaid: '',
      appRemark: '',
    },
  });

  const { register, handleSubmit, reset } = methods;

  useEffect(() => {
    register('vouchersId');
    register('bankName');
    register('bankBranch');
    register('accountName');
  }, [register]);

  useEffect(() => {
    setTimeout(() => {
      reset({
        vouchersId,
      });
    }, 0);
  }, [vouchersId, reset]);

  const onSubmit = () => {
    alert('submit');
  };

  return (
    <Spin spinning={isLoading}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Header />
          <PaymentVoucherCreateApp />
        </form>
      </FormProvider>
    </Spin>
  );
};

export default ModalCreateAppWrapper;
