import gql from 'graphql-tag';

export interface CreatePaymentDocumentData {
  createPaymentDocument: {
    _id: string;
  };
}

export interface CreatePaymentDocumentVars {
  payment_id: string;
  document_type: number;
  document_file: string;
  list_ref?: string[];
}

export default gql`
  mutation CreatePaymentDocument(
    $payment_id: ID!
    $document_type: Int!
    $document_file: String!
    $list_ref: [ID]
  ) {
    createPaymentDocument(
      createPaymentDocument: {
        payment_id: $payment_id
        document_type: $document_type
        document_file: $document_file
        list_ref: $list_ref
      }
    ) {
      _id
    }
  }
`;
