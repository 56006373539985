export default {
  apiKey: 'AIzaSyCI94f4702vks7rQ4_zKsHM65vdEP5JaBM',
  authDomain: 'giztix-billing.firebaseapp.com',
  databaseURL: 'https://giztix-billing.firebaseio.com',
  projectId: 'giztix-billing',
  storageBucket: 'giztix-billing.appspot.com',
  messagingSenderId: '437123700186',
  appId: '1:437123700186:web:01e53fb4cd1b06f9c39ce3',
  measurementId: 'G-P96BBJS1NY',
};
