import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  &.custom-modal.ant-modal {
    width: 100% !important;
    max-width: 700px;
  }
`;

export default StyledModal;
