/* eslint-disable no-underscore-dangle */
import React, { ReactNode, useState } from 'react';
import { Empty, Select } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import VENDORS_QUERY, { VendorsData, VendorsVars } from 'shared/graphql/query/vendors';

const { Option } = Select;

type Props = {
  onChange: (vendorId: string | null) => void;
};

const SelectVendorField: React.FC<Props> = ({ onChange }) => {
  const [fullName, setFullName] = useState('');

  const { loading, data } = useQuery<VendorsData, VendorsVars>(VENDORS_QUERY, {
    variables: {
      full_name: fullName,
      showData: 10,
    },
    fetchPolicy: 'cache-and-network',
  });

  let options: ReactNode = null;
  if (data?.vendors.vendors.length) {
    options = data.vendors.vendors.map((vendor) => (
      <Option key={vendor._id} value={vendor._id}>
        {vendor.full_name}
      </Option>
    ));
  }

  return (
    <Select
      style={{
        width: '100%',
      }}
      allowClear
      showSearch
      loading={loading}
      placeholder="ค้นหา"
      optionFilterProp="children"
      onSearch={(value) => setFullName(value)}
      onChange={(value) => {
        if (typeof value === 'string') {
          onChange(value);
          return;
        }

        onChange(null);
      }}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      filterOption={(input, option) =>
        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};

export default SelectVendorField;
