import gql from 'graphql-tag';

export interface CodStatusData {
  codStatus: {
    pending: number;
    waiting: number;
    confirm: number;
  };
}

export default gql`
  query CodStatus {
    codStatus {
      pending
      waiting
      confirm
    }
  }
`;
