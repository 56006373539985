/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode } from 'react';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { Text } from 'shared/components';
import { InvoiceDetail } from 'shared/graphql/query/billingNote';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { FormValues } from 'views/AccountReceive/Routes/Receipt/BillingNotesTable/ModalCreateReceipt/model/formValues.model';
import { StyledTable } from './Styles';
import ExpandedRow from './ExpandedRow';

type InvoiceDataTable = {
  key: string;
  column1: ReactNode;
  column2: ReactNode;
  column3: ReactNode;
  InvoiceDetail: InvoiceDetail[];
};

const BillingNoteDetailTable: React.FC = () => {
  const { getValues } = useFormContext<FormValues>();
  const billInvoices = getValues('billInvoices') ? getValues('billInvoices') : [];

  const columns = [
    {
      title: 'วันที่เอกสาร',
      dataIndex: 'column1',
      width: 110,
    },
    {
      title: 'รหัสใบแจ้งหนี้',
      dataIndex: 'column2',
      width: 110,
    },
    {
      title: 'มูลค่าใบแจ้งหนี้',
      dataIndex: 'column3',
      width: 110,
      align: 'right',
    },
    {
      title: '',
    },
  ];

  const billingNoteData = billInvoices.map(
    (invoice): InvoiceDataTable => {
      const {
        invoice: { service_date, invoice_number, grand_total_price, invoice_detail },
      } = invoice;

      return {
        key: uuidv4(),
        column1: service_date ? formatDate(service_date) : '-',
        column2: invoice_number || '-',
        column3: grand_total_price ? (
          <Text strong customType="primary">
            {formatCurrency(grand_total_price)}
          </Text>
        ) : (
          '-'
        ),
        InvoiceDetail: invoice_detail,
      };
    },
  );

  return (
    <div>
      <StyledTable
        scroll={{ x: 'max-content' }}
        pagination={false}
        columns={columns}
        dataSource={billingNoteData}
        size="small"
        expandable={{
          expandedRowRender: (record: { InvoiceDetail: InvoiceDetail[] }) => (
            <ExpandedRow data={record.InvoiceDetail} />
          ),
        }}
      />
    </div>
  );
};

export default BillingNoteDetailTable;
