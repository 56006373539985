/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, useState, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Row, Col, Input } from 'antd';
import { DeleteOutlined, PlusCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useFormContext, Controller } from 'react-hook-form';
import BUILD_COD_OUT_PARCEL_QUERY, {
  BuildCodOutParcelData,
  BuildCodOutParcelVars,
} from 'shared/graphql/query/buildCodOutParcel';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDateTime } from 'shared/utils/dateTime';
import { Button, Text, Spin } from 'shared/components';
import { FormValues } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/Routes/Cod/ModalCreatePaymentCod/model/formValues.model';
import {
  CodContext,
  SelectedCodItemType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/context/CodContext';
import ModalAddCod from '../ModalAddCod';
import { StyledTable, StyledFormItem } from './Styles';

const { TextArea } = Input;

type CodDataTable = {
  key: string;
  tools: ReactNode;
  column1: string;
  column2: ReactNode;
  column3: string;
  column4: string;
  column5: string;
};

const columns = [
  {
    title: 'เครื่องมือ',
    dataIndex: 'tools',
    width: 80,
  },
  {
    title: 'รหัส COD-IN',
    dataIndex: 'column1',
    width: 180,
  },
  {
    title: 'ยอดรวม COD (บาท)',
    dataIndex: 'column2',
    width: 180,
    align: 'right',
  },
  {
    title: 'Tracking Number',
    dataIndex: 'column3',
    width: 180,
  },
  {
    title: 'วันที่แจ้งโอน',
    dataIndex: 'column4',
    width: 180,
  },
  {
    title: 'วันที่อัพเดต',
    dataIndex: 'column5',
    width: 180,
  },
  {
    title: '',
  },
];

const CodList: React.FC = () => {
  const { control } = useFormContext<FormValues>();
  const [isOpenModalAddCod, setOpenModalAddCod] = useState<boolean>(false);
  const { selectedCodItems, setSelectedCodItems } = useContext(CodContext);

  const { error, data, loading } = useQuery<BuildCodOutParcelData, BuildCodOutParcelVars>(
    BUILD_COD_OUT_PARCEL_QUERY,
    {
      variables: {
        parcel_orders: selectedCodItems.codItemsId,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  let content = <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  let codDataTable: CodDataTable[] = [];

  if (data?.buildCodOutParcel) {
    const { parcel_orders } = data.buildCodOutParcel;

    codDataTable = parcel_orders.map((item) => {
      return {
        key: item._id,
        tools: (
          <Button
            disabled={selectedCodItems.codItemsId.length < 2}
            title="ลบ"
            color="red--outline"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => {
              const filterItems = selectedCodItems.codItemsId.filter((value) => value !== item._id);
              setSelectedCodItems(
                (prevState): SelectedCodItemType => ({
                  ...prevState,
                  codItemsId: filterItems,
                }),
              );
            }}
          />
        ),
        column1: item.cod_in_number || '-',
        column2: (
          <Text customType="primary" strong>
            {formatCurrency(item.cod_price)}
          </Text>
        ),
        column3: item.tracking_number || '-',
        column4: formatDateTime(item.cod_in_date) || '-',
        column5: formatDateTime(item.updatedAt) || '-',
      };
    });

    content = (
      <>
        <StyledTable
          scroll={{ x: 'max-content' }}
          pagination={false}
          columns={columns}
          dataSource={codDataTable}
          size="small"
          loading={loading}
        />
        <Row justify="space-between" style={{ marginTop: 10 }}>
          <Col>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button
                  color="blue--light"
                  icon={<PlusCircleOutlined />}
                  onClick={() => setOpenModalAddCod(true)}
                >
                  เพิ่มรายการ
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            หมายเหตุ <Text customType="grey">(ไม่บังคับ)</Text>
            <StyledFormItem>
              <Controller
                control={control}
                name="paymentCodRemark"
                render={({ onChange, value }) => (
                  <TextArea
                    value={value || undefined}
                    placeholder="ระบุ"
                    rows={4}
                    onChange={(event) => {
                      onChange && onChange(event.target.value);
                    }}
                  />
                )}
              />
            </StyledFormItem>
          </Col>
        </Row>

        <ModalAddCod
          visible={isOpenModalAddCod}
          onOk={(cods) => {
            setSelectedCodItems(
              (prevState): SelectedCodItemType => ({
                ...prevState,
                codItemsId: cods.map((value) => value.id),
              }),
            );
            setOpenModalAddCod(false);
          }}
          onCancel={() => setOpenModalAddCod(false)}
          cods={parcel_orders.map((codItem) => ({
            id: codItem._id,
          }))}
        />
      </>
    );
  }

  if (error) {
    content = <div>{error.message}</div>;
  }

  return content;
};

export default CodList;
