import styled from 'styled-components';
import { Modal, Form } from 'antd';

export const StyledModal = styled(Modal)`
  &.custom-modal.ant-modal {
    width: 100% !important;
    max-width: 640px;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;
