import gql from 'graphql-tag';

export interface DataMasterServiceItems {
  _id: string;
  datamaster_service_item_number: string;
  service_item_name: {
    th: string;
  };
}

export interface DataMasterServiceItemByChartOfAccountData {
  DataMasterServiceItemByChartOfAccount: {
    dataMasterServiceItems: DataMasterServiceItems[];
    totalDocument: number;
  };
}

export interface DataMasterServiceItemByChartOfAccountVars {
  code?: string;
  account_name?: string;
  mode: string;
  page?: number;
  showData?: number;
}

export default gql`
  query DataMasterServiceItemByChartOfAccount(
    $code: String
    $account_name: String
    $mode: ID!
    $page: Int
    $showData: Int
  ) {
    DataMasterServiceItemByChartOfAccount(
      params: { code: $code, account_name: $account_name, mode: $mode, type: 2 }
      page: $page
      showData: $showData
    ) {
      dataMasterServiceItems {
        _id
        service_item_name {
          th
        }
        datamaster_service_item_number
      }
      totalDocument
    }
  }
`;
