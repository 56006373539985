/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Button, Modal, Typography, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import CREATE_PAYMENT_VOUCHER_MUTATION from 'shared/graphql/mutation/createPaymentVoucher';
import { PaymentOrderContext } from 'views/AccountPayment/common/context/PaymentOrderContext';
import updateObject from 'shared/utils/updateObject';

const { Text } = Typography;

const ModalConfirmCreateVoucher = (props) => {
  const { onCancel, visible } = props;
  const { values } = useFormikContext();
  const { selectedPayments: selectedPaymentsContext } = useContext(PaymentOrderContext);
  const [selectedPayments, setSelectedPayments] = selectedPaymentsContext;

  const closeModalHandler = () => {
    setSelectedPayments((prevState) => ({
      ...prevState,
      isOpenModal: false,
      payments: [],
      payeeId: '',
    }));
  };

  // const resetSelectedPayments = () => {
  //   const { isOpenModal, ...initialData } = selectedPayments;
  //   setSelectedPayments((prevState) => updateObject(prevState, initialData));
  //   console.log('selectedPayments.payments', selectedPayments.payments);
  //   console.log('selectedPayments.payments', selectedPayments.payeeId);
  // };

  const [updatePaymentVoucher, { loading }] = useMutation(CREATE_PAYMENT_VOUCHER_MUTATION, {
    update: (_cache, { data }) => {
      const { _id } = data.createPaymentVoucher;
      if (_id) {
        message.success('สร้างใบสำคัญจ่ายแล้ว');
        // resetSelectedPayments();
        onCancel();
        closeModalHandler();
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['Payment', 'PaymentStatus', 'Payments'],
  });

  const confirmCreateVoucher = () => {
    updatePaymentVoucher({
      variables: {
        voucher_status: 1,
        payee: values.payeeId,
        due_date: values.dueDate,
        payment_method: values.paymentMethod,
        payment: values.payments.map((payment) => payment.id),
        bank_name: values.bankName,
        bank_branch: values.bankBranch,
        account_name: values.accountName,
        account_number: values.accountNumber,
        voucher_remark: values.voucherRemark,
        payment_type: values.paymentType,
      },
    });
  };

  return (
    <Modal
      title="ยืนยันสร้างใบสำคัญจ่ายหรือไม่?"
      visible={visible}
      onCancel={onCancel}
      okButtonProps={{
        icon: <CheckCircleOutlined />,
        type: 'green',
      }}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          type="green"
          onClick={() => confirmCreateVoucher()}
          loading={loading}
        >
          สร้างใบสำคัญจ่าย
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>รหัสใบสั่งจ่าย:</Col>
        <Col span={18}>
          <Text strong>{values.payments.map((payment) => payment.paymentNumber).join(`, `)}</Text>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalConfirmCreateVoucher;
