/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode } from 'react';
import { Select, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import INVOICES_QUERY, { InvoicesData, InvoicesVars } from 'shared/graphql/query/invoices';

type SelectInvoiceFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const { Option } = Select;

const SelectInvoiceField: React.FC<SelectInvoiceFieldProps> = (props) => {
  const { value, onChange } = props;
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const { loading, data } = useQuery<InvoicesData, InvoicesVars>(INVOICES_QUERY, {
    variables: {
      invoice_number: invoiceNumber,
      showData: 10,
    },
    fetchPolicy: 'cache-and-network',
  });

  let options: ReactNode[] = [];
  if (data && data.invoices) {
    const { invoices } = data.invoices;
    options = invoices.map((invoice) => (
      <Option key={invoice._id} value={invoice._id}>
        {invoice.invoice_number}
      </Option>
    ));
  }

  return (
    <Select
      allowClear
      style={{
        width: '100%',
      }}
      value={value}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      showSearch
      placeholder="ค้นหา"
      onSearch={(valueSearch) => setInvoiceNumber(valueSearch)}
      onChange={(valueChange) => onChange && onChange(valueChange)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};

export default SelectInvoiceField;
