import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Row, Col, Typography } from 'antd';
import { Spin } from 'shared/components';
import formatCurrency from 'shared/utils/formatCurrency';
import PAYMENT_QUERY from 'shared/graphql/query/payment';
import { StyledTable, StyledRowDetail } from './Styles';

const { Text } = Typography;
const columns = [
  {
    title: 'รหัสรายการ',
    dataIndex: 'column1',
    width: 130,
  },
  {
    title: 'ชื่อรายการ',
    dataIndex: 'column2',
  },
  {
    title: 'จำนวน',
    dataIndex: 'column3',
    width: 100,
    align: 'right',
  },
  {
    title: 'ราคาต่อหน่วย',
    dataIndex: 'column4',
    width: 110,
    align: 'right',
  },
  {
    title: 'ราคารวม',
    dataIndex: 'column5',
    width: 110,
    align: 'right',
    className: 'divider',
  },
  {
    title: 'ส่วนลด',
    dataIndex: 'column6',
    width: 110,
    align: 'right',
  },
  {
    title: 'รวมหลังส่วนลด',
    dataIndex: 'column7',
    width: 120,
    align: 'right',
    className: 'divider',
  },
  {
    title: 'จำนวนเรียกคืน',
    dataIndex: 'column8',
    width: 145,
    align: 'right',
    fixed: 'right',
  },
  {
    title: 'ยกเว้นภาษี',
    dataIndex: 'column9',
    width: 110,
    align: 'right',
    fixed: 'right',
  },
  {
    title: 'ภาษีมูลค่าเพิ่ม',
    dataIndex: 'column10',
    width: 130,
    align: 'right',
    fixed: 'right',
  },
];

const ExpandedRowPayment = (props) => {
  const { paymentId } = props;
  const { error, data } = useQuery(PAYMENT_QUERY, {
    variables: {
      _id: paymentId,
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = <Spin />;
  if (data && data.payment) {
    const { payment } = data;
    const {
      re_total: reTotal,
      non_total: nonTotal,
      inc_total: incTotal,
      total_price: totalPrice,
      grand_total_price: grandTotalPrice,
      all_wht: allWht,
    } = payment;

    const paymentList = payment.payment_list.map((item, index) => {
      const {
        list_number: listNumber,
        list_name: listName,
        qty,
        price,
        total_before_discount: totalBeforeDiscount,
        discount,
        total_price: paymentListTotalPrice,
        vat,
        wht,
        advance_type: advanceType,
      } = item;

      return {
        key: index,
        column1: listNumber,
        column2: listName,
        column3: qty,
        column4: formatCurrency(price),
        column5: formatCurrency(totalBeforeDiscount),
        column6: formatCurrency(discount),
        column7: formatCurrency(paymentListTotalPrice),
        column8:
          advanceType && vat === 0 && wht === 0 ? formatCurrency(paymentListTotalPrice) : '-',
        column9: !advanceType && vat === 0 ? formatCurrency(paymentListTotalPrice) : '-',
        column10: !advanceType && vat !== 0 ? formatCurrency(paymentListTotalPrice) : '-',
      };
    });

    const whtList = allWht.map((item) => {
      const { wht, price, total_wht: totalWht } = item;
      return (
        <StyledRowDetail key={item.wht}>
          <Col className="column-1">{`หัก ณ ที่จ่าย ${wht}% (${formatCurrency(price)})`}</Col>
          <Col className="column-2 text-right" />
          <Col className="column-3 text-right" />
          <Col className="column-4 text-right">{formatCurrency(totalWht, '-')}</Col>
        </StyledRowDetail>
      );
    });

    content = (
      <>
        <StyledTable
          scroll={{ x: 'max-content' }}
          pagination={false}
          columns={columns}
          dataSource={paymentList}
          size="small"
        />

        <Row justify="end" style={{ marginTop: 15 }}>
          <Col>
            <div>
              <StyledRowDetail>
                <Col className="column-1">รวมเป็นเงิน</Col>
                <Col className="column-2 text-right">{formatCurrency(reTotal, '-')}</Col>
                <Col className="column-3 text-right">{formatCurrency(nonTotal, '-')}</Col>
                <Col className="column-4 text-right">{formatCurrency(incTotal, '-')}</Col>
              </StyledRowDetail>
              <StyledRowDetail>
                <Col className="column-1">จำนวนรวมทั้งสิ้น</Col>
                <Col className="column-2 text-right" />
                <Col className="column-3 text-right" />
                <Col className="column-4 text-right">{formatCurrency(totalPrice, '-')}</Col>
              </StyledRowDetail>

              {whtList}

              <StyledRowDetail>
                <Col className="column-1">
                  <Text strong>รวมชำระทั้งสิ้น</Text>
                </Col>
                <Col className="column-2 text-right" />
                <Col className="column-3 text-right" />
                <Col className="column-4 text-right">
                  <Text strong style={{ fontSize: 24 }}>
                    {formatCurrency(grandTotalPrice)}
                  </Text>
                </Col>
              </StyledRowDetail>
            </div>
          </Col>
        </Row>
      </>
    );
  }

  if (error) {
    content = error.message;
  }

  return <div>{content}</div>;
};

export default ExpandedRowPayment;
