import gql from 'graphql-tag';

export interface SendEmailBillingNoteData {
  sendEmailBillingNote: {
    data: number;
    error: number;
  };
}

export interface SendEmailBillingNoteVars {
  _id: string;
}

export default gql`
  mutation SendEmailBillingNote($_id: ID) {
    sendEmailBillingNote(_id: $_id) {
      data
      error
    }
  }
`;
