/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';
import { Divider, Row, Col, Tabs, Skeleton, message, Select, DatePicker, Form } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import formatCurrency from 'shared/utils/formatCurrency';
import { color } from 'shared/utils/styles';
import { Tag, Text } from 'shared/components';
import { useFormContext, Controller } from 'react-hook-form';
import BUILD_APP_QUERY, { BuildAppData, BuildAppVars } from 'shared/graphql/query/buildApp';
import { FormValues } from 'views/AccountPayment/Routes/PaymentVoucher/ModalCreateApp/model/formValues.model';
import {
  PaymentVoucherContext,
  SelectedPaymentVouchersType,
} from 'views/AccountPayment/common/context/PaymentVoucherContext';
import moment from 'moment';
import SelectBankAccountsField from 'views/AccountPayment/common/components/SelectBankAccountsField';
import PaymentVoucherList from './PaymentVoucherList';

const { TabPane } = Tabs;
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';

const PaymentVoucherCreateApp: React.FC = () => {
  const { selectedPaymentVouchers: selectedPaymentVouchersContext } = useContext(
    PaymentVoucherContext,
  );
  const [, setSelectedPaymentVouchers] = selectedPaymentVouchersContext;
  const { getValues, control, setValue, errors } = useFormContext<FormValues>();
  const vouchersId = getValues('vouchersId') ? getValues('vouchersId') : [];

  const { data: buildAppQueryData, loading, error } = useQuery<BuildAppData, BuildAppVars>(
    BUILD_APP_QUERY,
    {
      variables: {
        payment_voucher: vouchersId,
      },
      onError: (err) => {
        message.error(err.message);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    setSelectedPaymentVouchers(
      (prevState): SelectedPaymentVouchersType => ({
        ...prevState,
        isLoading: loading,
      }),
    );

    if (!loading && !error && buildAppQueryData?.buildApp) {
      const { grand_total_price, payment_voucher } = buildAppQueryData.buildApp;
      setSelectedPaymentVouchers(
        (prevState): SelectedPaymentVouchersType => ({
          ...prevState,
          modalConfirmCreateApp: {
            grandTotalPrice: grand_total_price,
            paymentVouchers: payment_voucher.map((paymentVoucher) => ({
              createDate: paymentVoucher.create_date,
              paymentVoucherNumber: paymentVoucher.voucher_number || '-',
              roundPaid: paymentVoucher.round_paid,
              payee: paymentVoucher.payee.full_name,
              grandTotalPrice: paymentVoucher.grand_total_price,
            })),
          },
        }),
      );
    }
  }, [buildAppQueryData?.buildApp, error, loading, setSelectedPaymentVouchers]);

  let content = <Skeleton />;
  if (buildAppQueryData?.buildApp) {
    const { grand_total_price } = buildAppQueryData.buildApp;

    content = (
      <>
        <Text strong>เงื่อนไขรอบจ่าย</Text>
        <Divider style={{ margin: '10px 0' }} />
        <Row gutter={[32, 16]}>
          <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Row justify="space-between">
                  <Col>
                    ช่องทางการจ่าย
                    <Text customType="danger">*</Text>
                  </Col>
                  <Col span={24}>
                    <Form.Item validateStatus={errors.paymentMethod && 'error'}>
                      <Controller
                        control={control}
                        name="paymentMethod"
                        render={({ onChange, value }) => (
                          <Select
                            style={{
                              width: '100%',
                            }}
                            value={value}
                            placeholder="เลือก"
                            onChange={(valueChange) => onChange(valueChange)}
                          >
                            <Option value="โอนเงิน">โอนเงิน</Option>
                          </Select>
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify="space-between">
                  <Col>
                    บัญชีที่ต้องการโอน
                    <Text customType="danger">*</Text>
                  </Col>
                  <Col span={24}>
                    <Form.Item validateStatus={errors.accountNumber && 'error'}>
                      <Controller
                        control={control}
                        name="accountNumber"
                        render={({ onChange, value }) => (
                          <SelectBankAccountsField
                            value={value || undefined}
                            onChange={(valueSelect) => {
                              onChange(valueSelect.accountNumber);
                              setValue('bankName', valueSelect.bankName);
                              setValue('bankBranch', valueSelect.bankBranch);
                              setValue('accountName', valueSelect.accountName);
                            }}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Row justify="space-between">
                  <Col>
                    ประเภทการจ่าย
                    <Text customType="danger">*</Text>
                  </Col>
                  <Col span={24}>
                    <Form.Item validateStatus={errors.paymentType && 'error'}>
                      <Controller
                        control={control}
                        name="paymentType"
                        render={({ onChange, value }) => (
                          <Select
                            style={{
                              width: '100%',
                            }}
                            value={value}
                            placeholder="เลือก"
                            onChange={(valueChange) => onChange(valueChange)}
                          >
                            <Option value="OPEX">OPEX</Option>
                            <Option value="COGs">COGs</Option>
                          </Select>
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify="space-between">
                  <Col>
                    วันที่รอบจ่าย
                    <Text customType="danger">*</Text>
                  </Col>
                  <Col span={24}>
                    <Form.Item validateStatus={errors.roundPaid && 'error'}>
                      <Controller
                        control={control}
                        name="roundPaid"
                        render={({ onChange, value }) => (
                          <DatePicker
                            allowClear={false}
                            value={value ? moment(value, dateFormat) : null}
                            style={{ width: '100%' }}
                            placeholder="รอบที่ต้องจ่าย"
                            onChange={(valueDate) => {
                              if (!valueDate) {
                                onChange('roundPaid');
                              } else {
                                onChange(valueDate.format(dateFormat));
                              }
                            }}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={8}>
            <Row>
              <Col span={8}>รหัสรอบจ่าย:</Col>
              <Col span={16}>
                <Text strong>-</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>มูลค่ารอบจ่าย:</Col>
              <Col span={16}>
                <Text strong customType="primary">
                  {formatCurrency(grand_total_price)}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>สถานะ:</Col>
              <Col span={16}>
                <Tag customColor="blue">กำลังสร้าง...</Tag>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider style={{ margin: 0, marginTop: -5 }} />

        <Tabs defaultActiveKey="1" animated={false}>
          <TabPane
            tab={
              <span>
                รายการใบสำคัญจ่าย{' '}
                <CheckCircleOutlined
                  style={{
                    color: color.success,
                    marginLeft: 5,
                  }}
                />
              </span>
            }
            key="1"
          >
            <PaymentVoucherList />
          </TabPane>
        </Tabs>
      </>
    );
  }

  return content;
};

export default PaymentVoucherCreateApp;
