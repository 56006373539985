/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import { ModalTopButton, Text, TabPane, FileList } from 'shared/components';
import { useHistory } from 'react-router-dom';
import { DollarCircleOutlined } from '@ant-design/icons';
import { Row, Col, Divider, Skeleton, Spin, Popover } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import { CodContext } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/context/CodContext';
import { useQuery } from '@apollo/react-hooks';
import COD_ITEM_QUERY, { CodItemData, CodItemVars } from 'shared/graphql/query/newCodItem';
import CardIcon from './CardIcon';

interface ModalCodItemDetailProps {
  codItemId: string;
  goBack: string;
}

const ModalCodItemDetailWrapper: React.FC<ModalCodItemDetailProps> = (props) => {
  const { codItemId, goBack } = props;
  const { selectedCodItemDetail } = useContext(CodContext);
  const { codNumber } = selectedCodItemDetail;
  const history = useHistory();

  return (
    <ModalTopButton
      visible
      onCancel={() => {
        history.push(goBack);
      }}
      title={`รหัสCOD-IN: ${codNumber}`}
      width={900}
      btnHeader={[]}
    >
      <ModalCodItemDetail codItemId={codItemId} goBack={goBack} />
    </ModalTopButton>
  );
};

const ModalCodItemDetail: React.FC<ModalCodItemDetailProps> = (props) => {
  const { codItemId } = props;
  const { loading, error, data } = useQuery<CodItemData, CodItemVars>(COD_ITEM_QUERY, {
    variables: {
      _id: codItemId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const contentPopOverShipmentNumber = (value: string[]) => {
    return (
      <>
        <Row>
          <Col>
            <Text>Shipment No: </Text>
          </Col>
        </Row>
        {value.map((item) => (
          <Row key={uuidv4()}>
            <Col>
              <Text strong>{item}</Text>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const popOverShipmentNumber = (value: string[]) => {
    if (value.length === 1) {
      return <Text>{value[0]}</Text>;
    }
    if (value.length > 1) {
      return (
        <Popover placement="rightTop" content={contentPopOverShipmentNumber(value)}>
          <div style={{ display: 'inline' }}>
            <Text>{value.length} รหัส</Text>
          </div>
        </Popover>
      );
    }
    return '-';
  };

  let content = <Skeleton />;

  if (data?.newCodItem) {
    const {
      total_cod,
      customer,
      cod,
      shipment_list,
      cod_in_date,
      document,
      remark,
    } = data.newCodItem;

    content = (
      <>
        <Row gutter={[32, 16]}>
          <Col
            span={12}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CardIcon
              title="จำนวนที่ 3PL ต้องโอน (บาท)"
              text={<Text customType="primary">{`${formatCurrency(total_cod)} บาท`}</Text>}
              icon={<DollarCircleOutlined />}
            />
          </Col>
        </Row>

        <Divider style={{ marginBottom: 10, marginTop: -5 }} />

        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Row>
              <Col span={8}>ชื่อ 3PL:</Col>
              <Col span={16}>
                <Text strong>{cod?.vendor?.full_name || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Shipment No.:</Col>
              <Col span={16}>
                <Text strong>
                  {popOverShipmentNumber(shipment_list.map((shipment) => shipment.shipment_number))}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>วันที่แจ้งโอน:</Col>
              <Col span={16}>
                <Text strong>{cod_in_date ? formatDateTime(cod_in_date) : '-'}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={4}>เหตุผล:</Col>
              <Col span={16}>
                <Text strong>{remark || '-'}</Text>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider style={{ marginBottom: 0, marginTop: 10 }} />

        <TabPane
          tabs={[
            {
              key: '1',
              text: 'หลักฐานการโอน',
              isShowIcon: true,
              component: (
                <>
                  {document.length === 0 ? (
                    '-'
                  ) : (
                    <div>
                      <FileList files={document} />
                    </div>
                  )}
                </>
              ),
            },
            {
              key: '2',
              text: 'รายละเอียดงาน',
              isShowIcon: true,
              component: (
                <>
                  <Row gutter={[16, 0]}>
                    <Col span={6}>ชื่อลูกค้า</Col>
                    <Col span={18}>
                      <Row gutter={[16, 0]}>
                        <Col span={10}>Shipment No</Col>
                        <Col span={9}>Booking Number</Col>
                        <Col span={5} style={{ textAlign: 'right' }}>
                          มูลค่า COD
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {shipment_list.length === 0 ? (
                    '-'
                  ) : (
                    <Row gutter={[16, 0]}>
                      <Col span={6}>{customer?.full_name || '-'}</Col>
                      <Col span={18}>
                        {shipment_list.map((item) => (
                          <Row key={uuidv4()} gutter={[16, 0]}>
                            <Col span={10}>
                              <Text strong>{item.shipment_number || '-'}</Text>
                            </Col>
                            <Col span={9}>
                              <Text strong>{item.booking_number || '-'}</Text>
                            </Col>
                            <Col span={5} style={{ textAlign: 'right' }}>
                              <Text customType="primary" strong>
                                {item.cod_price ? formatCurrency(item.cod_price) : '-'}
                              </Text>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  )}
                </>
              ),
            },
          ]}
        />
      </>
    );
  }

  if (error) {
    content = <div>{error.message}</div>;
  }

  return <Spin spinning={loading}>{content}</Spin>;
};

export default ModalCodItemDetailWrapper;
