import React, { useContext, useState } from 'react';
import { Col, Row } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { ParcelCostingContext } from 'views/Costing/common/context/ParcelCostingContext';
import { useHistory } from 'react-router-dom';
import { ParcelCostingStatus } from 'views/Costing/common/model/parcelCostingStatus.model';
import { Container, StyledButton, TextHeader } from './Styles';
import ModalConfirmReject from './ModalConfirmReject';
import ModalConfirmChecked from './ModalConfirmChecked';

const Header: React.FC = () => {
  const history = useHistory();
  const [isOpenModalConfirmReject, setOpenModalConfirmReject] = useState<boolean>(false);
  const [isOpenModalConfirmChecked, setOpenModalConfirmChecked] = useState<boolean>(false);
  const { selectedCostingDetail } = useContext(ParcelCostingContext);
  const { costingNumber, costingStatus, isLoading, backUrl } = selectedCostingDetail!;

  const closeModalHandler = () => {
    history.push(backUrl);
  };

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>รหัสต้นทุน/ขาย: {costingNumber}</TextHeader>
        </Col>
        <Col>
          {costingStatus === ParcelCostingStatus.WAITING_CONFIRM && (
            <>
              <StyledButton
                disabled={isLoading}
                icon={<CheckCircleOutlined />}
                color="green"
                onClick={() => setOpenModalConfirmChecked(true)}
              >
                ตรวจสอบแล้ว
              </StyledButton>
              <ModalConfirmChecked
                visible={isOpenModalConfirmChecked}
                onCancel={() => setOpenModalConfirmChecked(false)}
              />

              <StyledButton
                disabled={isLoading}
                icon={<CloseCircleOutlined />}
                color="red--light"
                onClick={() => setOpenModalConfirmReject(true)}
              >
                ปฏิเสธ
              </StyledButton>
              <ModalConfirmReject
                visible={isOpenModalConfirmReject}
                onCancel={() => setOpenModalConfirmReject(false)}
              />
            </>
          )}

          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
