import gql from 'graphql-tag';

export interface CreateBillingNoteVars {
  bill_invoice: {
    invoice: string;
  }[];
}

export interface CreateBillingNoteData {
  createBillingNote: {
    _id: string;
  };
}

export default gql`
  mutation CreateBillingNote($bill_invoice: [inputBillInvoice]!) {
    createBillingNote(createBillingNote: { bill_invoice: $bill_invoice }) {
      _id
    }
  }
`;
