import gql from 'graphql-tag';
import { PaymentCodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/paymentCodStatus.model';

export interface Customer {
  _id: string;
  full_name: string;
}

export interface Cod {
  _id: string;
  cod_in_number: string;
  booking_number: string;
}

export interface PaymentCod {
  _id: string;
  cod_out_number: string;
  payment_cod_status: PaymentCodStatus;
  paid_date?: string;
  bank_ref?: string;
  customer: Customer;
  total_cod: number;
  cod: Cod[];
  last_update: string;
}

export interface PaymentCods {
  paymentCods: PaymentCod[];
  totalDocument: number;
}

export interface PaymentCodsData {
  paymentCods: PaymentCods;
}

export interface PaymentCodsVars {
  payment_cod_status?: PaymentCodStatus;
  customer?: string;
  cod?: string;
  cod_out_number?: string;
  paid_date?: string;
  bank_ref?: string;
  order_by?: string;
  order_type?: '1' | '-1' | '';
  page?: number;
  showData?: number;
}

export default gql`
  query PaymentCods(
    $payment_cod_status: Int
    $customer: ID
    $cod: ID
    $cod_out_number: String
    $paid_date: String
    $bank_ref: String
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
  ) {
    paymentCods(
      params: {
        payment_cod_status: $payment_cod_status
        customer: $customer
        cod: $cod
        cod_out_number: $cod_out_number
        paid_date: $paid_date
        bank_ref: $bank_ref
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      paymentCods {
        _id
        cod_out_number
        payment_cod_status
        paid_date
        bank_ref
        customer {
          _id
          full_name
        }
        total_cod
        cod {
          _id
          cod_in_number
          booking_number
        }
        last_update
      }
      totalDocument
    }
  }
`;
