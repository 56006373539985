import React from 'react';
import { TextProps } from 'antd/lib/typography/Text';
import { StyledText } from './Styles';

type CustomProps = {
  customType?: 'primary' | 'grey' | 'success' | 'danger' | 'normal';
} & TextProps;

type TextTypeClassName =
  | 'ant-typography-primary'
  | 'ant-typography-grey'
  | 'ant-typography-success'
  | 'ant-typography-danger'
  | 'ant-typography'
  | undefined;

const Text: React.FC<CustomProps> = (props) => {
  const { children, customType, ...otherProps } = props;

  let typeClassName: TextTypeClassName;
  if (customType === 'primary') {
    typeClassName = 'ant-typography-primary';
  } else if (customType === 'grey') {
    typeClassName = 'ant-typography-grey';
  } else if (customType === 'success') {
    typeClassName = 'ant-typography-success';
  } else if (customType === 'danger') {
    typeClassName = 'ant-typography-danger';
  } else if (customType === 'normal') {
    typeClassName = 'ant-typography';
  }

  return (
    <StyledText className={typeClassName} {...otherProps}>
      {children}
    </StyledText>
  );
};

export default Text;
