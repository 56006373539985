import gql from 'graphql-tag';
import { ParcelCostingStatus } from 'views/Costing/common/model/parcelCostingStatus.model';

export interface ParcelCostData {
  parcelCost: {
    _id: string;
    costing_number: string;
    costing_status: ParcelCostingStatus;
    total_after_discount: number;
    total_before_discount: number;
    total_vat: number;
    total_price: number;
    remark: string | null;
    vendor: {
      _id: string;
      full_name: string;
    };
    employee: {
      _id: string;
      full_name: string;
    } | null;
    serviceItems: {
      service_category_name: string;
      list_name: string;
      qty: number;
      price: number;
      wht: number;
      grand_total_price: number;
      discount: number;
      total_before_discount: number;
      total_price: number;
      vat: number;
    }[];
    all_wht: {
      wht: number;
      price: number;
      total_wht: number;
    }[];
    grand_total_price: number;
    refNumber: string;
    createdAt: string;
    edit_request_status: boolean;
    reject_remark: string | null;
    totalCostAvg: number;
    parcelTarget: number;
    total_discount: number;
    costModel: 'trip' | 'monthly';
    totalDocumentParcels: number;
    totalParcelDelivered: number;
  };
}

export interface ParcelCostVars {
  _id: string;
}

export default gql`
  query ParcelCost($_id: ID) {
    parcelCost(_id: $_id) {
      _id
      costing_number
      costing_status
      total_after_discount
      remark
      # profit
      # payer {
      #   _id
      #   full_name
      # }
      # payee {
      #   _id
      #   full_name
      # }
      vendor {
        _id
        full_name
      }
      employee {
        _id
        full_name
      }
      # costing_remark
      # last_send
      # send_history {
      #   timestamp
      # }
      # reject_remark
      serviceItems {
        service_category_name
        list_name
        qty
        price
        # total_vat {
        #   cost
        #   sale
        # }
        wht
        grand_total_price
        discount
        total_before_discount
        total_price
        vat
      }
      total_before_discount
      # total_discount {
      #   cost
      #   sale
      # }
      total_vat
      total_price
      all_wht {
        wht
        price
        total_wht
      }
      grand_total_price
      refNumber
      # paid_type
      createdAt
      edit_request_status
      reject_remark
      totalCostAvg
      parcelTarget
      costModel
      total_discount
      totalDocumentParcels
      totalParcelDelivered
    }
  }
`;
