import React, { useState, ReactChild } from 'react';
import { CodParcelStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codParcelStatus.model';
import { CodParcelOrderStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codParcelOrderStatus.model';

export interface SelectedCodDetailType {
  codId: string;
  isOpenModal: boolean;
  isLoading: boolean;
  codStatus: CodParcelStatus | null;
  codNumber: string;
  modalConfirm: {
    totalCod: number;
    vendorFullName: string;
    shipmentNumbers: string[];
    codInDate: string;
  };
}

export interface SelectedCodItemDetailType {
  codItemId: string;
  isOpenModal: boolean;
  isLoading: boolean;
  codItemStatus: CodParcelOrderStatus | null;
  trackingNumber: string;
}

export interface SelectedCodItemType {
  codItemsId: string[];
  isOpenModal: boolean;
  isLoading: boolean;
  customerId: string;
  customerFullName: string;
  customerAddress: string;
  customerTaxId: string;
  customerPhone: string[];
  customerEmail: string[];
  codPrice: number[];
  confirmPaymentCod: {
    totalCod: number;
    codItems: {
      codNumber: string;
      totalCodItem: number;
      trackingNumber: string;
      codInDate: string;
    }[];
  };
}

interface Cod {
  selectedCodDetail: SelectedCodDetailType;
  setSelectedCodDetail: (params: (cb: SelectedCodDetailType) => SelectedCodDetailType) => void;
  selectedCodItemDetail: SelectedCodItemDetailType;
  setSelectedCodItemDetail: (
    params: (cb: SelectedCodItemDetailType) => SelectedCodItemDetailType,
  ) => void;
  selectedCodItems: SelectedCodItemType;
  setSelectedCodItems: (params: (cb: SelectedCodItemType) => SelectedCodItemType) => void;
}

type CodInProps = {
  children: ReactChild;
};

const CodContext = React.createContext({} as Cod);

const CodProvider: React.FC<CodInProps> = (props) => {
  const { children } = props;
  const [selectedCodDetail, setSelectedCodDetail] = useState<SelectedCodDetailType>({
    codId: '',
    isOpenModal: false,
    isLoading: true,
    codStatus: null,
    codNumber: '',
    modalConfirm: {
      totalCod: 0,
      vendorFullName: '',
      shipmentNumbers: [],
      codInDate: '',
    },
  });

  const [selectedCodItemDetail, setSelectedCodItemDetail] = useState<SelectedCodItemDetailType>({
    codItemId: '',
    isOpenModal: false,
    isLoading: true,
    codItemStatus: null,
    trackingNumber: '',
  });

  const [selectedCodItems, setSelectedCodItems] = useState<SelectedCodItemType>({
    codItemsId: [],
    isLoading: false,
    isOpenModal: false,
    customerId: '',
    customerFullName: '',
    customerAddress: '',
    customerTaxId: '',
    customerPhone: [],
    customerEmail: [],
    codPrice: [],
    confirmPaymentCod: {
      totalCod: 0,
      codItems: [
        {
          codNumber: '',
          totalCodItem: 0,
          trackingNumber: '',
          codInDate: '',
        },
      ],
    },
  });

  const store = {
    selectedCodDetail,
    setSelectedCodDetail,
    selectedCodItemDetail,
    setSelectedCodItemDetail,
    selectedCodItems,
    setSelectedCodItems,
  };

  return <CodContext.Provider value={store}>{children}</CodContext.Provider>;
};

export { CodContext, CodProvider };
