import styled from 'styled-components';
import { Tag, Form } from 'antd';
import { font, color } from 'shared/utils/styles';
import { Table } from 'shared/components';

export const TextHeader = styled.div`
  ${font.size(24)};
  ${font.medium};
  margin-bottom: 10px;
`;

export const StyledTag = styled(Tag)`
  &.ant-tag {
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 8px;
  }
`;

export const StyledTable = styled(Table)`
  & thead > tr > th {
    border-top: 1px solid ${color.border};
  }

  & tr > th.divider,
  tr > td.divider {
    border-right: 1px solid ${color.border};
  }
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;
