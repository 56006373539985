import gql from 'graphql-tag';

export interface CreatePaymentInsuranceData {
  createPaymentInsurance: {
    result: string;
  };
}

export interface CreatePaymentInsuranceVars {
  payee: string;
  payment_number: string;
}

export default gql`
  mutation CreatePaymentInsurance($payee: ID!, $payment_number: String!) {
    createPaymentInsurance(create: { payee: $payee, payment_number: $payment_number }) {
      result
    }
  }
`;
