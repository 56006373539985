import * as yup from 'yup';
import { CustomerBankMode, CustomerBankPromptPayMode } from './formValues.model';

const validateSchema = yup.object().shape({
  from: yup.number().required('กรุณากรอกข้อมูล'),
  firstName: yup.string().required('กรุณากรอกข้อมูล'),
  lastName: yup.string().nullable(),
  email: yup
    .array()
    .required('กรุณากรอกข้อมูล')
    .of(
      yup.object().shape({
        text: yup.string().email(),
      }),
    ),
  phone: yup
    .string()
    .required('กรุณากรอกข้อมูล')
    .matches(/^[0]\d{8,9}$/, 'รูปแบบไม่ถูกต้อง'),
  taxId: yup
    .string()
    .required('กรุณากรอกข้อมูล')
    .matches(/^\d{13}$/, 'รูปแบบไม่ถูกต้อง'),
  branch: yup.string().required('กรุณากรอกข้อมูล'),
  address: yup.string().required('กรุณากรอกข้อมูล').nullable(),
  province: yup.string().required('กรุณากรอกข้อมูล').nullable(),
  district: yup.string().required('กรุณากรอกข้อมูล').nullable(),
  subDistrict: yup.string().required('กรุณากรอกข้อมูล').nullable(),
  postCode: yup
    .string()
    .required('กรุณากรอกข้อมูล')
    .matches(/^\d{5}$/, 'รูปแบบไม่ถูกต้อง')
    .nullable(),
  businessInvoiceType: yup.number().required(),
  businessInvoiceDateRangeStart: yup.string().when('businessInvoiceType', {
    is: (value) => value === 3 || value === 5,
    then: yup
      .string()
      .required('กรุณากรอกข้อมูล')
      .matches(/\b([1-9]|[12][0-9]|3[01])\b/, 'รูปแบบไม่ถูกต้อง'),
  }),
  businessInvoiceDateRangeEnd: yup.string().when('businessInvoiceType', {
    is: (value) => value === 3 || value === 5,
    then: yup
      .string()
      .required('กรุณากรอกข้อมูล')
      .matches(/\b([1-9]|[12][0-9]|3[01])\b/, 'รูปแบบไม่ถูกต้อง')
      .test('businessInvoiceDateRangeEnd', '', function (value) {
        const { parent, createError } = this;
        if (Number(value) < Number(parent.businessInvoiceDateRangeStart)) {
          return createError({
            message: `ต้องมากกว่า ${parent.businessInvoiceDateRangeStart}`,
          });
        }
        return true;
      }),
  }),
  bankName: yup.string().required('กรุณากรอกข้อมูล').nullable(),
  bankBranch: yup.string().required('กรุณากรอกข้อมูล').nullable(),
  accountName: yup.string().required('กรุณากรอกข้อมูล').nullable(),
  accountNumber: yup
    .string()
    .required('กรุณากรอกข้อมูล')
    .matches(/^[\d]+$/, 'รูปแบบไม่ถูกต้อง')
    .nullable(),
  businessPayRoundRound: yup.string(),
  businessPayRoundRoundDate: yup.string().when('businessPayRoundRound', {
    is: (value) => value === '1',
    then: yup
      .string()
      .required('กรุณากรอกข้อมูล')
      .matches(/\b([1-9]|[12][0-9]|3[01])\b/, 'รูปแบบไม่ถูกต้อง'),
  }),
  businessPayRoundRoundAfterInvoice: yup.string().when('businessPayRoundRound', {
    is: (value) => value === '2',
    then: yup
      .string()
      .required('กรุณากรอกข้อมูล')
      .matches(/^[1-9]+[\d]*$/, 'รูปแบบไม่ถูกต้อง'),
    // .matches(/\b([1-9]|[1-9][0-9]|[12][0-9][0-9]|3[0-6][0-5])\b/, 'รูปแบบไม่ถูกต้อง'),
  }),
  customerBankMode: yup.number().required('กรุณากรอกข้อมูล'),
  customerBankPromptPayMode: yup.number().when('customerBankMode', {
    is: (value) => value === CustomerBankMode.PROMPT_PAY,
    then: yup.number().required('กรุณากรอกข้อมูล'),
  }),
  customerBankId: yup.string().when('customerBankMode', {
    is: (value) => value === CustomerBankMode.BANK_ACCOUNT,
    then: yup.string().required('กรุณากรอกข้อมูล'),
  }),
  customerBankCode: yup.string().when('customerBankMode', {
    is: (value) => value === CustomerBankMode.BANK_ACCOUNT,
    then: yup.string().required('กรุณากรอกข้อมูล'),
  }),
  customerAccountName: yup.string().required('กรุณากรอกข้อมูล'),
  customerAccountNumber: yup.string().when('customerBankMode', (customerBankMode) => {
    if (customerBankMode === CustomerBankMode.PROMPT_PAY) {
      return yup.string().when('customerBankPromptPayMode', (customerBankPromptPayMode) => {
        if (customerBankPromptPayMode === CustomerBankPromptPayMode.TELEPHONE_NUMBER) {
          return yup
            .string()
            .required('กรุณากรอกข้อมูล')
            .matches(/^[0]\d{9}$/, 'รูปแบบไม่ถูกต้อง');
        }
        return yup
          .string()
          .required('กรุณากรอกข้อมูล')
          .matches(/^\d{13}$/, 'รูปแบบไม่ถูกต้อง');
      });
    }
    return yup
      .string()
      .required('กรุณากรอกข้อมูล')
      .matches(/^[\d]+$/, 'รูปแบบไม่ถูกต้อง');
  }),
});

export default validateSchema;
