import React, { useContext } from 'react';
import { Row, Col, message } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import {
  BillingNoteContext,
  SelectedInvoiceDetailType,
  SelectedInvoicesType,
} from 'views/AccountReceive/common/context/BillingNoteContext';
import UPDATE_INVOICE_MUTATION, {
  UpdateInvoiceVars,
  UpdateInvoiceData,
} from 'shared/graphql/mutation/updateInvoice';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountReceive/Routes/BillingNote/InvoicesTable/ModalInvoiceDetail/model/formValues.model';
import { StyledButton, TextHeader, Container } from './Styles';

const Header: React.FC = () => {
  const { selectedInvoiceDetail, setSelectedInvoiceDetail, setSelectedInvoices } = useContext(
    BillingNoteContext,
  );
  const { isLoading, invoiceNumber, invoiceStatus, document, invoiceId } = selectedInvoiceDetail;
  const { watch, formState } = useFormContext<FormValues>();
  const { isDirty } = formState;

  const watchInvoiceRemark = watch('invoiceRemark');

  const [updateInvoice] = useMutation<UpdateInvoiceData, UpdateInvoiceVars>(
    UPDATE_INVOICE_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.updateInvoice._id) {
          message.success('สร้างใบแจ้งหนี้แล้ว');
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['Invoice', 'InvoiceStatus', 'Invoices'],
    },
  );

  const submitUpdateInvoice = () => {
    updateInvoice({
      variables: {
        _id: invoiceId,
        invoice_remark: watchInvoiceRemark,
      },
    });
  };

  const closeModalHandler = () => {
    setSelectedInvoiceDetail(
      (prevState): SelectedInvoiceDetailType => ({
        ...prevState,
        isOpenModal: false,
        document: '',
      }),
    );
  };

  const createBillingHandler = () => {
    closeModalHandler();
    setSelectedInvoices(
      (prevState): SelectedInvoicesType => ({
        ...prevState,
        isOpenModal: true,
        invoicesId: [selectedInvoiceDetail.invoiceId],
        payerId: selectedInvoiceDetail.payerId,
        payerFullName: selectedInvoiceDetail.payerFullName,
        systemRequest: selectedInvoiceDetail.systemRequest,
        businessInvoiceType: selectedInvoiceDetail.businessInvoiceType,
      }),
    );
  };

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>{`รหัสใบแจ้งหนี้${invoiceNumber ? `: ${invoiceNumber}` : ''}`}</TextHeader>
        </Col>
        <Col>
          {invoiceStatus === 2 && (
            <StyledButton
              disabled={isLoading}
              color="blue--outline"
              onClick={() => window.open(document)}
            >
              ตัวอย่างเอกสาร
            </StyledButton>
          )}

          <StyledButton disabled={!isDirty} color="green--light" onClick={submitUpdateInvoice}>
            บันทึก
          </StyledButton>

          {invoiceStatus === 2 && (
            <StyledButton
              disabled={isDirty}
              icon={<CheckCircleOutlined />}
              color="green"
              onClick={createBillingHandler}
            >
              สร้างใบวางบิล
            </StyledButton>
          )}

          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
