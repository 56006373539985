import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { PageNotFound } from 'shared/components';
import { CodInProvider } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/CodInContext';
import CodInList from './CodInList';
import CodIn3PL from './CodIn3PL';

const CodInWrapper: React.FC<RouteComponentProps> = (props) => {
  return (
    <CodInProvider>
      <CodIn {...props} />
    </CodInProvider>
  );
};

const CodIn: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;

  return (
    <Switch>
      <Route exact path={`${match.path}/3pl/:id`} component={CodIn3PL} />
      <Route exact path={`${match.path}`} component={CodInList} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default CodInWrapper;
