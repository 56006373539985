/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Select, message, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import RECEIPTS_QUERY, { ReceiptsData, ReceiptsVars, Receipt } from 'shared/graphql/query/receipts';

const { Option } = Select;

type SelectReceiptNumberFieldProps = {
  value?: string;
  payerId?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
};

const SelectReceiptNumberField: React.FC<SelectReceiptNumberFieldProps> = (props) => {
  const { value, payerId, onChange, disabled, style } = props;
  const [receipt, setReceipt] = useState<Receipt[]>([]);
  const { data, loading, error } = useQuery<ReceiptsData, ReceiptsVars>(RECEIPTS_QUERY, {
    variables: {
      payer: payerId,
      showData: 10000,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && data?.receipts) {
      setReceipt([...data.receipts.receipts]);
    }
  }, [data?.receipts, error, loading]);

  const options = receipt.map((item) => (
    <Option key={item._id} value={item._id}>
      {item.receipt_number}
    </Option>
  ));

  return (
    <Select
      style={{
        width: '100%',
        ...style,
      }}
      value={value}
      allowClear
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      placeholder="- เลือก -"
      showSearch
      onChange={(valueChange) => {
        if (onChange) {
          onChange(valueChange || '');
        }
      }}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
    >
      {options}
    </Select>
  );
};

export default SelectReceiptNumberField;
