import React from 'react';
import { matchPath, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import Layout from 'shared/container/Layout';
import { PageNotFound } from 'shared/components';
import CostingFTL from './Routes/CostingFTL';
import CostingParcel from './Routes/CostingParcel';

const CashOnDelivery: React.FC<RouteComponentProps> = (props) => {
  const { match, location } = props;

  const checkPath = (path: string) => {
    return !!matchPath(location.pathname, `${match.path}/${path}`);
  };

  const textHeader: { [key: string]: string } = {
    ftl: 'ต้นทุน/ขาย (Costing)',
    parcel: 'ต้นทุน/ขาย (Costing)',
  };

  const findTextHeader = Object.keys(textHeader).find((item) => checkPath(item));

  return (
    <Layout textHeader={findTextHeader ? textHeader[findTextHeader] : 'Costing'}>
      <Switch>
        {match.isExact && <Redirect to={`${match.path}/ftl`} />}
        <Route path={`${match.path}/ftl`} component={CostingFTL} />
        <Route path={`${match.path}/parcel`} component={CostingParcel} />
        <Redirect from={`${match.path}/costing`} to={location.pathname.replace('costing', 'ftl')} />
        <Route component={PageNotFound} />
      </Switch>
    </Layout>
  );
};

export default CashOnDelivery;
