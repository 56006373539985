import gql from 'graphql-tag';
import { ListParcelStatus } from 'views/CostingParcel/Routes/ListParcel/components/ListParcelStatus';

export interface Parcels {
  _id: string;
  deliveredDate: string;
  trackingNumber: string;
  customer: {
    _id: string;
    full_name: string;
  };
  status: number;
  cost: number;
  parcelRevenue: {
    totalRevenue: number;
  };
  invoice: {
    invoice_number: string;
  };
}

export interface ParcelsListData {
  parcelsList: {
    parcels: Parcels[];
    totalDocument: number;
    totalCost: number;
    totalRevenue: number;
    totalCostAvg: number;
    parcelTarget: number;
    totalParcel: number;
  };
}

export interface ParcelsListVars {
  _id?: string;
  deliveredDate?: string;
  trackingNumber?: string;
  customer?: string;
  status?: number;
  parcelStatus?: ListParcelStatus;
  order_by?: string;
  order_type?: '1' | '-1' | '';
  page?: number;
  showData?: number;
}

export default gql`
  query ParcelsList(
    $_id: ID
    $deliveredDate: String
    $trackingNumber: String
    $customer: ID
    $status: Int
    $parcelStatus: Int
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
  ) {
    parcelsList(
      params: {
        _id: $_id
        deliveredDate: $deliveredDate
        trackingNumber: $trackingNumber
        customer: $customer
        status: $status
        parcelStatus: $parcelStatus
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      parcels {
        _id
        deliveredDate
        trackingNumber
        customer {
          _id
          full_name
        }
        status
        cost
        parcelRevenue {
          totalRevenue
        }
        invoice {
          invoice_number
        }
      }
      totalDocument
      totalCost
      totalRevenue
      totalCostAvg
      parcelTarget
      totalParcel
    }
  }
`;
