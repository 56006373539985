import gql from 'graphql-tag';
import { AppStatus } from 'views/AccountPayment/common/model/appStatus.model';

export interface AppVars {
  _id: string;
}

export interface Payment {
  create_date: string;
  payment_number: string;
  number_ref: string;
  grand_total_price: number;
  payment_remark: string;
}

export interface PaymentVoucher {
  _id: string;
  create_date: string;
  voucher_number: string;
  round_paid: string;
  payee: {
    _id: string;
    full_name: string;
  };
  grand_total_price: number;
  payment: Payment[];
}

export interface AppData {
  app: {
    _id: string;
    app_number: string;
    app_status: AppStatus;
    payment_method: string;
    round_paid: string;
    grand_total_price: number;
    bank_account: {
      bank_name: string;
      bank_branch: string;
      account_name: string;
      account_number: string;
    };
    payment_type: string;
    payment_voucher: PaymentVoucher[];
    re_total_before_discount: number;
    non_total_before_discount: number;
    inc_total_before_discount: number;
    re_total_discount: number;
    non_total_discount: number;
    inc_total_discount: number;
    re_total_after_discount: number;
    non_total_after_discount: number;
    inc_total_after_discount: number;
    total_vat: number;
    re_total: number;
    non_total: number;
    inc_total: number;
    all_wht: {
      wht: number;
      price: number;
      total_wht: number;
    }[];
    total_price: number;
    app_remark: string;
    create_date: string;
    proof_slip: string;
    cancle_remark: string;
  };
}

export default gql`
  query App($_id: ID!) {
    app(_id: $_id) {
      _id
      app_number
      app_status
      payment_method
      round_paid
      grand_total_price
      bank_account {
        bank_name
        bank_branch
        account_name
        account_number
      }
      payment_type
      payment_voucher {
        _id
        create_date
        voucher_number
        round_paid
        payee {
          _id
          full_name
        }
        grand_total_price
        payment {
          create_date
          payment_number
          number_ref
          grand_total_price
          payment_remark
        }
      }
      re_total_before_discount
      non_total_before_discount
      inc_total_before_discount
      re_total_discount
      non_total_discount
      inc_total_discount
      re_total_after_discount
      non_total_after_discount
      inc_total_after_discount
      total_vat
      re_total
      non_total
      inc_total
      all_wht {
        wht
        price
        total_wht
      }
      total_price
      app_remark
      create_date
      proof_slip
      cancle_remark
    }
  }
`;
