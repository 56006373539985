import gql from 'graphql-tag';

export interface ParcelDetailCreateInvoiceData {
  parcelDetailCreateInvoice: {
    _id: string;
  };
}

export interface ParcelDetailCreateInvoiceVars {
  _id: string;
}

export default gql`
  mutation ParcelDetailCreateInvoice($_id: ID!) {
    parcelDetailCreateInvoice(_id: $_id) {
      _id
    }
  }
`;
