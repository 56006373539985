/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Button } from 'shared/components';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Container } from './Styles';
import CompanyTable from './CompanyTable';
import CompanyTabs from './CompanyTabs';

const CompanyList: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;
  const [tabVendor, setTabVendor] = useState<number>(1);
  const [tabName, setTabName] = useState<'vendor'>('vendor');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={12}>
          <Row>
            <Col>
              <CompanyTabs
                tab={tabVendor}
                name={tabName}
                onChange={(value) => {
                  setTabVendor(value);
                  setTabName('vendor');
                  setCurrentPage(1);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Col>
              <Link to={`${match.path}/create`}>
                <Button color="green">เพิ่มรายชื่อเจ้าหนี้</Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      {tabName === 'vendor' && (
        <CompanyTable
          pageSize={pageSize}
          onChangePageSize={(value) => setPageSize(value)}
          currentPage={currentPage}
          onChangeCurrentPage={(value) => setCurrentPage(value)}
        />
      )}
    </Container>
  );
};

export default CompanyList;
