/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode, useEffect } from 'react';
import { Select, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import CUSTOMERS_QUERY, {
  CustomersData,
  CustomersVars,
  Customers,
} from 'shared/graphql/query/customers';

const { Option } = Select;

type SelectCustomerNumberFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const SelectCustomerNumberField: React.FC<SelectCustomerNumberFieldProps> = (props) => {
  const { value, onChange } = props;
  const [customers, setCustomers] = useState<Customers[]>([]);
  const { data, loading, error } = useQuery<CustomersData, CustomersVars>(CUSTOMERS_QUERY, {
    variables: {
      showData: 10000,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && data?.customers) {
      setCustomers([...data.customers.customers]);
    }
  }, [data?.customers, loading, error]);

  const options: ReactNode[] = customers.map((customer) => (
    <Option key={customer._id} value={customer._id}>
      {customer.number}
    </Option>
  ));

  return (
    <Select
      allowClear
      style={{
        width: '100%',
      }}
      value={value || undefined}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      showSearch
      placeholder="ค้นหา"
      onChange={(valueChange) => {
        if (onChange) {
          onChange(valueChange || '');
        }
      }}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};

export default SelectCustomerNumberField;
