/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDateTime } from 'shared/utils/dateTime';
import { Button, Text, Spin } from 'shared/components';
import PAYMENT_COD_QUERY, {
  PaymentCodData,
  PaymentCodVars,
  ShipmentList,
} from 'shared/graphql/query/paymentNewCod';
import { v4 as uuidv4 } from 'uuid';
import { PaymentCodContext } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/context/PaymentCodContext';
import { StyledTable } from './Styles';
import ExpandedRow from './ExpandedRow';

type PaymentCodDataTable = {
  key: string;
  tools: ReactNode;
  column1: string;
  column2: ReactNode;
  column3: string;
  column4: string;
  shipmentList: ShipmentList[];
};

const columns = [
  {
    title: 'เครื่องมือ',
    dataIndex: 'tools',
    width: 70,
  },
  {
    title: 'รหัส COD-IN',
    dataIndex: 'column1',
    width: 150,
  },
  {
    title: 'ยอดรวม COD (บาท)',
    dataIndex: 'column2',
    align: 'right',
    width: 150,
  },
  {
    title: 'วันที่แจ้งโอน',
    dataIndex: 'column3',
    width: 180,
  },
  {
    title: 'วันที่อัพเดต',
    dataIndex: 'column4',
    width: 180,
  },
  {
    title: '',
  },
];

const PaymentCodDetailTable: React.FC = () => {
  const { selectedPaymentCodDetail } = useContext(PaymentCodContext);
  const { paymentCodId } = selectedPaymentCodDetail;
  const { error, data, loading } = useQuery<PaymentCodData, PaymentCodVars>(PAYMENT_COD_QUERY, {
    variables: {
      _id: paymentCodId,
    },
    fetchPolicy: 'cache-and-network',
  });

  let content: ReactNode = <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  let paymentCodData: PaymentCodDataTable[] = [];
  if (data?.paymentNewCod) {
    const { cod_item } = data.paymentNewCod;
    paymentCodData = cod_item.map(
      (item): PaymentCodDataTable => {
        return {
          key: uuidv4(),
          tools: (
            <Button
              disabled
              title="ลบ"
              color="red--outline"
              icon={<DeleteOutlined />}
              size="small"
            />
          ),
          column1: item.cod_in_number,
          column2: (
            <Text customType="primary" strong>
              {item.total_cod ? formatCurrency(item.total_cod) : '-'}
            </Text>
          ),
          column3: item.cod_in_date ? formatDateTime(item.cod_in_date) : '-',
          column4: item.last_update ? formatDateTime(item.last_update) : '-',
          shipmentList: item.shipment_list,
        };
      },
    );

    content = (
      <>
        <StyledTable
          pagination={false}
          columns={columns}
          dataSource={paymentCodData}
          size="small"
          loading={loading}
          expandable={{
            expandedRowRender: (record: { shipmentList: ShipmentList[] }) => (
              <ExpandedRow data={record.shipmentList} />
            ),
          }}
        />
      </>
    );
  }

  if (error) {
    content = error.message;
  }

  return <div>{content}</div>;
};

export default PaymentCodDetailTable;
