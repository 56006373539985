/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, ReactNode, useEffect } from 'react';
import { Input, message, Row, Col, Select } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import CHART_OF_ACCOUNTS_QUERY, {
  ChartOfAccountsData,
  ChartOfAccountsVars,
} from 'shared/graphql/query/chartOfAccountsLevel';
import { Text, SelectChartOfAccountField } from 'shared/components';
import TableChart from './TableChart';

const { Option } = Select;

type CompanyTableProps = {
  mode: string;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type ChartOfAccountsDataTable = {
  key: string;
  tools?: ReactNode;
  column1: string;
  column2: string;
  column3: ReactNode;
  column4: ReactNode;
  children: unknown;
};

const ChartOfAccountsTable: React.FC<CompanyTableProps> = (props) => {
  const { mode, pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [code, setCode] = useState<string>('');
  const [accountNameId, setAccountNameId] = useState<string>('');
  const [level, setLevel] = useState<number | undefined>(2);

  const { loading, error, data } = useQuery<ChartOfAccountsData, ChartOfAccountsVars>(
    CHART_OF_ACCOUNTS_QUERY,
    {
      variables: {
        _id: accountNameId,
        code,
        mode,
        level,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (accountNameId === '' || accountNameId === undefined) {
      setLevel(2);
    } else {
      setLevel(undefined);
    }
  }, [accountNameId]);

  const content = data?.chartOfAccountsLevel.chartOfAccounts.map((item) => ({
    key: item._id,
    column1: item.code,
    column2: item.account_name,
    column3: (
      <Text strong customType="primary">
        {item.level}
      </Text>
    ),
    column4: (
      <Text strong customType="primary">
        {item.type === 1 ? 'บัญชีคุม' : 'บัญชีย่อย'}
      </Text>
    ),
    children:
      item.sub_account && item.sub_account.length < 1
        ? null
        : item.sub_account &&
          item.sub_account.map((item3) => ({
            key: item3._id,
            column1: item3.code,
            column2: <div style={{ marginLeft: 10 }}>{item3.account_name}</div>,
            column3: (
              <Text strong customType="primary">
                {item3.level}
              </Text>
            ),
            column4: (
              <Text strong customType="primary">
                {item3.type === 1 ? 'บัญชีคุม' : 'บัญชีย่อย'}
              </Text>
            ),
            children:
              item3.sub_account && item3.sub_account.length < 1
                ? null
                : item3.sub_account &&
                  item3.sub_account.map((item4) => ({
                    key: item4._id,
                    column1: item4.code,
                    column2: <div style={{ marginLeft: 20 }}>{item4.account_name}</div>,
                    column3: (
                      <Text strong customType="primary">
                        {item4.level}
                      </Text>
                    ),
                    column4: (
                      <Text strong customType="primary">
                        {item4.type === 1 ? 'บัญชีคุม' : 'บัญชีย่อย'}
                      </Text>
                    ),
                    children:
                      item4.sub_account && item4.sub_account.length < 1
                        ? null
                        : item4.sub_account &&
                          item4.sub_account.map((item5) => ({
                            key: item5._id,
                            column1: item5.code,
                            column2: <div style={{ marginLeft: 30 }}>{item5.account_name}</div>,
                            column3: (
                              <Text strong customType="primary">
                                {item5.level}
                              </Text>
                            ),
                            column4: (
                              <Text strong customType="primary">
                                {item5.type === 1 ? 'บัญชีคุม' : 'บัญชีย่อย'}
                              </Text>
                            ),
                            children:
                              item5.sub_account && item5.sub_account.length < 1
                                ? null
                                : item5.sub_account &&
                                  item5.sub_account.map((item6) => ({
                                    key: item6._id,
                                    column1: item6.code,
                                    column2: (
                                      <div style={{ marginLeft: 40 }}>{item6.account_name}</div>
                                    ),
                                    column3: (
                                      <Text strong customType="primary">
                                        {item6.level}
                                      </Text>
                                    ),
                                    column4: (
                                      <Text strong customType="primary">
                                        {item6.type === 1 ? 'บัญชีคุม' : 'บัญชีย่อย'}
                                      </Text>
                                    ),
                                    children:
                                      item6.sub_account && item6.sub_account.length < 1
                                        ? null
                                        : item6.sub_account &&
                                          item6.sub_account.map((item7) => ({
                                            key: item7._id,
                                            column1: item7.code,
                                            column2: (
                                              <div style={{ marginLeft: 50 }}>
                                                {item7.account_name}
                                              </div>
                                            ),
                                            column3: (
                                              <Text strong customType="primary">
                                                {item7.level}
                                              </Text>
                                            ),
                                            column4: (
                                              <Text strong customType="primary">
                                                {item7.type === 1 ? 'บัญชีคุม' : 'บัญชีย่อย'}
                                              </Text>
                                            ),
                                          })),
                                  })),
                          })),
                  })),
          })),
  }));

  const columns = [
    {
      title: 'การกระทำ',
      dataIndex: 'tools',
      width: 70,
    },
    {
      title: 'รหัสบัญชี',
      dataIndex: 'column1',
      width: 150,
      sortName: 'number',
      search: (
        <Input
          allowClear
          placeholder="ค้นหา"
          onChange={(event) => {
            setCode(event.target.value);
            if (event.target.value === '' || event.target.value === undefined) {
              setLevel(2);
            } else {
              setLevel(undefined);
            }
          }}
        />
      ),
    },
    {
      title: 'ชื่อบัญชี',
      dataIndex: 'column2',
      width: 400,
      search: (
        <SelectChartOfAccountField
          onChange={(value: string) => {
            setAccountNameId(value);
          }}
          mode={mode}
        />
      ),
    },
    {
      title: 'ระดับ',
      dataIndex: 'column3',
      width: 60,
      align: 'center',
    },
    {
      title: 'ประเภท',
      dataIndex: 'column4',
      width: 150,
    },
  ];

  let chartOfAccountsDataTable: ChartOfAccountsDataTable[] = [];
  let totalDocument = 0;

  if (content && data && data.chartOfAccountsLevel) {
    chartOfAccountsDataTable = content.map(
      (item): ChartOfAccountsDataTable => ({
        key: item.key,
        tools: undefined,
        column1: item.column1,
        column2: item.column2,
        column3: item.column3,
        column4: item.column4,
        children: item.children !== null && [...item.children],
      }),
    );

    totalDocument = data.chartOfAccountsLevel.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableChart
        columns={columns}
        dataSource={chartOfAccountsDataTable}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value: number) => onChangeCurrentPage(value),
        }}
      />
    </>
  );
};

export default ChartOfAccountsTable;
