import gql from 'graphql-tag';
import { CodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/codStatus.model';

export interface CodItem {
  _id: string;
  customer: {
    _id: string;
    full_name: string;
  };
  shipment_list: {
    _id: string;
    shipment_number: string;
    booking_number: string;
    cod_price: number;
  }[];
  total_cod: number;
}

export interface CodData {
  newCod: {
    _id: string;
    cod_in_number: string;
    total_cod: number;
    cod_status: CodStatus;
    vendor: {
      _id: string;
      full_name: string;
    };
    shipment_numbers: string[];
    cod_in_date: string;
    cod_item: CodItem[];
    document: string[];
    reject_remark: string;
  };
}

export interface CodVars {
  _id: string;
}

export default gql`
  query NewCod($_id: ID!) {
    newCod(_id: $_id) {
      _id
      cod_in_number
      total_cod
      cod_status
      vendor {
        _id
        full_name
      }
      shipment_numbers
      cod_in_date
      cod_item {
        _id
        customer {
          _id
          full_name
        }
        shipment_list {
          _id
          shipment_number
          booking_number
          cod_price
        }
        total_cod
      }
      document
      reject_remark
    }
  }
`;
