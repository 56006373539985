import gql from 'graphql-tag';
import { CodStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/codStatus.model';

export interface Cods {
  _id: string;
  vendor: {
    _id: string;
    full_name: string;
  };
  cod_in_number: string;
  total_cod: number;
  shipment_numbers: string[];
  due_date: string;
  cod_in_date: string;
  ops_employee: {
    _id: string;
    full_name: string;
  };
  payment_cod: {
    _id: string;
    cod_out_number: string;
  };
  cod_status: CodStatus;
  document: string[];
  cod_remark: string;
  reject_remark: string;
  last_send: string;
  create_date: string;
  last_update: string;
}

export interface CodsData {
  newCods: {
    cods: Cods[];
    totalDocument: number;
  };
}

export interface CodsVars {
  _id?: string;
  vendor?: string;
  payment_cod?: string;
  cod_in_number?: string;
  shipment_numbers?: string;
  cod_status?: CodStatus;
  due_date?: string;
  order_by?: string;
  order_type?: '1' | '-1' | '';
  page?: number;
  showData?: number;
}

export default gql`
  query NewCods(
    $_id: ID
    $vendor: ID
    $payment_cod: ID
    $cod_in_number: String
    $shipment_numbers: String
    $cod_status: Int
    $due_date: String
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
  ) {
    newCods(
      params: {
        _id: $_id
        vendor: $vendor
        payment_cod: $payment_cod
        cod_in_number: $cod_in_number
        shipment_numbers: $shipment_numbers
        cod_status: $cod_status
        due_date: $due_date
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      cods {
        _id
        vendor {
          _id
          full_name
        }
        cod_in_number
        total_cod
        shipment_numbers
        due_date
        cod_in_date
        ops_employee {
          _id
          full_name
        }
        payment_cod {
          _id
          cod_out_number
        }
        cod_status
        document
        cod_remark
        reject_remark
        last_send
        create_date
        last_update
      }
      totalDocument
    }
  }
`;
