import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SubTabs } from 'shared/components';
import COSTING_STATUS_QUERY, { CostingStatusData } from 'shared/graphql/query/costingStatus';
import { CostingStatus } from 'views/Costing/common/model/costingStatus.model';

interface CostingTabsProps {
  name: string;
  tab: CostingStatus;
  onChange: (tab: CostingStatus) => void;
}

const CostingTabs: React.FC<CostingTabsProps> = (props) => {
  const { tab, onChange, name } = props;

  const { data, refetch } = useQuery<CostingStatusData>(COSTING_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const checkSelectTab = (id: CostingStatus) => {
    return id === tab && name === 'costing';
  };

  const selectTab = (index: CostingStatus) => {
    refetch();
    onChange(index);
  };

  const tabs = [
    {
      id: CostingStatus.CHECKING,
      name: 'รอตรวจสอบ',
      color: 'badge-grey',
      count: data?.costingStatus.checking || 0,
      divider: false,
    },
    {
      id: CostingStatus.CHECKED,
      name: 'ตรวจสอบแล้ว',
      color: 'badge-grey',
      count: data?.costingStatus.checked || 0,
      divider: true,
    },
    {
      id: CostingStatus.REJECT,
      name: 'ปฎิเสธ',
      color: 'badge-grey',
      count: data?.costingStatus.reject || 0,
      divider: false,
    },
    {
      id: CostingStatus.EDIT,
      name: 'แก้ไข',
      color: 'badge-grey',
      count: data?.costingStatus.edit || 0,
      divider: false,
    },
  ].map((item) => ({
    id: item.id,
    name: item.name,
    count: item.count,
    active: checkSelectTab(item.id),
    clicked: () => selectTab(item.id),
    badgeClassName: checkSelectTab(item.id) ? item.color : null,
    divider: item.divider,
  }));

  return <SubTabs tabs={tabs} />;
};

export default CostingTabs;
