/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message, Divider } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { InvoiceContext } from 'views/AccountReceive/common/context/InvoiceContext';
import UPDATE_INVOICE_MUTATION, {
  UpdateInvoiceVars,
  UpdateInvoiceData,
} from 'shared/graphql/mutation/updateInvoice';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { formatDate } from 'shared/utils/dateTime';
import { StyledModal } from './Styles';

type ModalConfirmCreateInvoiceProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmCreateInvoice: React.FC<ModalConfirmCreateInvoiceProps> = (props) => {
  const { onCancel, visible } = props;
  const { selectedInvoiceDetail } = useContext(InvoiceContext);
  const { modalConfirmCreateInvoice, invoiceId } = selectedInvoiceDetail;
  const {
    payerFullName,
    businessInvoiceType,
    grandTotalPrice,
    costings,
  } = modalConfirmCreateInvoice;

  const [updateInvoice, { loading }] = useMutation<UpdateInvoiceData, UpdateInvoiceVars>(
    UPDATE_INVOICE_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.updateInvoice._id) {
          message.success('สร้างใบแจ้งหนี้แล้ว');
          onCancel();
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['Invoice', 'InvoiceStatus', 'Invoices'],
    },
  );

  const confirmCreateInvoice = () => {
    updateInvoice({
      variables: {
        _id: invoiceId,
        invoice_status: 2,
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันสร้างใบแจ้งหนี้หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmCreateInvoice()}
          loading={loading}
        >
          ใช่,สร้างใบแจ้งหนี้
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>
          ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>:
        </Col>
        <Col span={18}>
          <Text strong>{payerFullName}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>รูปแบบรับใบแจ้งหนี้:</Col>
        <Col span={18}>
          <Text strong>{businessInvoiceType}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>มูลค่าใบแจ้งหนี้รวม:</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(grandTotalPrice, '0')}
          </Text>
        </Col>
      </Row>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Row>
        <Col span={24}>
          ทั้งหมด <Text strong>{`${costings.length} รายการ`}</Text>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 0]}>
            <Col span={4}>วันที่อนุมัติ</Col>
            <Col span={4}>รหัสต้นทุน/ขาย</Col>
            <Col span={4}>รหัสงานขนส่ง</Col>
            <Col span={4}>วันที่เริ่มงาน</Col>
            <Col span={4}>วันที่งานเสร็จสิ้น</Col>
            <Col span={4}>ราคาขาย</Col>
          </Row>

          {costings.map((costing) => (
            <Row gutter={[8, 0]}>
              <Col span={4}>
                <Text strong>{costing.checkedDate ? formatDate(costing.checkedDate) : '-'}</Text>
              </Col>
              <Col span={4}>
                <Text strong>{costing.costingNumber || '-'}</Text>
              </Col>
              <Col span={4}>
                <Text strong>{costing.numberRef}</Text>
              </Col>
              <Col span={4}>
                <Text strong>{costing.serviceDate ? formatDate(costing.serviceDate) : '-'}</Text>
              </Col>
              <Col span={4}>
                <Text strong>
                  {costing.completedDate ? formatDate(costing.completedDate) : '-'}
                </Text>
              </Col>
              <Col span={4}>
                <Text strong customType="primary">
                  {formatCurrency(costing.grandTotalPrice)}
                </Text>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmCreateInvoice;
