import React from 'react';
import formatCurrency from 'shared/utils/formatCurrency';
import { Payment } from 'shared/graphql/query/app';
import { formatDate } from 'shared/utils/dateTime';
import { Text } from 'shared/components';
import { StyledTable } from './Styles';

type ExpandedRowProps = {
  data: Payment[];
};

const columns = [
  {
    title: 'วันที่เอกสาร',
    dataIndex: 'column1',
    width: 110,
  },
  {
    title: 'รหัสใบสั่งจ่าย',
    dataIndex: 'column2',
    width: 110,
  },
  {
    title: 'รหัส Shipment/JOB/Booking',
    dataIndex: 'column3',
    width: 200,
  },
  {
    title: 'จำนวนตั้งเบิก (บาท)',
    dataIndex: 'column4',
    align: 'right',
    width: 150,
  },
  {
    title: 'หมายเหตุ',
    dataIndex: 'column5',
    width: 150,
  },
  {
    title: '',
  },
];

const ExpandedRow: React.FC<ExpandedRowProps> = (props) => {
  const { data } = props;
  const paymentsDataTable = data.map((item, index) => {
    return {
      key: index,
      column1: item.create_date ? formatDate(item.create_date) : '-',
      column2: item.payment_number || '-',
      column3: item.number_ref || '-',
      column4: (
        <Text customType="primary" strong>
          {formatCurrency(item.grand_total_price, '-')}
        </Text>
      ),
      column5: item.payment_remark || '-',
    };
  });

  return (
    <div style={{ marginTop: 10 }}>
      <StyledTable
        scroll={{ x: 'max-content' }}
        pagination={false}
        columns={columns}
        dataSource={paymentsDataTable}
        size="small"
      />
    </div>
  );
};

export default ExpandedRow;
