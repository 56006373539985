/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, useContext, useState } from 'react';
import classNames from 'classnames';
import { Row, Col, Input } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { DeleteOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { Button, Text, Spin } from 'shared/components';
import APP_QUERY, { AppData, AppVars, Payment } from 'shared/graphql/query/app';
import { v4 as uuidv4 } from 'uuid';
import { ApprovePaymentContext } from 'views/AccountPayment/common/context/ApprovePaymentContext';
import ModalAddPaymentVoucher from 'views/AccountPayment/Routes/ApprovePayment/ModalAddPaymentVoucher';
import { StyledTable, StyledDetailContainer, StyledRowDetail } from './Styles';
import ExpandedRow from './ExpandedRow';
import ModalConfirmAddPaymentVoucher from './ModalConfirmAddPaymentVoucher';
import ModalConfirmDeletePaymentVoucher from './ModalConfirmDeletePaymentVoucher';

const { TextArea } = Input;

type PaymentVoucherDataTable = {
  key: string;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: string;
  column4: string;
  column5: ReactNode;
  payments: Payment[];
};

const columns = [
  {
    title: 'เครื่องมือ',
    dataIndex: 'tools',
    width: 70,
  },
  {
    title: 'วันที่เอกสาร',
    dataIndex: 'column1',
    width: 110,
  },
  {
    title: 'รหัสใบสำคัญจ่าย',
    dataIndex: 'column2',
    width: 150,
  },
  {
    title: 'รอบจ่าย',
    dataIndex: 'column3',
    width: 110,
  },
  {
    title: 'ผู้รับเงิน',
    dataIndex: 'column4',
    width: 200,
  },
  {
    title: 'จำนวนตั้งเบิก (บาท)',
    dataIndex: 'column5',
    align: 'right',
    width: 150,
  },
  {
    title: '',
  },
];

type SelectedPaymentVoucher = {
  id: string;
  createDate: string;
  voucherNumber: string;
  grandTotalPrice: number;
  roundPaid: string;
  payeeFullName: string;
};

const BillingNoteDetailTable: React.FC = () => {
  const [isOpenModalAddPaymentVoucher, setOpenModalAddPaymentVoucher] = useState<boolean>(false);
  const [
    isOpenModalConfirmAddPaymentVoucher,
    setOpenModalConfirmAddPaymentVoucher,
  ] = useState<boolean>(false);
  const [isOpenModalDeletePaymentVoucher, setOpenModalDeletePaymentVoucher] = useState<boolean>(
    false,
  );
  const [
    selectedPaymentVoucherDelete,
    setSelectedPaymentVoucherDelete,
  ] = useState<SelectedPaymentVoucher>({
    id: '',
    createDate: '',
    voucherNumber: '',
    grandTotalPrice: 0,
    roundPaid: '',
    payeeFullName: '',
  });
  const [selectedPaymentVoucherAdd, setSelectedPaymentVoucherAdd] = useState<
    SelectedPaymentVoucher[]
  >([]);
  const [appsIdSendUpdate, setAppsIdSendUpdate] = useState<string[]>([]);
  const { selectedApprovePaymentDetail } = useContext(ApprovePaymentContext);
  const { appId, appStatus } = selectedApprovePaymentDetail;
  const { error, data, loading } = useQuery<AppData, AppVars>(APP_QUERY, {
    variables: {
      _id: appId,
    },
    fetchPolicy: 'cache-and-network',
  });

  let content: ReactNode = <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  let paymentVoucherData: PaymentVoucherDataTable[] = [];
  if (data?.app) {
    const {
      payment_voucher,
      re_total_before_discount,
      non_total_before_discount,
      inc_total_before_discount,
      re_total_discount,
      non_total_discount,
      inc_total_discount,
      re_total_after_discount,
      non_total_after_discount,
      inc_total_after_discount,
      total_vat,
      re_total,
      non_total,
      inc_total,
      all_wht,
      total_price,
      grand_total_price,
      app_remark,
    } = data.app;

    paymentVoucherData = payment_voucher.map(
      (item): PaymentVoucherDataTable => {
        const {
          _id,
          create_date,
          voucher_number,
          round_paid,
          payee,
          grand_total_price: grandTotalPricePaymentVoucher,
          payment,
        } = item;

        return {
          key: uuidv4(),
          tools: (
            <Button
              disabled={payment_voucher.length < 2 || appStatus !== 1}
              title="ลบ"
              color="red--outline"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => {
                setAppsIdSendUpdate(
                  payment_voucher
                    .map((paymentVoucher) => paymentVoucher._id)
                    .filter((id) => id !== _id),
                );
                setSelectedPaymentVoucherDelete({
                  id: _id,
                  voucherNumber: voucher_number,
                  grandTotalPrice: grandTotalPricePaymentVoucher,
                  createDate: create_date,
                  payeeFullName: payee.full_name,
                  roundPaid: round_paid,
                });
                setOpenModalDeletePaymentVoucher(true);
              }}
            />
          ),
          column1: create_date ? formatDate(create_date) : '-',
          column2: voucher_number || '-',
          column3: round_paid ? formatDate(round_paid) : '-',
          column4: payee.full_name,
          column5: (
            <Text customType="primary" strong>
              {formatCurrency(grandTotalPricePaymentVoucher, '-')}
            </Text>
          ),
          payments: payment,
        };
      },
    );

    const whtList = all_wht.map((item, index, arr) => {
      const { wht, price, total_wht: totalWht } = item;
      const isLastItem = index === arr.length - 1;
      return (
        <StyledRowDetail key={item.wht} className={classNames({ divider: isLastItem })}>
          <Col className="column-1">{`หัก ณ ที่จ่าย ${wht}% (${formatCurrency(price)})`}</Col>
          <Col className="column-2 text-right" />
          <Col className="column-3 text-right" />
          <Col className="column-4 text-right">{formatCurrency(totalWht, '-')}</Col>
        </StyledRowDetail>
      );
    });

    content = (
      <>
        <StyledTable
          pagination={false}
          columns={columns}
          dataSource={paymentVoucherData}
          size="small"
          loading={loading}
          expandable={{
            expandedRowRender: (record: { payments: Payment[] }) => (
              <ExpandedRow data={record.payments} />
            ),
          }}
        />

        <ModalConfirmDeletePaymentVoucher
          appId={appId}
          paymentVoucher={selectedPaymentVoucherDelete}
          paymentVouchersId={appsIdSendUpdate}
          visible={isOpenModalDeletePaymentVoucher}
          onCancel={() => setOpenModalDeletePaymentVoucher(false)}
        />

        <Row justify="space-between" style={{ marginTop: 10 }}>
          <Col>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button
                  disabled={appStatus !== 1}
                  color="blue--light"
                  icon={<PlusCircleOutlined />}
                  onClick={() => setOpenModalAddPaymentVoucher(true)}
                >
                  เพิ่มรายการ
                </Button>
              </Col>
              <Col span={24}>
                หมายเหตุ <Text customType="grey">(ไม่บังคับ)</Text>
                <TextArea value={app_remark} placeholder="ระบุ" rows={4} disabled />
              </Col>
            </Row>
          </Col>
          <Col>
            <StyledDetailContainer>
              <StyledRowDetail>
                <Col className="column-1">ราคารวมก่อนส่วนลด</Col>
                <Col className="column-2 text-right">
                  {formatCurrency(re_total_before_discount, '-')}
                </Col>
                <Col className="column-3 text-right">
                  {formatCurrency(non_total_before_discount, '-')}
                </Col>
                <Col className="column-4 text-right">
                  {formatCurrency(inc_total_before_discount, '-')}
                </Col>
              </StyledRowDetail>

              <StyledRowDetail className="divider">
                <Col className="column-1">ส่วนลด</Col>
                <Col className="column-2 text-right">{formatCurrency(re_total_discount, '-')}</Col>
                <Col className="column-3 text-right">{formatCurrency(non_total_discount, '-')}</Col>
                <Col className="column-4 text-right">{formatCurrency(inc_total_discount, '-')}</Col>
              </StyledRowDetail>

              <StyledRowDetail>
                <Col className="column-1">ราคารวมหลังส่วนลด</Col>
                <Col className="column-2 text-right">
                  {formatCurrency(re_total_after_discount, '-')}
                </Col>
                <Col className="column-3 text-right">
                  {formatCurrency(non_total_after_discount, '-')}
                </Col>
                <Col className="column-4 text-right">
                  {formatCurrency(inc_total_after_discount, '-')}
                </Col>
              </StyledRowDetail>

              <StyledRowDetail className="divider">
                <Col className="column-1">ภาษีมูลค่าเพิ่ม 7%</Col>
                <Col className="column-2 text-right">-</Col>
                <Col className="column-3 text-right">-</Col>
                <Col className="column-4 text-right">{formatCurrency(total_vat, '-')}</Col>
              </StyledRowDetail>

              <StyledRowDetail>
                <Col className="column-1">รวมเป็นเงิน</Col>
                <Col className="column-2 text-right">{formatCurrency(re_total, '-')}</Col>
                <Col className="column-3 text-right">{formatCurrency(non_total, '-')}</Col>
                <Col className="column-4 text-right">{formatCurrency(inc_total, '-')}</Col>
              </StyledRowDetail>

              <StyledRowDetail className="divider">
                <Col className="column-1">
                  <Text strong>จำนวนรวมทั้งสิ้น</Text>
                </Col>
                <Col className="column-2 text-right" />
                <Col className="column-3 text-right" />
                <Col className="column-4 text-right">
                  <Text strong>{formatCurrency(total_price, '-')}</Text>
                </Col>
              </StyledRowDetail>

              {whtList}

              <StyledRowDetail>
                <Col className="column-1">
                  <Text strong>รวมชำระทั้งสิ้น</Text>
                </Col>
                <Col className="column-2 text-right" />
                <Col className="column-3 text-right" />
                <Col className="column-4 text-right">
                  <Text strong style={{ fontSize: 24 }}>
                    {formatCurrency(grand_total_price)}
                  </Text>
                </Col>
              </StyledRowDetail>
            </StyledDetailContainer>
          </Col>
        </Row>

        <ModalAddPaymentVoucher
          visible={isOpenModalAddPaymentVoucher}
          onOk={(paymentVouchers) => {
            const oldPaymentVouchers: SelectedPaymentVoucher[] = payment_voucher.map((item) => ({
              id: item._id,
              createDate: item.create_date,
              grandTotalPrice: item.grand_total_price,
              voucherNumber: item.voucher_number,
              payeeFullName: item.payee.full_name,
              roundPaid: item.round_paid,
            }));

            const newPaymentVouchers = paymentVouchers.map((paymentVoucher) => {
              const {
                id,
                createDate,
                grandTotalPrice,
                payeeFullName,
                voucherNumber,
                roundPaid,
              } = paymentVoucher;

              return {
                id,
                createDate,
                grandTotalPrice,
                payeeFullName,
                voucherNumber,
                roundPaid,
              };
            });

            setSelectedPaymentVoucherAdd([...oldPaymentVouchers, ...newPaymentVouchers]);
            setOpenModalAddPaymentVoucher(false);
            setOpenModalConfirmAddPaymentVoucher(true);
          }}
          paymentType={selectedApprovePaymentDetail.paymentType}
          onCancel={() => setOpenModalAddPaymentVoucher(false)}
        />

        <ModalConfirmAddPaymentVoucher
          appId={appId}
          paymentVouchers={selectedPaymentVoucherAdd}
          visible={isOpenModalConfirmAddPaymentVoucher}
          onCancel={() => setOpenModalConfirmAddPaymentVoucher(false)}
        />
      </>
    );
  }

  if (error) {
    content = error.message;
  }

  return <div>{content}</div>;
};

export default BillingNoteDetailTable;
