import { useState, useCallback } from 'react';
import changwatsData from 'shared/config/province/changwats/th.json';
import amphoesData from 'shared/config/province/amphoes/th.json';
import tambonsData from 'shared/config/province/tambons/th.json';

const useProvince = () => {
  const [selectedProvince, setSelectedProvince] = useState<string>('');
  const [selectedDistrict, setSelectedDistrict] = useState<string>('');
  const { changwats } = changwatsData.th;
  const { amphoes } = amphoesData.th;
  const { tambons } = tambonsData.th;

  const getProvince = useCallback(() => {
    return changwats.map((item) => ({
      id: item.pid,
      name: item.name,
    }));
  }, [changwats]);

  const getDistrict = useCallback(() => {
    return amphoes
      .filter((item) => item.changwat_pid === selectedProvince || selectedProvince === '')
      .map((item) => ({
        id: item.pid,
        name: item.name,
        provinceId: item.changwat_pid,
      }));
  }, [amphoes, selectedProvince]);

  const getSubDistrict = useCallback(() => {
    return tambons
      .filter((item) => item.amphoe_pid === selectedDistrict || selectedDistrict === '')
      .map((item) => ({
        id: item.pid,
        name: item.name,
        districtId: item.amphoe_pid,
      }));
  }, [selectedDistrict, tambons]);

  const selectProvince = useCallback(
    (provinceId: string) => {
      const findProvince = changwats.find((changwat) => changwat.pid === provinceId);
      setSelectedProvince(findProvince ? findProvince.pid : '');
    },
    [changwats],
  );

  const selectDistrict = useCallback(
    (districtId: string) => {
      const findDistrict = amphoes.find(
        (amphoe) => amphoe.pid === districtId && amphoe.changwat_pid === selectedProvince,
      );
      setSelectedDistrict(findDistrict ? findDistrict.pid : '');
    },
    [amphoes, selectedProvince],
  );

  return {
    provinces: getProvince(),
    districts: getDistrict(),
    subDistricts: getSubDistrict(),
    setProvince: selectProvince,
    setDistrict: selectDistrict,
  };
};

export default useProvince;
