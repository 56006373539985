import React, { useState, useContext } from 'react';
import { Row, Col, message } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import {
  CodContext,
  SelectedCodItemType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/context/CodContext';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/Routes/Cod/ModalCreatePaymentCod/model/formValues.model';
import { StyledButton, TextHeader, Container } from './Styles';
import ModalConfirmCreatePaymentCod from './ModalConfirmCreatePaymentCod';

const Header: React.FC = () => {
  const { handleSubmit } = useFormContext<FormValues>();
  const [
    isOpenModalConfirmCreatePaymentCod,
    setOpenModalConfirmCreatePaymentCod,
  ] = useState<boolean>(false);
  const { selectedCodItems, setSelectedCodItems } = useContext(CodContext);
  const { isLoading } = selectedCodItems;

  const closeModalHandler = () => {
    setSelectedCodItems(
      (prevState): SelectedCodItemType => ({
        ...prevState,
        isOpenModal: false,
      }),
    );
  };

  const onSubmitCreatePaymentCod = handleSubmit(() => {
    setOpenModalConfirmCreatePaymentCod(true);
  });

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>ทำจ่าย COD</TextHeader>
        </Col>
        <Col>
          <StyledButton
            disabled={isLoading}
            icon={<CheckCircleOutlined />}
            color="green"
            onClick={() => {
              if (selectedCodItems.confirmPaymentCod.totalCod <= 2000000) {
                onSubmitCreatePaymentCod();
              } else {
                message.error('มูลค่ารวม COD ห้ามเกิน 2,000,000 บาท');
              }
            }}
          >
            สร้างการทำจ่าย
          </StyledButton>
          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>
      <ModalConfirmCreatePaymentCod
        visible={isOpenModalConfirmCreatePaymentCod}
        onCancel={() => setOpenModalConfirmCreatePaymentCod(false)}
      />
    </Container>
  );
};

export default Header;
