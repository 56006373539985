import React, { useState, useContext } from 'react';
import { Row, Col, message } from 'antd';
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import {
  InvoiceContext,
  SelectedInvoiceDetailType,
} from 'views/AccountReceive/common/context/InvoiceContext';
import UPDATE_INVOICE_MUTATION, {
  UpdateInvoiceVars,
  UpdateInvoiceData,
} from 'shared/graphql/mutation/updateInvoice';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountReceive/Routes/Invoice/InvoicesTable/ModalInvoiceDetail/model/formValues.model';
import { InvoiceStatus } from 'views/AccountReceive/common/model/invoiceStatus.model';
import { StyledButton, TextHeader, Container } from './Styles';
import ModalConfirmCreateInvoice from './ModalConfirmCreateInvoice';
import ModalConfirmCancel from './ModalConfirmCancel';

const Header: React.FC = () => {
  const [isOpenModalConfirmCreateInvoice, setOpenModalConfirmCreateInvoice] = useState<boolean>(
    false,
  );
  const [isOpenModalConfirmCancelInvoice, setOpenModalConfirmCancelInvoice] = useState<boolean>(
    false,
  );
  const { selectedInvoiceDetail, setSelectedInvoiceDetail } = useContext(InvoiceContext);
  const { isLoading, invoiceNumber, invoiceStatus, document, invoiceId } = selectedInvoiceDetail;
  const { watch, formState } = useFormContext<FormValues>();
  const { isDirty } = formState;

  const watchInvoiceRemark = watch('invoiceRemark');

  const [updateInvoice] = useMutation<UpdateInvoiceData, UpdateInvoiceVars>(
    UPDATE_INVOICE_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.updateInvoice._id) {
          message.success('สร้างใบแจ้งหนี้แล้ว');
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['Invoice', 'InvoiceStatus', 'Invoices'],
    },
  );

  const submitUpdateInvoice = () => {
    updateInvoice({
      variables: {
        _id: invoiceId,
        invoice_remark: watchInvoiceRemark,
      },
    });
  };

  const closeModalHandler = () => {
    setSelectedInvoiceDetail(
      (prevState): SelectedInvoiceDetailType => ({
        ...prevState,
        isOpenModal: false,
        document: '',
      }),
    );
  };

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>{`รหัสใบแจ้งหนี้${invoiceNumber ? `: ${invoiceNumber}` : ''}`}</TextHeader>
        </Col>
        <Col>
          {invoiceStatus === 1 && (
            <StyledButton
              disabled={isLoading}
              color="blue--outline"
              onClick={() => window.open(document)}
            >
              ตัวอย่างเอกสาร
            </StyledButton>
          )}

          {(invoiceStatus === 2 ||
            invoiceStatus === 3 ||
            invoiceStatus === 4 ||
            invoiceStatus === 5) && (
            <StyledButton
              disabled={isLoading}
              color="blue--outline"
              onClick={() => window.open(document)}
            >
              เอกสารใบแจ้งหนี้
            </StyledButton>
          )}

          {(invoiceStatus === 1 || invoiceStatus === 2) && (
            <StyledButton
              disabled={isLoading}
              icon={<StopOutlined />}
              color="red"
              onClick={() => setOpenModalConfirmCancelInvoice(true)}
            >
              ยกเลิกการใช้
            </StyledButton>
          )}

          {invoiceStatus === 1 && (
            <StyledButton
              disabled={isLoading}
              icon={<CheckCircleOutlined />}
              color="green"
              onClick={() => setOpenModalConfirmCreateInvoice(true)}
            >
              สร้างใบแจ้งหนี้
            </StyledButton>
          )}

          {invoiceStatus === InvoiceStatus.INVOICE && (
            <StyledButton disabled={!isDirty} color="green--light" onClick={submitUpdateInvoice}>
              บันทึก
            </StyledButton>
          )}

          <ModalConfirmCreateInvoice
            visible={isOpenModalConfirmCreateInvoice}
            onCancel={() => setOpenModalConfirmCreateInvoice(false)}
          />

          <ModalConfirmCancel
            visible={isOpenModalConfirmCancelInvoice}
            onCancel={() => setOpenModalConfirmCancelInvoice(false)}
          />

          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
