/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import UPDATE_VENDOR_MUTATION, {
  UpdateVendorData,
  UpdateVendorVars,
} from 'shared/graphql/mutation/updateVendor';
import { Button, Text } from 'shared/components';
import { FormValues } from 'views/Setting/Routes/Creditor/Individual/IndividualDetail/model/formValues.model';
import { useFormContext } from 'react-hook-form';
import { VendorType } from 'views/Setting/common/model/vendorType.model';
import { StyledModal } from './Styles';

type ModalConfirmUpdateVendorProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmUpdateVendor: React.FC<ModalConfirmUpdateVendorProps> = (props) => {
  const { onCancel, visible } = props;

  const { handleSubmit, getValues } = useFormContext<FormValues>();
  const allValue = getValues();

  const [updateVendor, { loading: updateVendorLoading }] = useMutation<
    UpdateVendorData,
    UpdateVendorVars
  >(UPDATE_VENDOR_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.updateVendor._id) {
        onCancel();
        message.success('แก้ไขบัญชีเจ้าหนี้แล้ว');
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['Vendor'],
  });

  const submitUpdateVendor = handleSubmit((values) => {
    let docAddressData = {
      doc_address: values.docAddress,
      doc_sub_district: values.docSubDistrict,
      doc_district: values.docDistrict,
      doc_province: values.docProvince,
      doc_postcode: values.docPostcode,
      doc_tax_id: values.docTaxId,
    };

    if (values.chkSelectAddress) {
      docAddressData = {
        doc_address: values.address,
        doc_sub_district: values.subDistrict,
        doc_district: values.district,
        doc_province: values.province,
        doc_postcode: values.postCode,
        doc_tax_id: values.taxId,
      };
    }

    updateVendor({
      variables: {
        _id: values.id,
        ...docAddressData,
        vendor_type: VendorType.INDIVIDUAL,
        full_name: values.fullName,
        system_request: values.systemRequest,
        tax_id: values.taxId,
        address: values.address,
        country: values.country,
        province: values.province,
        district: values.district,
        sub_district: values.subDistrict,
        postcode: values.postCode,
        phone: [values.phone],
        staff_phone: values.staffPhone,
        email: values.email.map((item) => item.text),
        bank_account: {
          bank_name: values.bankName,
          bank_branch: values.bankBranch,
          account_name: values.accountName,
          account_number: values.accountNumber,
        },
        checkbox_address_company: values.chkSelectAddress,
      },
    });
  });

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันแก้ไขบัญชีเจ้าหนี้ - บุคคล ใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => submitUpdateVendor()}
          loading={updateVendorLoading}
        >
          ใช่, แก้ไขบัญชีเจ้าหนี้
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={updateVendorLoading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>ชื่อ - นามสกุล:</Col>
        <Col span={18}>
          <Text strong>{allValue.fullName}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>ประเภทงาน:</Col>
        <Col span={18}>
          <Text strong>{allValue.systemRequest}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>เลขประจำตัวผู้เสียภาษี:</Col>
        <Col span={18}>
          <Text strong>{allValue.taxId}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>ที่อยู่:</Col>
        <Col span={18}>
          <Text strong>{allValue.address}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>เบอร์โทรศัพท์:</Col>
        <Col span={18}>
          <Text strong>{allValue.phone}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>อีเมล:</Col>
        <Col span={18}>
          <Text strong>{allValue.email.map((item) => item.text).join(', ')}</Text>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmUpdateVendor;
