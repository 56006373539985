import React, { useState, useEffect } from 'react';
import { Col, Row, Input, Popover, Form } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import formatCurrency from 'shared/utils/formatCurrency';
import IconFilter from 'shared/assets/icons/filter.svg';
import { Button } from 'shared/components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledButtonFilter } from './Styles';
import { FormValues } from './formValues.model';
import validateSchema from './validateSchema';

interface InputMoneyFilterProps {
  onChangeValue: (value: string) => void;
}

const InputRangeFilter: React.FC<InputMoneyFilterProps> = (props) => {
  const { onChangeValue } = props;
  const [textValue, setTextValue] = useState<string>();
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleClear, setVisibleClear] = useState<boolean>(false);

  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validateSchema),
    defaultValues: {
      startNumber: '',
      endNumber: '',
    },
  });

  const { watch, control, errors, handleSubmit, reset, trigger } = methods;

  useEffect(() => {
    setTimeout(() => {
      if (visible) {
        reset({
          startNumber: '',
          endNumber: '',
        });
      }
    }, 0);
  }, [visible, reset]);

  const watchStartNumber = watch('startNumber');
  const watchEndNumber = watch('endNumber');

  const submit = handleSubmit((values) => {
    onChangeValue(`${values.startNumber}:${values.endNumber}`);
    setTextValue(`${formatCurrency(+values.startNumber)} - ${formatCurrency(+values.endNumber)}`);
    setVisible(false);
    setVisibleClear(true);
  });

  const content = (
    <>
      <Row>
        <Col span={12} style={{ fontWeight: 'bold' }}>
          ช่วงเงิน
        </Col>
      </Row>
      <Row>
        <Col style={{ width: 150 }}>ตั้งแต่</Col>
        <Col style={{ width: 120 }}>สิ้นสุด</Col>
      </Row>
      <Row>
        <Col style={{ width: 120 }}>
          <Form.Item
            validateStatus={errors.startNumber && 'error'}
            help={errors.startNumber ? errors.startNumber.message : undefined}
          >
            <Controller
              control={control}
              name="startNumber"
              render={({ onChange, value, onBlur }) => (
                <Input
                  allowClear
                  value={value}
                  onChange={(event) => {
                    onChange(event.target.value);
                    if (watchEndNumber) {
                      trigger(['endNumber']);
                    }
                  }}
                  onBlur={onBlur}
                />
              )}
            />
          </Form.Item>
        </Col>
        <Col style={{ textAlign: 'center', width: 30 }}>-</Col>
        <Col style={{ width: 120 }}>
          <Form.Item
            validateStatus={errors.endNumber && 'error'}
            help={errors.endNumber ? errors.endNumber.message : undefined}
          >
            <Controller
              control={control}
              name="endNumber"
              render={({ onChange, value, onBlur }) => (
                <Input
                  allowClear
                  value={value}
                  onChange={(event) => {
                    onChange(event.target.value);
                  }}
                  onBlur={onBlur}
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button
            onClick={() => {
              submit();
            }}
            style={{ width: '100%' }}
            color="green"
            disabled={!watchStartNumber || !watchEndNumber}
          >
            ตกลง
          </Button>
        </Col>
      </Row>
    </>
  );

  const visibleChangeHandler = (isVisible: boolean) => {
    setVisible(isVisible);
  };

  return (
    <Input.Group compact>
      <Input
        style={{ width: '80%', textAlign: 'left' }}
        value={textValue}
        placeholder="ช่วงเงิน"
        readOnly
      />
      {!visibleClear ? (
        <Popover
          trigger="click"
          placement="bottomRight"
          content={content}
          visible={visible}
          onVisibleChange={visibleChangeHandler}
        >
          <StyledButtonFilter icon={<IconFilter />} style={{ width: '20%' }} />
        </Popover>
      ) : (
        <StyledButtonFilter
          icon={<CloseCircleFilled />}
          onClick={() => {
            reset({
              startNumber: '',
              endNumber: '',
            });
            onChangeValue('');
            setTextValue('');
            setVisibleClear(false);
          }}
          style={{ width: '20%' }}
        />
      )}
    </Input.Group>
  );
};

export default InputRangeFilter;
