import styled from 'styled-components';
import { Modal, Form } from 'antd';

export const StyledModal = styled(Modal)`
  top: 0px;
  padding: 20px;

  &.custom-modal.ant-modal {
    width: 100% !important;
    max-width: 1366px;
  }

  & .ant-modal-body {
    padding: 20px;

    & p {
      margin: 0;
    }
  }

  & .ant-modal-body p {
    margin: 0;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;
