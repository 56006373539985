import gql from 'graphql-tag';

export interface Payee {
  _id: string;
  full_name: string;
}

export interface Payment {
  _id: string;
  payment_number: string;
  payment_type: string;
}

export interface PaymentVoucher {
  _id: string;
  create_date: string;
  voucher_number: string;
  voucher_status: number;
  grand_total_price: number;
  payee: Payee;
  due_date: string;
  payment_method: string;
  payment: Payment[];
  payment_type: string;
  last_update: string;
  document: string;
  total_price: number;
  save_status: number;
  round_paid: string;
}

export interface PaymentVouchers {
  paymentVouchers: PaymentVoucher[];
  totalDocument: number;
}

export interface PaymentVouchersData {
  paymentVouchers: PaymentVouchers;
}

export interface PaymentVouchersVars {
  create_date?: string;
  voucher_number?: string;
  payee?: string;
  due_date?: string;
  payment_method?: string;
  payment?: string;
  round_paid?: string;
  voucher_status?: number;
  page?: number;
  showData?: number;
  order_by?: string;
  order_type?: string;
  payment_type?: string;
}

export default gql`
  query PaymentVouchers(
    $create_date: String
    $voucher_number: String
    $payee: ID
    $due_date: String
    $payment_method: String
    $payment: ID
    $round_paid: String
    $voucher_status: Int
    $page: Int
    $showData: Int
    $order_by: String
    $order_type: String
    $payment_type: String
  ) {
    paymentVouchers(
      params: {
        create_date: $create_date
        voucher_number: $voucher_number
        payee: $payee
        due_date: $due_date
        payment_method: $payment_method
        payment: $payment
        voucher_status: $voucher_status
        round_paid: $round_paid
        payment_type: $payment_type
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      paymentVouchers {
        _id
        create_date
        voucher_number
        voucher_status
        grand_total_price
        payee {
          _id
          full_name
        }
        due_date
        payment_method
        payment {
          _id
          payment_number
          payment_type
        }
        payment_type
        last_update
        document
        total_price
        save_status
        round_paid
      }
      totalDocument
    }
  }
`;
