/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useEffect, ReactNode } from 'react';
import { Row, Col, Input, Form, DatePicker } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Text, Button, InputNumberFormat } from 'shared/components';
import moment from 'moment';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import {
  FormValues,
  ChartOfAccount,
} from 'views/Setting/Routes/ChartOfAccounts/JV/JvDetail/model/formValues.model';
import { StyledTable, StyledFormItem } from './Styles';
import ModalAddItem from './ModalAddItem';
import ModalConfirmDeleteItem from './ModalConfirmDeleteItem';

const dateFormat = 'YYYY/MM/DD';

type FormsProps = {
  isItem: (value: boolean) => void;
};

type JvDataTable = {
  key: string | undefined;
  tools: ReactNode;
  column1: ReactNode;
  column2: ReactNode;
  column3: ReactNode;
};

const Forms: React.FC<FormsProps> = (props) => {
  const { isItem } = props;
  const [isOpenModalAddItem, setOpenModalAddItem] = useState<boolean>(false);
  const [isOpenModalDeleteItem, setOpenModalDeleteItem] = useState<boolean>(false);
  const [indexDelete, setIndexDelete] = useState<number>();
  const { errors, control } = useFormContext<FormValues>();

  const { fields: chartOfAccountList, append, remove } = useFieldArray<ChartOfAccount>({
    control,
    name: 'chartOfAccountList',
  });

  useEffect(() => {
    if (chartOfAccountList.length > 0) {
      isItem(true);
    } else {
      isItem(false);
    }
  }, [chartOfAccountList.length, isItem]);

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'รหัสบัญชี',
      dataIndex: 'column1',
      width: 150,
    },
    {
      title: 'ชื่อบัญชี',
      dataIndex: 'column2',
      width: 300,
    },
    {
      title: 'มูลค่า',
      dataIndex: 'column3',
      width: 150,
    },
  ];

  let jvDataTable: JvDataTable[] = [];
  jvDataTable = chartOfAccountList.map(
    (item, index): JvDataTable => {
      return {
        key: item.id,
        tools: (
          <>
            <Button
              title="ลบ"
              color="red--outline"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => {
                setOpenModalDeleteItem(true);
                setIndexDelete(index);
              }}
            />
          </>
        ),
        column1: (
          <>
            <Controller
              as={Input}
              type="hidden"
              control={control}
              name={`chartOfAccountList[${index}].chartOfAccountId`}
              defaultValue={item.chartOfAccountId}
            />
            {item.code}
            <Controller
              as={Input}
              type="hidden"
              control={control}
              name={`chartOfAccountList[${index}].code`}
              defaultValue={item.code}
            />
          </>
        ),
        column2: (
          <>
            {item.accountName}
            <Controller
              as={Input}
              type="hidden"
              control={control}
              name={`chartOfAccountList[${index}].accountName`}
              defaultValue={item.accountName}
            />
          </>
        ),
        column3: (
          <StyledFormItem
            validateStatus={
              errors.chartOfAccountList && errors.chartOfAccountList[index]?.value && 'error'
            }
          >
            <Controller
              control={control}
              name={`chartOfAccountList[${index}].value`}
              defaultValue={item.value}
              render={({ onChange, value, onBlur }) => (
                <InputNumberFormat
                  value={value}
                  onValueChange={(valueChange) => {
                    // onChange(getValueFormNumber(valueChange.floatValue));
                    onChange(valueChange.floatValue);
                    // trigger([`paymentList[${index}].discount`]);
                  }}
                  fixedDecimalScale
                  onBlur={() => onBlur()}
                  allowNegative
                />
              )}
            />
          </StyledFormItem>
        ),
      };
    },
  );

  return (
    <>
      <Row gutter={[0, 16]}>
        <Col span={12}>
          <Row gutter={[16, 0]}>
            <Col>
              คำอธิบาย:<Text customType="danger">*</Text>
            </Col>
            <Col span={20}>
              <Form.Item
                validateStatus={errors.description && 'error'}
                help={errors.description ? errors.description.message : undefined}
              >
                <Controller as={Input} control={control} name="description" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[16, 0]}>
            <Col>
              วันที่เอกสาร:<Text customType="danger">*</Text>
            </Col>
            <Col span={8}>
              <Form.Item
                validateStatus={errors.documentDate && 'error'}
                help={errors.documentDate ? errors.documentDate.message : undefined}
              >
                <Controller
                  control={control}
                  name="documentDate"
                  render={({ onChange, value }) => (
                    <DatePicker
                      style={{ width: '100%' }}
                      allowClear={false}
                      value={value ? moment(value, dateFormat) : null}
                      placeholder="- เลือก -"
                      onChange={(valueDate) => {
                        if (!valueDate) {
                          onChange('');
                        } else {
                          onChange(valueDate.format(dateFormat));
                        }
                      }}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <StyledTable
        scroll={{ x: 'max-content' }}
        pagination={false}
        columns={columns}
        dataSource={jvDataTable}
        size="small"
        locale={{
          emptyText: <Text strong>กรุณาเลือกบัญชี 1 บัญชี</Text>,
        }}
      />

      <Row justify="space-between" style={{ marginTop: 10 }}>
        <Col>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Button
                disabled={chartOfAccountList.length === 1}
                color="blue--light"
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  setOpenModalAddItem(true);
                }}
              >
                เพิ่มรายการ
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <ModalAddItem
        visible={isOpenModalAddItem}
        onCancel={() => setOpenModalAddItem(false)}
        onOk={(value) => {
          append({
            mode: value.mode,
            chartOfAccountId: value.chartOfAccountId,
            code: value.code,
            accountName: value.accountName,
            value: 0,
          });

          setOpenModalAddItem(false);
        }}
      />
      <ModalConfirmDeleteItem
        visible={isOpenModalDeleteItem}
        onCancel={() => setOpenModalDeleteItem(false)}
        onOk={() => {
          remove(indexDelete);
        }}
      />
    </>
  );
};

export default Forms;
