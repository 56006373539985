//LIBRARY
import React from 'react';
import { Calendar, Select } from 'antd';
import moment from 'moment';

//UTILS
import { STYLE_CALENDAR_MARGIN } from '../utils/Dashboard.Style';

//FUNCTIONS
import {
  THIS_CALENDAR_MONTH,
  THIS_CALENDAR_YEAR,
  THIS_MONTH,
  THIS_YEAR,
} from '../utils/Dashboard.Constants';
import DashboardRenderCell from './DashboardRenderCell';

//COMPONENTS

// interface DataProps {
//   date: string;
//   day: string;
//   month: string;
//   total_price: number;
//   total_tracking: number;
//   total_tracking_wait_tranfer: number;
//   wait_tranfer_price: number;
// }

interface CalendarProps {
  month: number;
  year: number;
  setYear?: (year: number) => void;
  setMonth?: (month: number) => void;
  setSelectDate?: (date: string) => void;
  setOpen?: (open: boolean) => void;
  data?: any;
}

const DashboardRenderCalendar: React.FC<CalendarProps> = ({
  month = THIS_MONTH - 1,
  year = THIS_YEAR,
  setYear = () => {},
  setMonth = () => {},
  setSelectDate = () => {},
  setOpen = () => {},
  data = [],
}) => {
  const { Option } = Select;

  return (
    <div style={STYLE_CALENDAR_MARGIN}>
      <Calendar
        disabledDate={(current) => {
          const thisMonth = current.month() === month;
          return !thisMonth;
        }}
        value={moment(new Date(year, month))}
        headerRender={() => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <div
              style={{
                marginRight: '10px',
              }}
            >
              <Select
                defaultValue={THIS_MONTH}
                value={month}
                onChange={(value) => setMonth(value)}
                style={{ width: '150px' }}
              >
                {THIS_CALENDAR_MONTH?.filter((_, i) => {
                  if (year < THIS_YEAR) {
                    return _;
                  } else {
                    return i < THIS_MONTH;
                  }
                })?.map((item, index) => {
                  return (
                    <Option key={`option-month-${index}`} value={index}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <Select value={year} onChange={(value) => setYear(value)} style={{ width: '100px' }}>
              {THIS_CALENDAR_YEAR?.map((item, index) => {
                return (
                  <Option key={`option-year-${index}`} value={item}>
                    {item}
                  </Option>
                );
              })}
            </Select>
          </div>
        )}
        dateCellRender={(props) => DashboardRenderCell(props, data)}
        onPanelChange={(event) => {
          setMonth(event.month());
          setYear(event.year());
        }}
        onSelect={(event) => {
          setSelectDate(event.format('YYYY-MM-DD'));
          setOpen(true);
        }}
      />
    </div>
  );
};

export default DashboardRenderCalendar;
