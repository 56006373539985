import gql from 'graphql-tag';

export interface ConfirmParcelCostData {
  confirmParcelCost: {
    _id: string;
    refNumber: string;
    costing_number: string;
  };
}

export interface ConfirmParcelCostVars {
  _id: string;
}

export default gql`
  mutation ConfirmParcelCost($_id: ID!) {
    confirmParcelCost(_id: $_id) {
      _id
      costing_number
      refNumber
    }
  }
`;
