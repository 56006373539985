/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Row, Col, Divider, message, Radio } from 'antd';
import formatCurrency from 'shared/utils/formatCurrency';
import { useMutation } from '@apollo/react-hooks';
import { useFormContext, Controller } from 'react-hook-form';
import { FormValues } from 'views/AccountReceive/Routes/Receipt/ReceiptsTable/ModalReceiptDetail/model/formValues.model';
import {
  ReceiptContext,
  SelectedReceiptDetailType,
} from 'views/AccountReceive/common/context/ReceiptContext';
import SEND_EMAIL_RECEIPT_MUTATION, {
  SendEmailReceiptData,
  SendEmailReceiptVars,
} from 'shared/graphql/mutation/sendEmailReceipt';
import { Button, Text } from 'shared/components';
import { StyledModal } from './Styles';

type ModalConfirmSendEmailProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmSendEmail: React.FC<ModalConfirmSendEmailProps> = (props) => {
  const { onCancel, visible } = props;
  const { selectedReceiptDetail, setSelectedReceiptDetail } = useContext(ReceiptContext);
  const { receiptId, systemRequest, totalReceipt } = selectedReceiptDetail;
  const { watch, control, reset } = useFormContext<FormValues>();
  const watchPayerFullName = watch('payerFullName');
  const watchSendEmail = watch('sendEmail');

  const [sendEmailReceipt, { loading }] = useMutation<SendEmailReceiptData, SendEmailReceiptVars>(
    SEND_EMAIL_RECEIPT_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.sendEmailReceipt) {
          const { data: dataReturn } = data.sendEmailReceipt;
          if (dataReturn === null) {
            message.warning('ส่งใบเสร็จลูกค้าไม่สำเร็จ');
          } else {
            return message.success('ส่งใบเสร็จลูกค้าแล้ว');
          }
          onCancel();
          reset();
          setSelectedReceiptDetail(
            (prevState): SelectedReceiptDetailType => ({
              ...prevState,
              isOpenModal: false,
            }),
          );
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['Receipt', 'ReceiptStatus', 'Receipts', 'EmailLogs'],
    },
  );

  const confirmUpdate = () => {
    sendEmailReceipt({
      variables: {
        _id: receiptId,
        type: watchSendEmail,
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันการส่งใบเสร็จให้ลูกค้าใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmUpdate()}
          loading={loading}
        >
          ใช่
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>
          ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>:
        </Col>
        <Col span={18}>
          <Text strong>{watchPayerFullName}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>ประเภทงาน:</Col>
        <Col span={18}>
          <Text strong>{systemRequest}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>รวมมูลค่า:</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(totalReceipt, '0')}
          </Text>
        </Col>
      </Row>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Row>
        <Col span={24}>
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Text>ส่งใบเสร็จผ่านอีเมลให้ลูกค้า</Text>
            </Col>
            <Col span={24}>
              <Controller
                control={control}
                name="sendEmail"
                render={({ value, onChange }) => (
                  <Radio.Group
                    value={value}
                    onChange={(valueOnChange) => onChange(valueOnChange.target.value)}
                  >
                    <Radio value="copy">สำเนา</Radio>
                    <Radio value="original">ตัวจริง</Radio>
                  </Radio.Group>
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmSendEmail;
