import gql from 'graphql-tag';

export interface BuildConfirmReceiptVars {
  invoice?: string[];
}

export interface AllWht {
  wht: number;
  price: number;
  total_wht: number;
}

export interface BuildConfirmReceiptData {
  buildConfirmReceipt: {
    bank_name: string;
    account_number: string;
    total_paid: number;
    all_wht: AllWht[];
    unpaid: number;
    paid: number;
    receipt_value: number;
  };
}

export default gql`
  query BuildConfirmReceipt($invoice: [ID]) {
    buildConfirmReceipt(createConfirmReceipt: { invoice: $invoice }) {
      bank_name
      account_number
      total_paid
      all_wht {
        wht
        price
        total_wht
      }
      paid
      unpaid
      receipt_value
    }
  }
`;
