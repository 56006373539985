/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Divider, Row, Col, Tabs, Input, DatePicker, Select } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import formatCurrency from 'shared/utils/formatCurrency';
import moment from 'moment';
import { color } from 'shared/utils/styles';
import {
  Tag,
  Text,
  SelectEmployeesField,
  SelectPayeesField,
  SelectSystemRequestField,
  SelectPaymentTypeField,
  SelectDepartmentField,
} from 'shared/components';
import { useFormContext, Controller } from 'react-hook-form';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalCreatePaymentOrder/model/formValues.model';
import ListPayment from './ListPayment';
import { StyledFormItem } from './Styles';
import UploadDocument from './UploadDocument';

const { TabPane } = Tabs;
const dateFormat = 'YYYY/MM/DD';
const { Option } = Select;

const CreatePaymentOrder: React.FC = () => {
  const { setValue, watch, control, errors } = useFormContext<FormValues>();
  const watchGrandTotalPrice = watch('grandTotalPrice');

  return (
    <>
      <Text strong>เงื่อนไขรายการจ่าย</Text>
      <Divider style={{ margin: '10px 0' }} />
      <Row gutter={[32, 16]}>
        <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  ผู้ตั้งเบิก<Text customType="danger">*</Text>
                  <StyledFormItem
                    validateStatus={errors.employeeId && 'error'}
                    style={{ width: '100%' }}
                  >
                    <Controller
                      control={control}
                      name="employeeId"
                      render={({ onChange, value }) => (
                        <SelectEmployeesField
                          value={value}
                          onChange={(valueChange) => {
                            onChange(valueChange.employeeId);
                          }}
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
                <Col span={12}>
                  แผนกของค่าใช้จ่าย<Text customType="danger">*</Text>
                  <StyledFormItem
                    validateStatus={errors.department && 'error'}
                    style={{ width: '100%' }}
                  >
                    {watch('paymentType') !== 'COGs' ? (
                      <Controller
                        control={control}
                        name="department"
                        render={({ onChange, value }) => (
                          <SelectDepartmentField
                            value={value || undefined}
                            onChange={(valueChange) => {
                              onChange(valueChange);
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Input value="-" disabled />
                    )}
                  </StyledFormItem>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col>
                  ผู้รับเงิน <Text customType="grey">(เจ้าหนี้)</Text>
                  <Text customType="danger">*</Text>
                </Col>
                <Col span={24}>
                  <StyledFormItem validateStatus={errors.payeeId && 'error'}>
                    <Controller
                      control={control}
                      name="payeeId"
                      render={({ onChange, value }) => (
                        <SelectPayeesField
                          value={value || undefined}
                          onChange={(valueChange) => {
                            onChange(valueChange ? valueChange.payeeId : '');
                            setValue('payeeName', valueChange ? valueChange.fullName : '');
                          }}
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Text>รอบที่ต้องจ่าย</Text>
                  <Text customType="danger">*</Text>
                  <StyledFormItem validateStatus={errors.roundPaidDate && 'error'}>
                    <Controller
                      control={control}
                      name="roundPaidDate"
                      render={({ onChange, value }) => (
                        <DatePicker
                          style={{ width: '100%' }}
                          allowClear={false}
                          value={value ? moment(value, dateFormat) : null}
                          placeholder="- เลือก -"
                          onChange={(valueDate) => {
                            if (!valueDate) {
                              onChange('');
                            } else {
                              onChange(valueDate.format(dateFormat));
                            }
                          }}
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
                <Col span={12}>
                  <Text>รหัส Shipment/JOB/Booking</Text>
                  <StyledFormItem validateStatus={errors.numberRef && 'error'}>
                    <Controller
                      control={control}
                      name="numberRef"
                      render={({ value, onChange }) => (
                        <Input
                          placeholder="ระบุ"
                          value={value}
                          onChange={(valueNumberRef) => onChange(valueNumberRef)}
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
          <Row gutter={[16, 16]} justify="space-between">
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Text>ประเภทการจ่าย</Text>
                  <Text customType="danger">*</Text>
                </Col>
                <Col span={24}>
                  <StyledFormItem validateStatus={errors.paymentType && 'error'}>
                    <Controller
                      control={control}
                      name="paymentType"
                      render={({ onChange, value }) => (
                        <SelectPaymentTypeField
                          value={value || undefined}
                          onChange={(valuePaymentType) => onChange(valuePaymentType)}
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Text>Product</Text>
                  <Text customType="danger">*</Text>
                </Col>
                <Col span={24}>
                  <StyledFormItem validateStatus={errors.systemRequest && 'error'}>
                    <Controller
                      control={control}
                      name="systemRequest"
                      render={({ onChange, value }) => (
                        <SelectSystemRequestField
                          value={value}
                          onChange={(valueChange) => {
                            onChange(valueChange);
                          }}
                          placeholder="- เลือก -"
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
              </Row>
            </Col>
          </Row>
          {watch('paymentType') === 'OPEX' && (
            <Row gutter={[16, 16]} justify="space-between">
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Text>Fixed Asset Check</Text>
                    <Text customType="danger">*</Text>
                  </Col>
                  <Col span={24}>
                    <StyledFormItem validateStatus={errors.fixedAssetCheck && 'error'}>
                      <Controller
                        control={control}
                        name="fixedAssetCheck"
                        render={({ onChange, value }) => (
                          <Select
                            style={{
                              width: '100%',
                            }}
                            value={value || undefined}
                            placeholder="- เลือก -"
                            onChange={(valueChange) => onChange && onChange(valueChange)}
                            allowClear
                          >
                            <Option value="1">Expense</Option>
                            <Option value="2">{'Asset <= 3000'}</Option>
                            <Option value="3">{'Asset > 3000'}</Option>
                          </Select>
                        )}
                      />
                    </StyledFormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row gutter={[16, 16]} justify="space-between">
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Text>Payment Invoice Date</Text>
                  <Text customType="danger">*</Text>
                </Col>
                <Col span={24}>
                  <StyledFormItem validateStatus={errors.paymentInvoiceDate && 'error'}>
                    <Controller
                      control={control}
                      name="paymentInvoiceDate"
                      render={({ onChange, value }) => (
                        <DatePicker
                          style={{ width: '100%' }}
                          allowClear={false}
                          value={value ? moment(value, dateFormat) : null}
                          placeholder="- เลือก -"
                          onChange={(valueDate) => {
                            if (!valueDate) {
                              onChange('');
                            } else {
                              onChange(valueDate.format(dateFormat));
                            }
                          }}
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Text>Invoice Due Date</Text>
                  <Text customType="danger">*</Text>
                </Col>
                <Col span={24}>
                  <StyledFormItem validateStatus={errors.invoiceDueDate && 'error'}>
                    <Controller
                      control={control}
                      name="invoiceDueDate"
                      render={({ onChange, value }) => (
                        <DatePicker
                          style={{ width: '100%' }}
                          allowClear={false}
                          value={value ? moment(value, dateFormat) : null}
                          placeholder="- เลือก -"
                          onChange={(valueDate) => {
                            if (!valueDate) {
                              onChange('');
                            } else {
                              onChange(valueDate.format(dateFormat));
                            }
                          }}
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify="space-between">
            <Col span={12}>
              <Text>Vendor Invoice</Text>
              <StyledFormItem validateStatus={errors.vendorInvoice && 'error'}>
                <Controller
                  control={control}
                  name="vendorInvoice"
                  render={({ value, onChange }) => (
                    <Input
                      placeholder="ระบุ"
                      value={value}
                      onChange={(valueVendorInvoice) => onChange(valueVendorInvoice)}
                    />
                  )}
                />
              </StyledFormItem>
            </Col>
            <Col span={12}>
              <Text>Vendor Name</Text>
              <StyledFormItem validateStatus={errors.vendorId && 'error'}>
                <Controller
                  control={control}
                  name="vendorId"
                  render={({ onChange, value }) => (
                    <SelectPayeesField
                      value={value || undefined}
                      onChange={(valueChange) => {
                        onChange(valueChange ? valueChange.payeeId : '');
                      }}
                    />
                  )}
                />
              </StyledFormItem>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            <Col span={8}>วันที่เอกสาร:</Col>
            <Col span={16}>
              <StyledFormItem validateStatus={errors.serviceDate && 'error'}>
                <Controller
                  control={control}
                  name="serviceDate"
                  render={({ onChange, value }) => (
                    <DatePicker
                      allowClear={false}
                      style={{ width: '100%' }}
                      value={value ? moment(value, dateFormat) : null}
                      placeholder="- เลือก -"
                      onChange={(valueDate) => {
                        if (!valueDate) {
                          onChange('');
                        } else {
                          onChange(valueDate.format(dateFormat));
                        }
                      }}
                    />
                  )}
                />
              </StyledFormItem>
            </Col>
          </Row>
          <Row>
            <Col span={8}>รหัสใบสั่งจ่าย:</Col>
            <Col span={16}>
              <Text strong>-</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>มูลค่าใบสำคัญจ่าย:</Col>
            <Col span={16}>
              <Text strong customType="primary">
                {formatCurrency(watchGrandTotalPrice)}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>สถานะ:</Col>
            <Col span={16}>
              <Tag customColor="grey">กำลังสร้าง...</Tag>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider style={{ margin: 0, marginTop: -5 }} />

      <Tabs defaultActiveKey="1" animated={false}>
        <TabPane
          tab={
            <span>
              รายการจ่าย{' '}
              <CheckCircleOutlined
                style={{
                  color: color.success,
                  marginLeft: 5,
                }}
              />
            </span>
          }
          key="1"
        >
          <ListPayment />
        </TabPane>
        <TabPane
          tab={
            <span>
              เอกสารอื่นๆ{' '}
              <CheckCircleOutlined
                style={{
                  color: color.success,
                  marginLeft: 5,
                }}
              />
            </span>
          }
          key="2"
        >
          <Controller
            control={control}
            name="otherDocument"
            render={({ onChange, value }) => <UploadDocument onChange={onChange} value={value} />}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default CreatePaymentOrder;
