import gql from 'graphql-tag';

interface Customer {
  _id: string;
  number: string;
  full_name: string;
  business_invoice_type: number;
  email: string[];
  phone: string[];
  tax_id: string;
  full_address: string;
}

interface LogARs {
  _id: string;
  customer: Customer;
  totalDebt: string;
  totalDebtOverdue: string;
  lastFollowDate: string;
  totalInvoice: string[];
  totalInvoiceOverdue: string[];
  totalBillingNote: string[];
  createdAt: string;
  updatedAt: string;
}

export interface LogARsData {
  logARs: {
    logARs: LogARs[];
    totalDocument: number;
  };
}

export interface LogARsVars {
  _id?: string;
  customer?: string;
  totalDebt?: string;
  totalDebtOverdue?: string;
  lastFollowDate?: string;
  createdAt?: string;
  updatedAt?: string;
  showData?: number;
  page?: number;
  order_by?: string;
  order_type?: '1' | '-1' | '';
}

export default gql`
  query LogARs(
    $_id: ID
    $customer: ID
    $totalDebt: String
    $totalDebtOverdue: String
    $lastFollowDate: String
    $createdAt: String
    $updatedAt: String
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
  ) {
    logARs(
      params: {
        _id: $_id
        customer: $customer
        totalDebt: $totalDebt
        totalDebtOverdue: $totalDebtOverdue
        lastFollowDate: $lastFollowDate
        createdAt: $createdAt
        updatedAt: $updatedAt
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      logARs {
        _id
        customer {
          _id
          number
          full_name
          external_system_id
          business_invoice_type
          email
          phone
          tax_id
          full_address
        }
        totalDebt
        totalDebtOverdue
        lastFollowDate
        totalInvoice
        totalInvoiceOverdue
        totalBillingNote
        createdAt
        updatedAt
      }
      totalDocument
    }
  }
`;
