import { Table } from 'antd';
import React from 'react';
import formatCurrency from 'shared/utils/formatCurrency';

interface DataType {
  // key: string;
  // totalTrackingWaitTranfer: number;
  // waitTranferPrice: number;
  detailByAgent: any;
  // date: string;
}

const DashboardRenderModalTable: React.FC<DataType> = (props) => {
  const { detailByAgent } = props;

  // console.log('detailByAgent', detailByAgent);

  const columns = [
    // {
    //   title: 'Agent',
    //   dataIndex: 'agent',
    //   key: 'agent',
    // },
    {
      title: 'AGENT',
      dataIndex: 'agent_name',
      key: 'agent_name',
    },
    {
      title: 'มูลค่าที่ต้องโอน',
      dataIndex: 'wait_tranfer_price',
      key: 'wait_tranfer_price',
      render: (text: number) => <div>{formatCurrency(text, '0.00')}</div>,
    },
    {
      title: 'จำนวนชิ้น',
      dataIndex: 'total_tracking_wait_tranfer',
      key: 'total_tracking_wait_tranfer',
    },
  ];

  return (
    <div>
      <Table dataSource={detailByAgent} columns={columns} pagination={false} />
    </div>
  );
};

export default DashboardRenderModalTable;
