/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useEffect, useContext, useState } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import COD_QUERY, { CodVars, CodData } from 'shared/graphql/query/cod';
import {
  CodInContext,
  SelectedCodItemType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/CodInContext';
import { PageResultError } from 'shared/components';
import { Container, StyledPageHeader } from './Styles';
import CodIn3PLTable from './CodIn3PLTable';
import ModalCodCompleted from './ModalCodCompleted';

type Params = {
  id: string;
};

const CodIn3PL: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const { id } = useParams<Params>();
  const [isOpenModalCodCompleted, setOpenModalCodCompleted] = useState<boolean>(false);
  const { selectedCodItem, setSelectedCodItem } = useContext(CodInContext);
  const { isUpdateChecked } = selectedCodItem;
  const { data, error, loading } = useQuery<CodData, CodVars>(COD_QUERY, {
    variables: {
      _id: id,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && data?.cod) {
      const {
        customer,
        cod_in_number,
        booking_number,
        total_cod,
        total_vendor,
        confirm_status,
      } = data.cod;

      setSelectedCodItem(
        (prevState): SelectedCodItemType => ({
          ...prevState,
          modalCodInCompleted: {
            customerFullName: customer.full_name,
            codInNumber: cod_in_number,
            bookingNumber: booking_number,
            totalCod: total_cod,
            totalVendor: total_vendor,
            confirmStatus: confirm_status,
          },
        }),
      );

      if (total_vendor === confirm_status && isUpdateChecked) {
        setOpenModalCodCompleted(true);
      }
    }
  }, [data?.cod, error, isUpdateChecked, loading, setSelectedCodItem]);

  let content = (
    <Container>
      <Skeleton />
    </Container>
  );

  if (error) {
    content = <PageResultError title="เกิดข้อผิดพลาด" message={error.message} />;
  }

  if (data?.cod) {
    const {
      cod_in_number,
      cod_item,
      cod_status,
      customer,
      booking_number,
      _id,
      total_cod,
    } = data.cod;

    content = (
      <Container>
        <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3', marginBottom: 10 }}>
          <Col span={12}>
            <StyledPageHeader
              onBack={() => history.push('/cod/by-booking/cod-in')}
              title={cod_in_number}
            />
          </Col>
        </Row>

        <CodIn3PLTable
          data={{
            codInId: _id,
            codStatus: cod_status,
            codItems: cod_item,
            customerFullName: customer.full_name,
            bookingNumber: booking_number,
            codInNumber: cod_in_number,
            totalCod: total_cod,
          }}
        />

        <ModalCodCompleted
          visible={isOpenModalCodCompleted}
          onCancel={() => {
            setOpenModalCodCompleted(false);
            setSelectedCodItem(
              (prevState): SelectedCodItemType => ({
                ...prevState,
                isUpdateChecked: false,
              }),
            );
          }}
        />
      </Container>
    );
  }

  return content;
};

export default CodIn3PL;
