/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Row, Col, Divider, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import {
  BillingNoteContext,
  SelectedBillingNoteDetailType,
} from 'views/AccountReceive/common/context/BillingNoteContext';
import SEND_EMAIL_BILLING_NOTE_MUTATION, {
  SendEmailBillingNoteData,
  SendEmailBillingNoteVars,
} from 'shared/graphql/mutation/sendEmailBillingNote';
import { formatDate } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { StyledModal } from './Styles';

type ModalConfirmSendEmailProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmSendEmail: React.FC<ModalConfirmSendEmailProps> = (props) => {
  const { onCancel, visible } = props;
  const { selectedBillingNoteDetail, setSelectedBillingNoteDetail } = useContext(
    BillingNoteContext,
  );
  const { billingNoteId, modalConfirmUpdate } = selectedBillingNoteDetail;
  const { invoices, businessInvoiceType, payerFullName, grandTotalPrice } = modalConfirmUpdate;

  const [sendEmailBillingNote, { loading }] = useMutation<
    SendEmailBillingNoteData,
    SendEmailBillingNoteVars
  >(SEND_EMAIL_BILLING_NOTE_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.sendEmailBillingNote) {
        const { data: dataReturn } = data.sendEmailBillingNote;
        if (dataReturn === null) {
          message.warning('ส่งใบวางบิลไม่สำเร็จ');
        } else {
          return message.success('ส่งใบวางบิลลูกค้าแล้ว');
        }
        onCancel();
        setSelectedBillingNoteDetail(
          (prevState): SelectedBillingNoteDetailType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['BillingNote', 'BillingNoteStatus', 'BillingNotes', 'EmailLogs'],
  });

  const confirmUpdate = () => {
    sendEmailBillingNote({
      variables: {
        _id: billingNoteId,
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันการส่งใบวางบิลลูกค้าใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmUpdate()}
          loading={loading}
        >
          ใช่
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={6}>
          ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>:
        </Col>
        <Col span={18}>
          <Text strong>{payerFullName}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>รูปแบบรับใบแจ้งหนี้:</Col>
        <Col span={18}>
          <Text strong>{businessInvoiceType}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6}>มูลค่าใบวางบิล:</Col>
        <Col span={18}>
          <Text strong customType="primary">
            {formatCurrency(grandTotalPrice, '0')}
          </Text>
        </Col>
      </Row>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Row>
        <Col span={24}>
          ทั้งหมด <Text strong>{`${invoices.length} รายการ`}</Text>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 0]}>
            <Col span={4}>วันที่เอกสาร</Col>
            <Col span={4}>รหัสใบแจ้งหนี้</Col>
            <Col span={4}>มูลค่าใบแจ้งหนี้</Col>
          </Row>

          {invoices.map((item) => (
            <Row gutter={[8, 0]}>
              <Col span={4}>
                <Text strong>{item.createDate ? formatDate(item.createDate) : '-'}</Text>
              </Col>
              <Col span={4}>
                <Text strong>{item.invoiceNumber || '-'}</Text>
              </Col>
              <Col span={4}>
                <Text strong customType="primary">
                  {formatCurrency(item.grandTotalPrice)}
                </Text>
              </Col>
            </Row>
          ))}
          <Row gutter={[16, 0]} style={{ marginTop: 30 }}>
            <Col span={16}>
              <Text>ต้องการส่งใบวางบิลนี้ผ่านอีเมลให้ลูกค้าทันที</Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmSendEmail;
