/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { message, Skeleton, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { FileOutlined } from '@ant-design/icons';
import { Button } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import JV_QUERY, { JvData, JvVars } from 'shared/graphql/query/jv';
import { FormValues } from 'views/Setting/Routes/ChartOfAccounts/JV/JvDetail/model/formValues.model';
import { color } from 'shared/utils/styles';
import { Container } from './Styles';
import Forms from '../Forms';
import validateSchema from '../validateSchema';
import ModalConfirmUpdate from './ModalConfirmUpdate';
import ModalConfirmCancel from './ModalConfirmCancel';

const { TabPane } = Tabs;

type Params = {
  id: string;
};

const EditJv: React.FC = () => {
  const [isOpenModalConfirmUpdate, setOpenModalConfirmUpdate] = useState<boolean>(false);
  const [isOpenModalConfirmCancel, setOpenModalConfirmCancel] = useState<boolean>(false);
  const [isItem, setIsItem] = useState<boolean>(true);
  const { id } = useParams<Params>();
  const { data, loading, error } = useQuery<JvData, JvVars>(JV_QUERY, {
    variables: {
      _id: id,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = <Skeleton />;

  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validateSchema),
    defaultValues: {
      description: '',
      documentDate: '',
      chartOfAccountList: [],
    },
  });

  const { handleSubmit, reset, register, formState } = methods;
  const { isDirty } = formState;

  useEffect(() => {
    register('jvId');
    register('jvNumber');
  }, [register]);

  useEffect(() => {
    if (!loading && !error && data?.jv) {
      const {
        _id,
        jv_number,
        code,
        account_name,
        description,
        document_date,
        value,
        chartOfAccount,
      } = data.jv;

      reset({
        jvId: _id,
        jvNumber: jv_number,
        description: description || '',
        documentDate: document_date,
        chartOfAccountList: [
          { chartOfAccountId: chartOfAccount._id, code, accountName: account_name, value },
        ],
      });
    }
  }, [error, loading, reset, data?.jv]);

  const submitUpdate = handleSubmit(() => {
    setOpenModalConfirmUpdate(true);
  });

  if (data?.jv) {
    content = (
      <>
        <Container>
          <Tabs
            defaultActiveKey="1"
            animated={false}
            tabBarExtraContent={
              <>
                <Button
                  disabled={!isDirty || !isItem}
                  color="green"
                  onClick={submitUpdate}
                  style={{ marginRight: 10 }}
                >
                  บันทึก
                </Button>
                <Button onClick={() => setOpenModalConfirmCancel(true)}>ยกเลิก</Button>
              </>
            }
          >
            <TabPane
              tab={
                <div style={{ display: 'flex', lineHeight: 1 }}>
                  <FileOutlined
                    style={{
                      color: color.primary,
                      marginRight: 5,
                      fontWeight: 'bold',
                    }}
                  />
                  <div style={{ fontWeight: 'bold' }}>สร้างรายการ JV</div>
                </div>
              }
              key="1"
            >
              <FormProvider {...methods}>
                <Forms isItem={(value) => (value ? setIsItem(true) : setIsItem(false))} />
                <ModalConfirmUpdate
                  visible={isOpenModalConfirmUpdate}
                  onCancel={() => setOpenModalConfirmUpdate(false)}
                />
                <ModalConfirmCancel
                  visible={isOpenModalConfirmCancel}
                  onCancel={() => setOpenModalConfirmCancel(false)}
                />
              </FormProvider>
            </TabPane>
          </Tabs>
        </Container>
      </>
    );
  }

  return content;
};

export default EditJv;
