export const PaymentOrderStatusType = {
  REVIEW: 'review', // 1
  WAIT: 'wait', // 2
  COMPLETE: 'complete', // 3
  REJECT: 'reject', // 4
  CANCEL: 'cancel', // 5
  PAID: 'paid', // 6
  EDIT: 'edit', // 7
};

export const PaymentOrderStatusText = {
  [PaymentOrderStatusType.REVIEW]: 'รอตรวจสอบ',
  [PaymentOrderStatusType.WAIT]: 'รอสั่งจ่าย',
  [PaymentOrderStatusType.COMPLETE]: 'สั่งจ่ายแล้ว',
  [PaymentOrderStatusType.REJECT]: 'ปฏิเสธ',
  [PaymentOrderStatusType.CANCEL]: 'ยกเลิก',
  [PaymentOrderStatusType.PAID]: 'ทำจ่ายแล้ว',
  [PaymentOrderStatusType.EDIT]: 'แก้ไข',
};

export const PaymentVoucherStatusType = {
  RECHECK: 'Recheck', // 1
  PENDING_APPROVAL: 'Pending approval', // 2
  WAITING_PAYMENT_RECORD: 'Waiting payment record', // 3
  SUCCESSFUL_PAYMENT: 'Successful payment', // 4
  REJECT: 'Reject', // 5
  CANCEL: 'Cancel', // 6
  EDIT: 'Edit', // 7
};

export const PaymentVoucherStatusText = {
  [PaymentVoucherStatusType.RECHECK]: 'รอตรวจสอบ',
  [PaymentVoucherStatusType.PENDING_APPROVAL]: 'รออนุมัติจ่าย',
  [PaymentVoucherStatusType.WAITING_PAYMENT_RECORD]: 'รอบันทึกการจ่าย',
  [PaymentVoucherStatusType.SUCCESSFUL_PAYMENT]: 'จ่ายเรียบร้อย',
  [PaymentVoucherStatusType.REJECT]: 'ปฏิเสธ',
  [PaymentVoucherStatusType.CANCEL]: 'ยกเลิก',
  [PaymentVoucherStatusType.EDIT]: 'แก้ไข',
};

export const InvoiceStatusType = {
  DRAFT: 'Draft', // 1
  WAITING: 'Waiting', // 2
  BILLING_NOTE: 'Billing note', // 3
  REJECT: 'Reject', // 4
  CANCEL: 'Cancel', // 5
};

export const InvoiceStatusText = {
  [InvoiceStatusType.DRAFT]: 'แบบร่าง',
  [InvoiceStatusType.WAITING]: 'รอวางบิล',
  [InvoiceStatusType.BILLING_NOTE]: 'วางบิลแล้ว',
  [InvoiceStatusType.REJECT]: 'ปฏิเสธ',
  [InvoiceStatusType.CANCEL]: 'ยกเลิกการใช้',
};

export const BillingNoteStatusType = {
  WAITING: 'Waiting', // 1
  BILLING_NOTE: 'Billing note', // 2
  COMPLETE: 'Complete', // 3
};

export const BillingNoteStatusText = {
  [BillingNoteStatusType.WAITING]: 'รอสร้างใบวางบิล',
  [BillingNoteStatusType.BILLING_NOTE]: 'รอวางบิล',
  [BillingNoteStatusType.COMPLETE]: 'รอรับชำระ',
};

export const ReceiptStatusType = {
  COMPLETE: 'complete', // 1
  CANCEL: 'cancel', // 2
};

export const ReceiptStatusText = {
  [ReceiptStatusType.COMPLETE]: 'ชำระแล้ว',
  [ReceiptStatusType.CANCEL]: 'ยกเลิกการใช้',
};

export const SaveStatusType = {
  WAITING: 'waiting', // 1
  SAVED: 'saved', // 2
};

export const SaveStatusText = {
  [SaveStatusType.WAITING]: 'รอบันทึกบัญชี',
  [SaveStatusType.SAVED]: 'บันทึกบัญชี',
};

export const CostingStatusType = {
  CHECKING: 'checking', // 1
  CHECKED: 'checked', // 2
  REJECT: 'reject', // 3
  EDIT: 'edit', // 4
};

export const CostingStatusText = {
  [CostingStatusType.CHECKING]: 'รอตรวจสอบ',
  [CostingStatusType.CHECKED]: 'ตรวจสอบแล้ว',
  [CostingStatusType.REJECT]: 'ปฏิเสธ',
  [CostingStatusType.EDIT]: 'แก้ไข',
};

export const ParcelCostingStatusType = {
  WAIT_AGENT_CONFIRM: 'waitAgentConfirm', // 1
  WAITING_CONFIRM: 'waitingConfirm', // 2
  CONFIRM: 'confirm', // 3
  REJECT: 'reject', // 4
  EDIT: 'edit', // 5
  CANCEL: 'cancel', // 6
};

export const ParcelCostingStatusText = {
  [ParcelCostingStatusType.WAIT_AGENT_CONFIRM]: 'รอ Agent ยืนยัน',
  [ParcelCostingStatusType.WAITING_CONFIRM]: 'รอตรวจสอบ',
  [ParcelCostingStatusType.CONFIRM]: 'ตรวจสอบแล้ว',
  [ParcelCostingStatusType.REJECT]: 'ปฎิเสธ',
  [ParcelCostingStatusType.EDIT]: 'แก้ไข',
  [ParcelCostingStatusType.CANCEL]: 'ยกเลิก',
};
