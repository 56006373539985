import React from 'react';
import classNames from 'classnames';
import { Container, StyledBadge, Divider } from './Styles';

const SubTabs = (props) => {
  const { tabs } = props;
  const tabList = tabs.map((item) => (
    <React.Fragment key={item.id}>
      <li
        className={classNames({
          active: item.active,
          divider: item.divider,
        })}
        onMouseDown={item.clicked}
        role="tab"
      >
        {item.name}{' '}
        <StyledBadge
          count={item.count}
          className={classNames({ [item.badgeClassName]: item.badgeClassName })}
        />
      </li>
      {item.divider && <Divider />}
    </React.Fragment>
  ));

  return (
    <Container {...props}>
      <ul>{tabList}</ul>
    </Container>
  );
};

export default SubTabs;
