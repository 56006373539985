import React from 'react';
import { Redirect, Route, matchPath, RouteComponentProps } from 'react-router-dom';
import Tabs from 'shared/components/Tabs';
import Layout from 'shared/container/Layout';
import PaymentVoucher from './Routes/PaymentVoucher';
import PaymentOrder from './Routes/PaymentOrder';
import ApprovePayment from './Routes/ApprovePayment';

type Tabs = 'payment-order' | 'payment-voucher' | 'approve-payment';

const AccountPayment: React.FC<RouteComponentProps> = (props) => {
  const { history, match, location } = props;

  const selectTab = (tabIndex: Tabs) => {
    const getUrl = match.path;
    history.push(`${getUrl}/${tabIndex}`);
  };

  const checkTabsActive = (tabIndex: Tabs) => {
    return !!matchPath(location.pathname, `${match.path}/${tabIndex}`);
  };

  const tabs = [
    {
      name: 'ใบสั่งจ่าย',
      icon: 'file',
      active: checkTabsActive('payment-order'),
      clicked: () => selectTab('payment-order'),
    },
    {
      name: 'ใบสำคัญจ่าย',
      icon: 'file',
      active: checkTabsActive('payment-voucher'),
      clicked: () => selectTab('payment-voucher'),
    },
    {
      name: 'รอบจ่าย',
      icon: 'file',
      active: checkTabsActive('approve-payment'),
      clicked: () => selectTab('approve-payment'),
    },
  ];

  return (
    <>
      {match.isExact && <Redirect to={`${match.path}/payment-order`} />}
      <Layout textHeader="บัญชีรายจ่าย">
        <Tabs menu={tabs} />
        <Route path={`${match.path}/payment-order`} component={PaymentOrder} />
        <Route path={`${match.path}/payment-voucher`} component={PaymentVoucher} />
        <Route path={`${match.path}/approve-payment`} component={ApprovePayment} />
      </Layout>
    </>
  );
};

export default AccountPayment;
