import gql from 'graphql-tag';
import { ParcelDetailStatus } from 'views/CostingParcel/Routes/ParcelDetail/components/ParcelDetailStatus';

export interface ParcelDetailData {
  parcelDetail: {
    _id: string;
    number: string;
    customer: {
      full_name: string;
      address: string;
      tax_id: string;
      phone: string[];
      email: string[];
    };
    invoice: {
      business_invoice_type: string;
      due_date: string;
      system_request: string;
      grand_total_price: number;
      invoice_status: number;
      invoice_number: string;
    };
    billing_note: {
      bill_number: string;
    };
    receipt: {
      receipt_number: string;
      receipt_date: string;
    };
    createdAt: string;
    parcels: {
      trackingNumber: string;
      itemName: string;
      deliveredDate: string;
      weight: number;
      cbm: number;
      cost: number;
      parcelRevenue: {
        totalRevenue: number;
      };
      cod: number;
    }[];
    status: ParcelDetailStatus;
  };
}

export interface ParcelDetailVars {
  _id: string;
}

export default gql`
  query ParcelDetail($_id: ID) {
    parcelDetail(_id: $_id) {
      _id
      number
      customer {
        full_name
        address
        tax_id
        phone
        email
      }
      invoice {
        business_invoice_type
        due_date
        system_request
        grand_total_price
        invoice_status
        invoice_number
      }
      billing_note {
        bill_number
      }
      receipt {
        receipt_number
        receipt_date
      }
      createdAt
      parcels {
        trackingNumber
        itemName
        deliveredDate
        weight
        cbm
        cost
        parcelRevenue {
          totalRevenue
        }
        cod
      }
      status
    }
  }
`;
