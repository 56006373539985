import React from 'react';
import { Steps } from 'antd';

const { Step } = Steps;

type Step = 0 | 1 | 2;

type HeaderStepProps = {
  activeStep: Step;
};

const HeaderStep: React.FC<HeaderStepProps> = (props) => {
  const { activeStep } = props;
  return (
    <Steps size="small" current={activeStep}>
      <Step title="เลือกเอกสารรับชำระ" />
      <Step title="ระบุการรับชำระ" />
      <Step title="ตรวจสอบข้อมูล" />
    </Steps>
  );
};

export default HeaderStep;
