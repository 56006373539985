import React from 'react';
import {
  Redirect,
  Route,
  matchPath,
  useHistory,
  useLocation,
  useRouteMatch,
  Switch,
} from 'react-router-dom';
import { Tabs, PageNotFound } from 'shared/components';
import { CustomerProvider } from 'views/Setting/Routes/Customer/common/context/CustomerContext';
import CustomerList from './Routes/CustomerList';
import CustomerDetail from './Routes/CustomerDetail';

type Tabs = 'debt-tracker';

const CustomerWrapper: React.FC = () => {
  return (
    <CustomerProvider>
      <Customer />
    </CustomerProvider>
  );
};

const Customer: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const selectTab = () => {
    const getUrl = match.path;
    history.push(`${getUrl}`);
  };

  const checkTabsActive = () => {
    return !!matchPath(location.pathname, `${match.path}`);
  };

  const tabs = [
    {
      name: 'บัญชีลูกหนี้',
      icon: 'file',
      active: checkTabsActive(),
      clicked: () => selectTab(),
    },
  ];

  return (
    <>
      {match.isExact && <Redirect to={`${match.path}`} />}
      <Tabs menu={tabs} />
      <Switch>
        <Route path={`${match.path}/edit/:id`} component={CustomerDetail} />
        <Route path={`${match.path}/create`} component={CustomerDetail} />
        <Route path={`${match.path}`} component={CustomerList} />
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default CustomerWrapper;
