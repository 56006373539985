/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import formatCurrency from 'shared/utils/formatCurrency';
import { Col, Popover, Row, Table as TableAntd, Typography } from 'antd';
import { ParcelCostData } from 'shared/graphql/query/parcelCost';
import { v4 as uuidv4 } from 'uuid';
import { StyledTable, TextTotalPrice } from './Styles';

const { Text } = Typography;

type ServiceTableProps = {
  parcelCostData: ParcelCostData;
};

const TotalPriceTable: React.FC<ServiceTableProps> = ({ parcelCostData }) => {
  const {
    all_wht,
    total_vat,
    total_discount,
    grand_total_price,
    total_after_discount,
    total_before_discount,
  } = parcelCostData.parcelCost;
  const columnsTableService = [
    {
      title: '',
      children: [
        {
          title: 'รายการ',
          dataIndex: 'list_name',
          key: 'list_name',
          width: 200,
        },
      ],
    },
    {
      title: 'สรุปรายการต้นทุน',
      children: [
        {
          title: 'ราคาต่อหน่วย',
          dataIndex: 'price',
          key: 'price',
          width: 100,
          align: 'right',
        },
        {
          title: 'ส่วนลด',
          dataIndex: 'discount',
          key: 'discount',
          width: 100,
          align: 'right',
        },
        {
          title: 'ราคาหลังลด',
          dataIndex: 'total_price',
          key: 'total_price',
          width: 100,
          align: 'right',
        },
        {
          title: 'ภาษีมูลค่าเพิ่ม',
          dataIndex: 'vat',
          key: 'vat',
          width: 100,
          align: 'right',
        },
        {
          title: 'หัก ณ ที่จ่าย',
          dataIndex: 'wht',
          key: 'wht',
          width: 100,
          align: 'right',
        },
        {
          title: 'ราคาต้นทุน',
          dataIndex: 'grand_total_price',
          key: 'grand_total_price',
          width: 100,
          align: 'right',
        },
      ],
    },
  ];

  const rowDefaultValue = {
    price: formatCurrency(0, '-'),
    discount: formatCurrency(0, '-'),
    total_price: formatCurrency(0, '-'),
    vat: formatCurrency(0, '-'),
    wht: formatCurrency(0, '-'),
    grand_total_price: formatCurrency(0, '-'),
  };

  const rowAllWHT = all_wht.map((item) => ({
    key: uuidv4(),
    list_name: `รวมหัก ณ ที่จ่าย ${item.wht}%`,
    wht:
      item.total_wht > 0 ? (
        <Popover placement="top" content={<div>{formatCurrency(item.price)}</div>}>
          <Text>{`${formatCurrency(item.total_wht)}`}</Text>
        </Popover>
      ) : (
        '-'
      ),
  }));

  const listName = [
    {
      key: uuidv4(),
      list_name: 'ราคารวมก่อนส่วนลด',
      total_price: formatCurrency(total_before_discount, '-'),
    },
    {
      key: uuidv4(),
      list_name: 'รวมส่วนลด',
      discount: formatCurrency(total_discount, '-'),
    },
    {
      key: uuidv4(),
      list_name: 'ราคารวมหลังส่วนลด',
      total_price: formatCurrency(total_after_discount, '-'),
    },
    {
      key: uuidv4(),
      list_name: 'รวมภาษีมูลค่าเพิ่ม 7%',
      vat: formatCurrency(total_vat, '-'),
    },
    ...rowAllWHT,
  ].map((item) => ({
    ...rowDefaultValue,
    ...item,
  }));

  const dataTable = [...listName];

  return (
    <StyledTable
      bordered
      pagination={false}
      className="table-2"
      dataSource={dataTable}
      style={{ marginTop: 15 }}
      columns={columnsTableService}
      summary={() => {
        return (
          <TableAntd.Summary.Row>
            <TableAntd.Summary.Cell colSpan={7} index={1}>
              <Row justify="end" align="middle" gutter={[16, 0]}>
                <Col>
                  <Text strong>ต้นทุนสุทธิ / Net Cost</Text>
                </Col>
                <Col>
                  <Text strong>
                    <TextTotalPrice>{`${formatCurrency(grand_total_price)} บาท`}</TextTotalPrice>
                  </Text>
                </Col>
              </Row>
            </TableAntd.Summary.Cell>
          </TableAntd.Summary.Row>
        );
      }}
    />
  );
};

export default TotalPriceTable;
