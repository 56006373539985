import gql from 'graphql-tag';

export interface PaymentCodStatusData {
  paymentNewCodStatus: {
    waiting: number;
    paid: number;
  };
}

export default gql`
  query PaymentNewCodStatus {
    paymentNewCodStatus {
      waiting
      paid
    }
  }
`;
