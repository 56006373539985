import gql from 'graphql-tag';
import { BillingNotesStatus } from 'views/AccountReceive/common/model/billingNoteStatus.model';

export interface UpdateBillingNoteData {
  updateBillingNote: {
    _id: string;
    bill_status: BillingNotesStatus;
  };
}

export interface UpdateBillingNoteVars {
  _id: string;
  bill_invoice?: {
    invoice: string;
  }[];
  bill_status?: BillingNotesStatus;
  due_date?: string;
  bill_remark?: string;
  send_email?: boolean;
  cancel_remark?: string;
}

export default gql`
  mutation UpdateBillingNote(
    $_id: ID!
    $bill_invoice: [inputBillInvoice]
    $bill_status: Int
    $due_date: String
    $bill_remark: String
    $send_email: Boolean
    $cancel_remark: String
  ) {
    updateBillingNote(
      _id: $_id
      updateBillingNote: {
        bill_invoice: $bill_invoice
        bill_status: $bill_status
        due_date: $due_date
        bill_remark: $bill_remark
        send_email: $send_email
        cancel_remark: $cancel_remark
      }
    ) {
      _id
      bill_status
    }
  }
`;
