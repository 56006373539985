import React, { useRef } from 'react';
import { message } from 'antd';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import SINGLE_UPLOAD_MUTATION, {
  SingleUploadData,
  SingleUploadVars,
} from 'shared/graphql/mutation/singleUpload';
import { useMutation } from '@apollo/react-hooks';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);
const acceptedFile = ['image/jpeg', 'image/png', 'application/pdf'] as const;
type AcceptedFileTypes = typeof acceptedFile[number];
type UploadFileProps = {
  maxFiles?: number;
  maxFileSize?: string;
  acceptedFileTypes?: AcceptedFileTypes[];
  onCompleted?: (path: string) => void;
};

const defaultProps = {
  maxFileSize: '2MB',
  acceptedFileTypes: ['image/jpeg', 'image/png', 'application/pdf'] as AcceptedFileTypes[],
};

const UploadFile: React.FC<UploadFileProps> = (props) => {
  const { maxFiles, maxFileSize, acceptedFileTypes, onCompleted } = props;
  const filePondRef = useRef<any>();
  const [singleUpload] = useMutation<SingleUploadData, SingleUploadVars>(SINGLE_UPLOAD_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.singleUpload.path) {
        const { path } = data.singleUpload;
        message.success('อัพโหลดเอกสารแล้ว');
        if (onCompleted) {
          onCompleted(path);
        }
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
  });

  return (
    <FilePond
      ref={filePondRef}
      server={{
        process: (_fieldName, file, _metadata, _load, error) => {
          singleUpload({
            variables: {
              file: file as File,
            },
          }).catch((err) => {
            message.error(err.message);
            error(err);
          });
        },
      }}
      acceptedFileTypes={acceptedFileTypes}
      maxFileSize={maxFileSize}
      maxFiles={maxFiles}
    />
  );
};

UploadFile.defaultProps = defaultProps;
export default UploadFile;
