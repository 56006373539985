import React, { ReactNode } from 'react';
import { Empty } from 'antd';
import { StyledTable } from './Styles';

type Column = {
  title: string;
  dataIndex?: string;
  width?: number;
  search?: ReactNode;
};

type Pagination = {
  current: number;
  pageSize: number;
  total: number;
  showSizeChanger: boolean;
  onChange: (value: number) => void;
};

type TableChartProps = {
  columns: Column[];
  dataSource: any[];
  loading?: boolean;
  pagination: Pagination | false;
};

const defaultProps = {
  loading: false,
};

const TableChart: React.FC<TableChartProps> = (props) => {
  const { columns, ...otherProps } = props;

  const renderColumns = columns.map((column) => ({
    ...column,
    title: (
      <div className="table-header">
        <div className="table-header__text">{column.title}</div>
        {column.search && <div className="table-header__search">{column.search}</div>}
      </div>
    ),
    dataIndex: column.dataIndex,
  }));

  return (
    <StyledTable
      columns={renderColumns}
      scroll={{ x: 'max-content' }}
      locale={{
        emptyText: <Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
      }}
      indentSize={0}
      // expandRowByClick
      {...otherProps}
    />
  );
};

TableChart.defaultProps = defaultProps;

export default TableChart;
