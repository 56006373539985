import styled from 'styled-components';
import { Button } from 'shared/components';

export const Container = styled.div`
  padding: 0px 20px 20px;
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;
