import gql from 'graphql-tag';

export interface CodOutParcelBBLTransferValidationData {
  codOutParcelBBLTransferValidation: {
    responseCode: string;
    responseMesg: string;
    data: {
      _id: string;
      receiverName: string;
    };
  };
}

export interface CodOutParcelBBLTransferValidationVars {
  _id: string;
}

export default gql`
  mutation CodOutParcelBBLTransferValidation($_id: ID!) {
    codOutParcelBBLTransferValidation(_id: $_id) {
      responseCode
      responseMesg
      data {
        _id
        receiverName
      }
    }
  }
`;
