/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Row, Col, message, Skeleton } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import VENDOR_QUERY, { VendorData, VendorVars } from 'shared/graphql/query/vendor';
import { StyledPageHeader } from './Styles';
import { FormValues } from '../model/formValues.model';
import Forms from '../Forms';
import validateSchema from '../validateSchema';
import ModalConfirmUpdateVendor from './ModalConfirmUpdateVendor';
import ModalConfirmDeleteVendor from './ModalConfirmDeleteVendor';

type Params = {
  id: string;
};

type Vendor = {
  id: string;
  fullName: string;
  number: string;
};

const EditIndividual: React.FC = () => {
  const history = useHistory();
  const [vendor, setVendor] = useState<Vendor>({
    id: '',
    fullName: '',
    number: '',
  });
  const [isOpenModalConfirmUpdate, setOpenModalConfirmUpdate] = useState<boolean>(false);
  const [isOpenModalConfirmDelete, setOpenModalConfirmDelete] = useState<boolean>(false);
  const { id } = useParams<Params>();
  const { data: vendorData, loading, error } = useQuery<VendorData, VendorVars>(VENDOR_QUERY, {
    variables: {
      _id: id,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = <Skeleton />;

  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validateSchema),
    defaultValues: {
      id: '',
      fullName: '',
      systemRequest: '',
      taxId: '',
      address: '',
      country: 'ไทย',
      province: '',
      district: '',
      subDistrict: '',
      postCode: '',
      phone: '',
      staffPhone: '',
      email: [],
      docAddress: '',
      docTaxId: '',
      docProvince: '',
      docDistrict: '',
      docSubDistrict: '',
      docPostcode: '',
      bankName: '',
      bankBranch: '',
      accountName: '',
      accountNumber: '',
      chkSelectAddress: false,
    },
  });

  const { handleSubmit, reset, register } = methods;

  useEffect(() => {
    register('id');
  }, [register]);

  useEffect(() => {
    if (!loading && !error && vendorData?.vendor) {
      const {
        _id,
        number,
        full_name,
        system_request,
        tax_id,
        address,
        country,
        province,
        district,
        sub_district,
        postcode,
        phone,
        staff_phone,
        email,
        doc_address,
        doc_tax_id,
        doc_province,
        doc_district,
        doc_sub_district,
        doc_postcode,
        bank_account,
        checkbox_address_company,
      } = vendorData.vendor;

      setVendor({
        id: _id,
        fullName: full_name,
        number,
      });

      reset({
        id: _id,
        fullName: full_name,
        systemRequest: system_request || '',
        taxId: tax_id || '',
        address,
        country: country || '',
        province,
        district,
        subDistrict: sub_district,
        postCode: postcode,
        phone: phone[0],
        staffPhone: staff_phone || '',
        email: email.map((item) => ({ text: item })),
        docAddress: doc_address || '',
        docTaxId: doc_tax_id || '',
        docProvince: doc_province || '',
        docDistrict: doc_district || '',
        docSubDistrict: doc_sub_district || '',
        docPostcode: doc_postcode || '',
        bankName: bank_account[0].bank_name,
        bankBranch: bank_account[0].bank_branch,
        accountName: bank_account[0].account_name,
        accountNumber: bank_account[0].account_number,
        chkSelectAddress: !!checkbox_address_company,
      });
    }
  }, [error, loading, reset, vendorData?.vendor]);

  const submitUpdateVendor = handleSubmit(() => {
    setOpenModalConfirmUpdate(true);
  });

  if (vendorData?.vendor) {
    const { number } = vendorData.vendor;

    content = (
      <>
        <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3', marginBottom: 10 }}>
          <Col span={12}>
            <StyledPageHeader onBack={() => history.goBack()} title={number || 'ไม่มีหมายเลข'} />
          </Col>
          <Col span={12}>
            <Row justify="end">
              <Col>
                <Button onClick={() => setOpenModalConfirmDelete(true)} style={{ marginRight: 10 }}>
                  ลบบัญชีเจ้าหนี้
                </Button>
                <Button color="green" onClick={submitUpdateVendor}>
                  บันทึก
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <FormProvider {...methods}>
          <Forms />
          <ModalConfirmUpdateVendor
            visible={isOpenModalConfirmUpdate}
            onCancel={() => setOpenModalConfirmUpdate(false)}
          />
          <ModalConfirmDeleteVendor
            visible={isOpenModalConfirmDelete}
            onCancel={() => setOpenModalConfirmDelete(false)}
            vendor={vendor}
          />
        </FormProvider>
      </>
    );
  }

  return content;
};

export default EditIndividual;
