import gql from 'graphql-tag';
import { CodItemStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/codItemStatus.model';

export interface CodItemData {
  newCodItem: {
    _id: string;
    customer: {
      _id: string;
      full_name: string;
    };
    cod: {
      cod_in_number: string;
      vendor: {
        _id: string;
        full_name: string;
      };
    };
    total_cod: number;
    shipment_list: {
      _id: string;
      shipment_number: string;
      booking_number: string;
      cod_price: number;
    }[];
    status: CodItemStatus;
    cod_in_date: string;
    document: string[];
    remark: string;
  };
}

export interface CodItemVars {
  _id: string;
}

export default gql`
  query NewCodItem($_id: ID!) {
    newCodItem(_id: $_id) {
      _id
      customer {
        _id
        full_name
      }
      cod {
        cod_in_number
        vendor {
          _id
          full_name
        }
      }
      total_cod
      shipment_list {
        _id
        shipment_number
        booking_number
        cod_price
      }
      status
      cod_in_date
      document
      remark
    }
  }
`;
