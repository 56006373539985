//LIBRARY
import React, { useEffect, useState } from 'react';
// import { useQuery } from 'react-query';
// import { useQuery } from '@apollo/react-hooks';

//UTILS
import { THIS_MONTH, THIS_YEAR } from './utils/Dashboard.Constants';
import { Container } from '../Cod/Styles';

//FUNCTIONS
import { axiosExpress } from 'shared/config/axios';

//COMPONENTS
import DashboardRenderCalendar from './components/DashboardRenderCalendar';
import DashboardRenderModal from './components/DashboardRenderModal';

//QUERY
// import COD_DASHBOARD_CALENDAR from "shared/graphql/query/codDashboardCalenda";
import { TrackingCodByCashCalendarData } from 'shared/model/trackingCodByCashCalendar.model';
import { FunctionZeroPad } from './utils/Dashboard.Functions';

const Dashboard: React.FC = () => {
  const [month, setMonth] = useState<number>(THIS_MONTH - 1);
  const [year, setYear] = useState<number>(THIS_YEAR);
  const [open, setOpen] = useState<boolean>(false);
  const [selectDate, setSelectDate] = useState<string>('');
  const [data, setData] = useState<TrackingCodByCashCalendarData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const FunctionGetData = async () => {
    setLoading(true);
    try {
      const { data } = await axiosExpress.get(
        `/v1/dashboard/wait-pay-cod-calendar?month=${year}-${FunctionZeroPad(month + 1, 2)}`,
      );
      setData(data);
    } catch (error) {
      console.error('ERROR TO LOAD COD CALENDAR: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FunctionGetData();
  }, [month, year]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <DashboardRenderCalendar
        data={data}
        month={month}
        year={year}
        setMonth={setMonth}
        setYear={setYear}
        setSelectDate={setSelectDate}
        setOpen={setOpen}
      />
      {open && <DashboardRenderModal open={open} setOpen={setOpen} selectDate={selectDate} />}
    </Container>
  );
};

export default Dashboard;
