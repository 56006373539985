import React, { useState, ReactChild } from 'react';

export interface SelectedPaymentType {
  payments: {
    id: string;
    paymentNumber: string;
  }[];
  payeeId: string;
  payeeFullName: string;
  dueDate: string;
  paymentType: string;
  isOpenModal: boolean;
  isLoading: boolean;
}

export interface SelectedPaymentDetailType {
  paymentId: string;
  isOpenModal: boolean;
  paymentNumber: string;
  paymentStatus: number | null;
  employeeFullName: string;
  payeeFullName: string;
  grandTotalPrice: number;
  isLoading: boolean;
}

export interface CreatePaymentOrderType {
  isOpenModal: boolean;
  isLoading: boolean;
}

export interface CreatePaymentInsuranceType {
  isOpenModal: boolean;
}

interface PaymentOrderProps {
  children: ReactChild;
}

interface PaymentOrder {
  selectedPayments: [
    SelectedPaymentType,
    React.Dispatch<React.SetStateAction<SelectedPaymentType>>,
  ];
  selectedPaymentDetail: [
    SelectedPaymentDetailType,
    React.Dispatch<React.SetStateAction<SelectedPaymentDetailType>>,
  ];
  createPaymentOrder: [
    CreatePaymentOrderType,
    React.Dispatch<React.SetStateAction<CreatePaymentOrderType>>,
  ];
  createPaymentInsurance: [
    CreatePaymentInsuranceType,
    React.Dispatch<React.SetStateAction<CreatePaymentInsuranceType>>,
  ];
}

const PaymentOrderContext = React.createContext({} as PaymentOrder);

const PaymentOrderProvider: React.FC<PaymentOrderProps> = (props) => {
  const [selectedPayments, setSelectedPayments] = useState<SelectedPaymentType>({
    payments: [], // { id: '', paymentNumber: '' }
    payeeId: '',
    payeeFullName: '',
    dueDate: '',
    paymentType: '',
    isOpenModal: false,
    isLoading: true,
  });

  const [selectedPaymentDetail, setSelectedPaymentDetail] = useState<SelectedPaymentDetailType>({
    paymentId: '',
    isOpenModal: false,
    paymentNumber: '',
    paymentStatus: null,
    employeeFullName: '',
    payeeFullName: '',
    grandTotalPrice: 0,
    isLoading: true,
  });

  const [createPaymentOrder, setCreatePaymentOrder] = useState<CreatePaymentOrderType>({
    isOpenModal: false,
    isLoading: false,
  });

  const [createPaymentInsurance, setCreatePaymentInsurance] = useState<CreatePaymentInsuranceType>({
    isOpenModal: false,
  });

  const store: PaymentOrder = {
    selectedPayments: [selectedPayments, setSelectedPayments],
    selectedPaymentDetail: [selectedPaymentDetail, setSelectedPaymentDetail],
    createPaymentOrder: [createPaymentOrder, setCreatePaymentOrder],
    createPaymentInsurance: [createPaymentInsurance, setCreatePaymentInsurance],
  };

  const { children } = props;

  return <PaymentOrderContext.Provider value={store}>{children}</PaymentOrderContext.Provider>;
};

export { PaymentOrderContext, PaymentOrderProvider };
