/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext } from 'react';
import { Row, Col, message } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import baseUrl from 'shared/config/baseUrl';
import {
  InvoiceProvider,
  InvoiceContext,
  CreateInvoiceType,
  SelectedInvoicesType,
  CreateInvoiceFromExcelType,
} from 'views/AccountReceive/common/context/InvoiceContext';
import { InvoiceStatus } from 'views/AccountReceive/common/model/invoiceStatus.model';
import InvoiceTabs from './InvoiceTabs';
import { Container, StyledButton } from './Styles';
import InvoicesTable from './InvoicesTable';
import CostingsTable from './CostingsTable';
import ModalCreateInvoice from './ModalCreateInvoice';
import ModalCreateInvoiceFromExcel from './ModalCreateInvoiceFromExcel';

const InvoiceWrapper = () => {
  return (
    <InvoiceProvider>
      <Invoice />
    </InvoiceProvider>
  );
};

const Invoice = () => {
  const [tab, setTab] = useState<InvoiceStatus>(InvoiceStatus.DRAFT);
  const [tabName, setTabName] = useState<'costing' | 'invoice'>('invoice');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    setSelectedInvoices,
    setCreateInvoice,
    setCreateInvoiceFromExcel,
    selectedInvoices,
  } = useContext(InvoiceContext);

  const confirmCreatePdfZip = () => {
    const invoicesId =
      selectedInvoices.invoicesId.length === 1
        ? [selectedInvoices.invoicesId[0], selectedInvoices.invoicesId[0]]
        : selectedInvoices.invoicesId;
    const url = `${baseUrl}/GetPdfZip/?name=IV&_id=${invoicesId.join('&_id=')}`;
    window.open(url);
    message.success('ดาวน์โหลดสำเร็จแล้ว');
    setSelectedInvoices((prevState) => ({
      ...prevState,
      invoicesId: [],
    }));
  };

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={14}>
          <Row>
            <Col>
              <InvoiceTabs
                tab={tab}
                name={tabName}
                onChange={(value) => {
                  setSelectedInvoices(
                    (prevState): SelectedInvoicesType => ({
                      ...prevState,
                      invoicesId: [],
                      payerId: '',
                      dueDate: '',
                    }),
                  );

                  setTab(value);
                  setTabName('invoice');
                  setCurrentPage(1);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <Row justify="end">
            <Col>
              {tab === 1 && tabName === 'invoice' && (
                <StyledButton
                  onClick={() => {
                    setCreateInvoiceFromExcel(
                      (prevState): CreateInvoiceFromExcelType => ({
                        ...prevState,
                        isOpenModal: true,
                      }),
                    );
                  }}
                  color="blue--light"
                >
                  อัพโหลดเอกสาร
                </StyledButton>
              )}
              {tab === InvoiceStatus.DRAFT && (
                <StyledButton
                  onClick={() => {
                    setCreateInvoice(
                      (prevState): CreateInvoiceType => ({
                        ...prevState,
                        isOpenModal: true,
                      }),
                    );
                  }}
                  disabled
                  color="blue--light"
                >
                  สร้างใบแจ้งหนี้
                </StyledButton>
              )}
              {(tab === InvoiceStatus.INVOICE ||
                tab === InvoiceStatus.BILLING_NOTE ||
                tab === InvoiceStatus.CANCEL) && (
                <StyledButton
                  icon={<FileTextOutlined />}
                  disabled={selectedInvoices.invoicesId.length === 0}
                  onClick={() => {
                    confirmCreatePdfZip();
                  }}
                  color="green"
                >
                  ดาวน์โหลดเอกสาร
                </StyledButton>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      {tab === 1 && tabName === 'costing' ? (
        <CostingsTable
          tab={tab}
          pageSize={pageSize}
          onChangePageSize={(value) => setPageSize(value)}
          currentPage={currentPage}
          onChangeCurrentPage={(value) => setCurrentPage(value)}
        />
      ) : (
        <InvoicesTable
          tab={tab}
          pageSize={pageSize}
          onChangePageSize={(value) => setPageSize(value)}
          currentPage={currentPage}
          onChangeCurrentPage={(value) => setCurrentPage(value)}
        />
      )}

      <ModalCreateInvoice />
      <ModalCreateInvoiceFromExcel />
    </Container>
  );
};

export default InvoiceWrapper;
