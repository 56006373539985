/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button } from 'shared/components';
import { StyledModal } from './Styles';

type ModalConfirmDeleteItemProps = {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
};

const ModalConfirmDeleteItem: React.FC<ModalConfirmDeleteItemProps> = (props) => {
  const { onCancel, visible, onOk } = props;

  const submitDelete = () => {
    onOk();
    onCancel();
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ลบรายการบัญชี ใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<DeleteOutlined />}
          color="red--outline"
          onClick={submitDelete}
        >
          ตกลง
        </Button>,
        <Button key="cancelButton" icon={<CloseCircleOutlined />} onClick={onCancel}>
          ยกเลิก
        </Button>,
      ]}
    />
  );
};

export default ModalConfirmDeleteItem;
