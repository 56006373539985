/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';
import {
  CodInContext,
  SelectedCodsInType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/CodInContext';
import { CodInStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/model/codInStatus.model';
import { Container, StyledButton } from './Styles';
import CodInListTable from './CodInListTable';
import CodInListTabs from './CodInListTabs';
import ModalCreateCod from './ModalCreateCod';

const CodInList: React.FC = () => {
  const [tabCodIn, setTabCodIn] = useState<CodInStatus>(CodInStatus.PENDING);
  const [tabName, setTabName] = useState<'cod'>('cod');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { selectedCodsIn, setSelectedCodsIn } = useContext(CodInContext);

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={12}>
          <Row>
            <Col>
              <CodInListTabs
                tab={tabCodIn}
                name={tabName}
                onChange={(value) => {
                  setTabCodIn(value);
                  setTabName('cod');
                  setCurrentPage(1);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Col>
              <StyledButton
                onClick={() => {
                  setSelectedCodsIn(
                    (prevState): SelectedCodsInType => ({
                      ...prevState,
                      isOpenModal: true,
                    }),
                  );
                }}
                disabled={selectedCodsIn.codsInId.length === 0}
                color="blue--light"
              >
                สร้างการทำจ่าย
              </StyledButton>
            </Col>
          </Row>
        </Col>
      </Row>

      {tabName === 'cod' && (
        <CodInListTable
          status={tabCodIn}
          pageSize={pageSize}
          onChangePageSize={(value) => setPageSize(value)}
          currentPage={currentPage}
          onChangeCurrentPage={(value) => setCurrentPage(value)}
        />
      )}

      <ModalCreateCod />
    </Container>
  );
};

export default CodInList;
