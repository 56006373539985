/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useState, useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { Input, Col, Row, message, Divider, Popover } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { CodContext } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/context/CodContext';
import UPDATE_COD_PARCEL_MUTATION, {
  UpdateCodParcelData,
  UpdateCodParcelVars,
} from 'shared/graphql/mutation/updateCodParcel';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { formatDateTime } from 'shared/utils/dateTime';
import { StyledModal } from './Styles';
import CardIcon from '../CardIcon';

const { TextArea } = Input;

type ModalConfirmCheckedProps = {
  visible: boolean;
  onCancel: () => void;
  goBack: string;
};

const ModalConfirmChecked: React.FC<ModalConfirmCheckedProps> = (props) => {
  const { onCancel, visible, goBack } = props;
  const [remark, setRemark] = useState<string>('');
  const { selectedCodDetail } = useContext(CodContext);
  const { codId, modalConfirm } = selectedCodDetail;
  const { totalCod, vendorFullName, shipmentNumbers, codInDate } = modalConfirm;
  const history = useHistory();

  const closeModalHandler = () => {
    history.push(goBack);
  };

  const [updateCod, { loading }] = useMutation<UpdateCodParcelData, UpdateCodParcelVars>(
    UPDATE_COD_PARCEL_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.updateCodParcel._id) {
          message.success('ตรวจสอบแล้ว');
          onCancel();
          closeModalHandler();
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['CodParcel', 'CodParcelStatus', 'CodParcels', 'CodParcelOrderStatus'],
    },
  );

  const confirmCheck = () => {
    updateCod({
      variables: {
        _id: codId,
        cod_status: 3,
        cod_remark: remark,
      },
    });
  };

  const contentPopOver = (value: string[]) => {
    return (
      <>
        <Row>
          <Col>
            <Text>Shipment No: </Text>
          </Col>
        </Row>
        {value.map((item) => (
          <Row key={uuidv4()}>
            <Col>
              <Text strong>{item}</Text>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const popOver = (value: string[]) => {
    if (value.length === 1) {
      return <Text>{value[0]}</Text>;
    }
    if (value.length > 1) {
      return (
        <Popover placement="rightTop" content={contentPopOver(value)}>
          <div style={{ display: 'inline' }}>
            <Text>{value.length} รหัส</Text>
          </div>
        </Popover>
      );
    }
    return '-';
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ตรวจสอบรายการยอดCOD แล้วใช่หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmCheck()}
          loading={loading}
        >
          ใช่, ตรวจสอบแล้ว
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
      afterClose={() => setRemark('')}
    >
      <Row gutter={[32, 16]}>
        <Col
          span={12}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CardIcon
            title="จำนวนที่ 3PL ต้องโอน (บาท)"
            text={<Text customType="primary">{`${formatCurrency(totalCod)} บาท`}</Text>}
            icon={<DollarCircleOutlined />}
          />
        </Col>
      </Row>

      <Divider style={{ marginBottom: 10, marginTop: -5 }} />

      <Row>
        <Col span={12}>
          <Row>
            <Col span={8}>ชื่อ 3PL:</Col>
            <Col span={16}>
              <Text strong>{vendorFullName || '-'}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>Shipment No.:</Col>
            <Col span={16}>
              <Text strong>{popOver(shipmentNumbers)}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={8}>วันที่แจ้งโอน:</Col>
            <Col span={16}>
              <Text strong>{codInDate ? formatDateTime(codInDate) : '-'}</Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider style={{ marginBottom: 0, marginTop: 10 }} />

      <Row>
        <Col span={24}>
          หมายเหตุ: <Text customType="grey">(ถ้ามี)</Text>
        </Col>
        <Col span={24}>
          <TextArea
            value={remark}
            onChange={(event) => setRemark(event.target.value)}
            placeholder="ระบุ"
            rows={2}
          />
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmChecked;
