import { createGlobalStyle } from 'styled-components';
import { font, color, mixin } from 'shared/utils/styles';

export default createGlobalStyle`
  html, body, #root {
    height: 100%;
    min-height: 100%;
  }

  body {
    -webkit-tap-highlight-color: transparent;
    line-height: 1;
    ${font.size(20)}
    ${font.regular}
    color: ${color.black};
  }

  .ant-input {
    padding: 0px 10px;
    line-height: 1.5;
  }
  

  .ant-input-affix-wrapper {
    padding: 0px 10px;
    ${font.size(18)}
  }

  .ant-input-affix-wrapper > .ant-input-prefix {
    padding-top: 2px;
    margin-right: 10px;
  }

  .ant-input-prefix > .site-form-item-icon {
    color: ${mixin.rgba(color.black, 0.25)};
  }

  .ant-input-suffix {
    ${font.size(16)}
    padding-top: 3px;
  }

  .ant-input-number {
    line-height: 1.2;
  }

  .ant-picker {
    padding: 0px 10px;
  }

  .ant-picker-dropdown {
    line-height: 1;
  }

  .ant-picker-suffix, .ant-picker-clear {
    ${font.size(16)}
    vertical-align: -2px;
  }

  .ant-form-item-explain {
    ${font.size(18)}
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    ${font.size(14)}
  }

  .ant-btn {
    padding: 0px 15px;

    & .ant-btn-loading-icon .anticon {
      ${font.size(18)}
    }

    & > .anticon {
      ${font.size(14)}
    }

    &-green {
      background-color: ${color.backgroundSuccess};
      border-color: ${color.backgroundSuccess};
      color: ${color.white};

      &:active,
      &:focus,
      &:hover {
        background-color: ${mixin.rgba(color.backgroundSuccess, 0.8)};
        border-color: ${mixin.rgba(color.backgroundSuccess, 0.8)};
        color: ${color.white};
      }

      &--light {
        background-color: ${color.backgroundLightSuccess};
        border-color: ${color.backgroundLightSuccess};
        color: ${color.success};

        &:active,
        &:focus,
        &:hover {
          background-color: ${mixin.rgba(color.backgroundLightSuccess, 0.8)};
          border-color: ${mixin.rgba(color.backgroundLightSuccess, 0.8)};
          color: ${color.success};
        }
      }
    }

    &-red {
      background-color: ${color.backgroundDanger};
      border-color: ${color.backgroundDanger};
      color: ${color.white};

      &:active,
      &:focus,
      &:hover {
        background-color: ${mixin.rgba(color.backgroundDanger, 0.8)};
        border-color: ${mixin.rgba(color.backgroundDanger, 0.8)};
        color: ${color.white};
      }

      &--light {
        background-color: ${color.backgroundLightDanger};
        border-color: ${color.backgroundLightDanger};
        color: ${color.danger};

        &:active,
        &:focus,
        &:hover {
          background-color: ${mixin.rgba(color.backgroundLightDanger, 0.8)};
          border-color: ${mixin.rgba(color.backgroundLightDanger, 0.8)};
          color: ${color.danger};
        }
      }

      &--outline {
        color: ${color.danger};

        &:active,
        &:focus,
        &:hover {
          background-color: ${mixin.rgba(color.white, 0.8)};
          border-color: ${mixin.rgba(color.danger, 0.8)};
          color: ${color.danger};
        }
      }
    }

    &-blue {
      background-color: ${color.backgroundPrimary};
      border-color: ${color.backgroundPrimary};
      color: ${color.white};

      &:active,
      &:focus,
      &:hover {
        background-color: ${mixin.rgba(color.backgroundPrimary, 0.8)};
        border-color: ${mixin.rgba(color.backgroundPrimary, 0.8)};
        color: ${color.white};
      }

      &--light {
        background-color: ${color.backgroundLightPrimary};
        border-color: ${color.backgroundLightPrimary};
        color: ${color.primary};

        &:active,
        &:focus,
        &:hover {
          background-color: ${mixin.rgba(color.backgroundLightPrimary, 0.8)};
          border-color: ${mixin.rgba(color.backgroundLightPrimary, 0.8)};
          color: ${color.primary};
        }
      }

      &--outline {
        color: ${color.primary};

        &:active,
        &:focus,
        &:hover {
          background-color: ${mixin.rgba(color.white, 0.8)};
          border-color: ${mixin.rgba(color.primary, 0.8)};
          color: ${color.primary};
        }
      }
    }

    &-grey {
      &--text-only {
        background-color: transparent;
        border: none;
        color: ${mixin.rgba(color.black, 0.65)};

        &:active,
        &:focus,
        &:hover {
          opacity: 0.85;
          background-color: transparent;
          border: none;
          color: ${mixin.rgba(color.black, 0.65)};
        }
      }
    }
  }

  .ant-alert {
    ${font.size(18)}
    line-height: 1;
  }

  .ant-alert-icon {
    top: 10px;
    ${font.size(14)}
  }
  
  .ant-table {
    line-height: 1;
  }

  .ant-table thead > tr > th,
  .ant-table tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 6px;
  }

  .ant-table thead > tr > th {
    ${font.medium}
  }

  .ant-pagination {
    ${font.size(14)}
  }

  .ant-typography strong {
    ${font.medium}
  }

  .ant-typography-primary {
    color: ${color.primary}
  }

  .ant-typography-grey {
    color: #B3B3B3;
  }

  .ant-typography-success {
    color: ${color.success};
  }

  .badge-blue .ant-badge-count {
    background-color: ${color.primary};
    color: ${color.white};
    box-shadow: none;
  }

  .badge-blue--outline .ant-badge-count {
    background-color: ${color.backgroundLightPrimary};
    color: ${color.primary};
    box-shadow: none;
  }

  .badge-green .ant-badge-count {
    background-color: ${color.backgroundSuccess};
    color: ${color.backgroundLightSuccess};
    box-shadow: none;
  }

  .badge-green--outline .ant-badge-count {
    background-color: ${color.backgroundSuccess};
    color: ${color.white};
    box-shadow: none;
  }

  .badge-red .ant-badge-count {
    background-color: ${color.backgroundLightDanger};
    color: ${color.danger};
    box-shadow: none;
  }

  .badge-red--outline .ant-badge-count {
    background-color: ${color.danger};
    color: ${color.white};
    box-shadow: none;
  }

  .badge-grey .ant-badge-count {
    background-color: ${color.grey};
    color: ${color.black};
    box-shadow: none;
  }

  .ant-modal-close-x {
    font-size: 16px;
  }

  .ant-modal-title {
    ${font.medium}
    ${font.size(24)}
  }
  
  .ant-tabs-nav .ant-tabs-tab .anticon {
    ${font.size(18)}
    vertical-align: -3px;
  }

  .ant-btn-icon-only {
    padding: 0px;

    & > .anticon {
      vertical-align: -2px;
      font-size: 18px;
    }

    &.ant-btn-sm {
      width: 26px;
      height: 26px;
      line-height: 1.5;
      
      & > .anticon {
        vertical-align: -2px;
      }
    }
  }

  .ant-tooltip {
    line-height: 1;
  }

  .ant-picker-separator {
    font-size: 12px;
    height: 12px;
    vertical-align: baseline;
  }

  .ant-picker-range-separator {
    padding: 0px 2px 0px 0px;
  }
`;
