import gql from 'graphql-tag';

export interface Documents {
  _id: string;
  create_date: string;
  document_type: number;
  document_file: string;
  delete: boolean;
  list_ref: string[];
}

export interface InvoiceDocumentsData {
  invoiceDocuments: {
    documents: Documents[];
  };
}

export interface InvoiceDocumentsVars {
  invoice_id: string;
  document_type?: number;
}

export default gql`
  query InvoiceDocuments($invoice_id: ID!, $document_type: Int) {
    invoiceDocuments(params: { invoice_id: $invoice_id, document_type: $document_type }) {
      documents {
        _id
        create_date
        document_type
        document_file
        delete
        list_ref
      }
    }
  }
`;
