import gql from 'graphql-tag';
import { CostingStatus } from 'views/Costing/common/model/costingStatus.model';

type EnumLiteralsOf<T extends unknown> = T[keyof T];
export const PaidType = Object.freeze({
  CREDIT_CARD: 1 as const,
  CASH: 2 as const,
  INVOICE: 3 as const,
});

export type PaidType = EnumLiteralsOf<typeof PaidType>;

export interface CostingData {
  costing: {
    _id: string;
    costing_number: string;
    costing_status: CostingStatus;
    total_after_discount: {
      cost: number;
      sale: number;
    };
    profit: number;
    payer: {
      _id: string;
      full_name: string;
    };
    payee: {
      _id: string;
      full_name: string;
    };
    employee: {
      _id: string;
      full_name: string;
    };
    costing_remark: string;
    last_send: string;
    send_history: {
      timestamp: string;
    }[];
    reject_remark: string;
    costing_list: {
      service_category_name: string;
      list_name: string;
      qty: number;
      price: {
        cost: number;
        sale: number;
      };
      total_vat: {
        cost: number;
        sale: number;
      };
      total_wht: {
        cost: number;
        sale: number;
      };
      grand_total_price: {
        cost: number;
        sale: number;
      };
      discount: {
        cost: number;
        sale: number;
      };
      total_before_discount: {
        cost: number;
        sale: number;
      };
      total_price: {
        cost: number;
        sale: number;
      };
    }[];
    total_before_discount: {
      cost: number;
      sale: number;
    };
    total_discount: {
      cost: number;
      sale: number;
    };
    total_vat: {
      cost: number;
      sale: number;
    };
    all_wht: {
      wht: number;
      price: {
        cost: number;
        sale: number;
      };
      total_wht: {
        cost: number;
        sale: number;
      };
    }[];
    grand_total_price: {
      cost: number;
      sale: number;
    };
    number_ref: string;
    edit_request: boolean;
    paid_type: PaidType;
  };
}

export interface CostingVars {
  _id: string;
}

export default gql`
  query Costing($_id: ID!) {
    costing(_id: $_id) {
      _id
      costing_number
      costing_status
      total_after_discount {
        cost
        sale
      }
      profit
      payer {
        _id
        full_name
      }
      payee {
        _id
        full_name
      }
      employee {
        _id
        full_name
      }
      costing_remark
      last_send
      send_history {
        timestamp
      }
      reject_remark
      costing_list {
        service_category_name
        list_name
        qty
        price {
          cost
          sale
        }
        total_vat {
          cost
          sale
        }
        total_wht {
          cost
          sale
        }
        grand_total_price {
          cost
          sale
        }
        discount {
          cost
          sale
        }
        total_before_discount {
          cost
          sale
        }
        total_price {
          cost
          sale
        }
      }
      total_before_discount {
        cost
        sale
      }
      total_discount {
        cost
        sale
      }
      total_vat {
        cost
        sale
      }
      all_wht {
        wht
        price {
          cost
          sale
        }
        total_wht {
          cost
          sale
        }
      }
      grand_total_price {
        cost
        sale
      }
      number_ref
      edit_request
      paid_type
    }
  }
`;
