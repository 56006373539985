import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import {
  ReceiptContext,
  SelectedBillingNoteDetailType,
} from 'views/AccountReceive/common/context/ReceiptContext';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountReceive/Routes/Receipt/BillingNotesTable/ModalCreateReceipt/model/formValues.model';
import useSumTotalPrice from 'views/AccountReceive/Routes/Receipt/BillingNotesTable/ModalCreateReceipt/hooks/useSumTotalPrice';
import { StyledButton, TextHeader, Container } from './Styles';
import ModalConfirmCreateReceipt from './ModalConfirmCreateReceipt';

type Step = 0 | 1 | 2;

type HeaderProps = {
  activeStep: Step;
  onChangeStep: (step: Step) => void;
  onReset: () => void;
};

const Header: React.FC<HeaderProps> = (props) => {
  const { activeStep, onChangeStep, onReset } = props;
  const { getValues, trigger } = useFormContext<FormValues>();
  const selectedBillInvoices = getValues('billInvoices') ? getValues('billInvoices') : [];
  const { totalPriceLeft, totalPriceRight } = useSumTotalPrice();
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const { selectedBillingNoteDetail, setSelectedBillingNoteDetail } = useContext(ReceiptContext);
  const { billNumber } = selectedBillingNoteDetail;

  const closeModalHandler = () => {
    setSelectedBillingNoteDetail(
      (prevState): SelectedBillingNoteDetailType => ({
        ...prevState,
        isOpenModal: false,
      }),
    );
  };

  const nextStep = (step: Step): Step => {
    switch (step) {
      case 0:
        return 1;
      case 1:
        return 2;
      default:
        return step;
    }
  };

  const prevStep = (step: Step): Step => {
    switch (step) {
      case 1:
        return 0;
      case 2:
        return 1;
      default:
        return step;
    }
  };

  const checkDisabledNextButton = () => {
    if (activeStep === 0) {
      return selectedBillInvoices.length === 0;
    }

    if (activeStep === 1) {
      return totalPriceLeft !== totalPriceRight;
    }

    return false;
  };

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>{`รหัสใบวางบิล${billNumber ? `: ${billNumber}` : ''}`}</TextHeader>
        </Col>
        <Col>
          {activeStep > 0 && (
            <StyledButton
              onClick={() => {
                if (activeStep === 1) {
                  onReset();
                }
                onChangeStep(prevStep(activeStep));
              }}
            >
              <ArrowLeftOutlined /> ย้อนกลับ
            </StyledButton>
          )}

          {(activeStep === 0 || activeStep === 1) && (
            <StyledButton
              color="green"
              disabled={checkDisabledNextButton()}
              onClick={() => {
                const fieldStep1 = ['billInvoices'];
                trigger(activeStep === 0 ? fieldStep1 : undefined).then((valid) => {
                  if (valid) {
                    onChangeStep(nextStep(activeStep));
                  }
                });
              }}
            >
              ต่อไป <ArrowRightOutlined />
            </StyledButton>
          )}

          {activeStep === 2 && (
            <StyledButton color="green" onClick={() => setIsOpenModalConfirm(true)}>
              บันทึกการรับชำระ
            </StyledButton>
          )}

          <StyledButton onClick={closeModalHandler}>ปิด</StyledButton>
        </Col>
      </Row>

      <ModalConfirmCreateReceipt
        visible={isOpenModalConfirm}
        onCancel={() => setIsOpenModalConfirm(false)}
      />
    </Container>
  );
};

export default Header;
