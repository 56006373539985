import gql from 'graphql-tag';

export interface BuildCodOutParcelData {
  buildCodOutParcel: {
    cod_out_number: string;
    total_cod: number;
    parcel_orders: {
      _id: string;
      cod_in_number: string;
      tracking_number: string;
      cod_price: number;
      cod_in_date: string;
      updatedAt: string;
    }[];
  };
}

export interface BuildCodOutParcelVars {
  parcel_orders: string[];
}

export default gql`
  query BuildCodOutParcel($parcel_orders: [ID]) {
    buildCodOutParcel(parcel_orders: $parcel_orders) {
      cod_out_number
      total_cod
      parcel_orders {
        _id
        cod_in_number
        tracking_number
        cod_price
        cod_in_date
        updatedAt
      }
    }
  }
`;
