interface listDataProp {
  date: string;
  day: string;
  month: string;
  total_price: number;
  total_tracking: number;
  total_tracking_wait_tranfer: number;
  wait_tranfer_price: number;
}

const initData = {
  date: '',
  day: '',
  month: '',
  total_price: 0,
  total_tracking: 0,
  total_tracking_wait_tranfer: 0,
  wait_tranfer_price: 0,
};

export const FunctionGetListData = (data = [initData], date = 0, month = 0) => {
  let listData = {} as listDataProp;

  if (data[date - 1]) {
    const thisMonthThatSelected = new Date(data[date - 1].month).getMonth();
    if (thisMonthThatSelected === month) {
      listData = {
        date: data[date - 1].date,
        day: data[date - 1].day,
        month: data[date - 1].month,
        total_price: data[date - 1].total_price,
        total_tracking: data[date - 1].total_tracking,
        total_tracking_wait_tranfer: data[date - 1].total_tracking_wait_tranfer,
        wait_tranfer_price: data[date - 1].wait_tranfer_price,
      };
    }
  }

  return listData || initData;
};

export const FunctionCreateRangeYearToToDay = (start = 2021) => {
  const nextYear = new Date().getFullYear() + 1;
  const size = nextYear - start;
  const startAt = nextYear - size;
  let countYear = new Array(size).fill(0).map((_, i) => startAt + i);
  return countYear.reverse();
};

export const FunctionZeroPad = (num = 1, numZeros = 2) => {
  var n = Math.abs(num);
  var zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
  var zeroString = Math.pow(10, zeros).toString().substr(1);
  if (num < 0) {
    zeroString = '-' + zeroString;
  }

  return zeroString + n;
};
