import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Button } from 'shared/components';
import { Route, useRouteMatch } from 'react-router-dom';
import ListParcelTab from './components/ListParcelTab';
import { Container } from './Styles';
import ModalParcelCreate from './components/ModalParcelCreate';
import ListParcelTable from './components/ListParcelTable';
import { ListParcelStatus } from './components/ListParcelStatus';
import ModalParcelDetail from './components/ModalParcelDetail';

const ListParcel: React.FC = () => {
  const [tab, setTab] = React.useState<ListParcelStatus>(ListParcelStatus.WAIT_PARCEL_DETAIL);
  const { path } = useRouteMatch();
  const [tabName, setTabName] = React.useState<'listParcel'>('listParcel');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isVisibleModalParcelCreate, setIsVisibleModalParcelCreate] = useState<boolean>(false);

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={18}>
          <ListParcelTab
            tab={tab}
            name={tabName}
            onChange={(value) => {
              setTab(value);
              setTabName('listParcel');
              setCurrentPage(1);
            }}
          />
        </Col>
        <Col span={6}>
          <Row justify="end">
            <Col>
              <Button onClick={() => setIsVisibleModalParcelCreate(true)} color="blue--light">
                สร้าง Parcel detail
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {tabName === 'listParcel' && (
        <Row>
          <Col span={24}>
            <ListParcelTable
              tab={tab}
              pageSize={pageSize}
              onChangePageSize={(value) => setPageSize(value)}
              currentPage={currentPage}
              onChangeCurrentPage={(value) => setCurrentPage(value)}
            />
          </Col>
        </Row>
      )}

      <ModalParcelCreate
        isVisible={isVisibleModalParcelCreate}
        onCancel={() => {
          setIsVisibleModalParcelCreate(false);
        }}
      />

      <Route
        exact
        path={`${path}/detail/:parcelId`}
        render={(routeProps) => (
          <ModalParcelDetail parcelId={routeProps.match.params.parcelId} goBack={path} />
        )}
      />
    </Container>
  );
};

export default ListParcel;
