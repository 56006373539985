import gql from 'graphql-tag';

export interface CustomerStatusData {
  customerStatus: {
    active: number;
    inactive: number;
  };
}

export default gql`
  query CustomerStatus {
    customerStatus {
      active
      inactive
    }
  }
`;
