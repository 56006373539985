/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';
import {
  Divider,
  Row,
  Col,
  Tabs,
  Skeleton,
  Select,
  DatePicker,
  Input,
  Checkbox,
  message,
} from 'antd';
import moment from 'moment';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { color } from 'shared/utils/styles';
import { Text } from 'shared/components';
import { useFormContext, Controller } from 'react-hook-form';
import { ReceiptContext } from 'views/AccountReceive/common/context/ReceiptContext';
import {
  SelectFieldSearchCustomers,
  SelectBankAccountsField,
} from 'views/AccountReceive/common/components';
import { FormValues } from 'views/AccountReceive/Routes/Receipt/BillingNotesTable/ModalCreateReceipt/model/formValues.model';
import BUILD_CONFIRM_RECEIPT_QUERY, {
  BuildConfirmReceiptData,
  BuildConfirmReceiptVars,
} from 'shared/graphql/query/buildConfirmReceipt';
import { StyledFormItem } from './Styles';
import UploadDocument from './UploadDocument';
import FormPrice from './FormPrice';

const { TabPane } = Tabs;
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';

const PaymentStep: React.FC = () => {
  const { selectedBillingNoteDetail } = useContext(ReceiptContext);
  const {
    payerId,
    payerFullName,
    serviceDate,
    grandTotalPrice,
    billNumber,
  } = selectedBillingNoteDetail;

  const { getValues, setValue, watch, control, errors, trigger, clearErrors } = useFormContext<
    FormValues
  >();

  const watchWrongBankField = watch('wrongBank');
  const watchReceiptPriceField = watch('receiptPrice');
  const selectedInvoices = getValues('billInvoices') ? getValues('billInvoices') : [];
  const watchAllFields = watch([
    'channel',
    'receiptDate',
    'wrongBank',
    'accountNumber',
    'bankName',
  ]);

  const { data: buildConfirmReceiptData } = useQuery<
    BuildConfirmReceiptData,
    BuildConfirmReceiptVars
  >(BUILD_CONFIRM_RECEIPT_QUERY, {
    variables: {
      invoice: selectedInvoices.map((item) => item.invoice._id),
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (buildConfirmReceiptData?.buildConfirmReceipt) {
      const {
        bank_name,
        account_number,
        total_paid,
        unpaid,
        receipt_value,
        all_wht,
      } = buildConfirmReceiptData.buildConfirmReceipt;

      if (all_wht && all_wht.length > 0 && !watchReceiptPriceField) {
        const allWht = all_wht.map((item) => ({
          wht: item.wht,
          totalWht: item.total_wht,
        }));

        setValue('allWht', [...allWht]);
      }

      setValue('totalPaid', total_paid);
      setValue('unPaid', unpaid);
      setValue('receiptValue', receipt_value);

      if (!watchWrongBankField) {
        setValue('bankName', bank_name);
        setValue('accountNumber', account_number);
      }
    }
  }, [
    buildConfirmReceiptData?.buildConfirmReceipt,
    setValue,
    watchReceiptPriceField,
    watchWrongBankField,
  ]);

  let content = <Skeleton />;
  if (buildConfirmReceiptData?.buildConfirmReceipt) {
    const { total_paid, bank_name, account_number } = buildConfirmReceiptData.buildConfirmReceipt;

    content = (
      <>
        <Row gutter={[32, 16]}>
          <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>:
                  </Col>
                  <Col span={24}>
                    <SelectFieldSearchCustomers
                      value={{
                        key: payerId,
                        label: payerFullName,
                      }}
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          {watchAllFields && (
            <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Text>ช่องทางการรับ</Text>
                  <Text customType="danger">*</Text>
                  <StyledFormItem validateStatus={errors.channel && 'error'}>
                    <Controller
                      control={control}
                      name="channel"
                      render={({ onChange, onBlur, value }) => (
                        <Select
                          value={value}
                          onChange={(valueSelect) => onChange(valueSelect)}
                          style={{ width: '100%' }}
                          onBlur={() => onBlur()}
                        >
                          <Option value="">เลือกช่องทางการรับ</Option>
                          <Option value="โอนเงิน">โอนเงิน</Option>
                        </Select>
                      )}
                    />
                  </StyledFormItem>
                </Col>
                <Col span={12}>
                  <Text>วันที่รับเงิน</Text>
                  <Text customType="danger">*</Text>
                  <StyledFormItem validateStatus={errors.receiptDate && 'error'}>
                    <Controller
                      control={control}
                      name="receiptDate"
                      render={({ onChange, value }) => (
                        <DatePicker
                          allowClear={false}
                          value={value ? moment(value, dateFormat) : null}
                          style={{ width: '100%' }}
                          placeholder="ระบุ"
                          onChange={(valueDate) => {
                            if (!valueDate) {
                              onChange('');
                            } else {
                              onChange(valueDate.format(dateFormat));
                            }
                          }}
                        />
                      )}
                    />
                  </StyledFormItem>
                </Col>
                <Col span={24}>
                  <Controller
                    control={control}
                    name="wrongBank"
                    render={({ onChange, value }) => (
                      <Checkbox
                        value={value}
                        checked={value}
                        onChange={(event) => {
                          if (!event.target.checked) {
                            setValue('bankName', bank_name);
                            setValue('accountNumber', account_number);
                            trigger(['bankName', 'accountNumber']);
                          } else {
                            setValue('bankName', '');
                            setValue('accountNumber', '');
                          }

                          onChange(event.target.checked);
                        }}
                      >
                        กรณีชำระผิดธนาคาร / เลขบัญชี
                      </Checkbox>
                    )}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Text>ธนาคารที่รับชำระ</Text>
                  <Text customType="danger">*</Text>
                  <StyledFormItem validateStatus={errors.bankName && 'error'}>
                    {!watchWrongBankField && (
                      <Controller
                        as={Input}
                        control={control}
                        name="bankName"
                        placeholder="ระบุ"
                        disabled
                      />
                    )}

                    {watchWrongBankField && (
                      <Controller
                        control={control}
                        name="bankName"
                        render={({ onChange, value }) => (
                          <SelectBankAccountsField
                            value={value || undefined}
                            onChange={(valueSelect) => {
                              onChange(valueSelect.bankName);
                              setValue('accountNumber', valueSelect.accountNumber);
                              clearErrors('accountNumber');
                            }}
                          />
                        )}
                      />
                    )}
                  </StyledFormItem>
                </Col>
                <Col span={12}>
                  <Text>เลขบัญชีที่รับชำระ</Text>
                  <Text customType="danger">*</Text>
                  <StyledFormItem validateStatus={errors.accountNumber && 'error'}>
                    <Controller
                      as={Input}
                      name="accountNumber"
                      placeholder="ระบุ"
                      control={control}
                      disabled
                    />
                  </StyledFormItem>
                </Col>
              </Row>
            </Col>
          )}

          <Col span={8}>
            <Row>
              <Col span={8}>วันที่เอกสาร:</Col>
              <Col span={16}>
                <Text strong>{formatDate(serviceDate)}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รหัสใบวางบิล:</Col>
              <Col span={16}>
                <Text strong>{billNumber}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รวมมูลค่าใบวางบิล:</Col>
              <Col span={16}>
                <Text strong customType="primary">
                  {formatCurrency(grandTotalPrice)}
                </Text>
              </Col>
            </Row>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Row>
              <Col span={24}>
                มูลค่าใบแจ้งหนี้ที่รับชำระ: <Text strong>{formatCurrency(total_paid)}</Text>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider style={{ margin: 0, marginTop: -5 }} />

        <Tabs defaultActiveKey="1" animated={false}>
          <TabPane
            tab={
              <span>
                รายละเอียดงาน{' '}
                <CheckCircleOutlined
                  style={{
                    color: color.success,
                    marginLeft: 5,
                  }}
                />
              </span>
            }
            key="1"
          >
            <FormPrice />
            <Divider />
            <Controller
              control={control}
              name="proofOfPayment"
              render={({ onChange, value }) => <UploadDocument onChange={onChange} value={value} />}
            />
          </TabPane>
        </Tabs>
      </>
    );
  }

  return content;
};

export default PaymentStep;
