import styled from 'styled-components';
import { Typography } from 'antd';

type TextCustomProps = {
  customType?: 'primary' | 'grey' | 'success' | 'danger' | 'normal';
};

const { Text } = Typography;
export const StyledText = styled(Text)<TextCustomProps>``;

export default StyledText;
