import React, { useRef } from 'react';
import { Row, Col, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import SINGLE_UPLOAD_MUTATION, {
  SingleUploadData,
  SingleUploadVars,
} from 'shared/graphql/mutation/singleUpload';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { Text } from 'shared/components';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'views/AccountPayment/Routes/PaymentOrder/ModalCreatePaymentOrder/model/formValues.model';
import DocumentList from './DocumentList';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

type UploadDocumentProps = {
  value: string[];
  onChange: (filesPath: string[]) => void;
};

const UploadDocument: React.FC<UploadDocumentProps> = (props) => {
  const { onChange, value } = props;
  const { errors } = useFormContext<FormValues>();
  const filePondRef = useRef<any>();
  const [singleUpload] = useMutation<SingleUploadData, SingleUploadVars>(SINGLE_UPLOAD_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.singleUpload.path) {
        onChange([...value, data?.singleUpload.path]);
        message.success('อัพโหลดเอกสารแล้ว');
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
  });

  const deletedFileUpload = (path: string) => {
    const filterFiles = value.filter((filePath) => filePath !== path);
    onChange([...filterFiles]);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          อัพโหลดเอกสาร
          {errors.otherDocument && <Text customType="danger">(กรุณาอัพโหลดเอกสาร)</Text>}
          <div>
            <Text customType="grey">
              (รูปแบบไฟล์ที่รองรับ .jpg , .jpeg , .png , .pdf , และขนาดไฟล์ไม่เกิน 2Mb.)
            </Text>
          </div>
        </Col>
        <Col span={24}>
          <FilePond
            ref={filePondRef}
            server={{
              process: (_fieldName, file, _metadata, _load, error) => {
                singleUpload({
                  variables: {
                    file: file as File,
                  },
                })
                  .then(() => {
                    filePondRef.current.removeFile();
                  })
                  .catch((err) => {
                    message.error(err.message);
                    error(err);
                  });
              },
            }}
            acceptedFileTypes={['image/jpeg', 'image/png', 'application/pdf']}
            maxFileSize="2MB"
          />
        </Col>
      </Row>
      <DocumentList filesPath={value} onDelete={(path) => deletedFileUpload(path)} />
    </>
  );
};

export default UploadDocument;
