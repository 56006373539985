import gql from 'graphql-tag';
import { CodParcelOrderStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByParcel/common/model/codParcelOrderStatus.model';

export interface ParcelOrders {
  _id: string;
  cod: string;
  cod_status: CodParcelOrderStatus;
  customer: {
    _id: string;
    full_name: string;
    address: string;
    tax_id: string;
    phone: string[];
    email: string[];
  };
  cod_in_number: string;
  cod_price: number;
  tracking_number: string;
  due_date: string;
  cod_in_date: string;
  checked_by: {
    full_name: string;
  };
  updatedAt: string;
}

export interface ParcelOrderAllData {
  parcelOrderAll: {
    parcelOrders: ParcelOrders[];
  };
}

export interface ParcelOrderAllVars {
  _id?: string;
  customer?: string;
  cod_in_number?: string;
  shipment_number?: string;
  tracking_number?: string;
  due_date?: string;
  cod_status?: CodParcelOrderStatus;
  cod_in_date?: string;
}

export default gql`
  query ParcelOrderAll(
    $_id: ID
    $customer: ID
    $cod_in_number: String
    $shipment_number: String
    $tracking_number: String
    $due_date: String
    $cod_status: Int
    $cod_in_date: String
  ) {
    parcelOrderAll(
      params: {
        _id: $_id
        customer: $customer
        cod_in_number: $cod_in_number
        shipment_number: $shipment_number
        tracking_number: $tracking_number
        due_date: $due_date
        cod_status: $cod_status
        cod_in_date: $cod_in_date
      }
    ) {
      parcelOrders {
        _id
        cod
        cod_status
        customer {
          _id
          full_name
          address
          tax_id
          phone
          email
        }
        cod_in_number
        cod_price
        tracking_number
        due_date
        cod_in_date
        checked_by {
          full_name
        }
        updatedAt
      }
    }
  }
`;
