import React from 'react';
import formatCurrency from 'shared/utils/formatCurrency';
import { ShipmentList } from 'shared/graphql/query/paymentNewCod';
import { Text } from 'shared/components';
import { StyledTable } from './Styles';

type ExpandedRowProps = {
  data: ShipmentList[];
};

const columns = [
  {
    title: 'Shipment No.',
    dataIndex: 'column1',
    width: 150,
  },
  {
    title: 'Booking Number',
    dataIndex: 'column2',
    width: 180,
  },
  {
    title: 'จำนวนที่ 3PL ต้องโอน (บาท)',
    dataIndex: 'column3',
    width: 180,
    align: 'right',
  },
  {
    title: '',
  },
];

const ExpandedRow: React.FC<ExpandedRowProps> = (props) => {
  const { data } = props;
  const shipmentList = data?.map((item, index) => {
    return {
      key: index,
      column1: item.shipment_number || '-',
      column2: item.booking_number || '-',
      column3: (
        <Text customType="primary" strong>
          {item.cod_price ? formatCurrency(item.cod_price) : '-'}
        </Text>
      ),
    };
  });

  return (
    <div style={{ marginTop: 10 }}>
      <StyledTable
        scroll={{ x: 'max-content' }}
        pagination={false}
        columns={columns}
        dataSource={shipmentList}
        size="small"
      />
    </div>
  );
};

export default ExpandedRow;
