/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Select, Empty, message } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { useFormikContext } from 'formik';
import VENDORS_QUERY from 'shared/graphql/query/vendors';

const { Option } = Select;
const SelectFieldSearchVendor = () => {
  const [fullName, setFullName] = useState('');
  const { setFieldValue, values, setFieldTouched } = useFormikContext();
  const { loading, error, data } = useQuery(VENDORS_QUERY, {
    variables: {
      full_name: fullName,
      showData: 10,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Select
      style={{
        width: '100%',
      }}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      showSearch
      placeholder="ค้นหาผู้รับเงิน"
      onSearch={(value) => setFullName(value)}
      onChange={(value) => {
        setFullName('');
        setFieldValue('payeeId', value.value);
        setFieldValue('payeeFullName', value.label);
        setFieldValue('bankName', '');
        setFieldValue('bankBranch', '');
        setFieldValue('accountName', '');
        setFieldValue('accountNumber', '');
      }}
      onBlur={() => setFieldTouched('payeeId', true)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      labelInValue
      value={{
        key: values.payeeId,
        label: values.payeeFullName,
      }}
      disabled
    >
      {loading || error
        ? []
        : data.vendors.vendors.map((item) => (
            <Option key={item._id} value={item._id}>
              {item.full_name}
            </Option>
          ))}
    </Select>
  );
};

export default SelectFieldSearchVendor;
