/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, ReactNode } from 'react';
import { Input, message, DatePicker } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import INVOICES_FOR_LOG_AR_QUERY, {
  InvoicesForLogARData,
  InvoicesForLogARVars,
} from 'shared/graphql/query/invoicesForLogAR';
import { Button, TableSearch, Text } from 'shared/components';
import formatCurrency from 'shared/utils/formatCurrency';

import { SearchOutlined, FileTextOutlined } from '@ant-design/icons';
import { ToolsContainer } from './Styles';

const { RangePicker } = DatePicker;

type DueDateTableProps = {
  customerId: string;
};

type DueDateDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: ReactNode;
  column4: ReactNode;
  column5: ReactNode;
  column6: ReactNode;
  column7: ReactNode;
  column8: string;
  column9: string;
};

const DueDateTable: React.FC<DueDateTableProps> = (props) => {
  const location = useLocation();
  const { customerId } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [serviceDate, setServiceDate] = useState<string>('');
  const [dueDate, setDueDate] = useState<string>('');
  const [followDate, setFollowDate] = useState<string>('');
  const [nextFollowDate, setNextFollowDate] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('');

  const { loading, error, data } = useQuery<InvoicesForLogARData, InvoicesForLogARVars>(
    INVOICES_FOR_LOG_AR_QUERY,
    {
      variables: {
        _id: '',
        payer: customerId,
        overDueDays: '<=0',
        service_date: serviceDate,
        invoice_number: invoiceNumber,
        due_date: dueDate,
        followDate,
        nextFollowDate,
        showData: pageSize,
        page: currentPage,
        order_by: orderBy,
        order_type: orderType,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'วันที่เอกสาร',
      dataIndex: 'column1',
      width: 210,
      search: (
        <RangePicker
          onChange={(values) => {
            if (values) {
              const formatRangeDate = `${values[0]?.format('YYYY-MM-DD')}:${values[1]?.format(
                'YYYY-MM-DD',
              )}`;
              return setServiceDate(formatRangeDate);
            }
            setServiceDate('');
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'รหัสใบแจ้งหนี้',
      dataIndex: 'column2',
      width: 150,
      search: (
        <Input
          allowClear
          value={invoiceNumber}
          placeholder="ค้นหา"
          onChange={(event) => setInvoiceNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'มูลค่าใบแจ้งหนี้',
      dataIndex: 'column3',
      width: 150,
      align: 'right',
    },
    {
      title: 'วันครบกำหนดชำระ',
      dataIndex: 'column4',
      width: 210,
      search: (
        <RangePicker
          onChange={(values) => {
            if (values) {
              const formatRangeDate = `${values[0]?.format('YYYY-MM-DD')}:${values[1]?.format(
                'YYYY-MM-DD',
              )}`;
              return setDueDate(formatRangeDate);
            }
            setDueDate('');
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'เกินกำหนด',
      dataIndex: 'column5',
      width: 150,
    },
    {
      title: 'อ้างอิงใบวางบิล',
      dataIndex: 'column6',
      width: 130,
    },
    {
      title: 'บันทึกการติดตาม',
      dataIndex: 'column7',
      width: 210,
      search: (
        <RangePicker
          onChange={(values) => {
            if (values) {
              const formatRangeDate = `${values[0]?.format('YYYY-MM-DD')}:${values[1]?.format(
                'YYYY-MM-DD',
              )}`;
              return setFollowDate(formatRangeDate);
            }
            setFollowDate('');
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'สถานะการติดต่อ',
      dataIndex: 'column8',
      width: 150,
    },
    {
      title: 'นัดหมายครั้งต่อไป',
      dataIndex: 'column9',
      width: 210,
      search: (
        <RangePicker
          onChange={(values) => {
            if (values) {
              const formatRangeDate = `${values[0]?.format('YYYY-MM-DD')}:${values[1]?.format(
                'YYYY-MM-DD',
              )}`;
              return setNextFollowDate(formatRangeDate);
            }
            setNextFollowDate('');
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
  ];

  let dueDateDataTable: DueDateDataTable[] = [];
  let totalDocument = 0;

  if (data?.invoicesForLogAR) {
    const { invoices } = data.invoicesForLogAR;
    dueDateDataTable = invoices.map(
      (item, index): DueDateDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <Link to={`${location.pathname}/invoice/${item._id}`}>
              <Button color="grey--text-only" icon={<SearchOutlined />} size="small" />
            </Link>
            <Button
              color="grey--text-only"
              icon={<FileTextOutlined />}
              size="small"
              target="_blank"
              disabled={!item.document}
              href={item.document}
            />
          </ToolsContainer>
        ),
        column1: item?.service_date || '-',
        column2: item.invoice_number || '-',
        column3: (
          <Text strong customType="primary">
            {formatCurrency(+item.grand_total_price, '0')}
          </Text>
        ),
        column4: item.due_date || '-',
        column5: item.overDueDays ? `${item.overDueDays} วัน` : '-',
        column6: item.billing_note?.bill_number || '-',
        column7: item.followDate || '-',
        column8: item.followStatus || '-',
        column9: item.nextFollowDate || '-',
      }),
    );

    totalDocument = data.invoicesForLogAR.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <TableSearch
        columns={columns}
        dataSource={dueDateDataTable}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value) => setCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />
    </>
  );
};

export default DueDateTable;
