/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext, ReactNode } from 'react';
import moment from 'moment';
import { Input, DatePicker, message, Row, Col, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { formatDate, formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import INVOICES_QUERY, {
  InvoicesData,
  InvoicesVars,
  InvoicesList,
} from 'shared/graphql/query/invoices';
import {
  BillingNoteContext,
  SelectedInvoicesType,
  SelectedInvoiceDetailType,
} from 'views/AccountReceive/common/context/BillingNoteContext';
import { Text, Button, TableSearch, SelectSystemRequestField } from 'shared/components';
import SelectCustomersField from 'views/AccountReceive/Routes/BillingNote/SelectCustomersField';
import { InvoiceStatus } from 'views/AccountReceive/common/model/invoiceStatus.model';
import SelectEmployeeField from 'views/AccountReceive/Routes/BillingNote/SelectEmployeeField';
import { BusinessInvoiceType } from 'views/AccountReceive/common/model/businessInvoiceType.model';
import { SelectBusinessTypeField } from 'views/AccountReceive/common/components';
import ModalInvoiceDetail from './ModalInvoiceDetail';
import { ToolsContainer, StyledCheckbox } from './Styles';

const { Option } = Select;
const { RangePicker } = DatePicker;

type InvoicesTableProps = {
  status: InvoiceStatus;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
};

type InvoicesDataTable = {
  key: number;
  tools: ReactNode;
  serviceDateColumn: string;
  dueDateColumn: string;
  receiptDateColumn: string;
  invoiceNumberColumn: string;
  grandTotalPriceColumn: ReactNode;
  payerFullNameColumn: string;
  businessInvoiceTypeColumn: string;
  systemRequestColumn: string;
  employeeFullNameColumn: string;
  createDateColumn: string;
  lastUpdateDateColumn: string;
};

const InvoicesTable: React.FC<InvoicesTableProps> = (props) => {
  const { status, pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [dueDate, setDueDate] = useState<string>('');
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [systemRequest, setSystemRequest] = useState<string>('');
  const [serviceDate, setServiceDate] = useState<string>('');
  const [payerId, setPayerId] = useState<string>('');
  const [employeeId, setEmployeeId] = useState<string>('');
  const [businessInvoiceType, setBusinessInvoiceType] = useState<BusinessInvoiceType>();
  const [businessInvoiceDateRange, setBusinessInvoiceDateRange] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('');
  const [createDate, setCreateDate] = useState<string>('');

  const { setSelectedInvoices, selectedInvoices, setSelectedInvoiceDetail } = useContext(
    BillingNoteContext,
  );

  const { loading, error, data } = useQuery<InvoicesData, InvoicesVars>(INVOICES_QUERY, {
    variables: {
      service_date: serviceDate,
      invoice_number: invoiceNumber,
      payer: payerId,
      employee: employeeId,
      system_request: systemRequest,
      due_date: dueDate,
      invoice_status: status,
      business_invoice_type: businessInvoiceType,
      business_invoice_date_range: +businessInvoiceDateRange,
      page: currentPage,
      showData: pageSize,
      order_by: orderBy,
      order_type: orderType,
      create_date: createDate,
    },
    fetchPolicy: 'cache-and-network',
  });

  const selectInvoices = (item: InvoicesList, checked: boolean, value: string) => {
    if (checked) {
      if (selectedInvoices.invoicesId.length === 0) {
        setSelectedInvoices(
          (prevState): SelectedInvoicesType => ({
            ...prevState,
            payerId: item.payer._id,
            payerFullName: item.payer.full_name,
            systemRequest: item.system_request,
            businessInvoiceType: item.business_invoice_type,
          }),
        );
      }

      setSelectedInvoices(
        (prevState): SelectedInvoicesType => ({
          ...prevState,
          invoicesId: [...prevState.invoicesId, value],
        }),
      );
    } else {
      const invoicesIdFilter = selectedInvoices.invoicesId.filter(
        (invoiceId) => invoiceId !== value,
      );
      setSelectedInvoices(
        (prevState): SelectedInvoicesType => ({
          ...prevState,
          invoicesId: [...invoicesIdFilter],
        }),
      );
    }
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },

    {
      title: 'วันที่เอกสาร',
      dataIndex: 'serviceDateColumn',
      sortName: 'service_date',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) =>
            values === null
              ? setServiceDate('')
              : setServiceDate(
                  `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                )
          }
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'รหัสใบแจ้งหนี้',
      dataIndex: 'invoiceNumberColumn',
      sortName: 'invoice_number',
      width: 150,
      search: (
        <Input
          allowClear
          value={invoiceNumber}
          placeholder="ค้นหา"
          onChange={(event) => setInvoiceNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'มูลค่าใบแจ้งหนี้',
      dataIndex: 'grandTotalPriceColumn',
      width: 100,
      align: 'right',
    },

    {
      title: 'วันที่ครบกำหนดชำระ',
      dataIndex: 'dueDateColumn',
      sortName: 'due_date',
      width: 160,
      search: (
        <RangePicker
          onChange={(values) =>
            values === null
              ? setDueDate('')
              : setDueDate(`${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`)
          }
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },

    {
      title: 'วันที่รับชำระ',
      dataIndex: 'receiptDateColumn',
      sortName: 'receipt_date',
      width: 160,
    },

    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'payerFullNameColumn',
      width: 300,
      search: <SelectCustomersField onChange={(value: string) => setPayerId(value)} />,
    },
    {
      title: 'รูปแบบรับใบแจ้งหนี้',
      dataIndex: 'businessInvoiceTypeColumn',
      width: 300,
      search: (
        <SelectBusinessTypeField
          onChange={(value) => {
            setBusinessInvoiceType(value.businessInvoiceType);
            setBusinessInvoiceDateRange(value.businessDate);
          }}
        />
      ),
    },
    {
      title: 'ประเภทงาน',
      dataIndex: 'systemRequestColumn',
      width: 200,
      search: (
        <SelectSystemRequestField
          onChange={(value) => {
            setSystemRequest(value);
          }}
        />
      ),
    },
    {
      title: 'ผู้สร้าง',
      dataIndex: 'employeeFullNameColumn',
      width: 250,
      search: <SelectEmployeeField onChange={(value: string) => setEmployeeId(value)} />,
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'createDateColumn',
      sortName: 'create_date',
      width: 200,
      search: (
        <RangePicker
          onChange={(values) =>
            values === null
              ? setCreateDate('')
              : setCreateDate(
                  `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`,
                )
          }
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
          ranges={{
            วันนี้: [moment(), moment()],
            สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
            เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
            ปีนี้: [moment().startOf('year'), moment().endOf('year')],
          }}
        />
      ),
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'lastUpdateDateColumn',
      sortName: 'last_update',
      width: 150,
    },
  ];

  let invoicesData: InvoicesDataTable[] = [];
  let totalDocument = 0;

  if (data && data.invoices) {
    const { invoices } = data.invoices;
    invoicesData = invoices.map(
      (item, index): InvoicesDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            <StyledCheckbox
              checked={selectedInvoices.invoicesId.indexOf(item._id) !== -1}
              disabled={
                selectedInvoices.invoicesId.length > 0 &&
                (item.payer._id !== selectedInvoices.payerId ||
                  item.system_request !== selectedInvoices.systemRequest)
              }
              value={item._id}
              onChange={(event) => selectInvoices(item, event.target.checked, event.target.value)}
            />
            <Button
              onClick={() => {
                setSelectedInvoiceDetail(
                  (prevState): SelectedInvoiceDetailType => ({
                    ...prevState,
                    invoiceId: item._id,
                    isOpenModal: true,
                  }),
                );
              }}
              color="grey--text-only"
              icon={<SearchOutlined />}
              size="small"
            />
          </ToolsContainer>
        ),
        serviceDateColumn: !item.service_date ? '-' : formatDate(item.service_date),
        invoiceNumberColumn: item.invoice_number || '-',
        grandTotalPriceColumn: (
          <Text strong customType="primary">
            {formatCurrency(item.grand_total_price)}
          </Text>
        ),
        dueDateColumn: !item.due_date ? '-' : formatDate(item.due_date),
        receiptDateColumn: !item.receipt_date ? '-' : formatDate(item.receipt_date),
        payerFullNameColumn: item.payer.full_name || '-',
        businessInvoiceTypeColumn: item.business_invoice_type || '-',
        systemRequestColumn: item.system_request || '-',
        employeeFullNameColumn: item.employee.full_name || '-',
        createDateColumn: formatDateTime(item.create_date) || '-',
        lastUpdateDateColumn: formatDateTime(item.last_update) || '-',
      }),
    );

    totalDocument = data.invoices.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <div>
        <TableSearch
          columns={columns}
          dataSource={invoicesData}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize,
            total: totalDocument,
            showSizeChanger: false,
            onChange: (value: number) => onChangeCurrentPage(value),
          }}
          onSort={(sort) => {
            setOrderBy(sort.orderBy);
            setOrderType(sort.orderType);
          }}
        />
      </div>

      <ModalInvoiceDetail />
    </>
  );
};

export default InvoicesTable;
