import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  DebtTrackerContext,
  SelectedDebtTrackerHistory,
} from 'views/DebtTracker/common/context/DebtTrackerContext';
import { StyledButton, TextHeader, Container } from './Styles';

interface HeaderProps {
  invoiceNumber: string;
  invoiceDocument: string;
  receipt: { receiptDate: string | undefined; receiptDocument: string | undefined };
}

const Header: React.FC<HeaderProps> = (props) => {
  const history = useHistory();
  const { invoiceNumber, invoiceDocument, receipt } = props;

  const { setSelectedDebtTrackerHistory } = useContext(DebtTrackerContext);

  const closeModalHandler = () => {
    history.goBack();
  };

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <TextHeader>รหัสใบแจ้งหนี้: {invoiceNumber || '-'}</TextHeader>
        </Col>
        <Col>
          {receipt.receiptDate ? (
            <StyledButton
              color="blue--outline"
              onClick={() => window.open(receipt.receiptDocument)}
            >
              เอกสารใบเสร็จ
            </StyledButton>
          ) : (
            <StyledButton color="blue--outline" onClick={() => window.open(invoiceDocument)}>
              เอกสารใบแจ้งหนี้
            </StyledButton>
          )}
          <StyledButton
            icon={<CloseOutlined />}
            onClick={() => {
              closeModalHandler();
              setSelectedDebtTrackerHistory(
                (prevState): SelectedDebtTrackerHistory => ({
                  ...prevState,
                  isEditDebtTrackerHistory: false,
                }),
              );
            }}
          >
            ปิด
          </StyledButton>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
