/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';
import { Divider, Row, Col, Tabs, Skeleton, message } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import formatCurrency from 'shared/utils/formatCurrency';
import { color } from 'shared/utils/styles';
import { Tag, Text, SelectFieldSearchCustomers } from 'shared/components';
import { useFormContext } from 'react-hook-form';
import CUSTOMER_QUERY, { CustomerData, CustomerVars } from 'shared/graphql/query/customer';
import BUILD_PAYMENT_COD_QUERY, {
  BuildPaymentCodData,
  BuildPaymentCodVars,
} from 'shared/graphql/query/buildPaymentCod';
import { FormValues } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/Routes/CodIn/CodInList/ModalCreateCod/model/formValues.model';
import {
  CodInContext,
  SelectedCodsInType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/context/CodInContext';
import CodInList from './CodInList';

const { TabPane } = Tabs;

const CreateCod: React.FC = () => {
  const { setSelectedCodsIn } = useContext(CodInContext);
  const { getValues, watch } = useFormContext<FormValues>();
  const watchFields = watch(['codsInId', 'customerId', 'customerFullName']);
  const codsInId = getValues('codsInId')
    ? getValues('codsInId').map((codInId: string) => codInId)
    : [];

  const { data: customerData } = useQuery<CustomerData, CustomerVars>(CUSTOMER_QUERY, {
    variables: {
      _id: getValues('customerId'),
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: buildPaymentCodQueryData, loading, error } = useQuery<
    BuildPaymentCodData,
    BuildPaymentCodVars
  >(BUILD_PAYMENT_COD_QUERY, {
    variables: {
      cod: codsInId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && buildPaymentCodQueryData?.buildPaymentCod) {
      const { cod } = buildPaymentCodQueryData.buildPaymentCod;
      setSelectedCodsIn(
        (prevState): SelectedCodsInType => ({
          ...prevState,
          modalConfirmCod: {
            codsIn: cod.map((codItem) => ({
              customerFullName: codItem.customer.full_name,
              codInNumber: codItem.cod_in_number || '-',
              pickUpDate: codItem.pick_up_date,
              bookingNumber: codItem.booking_number,
              totalCod: codItem.total_cod,
            })),
          },
        }),
      );
    }
  }, [buildPaymentCodQueryData?.buildPaymentCod, error, loading, setSelectedCodsIn]);

  let content = <Skeleton />;
  if (customerData?.customer && buildPaymentCodQueryData?.buildPaymentCod) {
    const { address, tax_id, email, phone } = customerData.customer;
    const { total_cod } = buildPaymentCodQueryData?.buildPaymentCod;

    content = (
      <>
        <Text strong>เงื่อนไขการทำจ่าย</Text>
        <Divider style={{ margin: '10px 0' }} />
        <Row gutter={[32, 16]}>
          <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    ผู้รับเงิน <Text customType="grey">(เจ้าหนี้)</Text>
                    <Text customType="danger">*</Text>
                  </Col>
                  <Col span={24}>
                    {watchFields && (
                      <SelectFieldSearchCustomers
                        value={{
                          key: getValues('customerId'),
                          label: getValues('customerFullName'),
                        }}
                        disabled
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
            <Row>
              <Col span={10}>ที่อยู่:</Col>
              <Col span={14}>
                <Text strong>{address || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เลขประจำตัวผู้เสียภาษี:</Col>
              <Col span={14}>
                <Text strong>{tax_id || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เบอร์โทรศัพท์:</Col>
              <Col span={14}>
                <Text strong>{phone.length === 0 ? '-' : phone.join(', ')}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>อีเมล:</Col>
              <Col span={14}>
                <Text strong>{email.length === 0 ? '-' : email.join(', ')}</Text>
              </Col>
            </Row>
          </Col>

          <Col span={8}>
            <Row>
              <Col span={8}>รหัส COD-OUT:</Col>
              <Col span={16}>
                <Text strong>-</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>มูลค่า CODรวม (บาท):</Col>
              <Col span={16}>
                <Text strong customType="primary" style={{ lineHeight: 1, fontSize: 24 }}>
                  {formatCurrency(total_cod)}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>สถานะ:</Col>
              <Col span={16}>
                <Tag customColor="grey">กำลังสร้าง...</Tag>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider style={{ margin: 0, marginTop: -5 }} />

        <Tabs defaultActiveKey="1" animated={false}>
          <TabPane
            tab={
              <span>
                รายการ COD{' '}
                <CheckCircleOutlined
                  style={{
                    color: color.success,
                    marginLeft: 5,
                  }}
                />
              </span>
            }
            key="1"
          >
            <CodInList />
          </TabPane>
        </Tabs>
      </>
    );
  }

  return content;
};

export default CreateCod;
