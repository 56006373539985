/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { message } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import CONFIRM_RECEIPT_QUERY, {
  ConfirmReceiptData,
  ConfirmReceiptVars,
} from 'shared/graphql/query/confirmReceipt';
import { BillingNoteContext } from 'views/AccountReceive/common/context/BillingNoteContext';
import { Spin, FileList } from 'shared/components';
import s3 from 'shared/config/s3';

const DocumentList: React.FC = () => {
  const { selectedBillingNoteDetail } = useContext(BillingNoteContext);
  const { billingNoteId } = selectedBillingNoteDetail;

  const { data } = useQuery<ConfirmReceiptData, ConfirmReceiptVars>(CONFIRM_RECEIPT_QUERY, {
    variables: {
      billing_id: billingNoteId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = <Spin />;
  if (data && data.confirmReceipt) {
    const { proof_of_payment } = data.confirmReceipt;
    const fileList = proof_of_payment.map((item) => `${s3}/${item}`);
    content = <FileList files={fileList} />;
  }
  return <div>{content}</div>;
};

export default DocumentList;
