import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'antd';
import { Button } from 'shared/components';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppContext } from 'shared/context/AppContext';
import { DebtTrackerContext } from 'views/DebtTracker/common/context/DebtTrackerContext';
import { FormValues } from 'views/DebtTracker/Routes/DebtTrackerDetail/ModalDebtTrackerHistory/DebtTrackerHistory/DebtTrackerHistoryForm/formValues.model';
import validateSchema from 'views/DebtTracker/Routes/DebtTrackerDetail/ModalDebtTrackerHistory/DebtTrackerHistory/DebtTrackerHistoryForm/validateSchema';
import InputDebtTrackerHistoryForm from 'views/DebtTracker/Routes/DebtTrackerDetail/ModalDebtTrackerHistory/DebtTrackerHistory/DebtTrackerHistoryForm/InputDebtTrackerHistoryForm';
import ModalConfirmCreate from './ModalConfirmCreate';

type Params = {
  id: string;
  invoiceId: string;
};

const DebtTrackerHistoryCreate: React.FC = () => {
  const { id, invoiceId } = useParams<Params>();

  const { userDetail } = useContext(AppContext);
  const { debtTrackerHistoryValue } = useContext(DebtTrackerContext);

  const [isOpenModalConfirmCreate, setOpenModalConfirmCreate] = useState<boolean>(false);

  const methods = useForm<FormValues>({
    mode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver(validateSchema),
    defaultValues: {
      logARId: '',
      invoiceId: '',
      followDebtHistoryId: '',
      followDetailId: '',
      historyLogId: '',
      followStatus: '',
      followStatusDetail: '',
      action: '',
      actionDetail: '',
      nextFollowDate: moment().format('YYYY/MM/DD'),
      detail: '',
      employeeId: '',
      status: 0,
    },
  });

  const { handleSubmit, formState, reset } = methods;
  const { isDirty } = formState;

  useEffect(() => {
    reset({
      logARId: id,
      invoiceId,
      followDebtHistoryId: debtTrackerHistoryValue.followDebtHistoryId,
      employeeId: userDetail.employeeId,
      nextFollowDate: moment().format('YYYY/MM/DD'),
    });
  }, [debtTrackerHistoryValue.followDebtHistoryId, id, invoiceId, reset, userDetail.employeeId]);

  const submit = handleSubmit(() => {
    setOpenModalConfirmCreate(true);
  });

  return (
    <>
      <FormProvider {...methods}>
        <InputDebtTrackerHistoryForm />
        <Row justify="end">
          <Col>
            <Button disabled={!isDirty} color="green" onClick={submit}>
              บันทึกการตามหนี้
            </Button>
          </Col>
        </Row>
        <ModalConfirmCreate
          visible={isOpenModalConfirmCreate}
          onCancel={() => setOpenModalConfirmCreate(false)}
        />
      </FormProvider>
    </>
  );
};

export default DebtTrackerHistoryCreate;
