import gql from 'graphql-tag';

export interface EmailLogs {
  _id: string;
  to: {
    email: string;
  }[];
  cc: {
    email: string;
  }[];
  bcc: {
    email: string;
  }[];
  billing_note: {
    _id: string;
    bill_number: string;
  };
  status_code: string;
  return_msg: string;
  create_date: string;
}

export interface EmailLogsData {
  emailLogs: EmailLogs[];
}

export interface EmailLogsVars {
  _id?: string;
  subject?: string;
  status_code?: string;
  billing_note?: string;
  receipt?: string;
}

export default gql`
  query EmailLogs(
    $_id: ID
    $subject: String
    $status_code: String
    $billing_note: ID
    $receipt: ID
  ) {
    emailLogs(
      _id: $_id
      subject: $subject
      status_code: $status_code
      billing_note: $billing_note
      receipt: $receipt
    ) {
      _id
      to {
        email
      }
      cc {
        email
      }
      bcc {
        email
      }
      billing_note {
        _id
        bill_number
      }
      status_code
      return_msg
      create_date
    }
  }
`;
