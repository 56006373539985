/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { Text } from 'shared/components';
import { Payment } from 'shared/graphql/query/buildApp';
import { StyledTable } from './Styles';

type ExpandedRowProps = {
  data: Payment[];
};

const columns = [
  {
    title: 'วันที่เอกสาร',
    dataIndex: 'column1',
    width: 110,
  },
  {
    title: 'รหัสใบสั่งจ่าย',
    dataIndex: 'column2',
    width: 200,
  },
  {
    title: 'รหัส shipment/JOB/Booking',
    dataIndex: 'column3',
    width: 200,
  },
  {
    title: 'จำนวนตั้งเบิก (บาท)',
    dataIndex: 'column4',
    width: 160,
  },
  {
    title: '',
  },
];

const ExpandedRow: React.FC<ExpandedRowProps> = (props) => {
  const { data } = props;
  const paymentData = data.map((item, index) => {
    const { create_date, payment_number, number_ref, grand_total_price } = item;

    return {
      key: index,
      column1: create_date ? formatDate(create_date) : '-',
      column2: payment_number || '-',
      column3: number_ref || '-',
      column4: (
        <Text customType="primary" strong>
          {formatCurrency(grand_total_price)}
        </Text>
      ),
    };
  });

  return (
    <div style={{ marginTop: 10 }}>
      <StyledTable
        scroll={{ x: 'max-content' }}
        pagination={false}
        columns={columns}
        dataSource={paymentData}
        size="small"
      />
    </div>
  );
};

export default ExpandedRow;
