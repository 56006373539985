/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDateTime } from 'shared/utils/dateTime';
import { Text } from 'shared/components';
import { CodItem } from 'shared/graphql/query/buildPaymentCod';
import { StyledTable } from './Styles';

type ExpandedRowProps = {
  data: CodItem[];
};

const columns = [
  {
    title: 'นายหน้า',
    dataIndex: 'column1',
    width: 110,
  },
  {
    title: 'จำนวนที่ 3PL ต้องโอน (บาท)',
    dataIndex: 'column2',
    width: 180,
    align: 'right',
  },
  {
    title: 'วันที่แจ้งโอน',
    dataIndex: 'column3',
    width: 180,
  },
  {
    title: 'วันที่ส่งสำเร็จ',
    dataIndex: 'column4',
    width: 180,
  },
  {
    title: '',
  },
];

const ExpandedRow: React.FC<ExpandedRowProps> = (props) => {
  const { data } = props;

  const codItemData = data?.map((item, index) => {
    const { vendor, total_cod, cod_in_date, delivered_date } = item;

    return {
      key: index,
      column1: vendor?.full_name || '-',
      column2: (
        <Text customType="primary" strong>
          {formatCurrency(total_cod)}
        </Text>
      ),
      column3: formatDateTime(cod_in_date) || '-',
      column4: formatDateTime(delivered_date) || '-',
    };
  });

  return (
    <div style={{ marginTop: 10 }}>
      <StyledTable
        scroll={{ x: 'max-content' }}
        pagination={false}
        columns={columns}
        dataSource={codItemData}
        size="small"
      />
    </div>
  );
};

export default ExpandedRow;
