import React, { ReactNode } from 'react';
import { ButtonType } from '../Button';
import ModalHeader from './ModalHeader';
import StyledModal from './Styles';

type ButtonHeader = {
  text: string;
  clicked?: () => void;
  color?: ButtonType;
  visible?: boolean;
  icon?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
};

type ModalTopButtonProps = {
  visible?: boolean;
  onCancel?: () => void;
  width?: number;
  children: ReactNode;
  title: string;
  btnHeader?: ButtonHeader[];
};

const defaultProps = {
  visible: false,
  width: 700,
};

const ModalTopButton: React.FC<ModalTopButtonProps> = (props) => {
  const { visible, onCancel, children, title, width, btnHeader } = props;
  return (
    <StyledModal
      visible={visible || false}
      onCancel={onCancel}
      className="custom-modal"
      destroyOnClose
      footer={null}
      closable={false}
      width={width}
      centered
    >
      <ModalHeader
        title={title}
        btnHeader={btnHeader || []}
        onClose={() => (onCancel ? onCancel() : {})}
      />
      {children}
    </StyledModal>
  );
};

ModalTopButton.defaultProps = defaultProps;

export default ModalTopButton;
