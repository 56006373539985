//LIBRARY
import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
// import { useQuery } from 'react-query';

//UTILS
// import { mock_data_modal_agent } from '../utils/Dashboard.MockData';
import {
  TEXT_MODAL_CLOSE,
  TEXT_MODAL_HAVE_TO_DO_DATE,
  TEXT_MODAL_PRODUCT_COUNT,
  TEXT_MODAL_TITLE,
  TEXT_MODAL_WAITING_TOTLE,
} from '../utils/Dashboard.Constants';
import {
  STYLE_MODAL_ADD_LINE,
  STYLE_MODAL_HEADER,
  STYLE_MODAL_TITLE,
} from '../utils/Dashboard.Style';

//FUNCTIONS
import { axiosExpress } from 'shared/config/axios';

//COMPONENTS
import RenderModalTable from './DashboardRenderModalTable';
import { TrackingCodByCashPopup } from 'shared/model/trackingCodByCashPopup.model';
import { formatDate } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';

//QUERY

interface ModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectDate: string;
}

const initTrackingCodByCashPopup = (): TrackingCodByCashPopup => {
  return {
    date: '',
    total_tracking_wait_tranfer: 0,
    wait_tranfer_price: 0,
    detail_by_agent: [],
  };
};

const DashboardRenderModal: React.FC<ModalProps> = ({
  open = false,
  setOpen = () => {},
  selectDate = '',
}) => {
  const [data, setData] = useState<TrackingCodByCashPopup>(initTrackingCodByCashPopup);
  const [loading, setLoading] = useState<boolean>(false);

  const FunctionGetDataModal = async () => {
    setLoading(true);
    try {
      const { data } = await axiosExpress.get(
        `/v1/dashboard/wait-pay-cod-popup?date=${selectDate}`,
      );
      setData(data);
    } catch (error) {
      console.error('ERROR TO LOAD DATA MODAL : ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FunctionGetDataModal();
  }, [open]);

  // const { data } = useQuery<TrackingCodByCashPopup>('FunctionGetDataModal', () =>
  //   FunctionGetDataModal(),
  // );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (data) {
    return (
      <div key={`modal-non-index`}>
        <Modal
          title={TEXT_MODAL_TITLE.replace(`[date]`, formatDate(selectDate))}
          visible={open}
          width={900}
          onCancel={() => {
            setOpen(false);
          }}
          footer={[
            <Button
              key="back"
              onClick={() => {
                setOpen(false);
              }}
            >
              {TEXT_MODAL_CLOSE}
            </Button>,
          ]}
        >
          <div style={STYLE_MODAL_HEADER}>
            <div>
              <div style={STYLE_MODAL_TITLE}>{TEXT_MODAL_WAITING_TOTLE}</div>
              <div
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold', // font weight bold import มาจาก style แล้วระเบิด เลย inline
                }}
              >
                {formatCurrency(data?.wait_tranfer_price, '0.00')}
              </div>
            </div>
            <div>
              <div style={STYLE_MODAL_TITLE}>{TEXT_MODAL_HAVE_TO_DO_DATE}</div>
              <div
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                }}
              >
                {formatDate(data?.date)}
              </div>
            </div>
            <div>
              <div style={STYLE_MODAL_TITLE}>{TEXT_MODAL_PRODUCT_COUNT}</div>
              <div
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                }}
              >
                {data?.total_tracking_wait_tranfer}
              </div>
            </div>
          </div>
          <div style={STYLE_MODAL_ADD_LINE} />
          <RenderModalTable detailByAgent={data?.detail_by_agent} />
        </Modal>
      </div>
    );
  } else {
    return null;
  }
};

export default DashboardRenderModal;
