type EnumLiteralsOf<T extends unknown> = T[keyof T];
export const PaymentStatus = Object.freeze({
  REVIEW: 1 as const,
  PENDING: 2 as const,
  COMPLETE: 3 as const,
  REJECT: 4 as const,
  CANCEL: 5 as const,
  PAID: 6 as const,
  EDIT: 7 as const,
});

export type PaymentStatus = EnumLiteralsOf<typeof PaymentStatus>;
