import gql from 'graphql-tag';

export interface AllWht {
  wht: number;
  price: number;
  total_wht: number;
}

export interface BuildListPayment {
  re_total_before_discount: number;
  non_total_before_discount: number;
  inc_total_before_discount: number;
  re_total_discount: number;
  non_total_discount: number;
  inc_total_discount: number;
  re_total_after_discount: number;
  non_total_after_discount: number;
  inc_total_after_discount: number;
  total_vat: number;
  re_total: number;
  non_total: number;
  inc_total: number;
  total_price: number;
  all_wht: AllWht[];
  grand_total_price: number;
}

export interface PaymentList {
  datamaster_service_item_id: string;
  advance_type: boolean;
  list_number: string;
  list_name: string;
  qty: number;
  unit: string;
  price: number;
  discount: number;
  vat: number;
  wht: number;
}

export interface BuildListPaymentData {
  buildListPayment: BuildListPayment;
}

export interface BuildListPaymentVars {
  payment_list: PaymentList[];
}

export default gql`
  query BuildListPayment($payment_list: [inputPaymentList]) {
    buildListPayment(payment_list: $payment_list) {
      re_total_before_discount
      non_total_before_discount
      inc_total_before_discount
      re_total_discount
      non_total_discount
      inc_total_discount
      re_total_after_discount
      non_total_after_discount
      inc_total_after_discount
      total_vat
      re_total
      non_total
      inc_total
      total_price
      all_wht {
        wht
        price
        total_wht
      }
      grand_total_price
    }
  }
`;
