import styled from 'styled-components';
import { color } from 'shared/utils/styles';

export const ContainerTitle = styled.div`
  padding: 5px 15px;
  border-radius: 5px 5px 0px 0px;
  background-color: ${(props) => `${props.color}`};
`;

export const ContainerDetail = styled.div`
  height: calc(100vh - 480px);
  overflow: auto;
  overflow-y: overlay;
  padding: 5px 15px;
  border-radius: 0px 0px 5px 5px;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: ${color.grey};
`;
