/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { SaveOutlined } from '@ant-design/icons';
import { Col, Row, message, Input, DatePicker, Form } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import {
  ApprovePaymentContext,
  SelectedApprovePaymentDetailType,
} from 'views/AccountPayment/common/context/ApprovePaymentContext';
import SINGLE_UPLOAD_MUTATION, {
  SingleUploadData,
  SingleUploadVars,
} from 'shared/graphql/mutation/singleUpload';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import formatCurrency from 'shared/utils/formatCurrency';
import { Text } from 'shared/components';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { FormValues } from 'views/AccountPayment/Routes/ApprovePayment/AppTable/ModalAppDetail/Header/ModalSavePaid/model/formValues.model';
import { StyledModal, StyledButton } from './Styles';
import DocumentList from './DocumentList';
import ModalConfirmUpdate from './ModalConfirmUpdate';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);
const dateFormat = 'YYYY/MM/DD';
const { TextArea } = Input;

type ModalSavePaidProps = {
  visible: boolean;
  onCancel: () => void;
};

const validateSchema = yup.object().shape({
  id: yup.string().required(),
  proofSlip: yup.string().required(),
  paidDate: yup.string().required(),
  bankRef: yup.string().required(),
  slipRemark: yup.string(),
});

const ModalSavePaid: React.FC<ModalSavePaidProps> = (props) => {
  const { onCancel, visible } = props;
  const [isOpenModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const { selectedApprovePaymentDetail, setSelectedApprovePaymentDetail } = useContext(
    ApprovePaymentContext,
  );
  const { grandTotalPrice, appId, appNumber } = selectedApprovePaymentDetail;
  const filePondRef = useRef<any>();

  const methods = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      id: appId,
      proofSlip: '',
      paidDate: '',
      bankRef: '',
      slipRemark: '',
    },
  });
  const { handleSubmit, register, setValue, control, errors, trigger, watch, reset } = methods;
  const watchProofSlip = watch('proofSlip');

  useEffect(() => {
    register('id');
    register('proofSlip');
  }, [register]);

  const [singleUpload] = useMutation<SingleUploadData, SingleUploadVars>(SINGLE_UPLOAD_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.singleUpload.path) {
        setValue('proofSlip', data.singleUpload.path);
        trigger(['proofSlip']);
        message.success('อัพโหลดเอกสารแล้ว');
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
  });

  const deletedFileUpload = (_path: string) => {
    setValue('proofSlip', '');
    trigger(['proofSlip']);
  };

  const onSubmit = handleSubmit(() => {
    setOpenModalConfirm(true);
  });

  return (
    <StyledModal
      closable={false}
      title={
        <Row justify="space-between" align="middle">
          <Col>บันทึกการจ่ายรายการ COD</Col>
          <Col>
            <StyledButton icon={<SaveOutlined />} color="green" onClick={() => onSubmit()}>
              บันทึกการจ่าย
            </StyledButton>
            <StyledButton onClick={onCancel}>ปิด</StyledButton>
          </Col>
        </Row>
      }
      className="custom-modal"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      afterClose={() => {
        reset({
          id: appId,
        });
      }}
    >
      <FormProvider {...methods}>
        <Row>
          <Col span={6}>รหัสรอบจ่าย:</Col>
          <Col span={18}>
            <Text strong>{appNumber}</Text>
          </Col>
        </Row>

        <Row>
          <Col span={6}>มูลค่ารอบจ่าย (บาท):</Col>
          <Col span={18}>
            <Text strong customType="primary">
              {formatCurrency(grandTotalPrice, '0')}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            วันที่จ่าย:<Text customType="danger">*</Text>
          </Col>
          <Col span={18}>
            <Form.Item validateStatus={errors.paidDate && 'error'} style={{ marginBottom: 10 }}>
              <Controller
                control={control}
                name="paidDate"
                render={({ onChange, value }) => (
                  <DatePicker
                    style={{ width: '100%', maxWidth: 200 }}
                    allowClear={false}
                    value={value ? moment(value, dateFormat) : null}
                    placeholder="วันที่จ่าย"
                    onChange={(valueDate) => {
                      if (!valueDate) {
                        onChange('');
                      } else {
                        onChange(valueDate.format(dateFormat));
                      }
                    }}
                  />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            Bank Ref:<Text customType="danger">*</Text>
          </Col>
          <Col span={18}>
            <Form.Item validateStatus={errors.bankRef && 'error'} style={{ marginBottom: 10 }}>
              <Controller
                style={{ width: '100%', maxWidth: 200 }}
                as={Input}
                name="bankRef"
                control={control}
                placeholder="ระบุ"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div>
              แนบไฟล์หลักฐานการจ่าย:<Text customType="danger">*</Text>{' '}
              {errors.proofSlip && (
                <Text customType="danger">(กรุณาอัพโหลดเอกสารหลักฐานการจ่าย)</Text>
              )}
              <div>
                <Text customType="grey">
                  (รูปแบบไฟล์ที่รองรับ .jpg , .jpeg , .png , .pdf , และขนาดไฟล์ไม่เกิน 2Mb.)
                </Text>
              </div>
            </div>
            {!watchProofSlip && (
              <FilePond
                ref={filePondRef}
                server={{
                  process: (_fieldName, file, _metadata, _load, error) => {
                    singleUpload({
                      variables: {
                        file: file as File,
                      },
                    }).catch((err) => {
                      message.error(err.message);
                      error(err);
                    });
                  },
                }}
                acceptedFileTypes={['image/jpeg', 'image/png', 'application/pdf']}
                maxFileSize="2MB"
                maxFiles={1}
              />
            )}

            {watchProofSlip && (
              <DocumentList
                filesPath={[watchProofSlip]}
                onDelete={(path) => deletedFileUpload(path)}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            หมายเหตุ:
            <Form.Item validateStatus={errors.slipRemark && 'error'}>
              <Controller
                style={{ width: '100%' }}
                as={TextArea}
                name="slipRemark"
                control={control}
                placeholder="ระบุ"
                rows={4}
              />
            </Form.Item>
          </Col>
        </Row>

        <ModalConfirmUpdate
          visible={isOpenModalConfirm}
          onCompleted={() => {
            onCancel();
            setSelectedApprovePaymentDetail(
              (prevState): SelectedApprovePaymentDetailType => ({
                ...prevState,
                isOpenModal: false,
              }),
            );
          }}
          onCancel={() => {
            setOpenModalConfirm(false);
          }}
        />
      </FormProvider>
    </StyledModal>
  );
};

export default ModalSavePaid;
