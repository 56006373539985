import styled from 'styled-components';
import { Modal, Radio } from 'antd';

export const StyledModal = styled(Modal)`
  top: 0px;
  padding: 20px;

  &.custom-modal.ant-modal {
    width: 100% !important;
    max-width: 900px;
  }

  & .ant-modal-body {
    padding: 0px 20px 20px;
  }

  & .ant-modal-body p {
    margin: 0;
  }
`;

export const StyledRadio = styled(Radio)`
  transform: scale(1.2);
  margin-right: 10px;
`;

export const ToolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
