import React, { useState, ReactChild } from 'react';
import { InvoiceStatus } from 'views/AccountReceive/common/model/invoiceStatus.model';

export interface SelectedInvoicesType {
  invoicesId: string[];
  payerId: string;
  dueDate: string;
  isOpenModal: boolean;
  isLoading: boolean;
}

export interface SelectedInvoiceDetailType {
  isOpenModal: boolean;
  invoiceId: string;
  invoiceStatus: InvoiceStatus | null;
  invoiceNumber: string;
  isLoading: boolean;
  modalConfirmCreateInvoice: {
    payerFullName: string;
    businessInvoiceType: string;
    grandTotalPrice: number;
    costings: {
      checkedDate: string;
      costingNumber: string;
      numberRef: string;
      serviceDate: string;
      completedDate: string;
      grandTotalPrice: number;
    }[];
  };
  document: string;
}

export interface SelectedCostingsType {
  costingsId: string[];
  payerId: string;
  dueDate: string;
  isOpenModal: boolean;
  isLoading: boolean;
}

export interface SelectedCostingDetailType {
  costingId: string;
  isOpenModal: boolean;
  costingNumber: string;
}

export interface CreateInvoiceType {
  isOpenModal: boolean;
}

export interface CreateInvoiceFromExcelType {
  isOpenModal: boolean;
}

interface Invoice {
  selectedInvoices: SelectedInvoicesType;
  setSelectedInvoices: (params: (cb: SelectedInvoicesType) => SelectedInvoicesType) => void;
  selectedInvoiceDetail: SelectedInvoiceDetailType;
  setSelectedInvoiceDetail: (
    params: (cb: SelectedInvoiceDetailType) => SelectedInvoiceDetailType,
  ) => void;
  selectedCostings: SelectedCostingsType;
  setSelectedCostings: (params: (cb: SelectedCostingsType) => SelectedCostingsType) => void;
  selectedCostingDetail: SelectedCostingDetailType;
  setSelectedCostingDetail: (
    params: (cb: SelectedCostingDetailType) => SelectedCostingDetailType,
  ) => void;
  createInvoice: CreateInvoiceType;
  setCreateInvoice: (params: (cb: CreateInvoiceType) => CreateInvoiceType) => void;
  createInvoiceFromExcel: CreateInvoiceFromExcelType;
  setCreateInvoiceFromExcel: (
    params: (cb: CreateInvoiceFromExcelType) => CreateInvoiceFromExcelType,
  ) => void;
}

type InvoiceProps = {
  children: ReactChild;
};

const InvoiceContext = React.createContext({} as Invoice);

const InvoiceProvider: React.FC<InvoiceProps> = (props) => {
  const { children } = props;

  const [selectedInvoices, setSelectedInvoices] = useState<SelectedInvoicesType>({
    invoicesId: [],
    payerId: '',
    dueDate: '',
    isOpenModal: false,
    isLoading: true,
  });

  const [selectedInvoiceDetail, setSelectedInvoiceDetail] = useState<SelectedInvoiceDetailType>({
    invoiceId: '',
    invoiceStatus: null,
    invoiceNumber: '',
    isOpenModal: false,
    isLoading: false,
    modalConfirmCreateInvoice: {
      payerFullName: '',
      businessInvoiceType: '',
      grandTotalPrice: 0,
      costings: [],
    },
    document: '',
  });

  const [selectedCostings, setSelectedCostings] = useState<SelectedCostingsType>({
    costingsId: [],
    payerId: '',
    dueDate: '',
    isOpenModal: false,
    isLoading: true,
  });

  const [selectedCostingDetail, setSelectedCostingDetail] = useState<SelectedCostingDetailType>({
    costingId: '',
    isOpenModal: false,
    costingNumber: '',
  });

  const [createInvoice, setCreateInvoice] = useState<CreateInvoiceType>({
    isOpenModal: false,
  });

  const [createInvoiceFromExcel, setCreateInvoiceFromExcel] = useState<CreateInvoiceFromExcelType>({
    isOpenModal: false,
  });

  const store = {
    selectedInvoices,
    setSelectedInvoices,
    selectedInvoiceDetail,
    setSelectedInvoiceDetail,
    selectedCostings,
    setSelectedCostings,
    selectedCostingDetail,
    setSelectedCostingDetail,
    createInvoice,
    setCreateInvoice,
    createInvoiceFromExcel,
    setCreateInvoiceFromExcel,
  };

  return <InvoiceContext.Provider value={store}>{children}</InvoiceContext.Provider>;
};

export { InvoiceContext, InvoiceProvider };
