import styled from 'styled-components';
import { color, mixin, font } from 'shared/utils/styles';

const StyledTabs = styled.div`
  background-color: ${color.backgroundPrimary};
  padding: 10px 20px 0;

  & ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  & li {
    display: inline-block;
    color: #ffffff;
    padding: 10px 20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    white-space: nowrap;
    ${mixin.clickable}
    ${font.size(20)}

    & svg {
      margin-right: 10px;
      vertical-align: middle;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &.active {
      background-color: #ffffff;
      color: #000000;
      ${font.bold}
    }
  }
`;

export default StyledTabs;
