import * as yup from 'yup';

const validateSchema = yup.object().shape({
  logARId: yup.string(),
  invoiceId: yup.string(),
  followDebtHistoryId: yup.string(),
  followDetailId: yup.string(),
  followStatus: yup.string().required('กรุณากรอกข้อมูล'),
  followStatusDetail: yup.string().when('followStatus', {
    is: (value) => value === 'อื่นๆ',
    then: yup.string().required('กรุณากรอกข้อมูล'),
  }),
  action: yup.string().required('กรุณากรอกข้อมูล'),
  actionDetail: yup.string().when('action', {
    is: (value) => value === 'อื่นๆ',
    then: yup.string().required('กรุณากรอกข้อมูล'),
  }),

  nextFollowDate: yup.string().required('กรุณากรอกข้อมูล'),
  detail: yup.string(),
  employeeId: yup.string(),
  status: yup.number(),
});

export default validateSchema;
