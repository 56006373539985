import styled from 'styled-components';
import { Button, Row } from 'antd';
import { color, font } from 'shared/utils/styles';

export const StyledButtonFilter = styled(Button)`
  &.ant-btn {
    padding: 1px;
  }

  &.ant-btn-icon-only {
    height: 33px;
  }

  & svg {
    fill: #8c8c8c;
    width: 15px;
    height: 15px;
  }
`;

export const StyledPopoverFilterRow = styled(Row)`
  font-size: 20px;

  & .text-right {
    text-align: right;
  }

  &.divider {
    margin-top: 10px;
    border-top: 1px solid ${color.border};
  }

  & .text-title {
    ${font.bold}
  }

  & .text-sub-title {
    color: #8c8c8c;
  }

  & .right-margin {
    margin-right: 2px;
  }
`;
