import styled from 'styled-components';
import { Checkbox } from 'antd';
import { mixin } from 'shared/utils/styles';
import { Table, Button } from 'shared/components';

export const Container = styled.div`
  padding: 0px 20px 20px;
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

export const StyledTable = styled(Table)`
  ${mixin.backgroundTableEven}

  & .ant-table thead > tr > th {
    vertical-align: top;
    background-color: transparent;
  }

  & .table-header {
    &__text {
      padding-bottom: 6px;
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  transform: scale(1.2);
  margin-right: 10px;
`;

export const ToolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
