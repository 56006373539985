type EnumLiteralsOf<T extends unknown> = T[keyof T];
export const CodItemStatus = Object.freeze({
  OPS_PENDING: 1 as const,
  PENDING: 2 as const,
  WAIT_TO_PAY: 3 as const,
  REJECT: 4 as const,
  CANCEL: 5 as const,
  PAID: 6 as const,
});

export type CodItemStatus = EnumLiteralsOf<typeof CodItemStatus>;
