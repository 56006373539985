import gql from 'graphql-tag';

export interface PaymentTypeData {
  paymentType: {
    payment_type: string[];
  };
}

export default gql`
  query PaymentType {
    paymentType {
      payment_type
    }
  }
`;
