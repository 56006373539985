import gql from 'graphql-tag';
import { InvoiceStatus } from 'views/AccountReceive/common/model/invoiceStatus.model';

export interface UpdateInvoiceData {
  updateInvoice: {
    _id: string;
  };
}

export interface UpdateInvoiceVars {
  _id: string;
  payer?: string;
  due_date?: string;
  invoice_remark?: string;
  invoice_status?: InvoiceStatus;
  cancel_remark?: string;
}

export default gql`
  mutation updateInvoice(
    $_id: ID!
    $payer: ID
    $due_date: String
    $invoice_remark: String
    $invoice_status: Int
    $cancel_remark: String
  ) {
    updateInvoice(
      _id: $_id
      updateInvoice: {
        payer: $payer
        due_date: $due_date
        invoice_remark: $invoice_remark
        invoice_status: $invoice_status
        cancel_remark: $cancel_remark
      }
    ) {
      _id
    }
  }
`;
