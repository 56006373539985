import gql from 'graphql-tag';
import { InvoiceStatus } from 'views/AccountReceive/common/model/invoiceStatus.model';

export interface InvoiceVars {
  _id: string;
}

export interface InvoiceList {
  _id: string;
  list_name: string;
  list_number: string;
  qty: number;
  price: number;
  total_before_discount: number;
  discount: number;
  total_price: number;
  vat: number;
  wht: number;
  advance_type: boolean;
  datamaster_service_item_id: string;
  unit: string;
}

export interface InvoiceData {
  invoice: {
    _id: string;
    payer: {
      _id: string;
      number: string;
      full_name: string;
      tax_id: string;
      phone: string[];
      email: string[];
      address: string;
      sub_district: string;
      district: string;
      province: string;
      postcode: string;
      full_address: string;
      business_pay_round: {
        round_date: string;
        after_invoice: string;
      };
    };
    due_date: string;
    create_date: string;
    service_date: string;
    invoice_number: string;
    invoice_status: InvoiceStatus;
    grand_total_price: number;
    system_request: string;
    invoice_detail: {
      service_date: string;
      ref_id: string;
      number_ref: string;
      grand_total_price: number | null;
      invoice_list: InvoiceList[];
      costing: null | {
        checked_date: string;
        costing_number: string;
        completed_date: string;
      };
    }[];
    re_total_before_discount: number;
    non_total_before_discount: number;
    inc_total_before_discount: number;
    re_total_discount: number;
    non_total_discount: number;
    inc_total_discount: number;
    re_total_after_discount: number;
    non_total_after_discount: number;
    inc_total_after_discount: number;
    total_vat: number;
    re_total: number;
    non_total: number;
    inc_total: number;
    total_price: number;
    all_wht: {
      wht: number;
      price: number;
      total_wht: number;
    }[];
    invoice_remark: string;
    billing_note: null | {
      bill_number: string;
    };
    receipt: null | {
      receipt_number: string;
      receipt_date: string;
      document: string;
    };
    business_invoice_type: string;
    document: string;
    cancel_remark: string;
    followDate: string;
    followStatus: string;
    nextFollowDate: string;
    overDueDays: number;
  };
}

export default gql`
  query Invoice($_id: ID!) {
    invoice(_id: $_id) {
      _id
      payer {
        _id
        number
        full_name
        tax_id
        phone
        email
        address
        sub_district
        district
        province
        postcode
        full_address
        business_pay_round {
          round_date
          after_invoice
        }
      }
      due_date
      create_date
      service_date
      invoice_number
      invoice_status
      grand_total_price
      system_request
      invoice_detail {
        service_date
        ref_id
        number_ref
        grand_total_price
        invoice_list {
          _id
          list_name
          list_number
          qty
          price
          total_before_discount
          discount
          total_price
          vat
          wht
          advance_type
          datamaster_service_item_id
          unit
        }
        costing {
          checked_date
          costing_number
          completed_date
        }
      }
      re_total_before_discount
      non_total_before_discount
      inc_total_before_discount
      re_total_discount
      non_total_discount
      inc_total_discount
      re_total_after_discount
      non_total_after_discount
      inc_total_after_discount
      total_vat
      re_total
      non_total
      inc_total
      total_price
      all_wht {
        wht
        price
        total_wht
      }
      invoice_remark
      billing_note {
        bill_number
      }
      receipt {
        receipt_number
        receipt_date
        document
      }
      business_invoice_type
      document
      cancel_remark
      followDate
      followStatus
      nextFollowDate
      overDueDays
    }
  }
`;
