/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode } from 'react';
import { Select, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import CUSTOMERS_NAME_AND_BRANCH_QUERY, {
  CustomersNameAndBranchData,
  CustomersNameAndBranchVars,
} from 'shared/graphql/query/customersNameAndBranch';

const { Option } = Select;

type SelectCustomersNameAndBranchFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const SelectCustomersNameAndBranchField: React.FC<SelectCustomersNameAndBranchFieldProps> = (
  props,
) => {
  const { value, onChange } = props;
  const [fullName, setFullName] = useState<string>('');
  const { data, loading } = useQuery<CustomersNameAndBranchData, CustomersNameAndBranchVars>(
    CUSTOMERS_NAME_AND_BRANCH_QUERY,
    {
      variables: {
        full_name: fullName,
        showData: 100,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  let options: ReactNode[] = [];
  if (data && data.customersNameAndBranch) {
    const { customers } = data.customersNameAndBranch;
    options = customers.map((customer) => (
      <Option key={customer._id} value={customer._id}>
        {customer.full_name}
      </Option>
    ));
  }

  return (
    <Select
      allowClear
      style={{
        width: '100%',
      }}
      value={value}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      showSearch
      placeholder="ค้นหา"
      onSearch={(valueSearch) => setFullName(valueSearch)}
      onChange={(valueChange) => onChange && onChange(valueChange)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};

export default SelectCustomersNameAndBranchField;
