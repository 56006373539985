/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode } from 'react';
import { Select, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import EMPLOYEES_QUERY, { EmployeesData, EmployeesVars } from 'shared/graphql/query/employees';

const { Option } = Select;

type SelectEmployeeFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const SelectEmployeeField: React.FC<SelectEmployeeFieldProps> = (props) => {
  const { value, onChange } = props;
  const [fullName, setFullName] = useState<string>('');
  const { data, loading } = useQuery<EmployeesData, EmployeesVars>(EMPLOYEES_QUERY, {
    variables: {
      full_name: fullName,
      showData: 10,
    },
    fetchPolicy: 'cache-and-network',
  });

  let options: ReactNode[] = [];
  if (data && data.employees) {
    const { employees } = data.employees;
    options = employees.map((employee) => (
      <Option key={employee._id} value={employee._id}>
        {employee.full_name}
      </Option>
    ));
  }

  return (
    <Select
      allowClear
      style={{
        width: '100%',
      }}
      value={value}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      showSearch
      placeholder="ค้นหา"
      onSearch={(valueSearch) => setFullName(valueSearch)}
      onChange={(valueChange) => onChange && onChange(valueChange)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};

export default SelectEmployeeField;
