import { useState, useEffect } from 'react';
import { message } from 'antd';
import firebase from 'shared/config/firebase';
import { ReportStatus } from 'views/Reports/common/model/reportStatus.model';

type Report = {
  id: string | null;
  file: string;
  status: ReportStatus;
};

const useGetReportStatus = () => {
  const [reports, setReports] = useState<Report[]>();

  const fetchReport = () => {
    try {
      firebase
        .database()
        .ref(`/report/`)
        .on('value', (snapshot) => {
          const reportsVal: Report[] = [];
          snapshot.forEach((child) => {
            const val = child.val();
            reportsVal.push({ id: child.key, status: val.status, file: val.file });
          });

          setReports([...reportsVal]);
        });
    } catch (error) {
      message.error(error as any);
      setReports([]);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return {
    reportsStatus: reports,
  };
};

export default useGetReportStatus;
