import gql from 'graphql-tag';

export interface CreatePaymentCodData {
  createPaymentCod: {
    _id: string;
  };
}

export interface CreatePaymentCodVars {
  customer?: string;
  cod?: string[];
  payment_cod_remark?: string;
}

export default gql`
  mutation CreatePaymentCod($customer: ID, $cod: [ID], $payment_cod_remark: String) {
    createPaymentCod(
      createPaymentCod: { customer: $customer, cod: $cod, payment_cod_remark: $payment_cod_remark }
    ) {
      _id
    }
  }
`;
