import React from 'react';
import { Container, LeftContainer, RightContainer, TextContent, TextTitle } from './Styles';

interface CardIconProps {
  title: string;
  text: string | React.ReactNode;
  icon: React.ReactNode;
}

const CardIcon: React.FC<CardIconProps> = (props) => {
  const { title, text, icon, ...otherProps } = props;
  return (
    <Container {...otherProps}>
      <LeftContainer>{icon}</LeftContainer>
      <RightContainer>
        <TextTitle>{title}</TextTitle>
        <TextContent>{text}</TextContent>
      </RightContainer>
    </Container>
  );
};

export default CardIcon;
