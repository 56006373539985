import gql from 'graphql-tag';
import { VendorStatus } from 'views/Setting/common/model/vendorStatus.model';
import { VendorType } from 'views/Setting/common/model/vendorType.model';

export interface Vendor {
  _id: string;
  vendor_type: VendorType;
  number: string;
  full_name: string;
  tax_id: string;
  phone: string[];
  last_update: string[];
}

export interface Vendors {
  vendors: Vendor[];
  totalDocument: number;
}

export interface VendorsData {
  vendors: Vendors;
}

export interface VendorsVars {
  vendor_type?: VendorType;
  number?: string;
  full_name?: string;
  tax_id?: string;
  phone?: string;
  showData?: number;
  page?: number;
  order_by?: string;
  order_type?: '1' | '-1' | '';
  vendor_status?: VendorStatus;
}

export default gql`
  query Vendors(
    $vendor_type: Int
    $number: String
    $full_name: String
    $tax_id: String
    $phone: String
    $order_by: String
    $order_type: String
    $showData: Int
    $page: Int
    $vendor_status: Int
  ) {
    vendors(
      params: {
        vendor_type: $vendor_type
        number: $number
        full_name: $full_name
        tax_id: $tax_id
        phone: $phone
        vendor_status: $vendor_status
      }
      sort: { order_by: $order_by, order_type: $order_type }
      showData: $showData
      page: $page
    ) {
      vendors {
        _id
        vendor_type
        number
        full_name
        tax_id
        phone
        last_update
      }
      totalDocument
    }
  }
`;
