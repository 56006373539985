import gql from 'graphql-tag';

export interface Payee {
  _id: string;
  full_name: string;
}

export interface Payment {
  _id: string;
  create_date: string;
  payment_number: string;
  number_ref: string;
  grand_total_price: number;
}

export interface PaymentVoucher {
  _id: string;
  voucher_number: string;
  grand_total_price: number;
  payee: Payee;
  payment: Payment[];
  round_paid: string;
  create_date: string;
}

export interface AllWht {
  wht: number;
  price: number;
  total_wht: number;
}

export interface BuildApp {
  grand_total_price: number;
  payment_voucher: PaymentVoucher[];
  app_remark?: string;
  re_total_before_discount: number;
  non_total_before_discount: number;
  inc_total_before_discount: number;
  re_total_discount: number;
  non_total_discount: number;
  inc_total_discount: number;
  re_total_after_discount: number;
  non_total_after_discount: number;
  inc_total_after_discount: number;
  total_vat: number;
  re_total: number;
  non_total: number;
  inc_total: number;
  total_price: number;
  all_wht: AllWht[];
}

export interface BuildAppData {
  buildApp: BuildApp;
}

export interface BuildAppVars {
  payment_voucher?: string[];
}

export default gql`
  query BuildApp($payment_voucher: [ID]) {
    buildApp(createApp: { payment_voucher: $payment_voucher }) {
      grand_total_price
      payment_voucher {
        _id
        voucher_number
        grand_total_price
        payee {
          _id
          full_name
        }
        payment {
          _id
          create_date
          payment_number
          number_ref
          grand_total_price
        }
        round_paid
        create_date
      }
      app_remark
      re_total_before_discount
      non_total_before_discount
      inc_total_before_discount
      re_total_discount
      non_total_discount
      inc_total_discount
      re_total_after_discount
      non_total_after_discount
      inc_total_after_discount
      total_vat
      re_total
      non_total
      inc_total
      total_price
      all_wht {
        wht
        price
        total_wht
      }
      grand_total_price
    }
  }
`;
