/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useContext, ReactNode } from 'react';
import { withRouter, RouteComponentProps, useRouteMatch, Link } from 'react-router-dom';
import { Input, DatePicker, message, Row, Col, Select, Popover } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@apollo/react-hooks';
import { formatDateTime } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import COD_ITEMS_QUERY, {
  CodItemsData,
  CodItemsVars,
  CodItems,
} from 'shared/graphql/query/newCodItems';
import {
  CodContext,
  SelectedCodItemDetailType,
  SelectedCodItemType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/context/CodContext';
import { Text, Button, SelectCustomersField, TableSearch } from 'shared/components';
import { CodItemStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/model/codItemStatus.model';
import { ToolsContainer, StyledCheckbox } from './Styles';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface CodItemListTableProps extends RouteComponentProps {
  status: CodItemStatus;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  currentPage: number;
  onChangeCurrentPage: (currentPage: number) => void;
}

type CodItemListDataTable = {
  key: number;
  tools: ReactNode;
  column1: string;
  column2: string;
  column3: ReactNode;
  column4: ReactNode;
  column5: string;
  column6: string;
  column7: string;
  column8: string;
  column9: string;
};

const CodItemListTable: React.FC<CodItemListTableProps> = (props) => {
  const { status, pageSize, onChangePageSize, currentPage, onChangeCurrentPage } = props;
  const [customerId, setCustomerId] = useState<string>('');
  const [codInNumber, setCodInNumber] = useState<string>('');
  const [shipmentNumber, setShipmentNumber] = useState<string>('');
  const [dueDate, setDueDate] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<'1' | '-1' | ''>('');

  const { selectedCodItems, setSelectedCodItems, setSelectedCodItemDetail } = useContext(
    CodContext,
  );

  const { path } = useRouteMatch();

  const { loading, error, data } = useQuery<CodItemsData, CodItemsVars>(COD_ITEMS_QUERY, {
    variables: {
      status,
      customer: customerId,
      cod_in_number: codInNumber,
      shipment_numbers: shipmentNumber,
      due_date: dueDate,
      order_by: orderBy,
      order_type: orderType,
      page: currentPage,
      showData: pageSize,
    },
    fetchPolicy: 'cache-and-network',
  });

  const selectCodItems = (item: CodItems, checked: boolean, value: string) => {
    if (checked) {
      if (selectedCodItems.codItemsId.length === 0) {
        setSelectedCodItems(
          (prevState): SelectedCodItemType => ({
            ...prevState,
            codItemsId: [],
            customerId: item.customer?._id,
            customerFullName: item.customer?.full_name,
            customerAddress: item.customer?.address,
            customerTaxId: item.customer?.tax_id,
            customerPhone: item.customer?.phone,
            customerEmail: item.customer?.email,
          }),
        );
      }

      setSelectedCodItems(
        (prevState): SelectedCodItemType => ({
          ...prevState,
          codItemsId: [...prevState.codItemsId, value],
        }),
      );
    } else {
      const codItemsIdFilter = selectedCodItems.codItemsId.filter(
        (codItemId) => codItemId !== value,
      );
      setSelectedCodItems(
        (prevState): SelectedCodItemType => ({
          ...prevState,
          codItemsId: [...codItemsIdFilter],
        }),
      );
    }
  };

  const contentPopOverShipmentNumber = (value: string[]) => {
    return (
      <>
        <Row>
          <Col>
            <Text>Shipment No: </Text>
          </Col>
        </Row>
        {value.map((item) => (
          <Row key={uuidv4()}>
            <Col>
              <Text strong>{item}</Text>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const popOverShipmentNumber = (value: string[]) => {
    if (value.length === 1) {
      return <Text>{value[0]}</Text>;
    }
    if (value.length > 1) {
      return (
        <Popover placement="rightTop" content={contentPopOverShipmentNumber(value)}>
          <div style={{ display: 'inline' }}>
            <Text>{value.length} รหัส</Text>
          </div>
        </Popover>
      );
    }
    return '-';
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      width: 80,
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'column1',
      width: 300,
      search: <SelectCustomersField onChange={(value: string) => setCustomerId(value)} />,
    },
    {
      title: 'รหัส COD-IN',
      dataIndex: 'column2',
      sortName: 'cod_in_number',
      width: 150,
      search: (
        <Input
          allowClear
          value={codInNumber}
          placeholder="ค้นหา"
          onChange={(event) => setCodInNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'ยอดรวม COD (บาท)',
      dataIndex: 'column3',
      sortName: 'total_cod',
      width: 150,
      align: 'right',
    },
    {
      title: 'Shipment Number',
      dataIndex: 'column4',
      width: 150,
      search: (
        <Input
          allowClear
          value={shipmentNumber}
          placeholder="ค้นหา"
          onChange={(event) => setShipmentNumber(event.target.value)}
        />
      ),
    },
    {
      title: 'วันที่ต้องทำจ่าย',
      dataIndex: 'column5',
      width: 150,
      search: (
        <RangePicker
          onChange={(values) => {
            const formatRangDate =
              values === null
                ? ''
                : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

            setDueDate(formatRangDate);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
        />
      ),
    },
    {
      title: 'วันที่แจ้งโอน',
      dataIndex: 'column6',
      width: 150,
    },
    {
      title: 'ผู้ตรวจสอบ',
      dataIndex: 'column7',
      width: 150,
    },
    ...(status === CodItemStatus.PAID
      ? [
          {
            title: 'รหัสCOD-OUT',
            dataIndex: 'column8',
            sortName: 'cod_out_number',
            width: 150,
          },
        ]
      : []),
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'column9',
      sortName: 'last_update',
      width: 150,
    },
  ];

  let codItemListData: CodItemListDataTable[] = [];
  let totalDocument = 0;

  if (data?.newCodItems) {
    const { codItems } = data.newCodItems;
    codItemListData = codItems.map(
      (item, index): CodItemListDataTable => ({
        key: index,
        tools: (
          <ToolsContainer>
            {status === CodItemStatus.WAIT_TO_PAY && (
              <StyledCheckbox
                checked={selectedCodItems.codItemsId.indexOf(item._id) !== -1}
                disabled={
                  selectedCodItems?.codItemsId.length > 0 &&
                  item.customer?._id !== selectedCodItems?.customerId
                }
                value={item._id}
                onChange={(event) => selectCodItems(item, event.target.checked, event.target.value)}
              />
            )}
            <Link to={`${path}/detail-item/${item._id}`}>
              <Button
                onClick={() => {
                  setSelectedCodItemDetail(
                    (prevState): SelectedCodItemDetailType => ({
                      ...prevState,
                      codItemId: item._id,
                      codItemStatus: item.status,
                      codNumber: item.cod?.cod_in_number,
                    }),
                  );
                }}
                color="grey--text-only"
                icon={<SearchOutlined />}
                size="small"
              />
            </Link>
          </ToolsContainer>
        ),
        column1: item.customer?.full_name || '-',
        column2: item.cod?.cod_in_number || '-',
        column3: (
          <Text strong customType="primary">
            {formatCurrency(item.total_cod)}
          </Text>
        ),
        column4: popOverShipmentNumber(
          item.shipment_list.map((shipment) => shipment.shipment_number),
        ),
        column5: item.due_date ? formatDateTime(item.due_date) : '-',
        column6: item.cod_in_date ? formatDateTime(item.cod_in_date) : '-',
        column7: item.ops_employee?.full_name || '-',
        column8: item.payment_cod?.cod_out_number || '-',
        column9: item.last_update ? formatDateTime(item.last_update) : '-',
      }),
    );

    totalDocument = data.newCodItems.totalDocument;
  }

  if (error) {
    message.error(error.message);
  }

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ borderBottom: '1px solid #e3e3e3', height: 60 }}
      >
        <Col>
          <Select defaultValue="all" style={{ width: 180 }}>
            <Option value="all">รายการทั้งหมด ({totalDocument})</Option>
          </Select>
        </Col>
        <Col>
          แสดง{' '}
          <Select
            onChange={(value) => {
              onChangeCurrentPage(1);
              onChangePageSize(value);
            }}
            value={pageSize}
            style={{ width: 80 }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Col>
      </Row>

      <TableSearch
        columns={columns}
        dataSource={codItemListData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalDocument,
          showSizeChanger: false,
          onChange: (value: number) => onChangeCurrentPage(value),
        }}
        onSort={(sort) => {
          setOrderBy(sort.orderBy);
          setOrderType(sort.orderType);
        }}
      />
    </>
  );
};

export default withRouter(CodItemListTable);
