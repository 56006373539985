import React from 'react';
import { Link, useLocation, useRouteMatch, matchPath } from 'react-router-dom';
import { Menu } from 'antd';
import classNames from 'classnames';
import Icon from '@ant-design/icons';
import IconFileAltSolid from 'shared/assets/icons/file-alt-solid.svg';
import IconUsdCircle from 'shared/assets/icons/usd-circle.svg';
import IconHandHoldingUsdSolid from 'shared/assets/icons/hand-holding-usd-solid.svg';
import IconHandsUsdSolid from 'shared/assets/icons/hands-usd-solid.svg';
import IconFileExcelSolid from 'shared/assets/icons/file-excel-solid.svg';
import IconUserCogSolid from 'shared/assets/icons/user-cog-solid.svg';
import IconUserSolid from 'shared/assets/icons/user-solid.svg';
import IconBoxUsdSolid from 'shared/assets/icons/box-usd-solid.svg';
import IconMoneyCheckAltSolid from 'shared/assets/icons/money-check-alt-solid.svg';

const LinkMenu = () => {
  const location = useLocation();
  const match = useRouteMatch();

  const routes = [
    {
      name: 'ต้นทุน/ขาย',
      link: '/ct',
      icon: IconUsdCircle,
      subMenu: [
        {
          name: 'เหมาคัน (FTL)',
          link: '/ct/ftl',
          icon: IconBoxUsdSolid,
        },
        {
          name: 'รายชิ้น (Parcel)',
          link: '/ct/parcel',
          icon: IconBoxUsdSolid,
        },
      ],
    },
    {
      name: 'ราคาขายต่อชิ้น',
      link: '/costing-parcel',
      icon: IconBoxUsdSolid,
    },
    {
      name: 'การเก็บเงินปลายทาง (COD)',
      link: '/cod',
      icon: IconFileAltSolid,
      subMenu: [
        {
          name: 'COD by Parcel',
          link: '/cod/by-parcel',
          icon: IconBoxUsdSolid,
        },
        {
          name: 'COD by Shipment',
          link: '/cod/by-shipment',
          icon: IconBoxUsdSolid,
        },
        {
          name: 'COD by Booking',
          link: '/cod/by-booking',
          icon: IconBoxUsdSolid,
        },
      ],
    },
    {
      name: 'บัญชีรายจ่าย',
      link: '/ap',
      icon: IconHandHoldingUsdSolid,
    },
    {
      name: 'บัญชีรายรับ',
      link: '/ar',
      icon: IconHandsUsdSolid,
    },
    {
      name: 'การติดตามหนี้',
      link: '/debt-tracker',
      icon: IconMoneyCheckAltSolid,
    },
    {
      name: 'รายงาน / เอกสารสรุป',
      link: '/reports',
      icon: IconFileExcelSolid,
    },
    {
      name: 'การตั้งค่าบัญชี',
      link: '/setting',
      icon: IconUserCogSolid,
      subMenu: [
        {
          name: 'บัญชีลูกหนี้',
          link: '/setting/customer',
          icon: IconUserSolid,
        },
        {
          name: 'บัญชีเจ้าหนี้',
          link: '/setting/creditor',
          icon: IconUserSolid,
        },
        {
          name: 'ผังบัญชี',
          link: '/setting/chart-of-accounts',
          icon: IconUserSolid,
        },
      ],
    },
  ];

  const checkLinkActiveClass = (path) => {
    return !!matchPath(location.pathname, `${path}`);
  };

  const menuList = routes.map((item) => {
    if (item.subMenu && item.subMenu.length >= 1) {
      return (
        <Menu.SubMenu key={`${item.link}`} icon={<Icon component={item.icon} />} title={item.name}>
          {item.subMenu.map((subMenu) => (
            <Menu.Item
              key={subMenu.link}
              className={classNames({
                'ant-menu-item-selected': checkLinkActiveClass(subMenu.link),
              })}
            >
              <Link to={subMenu.link}>
                <Icon component={subMenu.icon} />
                <span>{subMenu.name}</span>
              </Link>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      );
    }

    return (
      <Menu.Item key={item.link}>
        <Link to={item.link}>
          <Icon component={item.icon} />
          <span>{item.name}</span>
        </Link>
      </Menu.Item>
    );
  });
  return (
    <Menu
      mode="inline"
      theme="dark"
      defaultSelectedKeys={[match.path]}
      defaultOpenKeys={[match.path]}
    >
      {menuList}
    </Menu>
  );
};

export default LinkMenu;
