/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import UPDATE_PAYMENT_COD_MUTATION, {
  UpdatePaymentCodData,
  UpdatePaymentCodVars,
} from 'shared/graphql/mutation/updatePaymentCod';
import formatCurrency from 'shared/utils/formatCurrency';
import { Button, Text } from 'shared/components';
import { formatDate } from 'shared/utils/dateTime';
import { StyledModal } from './Styles';

type ModalConfirmCreateCodProps = {
  paymentCodId: string;
  cods: {
    id: string;
    customerFullName: string;
    codInNumber: string;
    pickUpDate: string;
    bookingNumber: string;
    totalCod: number;
  }[];
  visible: boolean;
  onCancel: () => void;
};

const ModalConfirmAddCod: React.FC<ModalConfirmCreateCodProps> = (props) => {
  const { onCancel, visible, cods, paymentCodId } = props;
  const [updatePaymentCod, { loading }] = useMutation<UpdatePaymentCodData, UpdatePaymentCodVars>(
    UPDATE_PAYMENT_COD_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.updatePaymentCod._id) {
          message.success('เพิ่มรายการ COD แล้ว');
          onCancel();
        }
      },
      onError: (err) => {
        message.error(err.message);
      },
      refetchQueries: () => ['PaymentCod'],
    },
  );

  const confirmUpdate = () => {
    updatePaymentCod({
      variables: {
        _id: paymentCodId,
        cod: cods.map((cod) => cod.id),
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันเพิ่มรายการ COD หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmUpdate()}
          loading={loading}
        >
          เพิ่มรายการ COD
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          ทั้งหมด <Text strong>{`${cods.length} รายการ`}</Text>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 0]}>
            <Col span={4}>ชื่อลูกค้า</Col>
            <Col span={5}>รหัส COD-IN</Col>
            <Col span={5}>วันที่รับสินค้า</Col>
            <Col span={5}>Booking Number</Col>
            <Col span={4}>ยอดรวม COD (บาท)</Col>
          </Row>

          {cods.map((cod) => (
            <Row gutter={[8, 0]} key={cod.codInNumber}>
              <Col span={4}>
                <Text strong>{cod.customerFullName || '-'}</Text>
              </Col>
              <Col span={5}>
                <Text strong>{cod.codInNumber || '-'}</Text>
              </Col>
              <Col span={5}>
                <Text strong>{cod.pickUpDate ? formatDate(cod.pickUpDate) : '-'}</Text>
              </Col>
              <Col span={5}>
                <Text strong>{cod.bookingNumber || '-'}</Text>
              </Col>
              <Col span={4}>
                <Text strong customType="primary">
                  {formatCurrency(cod.totalCod)}
                </Text>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalConfirmAddCod;
