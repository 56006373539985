import React from 'react';
import formatCurrency from 'shared/utils/formatCurrency';
import { InvoiceDetail } from 'shared/graphql/query/billingNote';
import { formatDate, formatDateTime } from 'shared/utils/dateTime';
import { Text } from 'shared/components';
import { StyledTable } from './Styles';

type ExpandedRowProps = {
  data: InvoiceDetail[] | null;
};

const columns = [
  {
    title: 'วันที่อนุมัติ',
    dataIndex: 'column1',
    width: 110,
  },
  {
    title: 'รหัสต้นทุน/ขาย',
    dataIndex: 'column2',
    width: 110,
  },
  {
    title: 'รหัสงานขนส่ง',
    dataIndex: 'column3',
    width: 110,
  },
  {
    title: 'วันที่งานเสร็จสิ้น',
    dataIndex: 'column4',
    width: 180,
  },
  {
    title: 'ราคาขาย',
    dataIndex: 'column5',
    align: 'right',
    width: 110,
  },
  {
    title: '',
  },
];

const ExpandedRow: React.FC<ExpandedRowProps> = (props) => {
  const { data } = props;
  const invoiceDetail = data?.map((item, index) => {
    return {
      key: index,
      column1: item?.costing?.checked_date ? formatDate(item.costing.checked_date) : '-',
      column2: item?.costing?.costing_number || '-',
      column3: item?.number_ref || '-',
      column4: item?.costing?.completed_date ? formatDateTime(item.costing.completed_date) : '-',
      column5: (
        <Text customType="primary" strong>
          {formatCurrency(item.grand_total_price, '-')}
        </Text>
      ),
    };
  });

  return (
    <div style={{ marginTop: 10 }}>
      <StyledTable
        scroll={{ x: 'max-content' }}
        pagination={false}
        columns={columns}
        dataSource={invoiceDetail}
        size="small"
      />
    </div>
  );
};

export default ExpandedRow;
