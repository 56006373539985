/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react';
import { ModalTopButton, Text } from 'shared/components';
import { Row, Col, Divider, Skeleton, Spin, Tabs, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { formatDate } from 'shared/utils/dateTime';
import formatCurrency from 'shared/utils/formatCurrency';
import { businessInvoiceType } from 'shared/constants/businessInvoiceType';
import { useQuery } from '@apollo/react-hooks';
import PARCEL_DETAIL_QUERY, {
  ParcelDetailData,
  ParcelDetailVars,
} from 'shared/graphql/query/parcelDetail';
import { color } from 'shared/utils/styles';
import { CheckCircleOutlined } from '@ant-design/icons';
import TagStatus from '../TagStatus';
import Table from './Table';
import ModalCancel from './ModalCancel';
import { ParcelDetailStatus } from '../ParcelDetailStatus';
import ModalCreate from './ModalCreate';

interface ModalParcelDetailProps {
  parcelDetailId: string;
  goBack: string;
}

const ModalParcelDetail: React.FC<ModalParcelDetailProps> = (props) => {
  const { parcelDetailId, goBack } = props;
  const history = useHistory();
  const [isOpenModalCancel, setIsOpenModalCancel] = useState<boolean>(false);
  const [isOpenModalCreate, setIsOpenModalCreate] = useState<boolean>(false);

  const { loading, error, data } = useQuery<ParcelDetailData, ParcelDetailVars>(
    PARCEL_DETAIL_QUERY,
    {
      variables: {
        _id: parcelDetailId,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  let content = <Skeleton />;

  if (data?.parcelDetail) {
    const {
      number,
      customer,
      invoice,
      receipt,
      createdAt,
      billing_note,
      status,
      parcels,
    } = data.parcelDetail;

    content = (
      <>
        <ModalTopButton
          visible
          onCancel={() => {
            history.push(goBack);
          }}
          title={`รหัสใบแจ้งหนี้: ${invoice?.invoice_number || '-'}`}
          width={1200}
          btnHeader={[
            {
              text: 'ยกเลิกการใช้',
              clicked: () => setIsOpenModalCancel(true),
              color: 'red',
              // icon: <CheckCircleOutlined />,
              visible: status === ParcelDetailStatus.WAIT_INVOICE,
            },
            {
              text: 'สร้างใบแจ้งหนี้',
              clicked: () => setIsOpenModalCreate(true),
              color: 'green',
              visible: status === ParcelDetailStatus.WAIT_INVOICE,
            },
          ]}
        >
          <>
            <Text strong>เงื่อนไขใบแจ้งหนี้</Text>
            <Divider style={{ margin: '10px 0' }} />
            <Row gutter={[32, 16]}>
              <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        ลูกค้า <Text customType="grey">(เจ้าหนี้)</Text>:
                      </Col>
                      <Col span={24}>
                        <Select defaultValue="" style={{ width: '100%' }} disabled>
                          <Select.Option value="">
                            <Text strong>{customer?.full_name || '-'}</Text>
                          </Select.Option>
                        </Select>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>รูปแบบรับใบแจ้งหนี้:</Col>
                      <Col span={16}>
                        <Text strong>
                          {businessInvoiceType(invoice?.business_invoice_type) || '-'}
                        </Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>วันครบกำหนด:</Col>
                      <Col span={16}>
                        <Text strong>{invoice?.due_date ? formatDate(invoice.due_date) : '-'}</Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>วันที่ชำระ:</Col>
                      <Col span={16}>
                        <Text strong>
                          {receipt?.receipt_date ? formatDate(receipt.receipt_date) : '-'}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
                <Row>
                  <Col span={10}>ประเภทงาน:</Col>
                  <Col span={14}>
                    <Text strong>{invoice?.system_request || '-'}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>ที่อยู่</Col>
                  <Col span={14}>
                    <Text strong>{customer?.address || '-'}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>เลขประจำตัวผู้เสียภาษี:</Col>
                  <Col span={14}>
                    <Text strong>{customer?.tax_id || '-'}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>เบอร์โทรศัพท์:</Col>
                  <Col span={14}>
                    <Text strong>
                      {customer?.phone.length > 0 ? customer.phone.join(', ') : '-'}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>อีเมล:</Col>
                  <Col span={14}>
                    <Text strong>
                      {customer?.email.length > 0 ? customer.email.join(', ') : '-'}
                    </Text>
                  </Col>
                </Row>
              </Col>

              <Col span={8}>
                <Row>
                  <Col span={8}>วันที่เอกสาร:</Col>
                  <Col span={16}>
                    <Text strong>{createdAt ? formatDate(createdAt) : '-'}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>รหัสใบแจ้งหนี้:</Col>
                  <Col span={16}>
                    <Text strong>{invoice?.invoice_number || '-'}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>มูลค่าใบแจ้งหนี้:</Col>
                  <Col span={16}>
                    <Text strong customType="primary">
                      {invoice?.grand_total_price ? formatCurrency(invoice.grand_total_price) : '-'}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>สถานะ:</Col>
                  <Col span={16}>
                    <TagStatus status={status} />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>รหัสใบวางบิล:</Col>
                  <Col span={16}>
                    <Text>{billing_note?.bill_number || '-'}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>รหัสใบเสร็จ/ใบกำกับ:</Col>
                  <Col span={16}>
                    <Text>{receipt?.receipt_number || '-'}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Divider style={{ margin: 0, marginTop: -5 }} />

            <div style={{ position: 'absolute', textAlign: 'right', marginTop: 10, right: 20 }}>
              <Text>
                <Text strong>ทั้งหมด:</Text> {parcels.length} รายการ
              </Text>
            </div>

            <Tabs defaultActiveKey="1" animated={false}>
              <Tabs.TabPane
                tab={
                  <span>
                    รายละเอียดงาน{' '}
                    <CheckCircleOutlined
                      style={{
                        color: color.success,
                        marginLeft: 5,
                      }}
                    />
                  </span>
                }
                key="1"
              >
                <Table data={data} loading={loading} />
              </Tabs.TabPane>
            </Tabs>
          </>
        </ModalTopButton>

        <ModalCreate
          visible={isOpenModalCreate}
          onCancel={() => setIsOpenModalCreate(false)}
          goBack={goBack}
          id={parcelDetailId}
          number={number || '-'}
          grandTotalPrice={
            invoice?.grand_total_price ? formatCurrency(invoice.grand_total_price) : '-'
          }
        />
        <ModalCancel
          visible={isOpenModalCancel}
          onCancel={() => setIsOpenModalCancel(false)}
          goBack={goBack}
          id={parcelDetailId}
        />
      </>
    );
  }

  if (error) {
    content = <div>{error.message}</div>;
  }

  return <Spin spinning={loading}>{content}</Spin>;
};

export default ModalParcelDetail;
