import styled from 'styled-components';
import { font } from 'shared/utils/styles';

export const Container = styled.a`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid #e9eff4;
  background-color: white;
  line-height: 1;

  &:hover,
  &:focus {
    background-color: #fafbfc;
  }
`;

export const IconContainer = styled.div`
  font-size: 30px;
  color: #b0bcc6;
  margin-right: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const FileDetail = styled.div`
  ${font.medium}
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ImagePreview = styled.div`
  & .ant-avatar {
    border-radius: 3px;
    border: 1px solid #e9eff4;
    background-color: #e9eff4;
  }
`;
