import React, { useState, ReactChild } from 'react';
import { CodInStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/model/codInStatus.model';
import { CodItemStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/model/codItemStatus.model';

export interface SelectedCodInDetailType {
  codInId: string;
  isOpenModal: boolean;
  isLoading: boolean;
  codInStatus: CodInStatus | null;
}

export interface SelectedCodItemType {
  codItemId: string;
  codItemStatus: CodItemStatus | null;
  isOpenModal: boolean;
  isLoading: boolean;
  vendorFullName: string;
  customerFullName: string;
  bookingNumber: string;
  codInDate: string;
  document: string[];
  totalCod: number;
  remark: string;
  rejectRemark: string;
  isUpdateChecked: boolean;
  modalCodInCompleted: {
    customerFullName: string;
    codInNumber: string;
    bookingNumber: string;
    totalCod: number;
    totalVendor: number;
    confirmStatus: number;
  };
}

export interface SelectedCodsInType {
  codsInId: string[];
  isOpenModal: boolean;
  isLoading: boolean;
  customerId: string;
  customerFullName: string;
  modalConfirmCod: {
    codsIn: {
      customerFullName: string;
      codInNumber: string;
      pickUpDate: string;
      bookingNumber: string;
      totalCod: number;
    }[];
  };
}

interface CodIn {
  selectedCodInDetail: SelectedCodInDetailType;
  setSelectedCodInDetail: (
    params: (cb: SelectedCodInDetailType) => SelectedCodInDetailType,
  ) => void;
  selectedCodItem: SelectedCodItemType;
  setSelectedCodItem: (params: (cb: SelectedCodItemType) => SelectedCodItemType) => void;
  selectedCodsIn: SelectedCodsInType;
  setSelectedCodsIn: (params: (cb: SelectedCodsInType) => SelectedCodsInType) => void;
}

type CodInProps = {
  children: ReactChild;
};

const CodInContext = React.createContext({} as CodIn);

const CodInProvider: React.FC<CodInProps> = (props) => {
  const { children } = props;
  const [selectedCodInDetail, setSelectedCodInDetail] = useState<SelectedCodInDetailType>({
    codInId: '',
    isOpenModal: false,
    isLoading: true,
    codInStatus: null,
  });

  const [selectedCodItem, setSelectedCodItem] = useState<SelectedCodItemType>({
    codItemId: '',
    codItemStatus: null,
    isOpenModal: false,
    isLoading: true,
    vendorFullName: '',
    customerFullName: '',
    bookingNumber: '',
    codInDate: '',
    document: [],
    totalCod: 0,
    remark: '',
    rejectRemark: '',
    modalCodInCompleted: {
      customerFullName: '',
      codInNumber: '',
      bookingNumber: '',
      totalCod: 0,
      totalVendor: 0,
      confirmStatus: 0,
    },
    isUpdateChecked: false,
  });

  const [selectedCodsIn, setSelectedCodsIn] = useState<SelectedCodsInType>({
    codsInId: [],
    isLoading: false,
    isOpenModal: false,
    customerId: '',
    customerFullName: '',
    modalConfirmCod: {
      codsIn: [],
    },
  });

  const store = {
    selectedCodInDetail,
    setSelectedCodInDetail,
    selectedCodItem,
    setSelectedCodItem,
    selectedCodsIn,
    setSelectedCodsIn,
  };

  return <CodInContext.Provider value={store}>{children}</CodInContext.Provider>;
};

export { CodInContext, CodInProvider };
