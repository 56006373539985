import gql from 'graphql-tag';
import { PaymentVoucherStatus } from 'views/AccountPayment/common/model/paymentVoucherStatus.model';

export interface PaymentVoucherData {
  _id: string;
  voucher_number: string;
  payee: {
    _id: string;
    full_name: string;
    address: string;
    sub_district: string;
    district: string;
    province: string;
    postcode: string;
    full_address: string;
    email: string[];
    tax_id: string;
    phone: string[];
  };
  create_date: string;
  payment_method: string;
  voucher_status: PaymentVoucherStatus;
  grand_total_price: number;
  payment: {
    _id: string;
    create_date: string;
    payment_number: string;
    round_paid: string;
    number_ref: string;
    grand_total_price: number;
    employee: {
      full_name: string;
    };
    payment_list: {
      _id: string;
      list_name: string;
      list_number: string;
      qty: number;
      price: number;
      total_before_discount: number;
      discount: number;
      total_price: number;
      vat: number;
      wht: number;
      unit: string;
      advance_type: boolean;
    }[];
    re_total: number;
    non_total: number;
    inc_total: number;
    total_price: number;
    all_wht: {
      wht: number;
      price: number;
      total_wht: number;
    }[];
    payment_type: string;
  }[];
  re_total_before_discount: number;
  non_total_before_discount: number;
  inc_total_before_discount: number;
  re_total_discount: number;
  non_total_discount: number;
  inc_total_discount: number;
  re_total_after_discount: number;
  non_total_after_discount: number;
  inc_total_after_discount: number;
  total_vat: number;
  re_total: number;
  non_total: number;
  inc_total: number;
  total_price: number;
  all_wht: {
    wht: number;
    price: number;
    total_wht: number;
  }[];
  voucher_remark: string;
  proof_slip: string;
  bank_account: {
    bank_name: string;
    bank_branch: string;
    account_name: string;
    account_number: string;
  };
  reject_remark: string;
  cancel_remark: string;
  save_status: number;
}

export interface PaymentVoucherVars {
  _id: string;
}

export default gql`
  query PaymentVoucher($_id: ID!) {
    paymentVoucher(_id: $_id) {
      _id
      voucher_number
      payee {
        _id
        full_name
        address
        sub_district
        district
        province
        postcode
        full_address
        email
        tax_id
        phone
      }
      create_date
      payment_method
      voucher_status
      grand_total_price
      payment {
        _id
        create_date
        payment_number
        round_paid
        number_ref
        grand_total_price
        employee {
          full_name
        }
        payment_list {
          _id
          list_name
          list_number
          qty
          price
          total_before_discount
          discount
          total_price
          vat
          wht
          unit
          advance_type
        }
        re_total
        non_total
        inc_total
        total_price
        all_wht {
          wht
          price
          total_wht
        }
        payment_type
      }
      re_total_before_discount
      non_total_before_discount
      inc_total_before_discount
      re_total_discount
      non_total_discount
      inc_total_discount
      re_total_after_discount
      non_total_after_discount
      inc_total_after_discount
      total_vat
      re_total
      non_total
      inc_total
      total_price
      all_wht {
        wht
        price
        total_wht
      }
      voucher_remark
      proof_slip
      bank_account {
        bank_name
        bank_branch
        account_name
        account_number
      }
      reject_remark
      cancel_remark
      save_status
    }
  }
`;
