/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Button } from 'shared/components';
import { RouteComponentProps, Link } from 'react-router-dom';
import { JvStatus } from 'views/Setting/common/model/jvStatus.model';
import { Container } from './Styles';
import JvTable from './JvTable';
import JvTab from './JvTab';

const JvList: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;
  const [tab, setTab] = useState<JvStatus>(JvStatus.USE);
  const [tabName, setTabName] = useState<'jv'>('jv');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <Container>
      <Row align="middle" style={{ borderBottom: '1px solid #e3e3e3' }}>
        <Col span={18}>
          <Row>
            <Col>
              <JvTab
                tab={tab}
                name={tabName}
                onChange={(value) => {
                  setTab(value);
                  setTabName('jv');
                  setCurrentPage(1);
                }}
              />
            </Col>
          </Row>
        </Col>

        <Col span={6}>
          <Row justify="end">
            <Col>
              <Link to={`${match.path}/create`}>
                <Button color="green">สร้างเอกสาร JV</Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      {tabName === 'jv' && (
        <JvTable
          pageSize={pageSize}
          onChangePageSize={(value) => setPageSize(value)}
          currentPage={currentPage}
          onChangeCurrentPage={(value) => setCurrentPage(value)}
        />
      )}
    </Container>
  );
};

export default JvList;
