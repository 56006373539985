/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  InvoiceContext,
  SelectedInvoiceDetailType,
} from 'views/AccountReceive/common/context/InvoiceContext';
import INVOICE_QUERY, { InvoiceData, InvoiceVars } from 'shared/graphql/query/invoice';
import { Spin } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { message } from 'antd';
import { FormValues } from 'views/AccountReceive/Routes/Invoice/InvoicesTable/ModalInvoiceDetail/model/formValues.model';
import Header from './Header';
import InvoiceDetail from './InvoiceDetail';
import { StyledModal } from './Styles';

const validateSchema = yup.object().shape({
  invoiceId: yup.array().required(),
  payerId: yup.string().required(),
  payerFullName: yup.string().required(),
  invoiceRemark: yup.string().nullable(),
  cancelRemark: yup.string().nullable(),
});

const ModalInvoiceDetailWrapper: React.FC = () => {
  const { setSelectedInvoiceDetail, selectedInvoiceDetail } = useContext(InvoiceContext);
  const { isOpenModal } = selectedInvoiceDetail;

  return (
    <StyledModal
      visible={isOpenModal}
      onCancel={() => {
        setSelectedInvoiceDetail(
          (prevState): SelectedInvoiceDetailType => ({
            ...prevState,
            isOpenModal: false,
          }),
        );
      }}
      closable={false}
      className="custom-modal"
      footer={null}
      destroyOnClose
    >
      <ModalInvoiceDetail />
    </StyledModal>
  );
};

const ModalInvoiceDetail: React.FC = () => {
  const { selectedInvoiceDetail, setSelectedInvoiceDetail } = useContext(InvoiceContext);
  const { isLoading, invoiceId } = selectedInvoiceDetail;
  const { data, loading, error } = useQuery<InvoiceData, InvoiceVars>(INVOICE_QUERY, {
    variables: {
      _id: invoiceId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      invoiceId: [],
      payerId: '',
      payerFullName: '',
      invoiceRemark: '',
      cancelRemark: '',
    },
  });

  const { register, handleSubmit, reset } = methods;

  useEffect(() => {
    setSelectedInvoiceDetail(
      (prevState): SelectedInvoiceDetailType => ({
        ...prevState,
        isLoading: loading,
      }),
    );

    if (!loading && !error && data?.invoice) {
      const {
        invoice_number,
        invoice_status,
        payer,
        business_invoice_type,
        grand_total_price,
        invoice_detail,
        document,
        invoice_remark,
        cancel_remark,
      } = data.invoice;

      setSelectedInvoiceDetail(
        (prevState): SelectedInvoiceDetailType => ({
          ...prevState,
          invoiceNumber: invoice_number,
          invoiceStatus: invoice_status,
          modalConfirmCreateInvoice: {
            payerFullName: payer.full_name,
            businessInvoiceType: business_invoice_type,
            grandTotalPrice: grand_total_price,
            costings: invoice_detail.map((invoice) => ({
              checkedDate: invoice.costing?.checked_date || '',
              costingNumber: invoice.costing?.costing_number || '',
              numberRef: invoice.number_ref,
              serviceDate: invoice.service_date,
              completedDate: invoice.costing?.completed_date || '',
              grandTotalPrice: invoice.grand_total_price || 0,
            })),
          },
          document,
        }),
      );

      setTimeout(() => {
        reset({
          payerId: payer._id,
          payerFullName: payer.full_name,
          invoiceRemark: invoice_remark,
          cancelRemark: cancel_remark,
        });
      });
    }

    return () => {
      setSelectedInvoiceDetail(
        (prevState): SelectedInvoiceDetailType => ({
          ...prevState,
          isLoading: true,
        }),
      );
    };
  }, [data?.invoice, error, loading, reset, setSelectedInvoiceDetail]);

  useEffect(() => {
    register('invoiceId');
    register('payerId');
    register('payerFullName');
  }, [register]);

  const onSubmit = () => {
    alert('submit');
  };

  return (
    <Spin spinning={isLoading}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Header />
          <InvoiceDetail />
        </form>
      </FormProvider>
    </Spin>
  );
};

export default ModalInvoiceDetailWrapper;
