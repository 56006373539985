/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, message, Row, Modal } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { Button, Text } from 'shared/components';
import styled from 'styled-components';
import PARCEL_DETAIL_CREATE_INVOICE_MUTATION, {
  ParcelDetailCreateInvoiceData,
  ParcelDetailCreateInvoiceVars,
} from 'shared/graphql/mutation/parcelDetailCreateInvoice';
import { useHistory } from 'react-router-dom';

export const StyledModal = styled(Modal)`
  &.custom-modal.ant-modal {
    width: 100% !important;
    max-width: 640px;
  }
`;

type ModalCreateProps = {
  visible: boolean;
  onCancel: () => void;
  goBack: string;
  id: string;
  number: string;
  grandTotalPrice: number | string;
};

const ModalCreate: React.FC<ModalCreateProps> = (props) => {
  const { onCancel, visible, goBack, id, number, grandTotalPrice } = props;
  const history = useHistory();

  const [parcelDetailCreateInvoice, { loading }] = useMutation<
    ParcelDetailCreateInvoiceData,
    ParcelDetailCreateInvoiceVars
  >(PARCEL_DETAIL_CREATE_INVOICE_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.parcelDetailCreateInvoice._id) {
        message.success('สร้างใบแจ้งหนี้แล้ว');
        onCancel();
        history.push(goBack);
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: () => ['ParcelDetailList', 'ParcelDetailStatus'],
  });

  const confirmCreate = () => {
    parcelDetailCreateInvoice({
      variables: {
        _id: id,
      },
    });
  };

  return (
    <StyledModal
      className="custom-modal"
      title="ยืนยันสร้างใบแจ้งหนี้หรือไม่?"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          color="green"
          onClick={() => confirmCreate()}
          loading={loading}
        >
          สร้างใบแจ้งหนี้
        </Button>,
        <Button
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
          disabled={loading}
        >
          ยกเลิก
        </Button>,
      ]}
      destroyOnClose
    >
      <Row gutter={[0, 0]}>
        <Col span={6}>
          <Text>รหัสใบแจ้งหนี้</Text>
        </Col>
        <Col span={18}>
          <Text>{number}</Text>
        </Col>
      </Row>
      <Row gutter={[0, 0]}>
        <Col span={6}>
          <Text>มูลค่าใบแจ้งหนี้</Text>
        </Col>
        <Col span={18}>
          <Text>{grandTotalPrice}</Text>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalCreate;
