import gql from 'graphql-tag';

export interface CodParcelStatusData {
  codParcelStatus: {
    waiting3PL: number;
    pending: number;
    confirm: number;
    reject: number;
    cancel: number;
  };
}

export default gql`
  query CodParcelStatus {
    codParcelStatus {
      waiting3PL
      pending
      confirm
      reject
      cancel
    }
  }
`;
