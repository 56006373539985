/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';
import { Divider, Row, Col, Tabs, Skeleton } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import formatCurrency from 'shared/utils/formatCurrency';
import { color } from 'shared/utils/styles';
import { Tag, Text, SelectFieldSearchCustomers } from 'shared/components';
import BUILD_PAYMENT_COD_QUERY, {
  BuildPaymentCodData,
  BuildPaymentCodVars,
} from 'shared/graphql/query/buildPaymentNewCod';
import {
  CodContext,
  SelectedCodItemType,
} from 'views/CashOnDelivery/Routes/CashOnDeliveryByShipment/common/context/CodContext';
import CodList from './CodList';

const { TabPane } = Tabs;

const CreatePaymentCod: React.FC = () => {
  const { selectedCodItems, setSelectedCodItems } = useContext(CodContext);

  const { data, loading, error } = useQuery<BuildPaymentCodData, BuildPaymentCodVars>(
    BUILD_PAYMENT_COD_QUERY,
    {
      variables: {
        cod_item: selectedCodItems.codItemsId,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (data?.buildPaymentNewCod) {
      const { cod_item, total_cod } = data.buildPaymentNewCod;
      setSelectedCodItems(
        (prevState): SelectedCodItemType => ({
          ...prevState,
          confirmPaymentCod: {
            totalCod: total_cod,
            codItems: cod_item.map((item) => ({
              codNumber: item.cod_in_number,
              totalCodItem: item.total_cod,
              shipmentList: item.shipment_list?.map((shipment) => ({
                shipmentNumber: shipment.shipment_number,
              })),
              codInDate: item.cod_in_date,
            })),
          },
        }),
      );
    }
  }, [data?.buildPaymentNewCod, setSelectedCodItems]);

  let content = <Skeleton loading={loading} />;

  if (data?.buildPaymentNewCod) {
    const { total_cod } = data.buildPaymentNewCod;

    content = (
      <>
        <Text strong>เงื่อนไขการทำจ่าย</Text>
        <Divider style={{ margin: '10px 0' }} />
        <Row gutter={[32, 16]}>
          <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    ผู้รับเงิน <Text customType="grey">(เจ้าหนี้)</Text>
                    <Text customType="danger">*</Text>
                  </Col>
                  <Col span={24}>
                    <SelectFieldSearchCustomers
                      value={{
                        key: selectedCodItems.customerId,
                        label: selectedCodItems.customerFullName,
                      }}
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
            <Row>
              <Col span={10}>ที่อยู่:</Col>
              <Col span={14}>
                <Text strong>{selectedCodItems.customerAddress || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เลขประจำตัวผู้เสียภาษี:</Col>
              <Col span={14}>
                <Text strong>{selectedCodItems.customerTaxId || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เบอร์โทรศัพท์:</Col>
              <Col span={14}>
                <Text strong>
                  {selectedCodItems.customerPhone.length === 0
                    ? '-'
                    : selectedCodItems.customerPhone.join(', ')}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>อีเมล:</Col>
              <Col span={14}>
                <Text strong>
                  {selectedCodItems.customerEmail.length === 0
                    ? '-'
                    : selectedCodItems.customerEmail.join(', ')}
                </Text>
              </Col>
            </Row>
          </Col>

          <Col span={8}>
            <Row>
              <Col span={8}>รหัส COD-OUT:</Col>
              <Col span={16}>
                <Text strong>-</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>มูลค่า CODรวม (บาท):</Col>
              <Col span={16}>
                <Text strong customType="primary" style={{ lineHeight: 1, fontSize: 24 }}>
                  {total_cod ? formatCurrency(total_cod) : '-'}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>สถานะ:</Col>
              <Col span={16}>
                <Tag customColor="grey">กำลังสร้าง...</Tag>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider style={{ margin: 0, marginTop: -5 }} />

        <Tabs defaultActiveKey="1" animated={false}>
          <TabPane
            tab={
              <span>
                รายการ COD{' '}
                <CheckCircleOutlined
                  style={{
                    color: color.success,
                    marginLeft: 5,
                  }}
                />
              </span>
            }
            key="1"
          >
            <CodList />
          </TabPane>
        </Tabs>
      </>
    );
  }

  if (error) {
    content = <div>{error.message}</div>;
  }

  return content;
};

export default CreatePaymentCod;
