import React from 'react';
import {
  SYNTAX_DIVIDED,
  TEXT_CALENDAR_CELL_FOOTER,
  TEXT_CALENDAR_CELL_TITLE,
  TEXT_CALENDAR_NO_DATA,
  TEXT_CALENDAR_UNIT,
} from '../utils/Dashboard.Constants';
import { FunctionGetListData } from '../utils/Dashboard.Functions';
import '../utils/Dashboard.Css.css';
import formatCurrency from 'shared/utils/formatCurrency';

const DashboardRenderCell = (value: any, data = []) => {
  const thisDay = value.date();
  const thisMonth = value.month();
  const thisData = FunctionGetListData(data, thisDay, thisMonth);

  // const final_price = (thisData.total_price || 0) - (thisData.wait_tranfer_price || 0);

  return (
    <div>
      <div
        style={{
          fontSize: '16px',
          textAlign: 'end',
        }}
      >
        {TEXT_CALENDAR_CELL_TITLE}
      </div>
      <div
        style={{
          fontSize: '24px',
          textAlign: 'end',
          backgroundColor: thisData.total_tracking
            ? thisData.wait_tranfer_price > 0
              ? 'rgb(254 226 226)'
              : 'rgb(220 252 231)'
            : 'rgb(209 213 219)',
          width: '70%',
          marginLeft: 'auto',
          borderRadius: '10px',
          padding: '4px',
        }}
      >
        {thisData.total_tracking ? (
          <div>
            {thisData.total_tracking_wait_tranfer} {SYNTAX_DIVIDED} {thisData.total_tracking}
            {TEXT_CALENDAR_UNIT}
          </div>
        ) : (
          <div>{TEXT_CALENDAR_NO_DATA}</div>
        )}
      </div>
      <div
        style={{
          fontSize: '16px',
          textAlign: 'end',
          color: thisData.wait_tranfer_price > 0 ? 'red' : 'black',
        }}
      >
        {TEXT_CALENDAR_CELL_FOOTER.replace(
          '[count]',
          `${formatCurrency(thisData.wait_tranfer_price, '0.00')}`,
        )}
      </div>
    </div>
  );

  // if (thisData.total_price > 0 && thisData.total_tracking_wait_tranfer > 0) {
  //   return (

  //   );
  // } else {
  //   return (
  //     <div>
  //       <div
  //         style={{
  //           fontSize: '16px',
  //           textAlign: 'end',
  //         }}
  //       >
  //         {TEXT_CALENDAR_CELL_TITLE}
  //       </div>
  //       <div
  //         style={{
  //           fontSize: '24px',
  //           textAlign: 'end',
  //           backgroundColor: 'rgb(209 213 219)',
  //           width: '70%',
  //           marginLeft: 'auto',
  //           borderRadius: '10px',
  //           padding: '4px',
  //         }}
  //       >
  //         {TEXT_CALENDAR_NO_DATA}
  //       </div>
  //       <div
  //         style={{
  //           fontSize: '16px',
  //           textAlign: 'end',
  //           color: true ? 'black' : 'red',
  //         }}
  //       >
  //         {TEXT_CALENDAR_CELL_FOOTER.replace('[count]', '0')}
  //       </div>
  //     </div>
  //   );
  // }
};

export default DashboardRenderCell;
