/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import { Divider, Row, Col, Tabs, Skeleton, message } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import formatCurrency from 'shared/utils/formatCurrency';
import { formatDate } from 'shared/utils/dateTime';
import { color } from 'shared/utils/styles';
import { Text } from 'shared/components';
import BILLING_NOTE_QUERY, {
  BillingNoteData,
  BillingNoteVars,
} from 'shared/graphql/query/billingNote';
import { ReceiptContext } from 'views/AccountReceive/common/context/ReceiptContext';
import {
  TagStatusBillingNote,
  SelectFieldSearchCustomers,
} from 'views/AccountReceive/common/components';
import BillingNoteDetailTable from './BillingNoteDetailTable';

const { TabPane } = Tabs;

const SelectBillInvoiceStep: React.FC = () => {
  const { selectedBillingNoteDetail } = useContext(ReceiptContext);
  const {
    billingNoteId,
    payerId,
    payerFullName,
    serviceDate,
    grandTotalPrice,
    billNumber,
    unPaid,
  } = selectedBillingNoteDetail;

  const { data } = useQuery<BillingNoteData, BillingNoteVars>(BILLING_NOTE_QUERY, {
    variables: {
      _id: billingNoteId,
    },
    onError: (err) => {
      message.error(err.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = <Skeleton />;
  if (data?.billingNote) {
    const { system_request, payer, paid, bill_status } = data.billingNote;

    content = (
      <>
        <Row gutter={[32, 16]}>
          <Col span={8} style={{ borderRight: `1px dashed ${color.grey}` }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    ลูกค้า <Text customType="grey">(ลูกหนี้)</Text>:
                  </Col>
                  <Col span={24}>
                    <SelectFieldSearchCustomers
                      value={{
                        key: payerId,
                        label: payerFullName,
                      }}
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={8} style={{ borderRight: `1px dashed ${color.border}` }}>
            <Row>
              <Col span={10}>ประเภทงาน:</Col>
              <Col span={14}>
                <Text strong>{system_request}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>ที่อยู่วางบิล:</Col>
              <Col span={14}>
                <Text strong>{payer.full_address || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เลขประจำตัวผู้เสียภาษี:</Col>
              <Col span={14}>
                <Text strong>{payer.tax_id || '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>เบอร์โทรศัพท์:</Col>
              <Col span={14}>
                <Text strong>{payer.phone.length > 0 ? payer.phone.join(', ') : '-'}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>อีเมล:</Col>
              <Col span={14}>
                <Text strong>{payer.email.length > 0 ? payer.email.join(', ') : '-'}</Text>
              </Col>
            </Row>
          </Col>

          <Col span={8}>
            <Row>
              <Col span={8}>วันที่เอกสาร:</Col>
              <Col span={16}>
                <Text strong>{formatDate(serviceDate)}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รหัสใบวางบิล:</Col>
              <Col span={16}>
                <Text strong>{billNumber}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>รวมมูลค่าใบวางบิล:</Col>
              <Col span={16}>
                <Text strong customType="primary">
                  {formatCurrency(grandTotalPrice)}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>สถานะ:</Col>
              <Col span={16}>
                <TagStatusBillingNote status={bill_status} />
              </Col>
            </Row>
            <Row>
              <Col span={8}>จำนวนที่รับชำระแล้ว:</Col>
              <Col span={16}>
                <Text strong>{formatCurrency(paid, '-')}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>คงเหลือที่ต้องรับชำระ:</Col>
              <Col span={16}>
                <Text strong customType={unPaid ? 'danger' : undefined}>
                  {formatCurrency(unPaid, '-')}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider style={{ margin: 0, marginTop: -5 }} />

        <Tabs defaultActiveKey="1" animated={false}>
          <TabPane
            tab={
              <span>
                รายละเอียดงาน{' '}
                <CheckCircleOutlined
                  style={{
                    color: color.success,
                    marginLeft: 5,
                  }}
                />
              </span>
            }
            key="1"
          >
            <BillingNoteDetailTable />
          </TabPane>
        </Tabs>
      </>
    );
  }

  return content;
};

export default SelectBillInvoiceStep;
