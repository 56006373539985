import gql from 'graphql-tag';
import { CodInStatus } from 'views/CashOnDelivery/Routes/CashOnDeliveryByBooking/common/model/codInStatus.model';

export interface Customer {
  _id: string;
  full_name: string;
}

export interface Cod {
  _id: string;
  customer: Customer;
  cod_in_number: string;
  pick_up_date: string;
  delivered_date: string;
  booking_number: string;
  total_cod: number;
  due_date: string;
  confirm_status: number;
  total_vendor: number;
  payment_cod: {
    _id: string;
    cod_out_number: string;
  };
}

export interface Cods {
  cods: Cod[];
  totalDocument: number;
}

export interface CodsData {
  cods: Cods;
}

export interface CodsVars {
  customer?: string;
  cod_in_number?: string;
  cod_status?: CodInStatus;
  pick_up_date?: string;
  delivered_date?: string;
  booking_number?: string;
  due_date?: string;
  payment_cod?: string;
  order_by?: string;
  order_type?: '1' | '-1' | '';
  page?: number;
  showData?: number;
}

export default gql`
  query Cods(
    $customer: ID
    $cod_in_number: String
    $cod_status: Int
    $pick_up_date: String
    $delivered_date: String
    $booking_number: String
    $due_date: String
    $payment_cod: ID
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
  ) {
    cods(
      params: {
        customer: $customer
        cod_in_number: $cod_in_number
        cod_status: $cod_status
        pick_up_date: $pick_up_date
        delivered_date: $delivered_date
        booking_number: $booking_number
        due_date: $due_date
        payment_cod: $payment_cod
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      cods {
        _id
        customer {
          _id
          full_name
        }
        cod_in_number
        pick_up_date
        delivered_date
        booking_number
        total_cod
        due_date
        confirm_status
        total_vendor
        payment_cod {
          _id
          cod_out_number
        }
      }
      totalDocument
    }
  }
`;
