import gql from 'graphql-tag';
import { InvoiceStatus } from 'views/AccountReceive/common/model/invoiceStatus.model';
import { BusinessInvoiceType } from 'views/AccountReceive/common/model/businessInvoiceType.model';

export interface InvoicesList {
  _id: string;
  invoice_number: string;
  create_date: string;
  service_date: string;
  invoice_status: InvoiceStatus;
  receipt_date: string;
  grand_total_price: number;
  payer: {
    _id: string;
    full_name: string;
  };
  employee: {
    _id: string;
    full_name: string;
  };
  business_invoice_type: string;
  due_date: string;
  system_request: string;
  draft_date: string;
  last_update: string;
  invoice_detail: {
    number_ref: string;
  };
  followDate: string;
  followStatus: string;
  nextFollowDate: string;
  overDueDays: number;
  document: string;
  billing_note: {
    _id: string;
    bill_number: string;
  };
  receipt: {
    _id: string;
    receipt_number: string;
    receipt_date: string;
    document: string;
  };
}

export interface InvoicesData {
  invoices: {
    invoices: InvoicesList[];
    totalDocument: number;
  };
}

export interface InvoicesVars {
  _id?: string;
  invoice_status?: InvoiceStatus;
  draft_date?: string;
  due_date?: string;
  system_request?: string;
  invoice_number?: string;
  payer?: string;
  employee?: string;
  grand_total_price?: number;
  business_invoice_type?: BusinessInvoiceType;
  business_invoice_date_range?: number;
  create_date?: string;
  service_date?: string;
  receipt_date?: string;
  followDate?: string;
  followStatus?: string;
  nextFollowDate?: string;
  overDueDays?: string;
  followSuccess?: boolean;
  receipt?: string;
  last_update?: string;
  page?: number;
  showData?: number;
  order_by?: string;
  order_type?: '1' | '-1' | '';
}

export default gql`
  query Invoices(
    $invoice_status: Int
    $draft_date: String
    $due_date: String
    $system_request: String
    $invoice_number: String
    $payer: ID
    $employee: ID
    $grand_total_price: Float
    $business_invoice_type: Int
    $business_invoice_date_range: Int
    $create_date: String
    $service_date: String
    $last_update: String
    $page: Int
    $showData: Int
    $order_by: String
    $order_type: String
    $receipt_date: String
    $followDate: String
    $followStatus: String
    $nextFollowDate: String
    $overDueDays: String
    $followSuccess: Boolean
    $receipt: ID
  ) {
    invoices(
      params: {
        invoice_status: $invoice_status
        draft_date: $draft_date
        due_date: $due_date
        system_request: $system_request
        invoice_number: $invoice_number
        payer: $payer
        employee: $employee
        grand_total_price: $grand_total_price
        business_invoice_type: $business_invoice_type
        business_invoice_date_range: $business_invoice_date_range
        create_date: $create_date
        service_date: $service_date
        last_update: $last_update
        receipt_date: $receipt_date
        followDate: $followDate
        followStatus: $followStatus
        nextFollowDate: $nextFollowDate
        overDueDays: $overDueDays
        followSuccess: $followSuccess
        receipt: $receipt
      }
      page: $page
      showData: $showData
      sort: { order_by: $order_by, order_type: $order_type }
    ) {
      invoices {
        _id
        create_date
        service_date
        invoice_number
        invoice_status
        receipt_date
        grand_total_price
        payer {
          _id
          full_name
        }
        employee {
          _id
          full_name
        }
        business_invoice_type
        due_date
        system_request
        draft_date
        last_update
        invoice_detail {
          number_ref
        }
        followDate
        followStatus
        nextFollowDate
        overDueDays
        document
        billing_note {
          _id
          bill_number
        }
        receipt {
          _id
          receipt_number
          receipt_date
          document
        }
      }
      totalDocument
    }
  }
`;
